"use strict";
/**
 * @name app.common.model.domain.ChartLegend
 * @date 1/26/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Definition of the ChartLegend domain model object.
 */

const ChartLegend = function (config) {
    this.id = "";
    this.color = "";
    this.label = "";
    this.toolTip = "";

    if (config) {
        this.id = config.id;
        this.color = config.color;
        this.label = config.label;
        this.toolTip = config.toolTip;
    }
};

export default ChartLegend;
