import React from "react";

import { isNil as _isNil } from "lodash";
import PropTypes from "prop-types";
import { useRaf } from "react-use";

import FormatCurrency from "../currencyFormatter/CurrencyFormatter";

/**
 * Consecutively displays numbers in the range from starting number to ending number for the specified duration.
 * For example if the starting number is 10 and the ending number 20 and the duration is 1 second,
 * it will display 10, then 11, then 12 all the way to 20 all within a second.
 */
const NumberCounter = ({
    startingNumber,
    endingNumber,
    durationInMilliseconds = 1000,
    delayInMilliseconds = 0,
    isCurrency = false,
    currencyPrecision = 0,
    currencyUsesSuperscriptSymbol = false
}) => {
    const elapsed = useRaf(durationInMilliseconds, delayInMilliseconds);

    if (_isNil(startingNumber) || startingNumber.toFixed(4) === endingNumber.toFixed(4)) {
        return isCurrency ? (
            <FormatCurrency
                useSuperscriptSymbol={currencyUsesSuperscriptSymbol}
                precision={currencyPrecision}
                amount={endingNumber}
            />
        ) : (
            <React.Fragment>{endingNumber}</React.Fragment>
        );
    }

    const currentNumber = Math.round(startingNumber + (endingNumber - startingNumber) * elapsed);

    return isCurrency ? (
        <FormatCurrency
            useSuperscriptSymbol={currencyUsesSuperscriptSymbol}
            precision={currencyPrecision}
            amount={currentNumber}
        />
    ) : (
        <React.Fragment>{currentNumber}</React.Fragment>
    );
};
NumberCounter.propTypes = {
    startingNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    endingNumber: PropTypes.number,
    durationInMilliseconds: PropTypes.number,
    delayInMilliseconds: PropTypes.number,
    isCurrency: PropTypes.bool,
    currencyPrecision: PropTypes.number,
    currencyUsesSuperscriptSymbol: PropTypes.any
};
export default NumberCounter;
