import React, { useState, useEffect } from "react";

import { navigateFE } from "core-ui/client/react/core/services/adviceService.js";
import { SearchboxContainer } from "gw-shared-components";

import ReferenceData from "../../constants/ReferenceData";
import { useSelector } from "../../hooks/use-redux-actions";
import {
    useGroupTxnAccess,
    useFunnelAttributes,
    useMaDetails,
    useAccountCustomization,
    useAccountsList,
    useGetTopNavLinks
} from "../../queries";
import { getDisplayHsa } from "../../selectors/featureFlagSelectors";
import { IPrimaryPlanResponse } from "../../types/common";

import { getParsedKTMGFLags, getIsInvestmentCheckupInTopNavLinks } from "./helpers";
import TXN_CODES from "./txnCodes";

const txnCodes = TXN_CODES.join(",");

const { PROVIDER_FE, PROVIDER_MTR } = ReferenceData;

type SearchBoxProps = {
    primaryPlan: IPrimaryPlanResponse["data"];
};

const SearchBox = ({ primaryPlan }: SearchBoxProps) => {
    const [isSearchboxActive, setIsSearchboxActive] = useState(false);

    const { empowerCrossSellConsent, hasRsgServices, isRetail } = globalThis.integratedEligibility;

    const participant = useSelector((state) => state.participant);

    useEffect(() => {
        const searchOverlayEvent = () => setIsSearchboxActive(true);
        document.addEventListener("openSearchbox", searchOverlayEvent);
        return () => document.removeEventListener("openSearchbox", searchOverlayEvent);
    }, []);

    const { data: accountsList } = useAccountsList();

    const { data: maDetails } = useMaDetails(primaryPlan);

    const { data: funnelAttributes } = useFunnelAttributes();

    const { data: txnCodesResponseData } = useGroupTxnAccess({
        individualId: primaryPlan.indId,
        planId: primaryPlan.gaId,
        txnCodes
    });

    const { data: accountCustomization } = useAccountCustomization();

    const { data: topNavLinks } = useGetTopNavLinks({
        participant,
        primaryPlan
    });

    if (
        !accountsList ||
        !funnelAttributes ||
        !txnCodesResponseData ||
        !accountCustomization ||
        !topNavLinks
    ) {
        return null;
    }

    const parsedKTMLFlags = getParsedKTMGFLags({ txnCodesResponseData });

    const flags = {
        ...parsedKTMLFlags,
        aggregationLevel: funnelAttributes.spData.aggregationLevel ?? "",
        isRetail,
        isEligibleForFE: maDetails?.maProduct === PROVIDER_FE,
        isEligibleForMTR: maDetails?.maProduct === PROVIDER_MTR,
        isEnrolledInFE:
            maDetails?.maProduct === PROVIDER_FE && maDetails?.enrolledInManagedAccounts,
        isEnrolledInMTR:
            maDetails?.maProduct === PROVIDER_MTR && maDetails?.enrolledInManagedAccounts,
        empowerCrossSellConsent,
        hasRsgServices,
        hasHSAPlan: getDisplayHsa(participant.plans),
        hasInvestmentCheckup: getIsInvestmentCheckupInTopNavLinks({ topNavLinks })
    };

    const urlParams = {
        indId: primaryPlan.indId,
        groupId: primaryPlan.gaId,
        ua: accountsList.accounts?.[0]?.userAccountId
    };

    return (
        <SearchboxContainer
            isOpen={isSearchboxActive}
            onOutsideClick={() => setIsSearchboxActive(false)}
            flags={flags}
            urlParams={urlParams}
            externalCustomActions={{
                navigateFE
            }}
            externalLabels={{
                maProgramName: maDetails?.maProgramName ?? ""
            }}
            externalURLs={{
                educationCntrURL: accountCustomization?.educationCntrURL
            }}
        />
    );
};

export default SearchBox;
