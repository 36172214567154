export const SET_PARTICIPANT_TYPE = "SET_PARTICIPANT_TYPE";
export const SET_PARTICIPANT_DATE_OF_BIRTH = "SET_PARTICIPANT_DATE_OF_BIRTH";
export const SET_PARTICIPANT_RETIREMENT_AGE = "SET_PARTICIPANT_RETIREMENT_AGE";
export const SET_PARTICIPANT_PROJECTIONS_MAP = "SET_PARTICIPANT_PROJECTIONS_MAP";
export const SET_PARTICIPANT = "SET_PARTICIPANT";
export const SET_PARTICIPANT_DEFERRALS = "SET_PARTICIPANT_DEFERRALS";
export const UPDATE_PARTICIPANT_SALARY = "UPDATE_PARTICIPANT_SALARY";
export const SET_PARTICIPANT_PLANS_NEW_DEFERRALS = "SET_PARTICIPANT_PLANS_NEW_DEFERRALS";
export const UPDATE_PARTICIPANT_INCOME_GOAL = "UPDATE_INCOME_GOAL";
export const SET_PARTICIPANT_ADJUSTED_LIFE_EXPECTANCY_AGE =
    "SET_PARTICIPANT_ADJUSTED_LIFE_EXPECTANCY_AGE";
export const SET_PARTICIPANT_DEFAULT_LIFE_EXPECTANCY_AGE =
    "SET_PARTICIPANT_DEFAULT_LIFE_EXPECTANCY_AGE";
export const SET_PLAN_RULES_OVERRIDE_FLAGS = "SET_PLAN_RULES_OVERRIDE_FLAGS";
export const UPDATE_HAS_REDUCED_SALARY = "UPDATE_HAS_REDUCED_SALARY";
export const SET_SHOW_CONSIDERATION_MODAL_LOADER = "SET_SHOW_CONSIDERATION_MODAL_LOADER";
export const UPDATE_PARTICIPANT_RETIREMENT_ASSETS = "UPDATE_PARTICIPANT_RETIREMENT_ASSETS";
export const UPDATE_PARTICIPANT_EXTERNAL_ASSETS = "UPDATE_PARTICIPANT_EXTERNAL_ASSETS";
export const SET_PARTICIPANT_AGE = "SET_PARTICIPANT_AGE";
export const SET_PARTICIPANT_INDIVIDUAL_ID = "SET_PARTICIPANT_INDIVIDUAL_ID";
export const SET_PARTICIPANT_PLANS = "SET_PARTICIPANT_PLANS";
export const SET_IS_AA_FEATURE_ENABLED = "SET_IS_AA_FEATURE_ENABLED";
export const SET_SPOUSE_RETIREMENT_AGE = "SET_SPOUSE_RETIREMENT_AGE";
export const SET_USER_MESSAGES = "SET_USER_MESSAGES";
export const SET_WCIR_AGELIST = "SET_WCIR_AGELIST";
