import { getStringDisplayPercent } from "../utils/numberUtils";

import {
    isDollarDeferral,
    getDeferralPaycheckToTermConversion,
    getDollarDeferralToPercentConversion
} from "./deferralsLogic/deferrals";

export const getDeferralSummaryStringDisplay = (
    primaryPlan,
    deferralSetupConfig,
    activeDeferral
) => {
    let totalPercent;
    const allowMultipleDeferrals = primaryPlan.planRules.allowMultipleDeferrals;
    const hasMixedDeferrals = deferralSetupConfig.hasMixedDeferrals;
    const deferralSummaryTotal = deferralSetupConfig.deferralSummaryTotal;
    const payFrequency = primaryPlan.payFrequency;
    const hasVariableDeferral = deferralSetupConfig.hasVariableDeferral;
    const salary = primaryPlan.salary;
    const { variableSalary, base } = salary;
    const totalSalary = hasVariableDeferral ? base + variableSalary : base;
    const isDollarType = isDollarDeferral(activeDeferral);

    if (allowMultipleDeferrals) {
        if (hasMixedDeferrals || !isDollarType) {
            return getStringDisplayPercent(deferralSummaryTotal);
        }
        totalPercent = getStringDisplayPercent(
            deferralSummaryTotal *
                getDollarDeferralToPercentConversion(totalSalary, payFrequency) *
                100
        );
        return (
            "($" +
            (deferralSummaryTotal * getDeferralPaycheckToTermConversion(payFrequency)).toFixed() +
            ") " +
            totalPercent
        );
    } else {
        if (!isDollarType) {
            return getStringDisplayPercent(deferralSummaryTotal);
        }
        totalPercent = getStringDisplayPercent(
            deferralSummaryTotal *
                getDollarDeferralToPercentConversion(totalSalary, payFrequency) *
                100
        );
        return (
            "($" +
            (deferralSummaryTotal * getDeferralPaycheckToTermConversion(payFrequency)).toFixed() +
            totalPercent
        );
    }
};
