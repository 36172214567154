import type { Investments } from "../../../types/common/index";
import { getIsPortfolioManagementAllowed } from "../index";

/**
 * Logic for detecting whether or not to submit an investment mix change
 * @returns {boolean}
 */
const equityMixChangesAllowed = (individualOptions: Investments["individualOptions"]): boolean => {
    return (
        !individualOptions.notAllMoneyTypeGroupingsFlow &&
        getIsPortfolioManagementAllowed(individualOptions)
    );
};

export default equityMixChangesAllowed;
