import React from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";

import Constants from "../../constants/Constants";

// aka zeroState
const WelcomeWidget = () => {
    const welcomeWidgetTranslations = selectTranslations("zeroStateDashboard");

    const { title, message, CTA_Button } = welcomeWidgetTranslations.netWorth.welcomeWidget;

    //when the user clicks the CTA within zero state tool page and goes to link account modal
    //create in client\src\app\core\amplitude\AmplitudeEvents.ts
    const navigateToAddAccountPage = () => {
        const amplitudeEventData = {
            eventType: AMPLITUDE_EVENTS.STARTFLOW_LINK_ACCOUNT, //new in core-ui. will fail pipeline until merged
            selection: CTA_Button,
            widget: "Welcome"
        };

        dispatchAmplitude(amplitudeEventData);
        window.location.href = "#/accounts/add";
    };

    return (
        <section id="welcome-widget-container" data-testid="welcome-widget">
            <div>
                <img
                    alt="confetti"
                    src={Constants.STYLE_ENV + "customizations/Default/images/confetti_wht.svg"}
                    className="enrollment-confirmation_V2-confetti-icon"
                />
            </div>
            <section className={`summary-widget summary-widget__zero-state welcome-widget`}>
                <div className={`summary-widget__header`}>
                    <h2 className="summary-widget__title--link">{title}</h2>
                </div>
                <div className="summary-widget__body">
                    <p>{message}</p>
                </div>
                <div className="zero-state__cta">
                    <button onClick={navigateToAddAccountPage}>{CTA_Button}</button>
                </div>
            </section>
        </section>
    );
};

export default WelcomeWidget;
