import React, { useState, useEffect } from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { isUndefinedOrNull } from "gw-shared-components";
import PropTypes from "prop-types";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import {
    changeHDICAgeFilter,
    changeHDICSalaryFilter,
    changeHDICGenderFilter
} from "../../../../actions/howDoICompare/howDoICompareActions";
import { dispatchEventBus } from "../../../../actions/shared/sharedActionCreators";
import { ariaControlsPeersTopPeers } from "../../../../constants/AccessibilityPaycheck";
import EventBusEvents from "../../../../events/eventBusEvents";

export const FilterController = ({ name, list, defaultValue, title }) => {
    const dispatch = useDispatch();
    const { ageGroup, salaryGroup, genderGroup } = useSelector(
        (state) => ({
            ageGroup: state.howDoICompare.ageGroup,
            salaryGroup: state.howDoICompare.salaryGroup,
            genderGroup: state.howDoICompare.genderGroup
        }),
        shallowEqual
    );

    const confidenceLevel = useSelector(
        (state) => state.applicationSettings.projectionSettings.confidence
    );
    const selectedFilter = {
        ageRange: { group: ageGroup, action: changeHDICAgeFilter, name: "ageRange" },
        salaryRange: { group: salaryGroup, action: changeHDICSalaryFilter, name: "salaryRange" },
        genderRange: { group: genderGroup, action: changeHDICGenderFilter, name: "genderRange" }
    };
    const [selectValue, setSelectedValue] = useState("");

    useEffect(() => {
        if (!isUndefinedOrNull(defaultValue)) {
            setSelectedValue(defaultValue.id);
        }
    }, [defaultValue]);

    const createSelectOptions = (arr = []) => {
        return arr.map((item) => {
            return (
                <option key={item.id} value={item.id} data-id={item.id}>
                    {item.label}
                </option>
            );
        });
    };

    const getNewScoreMapKey = (
        name,
        newGroup,
        ageGroup,
        genderGroup,
        salaryGroup,
        confidenceLevel
    ) => {
        let newKey = "";
        if (name === "ageRange") {
            newKey = newGroup.id + salaryGroup.id + genderGroup.id;
        } else if (name === "salaryRange") {
            newKey = ageGroup.id + newGroup.id + genderGroup.id;
        } else if (name === "genderRange") {
            newKey = ageGroup.id + salaryGroup.id + newGroup.id;
        }
        return newKey + confidenceLevel;
    };

    const handleGA = (name, newGroup) => {
        const previousValue = selectedFilter[name].group;
        const payload = {
            name,
            previousValue,
            value: newGroup
        };
        dispatch(dispatchEventBus(EventBusEvents.HDIC.GROUP_CHANGE, payload, payload));
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: EventBusEvents.HDIC.GROUP_CHANGE,
            payload
        });
    };

    const changeHandler = (event) => {
        const name = event.target.name;
        setSelectedValue(selectedFilter[name].group.id);

        const id = event.target[event.target.selectedIndex].dataset.id;
        const newGroup = list.find((item) => item.id === id);

        const newKey = getNewScoreMapKey(
            name,
            newGroup,
            ageGroup,
            genderGroup,
            salaryGroup,
            confidenceLevel
        );

        handleGA(name, newGroup);
        dispatch(selectedFilter[name].action({ newGroup, scoreMapKey: newKey }));
    };

    const ariaDescribedBy = `${ariaControlsPeersTopPeers} dropdown-title-${name}`;

    return (
        <div className="form-group row">
            <label htmlFor={name} id={`dropdown-title-${name}`}>
                {title}
            </label>
            <div className="form-control">
                <div className="custom-select custom-select-medium form-control">
                    <select
                        name={name}
                        id={name}
                        onChange={changeHandler}
                        value={selectValue}
                        className="btn dropdown-toggle"
                        required
                        aria-describedby={ariaDescribedBy}
                    >
                        {createSelectOptions(list)}
                    </select>
                </div>
            </div>
        </div>
    );
};

FilterController.propTypes = {
    name: PropTypes.string,
    list: PropTypes.array,
    title: PropTypes.string,
    defaultValue: PropTypes.any
};
