import React from "react";

import ReferenceData from "../../../../constants/ReferenceData";
import CurrencyFormatter from "../../../currencyFormatter/CurrencyFormatter";
import IQuickViewAccount from "../../types";
import QuickViewLink from "../QuickViewLink";

interface RenderContributionCardProps {
    planDetails: IQuickViewAccount | null;
    showContributionCard: boolean;
    translations: {
        planDetails: {
            contributions: {
                title: string;
                top: string;
            };
        };
    };
}
const RenderContributionCard = ({
    planDetails,
    translations,
    showContributionCard
}: RenderContributionCardProps) => {
    if (!planDetails || !translations || !showContributionCard) {
        return null;
    }

    return (
        <div className="quick-view-item d-flex flex-column" data-testid="contributionCard">
            <QuickViewLink
                link={ReferenceData.URL_HYPERLINK_MY_CONTRIBUTIONS}
                planDetails={planDetails}
                title={`${translations.planDetails.contributions.top} ${translations.planDetails.contributions.title}`}
            />
            <p className="subtitle">
                <CurrencyFormatter awaitHubCalc={false} amount={planDetails?.ytd} />
            </p>
        </div>
    );
};
export default RenderContributionCard;
