import { useEffect } from "react";

import { setGetInitDataCalled } from "../../../../actions/shared/sharedActionCreators";
import { useDispatch, useSelector } from "../../../../hooks/use-redux-actions";
import { initialize, noLiatInitialize } from "../../../../middleware/initializationMiddleware";

const useInitializedIntegrated = ({ showLIAT }: { showLIAT: boolean | undefined }) => {
    const dispatch = useDispatch();
    const { initCalled } = useSelector((state) => state.shared);

    useEffect(() => {
        if (showLIAT) {
            if (!initCalled) {
                dispatch(setGetInitDataCalled()); // This is where initCalled is being set
                dispatch(initialize());
            }
        } else if (showLIAT === false) {
            if (!initCalled) {
                dispatch(setGetInitDataCalled());
                dispatch(noLiatInitialize());
            }
        }
    }, [dispatch, initCalled, showLIAT]);
};

export default useInitializedIntegrated;
