import {
    SET_SHOW_NQ_MODAL,
    SET_SHOW_LOCKHEED_MODAL,
    SET_SHOW_LOCKHEED_ENROLL,
    SET_SHOW_LOCKHEED_TERMS_OF_SVC,
    SET_SHOW_LOCKHEED_NO_ELECTIONS,
    SET_LOCKHEED_SETTINGS
} from "./enrollmentTypes";

export const setShowNqModal = (payload) => {
    return {
        type: SET_SHOW_NQ_MODAL,
        payload
    };
};

export const setShowLockheedModal = (payload) => {
    return {
        type: SET_SHOW_LOCKHEED_MODAL,
        payload
    };
};

export const showLockheedEnroll = (payload) => {
    return {
        type: SET_SHOW_LOCKHEED_ENROLL,
        payload
    };
};

export const showLockheedTermsOfService = (payload) => {
    return {
        type: SET_SHOW_LOCKHEED_TERMS_OF_SVC,
        payload
    };
};

export const showLockheedNoElections = (payload) => {
    return {
        type: SET_SHOW_LOCKHEED_NO_ELECTIONS,
        payload
    };
};

export const setLockheedTerms = (data) => {
    const obj = {
        isGroupLockheedPlan: data.groupLockheedPlan,
        planName: data.planName,
        currentChainingOption: data.currentChainingOption,
        enrollmentEndDate: data.enrollmentEndDate
    };

    return {
        type: SET_LOCKHEED_SETTINGS,
        payload: obj
    };
};
