/**
 * @name app.common.model.domain.OutsideAssetCategory
 * @date 4/8/2015
 * @copyright 2015 Empower Retirement
 * @description A holder of an asset category
 */

"use strict";

const OutsideAssetCategory = function (config) {
    this.hover = "";
    this.display = "";
    this.createdDate = new Date();
    this.createdBy = "";
    this.updatedDate = new Date();
    this.updatedBy = "";
    this.faq = "";
    this.name = "";
    this.id = 0;
    this.status = "";

    if (config) {
        this.hover = config.hover;
        this.display = config.display;
        this.createdDate = config.createdDate;
        this.createdBy = config.createdBy;
        this.updatedDate = config.updatedDate;
        this.updatedBy = config.updatedBy;
        this.faq = config.faq;
        this.name = config.name;
        this.id = config.id;
        this.status = config.status;
    }
};

export default OutsideAssetCategory;
