import { SET_INIT_DATA } from "../../actions/initData";
import { SET_IRS_LIMITS_REF_DATA } from "../../actions/irsLimitsRefData/irsLimitsRefDataTypes";
import IrsLimitsRefDataFactory from "../../factories/IrsLimitsRefDataFactory";
const initialState = { dataIsLoaded: false };

const getNewStateFromRawIrsLimitsRefData = (state, rawIrsLimitsRefData) => {
    return {
        ...state,
        ...IrsLimitsRefDataFactory.createIrsLimitsRefData(rawIrsLimitsRefData),
        dataIsLoaded: true
    };
};

export default (state = initialState, action) => {
    const { type, rawIrsLimitsRefData, payload } = action;
    switch (type) {
        case SET_INIT_DATA:
            return getNewStateFromRawIrsLimitsRefData(state, payload.irsLimitsRefData);
        case SET_IRS_LIMITS_REF_DATA:
            return getNewStateFromRawIrsLimitsRefData(state, rawIrsLimitsRefData);
        default:
            return state;
    }
};
