import React from "react";
function FallbackComponent() {
    return (
        <div>
            An error has occurred. Please refresh the page if the problem persists contact a
            Participant Services Representative.
        </div>
    );
}

export default FallbackComponent;
