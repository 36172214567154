const LinkedAccountBalances = function (config) {
    this.name = "";
    this.investorId = 0;
    this.planId = "";
    this.value = 0;
    this.type = "";
    this.assetType = "";
    this.monthlyIncomeEST = 0;
    this.trsFlexAccountInfo = null;
    this.typeString = "";
    this.isEditable = false;
    this.isDeletable = false;
    this.isViewable = false;
    this.employerContribution = false;
    this.individualId = "";
    this.lastModifiedOn = null;

    if (config) {
        this.planId = config.planId;
        this.investorId = config.investorId;
        this.name = config.name;
        this.value = config.value;
        this.type = config.type;
        this.assetType = config.assetType;
        this.monthlyIncomeEST = config.monthlyIncomeEST;
        this.trsFlexAccountInfo = config.trsFlexAccountInfo;
        this.typeString = config.typeString;
        this.isEditable = config.isEditable;
        this.isDeletable = config.isDeletable;
        this.isViewable = config.isViewable;
        this.employerContribution = config.employerContribution;
        this.individualId = config.individualId;
        this.lastModifiedOn = config.lastModifiedOn;
    }
};

export default LinkedAccountBalances;
