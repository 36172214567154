import React, { memo } from "react";

import { useQuery } from "@tanstack/react-query";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { useAtomValue } from "jotai";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { csrfAtom } from "../../atoms/atoms";
import ReferenceData from "../../constants/ReferenceData";
import { getAccounts } from "../../middleware/otherAssetsMiddleware";
import { useNavigationMenu } from "../../queries";
import { QUERY_KEYS } from "../../queries/constants";
import { WantToShell } from "../loadShells/LoadShells";
import WantToList from "../wantToNavigation/WantToList";

const WantToComponent = ({ showLoader }) => {
    const translations = selectTranslations("rivdTodayView")["iWantTo"];
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const csrf = useAtomValue(csrfAtom);
    const {
        data: hideIWantToLinks,
        isLoading,
        isError
    } = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [QUERY_KEYS.GET_ACCOUNTS],
        queryFn: () => getAccounts(csrf),
        enabled: !!csrf
    });
    const isTaxableAcct = String(primaryPlan.id).indexOf(ReferenceData.TAXABLE_ACCOUNT) > -1;
    const { data: menuNavigationData, isLoading: isMenuNavigationLoading } = useNavigationMenu({
        groupId: primaryPlan.id,
        isTaxableAcct
    });
    const hasPriority =
        !isMenuNavigationLoading && hideIWantToLinks !== undefined && !isLoading && !isError;
    const cssClass = showLoader || !hasPriority ? "iwt__load-container" : "loaded";

    if (isLoading || isMenuNavigationLoading) {
        return (
            <div
                className={`bg-white rounded-border i-want-to-list--container ${cssClass}`}
                data-testid="want-to-component"
            >
                <WantToShell />
            </div>
        );
    }

    if (hideIWantToLinks || !menuNavigationData || isError) {
        return null;
    }

    return (
        <div
            className={`bg-white rounded-border i-want-to-list--container ${cssClass}`}
            data-testid="want-to-component"
        >
            <div className={`use-fade-in ${cssClass}`}>
                <div className="i-want-to-list--header">
                    <span className="i-want-to-icon"></span>
                    <h3>{translations?.labelEllipsis}</h3>
                </div>
                <WantToList navigationMenu={menuNavigationData} />
            </div>
        </div>
    );
};

WantToComponent.propTypes = {
    showLoader: PropTypes.bool.isRequired
};

export default memo(WantToComponent);
