import React, { useState, useEffect } from "react";

import { keyboardTabNavigation } from "core-ui/client/react/core/utils/accessibilityHelpers";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { Loader } from "gw-shared-components";
import { useSelector, useDispatch } from "react-redux";

import { setGoalFocus } from "../../actions/goal/goalModalActionCreators";
import {
    setHDICAgeGroup,
    setHDICSalaryGroup,
    setHDICGenderGroup,
    setHDICKeyAndScore,
    unSetTopPeersFocus
} from "../../actions/howDoICompare/howDoICompareActions";
import { reselectParticipantPortionPct } from "../../selectors/reselectSelectors";
import PaycheckHeader from "../../shared/PaycheckHeader";
import PaycheckDetails from "../paycheck/PaycheckDetails";

import MobileFilterControls from "./components/filterControls/MobileFilterControls";
import { FiltersControls, TabledDataView, TargetPeersComparison } from "./components/index";
import { TableHeaders } from "./components/tabledDataView/index";
import useHdicData from "./use-hdic-data";

const getMedLis = (includeSocialSecurity, score, participantPortionPct) => {
    return ((includeSocialSecurity ? score.medLis : score.medLisNoSS) * 75) / participantPortionPct;
};

const getTopLis = (includeSocialSecurity, score, participantPortionPct) => {
    return ((includeSocialSecurity ? score.topLis : score.topLisNoSS) * 75) / participantPortionPct;
};

/**
 * On change of any of the 3 drops downs, age, salary, or gender, the comparisons are displayed based on
 * function scoreMap using these parameters. Only 2 columns, My Peers and Top Peers will update
 * On change of Goal Modal salary, the same on change salary event using by how do I compare is done and uses
 * the function, scoreMap
 * On change of Goal Modal retirement amount or pct, the comparison values are obtained from the service
 * methods, getMyPeers and getTopPeers to obtain comparison values. All three columns are updated in display.
 * Only the percentages in the radial chart are updated. Contribution and Balances do not change.
 */
const HowDoICompare = () => {
    const dispatch = useDispatch();
    const translations = selectTranslations("hdic");
    const { srHeaderUpdatedMessage } = selectTranslations("retirementIncome");

    const {
        myPeers,
        topPeers,
        hasTopPeersFocus,
        score,
        scores,
        ageGroups,
        salaryGroups,
        genderList
    } = useSelector((state) => state.howDoICompare);
    const { includeSocialSecurity, confidence } = useSelector(
        (state) => state.applicationSettings.projectionSettings
    );

    const participantPortionPct = useSelector(reselectParticipantPortionPct);
    const [hasUpdatedGoalModalPct, setHasUpdatedGoalModalPct] = useState(false);
    const participantSalary = useSelector((state) => state.participant.salary.base);
    const baselineSalary = useSelector((state) => state.baseline.salary.base);
    const participant = useSelector((state) => state.participant);

    const { showHowDoICompare, howDoICompareLoaded } = useHdicData();

    const [showHDICModal, setShowHDICModal] = useState(false);

    useEffect(() => {
        if (participantSalary !== baselineSalary) {
            setHasUpdatedGoalModalPct(true);
        }
    }, [baselineSalary, participantSalary]);

    const toggleHDICModal = () => {
        setShowHDICModal(!showHDICModal);
    };

    const initalizeDefaultScore = () => {
        const age = participant.age;
        const gender = participant.gender;
        const salary = participant.salary.base;

        dispatch(setHDICAgeGroup(ageGroups, age));
        dispatch(setHDICSalaryGroup(salaryGroups, salary));
        dispatch(setHDICGenderGroup(genderList, gender));
        dispatch(
            setHDICKeyAndScore(participant, ageGroups, genderList, salaryGroups, confidence, scores)
        );
    };

    const handleCancel = () => {
        initalizeDefaultScore();
        setShowHDICModal(false);
    };

    const handleSave = () => {
        setShowHDICModal(false);
    };

    const onTabNav = (event) => {
        const nextAction = () => {
            event.preventDefault();
            dispatch(unSetTopPeersFocus());
            dispatch(setGoalFocus());
        };
        keyboardTabNavigation({ event, nextAction });
    };

    const medLis = getMedLis(includeSocialSecurity, score, participantPortionPct);
    const myPeerPct = hasUpdatedGoalModalPct ? myPeers.percentageOfGoal * 100 : medLis;
    const topLis = getTopLis(includeSocialSecurity, score, participantPortionPct);
    const topPeerPct = hasUpdatedGoalModalPct ? topPeers.percentageOfGoal * 100 : topLis;

    const customMsg = srHeaderUpdatedMessage.replace("{title}", translations.rivdTitle);

    if (!showHowDoICompare) {
        return null;
    }

    return (
        <div className="section-peer-comparison">
            {!howDoICompareLoaded && <Loader />}
            <div className="sr-only" role="alert">
                {customMsg}
            </div>
            <div id="integrated-hdic" className="section-peer-comparison-wrapper">
                <PaycheckHeader
                    title={translations.rivdTitle}
                    additionalClass="integrated-hdic-color-tax-types-education"
                />
                <div>
                    <table className="desktop-how-do-i-compare-table hidden-xs">
                        <tbody>
                            <TableHeaders
                                toggleHDICModal={false}
                                display={"desktopView"}
                                translations={translations}
                            />
                            <tr className="hidden-xs">
                                <FiltersControls />
                                <TargetPeersComparison
                                    percentageOfGoal={myPeerPct}
                                    name={"target-my-peers"}
                                    peerClass="svg-my-peers"
                                />
                                <TargetPeersComparison
                                    percentageOfGoal={topPeerPct}
                                    name={"target-top-peers"}
                                    peerClass="svg-top-peers"
                                    hasFocus={hasTopPeersFocus}
                                    onTabNav={onTabNav}
                                />
                            </tr>
                        </tbody>
                    </table>
                    <TabledDataView
                        toggleHDICModal={toggleHDICModal}
                        myPeerPct={myPeerPct}
                        topPeerPct={topPeerPct}
                    />
                </div>
                {showHDICModal ? (
                    <MobileFilterControls
                        showHDICModal={showHDICModal}
                        handleCancel={handleCancel}
                        handleSave={handleSave}
                    ></MobileFilterControls>
                ) : null}
                <div className="paycheck-details-xs visible-xs-block">
                    <PaycheckDetails isMobileView={true} />
                </div>
            </div>
        </div>
    );
};

export default HowDoICompare;
