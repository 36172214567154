import {
    setParticipantLifeExpectancyAge,
    setConsiderationModalLoader
} from "../actions/participantDetails/participantDetailsActions";
import { preRetireeProjectedIncome } from "../actions/projectedIncome/projectedIncomeActions";
import { setCurrentFault } from "../actions/shared/sharedActionCreators";
import RequestFactory from "../factories/RequestFactory";
import { updateProjectionFactors } from "../middleware/projectionFactorsMiddleware";
import { executeRequest } from "../utils/apiUtils.js";
import { isNotEmulator, handleErrorPAE, isPrivileged } from "../utils/paeUtils";
import { getUrl } from "../utils/urlUtils.js";

/**
 * @description builds the request for saving age override based on migrated/non-migrated
 * @param {number|null} planToAgeOverride  // null for reseting assumptions
 */
const createPreRetireeRequest = (planToAgeOverride, updateStatus) => {
    const service =
        updateStatus === "update" ? "saveNonMigratedPlanToAgeOverride" : "resetPlanToAgeOverride";

    const url = getUrl(service);
    const params = updateStatus === "update" ? { planToAgeOverride } : "";

    const method =
        updateStatus === "update"
            ? RequestFactory.HTTP_METHOD.POST
            : RequestFactory.HTTP_METHOD.GET;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const request = RequestFactory.create(url, params, method, contentType);
    delete request.headers.samlToken;

    return request;
};

/**
 * @description overrides the default plan to age (life expectancy)
 * @param {number} age - user defined life expectancy
 * @returns {function} - a redux-thunk dispatchable function
 */
export const updatePlanToAgeOverride = (age) => (dispatch, getState) => {
    const state = getState();
    const { participant } = state;

    dispatch(setConsiderationModalLoader(true));

    const savePlanToAgeSucces = async ({ data }) => {
        const payload = data.data[participant.personId];

        dispatch(setParticipantLifeExpectancyAge(payload.planToAgeOverride));
        await dispatch(updateProjectionFactors(false));
        dispatch(preRetireeProjectedIncome());
        dispatch(setConsiderationModalLoader(false));
    };

    const request = createPreRetireeRequest(age, "update");

    const emulateSavePlanToAgeSucces = async () => {
        handleErrorPAE(dispatch);
        dispatch(setParticipantLifeExpectancyAge(age));
        await dispatch(updateProjectionFactors(false));
        dispatch(preRetireeProjectedIncome());
        dispatch(setConsiderationModalLoader(false));
    };

    const proceedParticipantRequest = () => {
        return executeRequest(request, "savePlanToAgeOverride")
            .then(savePlanToAgeSucces)
            .catch((fault) => {
                dispatch(setConsiderationModalLoader(false));
                dispatch(setCurrentFault({ ...fault, stayOnCurrentPage: true }));
            });
    };

    const checkIfPrivileged = () => {
        if (isPrivileged()) {
            emulateSavePlanToAgeSucces();
        } else {
            handleErrorPAE(dispatch);
        }
    };

    return isNotEmulator()
        .then(proceedParticipantRequest)
        .catch(() => {
            checkIfPrivileged();
        });
};

/**
 * @description handles the reset assmptions for the participant plan to default age
 * @param {object} participant
 * @param {function} dispatch
 */
export const resetAgeAssumptions = (participant, dispatch) => {
    dispatch(setConsiderationModalLoader(true));

    const resetAgeAssumptionsSuccess = async ({ data }) => {
        const payload = data.data[participant.personId];
        dispatch(setParticipantLifeExpectancyAge(payload.defaultLEAge));
        await dispatch(updateProjectionFactors(true));
        dispatch(preRetireeProjectedIncome());

        dispatch(setConsiderationModalLoader(false));

        return data;
    };

    const emulateResetAgeAssumptionsSuccess = async () => {
        handleErrorPAE(dispatch);
        dispatch(setParticipantLifeExpectancyAge(participant.defaultLEAge));
        await dispatch(updateProjectionFactors(false));
        dispatch(preRetireeProjectedIncome());
        dispatch(setConsiderationModalLoader(false));
    };

    const request = createPreRetireeRequest(null, "reset");

    const proceedParticipantRequest = () => {
        return executeRequest(request, "resetAgeAssumptions")
            .then(resetAgeAssumptionsSuccess)
            .catch((fault) => {
                dispatch(setConsiderationModalLoader(false));
                dispatch(setCurrentFault({ ...fault, stayOnCurrentPage: true }));
            });
    };

    const checkIfPrivileged = () => {
        if (isPrivileged()) {
            emulateResetAgeAssumptionsSuccess();
        } else {
            handleErrorPAE(dispatch);
        }
    };

    return isNotEmulator()
        .then(proceedParticipantRequest)
        .catch(() => {
            checkIfPrivileged();
        });
};
