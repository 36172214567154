import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";

import { FilterController } from "./FilterController";

const MobileFilterControls = ({ showHDICModal, handleCancel, handleSave }) => {
    const translations = selectTranslations("hdic");
    const translationsApp = selectTranslations("app");

    const { ageGroups, salaryGroups, genderList } = useSelector(
        (state) => ({
            ageGroups: state.howDoICompare.ageGroups,
            salaryGroups: state.howDoICompare.salaryGroups,
            genderList: state.howDoICompare.genderList
        }),
        shallowEqual
    );

    const ageGroup = useSelector((state) => state.howDoICompare.ageGroup);
    const salaryGroup = useSelector((state) => state.howDoICompare.salaryGroup);
    const genderGroup = useSelector((state) => state.howDoICompare.genderGroup);

    return (
        <Modal dialogClassName="hdic-modal" show={showHDICModal}>
            <Modal.Header className="modal-header">
                <h3 className="modal-title" id="modal-title">
                    {translations.filterOptionsTitle}
                </h3>
            </Modal.Header>
            <Modal.Body className="modal-body" id="modal-body">
                <form
                    action="#replace-with-service"
                    method="post"
                    className="form-inline ng-pristine ng-valid hdic-mobile-filters"
                >
                    <FilterController
                        list={ageGroups}
                        name={"ageRange"}
                        defaultValue={ageGroup}
                        title={translations.age}
                    />
                    <FilterController
                        list={salaryGroups}
                        name={"salaryRange"}
                        defaultValue={salaryGroup}
                        title={translations.salary}
                    />
                    <FilterController
                        list={genderList}
                        name={"genderRange"}
                        defaultValue={genderGroup}
                        title={translations.gender.title}
                    />
                </form>
            </Modal.Body>
            <Modal.Footer className="row">
                <div className="hdic-footer">
                    <div className="health-care__close-container col-sm-1">
                        <button className="btn btn-link" onClick={handleCancel}>
                            <span>{translationsApp.common.labels.cancel}</span>
                        </button>
                    </div>
                    <div className="health-care__update-container  col-sm-3">
                        <button
                            className="done-btn btn btn-primary"
                            type="button"
                            onClick={handleSave}
                        >
                            {translationsApp.common.labels.save}
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
MobileFilterControls.propTypes = {
    showHDICModal: PropTypes.bool,
    handleCancel: PropTypes.func,
    handleSave: PropTypes.func
};

export default MobileFilterControls;
