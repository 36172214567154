"use strict";
/**
 * @name app.infrastructure.domainModel.DeferralConfig
 * @date 12/7/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Definition of the DeferralConfig domain model object.
 */

const DeferralConfig = function (config) {
    this.displayName = "";
    this.descr = "";
    this.granularityPct = 0.0;
    this.granularityAmt = 0.0;
    this.minPct = 0.0;
    this.minAmt = 0.0;
    this.maxDeferral = 0.0;
    this.defrlAvailCode = "";
    this.taxStatus = "";
    this.incomeStream = "";
    this.ageCatchupApplicable = "";
    this.allowAgeCombinedInd = "";
    this.minRequiredInd = false;
    this.allowScheduleInd = "";
    this.maximizeEligibleInd = "";
    this.maxSlider = 0.0; //this isn't in the schema but based on 12/7 discussion it will exist
    this.enrollmentGroupCode = "STANDARD";

    if (config) {
        this.displayName = config.displayName;
        this.descr = config.descr;
        this.granularityPct = config.granularityPct;
        this.granularityAmt = config.granularityAmt;
        this.minPct = config.minPct;
        this.minAmt = config.minAmt;
        this.maxDeferral = config.maxDeferral;
        this.defrlAvailCode = config.defrlAvailCode;
        this.taxStatus = config.taxStatus;
        this.incomeStream = config.incomeStream;
        this.ageCatchupApplicable = config.ageCatchupApplicable;
        this.allowAgeCombinedInd = config.allowAgeCombinedInd;
        this.minRequiredInd = config.minRequiredInd;
        this.allowScheduleInd = config.allowScheduleInd;
        this.maximizeEligibleInd = config.maximizeEligibleInd;
        this.maxSlider = config.maxSlider;
        this.enrollmentGroupCode = config.enrollmentGroupCode;
    }
};

export default DeferralConfig;
