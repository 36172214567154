import React from "react";

import { RadioOption } from "gw-shared-components";
import PropTypes from "prop-types";

import { NumberInput } from "../../numberinput/NumberInput";

const CustomHsaExpenseOption = ({
    message,
    hsaExpenseOption,
    handleRadioSelected,
    customAmount,
    handleCustomIncome,
    spendCode,
    handleTextInputClick
}) => {
    const maxEditableEntry = 1000000;

    const format = (val) => {
        if (typeof val === "number") {
            val = val.toString();
        }
        const maxEntryLength = String(maxEditableEntry).length;
        let num = val;
        //take off leading zeros
        if (Number(num) >= 1) {
            num = num.replace(/^0+/, "");
        }

        //limit to max entry
        if (maxEditableEntry && Number(val) > maxEditableEntry) {
            const valStringArray = val.toString().split(".");
            num = valStringArray[0].substr(0, maxEntryLength);
            if (valStringArray.length > 1) {
                num += "." + valStringArray[1];
            }
        }
        return String(num);
    };

    const formattedCustomAmount = format(customAmount);
    const isChecked = hsaExpenseOption === spendCode;

    return (
        <div className="hsa-tab-radio-button-row">
            <RadioOption
                option={message}
                isChecked={isChecked}
                onChangeHandler={handleRadioSelected}
                value={spendCode}
                strongValue={null}
            />
            {hsaExpenseOption === spendCode && (
                <NumberInput
                    id="customAmountValue"
                    additional={false}
                    handleIncome={(amount) => {
                        handleCustomIncome(amount);
                    }}
                    type="text"
                    value={formattedCustomAmount}
                    modal={true}
                    canSave={true}
                    placeholder="$0.00"
                    className="hsa-number-input form-control"
                    customizeClass={true}
                    identifier="edit-customAmt-value-input"
                    onClick={handleTextInputClick}
                    maxEditableEntry={maxEditableEntry}
                />
            )}
        </div>
    );
};
CustomHsaExpenseOption.propTypes = {
    message: PropTypes.string,
    hsaExpenseOption: PropTypes.string,
    handleRadioSelected: PropTypes.func,
    customAmount: PropTypes.number,
    handleCustomIncome: PropTypes.func,
    spendCode: PropTypes.string,
    handleTextInputClick: PropTypes.func
};
export { CustomHsaExpenseOption };
