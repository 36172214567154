/**
 * Summary,
 * The percentageStringFormatter function converts a string representing a numeric value into a percentage format with two decimal places.
 * If the input is null, it returns "0.00".
 * Example Usage
 * console.log(percentageFormatUI("0.1234")); // Output: "12.34"
 * console.log(percentageFormatUI(null)); // Output: "0.00"
 * Inputs
 * @param value The input value as a string or null.
 * @returns A string representing the percentage value with two decimal places.
 */
const percentageStringFormatter = (value: string | null): string => {
    const numericValue = value != null && !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
    return (numericValue * 100).toFixed(2);
};

export default percentageStringFormatter;
