import React from "react";

import PropTypes from "prop-types";

import { dispatchEventBus } from "../../../actions/shared/sharedActionCreators";
import SOCIAL_SECURITY_CONSTANTS from "../../../constants/SocialSecurityConstants";
import { SSEvents } from "../../../events/SSEvents";

const SocialSecurityMessaging = ({ translations }) => {
    const handleEventBus = () => {
        dispatchEventBus(SSEvents.ADMINISTRATION_LINK_CLICK);
    };

    return (
        <React.Fragment>
            <p data-testid="social-security-messaging">
                {translations.benefitsAndOptions.descriptionPart1} &nbsp;
                <a
                    href={SOCIAL_SECURITY_CONSTANTS.SSA_GOV_QUICKCALC}
                    title={translations.benefitsAndOptions.socialSecurityAdministration}
                    target="_blank"
                    onClick={handleEventBus}
                    data-testid="social-admin-link"
                    rel="noreferrer"
                >
                    {translations.benefitsAndOptions.socialSecurityAdministration}
                </a>
                {translations.benefitsAndOptions.descriptionPart2}
                <a
                    href={SOCIAL_SECURITY_CONSTANTS.SSA_GOV_QUICKCALC_FAQ}
                    title={translations.benefitsAndOptions.here}
                    target="_blank"
                    data-testid="gov-social-security-link"
                    rel="noreferrer"
                >
                    {translations.benefitsAndOptions.here}
                </a>
                .
            </p>
        </React.Fragment>
    );
};
SocialSecurityMessaging.propTypes = {
    translations: PropTypes.object
};
export default SocialSecurityMessaging;
