import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import { ObjectUtil } from "gw-shared-components";
import { each as _each } from "lodash";

import { hasDeferralWarnings } from "../business/deferralsLogic/deferrals";
import { save } from "../services/deferralService";
import { saveUserPreferencesService } from "../services/userPreferencesService";
import { handleFault } from "../utils/errorUtils";
import { getPAEPromise } from "../utils/paeUtils";

const logger = ExternalLogger.getInstance("ReviewChangesService");

/**
 * Uses the deferral delegate to save changes to a deferral.
 *
 * Technically this method is unnecessary and we could simply call this directly in the `submitChanges()` method
 * but since we have one for saving retirement we'll keep this here for consistency and readability.
 *
 * @param {number} value The value of the deferral to save.
 * @param {Deferral} deferral The actual deferral to save. The value is the old, original once hence the value being passed in.
 * @param {string} planId The plan ID for the deferral.
 * @returns {promise}
 */
const saveDeferral = (deferrals, planId) => {
    return save(deferrals, planId);
};

/**
 * Uses the user preferences delegate to save changes to the retirement age.
 *
 * @param {number} retirementAge The value to change.
 * @returns {promise}
 */
const saveRetirementAge = (config) => {
    logger.info("saveRetirementAge( {0} )", [config.retirementAge]);

    const newConfig = {
        pcapPersonId: config.personId,
        retirementAge: config.retirementAge
    };

    return saveUserPreferencesService(config.individualId, newConfig);
};

export const submit = (config) => {
    if (ObjectUtil.isUndefinedOrNull(config.clientId)) {
        throw new Error("`clientId` is a required parameter.");
    }
    if (ObjectUtil.isUndefinedOrNull(config.individualId)) {
        throw new Error("`individualId` is a required parameter.");
    }
    if (ObjectUtil.isUndefinedOrNull(config.planId)) {
        throw new Error("`planId` is a required parameter.");
    }
    if (
        ObjectUtil.isUndefinedOrNull(config.deferrals) &&
        ObjectUtil.isUndefinedOrNull(config.retirementAge) &&
        ObjectUtil.isUndefinedOrNull(config.equityMix)
    ) {
        throw new Error(
            "Either `contributionRate`, `retirementAge`, or `equityMix` is required parameter in order to save."
        );
    }

    const promisesList = [];
    const confirmationData = [];
    let deferralRestriction = null;

    const getConfirmationData = function (data, planName) {
        if (
            !ObjectUtil.isUndefinedOrNull(data) &&
            !ObjectUtil.isUndefinedOrNull(data.confirmationNumber)
        ) {
            confirmationData.push({
                planName: planName,
                confirmationNumber: data.confirmationNumber
            });
        }
    };

    const getDeferralRestrictions = (data) => {
        deferralRestriction = !ObjectUtil.isUndefinedOrNull(data.deferralRestrictions)
            ? data.deferralRestrictions
            : null;
    };

    const processResponse = (data, planName) => {
        getConfirmationData(data, planName);
        getDeferralRestrictions(data);
    };

    // need closure for values
    const createPromise = (deferrals, planId, planName, effectiveDate) => {
        return saveDeferral(deferrals, planId, effectiveDate).then(function (data) {
            return processResponse(data, planName);
        });
    };

    const submitChangesSuccess = () => {
        logger.info("submitChangesSuccess()");

        const data = {
            deferrals: config.deferrals ? config.deferrals : null,
            confirmationData: confirmationData,
            retirementAge: config.retirementAge ? config.retirementAge : null,
            equityMix: config.equityMix ? config.equityMix : null,
            deferralRestrictions: deferralRestriction
        };
        return data;
    };

    const submitChangesFault = (fault) => {
        const faultObj = Object.prototype.hasOwnProperty.call(fault, "genericError")
            ? fault
            : handleFault(fault);
        logger.error("submitChangesFault( fault = {0} )", [fault.message]);
        return Promise.reject(faultObj);
    };

    const submitChangesComplete = () => {
        logger.info("submitChangesComplete()");
    };

    /* dont call save Methods if the user is not a participant */
    if (!config.isTypeParticipant) {
        promisesList.push(getPAEPromise());
    } else {
        if (
            config.deferrals &&
            config.deferrals.length &&
            config.hasDeferralChanged &&
            !hasDeferralWarnings(config.deferralWarnings)
        ) {
            if (ObjectUtil.isUndefinedOrNull(config.deferrals)) {
                throw new Error(
                    "`deferral` is a required parameter if trying to save the contribution rate."
                );
            }

            const deferralMap = {};

            _each(config.deferrals, function (deferral) {
                if (deferralMap[deferral.plan.id]) {
                    deferralMap[deferral.plan.id].push(deferral);
                } else {
                    deferralMap[deferral.plan.id] = [deferral];
                }
            });

            for (const key in deferralMap) {
                promisesList.push(
                    createPromise(
                        deferralMap[key],
                        key,
                        deferralMap[key][0].plan.name,
                        config.effectiveDate
                    )
                );
            }
        }

        if (config.retirementAge) {
            promisesList.push(saveRetirementAge(config));
        }
    }

    return Promise.all(promisesList)
        .then(submitChangesSuccess)
        .catch(submitChangesFault)
        .finally(submitChangesComplete);
};
