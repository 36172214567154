import { each as _each } from "lodash";

import IRSLimits from "../models/IRSLimits";

const IrsLimitsRefDataFactory = {
    createIrsLimitsRefData: function (data) {
        const limits = {};
        if (data && Object.keys(data)) {
            _each(Object.keys(data), function (limitKey) {
                const limit = new IRSLimits(data[limitKey]);
                if (data[limitKey]) {
                    data[limitKey].rateOfInflation = data[limitKey].rateOfInflation
                        ? data[limitKey].rateOfInflation * 100
                        : data[limitKey].rateOfInflation;
                    data[limitKey].discountFactor = data[limitKey].discountFactor
                        ? data[limitKey].discountFactor * 100
                        : data[limitKey].discountFactor * 100;
                }

                limits[limitKey] = limit;
            });
        }
        return limits;
    }
};

export default IrsLimitsRefDataFactory;
