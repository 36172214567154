"use strict";
/**
 * @name app.common.model.domain.CompanyMatch
 * @date 1/26/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Definition of the CompanyMatch domain model object.
 */

class CompanyMatch {
    constructor(config) {
        this.matchTiers = [];
        this.matchableDeferralTypes = ["BEFORE"];
        this.ruleTypes = [];

        if (config) {
            this.matchTiers = config.matchTiers;
            this.matchableDeferralTypes = config.matchableDeferralTypes;
            this.ruleTypes = config.ruleTypes;
        }
    }
}

export default CompanyMatch;
