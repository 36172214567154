import { DiagnosticsConstants } from "../common";
import formatTimingPrefix from "../common/formatTimingPrefix";

const timingPrefix = formatTimingPrefix();

export const getLiatAppStartTime = () => {
    return sessionStorage.getItem(`${timingPrefix}${DiagnosticsConstants.LIAT_APP_START}`);
};

export default getLiatAppStartTime;
