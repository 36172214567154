import { ObjectUtil } from "gw-shared-components";

export const getTradeOffCurrencyPrefix = (tradeOffAmount) => {
    let prefix;
    if (ObjectUtil.isUndefinedOrNull(tradeOffAmount)) {
        return "";
    }
    if (tradeOffAmount < 0) {
        prefix = "-";
    } else {
        prefix = "+";
    }
    return prefix + " ";
};

export const getTradeOffMessage = (message, tradeoffs, term) => {
    let tradeOffTerm;
    switch (term) {
        case 1:
            tradeOffTerm = "year";
            break;
        case 12:
            tradeOffTerm = "month";
            break;
    }
    return tradeoffs[message][tradeOffTerm];
};

export const getTradeOffMobileLabel = (message, tradeoffs) => {
    return tradeoffs[message].mobileLabel;
};

export const hasTradeOffs = (set) => {
    const EPSILON = 0.05;
    return set.some((item) => {
        const amount = Math.abs(item.amount);
        return amount >= EPSILON;
    });
};

/**
 * @memberOf common.service.business.RetirementSavingsService
 * @description is enrolled in maximizer
 * @returns {*|maximizer|{leavingMaximizer}|string|null|boolean|*}
 */
export const getEnrolledInMaximizer = function (primaryPlan) {
    // NOTE: Only uncomment this for dev, but do not leave in for prod as it logs quite a bit for AJS digest cycle's data binding
    //logger.debug("getMaximizer( exists = {0} )", [String(!ObjectUtil.isUndefinedOrNull(dataModel.activePlan.maximizer))]);
    // return getMaximizerAvailable() && getMaximizerEnrolledAvailable() && getMaximizer().isOngoing;
    return (
        primaryPlan.planRules.maximizer &&
        !ObjectUtil.isUndefinedOrNull(primaryPlan.maximizer) &&
        primaryPlan.maximizer.isOngoing
    );
};
