/**
 * @name app.infrastructure.domainModel.Deferral
 * @date 1/26/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Definition of the Deferral domain model object.
 */

import DeferralLimits from "./DeferralLimits";

const Deferral = function (configuration) {
    this.deferralTypeCode = "";
    this.nycGoalFlag = null;
    this.valueUnits = ""; //TODO: TJM we could possibly do away with this, BUT, it's everywhere so maybe this is better?
    this.value = 0;
    this.priorValue = null;
    this.deferralLimits = new DeferralLimits(); //this will derived in the DMF and is not part of the actual domain object
    this.pctAmtCode = 0;
    this.payrollOption = "";
    this.status = "";
    this.effectiveDate = new Date();
    this.effectiveDateString = this.effectiveDate.toDateString(); //this is for display purposes and not part of the actual domain object
    this.nextPayrollDate = new Date();
    this.defaulted = "";
    this.active = false;
    this.autoIncrease = null;
    this.futureDated = null;
    this.electedMaximizerSelection = "";
    this.electedMaximizerType = "";
    this.config = null;
    this.csor = false;
    this.grandfathered = false;
    this.submissionDate = new Date();
    this.plan = null; // watch out for circular reference
    this.planId = null;
    this.isPrimaryAndActive = false; // is deferral from primary plan
    this.isMixedDeferral = false;

    if (configuration) {
        this.deferralTypeCode = configuration.deferralTypeCode;
        this.nycGoalFlag = configuration.nycGoalFlag;
        this.value = configuration.value;
        this.pctAmtCode = configuration.pctAmtCode;
        this.payrollOption = configuration.payrollOption;
        this.status = configuration.status;
        this.valueUnits = configuration.valueUnits;
        this.effectiveDate = configuration.effectiveDate;
        this.effectiveDateString = configuration.effectiveDateString;
        this.nextPayrollDate = configuration.nextPayrollDate;
        this.defaulted = configuration.defaulted;
        this.active = configuration.active;
        this.autoIncrease = configuration.autoIncrease;
        this.futureDated = configuration.futureDated;
        this.electedMaximizerSelection = configuration.electedMaximizerSelection;
        this.electedMaximizerType = configuration.electedMaximizerType;
        this.config = configuration.config;
        this.deferralLimits = configuration.deferralLimits;
        this.csor = configuration.csor || false;
        this.grandfathered = configuration.grandfathered;
        this.submissionDate = configuration.submissionDate;
        this.plan = configuration.plan;
        this.planId = configuration.planId;
        this.isPrimaryAndActive = configuration.isPrimaryAndActive;
        this.isMixedDeferral = configuration.isMixedDeferral;
    }
};

export default Deferral;
