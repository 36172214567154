import { showLoader, setCurrentFault } from "../actions/shared/sharedActionCreators";
import ReferenceData from "../constants/ReferenceData";

export const getPAEFault = () => {
    return {
        isPAEError: true,
        message: "Non participant users are prohibited from saving changes.",
        code: "PAE001",
        stayOnCurrentPage: true
    };
};

/**
 * For when you can't click a navigation link because PAE
 * @returns {{message: string, code: string}}
 */
export const getPAENavigationFault = () => {
    return {
        message: "Non participant users are prohibited from navigating to this page.",
        code: "PAE002",
        stayOnCurrentPage: true
    };
};

/**
 * For when you can't click a button action because PAE
 * @returns {{message: string, code: string}}
 */
export const getPAEActionFault = () => {
    return {
        message: "Non participant users are prohibited from this feature.",
        code: "PAE003",
        stayOnCurrentPage: true
    };
};

export const getPAEPromise = () => {
    return Promise.resolve("Promise Resolved");
};

// Bypass update if PAE; don't call save services if the user is not a participant
export const isNotEmulator = () => {
    if (
        globalThis.integratedEligibility.isEmulator &&
        (globalThis.integratedEligibility.type === ReferenceData.USER_TYPE_PAE ||
            globalThis.integratedEligibility.type === ReferenceData.USER_TYPE_PRIVILEGED)
    ) {
        return Promise.reject("currently in emulator");
    } else {
        return Promise.resolve("proceed as participant");
    }
};

export const handleErrorPAE = (dispatch) => {
    const paePromise = getPAEPromise();
    dispatch(setCurrentFault({ ...getPAEFault(), stayOnCurrentPage: true }));
    dispatch(showLoader(false));
    return paePromise;
};

export const isEmulator = () => {
    return globalThis.integratedEligibility?.type !== ReferenceData.USER_TYPE_PARTICIPANT;
};

/**
 * Returns boolean indicating if particiapnt type is PRIVILEGED
 * @returns {boolean}
 */
export const isPrivileged = () => {
    return globalThis.integratedEligibility?.type === ReferenceData.USER_TYPE_PRIVILEGED;
};
