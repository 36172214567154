import {
    SET_CONFIRMATION_DATA,
    SET_SHOW_CONFIRM_MODAL,
    SET_LAST_CHANGES
} from "./confirmChangesTypes";

export const setConfirmationData = (payload) => {
    return {
        type: SET_CONFIRMATION_DATA,
        payload
    };
};

export const setShowConfirmModal = (payload) => {
    return {
        type: SET_SHOW_CONFIRM_MODAL,
        payload
    };
};

export const setLastChanges = (payload) => {
    return {
        type: SET_LAST_CHANGES,
        payload
    };
};
