import React from "react";

import { ktmgFlags, LoadingBar } from "gw-shared-components";

import { useGroupTxnAccess } from "../../../../queries";
import IQuickViewAccount from "../../types";
import { RenderBeneficiaries } from "../RenderBeneficiaries";
import { RenderContributionCard } from "../RenderContributionCard";
import { RenderLastTransaction } from "../RenderLastTransaction";
import { RenderRoR } from "../RenderRoR";

interface QuickViewDetailsOpenProps {
    annualRateOfReturn: string | undefined;
    planDetails: IQuickViewAccount | null;
    rateOfReturnDateRange: string;
    translations: {
        planDetails: {
            beneficiaries: {
                bottomPrimary: string;
                title: string;
            };
            contributions: {
                title: string;
                top: string;
            };
            lastTransactions: {
                title: string;
            };
        };
        rateOfReturn: string;
    };
}

const QuickViewDetailsOpen = ({
    planDetails,
    translations,
    annualRateOfReturn,
    rateOfReturnDateRange
}: QuickViewDetailsOpenProps) => {
    const { data: groupTxnAccessData, isLoading: isGroupTxnAccessDataLoading } = useGroupTxnAccess({
        individualId: planDetails?.indId,
        planId: planDetails?.gaId,
        txnCodes: `${ktmgFlags.ENABLE_CONTRIBUTION_CARD},${ktmgFlags.REACT_TRANSACTION_HISTORY}`
    });

    if (isGroupTxnAccessDataLoading || !planDetails) {
        return (
            <div className={`d-flex quick-view-details-display quick-view-details-display--full`}>
                <LoadingBar barWidth="90%" loadingTime={4} position="center" restartLoading />
            </div>
        );
    }

    const showContributionCard =
        groupTxnAccessData?.[ktmgFlags.ENABLE_CONTRIBUTION_CARD] === "true";
    const showTransactionHistory =
        groupTxnAccessData?.[ktmgFlags.REACT_TRANSACTION_HISTORY] === "true";

    const beneficiariesTotal =
        planDetails?.beneficiaries?.total && planDetails?.beneficiaries?.total > 0;

    const detailsArray = [
        Object.keys(planDetails?.ror || {}).length > 0,
        beneficiariesTotal,
        showContributionCard,
        showTransactionHistory && !!annualRateOfReturn
    ];

    const quickViewDetailsFilter = detailsArray.filter((item) => item === true);
    const hasAllDetails = quickViewDetailsFilter.length >= 3 ? "split" : "";

    return (
        <div
            className={`d-flex quick-view-details-display quick-view-details-display--full ${hasAllDetails}`}
        >
            <RenderRoR
                translations={translations}
                annualRateOfReturn={annualRateOfReturn}
                rateOfReturnDateRange={rateOfReturnDateRange}
                planDetails={planDetails}
            />
            <RenderBeneficiaries planDetails={planDetails} translations={translations} />
            <RenderContributionCard
                planDetails={planDetails}
                translations={translations}
                showContributionCard={showContributionCard}
            />
            <RenderLastTransaction planDetails={planDetails} translations={translations} />
        </div>
    );
};

export default QuickViewDetailsOpen;
