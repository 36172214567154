import React, { useRef } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { isEmpty } from "lodash";

import SubPage from "../../components/subpage";

import { useInitializeSubPage } from "./hooks";

interface IntegratedSubPageContainerProps {
    subPageContainerClass: string;
}

const IntegratedSubPageContainer = ({ subPageContainerClass }: IntegratedSubPageContainerProps) => {
    const subpageLayout = useRef(null);
    const translations = selectTranslations("app");

    const hasTranslations = !isEmpty(translations);

    useInitializeSubPage({ hasTranslations });

    if (!hasTranslations) {
        return null;
    }

    return (
        <div
            className={`subpage-page-layout ${subPageContainerClass}`}
            ref={subpageLayout}
            data-testid="sub-page-container"
        >
            <SubPage />
        </div>
    );
};

export default IntegratedSubPageContainer;
