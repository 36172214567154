import { useEffect, useState } from "react";

import { Location } from "history";
import { useAtomValue } from "jotai";
import { useLocation } from "react-router-dom";

import {
    RETIREMENT_INCOME_ROUTE,
    ROUTE_OPEN_ACCOUNT_PS,
    ROUTE_OPEN_ACCOUNT_IRA
} from "../../../../../routes";
import { redwoodAtom, showWidgetsAtom } from "../../../../atoms/atoms";
import useDisplayPcapModal from "../../../../hooks/useDisplayPcapModal";
import { initializeRouteLocation } from "../../helpers";
import { ROUTES_WITH_NO_SIDEBAR } from "../../helpers/routesWithNoSideBar";
import useSideBarDisplay from "../useSideBarDisplay";
const LIAT_PREVIOUS_ROUTE = "LIAT_PREVIOUS_ROUTE";
const setPreviousRouteSessionStorage = (previousLocation: string) => {
    sessionStorage.setItem(LIAT_PREVIOUS_ROUTE, previousLocation);
};
interface PcapClass extends Record<string, any> {
    appHistory: {
        currentRoute: string;
        previousRoute: string;
    };
    mountDashboard: (mount: boolean) => void;
    unmountSidebar?: () => void;
}
const unMountSideBar = (
    currentPath: string,
    pcapClass: PcapClass | Record<string, never>,
    hideSideBar: boolean
) => {
    const isOpenAccountSubRoute =
        currentPath.startsWith(ROUTE_OPEN_ACCOUNT_PS) ||
        currentPath.startsWith(ROUTE_OPEN_ACCOUNT_IRA);

    if (ROUTES_WITH_NO_SIDEBAR.includes(currentPath) || isOpenAccountSubRoute) {
        if (pcapClass?.sidebar && hideSideBar) {
            pcapClass?.unmountSidebar?.();
        }
    }
};

/**
 * Hook to set the previous route in session storage when a modal is opened, mounting the
 * pcap dashboard and unmounting the pcap sidebar.
 */
const useLocationMounting = (): Location => {
    const redwood = useAtomValue(redwoodAtom);
    const showWidgets = useAtomValue(showWidgetsAtom);
    const hashLocation: Location = useLocation();
    const displayModal = useDisplayPcapModal(hashLocation);
    const [routeLocation, setRouteLocation] = useState<Location>(
        initializeRouteLocation({ hashLocation, displayModal })
    );

    const { hideSideBar } = useSideBarDisplay(hashLocation.pathname);
    useEffect(() => {
        if (showWidgets !== null) {
            // Storing previous route in session storage to account for page refresh cases
            //  const previousRouteLocation = sessionStorage.getItem(LIAT_PREVIOUS_ROUTE);
            if (!displayModal) {
                /**
                 * When closing the modal, we enter this conditonal and expect the user to be
                 * brought back to previous route ex:
                 * /dashboard/retirement-income --> /accounts/add
                 * becomes
                 * /accounts/add --> /dashboard/retirement-income
                 */
                setRouteLocation(hashLocation);
                /**
                 * On page reload, the view does not always re-render when we close the modal.
                 * Adding the refresh param will force it to do so.
                 */
                const hashedLocationRefresh = {
                    ...hashLocation,
                    search: "?refresh=true"
                };
                setPreviousRouteSessionStorage(JSON.stringify(hashedLocationRefresh));
            }
            // appHistory doesn't update on dashboard if mountDashboard isn't called (showWidgets === false)
            if (hashLocation.pathname.indexOf("dashboard") !== -1 && !showWidgets) {
                if (redwood?.pcapClass?.appHistory) {
                    redwood.pcapClass.appHistory.currentRoute = "#" + RETIREMENT_INCOME_ROUTE;
                    redwood.pcapClass.appHistory.previousRoute = "#" + RETIREMENT_INCOME_ROUTE;
                }
            }
            unMountSideBar(hashLocation.pathname, redwood.pcapClass, hideSideBar);
        }
    }, [displayModal, hashLocation, redwood.pcapClass, showWidgets, hideSideBar]);

    return routeLocation;
};
export default useLocationMounting;
