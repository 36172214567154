/**
 *
 * @class common.factory.RequestFactory
 * @memberOf common.factory
 * @date 01/01/2015
 * @copyright 2015 Empower Retirement
 * @description
 *
 *        Factory to create API request objects. Provides the following defaults:
 *
 *            * HTTP Method: POST
 *            * Content-Type: "application/x-www-form-urlencoded"
 *
 */

import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import URLUtil from "core-ui/client/src/app/URLUtil";
import { isNil as _isNil } from "lodash";

const RequestFactory = {
    logger: ExternalLogger.getInstance("RequestFactory"),

    /**
     * Boolean indicating if the locator should grab the endpoint for live or mock services.
     * @type {boolean}
     */
    useMocks: URLUtil.getURLParam("useMocks") === "true",

    /**
     * Values for HTTP content type.
     */
    HTTP_METHOD: {
        GET: "get",
        POST: "post",
        PUT: "put",
        DELETE: "delete",
        PATCH: "patch"
    },

    /**
     * Values for HTTP content type.
     */
    HTTP_CONTENT_TYPE: {
        URL_ENCODED: "application/x-www-form-urlencoded",
        JSON: "application/json"
    },

    /**
     * Creates a generic request object that's typically the same for app server API endpoints.
     *
     * @param {string} url The service URL.
     * @param {object} params The request object's JSON payload.
     * @param {string} method The HTTP method or action like "GET", "POST", "DELETE", etc.
     * @param {object} contentType The request object's JSON payload.
     * @returns {object}
     */
    create: function (url, params, method, contentType, samlToken) {
        // If we're using mocks and hitting local JSON files we need to use HTTP GET
        // Else if `method` was null or undefined we default to HTTP POST
        if (RequestFactory.useMocks || url.indexOf("data/") !== -1) {
            RequestFactory.logger.warn("create( Method = HTTP GET; forcing since using Mocks )");
            method = RequestFactory.HTTP_METHOD.GET;
        } else if (_isNil(method)) {
            RequestFactory.logger.info(
                "create( Method = HTTP POST; Defaulting since not provided )"
            );
            method = RequestFactory.HTTP_METHOD.POST;
        }

        // Make sure to default the content type to a query string if on isn't provided
        if (_isNil(contentType)) {
            RequestFactory.logger.info("create( ContentType = {0}; Defaulting )", [
                RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED
            ]);
            contentType = RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED;
        }

        // create the request object
        const request = {
            url: url,
            method: method,
            headers: {
                "Content-Type": contentType,
                samlToken
            }
        };

        // make sure the parameters for the service are set in the request object correctly
        // based on the HTTP method
        if (method === RequestFactory.HTTP_METHOD.GET) {
            request.params = _isNil(params) || RequestFactory.useMocks ? null : params;
        } else {
            request.data = params;
        }

        // forces the credentials to be sent with every request object to ensure continued authentication
        request.withCredentials = true;

        return request;
    }
};

export default RequestFactory;
