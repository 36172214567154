import { Participant } from "../../../../types/common";
import { getDateString } from "../../../../utils/dateUtils";
import personDropdown from "../personDropdown";

const DEFAULT_DROPDOWN_OPTIONS: DropDownOption[] = [];

interface DropDownOption {
    age: number;
    optionsVal: string;
    value: string;
}

interface SSText {
    details: Record<string, string | Record<string, string>>;
    headingTitle: string;
}

interface GetDropDownOptins {
    dateOfBirth: Participant["dateOfBirth"];
    exists: boolean;
    retirementAge: number;
    ssText: SSText;
    startReceivingAge: number;
}

const getDropDownOptions = ({
    ssText,
    startReceivingAge,
    exists,
    dateOfBirth,
    retirementAge
}: GetDropDownOptins) => {
    const dob = typeof dateOfBirth === "string" ? dateOfBirth : getDateString(dateOfBirth);

    if (!exists) {
        return DEFAULT_DROPDOWN_OPTIONS;
    }

    return personDropdown(dob, retirementAge, ssText, startReceivingAge);
};

export default getDropDownOptions;
