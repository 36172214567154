import React, { useRef, useState } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import DateUtil from "core-ui/client/src/app/DateUtil";
import { ObjectUtil } from "gw-shared-components";
import Highcharts from "highcharts";
import highchartsAccessibility from "highcharts/modules/accessibility";
import highchartsPatternFill from "highcharts/modules/pattern-fill";
import HighchartsReact from "highcharts-react-official";
import _clone from "lodash/clone";
import _cloneDeep from "lodash/cloneDeep";
import _filter from "lodash/filter";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _keys from "lodash/keys";
import _map from "lodash/map";
import _max from "lodash/max";
import _min from "lodash/min";
import _some from "lodash/some";
import _values from "lodash/values";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { ACCESSIBILITY } from "../../constants/AccessibilityPaycheck";
import constants from "../../constants/Constants";
import { selectIncomeTerm } from "../../selectors/applicationSettingsSelectors";
import { formatUsDollarAmount } from "../../utils/currencyUtils";
import HiddenA11yWrapper from "../accessibility/HiddenA11yWrapper";

// used for scrolling
const COLUMN_WIDTH = 15;
const COLUMN_PADDING_PHONE = 8;
const COLUMN_PADDING_NON_PHONE = 20;
const CHART_SPACING_LEFT_AND_RIGHT_OF_X_AXIS = 88;

const CHART_HEIGHT = 180;

// if stripe width is 4 and line width is 3, there will be three lines of the hatching color (yellow or blue)
// and 1 of the background color (dark gray)
const HATCH_STRIPE_WIDTH = 4;
const HATCH_STRIPE_LINE_WIDTH = 3;
const HATCH_ROTATE_DEGREES = 135;

const HATCH_PATH = [...Array(HATCH_STRIPE_LINE_WIDTH)].reduce((prev, curr, index) => {
    return `${prev} M ${index} 0 L ${index} ${HATCH_STRIPE_WIDTH}`;
}, "");

// These are the keys used in the retireeDetails data we get back from the Hub
const USER_TYPE_PARTICIPANT = "user";
const USER_TYPE_SPOUSE = "spouse";

const NUM_YEARS_TO_SHOW_IN_MOBILE_COLLAPSED_VIEW = 7;

// non mobile, if there are less than this number of years, the width of the bars grows proportionally
const NUM_YEARS_WHEN_COLUMN_WIDTH_BECOMES_FIXED = 7;

const SPOUSE_SOCIAL_SECURITY_COLOR = "#f9c527";
const INTEGRATED_SPOUSE_OTHER_ASSETS_COLOR = "#0473ce";
const PARTICIPANT_COLOR = "#44A3B9";
const SPOUSE_COLOR = "#58D7F4";
const HATCH_BACKGROUND_COLOR = "#525252";

const PHONE_SPACING_BOTTOM = 35;
const NON_PHONE_SPACING_BOTTOM = 35;
const NUM_LEFT_COLUMNS_TO_ALWAYS_STACK_VERTICALLY = 4;

const PreRetireeChart = ({ retireeDetails, spouseRetireAge }) => {
    const translations = selectTranslations("preRetiree");
    const participant = useSelector((state) => state.participant);
    const spouse = useSelector((state) => state.spouse);
    const term = useSelector(selectIncomeTerm);

    const termLabel = term === 1 ? translations.chart.perYearIn : translations.chart.perMonthIn;

    const isPhone = useMediaQuery({ query: "(max-width: " + constants.SCREEN_WIDTH.PHONE + "px)" });
    const isTabletOrSmaller = useMediaQuery({
        query: "(max-width: " + constants.SCREEN_WIDTH.TABLET + "px)"
    });

    const isMidResolution =
        "(min-width: " +
        constants.SCREEN_WIDTH.TABLET +
        "px) and (max-width:" +
        constants.SCREEN_WIDTH.LARGE_DESKTOP +
        "px)";

    // if the screen is less than this width, the first 3 columns in the chart will get a tooltip
    // where the participant and spouse are stacked vertically so that the left portion of the tooltip
    // does not get cut off of the page
    const isScreenWideEnoughToShowTwoColumnsInTooltip = useMediaQuery({
        query: "(min-width: 1300px)"
    });

    // This custom tooltip callout adds a triangle to the middle of the bottom pointing down
    Highcharts.SVGRenderer.prototype.symbols.customTooltipCallout = function (x, y, w, h, options) {
        const arrowLength = 6,
            halfDistance = 6;
        const r = Math.min((options && options.r) || 0, w, h);
        const anchorX = w / 2;

        const path = [
            ["M", x + r, y],
            ["L", x + w - r, y],
            ["C", x + w, y, x + w, y, x + w, y + r],
            ["L", x + w, y + h - r],
            ["C", x + w, y + h, x + w, y + h, x + w - r, y + h],
            ["L", anchorX + halfDistance, y + h],
            ["L", anchorX, y + h + arrowLength],
            ["L", anchorX - halfDistance, y + h],
            ["L", x + r, y + h],
            ["C", x, y + h, x, y + h, x, y + h - r],
            ["L", x, y + r],
            ["C", x, y, x, y, x + r, y]
        ];

        return path;
    };
    highchartsAccessibility(Highcharts);
    highchartsPatternFill(Highcharts);

    const chartWrapperRef = useRef(null);

    const retireeDetailsRef = useRef(null);
    retireeDetailsRef.current = retireeDetails;

    const numYears = _keys(retireeDetails).length;

    // in mobile, there is a collapsed and expanded view.   > mobile, there is only expanded.
    const [showAllData, setShowAllData] = useState(
        !isPhone ? true : numYears <= NUM_YEARS_TO_SHOW_IN_MOBILE_COLLAPSED_VIEW
    );

    // if the user or spouse has no retirement income for a year in the retireeDetails, the data node will have this
    // structure for a spouse
    //
    //
    // spouse:
    //   ageInRetirement: 57,   // ( or whatever )
    //   incomeGoal: 2500,      // ( or some value)
    //   otherAssets: 0,
    //   socialSecurity: 0
    const retirementIncomeExistsForNode = (node, userType) => {
        if (ObjectUtil.isUndefinedOrNull(node) || _isEmpty(node)) {
            return false;
        }

        // check for spouse with no income goal
        let spouseIncomeGoalZeroHasIncome = false;
        if (
            !!userType &&
            userType === "spouse" &&
            !!node.incomeGoal &&
            parseInt(node.incomeGoal, 10) == 0
        ) {
            if (!!node.retirementAge && parseInt(node.retirementAge, 10) >= spouseRetireAge) {
                if (
                    (!!node.otherAssets && parseInt(node.otherAssets, 10) > 0) ||
                    (!!node.socialSecurity && parseInt(node.socialSecurity, 10) > 0)
                ) {
                    spouseIncomeGoalZeroHasIncome = true;
                }
            }
        }

        if (
            (!!node.otherAssets && parseInt(node.otherAssets, 10) > 0) ||
            (!!node.socialSecurity && parseInt(node.socialSecurity, 10) > 0) ||
            (!!node.mySavings && parseInt(node.mySavings, 10) > 0) ||
            (!!node.hsa && parseInt(node.hsa, 10) > 0) ||
            (!!userType &&
                (userType === "spouse" || userType === "user") &&
                !!node.incomeGoal &&
                parseInt(node.incomeGoal, 10) > 0) ||
            spouseIncomeGoalZeroHasIncome
        ) {
            return true;
        }
        return false;
    };

    const retirementIncomeExistsForSpouse = (retireeData) =>
        _some(_values(retireeData), (detail) => {
            return retirementIncomeExistsForNode(detail.spouse, USER_TYPE_SPOUSE);
        });

    // in mobile, there is an expanded (all data) view and a collapsed (truncated data) view.
    // Also in mobile the sort order of the years is reversed
    const sortAscending = (a, b) => {
        return parseInt(a) - parseInt(b);
    };
    const sortDescending = (a, b) => {
        return parseInt(b) - parseInt(a);
    };
    const yearsExpanded = _map(_keys(retireeDetails), (year) => {
        return parseInt(year);
    }).sort(sortAscending);
    const minYearCollapsed =
        yearsExpanded[yearsExpanded.length - 1] - NUM_YEARS_TO_SHOW_IN_MOBILE_COLLAPSED_VIEW + 1;

    const yearsCollapsed = _filter(_clone(yearsExpanded), (year) => {
        return year >= minYearCollapsed;
    }).sort(sortAscending);

    const getChartWidth = (numColumns) => {
        return (
            numColumns *
                (COLUMN_WIDTH + (isPhone ? COLUMN_PADDING_PHONE : COLUMN_PADDING_NON_PHONE)) +
            CHART_SPACING_LEFT_AND_RIGHT_OF_X_AXIS
        );
    };

    const chartWidthCollapsed = getChartWidth(NUM_YEARS_TO_SHOW_IN_MOBILE_COLLAPSED_VIEW);
    const chartWidthExpanded = getChartWidth(_keys(retireeDetails).length);

    const toggleShowAllData = () => {
        setShowAllData(!showAllData);
    };

    const drawIncomeYearLineMobile = ({
        xAxis,
        renderer,
        userType,
        yearList,
        retirementInitialYear,
        retirementFinalYear
    }) => {
        if (userType !== USER_TYPE_PARTICIPANT && userType !== USER_TYPE_SPOUSE) {
            throw new Error(
                `Unknown user type ${userType}. Expecting either ${USER_TYPE_PARTICIPANT} or ${USER_TYPE_SPOUSE}`
            );
        }

        // constants
        const LINE_WIDTH = 3;
        const CORNER_RADIUS = 1;
        const Z_INDEX = 8;
        const X_POSITION = userType === USER_TYPE_PARTICIPANT ? 0 : 8;
        const FILL_COLOR = userType === USER_TYPE_PARTICIPANT ? PARTICIPANT_COLOR : SPOUSE_COLOR;

        // for mobile the years are shown in reverse chronological order
        const numYearsUntilIncomeStarts = yearList[yearList.length - 1] - retirementFinalYear;
        const numYearsIncomeIsGenerated = retirementFinalYear - retirementInitialYear + 1;
        const numYearsIncomeIsGeneratedDisplayed = showAllData
            ? numYearsIncomeIsGenerated
            : numYearsUntilIncomeStarts + numYearsIncomeIsGenerated >=
                NUM_YEARS_TO_SHOW_IN_MOBILE_COLLAPSED_VIEW
              ? NUM_YEARS_TO_SHOW_IN_MOBILE_COLLAPSED_VIEW - numYearsUntilIncomeStarts
              : numYearsIncomeIsGenerated;

        // variables derived from existing data
        const numYears = showAllData ? yearList.length : NUM_YEARS_TO_SHOW_IN_MOBILE_COLLAPSED_VIEW;
        const col0 = xAxis.series[0].columnMetrics;
        const barWidth = col0.width;
        const widthBetweenBars = (xAxis.len - numYears * barWidth) / numYears;

        const startingYPosition =
            10 + widthBetweenBars / 2 + numYearsUntilIncomeStarts * (barWidth + widthBetweenBars);
        const lineHeight =
            numYearsIncomeIsGeneratedDisplayed * barWidth +
            (numYearsIncomeIsGeneratedDisplayed - 1) * widthBetweenBars;

        if (
            showAllData ||
            (!showAllData && numYearsUntilIncomeStarts < NUM_YEARS_TO_SHOW_IN_MOBILE_COLLAPSED_VIEW)
        ) {
            return renderer
                .rect(X_POSITION, startingYPosition, LINE_WIDTH, lineHeight, CORNER_RADIUS)
                .attr({ fill: FILL_COLOR, zIndex: Z_INDEX })
                .add();
        }
    };

    const drawIncomeYearLineNonMobile = ({
        xAxis,
        renderer,
        userType,
        yearList,
        retirementInitialYear,
        retirementFinalYear
    }) => {
        if (userType !== USER_TYPE_PARTICIPANT && userType !== USER_TYPE_SPOUSE) {
            throw new Error(
                `Unknown user type ${userType}. Expecting either ${USER_TYPE_PARTICIPANT} or ${USER_TYPE_SPOUSE}`
            );
        }

        // constants
        const LINE_HEIGHT = 3;
        const CORNER_RADIUS = 1;
        const Z_INDEX = 8;
        const Y_POSITION = userType === USER_TYPE_PARTICIPANT ? 150 : 156;
        const FILL_COLOR = userType === USER_TYPE_PARTICIPANT ? PARTICIPANT_COLOR : SPOUSE_COLOR;

        // determine the min and max year that contain income for the participant or spouse
        const numYearsUntilIncomeStarts = retirementInitialYear - yearList[0];
        const numYearsIncomeIsGenerated = retirementFinalYear - retirementInitialYear + 1;

        // variables derived from existing data
        const numYears = yearList.length;
        const col0 = xAxis.series[0].columnMetrics;
        const barWidth = col0.width;
        const widthBetweenBars = (xAxis.len - numYears * barWidth) / numYears;

        const startingXPosition =
            xAxis.left +
            widthBetweenBars / 2 +
            numYearsUntilIncomeStarts * (barWidth + widthBetweenBars);
        const lineWidth =
            numYearsIncomeIsGenerated * barWidth +
            (numYearsIncomeIsGenerated - 1) * widthBetweenBars;

        return renderer
            .rect(startingXPosition, Y_POSITION, lineWidth, LINE_HEIGHT, CORNER_RADIUS)
            .attr({ fill: FILL_COLOR, zIndex: Z_INDEX })
            .add();
    };

    const drawIncomeYearLine = (xAxis, renderer, userType, isPhone) => {
        // determine the min and max year that contain income for the participant or spouse
        const yearsWithRetirementIncome = _filter(_keys(retireeDetailsRef.current), (key) => {
            return retirementIncomeExistsForNode(
                retireeDetailsRef.current[key][userType],
                userType
            );
        });

        const retirementInitialYear = _min(
            _map(yearsWithRetirementIncome, (year) => {
                return parseInt(year);
            })
        );
        const retirementFinalYear = _max(
            _map(yearsWithRetirementIncome, (year) => {
                return parseInt(year);
            })
        );

        const yearList = _map(_keys(retireeDetailsRef.current), (year) => {
            return parseInt(year);
        }).sort(sortAscending);

        return isPhone
            ? drawIncomeYearLineMobile({
                  xAxis,
                  renderer,
                  userType,
                  yearList,
                  retirementInitialYear,
                  retirementFinalYear
              })
            : drawIncomeYearLineNonMobile({
                  xAxis,
                  renderer,
                  userType,
                  yearList,
                  retirementInitialYear,
                  retirementFinalYear
              });
    };

    /** Monthly Income Goal */
    const getTotalIncomeForYear = (retireeDetails, year) => {
        return (
            parseFloat(_get(retireeDetails[year], "user.incomeGoal") || 0) +
            parseFloat(_get(retireeDetails[year], "spouse.incomeGoal") || 0)
        );
    };

    const getEmployeeSavings = (retireeDetails, year) => {
        return (
            Number(
                parseFloat(
                    _get(retireeDetails[year], "user.currentEmployeeContributions") || 0
                ).toFixed(2)
            ) +
            Number(
                parseFloat(
                    _get(retireeDetails[year], "user.futureEmployeeContributions") || 0
                ).toFixed(2)
            )
        );
    };

    const getEmployerContribution = (retireeDetails, year) => {
        return (
            Number(
                parseFloat(
                    _get(retireeDetails[year], "user.currentEmployerContributions") || 0
                ).toFixed(2)
            ) +
            Number(
                parseFloat(
                    _get(retireeDetails[year], "user.futureEmployerContributions") || 0
                ).toFixed(2)
            )
        );
    };

    const getTotalMySavings = (retireeDetails, year) => {
        const employeeSavings = getEmployeeSavings(retireeDetails, year);
        const employerContribution = getEmployerContribution(retireeDetails, year);
        return employeeSavings + employerContribution;
    };

    const maxIncomeInAnySingleYear = (retireeDetails) => {
        return _max(
            _map(_keys(retireeDetails), (y) => {
                const total = getTotalIncomeForYear(retireeDetails, y);
                return total;
            })
        );
    };

    const getColorIncomeGap = () => {
        const pattern = {
            pattern: {
                id: "incomegap-diagonal-stripes",
                path: {
                    d: HATCH_PATH
                },
                width: 4,
                height: 4,
                color: "#ccc",
                backgroundColor: "#fff",
                opacity: 1,
                patternTransform: `rotate(-45)`
            }
        };
        return pattern;
    };

    const incomeGapClass = "integrated-incomeGap-color";

    const yearOfBirth = parseInt(DateUtil.getDateFormatted("YYYY", participant.dateOfBirth));

    const maxIncome = maxIncomeInAnySingleYear(retireeDetails);
    const getTickInterval = ({ maxTickersToShow, maxValue }) => {
        let tickerIncrement = undefined;
        if (maxValue < 1000000) {
            tickerIncrement = 1000;
        } else if (maxValue >= 1000000) {
            tickerIncrement = 1000000;
        }
        return Math.ceil(maxValue / ((maxTickersToShow + 1) * tickerIncrement)) * tickerIncrement;
    };

    const createSeries = (yearsToDisplay) => {
        const series = [
            {
                accessibility: {
                    enabled: true,
                    description: translations.chart.incomeGap
                },
                data: _map(yearsToDisplay, (y) => {
                    return parseInt(_get(retireeDetails[y], "gap") || 0);
                }),
                className: "",
                color: getColorIncomeGap(),
                name: translations.chart.incomeGap
            },
            {
                accessibility: {
                    enabled: true,
                    description: translations.chart.otherAssets
                },
                data: _map(yearsToDisplay, (y) => {
                    return parseInt(_get(retireeDetails[y], "user.otherAssets") || 0);
                }),
                className: "otherAssets-color",
                name: translations.chart.otherAssets
            },
            {
                accessibility: {
                    enabled: true,
                    description: translations.chart.spouseOtherAssets
                },
                data: _map(yearsToDisplay, (y) => {
                    return parseInt(_get(retireeDetails[y], "spouse.otherAssets") || 0);
                }),
                color: {
                    pattern: {
                        id: "spouseOtherAssetsPatternId",
                        path: {
                            d: HATCH_PATH
                        },
                        width: HATCH_STRIPE_WIDTH,
                        height: HATCH_STRIPE_WIDTH,
                        color: INTEGRATED_SPOUSE_OTHER_ASSETS_COLOR,
                        backgroundColor: HATCH_BACKGROUND_COLOR,
                        opacity: 1,
                        patternTransform: `rotate(${HATCH_ROTATE_DEGREES})`
                    }
                },
                name: translations.chart.spouseOtherAssets
            },

            {
                accessibility: {
                    enabled: true,
                    description: translations.chart.socialSecurity
                },
                data: _map(yearsToDisplay, (y) => {
                    return parseInt(_get(retireeDetails[y], "user.socialSecurity") || 0);
                }),
                className: "governmentBenefits-color",
                name: translations.chart.socialSecurity
            },
            {
                accessibility: {
                    enabled: true,
                    description: translations.chart.spouseSocialSecurity
                },
                data: _map(yearsToDisplay, (y) => {
                    return parseInt(_get(retireeDetails[y], "spouse.socialSecurity") || 0);
                }),
                color: {
                    pattern: {
                        id: "spouseSocialSecurityPatternId",
                        path: {
                            d: HATCH_PATH
                        },
                        width: HATCH_STRIPE_WIDTH,
                        height: HATCH_STRIPE_WIDTH,
                        color: SPOUSE_SOCIAL_SECURITY_COLOR,
                        backgroundColor: HATCH_BACKGROUND_COLOR,
                        opacity: 1,
                        patternTransform: `rotate(${HATCH_ROTATE_DEGREES})`
                    }
                },
                name: translations.chart.spouseSocialSecurity
            },
            {
                accessibility: {
                    enabled: true,
                    description: translations.chart.hsa
                },
                data: _map(yearsToDisplay, (y) => {
                    return parseInt(_get(retireeDetails[y], "user.hsa") || 0);
                }),
                className: "hsa-color",
                name: translations.chart.hsa
            }
        ];

        const employeeSavings = {
            accessibility: {
                enabled: true,
                description: translations.chart.mySavings
            },
            data: _map(yearsToDisplay, (y) => {
                return y >= yearOfBirth + participant.retirementAge
                    ? parseInt(getEmployeeSavings(retireeDetails, y) || 0)
                    : 0;
            }),
            className: "retirementIncome-color",
            name: translations.chart.mySavings
        };

        const employerContribution = {
            accessibility: {
                enabled: true,
                description: translations.chart.employerContributions
            },
            data: _map(yearsToDisplay, (y) => {
                return y >= yearOfBirth + participant.retirementAge
                    ? parseInt(getEmployerContribution(retireeDetails, y) || 0)
                    : 0;
            }),
            className: "employerContributions-color",
            name: translations.chart.employerContributions
        };
        series.push(employeeSavings, employerContribution);

        return series;
    };

    const createChartOptions = (isPhone, yearsToDisplay, showAllData) => {
        return {
            chart: {
                animation: {
                    duration: 1500
                },
                spacingBottom: isPhone ? PHONE_SPACING_BOTTOM : NON_PHONE_SPACING_BOTTOM,
                spacingLeft: isPhone ? 20 : -30,
                spacingRight: isPhone ? 50 : 0,
                marginLeft: isPhone ? undefined : undefined,
                marginBottom: undefined,
                scrollablePlotArea: isPhone
                    ? undefined
                    : {
                          minWidth: chartWidthExpanded,
                          opacity: 0
                      },
                inverted: isPhone,
                type: "column",
                height: isPhone
                    ? showAllData
                        ? chartWidthExpanded
                        : chartWidthCollapsed
                    : CHART_HEIGHT,
                backgroundColor: "transparent",

                accessibility: {
                    enabled: true,
                    keyboardNavigation: {
                        enabled: true,
                        order: ["series"],
                        focusBorder: {
                            enabled: true,
                            hideBrowserFocusOutline: false
                        }
                    }
                },
                events: {
                    render: function () {
                        const chart = this;

                        // We destroy all custom drawings so that they get updated
                        if (chart.toggleExpandCollapse) {
                            chart.toggleExpandCollapse.destroy();
                        }

                        if (isPhone && numYears > NUM_YEARS_TO_SHOW_IN_MOBILE_COLLAPSED_VIEW) {
                            const expandCollapseGroup = this.renderer
                                .g("toggle-expand-collapse")
                                .attr({ zIndex: 8 })
                                .add();
                            const baselineLength = chart.yAxis[0].left + chart.yAxis[0].len;
                            const baselineY =
                                (showAllData
                                    ? getChartWidth(chart.xAxis[0].categories.length)
                                    : chartWidthCollapsed) -
                                PHONE_SPACING_BOTTOM +
                                5;

                            // line
                            this.renderer
                                .rect(0, baselineY, baselineLength, 2, 1)
                                .attr({ fill: "#686868" })
                                .add(expandCollapseGroup);

                            // gray box
                            const boxX = baselineLength / 2 - 50;
                            this.renderer
                                .rect(boxX, baselineY, 100, 30, 5)
                                .attr({ fill: "#686868" })
                                .on("click", function () {
                                    toggleShowAllData(chart);
                                })
                                .add(expandCollapseGroup);

                            // triangle
                            const triangleX = boxX + 13;
                            const triangleY = baselineY + 11;
                            const triangleLength = 8;

                            const upArrow = [
                                "M",
                                triangleX + triangleLength / 2,
                                triangleY,
                                "L",
                                triangleX + triangleLength,
                                triangleY + triangleLength,
                                "L",
                                triangleX,
                                triangleY + triangleLength,
                                "Z"
                            ];
                            const downArrow = [
                                "M",
                                triangleX,
                                triangleY,
                                "L",
                                triangleX + triangleLength,
                                triangleY,
                                "L",
                                triangleX + triangleLength / 2,
                                triangleY + triangleLength,
                                "Z"
                            ];
                            this.renderer
                                .path(showAllData ? upArrow : downArrow)
                                .attr({ fill: "#FFFFFF" })
                                .on("click", function () {
                                    toggleShowAllData(chart);
                                })
                                .add(expandCollapseGroup);

                            // text
                            const text = showAllData
                                ? translations.chart.collapse
                                : translations.chart.expand;
                            this.renderer
                                .text(text, boxX + 30, baselineY + 20)
                                .attr({ fill: "#FFFFFF", class: "expand-collapse-text" })
                                .on("click", function () {
                                    toggleShowAllData(chart);
                                })
                                .add(expandCollapseGroup);

                            chart.toggleExpandCollapse = expandCollapseGroup;
                        }

                        if (chart.participantHorizontalLine) {
                            chart.participantHorizontalLine.destroy();
                            delete chart.participantHorizontalLine;
                        }

                        if (chart.spouseHorizontalLine) {
                            chart.spouseHorizontalLine.destroy();
                            delete chart.spouseHorizontalLine;
                        }

                        if (retirementIncomeExistsForSpouse(retireeDetailsRef.current)) {
                            chart.participantHorizontalLine = drawIncomeYearLine(
                                this.xAxis[0],
                                this.renderer,
                                USER_TYPE_PARTICIPANT,
                                isPhone
                            );
                            chart.spouseHorizontalLine = drawIncomeYearLine(
                                this.xAxis[0],
                                this.renderer,
                                USER_TYPE_SPOUSE,
                                isPhone
                            );
                        }
                    }
                }
            },
            title: {
                text: null
            },
            credits: {
                enabled: false
            },
            xAxis: {
                type: "linear",
                accessibility: {
                    description: "Years of retirement",
                    enabled: true
                },
                categories: showAllData
                    ? isPhone
                        ? yearsExpanded.sort(sortDescending)
                        : yearsExpanded
                    : yearsCollapsed.sort(sortDescending),
                showLastLabel: true,
                tickWidth: 0,
                tickmarkPlacement: "on",
                labels: {
                    formatter: function () {
                        return this.value === _max(yearsExpanded)
                            ? this.value + "+"
                            : String(this.value);
                    },
                    align: "center",
                    rotation: 0,
                    step: 1,
                    padding: 20
                }
            },
            yAxis: {
                min: 0,
                stackLabels: {
                    enabled: false
                },
                tickInterval: getTickInterval({ maxTickersToShow: 6, maxValue: maxIncome }),
                labels: {
                    enabled: true
                },
                title: {
                    // setting the title text to null for some reason messes up the labels in > phone
                    // by aligning it left we effectively hide it.
                    align: "left",
                    text: isPhone ? null : "Values"
                }
            },
            tooltip: {
                shape: "customTooltipCallout",
                enabled: true,
                followPointer: false,
                followTouchMove: false,
                shared: true,
                backgroundColor: "black",
                borderColor: "black",
                className: "rivd-pre-retiree-tooltip integrated-rivd-pre-retiree-tooltip",
                borderRadius: 20,
                outside: true,
                useHTML: true,
                padding: isTabletOrSmaller ? 60 : isMidResolution ? 15 : 8,
                style: {
                    color: "white"
                },

                positioner: function (tooltipWidth, tooltipHeight, point) {
                    const bodyRect = document.body.getBoundingClientRect();
                    const chartWrapperRect = chartWrapperRef.current.getBoundingClientRect();

                    const offsetY = chartWrapperRect.top - bodyRect.top;

                    const chart = this.chart;

                    if (!isPhone) {
                        return {
                            x: point.plotX - tooltipWidth / 2 - 10,
                            y: point.plotY - tooltipHeight
                        };
                    }

                    // ELSE PHONE

                    // In mobile, the tooltipWidth comes in 84 pixels too wide.
                    const TOOLTIP_EXTRA_WIDTH = 84;

                    const y = offsetY + point.plotY + chart.plotTop - tooltipHeight;

                    // highcharts adds different padding based on the height of the tooltip (looks like a bug),
                    // so we have to adjust the final y value accordingly.
                    const getFinalYAdjustmentForPhone = (tooltipHeight) => {
                        // The max tooltip height was determined by giving a participant and spouse
                        // every type of retirement income.   The min tooltip height was
                        // determined by giving just the participant a single type.   The adjustments were then
                        // determined by trial and error until the tooltip lined up just right.
                        const MAX_TOOLTIP_HEIGHT = 367;
                        const MIN_TOOLTIP_HEIGHT = 235;
                        const MAX_Y_ADJUSTMENT = 36;
                        const MIN_Y_ADJUSTMENT = 16;

                        // every time input grows by one, the output grows by this amount
                        const increment =
                            (MAX_Y_ADJUSTMENT - MIN_Y_ADJUSTMENT) /
                            (MAX_TOOLTIP_HEIGHT - MIN_TOOLTIP_HEIGHT);

                        return MIN_Y_ADJUSTMENT + increment * (tooltipHeight - MIN_TOOLTIP_HEIGHT);
                    };

                    return {
                        x:
                            chart.axisOffset[3] +
                            point.plotX / 2 -
                            (tooltipWidth - TOOLTIP_EXTRA_WIDTH) / 2,
                        y: y + getFinalYAdjustmentForPhone(tooltipHeight)
                    };
                },

                formatter: function () {
                    const year = this.points[0].x;

                    const participantIncomeExists =
                        retirementIncomeExistsForNode(
                            retireeDetails[year].user,
                            USER_TYPE_PARTICIPANT
                        ) &&
                        ((year >= yearOfBirth + participant.retirementAge
                            ? parseFloat(getTotalMySavings(retireeDetails, year) || 0)
                            : 0) > 0 ||
                            parseFloat(_get(retireeDetails[year], "user.socialSecurity") || 0) >
                                0 ||
                            parseFloat(_get(retireeDetails[year], "user.hsa") || 0) > 0 ||
                            (userOtherAssets > 0 || year >= yearOfBirth + participant.retirementAge
                                ? parseFloat(_get(retireeDetails[year], "user.otherAssets") || 0)
                                : 0) > 0);

                    const spouseIncomeExists = retirementIncomeExistsForNode(
                        retireeDetails[year].spouse,
                        USER_TYPE_SPOUSE
                    );

                    // If no income, do not show the tooltip
                    // This can happen if the year of the participant's plan-to-age is less than the year that the
                    // spouse plans to retire.   In this case there may be a few years in between when there is no income
                    if (!participantIncomeExists && !spouseIncomeExists) {
                        return false;
                    }

                    const participantClass =
                        "participant" +
                        (spouseIncomeExists ? " both-participant-and-spouse-income-exists" : "");
                    const spouseClass =
                        "spouse" +
                        (participantIncomeExists
                            ? " both-participant-and-spouse-income-exists"
                            : "");

                    const tooltipBodyClass =
                        "tooltip-body " +
                        (isTabletOrSmaller ||
                        (!isScreenWideEnoughToShowTwoColumnsInTooltip &&
                            parseInt(year) <
                                parseInt(yearsToDisplay[0]) +
                                    NUM_LEFT_COLUMNS_TO_ALWAYS_STACK_VERTICALLY)
                            ? " stack-vertically"
                            : participantIncomeExists && spouseIncomeExists
                              ? " both-participant-and-spouse-income-exists stack-horizontally"
                              : "stack-vertically");

                    const wrapperClass =
                        "inner-wrapper" +
                        (isTabletOrSmaller ||
                        (!isScreenWideEnoughToShowTwoColumnsInTooltip &&
                            parseInt(year) <
                                parseInt(yearsToDisplay[0]) +
                                    NUM_LEFT_COLUMNS_TO_ALWAYS_STACK_VERTICALLY)
                            ? " stack-vertically"
                            : participantIncomeExists && spouseIncomeExists
                              ? " both-participant-and-spouse-income-exists  stack-horizontally"
                              : "stack-vertically");

                    const participantColor = spouseIncomeExists ? PARTICIPANT_COLOR : "white";
                    const spouseColor = participantIncomeExists ? SPOUSE_COLOR : "white";

                    const getParticipantRow = (classKey, label, amount) => {
                        return amount > 0
                            ? `
                        <div class="pre-retiree-tooltip-row">
                            <span>
                                <svg version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    class="swatch"
                                >
                                    <rect class="${classKey}-color" width="10" height="10"></rect>
                                </svg>
                                <span class="income-type-label">${label}:</span>
                            </span>
                            <span class="amount">${formatUsDollarAmount(amount)}</span>
                        </div>
                    `
                            : ``;
                    };

                    const getSpouseRow = (patternId, label, amount) => {
                        return amount > 0
                            ? `
                            <div class="pre-retiree-tooltip-row">
                                <span>
                                    <svg version="1.1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        class="swatch"
                                    >
                                        <rect
                                            width="10"
                                            height="10"
                                            fill="url(#${patternId})"
                                        />
                                    </svg>
                                    <span class="income-type-label">${label}:</span>
                                </span>
                                <span class="amount">${formatUsDollarAmount(amount)}</span>
                            </div>
                        `
                            : ``;
                    };

                    const userHsaRow = getParticipantRow(
                        "hsa",
                        translations.chart.hsa,
                        parseFloat(_get(retireeDetails[year], "user.hsa") || 0)
                    );
                    const userOtherAssetsRow = getParticipantRow(
                        "otherAssets",
                        translations.chart.otherAssets,
                        parseFloat(_get(retireeDetails[year], "user.otherAssets") || 0)
                    );
                    const userSocialSecurityRow = getParticipantRow(
                        "governmentBenefits",
                        translations.chart.socialSecurity,
                        parseFloat(_get(retireeDetails[year], "user.socialSecurity") || 0)
                    );
                    const userMySavingsRow = getParticipantRow(
                        "retirementIncome",
                        translations.chart.mySavings,
                        parseFloat(getTotalMySavings(retireeDetails, year) || 0)
                    );
                    const userAge =
                        parseInt(_get(retireeDetails[year], "user.ageInRetirement")) || "-";

                    const spouseOtherAssetsRow = getSpouseRow(
                        "spouseOtherAssetsPatternId",
                        translations.chart.otherAssets,
                        parseFloat(_get(retireeDetails[year], "spouse.otherAssets") || 0)
                    );
                    const spouseSocialSecurityRow = getSpouseRow(
                        "spouseSocialSecurityPatternId",
                        translations.chart.socialSecurity,
                        parseFloat(_get(retireeDetails[year], "spouse.socialSecurity") || 0)
                    );
                    const spouseAge =
                        parseInt(_get(retireeDetails[year], "spouse.ageInRetirement")) || "-";

                    const userHsa = parseFloat(_get(retireeDetails[year], "user.hsa") || 0);
                    const userOtherAssets = parseFloat(
                        _get(retireeDetails[year], "user.otherAssets") || 0
                    );
                    const userSocialSecurity = parseFloat(
                        _get(retireeDetails[year], "user.socialSecurity") || 0
                    );

                    const participantSection = participantIncomeExists
                        ? `
                        <div class="${participantClass}">
                            ${
                                year >= yearOfBirth + participant.retirementAge ||
                                userSocialSecurity > 0 ||
                                userOtherAssets > 0 ||
                                userHsa > 0
                                    ? `<div class="pre-retiree-tooltip-row table-header">
                                <span>
                                    ${participant.firstName}
                                </span>
                                <span>
                                    ${translations.chart.age}<span class="age">${userAge}</span>
                                </span>
                            </div>
                            <div class="pre-retiree-tooltip-row">
                                <hr style="border-top: 2px solid ${participantColor}"></hr>
                            </div>`
                                    : ``
                            }

                            ${
                                userOtherAssets > 0 ||
                                year >= yearOfBirth + participant.retirementAge
                                    ? userOtherAssetsRow
                                    : ""
                            }
                            ${userSocialSecurityRow}
                            ${userHsaRow}
                            ${
                                year >= yearOfBirth + participant.retirementAge
                                    ? userMySavingsRow
                                    : ""
                            }
                        </div>`
                        : ``;
                    const spouseSection = spouseIncomeExists
                        ? `
                        <div class="${spouseClass}">
                            <div class="pre-retiree-tooltip-row table-header">
                                <span>
                                    ${spouse.firstName}
                                </span>
                                <span>
                                    ${translations.chart.age}<span class="age">${spouseAge}</span>
                                </span>
                            </div>
                            <div class="pre-retiree-tooltip-row">
                            <hr style="border-top: 2px solid ${spouseColor}"></hr>
                            </div>
                            ${spouseOtherAssetsRow}
                            ${spouseSocialSecurityRow}
                        </div>`
                        : ``;

                    return `
                        <div class="${wrapperClass}" style='width: ${spouseIncomeExists && participantIncomeExists ? 406 : 193}px; height: 151px'>
                            <div class="header">
                                <span>
                                    <span class="total-amount">${formatUsDollarAmount(
                                        getTotalIncomeForYear(retireeDetails, year)
                                    )}</span>
                                    ${termLabel}
                                    <span class="year">${year}</span>
                                </span>
                            </div>
                            <div class="income-gap">
                                <svg version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="10"
                                    height="10"
                                    viewBox="0 0 10 10"
                                    class="swatch"
                                >
                                    <rect class=${incomeGapClass} width="10" height="10"></rect>
                                </svg>
                                <span class="income-gap-label">${
                                    translations.chart.incomeGap
                                }:</span>
                                <span class="amount">${formatUsDollarAmount(
                                    parseFloat(_get(retireeDetails[year], "gap") || 0).toFixed(2)
                                )}</span>

                            </div>
                            <div class="${tooltipBodyClass}">
                                ${participantSection}
                                ${spouseSection}
                            </div>
                        </div>`;
                }
            },
            plotOptions: {
                column: {
                    stacking: "normal",
                    animation: false,
                    dataLabels: {
                        enabled: false
                    },
                    borderWidth: 0,
                    pointWidth:
                        yearsExpanded.length > NUM_YEARS_WHEN_COLUMN_WIDTH_BECOMES_FIXED || isPhone
                            ? COLUMN_WIDTH
                            : undefined,
                    pointPadding: 0.1
                }
            },
            legend: {
                enabled: false,
                floating: false
            },
            series: createSeries(yearsToDisplay)
        };
    };

    const chartOptionsExpanded = createChartOptions(
        isPhone,
        isPhone ? yearsExpanded.reverse() : yearsExpanded,
        true
    );
    const chartOptionsCollapsed = createChartOptions(isPhone, yearsCollapsed.reverse(), false);

    const chartOptionsExpandedJustYAxisClone = _cloneDeep(chartOptionsExpanded);
    const chartOptionsExpandedJustYAxis = {
        ...chartOptionsExpandedJustYAxisClone,
        chart: {
            ...chartOptionsExpandedJustYAxisClone.chart,
            marginLeft: isPhone ? undefined : 50,
            scrollablePlotArea: undefined,
            events: {}
        }
    };

    const chartOptionsExpandedExceptYAxisClone = _cloneDeep(chartOptionsExpanded);
    const chartOptionsExpandedExceptYAxis = {
        ...chartOptionsExpandedExceptYAxisClone,
        yAxis: {
            ...chartOptionsExpandedExceptYAxisClone.yAxis,
            labels: { ...chartOptionsExpandedExceptYAxisClone.yAxis.labels, enabled: false }
        }
    };

    const highChartYears = Object.keys(retireeDetails);
    const highChartYearsIncomeGaps = Object.values(retireeDetails);

    return (
        retireeDetails && (
            <div
                ref={chartWrapperRef}
                className={
                    "chart-wrapper" +
                    (retirementIncomeExistsForSpouse(retireeDetails) ? " spouse-income" : "")
                }
            >
                <HiddenA11yWrapper id={ACCESSIBILITY.HOW_LONG_WILL_MY_MONEY_LAST_HIGHCHART_INFO_ID}>
                    Interactive Highchart Info.
                    {highChartYears[0]} to {highChartYears[highChartYears.length - 2]} Income Gap
                    {highChartYearsIncomeGaps[0]?.gap}, and for
                    {highChartYears[highChartYears.length - 1]} year onwards Income Gap
                    {highChartYearsIncomeGaps[highChartYearsIncomeGaps.length - 1]?.gap},
                </HiddenA11yWrapper>
                {/*
                In order to get the scrollbar to start to the right of the y axis
                and have the y axis be fixed, we create 2 charts.   The first has only the y axis.
                The second has everything but the y axis and then we overlay the second over the first.
                */}
                {!isPhone && (
                    <div className="chart-wrapper-y-axis-merge">
                        <div className="chart-only-y-axis" style={{ marginLeft: -10 }}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={chartOptionsExpandedJustYAxis}
                                allowChartUpdate={true}
                            />
                        </div>
                        <div className="chart-except-y-axis">
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={chartOptionsExpandedExceptYAxis}
                                allowChartUpdate={true}
                            />
                        </div>
                    </div>
                )}
                {isPhone && showAllData && (
                    <div key="expanded-chart">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptionsExpanded}
                            allowChartUpdate={true}
                        />
                    </div>
                )}
                {isPhone && !showAllData && (
                    <div key="collapsed-chart">
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={chartOptionsCollapsed}
                            allowChartUpdate={true}
                        />
                    </div>
                )}
            </div>
        )
    );
};
PreRetireeChart.propTypes = {
    retireeDetails: PropTypes.array,
    spouseRetireAge: PropTypes.number
};
export default PreRetireeChart;
