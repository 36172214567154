import {
    SET_TOP_PEERS,
    SET_HOW_DO_I_COMPARE_SALARY_GROUP,
    SET_HOW_DO_I_COMPARE_GENDER_GROUP,
    SET_HOW_DO_I_COMPARE_KEY_AND_SCORE,
    SET_HDIC_ME_BALANCE,
    SET_HDIC_ME_CONTRIBUTION_RATE,
    CHANGE_HDIC_AGE_FILTER,
    CHANGE_HDIC_SALARY_FILTER,
    CHANGE_HDIC_GENDER_FILTER,
    SET_HDIC_ERROR,
    HAS_TOP_PEERS_FOCUS,
    SET_HOW_DO_I_COMPARE
} from "../../actions/howDoICompare/howDoICompareTypes";

const initialState = {
    ageGroup: {},
    ageGroups: [],
    details: { roth: 0, preTaxBonus: 0, catchUp: 0 },
    genderGroup: {},
    genderList: [],
    me: { percentageOfGoal: 0.6, contributionRate: 0.01, balance: 0 },
    myPeers: { percentageOfGoal: 0.1, contributionRate: 0.3, balance: 40000 },
    salaryGroup: {},
    salaryGroups: [],
    score: {
        medBalance: 0,
        medContRate: 0,
        medLis: 0,
        medLisNoSS: 0,
        topBalance: 0,
        topContRate: 0,
        topLis: 0,
        topLisNoSS: 0
    },
    scoreMapKey: "",
    scores: [],
    topPeers: { percentageOfGoal: 0.85, contributionRate: 0.12, balance: 150000 },
    warnThreshold: 0,
    yieldThreshold: 0,
    errorOccurred: false,
    hasTopPeersFocus: false,
    howDoICompareLoaded: false
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    let newScoreObj = { ...state.score };
    switch (type) {
        case SET_HOW_DO_I_COMPARE:
            return { ...state, howDoICompareLoaded: true, ...payload };

        case SET_HOW_DO_I_COMPARE_SALARY_GROUP:
            return { ...state, salaryGroup: payload };
        case SET_HOW_DO_I_COMPARE_GENDER_GROUP:
            return { ...state, genderGroup: payload };
        case SET_HOW_DO_I_COMPARE_KEY_AND_SCORE:
            return { ...state, score: payload.score, scoreMapKey: payload.key };
        case SET_TOP_PEERS:
            return { ...state, topPeers: payload };

        case SET_HDIC_ME_BALANCE:
        case SET_HDIC_ME_CONTRIBUTION_RATE:
            return { ...state, me: { ...state.me, ...payload } };
        case CHANGE_HDIC_AGE_FILTER:
            newScoreObj = state.scores[payload.scoreMapKey];
            return {
                ...state,
                scoreMapKey: payload.scoreMapKey,
                ageGroup: payload.newGroup,
                score: newScoreObj
            };
        case CHANGE_HDIC_SALARY_FILTER:
            newScoreObj = state.scores[payload.scoreMapKey];
            return {
                ...state,
                scoreMapKey: payload.scoreMapKey,
                salaryGroup: payload.newGroup,
                score: newScoreObj
            };
        case CHANGE_HDIC_GENDER_FILTER:
            newScoreObj = state.scores[payload.scoreMapKey];
            return {
                ...state,
                scoreMapKey: payload.scoreMapKey,
                genderGroup: payload.newGroup,
                score: newScoreObj
            };
        case SET_HDIC_ERROR:
            return {
                ...state,
                errorOccurred: true,
                howDoICompareLoaded: true
            };
        case HAS_TOP_PEERS_FOCUS:
            return {
                ...state,
                hasTopPeersFocus: payload
            };
        default:
            return state;
    }
};
