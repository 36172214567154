import { isNil as _isNil } from "lodash";

import {
    isParticipantInvestorId,
    participantHasSpouse,
    getMatchingAcctAggIds
} from "../../selectors/otherAssetsSelectors";
import { updateProjectedIncome } from "../projectedIncome/projectedIncomeActions";
import { showLoader as setShowLoader } from "../shared/sharedActionCreators";

import {
    LOAD_OTHER_ASSETS_REF_DATA,
    SPLIT_OTHER_ASSETS_BY_EMPLOYER,
    CREATE_OTHER_ASSET_SUCCESS,
    UPDATE_OTHER_ASSET_SUCCESS,
    DELETE_OTHER_ASSET_SUCCESS,
    CREATE_MIGRATED_RETIREMENT_ASSET,
    CREATE_MIGRATED_SPOUSE_RETIREMENT_ASSET,
    UPDATE_MIGRATED_RETIREMENT_ASSET,
    UPDATE_MIGRATED_SPOUSE_RETIREMENT_ASSET,
    DELETE_MIGRATED_RETIREMENT_ASSET,
    DELETE_MIGRATED_SPOUSE_RETIREMENT_ASSET,
    CREATE_MIGRATED_EXTERNAL_ASSET,
    CREATE_MIGRATED_SPOUSE_EXTERNAL_ASSET,
    DELETE_MIGRATED_EXTERNAL_ASSETS,
    DELETE_MIGRATED_SPOUSE_EXTERNAL_ASSETS,
    SAVE_TERMS_OF_SERVICE,
    CALL_FOR_AA_TERMS_OF_SERVICE,
    SET_HIDE_I_WANT_TO_LINKS
} from "./otherAssetsTypes";

export const ACTION_UPDATE = "UPDATE";
export const ACTION_DELETE = "DELETE";

export const loadOtherAssetsRefData = (otherAssetsRefData) => {
    return {
        type: LOAD_OTHER_ASSETS_REF_DATA,
        otherAssetsRefData
    };
};

export const createOtherAssetSuccess = (result) => {
    return {
        type: CREATE_OTHER_ASSET_SUCCESS,
        payload: result.data
    };
};

export const updateOtherAssetSuccess = (result) => {
    return {
        type: UPDATE_OTHER_ASSET_SUCCESS,
        payload: result.data
    };
};

export const deleteOtherAssetSuccess = (result) => {
    return {
        type: DELETE_OTHER_ASSET_SUCCESS,
        payload: result.data
    };
};

export const addExternalAssets = (payload) => {
    return (disptach, getState) => {
        const { participant, spouse } = getState();
        const isParticipant = isParticipantInvestorId(participant, payload.investorId);

        if (isParticipant) {
            disptach({ type: CREATE_MIGRATED_EXTERNAL_ASSET, payload });
        } else if (participantHasSpouse(spouse)) {
            disptach({ type: CREATE_MIGRATED_SPOUSE_EXTERNAL_ASSET, payload });
        }
    };
};

export const splitAssetsUpdateProjectionsCloseLoader = () => {
    return (dispatch) => {
        dispatch(splitOtherAssetsByEmployer());
        dispatch(updateProjectedIncome());
        dispatch(setShowLoader(false));
    };
};

export const splitOtherAssetsByEmployer = () => {
    return (dispatch, getState) => {
        const { primaryPlan, participant, spouse } = getState();
        dispatch({
            type: SPLIT_OTHER_ASSETS_BY_EMPLOYER,
            primaryPlan,
            participant,
            spouse,
            storeState: getState()
        });
    };
};

export const createMigratedRetirementAssetAction = function (payload) {
    return (dispatch, getState) => {
        const { participant, spouse } = getState();
        const isParticipant = isParticipantInvestorId(participant, payload.investorId);

        if (isParticipant) {
            dispatch({
                type: CREATE_MIGRATED_RETIREMENT_ASSET,
                payload
            });
        } else if (participantHasSpouse(spouse)) {
            dispatch({ type: CREATE_MIGRATED_SPOUSE_RETIREMENT_ASSET, payload });
        }
    };
};

export const updateMigratedRetirementAssetAction = function (payload) {
    return (dispatch, getState) => {
        const { participant, spouse } = getState();
        const isParticipant = isParticipantInvestorId(participant, payload.investorId);
        if (isParticipant) {
            dispatch({
                type: UPDATE_MIGRATED_RETIREMENT_ASSET,
                payload
            });
            dispatch({ type: DELETE_MIGRATED_SPOUSE_RETIREMENT_ASSET, payload });
        } else if (participantHasSpouse(spouse)) {
            dispatch({ type: UPDATE_MIGRATED_SPOUSE_RETIREMENT_ASSET, payload });
            dispatch({
                type: DELETE_MIGRATED_RETIREMENT_ASSET,
                payload
            });
        }
    };
};

export const deleteMigratedRetirementAssetAction = function (payload) {
    return (dispatch, getState) => {
        const { participant, spouse } = getState();
        const isParticipant = isParticipantInvestorId(participant, payload.investorId);

        if (isParticipant) {
            dispatch({
                type: DELETE_MIGRATED_RETIREMENT_ASSET,
                payload
            });
        } else if (participantHasSpouse(spouse)) {
            dispatch({ type: DELETE_MIGRATED_SPOUSE_RETIREMENT_ASSET, payload });
        }
    };
};

export const handleDeleteExternalAsset = (payload) => {
    return (dispatch, getState) => {
        const { participant, spouse } = getState();

        const isParticipant = isParticipantInvestorId(participant, payload.investorId);
        if (isParticipant) {
            dispatch({ type: DELETE_MIGRATED_EXTERNAL_ASSETS, payload });
        } else if (participantHasSpouse(spouse)) {
            dispatch({
                type: DELETE_MIGRATED_SPOUSE_EXTERNAL_ASSETS,
                payload
            });
        }
    };
};

export const saveLinkedAccountPreferencesAction = (accountList) => {
    return (dispatch, getState) => {
        const { otherAssets, participant, spouse } = getState();
        const allExternalAssets = otherAssets.tableAssets;
        //run through list of linked accounts and statuses
        accountList.map((account) => {
            //if status is fail, nothing was saved.  don't change
            if (account.userActionStatus !== "FAIL") {
                const matchAcctAggrIds = getMatchingAcctAggIds(
                    account.acctAggrId,
                    allExternalAssets
                );
                if (_isNil(matchAcctAggrIds)) {
                    return;
                }
                matchAcctAggrIds.acctAggrStatus = account.acctAggrStatus;

                const isParticipant = isParticipantInvestorId(
                    participant,
                    matchAcctAggrIds.investorId
                );
                let userExternalAssets = participant.externalAssets;

                if (!isParticipant && spouse.exists) {
                    userExternalAssets = spouse.externalAssets;
                }

                const checkIfAssetExists = userExternalAssets.some(
                    (asset) => asset.id === matchAcctAggrIds.id
                );

                if (account.acctAggrStatus === "DELETED") {
                    dispatch(handleDeleteExternalAsset(matchAcctAggrIds));
                } else {
                    if (!checkIfAssetExists) {
                        dispatch(addExternalAssets(matchAcctAggrIds));
                    }
                }
            }
        });

        dispatch(splitAssetsUpdateProjectionsCloseLoader());
    };
};

export const saveTermsOfServiceAction = (payload) => {
    return {
        type: SAVE_TERMS_OF_SERVICE,
        payload
    };
};

export const callForAATermsOfServiceAction = (payload) => {
    return {
        type: CALL_FOR_AA_TERMS_OF_SERVICE,
        payload
    };
};

export const setHideIWantToLinks = (payload) => {
    return {
        type: SET_HIDE_I_WANT_TO_LINKS,
        payload
    };
};
