import ReferenceData from "../../../../constants/ReferenceData";

const checkAgeIndx = (retireAge: number, indx: number) => {
    if (
        retireAge === ReferenceData.MIN_RETIREMENT_AGE &&
        (indx === 0 || indx === 1 || indx === 2)
    ) {
        return "wcir-hide-ticker";
    }
    if (
        retireAge === ReferenceData.MAX_RETIREMENT_AGE &&
        (indx === 4 || indx === 5 || indx === 6)
    ) {
        return "wcir-hide-ticker";
    }
    return "";
};

export default checkAgeIndx;
