import React, { useEffect, useRef } from "react";

import PropTypes from "prop-types";

const FocusLock = ({ children }) => {
    const rootNode = useRef(null);
    const focusableItems = useRef([]);
    const TAB_KEY = "Tab";

    useEffect(() => {
        const updateFocusableItems = () => {
            focusableItems.current = rootNode.current.querySelectorAll(
                'a[href], button:not([disabled]), textarea, input, select, [tabindex]:not([tabindex="-1"])'
            );
        };

        const observer = new MutationObserver(() => {
            updateFocusableItems();
        });
        updateFocusableItems();
        observer.observe(rootNode.current, { childList: true });
        return () => {
            observer.disconnect();
        };
    });

    useEffect(() => {
        const handleKeydownFocusLock = (event) => {
            if (!["Shift", "Tab"].includes(event.key)) return;
            if (!focusableItems.current) return;

            const { key, shiftKey } = event;
            const { length, 0: firstItem, [length - 1]: lastItem } = focusableItems.current;

            if (key === TAB_KEY) {
                // If only one item then prevent tabbing when locked
                if (length === 1) {
                    event.preventDefault();
                    return;
                }

                // If focused on last item then focus on first item when tab is pressed
                if (!shiftKey && document.activeElement === lastItem) {
                    event.preventDefault();
                    firstItem.focus();
                    return;
                }

                // If focused on first item then focus on last item when shift + tab is pressed
                if (shiftKey && document.activeElement === firstItem) {
                    event.preventDefault();
                    lastItem.focus();
                }
            }
        };

        let rootNodeRefValue = null;

        if (rootNode && rootNode.current) {
            rootNode.current.addEventListener("keydown", handleKeydownFocusLock);
            rootNodeRefValue = rootNode.current;
            return () => {
                if (rootNode && rootNodeRefValue) {
                    rootNodeRefValue.removeEventListener("keydown", handleKeydownFocusLock);
                }
            };
        } else {
            return null;
        }
    });

    return <div ref={rootNode}>{children}</div>;
};

FocusLock.propTypes = {
    children: PropTypes.object
};
export default FocusLock;
