const root = "ma.SocialSecurityEvent.";

export const SSEvents = {
    INPUT_CHANGE: root + "social_security_input_change_event",

    OPEN_SOCIAL_SECURITY_ESTIMATE_MODAL:
        root + "social_security_open_social_security_estimate_modal",
    CLOSE_SOCIAL_SECURITY_ESTIMATE_MODAL:
        root + "social_security_close_social_security_estimate_modal",

    SAVE_BUTTON_CLICKED: root + "social_security_save_button_clicked_event",
    CANCEL_BUTTON_CLICKED: root + "social_security_cancel_button_clicked_event",
    MODIFY_ASSUMPTIONS_BUTTON_CLICKED:
        root + "social_security_modify_assumptions_button_clicked_event",
    UPDATED_START_AGE: root + "start_age_altered_event",
    ADMINISTRATION_LINK_CLICK: root + "administration_link_click_event"
};
