export const mtrGoalModalEvents = {
    TOGGLE_MODAL: "toggle_modal_event",
    TAB_SWITCH: "switch_tabs_event",

    // User events
    UPDATE_INCOME: "update_income_event",
    UPDATE_ADDITIONAL_INCOME: "update_additional_income_event",
    UPDATE_DOB: "update_dob_event",
    UPDATE_PORTION: "update_portion_event",

    // Spouse events
    UPDATE_SPOUSE_NAME: "update_spouse_name_event",
    UPDATE_SPOUSE_INCOME: "update_spouse_income_event",
    UPDATE_SPOUSE_DOB: "update_spouse_dob_event",
    UPDATE_SPOUSE_GENDER: "update_spouse_gender_event",
    UPDATE_RET_AGE: "update_spouse_ret_age_event",
    UPDATE_SPOUSE_PORTION: "update_spouse_portion_event",
    DELETE_SPOUSE: "delete_spouse_event",

    TOGGLE_TOOLTIP: "tooltip_toggle_event",
    UPDATE_VIEW: "update_view_event",
    SAVE_CHANGES: "save_updates_event",
    CANCEL_CHANGES: "cancel_updates_event"
};
