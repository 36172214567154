import StringUtil from "core-ui/client/src/app/StringUtil";

import { executeGet } from "../utils/apiUtils.js";
import { getUrl } from "../utils/urlUtils.js";

export const fetchIsLockheed = (planList) => {
    const fetchDataResultSuccess = (response) => response.data;

    const plan = planList[0];
    const groupId = plan.planId ? plan.planId : plan.id;
    const service = "getLockheedQualifiedPlanNumber";
    const url = StringUtil.supplant(getUrl(service), { groupId });

    return executeGet(url, "fetchIsLockheedDataFault")
        .then(fetchDataResultSuccess)
        .catch(() => {});
};

export const loadTermsOfService = () => {};
