import React from "react";

const CompanyMatchMessage = ({ matchList }) => {
    return matchList.map((match) => {
        return (
            <li key={match} className="company-match-message">
                <i className="fa fa-solid fa-circle-check gray-color-400" />
                <span className="company-match-text">{match}</span>
            </li>
        );
    });
};

export { CompanyMatchMessage };
