import axios from "axios";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";

import RequestFactory from "../factories/RequestFactory";
import { handleFault } from "../utils/errorUtils";
import { getUrl } from "../utils/urlUtils";

const logger = ExternalLogger.getInstance("userMessagesService");

export const getUserMessages = (csrf) => {
    const service = "getUserMessages";
    const url = getUrl(service);
    const method = RequestFactory.HTTP_METHOD.POST;

    const formData = new FormData();
    formData.append("csrf", csrf);

    return axios({
        method: method,
        url: url,
        data: formData,
        withCredentials: true
    })
        .then((response) => {
            if (response && response.data) {
                return response.data.spData;
            }
        })
        .catch((err) => {
            logger.error(method + "getUserMessages Service Error: {0}", [err.message]);
            return handleFault(err, "getUserMessagesFault");
        });
};

export const updateUserMessages = (data) => {
    const service = "updateUserMessages";
    const url = getUrl(service);
    const method = RequestFactory.HTTP_METHOD.POST;

    const formData = new FormData();
    formData.append("csrf", window.csrf);
    formData.append("userMessageIds", JSON.stringify(data.userMessageIds));
    formData.append("actions", JSON.stringify(data.actions));

    return axios({
        method: method,
        url: url,
        data: formData,
        withCredentials: true
    }).catch((err) => {
        logger.error(method + "updateUserMessages Service Error: {0}", [err.message]);
        return handleFault(err, "updateUserMessagesFault");
    });
};
