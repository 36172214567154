/**
 * @name app.common.model.domain.BalanceInfo
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Definition of the BalanceInfo domain model object.
 */

"use strict";
const BalanceInfo = function (config) {
    this.totalBalance = 0;
    this.vestedBalance = 0;
    this.vestedPercent = 0;
    this.description = "";
    this.moneyType = "";
    this.taxStatus = "";

    if (config) {
        this.totalBalance = config.totalBalance;
        this.vestedBalance = config.vestedBalance;
        this.vestedPercent = config.vestedPercent;
        this.description = config.description;
        this.moneyType = config.moneyType;
        this.taxStatus = config.taxStatus;
    }
};

export default BalanceInfo;
