export const SET_HEALTH_VIEW_DATA = "SET_HEALTH_VIEW_DATA";
export const SET_HEALTH_CONDITION = "SET_HEALTH_CONDITION";
export const SET_SELECTED_RETIREMENT_STATE_CODE = "SET_SELECTED_RETIREMENT_CODE";
export const RESET_PENDING_HEALTHCARE_CHANGES = "RESET_PENDING_HEALTHCARE_CHANGES";
export const SET_ATTAINED_AGE = "SET_ATTAINED_AGE";
export const SET_SHOW_LOADER = "SET_SHOW_LOADER";
export const SET_HEALTH_VIEW_ERROR = "SET_HEALTH_VIEW_ERROR";

export const changeHealthCondition = (condition) => {
    return {
        type: SET_HEALTH_CONDITION,
        payload: condition
    };
};

export const setRetirementState = (state) => {
    return {
        type: SET_SELECTED_RETIREMENT_STATE_CODE,
        payload: state.stateCode
    };
};

export const resetPendingChanges = () => {
    return {
        type: RESET_PENDING_HEALTHCARE_CHANGES
    };
};

export const setAttainedAge = (age) => {
    return {
        type: SET_ATTAINED_AGE,
        payload: age
    };
};

//@TODO - REFACTOR AND SET A globalThis LOADER
export const setShowLoader = (payload) => {
    return {
        type: SET_SHOW_LOADER,
        payload
    };
};

export const setHealthViewError = () => {
    return {
        type: SET_HEALTH_VIEW_ERROR,
        payload: {}
    };
};
