/**
 *
 * @class common.factory.InitDataFactory
 * @memberOf common.factory
 * @date 01/01/2015
 * @copyright 2015 Empower Retirement
 * @description Creates the client-side model from the raw `getInitData()` service API's JSON response.
 *
 */

import { ObjectUtil } from "gw-shared-components";
import _cloneDeep from "lodash/cloneDeep";
import _compact from "lodash/compact";
import _each from "lodash/each";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";
import _forEach from "lodash/forEach";
import _isArray from "lodash/isArray";
import _isEmpty from "lodash/isEmpty";
import _isUndefined from "lodash/isUndefined";
import _sortBy from "lodash/sortBy";

import Constants from "../constants/Constants";
import DeferralConstants from "../constants/DeferralConstants";
import DeferralMoneyType from "../constants/DeferralMoneyType";
import EnrollmentGroupSort from "../constants/EnrollmentGroupCodeSort";
import PayPeriods from "../constants/PayPeriods";
import AllowableDeferral from "../models/AllowableDeferral";
import ApplicationSettings from "../models/ApplicationSettings";
import BalanceInfo from "../models/BalanceInfo";
import ChartLegend from "../models/ChartLegend";
import CombinedDeferralRule from "../models/CombinedDeferralRule";
import CompanyMatch from "../models/CompanyMatch";
import Condition from "../models/Condition";
import DataGroup from "../models/DataGroup";
import Deferral from "../models/Deferral";
import DeferralAutoIncrease from "../models/DeferralAutoIncrease";
import DeferralConfig from "../models/DeferralConfig";
import DeferralInfo from "../models/DeferralInfo";
import DeferralLimits from "../models/DeferralLimits";
import DeferralRestrictions from "../models/DeferralRestrictions";
import ExternalAsset from "../models/ExternalAsset";
import ExternalAssetAllocations from "../models/ExternalAssetAllocations";
import ExternalAssetContribution from "../models/ExternalAssetContribution";
import ExternalAssetContributionDetail from "../models/ExternalAssetContributionDetail";
import ExternalAssetEmployerDetail from "../models/ExternalAssetEmployerDetail";
import ExternalAssetInvestment from "../models/ExternalAssetInvestment";
import FutureDatedDeferral from "../models/FutureDatedDeferral";
import HealthSurvey from "../models/HealthSurvey";
import HealthViewData from "../models/HealthViewData";
import HealthViewProjection from "../models/HealthViewProjection";
import HowDoICompare from "../models/HowDoICompare";
import HsaDetails from "../models/HsaDetails";
import HVSChartConfig from "../models/HVSChartConfig";
import IncomeGoal from "../models/IncomeGoal";
// Models
import InitData from "../models/InitData";
import IntegratedSocialSecurityInfo from "../models/IntegratedSocialSecurityInfo";
import InvestmentHolding from "../models/InvestmentHolding";
import IRSLimits from "../models/IRSLimits";
import LinkedAccountBalances from "../models/LinkedAccountBalances";
import Maximizer from "../models/Maximizer";
import ModelPortfolio from "../models/ModelPortfolio";
import NextStep from "../models/NextStep";
import OneClickSolution from "../models/OneClickSolution";
import OtherAssetsRefData from "../models/OtherAssetsRefData";
import OutsideAsset from "../models/OutsideAsset";
import OutsideAssetCategory from "../models/OutsideAssetCategory";
import Plan from "../models/Plan";
import PlanLimits from "../models/PlanLimits";
import PlanMatchRule from "../models/PlanMatchRule";
import PlanMatchRuleCriteriaDetail from "../models/PlanMatchRuleCritDetail";
import PlanMatchRuleCriteria from "../models/PlanMatchRuleCriteria";
import PlanMatchRuleMoneyType from "../models/PlanMatchRuleMoneyType";
import PlanMatchRuleRate from "../models/PlanMatchRuleRate";
import PlanRules from "../models/PlanRules";
import ProfitSharing from "../models/ProfitSharing";
import ProjectionSettings from "../models/ProjectionSettings";
import RetirementAsset from "../models/RetirementIncomeAsset";
import SalaryInfo from "../models/SalaryInfo";
import SpecialLimit from "../models/SpecialLimit";
import SsInfoPerson from "../models/SsInfoPerson";
import TierDeferralRule from "../models/TierDeferralRule";
import User from "../models/User";

import LoggerFactory from "./LoggerFactory";
//Factories
import MatchTierFactory from "./MatchTierFactory";

const InitDataFactory = {
    /**
     * Logger.
     */
    logger: LoggerFactory.getInstance("InitDataFactory"),

    /**
     * Creates a generic request object that's typically the same for app server API endpoints.
     *
     * @param {object} data The raw JSON data from the `getInitData()` service API.
     * @returns {InitData} The client-side model.
     */
    fromServiceResponse: function (data) {
        InitDataFactory.logger.debug("create()");

        const userlist = InitDataFactory.createUsers(data);
        const primaryUser = _find(userlist, { headOfHousehold: true }),
            spouseHHIndx = _findIndex(userlist, { headOfHousehold: false }),
            spouse = spouseHHIndx ? userlist[spouseHHIndx] : null;
        // create the model
        const config = {
            data: data,
            applicationSettings: InitDataFactory.createApplicationSettings(data),
            users: userlist, //<<<<<
            user: primaryUser,
            spouse: spouse,
            otherAssetsRefData: InitDataFactory.createOtherAssetsRefData(data.otherAssetsRefData),
            irsLimitsRefData: InitDataFactory.createIrsLimitsRefData(data.irsLimitsRefData)
        };
        return new InitData(config);
    },

    /**
     * -- Best Next Steps - apply to non-migrated users only; migrated users have a separate call
     * -- Apply primary user data to projectionsMap, ssBenefitsMap, projectionSettings
     */
    createApplicationSettings: function (data) {
        InitDataFactory.logger.debug("createApplicationSettings()");
        const primaryHHIndx = _findIndex(data.users, { headOfHousehold: true });

        const model = new ApplicationSettings();

        model.projectionSettings = InitDataFactory.createProjectionSettings(data, primaryHHIndx);
        model.bestNextSteps = InitDataFactory.createNextStep(data.bestNextSteps);
        model.migratedContext = data.migratedContext;

        return model;
    },

    createIRSLimits: function (data, isHsa) {
        InitDataFactory.logger.debug("createIRSLimits()");

        //////////////////////////////////////////////////////////////////////////
        // Raw API data
        //////////////////////////////////////////////////////////////////////////
        /*
         "IRSLimits" : {
         catchupEligibleAge: 50
         discountFactor: 4
         limit402g: 18000
         limit402gCatchup: 24000
         limit415: 53000
         limit457: 18000
         limit457Catchup: 24000
         limitCatchup: 6000
         matchableSalaryCap: 265000
         rateOfInflation: 3
         }
         */

        if (ObjectUtil.isUndefinedOrNull(data) || _isEmpty(data)) {
            return new IRSLimits();
        }

        const config = {};

        if (isHsa) {
            config.hsaSingle = Number(data.hsaSingle);
            config.hsaSingleCatchUp = Number(data.hsaSingleCatchUp);
            config.hsaFamily = Number(data.hsaFamily);
            config.hsaFamilyCatchUp = Number(data.hsaFamilyCatchUp);
        } else {
            config.limit402g = Number(data.limit402g);
            config.limit402gNextYear = Number(data.limit402gNextYear);
            config.limit415 = Number(data.limit415);
            config.limitCatchup = Number(data.limitCatchup);
            config.limit402gCatchup = Number(data.limit402gCatchup);
            config.matchableSalaryCap = Number(data.matchableSalaryCap);
            config.discountFactor = Number(data.discountFactor) / 100;
            config.rateOfInflation = Number(data.rateOfInflation) / 100;
            config.catchupEligibleAge = Number(data.catchupEligibleAge);
            config.limit457 = Number(data.limit457);
            config.SSWageCap = Number(data.SSWageCap);
            config.limit457b = Number(data.limit457b);
            config.limit457bCatchup = Number(data.limit457bCatchup);
            config.higherCatchupEligibleAgeMax = Number(data.higherCatchupEligibleAgeMax);
            config.higherCatchupEligibleAgeMin = Number(data.higherCatchupEligibleAgeMin);
            config.higher402gCatchupLimit = Number(data.higher402gCatchupLimit);
            config.higher457CatchupLimit = Number(data.higher457CatchupLimit);
            config.higherCatchupLimit = Number(data.higherCatchupLimit);
        }
        config.isHsa = isHsa;
        return new IRSLimits(config);
    },

    createProjectionSettings: function (data, primaryHHIndx) {
        InitDataFactory.logger.debug("createProjectionSettings()");

        const primaryPlan = _find(data.users[primaryHHIndx].plans, (plan) => {
            return plan.primary === true;
        });

        const config = {
            probabilityOfSuccess: primaryPlan.retirementOption.confidence,
            confidence: primaryPlan.retirementOption.confidence,
            includeSocialSecurity: primaryPlan.retirementOption.inclSocialSec,
            rateOfInflation: Number(primaryPlan.irsLimits.rateOfInflation) / 100,
            term: data.users[primaryHHIndx].incomeGoalTerm
        };

        return new ProjectionSettings(config);
    },

    createNextStep: function (data) {
        if (ObjectUtil.isUndefinedOrNull(data)) {
            return new NextStep();
        }
        InitDataFactory.logger.debug("createNextStep()");
        //////////////////////////////////////////////////////////////////////////
        // Raw API data
        //////////////////////////////////////////////////////////////////////////
        /*
         "bestNextSteps" : {
         "message" : "",
         "url" : "",
         "recommended : true,
         "recommendedPct" : 0,
         "recommendedDollar" : 0
         }
         */
        /*
         "recommended": true,
         "recommendedPct": 2,
         "recommendedDollar": 0,
         "messageCode": "MSG_100",
         "url": null,
         "hasDeferralValue": true,
         "priority": 1,
         "type":  "NEXT_STEPS"
        * */
        //this section of code translates for the new migrated next steps
        //todo: work with service to have only one contract for bns (at earliest June 2018)
        if (!ObjectUtil.isUndefinedOrNull(data.messageCode)) {
            data.message = data.messageCode;
        }
        if (!ObjectUtil.isUndefinedOrNull(data.hasDeferralValue)) {
            data.staticMessage = !data.hasDeferralValue;
        }

        const config = ObjectUtil.isUndefinedOrNull(data)
            ? null
            : {
                  message: ObjectUtil.isUndefinedOrNull(data.message) ? "" : data.message,
                  url: ObjectUtil.isUndefinedOrNull(data.url) ? "" : data.url,
                  recommended: ObjectUtil.isUndefinedOrNull(data.recommended)
                      ? false
                      : data.recommended,
                  recommendedPct: ObjectUtil.isUndefinedOrNull(data.recommendedPct)
                      ? 0
                      : data.recommendedPct,
                  recommendedDollar: ObjectUtil.isUndefinedOrNull(data.recommendedDollar)
                      ? 0
                      : data.recommendedDollar,
                  enrollToMaximizer: ObjectUtil.isUndefinedOrNull(data.enrollToMaximizer)
                      ? false
                      : data.enrollToMaximizer,
                  staticMessage: ObjectUtil.isUndefinedOrNull(data.staticMessage)
                      ? false
                      : data.staticMessage,
                  appliesToTerminated: data.appliesToTerminated
              };

        return new NextStep(config);
    },

    /**
     *  Obtain the income goal for the primary user
     */
    createIncomeGoal: function (user) {
        InitDataFactory.logger.debug("createIncomeGoal()");

        const hasLinkedAccounts = user.linkedAccountBalances && user.linkedAccountBalances.length;
        const hidePercent = hasLinkedAccounts;
        const dollarValue =
            user.incomeGoalUnits === Constants.AMT
                ? user.incomeGoalValue
                : user.plans[0].salary.total * (user.incomeGoalValue / 100);
        const incomeGoalPercent = hidePercent ? dollarValue : user.incomeGoalValue;
        const userValueUnits =
            Constants.INTEGRATED_TYPE_CONVERSION[user.incomeGoalUnits] || user.incomeGoalUnits;
        const value = hidePercent ? incomeGoalPercent : user.incomeGoalValue,
            valueUnits = hidePercent ? Constants.AMT : userValueUnits || Constants.PCT,
            config = {
                valueUnits,
                term: user.incomeGoalTerm,
                value: value, // Sanity check that this user isn't `migrated`
                default: user.defaultIncomeGoalPct || 75
            };
        if (valueUnits === Constants.PCT) {
            config.value = Math.round(
                value * (valueUnits === Constants.PCT && value < 3 ? 100 : 1)
            );
        } else {
            config.value = Math.round(value * 100) / 100;
        }

        if (user.incomeGoalPercent) {
            config.value = user.incomeGoalPercent;
        }

        user.incomeGoalValue = parseFloat(config.value) || 0;

        return new IncomeGoal(config);
    },

    createSpecialLimitObject: function (data) {
        // This was added for LA County - an "L" or "H" ppt with pourover match enabled  requires a special calculation in the HUB
        if (!data) {
            //A null config is handled in the special low limit factory
            return new SpecialLimit(null);
        }

        const planEnabled = data.planEnabled || false;
        const pourOverEnabled = data.pourOverEnabled || false;
        const type = data.pptLvlNycGoalFlag || null;

        const config = {
            planEnabled: planEnabled,
            pourOverEnabled: pourOverEnabled,
            type: type,
            limit: pourOverEnabled && type === "L" ? data.lowLimit : 0
        };
        return new SpecialLimit(config);
    },
    createUsers: function (data) {
        //////////////////////////////////////////////////////////////////////////
        // Raw API data
        //////////////////////////////////////////////////////////////////////////
        /*
         "user" : {

         "individualId" : "",
         "firstName" : "",
         "middleName" : "",
         "lastName" : "",
         "age" : 35,
         "gender" : "", // values = "M" or "F" or "U"
         "clientId" : "",
         "incomeGoalTerm" : 12,
         "incomeGoalValue" : 0.75,
         "plans" : [{ // {Plan} }],
         "otherAssets" : {},
         "type" : "P", // {string} values: "P" (participant), "E" (emulated user),
         "dateOfBirth" : 1432898416894, // time from epoch, aka Date.getTime()
         "dateOfBirthSource" : "FILE" | "DEF" | "UI" // Source of the date of birth. either "FILE" - from fascore system, "DEF" for defaulted from age 45, "UI" - override data from client person table.

         }
         */

        const users = data.users;
        const userList = [];

        _forEach(users, (user) => {
            userList.push(InitDataFactory.createUser(user, data));
        });

        return userList;
    },

    createUser: function (user, data) {
        InitDataFactory.logger.debug("createUser( Creating user `{0}` )", [
            user.firstName + " " + user.lastName + " GENDER: " + user.gender
        ]);

        if (!user.personId && user.socialSecuritySummary) {
            user.personId = user.socialSecuritySummary.personId;
        }

        if (!user.investorId && user.socialSecuritySummary) {
            user.investorId = user.socialSecuritySummary.investorId;
        }

        if (user.migratedOtherAssets && user.migratedOtherAssets.personDetail) {
            user.isAAFeatureEnabled = user.migratedOtherAssets.personDetail.isAAFeatureEnabled;
        }

        const primaryPlanIndx = _findIndex(user.plans, { primary: true });
        const retirementIncomeAssets = data.integratedGoals ? data.integratedGoals : null;
        const userRetirementAssets = _filter(retirementIncomeAssets, (item) => {
            if (ObjectUtil.isUndefinedOrNull(item.pcapPersonId)) {
                if (user.headOfHousehold) {
                    item.pcapPersonId = user.pcapPersonId;
                }
            }
            return item.pcapPersonId === user.pcapPersonId;
        });
        const integratdExternalAssets = data.integratedAssets ? data.integratedAssets : [];

        const userExternalAssets = [];

        for (let i = 0; i < integratdExternalAssets.length; i++) {
            const asset = integratdExternalAssets[i];

            if (asset.owners && asset.owners.length > 0) {
                if (user.headOfHousehold) {
                    const primaryOwner = _filter(asset.owners, (item) => {
                        return item.pcapPersonId === user.pcapPersonId;
                    });
                    if (primaryOwner.length > 0) {
                        asset.isPrimary = true;
                        asset.personId = user.personId;
                        userExternalAssets.push(asset);
                    }
                } else {
                    const secondaryOwner = _filter(asset.owners, (item) => {
                        return item.pcapPersonId === user.pcapPersonId;
                    });
                    if (secondaryOwner.length > 0) {
                        asset.isPrimary = false;
                        asset.personId = user.personId;
                        userExternalAssets.push(asset);
                    }
                }
            } else {
                if (user.headOfHousehold) {
                    asset.isPrimary = true;
                    asset.personId = user.personId;
                    userExternalAssets.push(asset);
                }
            }
        }

        const config = {
            individualId: user.individualId ? user.individualId : "",
            firstName: user.firstName ? user.firstName : "",
            middleName: user.middleName ? user.middleName : "",
            lastName: user.lastName ? user.lastName : "",
            age: user.age,
            gender: user.gender,
            clientId: user.clientId ? user.clientId : "",
            investorId: user.investorId ? user.investorId : "",
            personId: user.personId,
            pcapPersonId: user.pcapPersonId,
            headOfHousehold: user.headOfHousehold,
            defaultLEAge: user.defaultLEAge,
            adjustedLEAge: !ObjectUtil.isUndefinedOrNull(user.planToAgeOverride)
                ? user.planToAgeOverride
                : user.defaultLEAge,
            plans: InitDataFactory.createPlans(user.plans),
            outsideAssets: !ObjectUtil.isUndefinedOrNull(user.otherAssets)
                ? InitDataFactory.createOutsideAssets(user.otherAssets)
                : null,
            outsideAssetCategories: !ObjectUtil.isUndefinedOrNull(user.otherAssets)
                ? InitDataFactory.createOutsideAssetCategories(user.otherAssets)
                : null,
            externalAssets: InitDataFactory.createExternalAssets(
                userExternalAssets,
                user.pcapPersonId
            ),
            retirementAssets: InitDataFactory.createRetirementAssets(userRetirementAssets),
            ssInfo: !ObjectUtil.isUndefinedOrNull(user.socialSecuritySummary)
                ? InitDataFactory.createSsInfo(user.socialSecuritySummary)
                : null,
            type: user.type ? user.type : "",
            hdic: null, // NOTE: Not coming from GetInitData ans populated later by GetHDICData
            healthViewData: null, // NOTE: Not coming from GetInitData ans populated later by GetHealthViewData
            dateOfBirth: user.dateOfBirth,
            dateOfBirthSource: user.dateOfBirthSource ? user.dateOfBirthSource : "",
            specialLimit: user.nycGoalData
                ? InitDataFactory.createSpecialLimitObject(user.nycGoalData)
                : null, //LA County - CAB
            isAAFeatureEnabled: user.isAAFeatureEnabled,
            projectionsMap: user.projectionsMap,
            ssBenefitsMap: user.ssBenefitsMap,
            incomeGoal: InitDataFactory.createIncomeGoal(user),
            retirementAge:
                user.plans.length > 0 ? user.plans[primaryPlanIndx].retirementOption.retireAge : 0
        };

        if (user.headOfHousehold && user.linkedAccountBalances) {
            config.linkedAccountBalances = InitDataFactory.createLinkedAccountBalances(
                user.linkedAccountBalances,
                config.personId
            );
        }

        if (user.gender === "UNKNOWN") {
            config.gender = "U";
        }

        // including HSA from other plans
        config.deferrals = [];
        config.newDeferrals = [];

        // new availableDeferrals including HSA
        config.availableDeferrals = [];
        config.newAvailableDeferrals = [];

        // NOTE:
        // this is an Array of pointer references, make sure to use pointer, NEVER deep copy!
        _each(config.plans, function (plan) {
            if (plan.isPrimary) {
                config.deferrals = config.deferrals.concat(plan.deferrals);
                config.newDeferrals = config.newDeferrals.concat(plan.newDeferrals);
                config.salary = plan.salary;
                config.retirementAge = plan.retirementAge;

                // add hasReducedSalary flag for Apple nq participants
                if (
                    plan.salary.source !== "UI" &&
                    !ObjectUtil.isUndefinedOrNull(plan.salary.nqContributionPct)
                ) {
                    config.hasReducedSalary = true;
                }
            } else {
                _each(plan.deferrals, function (def) {
                    if (def.deferralTypeCode === DeferralConstants.typeCodes.HSA) {
                        config.deferrals.push(def);
                    }
                });

                _each(plan.newDeferrals, function (def) {
                    if (def.deferralTypeCode === DeferralConstants.typeCodes.HSA) {
                        config.newDeferrals.push(def);
                    }
                });
            }
            config.availableDeferrals =
                plan.deferralInfo && plan.deferralInfo.availableDeferrals
                    ? config.availableDeferrals.concat(plan.deferralInfo.availableDeferrals)
                    : [];
        });

        return new User(config);
    },

    /*  createSpouse: function(data) {
        if (data) {
            var config = {
                investorId: data.investorId,
                personId: data.personId,
                firstName: data.firstName,
                lastName: data.lastName,
                gender: data.gender,
                retirementAge: data.retirementAge,
                plans: data.plans,
                dateOfBirth: data.dateOfBirth,
                age: data.age,
                incomeGoal: {
                    incomeGoalValue: data.incomeGoalValue,
                    incomeGoalValueUnits: data.incomeGoalValueUnits ? data.incomeGoalValueUnits : "PCT"
                }
            };

            return new User(config);
        }
        return null;
    }, */

    createPlans: function (data) {
        InitDataFactory.logger.debug("createPlans( Creating {0} plans )", [data.length]);

        //////////////////////////////////////////////////////////////////////////
        // Raw API data
        //////////////////////////////////////////////////////////////////////////
        /*
         "plans" : [{ // {Plan}
         "id" : "",
         "planName" : "",
         "modelPortfolio" : { },
         "primary" : true,
         "balances" : [{}],
         "funds" : [{}],
         "retirementOption" : {},
         "companyMatch" : {
         //structure unknown
         },
         "deferrals" : [{  }],
         "salary" : { },
         "discretionaryContribution" : {
         //structure unknown
         },
         "payFrequency" : "W",
         "terminated" : false,

         "limits" : { // {PlanLimits} },
         "irsCode" : "",
         "rules" : { },
         "managedAccounts" : { },
         "fw" : { },
         "maximizer" : { }
         }
         ],
         */

        let config;
        const list = [];

        const getEquityPercent = function (data) {
            const isEquityPctAvailable =
                !ObjectUtil.isUndefinedOrNull(data.retirementOption) &&
                !ObjectUtil.isUndefinedOrNull(data.retirementOption.equityPct);
            if (!isEquityPctAvailable) {
                InitDataFactory.logger.warn(
                    "getEquityPercent( Hard-coding the value to 0.5 for now. This will come from Investment Services API at some point. )"
                );
            }
            const equityPct =
                isEquityPctAvailable && data.retirementOption.equityPct >= 0
                    ? data.retirementOption.equityPct <= 100
                        ? data.retirementOption.equityPct
                        : 100
                    : 0.5;
            return isEquityPctAvailable ? equityPct : 0.5;
        };

        const hasNoCompanyMatch = function (rules) {
            return ObjectUtil.isUndefinedOrNull(rules) || rules.length === 0;
        };

        _each(data, function (item) {
            const deferralInfo = InitDataFactory.createDeferralInfo(
                item.allowableDeferralsWithRules,
                item.deferrals
            );
            config = {
                id: item.id,
                name: item.planName,
                isPrimary: item.primary,
                equityPercent: getEquityPercent(item),
                payFrequency: PayPeriods[item.payFrequency],
                terminated: item.terminated,
                irsCode: item.irsCode,
                higherCULimitInd: item.higherCULimitInd,
                // TODO: BMR: One of the following params might go away from the API response data at some point so be prepared to refactor
                //Add IRS Limits
                IRSLimits: !ObjectUtil.isUndefinedOrNull(item.irsLimits)
                    ? InitDataFactory.createIRSLimits(
                          item.irsLimits,
                          item.irsCode === DeferralConstants.typeCodes.HSA
                      )
                    : null,
                balances: !ObjectUtil.isUndefinedOrNull(item.balances)
                    ? InitDataFactory.createBalances(item.balances, item.balanceInfo)
                    : null,
                investmentHoldings: !ObjectUtil.isUndefinedOrNull(item.investmentHoldings)
                    ? InitDataFactory.createInvestmentHoldings(item.investmentHoldings)
                    : null,
                deferrals: !ObjectUtil.isUndefinedOrNull(item.deferrals)
                    ? InitDataFactory.createDeferrals(item.deferrals, deferralInfo, item.primary)
                    : [],
                deferralInfo: deferralInfo, // -- sy added
                assetAllocationEligibility: null, //set later
                planRules: !ObjectUtil.isUndefinedOrNull(item.rules)
                    ? InitDataFactory.createPlanRules(item.rules)
                    : null,
                discretionaryContribution: null, //TODO: structure unknown, not sure this is used
                limits: !ObjectUtil.isUndefinedOrNull(item.limits)
                    ? InitDataFactory.createPlanLimits(item.limits)
                    : null,
                salary: InitDataFactory.createSalary(item.salary),
                seniorityDate: item.seniorityDate,
                companyMatch: hasNoCompanyMatch(item.companyMatchRules)
                    ? null
                    : InitDataFactory.createCompanyMatch(item.companyMatchRules, item.deferrals),
                employerMatch: hasNoCompanyMatch(item.employerMatch)
                    ? null
                    : InitDataFactory.createEmployerMatch(item.employerMatch),
                profitSharing:
                    item.profitSharing && item.profitSharing.length
                        ? item.profitSharing.map((tier) => {
                              return new ProfitSharing(tier);
                          })
                        : null,
                modelPortfolio: !ObjectUtil.isUndefinedOrNull(item.modelPortfolio)
                    ? InitDataFactory.createModelPortfolio(item.modelPortfolio)
                    : null,
                maximizer: !ObjectUtil.isUndefinedOrNull(item.rules)
                    ? InitDataFactory.createMaximizer(item.rules.maximizer, item.maximizer)
                    : null,
                retirementAge: ObjectUtil.isUndefinedOrNull(item.retirementOption)
                    ? 0
                    : item.retirementOption.retireAge >= 50
                      ? item.retirementOption.retireAge
                      : 50,
                yearsOfService: ObjectUtil.isUndefinedOrNull(item.totalSvcYrs)
                    ? 0
                    : item.totalSvcYrs,
                trsFlexAccountInfo: ObjectUtil.isUndefinedOrNull(item.trsFlexAccountInfo)
                    ? null
                    : item.trsFlexAccountInfo,
                nqEnrollmentInfo: ObjectUtil.isUndefinedOrNull(item.nqEnrollmentInfo)
                    ? null
                    : item.nqEnrollmentInfo,
                deferralRestrictions: InitDataFactory.createDeferralRestrictions(
                    item.deferralRestrictions
                ),
                hsaDetails: InitDataFactory.createHsaDetails(
                    item.trsFlexAccountInfo,
                    item.hsaDetails
                )
            };

            config.newDeferrals = _cloneDeep(config.deferrals);
            if (!ObjectUtil.isUndefinedOrNull(config.trsFlexAccountInfo)) {
                config.trsFlexAccountInfo.isLinkedAccountBalances = false;
            }

            const plan = new Plan(config);
            list.push(plan);
        });

        // get reference to plan from deferral
        // NOTE! circular reference deferral.plan causes issue for iOS team, also add planId for plan lookup
        _each(list, function (plan) {
            if (plan.deferrals) {
                _each(plan.deferrals, function (def) {
                    def.plan = plan;
                    def.planId = plan.id;
                });
            }
            if (plan.newDeferrals) {
                _each(plan.newDeferrals, function (def) {
                    def.plan = plan;
                    def.planId = plan.id;
                });
            }

            // -- deferralInfo is undefined
            if (plan.deferralInfo && plan.deferralInfo.availableDeferrals) {
                _each(plan.deferralInfo.availableDeferrals, function (def) {
                    def.plan = plan;
                    def.planId = plan.id;
                });
            }
        });

        return list;
    },

    createBalances: function (balancesList, balance) {
        const list = [];
        const addBalance = function (item) {
            const config = {
                totalBalance: item.totalBalance,
                vestedBalance: item.vestedBalance,
                vestedPercent: item.vestedPercent,
                moneyType: item.moneyType,
                taxStatus: item.taxStatus
            };
            const object = new BalanceInfo(config);
            list.push(object);
        };

        if (!ObjectUtil.isArrayEmpty(balancesList)) {
            _each(balancesList, addBalance);
        } else {
            addBalance(balance);
        }

        return list;
    },

    createInvestmentHoldings: function (data) {
        const list = [];
        let config;
        _each(data, function (item) {
            config = {
                cusip: item.cusip,
                fundName: item.fundName,
                balance: item.balance,
                percent: item.percent,
                effectiveDate: item.effectiveDate,
                effectiveDateString: item.effectiveDateString
            };
            const object = new InvestmentHolding(config);
            list.push(object);
        });

        return list;
    },

    createPlanRules: function (data) {
        if (data) {
            const config = ObjectUtil.isUndefinedOrNull(data.hvsEnabled)
                ? null
                : {
                      allowSalaryReset: data.allowSalaryReset,
                      allowDeferral: data.allowDeferral,
                      allowMultipleDeferrals: data.allowMultipleDeferrals,
                      allowPreRetireeView: data.allowPreRetireeView,
                      allowWCIRView: data.allowWCIRView,
                      deferralMessage: data.deferralMessage,
                      monthlyDeferralsEnabled: data.monthlyDeferralsEnabled,
                      hdicEnabled: data.hdicEnabled,
                      hvsEnabled: data.hvsEnabled,
                      maximizer: data.maximizer,
                      otherAssetsEnabled: data.otherAssetsEnabled,
                      promptForAge: data.promptForAge,
                      promptForSalary: data.promptForSalary,
                      showSalary: data.showSalary,
                      ssEnabled: data.ssEnabled,
                      specialCatchup: data.specialCatchup,
                      usePayrollEmployerMatch: data.usePayrollEmployerMatch,
                      feForecastEnabled: data.feForecastEnabled,
                      showLimitMsg: true,
                      allowAntiDiscount: data.antiDiscountEnabled,

                      allowAddNewContributions: ObjectUtil.isUndefinedOrNull(
                          data.allowAddNewContributions
                      )
                          ? false
                          : data.allowAddNewContributions,
                      employerDirectedAllocationsOnly: data.employerDirectedAllocationsOnly
                  };
            let logMsg = "";
            let logger = InitDataFactory.logger.debug;
            if (ObjectUtil.isUndefinedOrNull(config)) {
                logMsg = " API data doesn't match contract so using default values ";
                logger = InitDataFactory.logger.warn(logMsg);
            }

            logger("createPlanRules({0})", [logMsg]);
            return new PlanRules(config);
        } else {
            InitDataFactory.logger.warn(
                "createPlanRules( Cannot create plan limits as it's not in the data. )"
            );
            return null;
        }
    },

    createPlanLimits: function (data) {
        if (data) {
            InitDataFactory.logger.debug("createPlanLimits()");
            const config = {
                ageCatchupAllowed: data.ageCatchupAllowed,
                ageCatchupMethodCode: data.ageCatchUpMethodCode
            };
            return new PlanLimits(config);
        } else {
            InitDataFactory.logger.warn(
                "createPlanLimits( Cannot create plan limits as it's not in the data. )"
            );
            return null;
        }
    },

    createSalary: function (data) {
        if (data) {
            InitDataFactory.logger.debug("createSalary()");
            // source: "FILE" | "DEF" | "UI"  Source of salary data, either "FILE" - from fascore system,
            // "DEF" for defaulted from age 45, "UI" - override data from client person table.
            if (ObjectUtil.isUndefinedOrNull(data.total)) {
                InitDataFactory.logger.warn(
                    "createSalary( Total salary is not Defined.  Setting it to $0.  The hub will skip this participant in calculations. )"
                );
            }
            const config = {
                total: ObjectUtil.isUndefinedOrNull(data.total) ? 0 : data.total,
                base: ObjectUtil.isUndefinedOrNull(data.base) ? data.total : data.base,
                //if variable is null, that means user has no variable.  do not set variable to 0.
                variable: data.variable,
                source: data.source,
                nqContributionPct: data.nqContributionPct,
                salaryReminder: data.salUpdReminder,
                salaryOnFile: data.salaryOnFile
            };
            if (ObjectUtil.isUndefinedOrNull(config.variable)) {
                // NOTE: if variable is null or undefined DO NOT default it to zero, as the UI needs to handle this case
                // and not show the variable field in the UI. When passing to the Hub we need to default to zero if
                // null or undefined -- this is done in the HubConfigFactory
                //config.variable = 0;
                config.total = config.base;
            }
            return new SalaryInfo(config);
        } else {
            InitDataFactory.logger.warn(
                "createSalary( Cannot create salary as it's not in the data. )"
            );
            return null;
        }
    },

    createModelPortfolio: function (data) {
        if (data) {
            InitDataFactory.logger.debug("createModelPortfolio()");
            const config = {
                modelDescription: data.modelDesc,
                modelNumber: data.modelNbr,
                effectiveDate: data.effectiveDate,
                rebalanceDate: data.rebalanceDate
            };
            return new ModelPortfolio(config);
        } else {
            InitDataFactory.logger.warn(
                "createModelPortfolio( Cannot create model portfolio as it's not in the data. )"
            );
            return null;
        }
    },

    createMaximizer: function (isMaximizer, inputData) {
        const data = !ObjectUtil.isUndefinedOrNull(inputData)
            ? inputData
            : {
                  ongoing: false,
                  totalMaxDefPercent: 0,
                  deferralPercent: [],
                  totalPayPeriods: 0,
                  remainingPayPeriods: 0,
                  salary: 0,
                  enrollType: "",
                  enrollmentSelection: ""
              };
        if (isMaximizer) {
            InitDataFactory.logger.debug("createMaximizer()");
            const config = {
                isOngoing: data.ongoing,
                totalMaxDefPercent: data.totalMaxDefPercent,
                deferralPercent: data.deferralPercent,
                totalPayPeriods: data.totalPayPeriods,
                remainingPayPeriods: data.remainingPayPeriods,
                salary: data.salary,
                enrollType: data.enrollType,
                enrollmentSelection: data.enrollmentSelection //,
                //wasEnrolled: false
            };
            return new Maximizer(config);
        } else {
            InitDataFactory.logger.warn(
                "createMaximizer( Cannot create maximizer as it's not in the data. )"
            );
            return null;
        }
    },

    createAutoIncrease: function (data) {
        /**
         * "autoIncrease": // do not include if auto increase is not needed
         {
                frequency: 1
                increaseValue: 3000
                nextScheduleDate: "12-Jun-2016"
                pctAmtCode: "AMT"
                stopAtValue: 18000
         }
         */
        if (data && data.nextScheduleDate) {
            InitDataFactory.logger.debug("createAutoIncrease()");
            const aiConfig = {
                frequency: data.frequency,
                increaseValue: data.increaseValue,
                stopAtValue: data.stopAtValue,
                nextScheduleDate: data.nextScheduleDate,
                pctAmtCode: data.pctAmtCode
            };

            return new DeferralAutoIncrease(aiConfig);
        } else {
            InitDataFactory.logger.warn(
                "createAutoIncrease( Cannot create auto increase as it's null. )"
            );
            return null;
        }
    },

    createFutureDatedDeferral: function (data) {
        if (_isArray(data) && data.length === 1) {
            InitDataFactory.logger.debug("createFutureDatedDeferral()");
            return new FutureDatedDeferral(data[0]);
        } else {
            InitDataFactory.logger.warn(
                "createFutureDatedDeferral( Cannot create future dated deferral as it's null. )"
            );
            return null;
        }
    },

    createOutsideAssets: function (data) {
        InitDataFactory.logger.debug("createOutsideAssets( Creating {0} outside assets )", [
            data.assetInfo.length
        ]);

        //////////////////////////////////////////////////////////////////////////
        // Raw API data
        //////////////////////////////////////////////////////////////////////////
        /*
         "otherAssets": {
         "assetInfo" : [{
         "id" : "",
         "name" : "",
         "type" : "",
         "value" : "",
         "planId" : "",
         "source" : "",
         "editable" : true,
         "deletable" : true,
         "viewable" : true,
         "employerContribution" : false
         }
         ],
         "assetCategories": [
         {
         "hover": "Hover Monthly Income in Retirement",
         "display": "Money outside of your 401(k) that you expect to have as monthly income in retirement.",
         "createdDate": null,
         "createdBy": "test",
         "updatedDate": null,
         "updatedBy": "Test",
         "faq": "FAQ Monthly Income in Retirement",
         "name": "Future monthly income",
         "id": 21,
         "status": "ACTIVE"
         },
         ...
         ]
         */

        const getCategoryPropertyValue = function (list, id, prop) {
            const item = _find(list, { id: id });
            return ObjectUtil.isUndefinedOrNull(item) || ObjectUtil.isUndefinedOrNull(item[prop])
                ? null
                : item[prop];
        };

        let config;
        const list = [];
        _each(data.assetInfo, function (item) {
            const categoriesList = data.assetCategories;
            config = {
                id: item.assetId,
                name: item.name,
                type: item.categoryId,
                typeString: getCategoryPropertyValue(categoriesList, item.categoryId, "name"),
                description: getCategoryPropertyValue(categoriesList, item.categoryId, "display"),
                value: item.value,
                planId: item.planId, // mapped to plan.id
                source: item.source,
                isEditable: ObjectUtil.isUndefinedOrNull(item.editable) ? true : item.editable,
                isDeletable: ObjectUtil.isUndefinedOrNull(item.deletable) ? true : item.deletable,
                isViewable: ObjectUtil.isUndefinedOrNull(item.viewable) ? true : item.viewable,
                employerContribution: item.employerContribution,
                assetType: "otherAsset",
                monthlyIncomeEST: 0
            };
            list.push(new OutsideAsset(config));
        });

        return list;
    },

    createOutsideAssetCategories: function (data) {
        InitDataFactory.logger.debug(
            "createOutsideAssetCategories( Creating {0} outside assets )",
            [data.assetCategories.length]
        );

        //////////////////////////////////////////////////////////////////////////
        // Raw API data
        //////////////////////////////////////////////////////////////////////////
        /*
         "otherAssets": {..,
         "assetCategories": [
         {
         "hover": "Hover Monthly Income in Retirement",
         "display": "Money outside of your 401(k) that you expect to have as monthly income in retirement.",
         "createdDate": null,
         "createdBy": "test",
         "updatedDate": null,
         "updatedBy": "Test",
         "faq": "FAQ Monthly Income in Retirement",
         "name": "Future monthly income",
         "id": 21,
         "status": "ACTIVE"
         },
         ...
         ]
         */

        let config;
        const list = [];
        _each(data.assetCategories, function (item) {
            config = {
                hover: item.hover,
                display: item.display,
                createdDate: item.createdDate,
                createdBy: item.createdBy,
                updatedDate: item.updatedDate,
                updatedBy: item.updatedBy,
                faq: item.faq,
                name: item.name,
                id: item.id,
                status: item.status
            };
            list.push(new OutsideAssetCategory(config));
        });

        return list;
    },

    translateIntegratedAccountType: function (type) {
        let translatedType = "";
        switch (type) {
            case "1165E":
            case "401K":
            case "INDIVIDUAL_K":
                translatedType = "401K";
                break;
            case "401A":
            case "403B":
            case "IRA":
                translatedType = type;
                break;
            case "457":
            case "457B":
            case "NON_QUAL":
                translatedType = "457";
                break;
            case "415M":
            case "457F":
                translatedType = "457F";
                break;
            case "HSA":
            case "401H":
            case "PROFIT_SHARING_PLAN":
                translatedType = "TXFR";
                break;
            case "CD":
            case "ESOP":
            case "ESPP":
            case "INVESTMENT":
            case "LIFE_INSURANCE":
            case "MMA":
            case "OTHER_PENSION_PLAN":
            case "SAVINGS":
                translatedType = "TXBL";
                break;
            default:
                translatedType = "401K";
                break;
        }

        return translatedType;
    },

    translateIntegratedAccountSubType: function (type, accountTypeGroup) {
        let translatedType = "";
        if (accountTypeGroup === "RETIREMENT") {
            switch (type) {
                case "ROTH":
                case "INHERITED ROTH":
                    translatedType = "ROTH";
                    break;
                case "AFTER":
                case "AFTER_TAX":
                    translatedType = "AFTER";
                    break;
                default:
                    translatedType = "BEFORE";
                    break;
            }
        } else {
            translatedType = "BEFORE";
        }
        return translatedType;
    },

    createExternalAssets: function (data, personId) {
        if (data) {
            const list = [];
            _each(data, function (asset) {
                list.push(InitDataFactory.createExternalAsset(asset, personId));
            });
            return list;
        }
    },

    createExternalAsset: function (asset, personId) {
        let balanceValue = asset.currentBalance ? asset.currentBalance : 0;
        let isJointOwner = false;
        if (asset.owners && asset.owners.length > 0) {
            if (asset.owners.length > 1) {
                isJointOwner = true;
            }
            const ownerlist = _filter(asset.owners, (item) => {
                return item.pcapPersonId === personId;
            });

            if (ownerlist.length > 0) {
                balanceValue = asset.currentBalance
                    ? asset.currentBalance * (Number(ownerlist[0].percentage) / 100)
                    : 0;
            }
        }

        const config = {
            id: asset.pcapAccountId,
            retirementAccountId: asset.pcapAccountId,
            investorId: Number(personId),
            accountTypeCode: InitDataFactory.translateIntegratedAccountType(asset.accountType),
            accountProviderName:
                !ObjectUtil.isUndefinedOrNull(asset.name) && asset.name !== ""
                    ? asset.name
                    : asset.firmName,
            accountTypeName: InitDataFactory.translateIntegratedAccountType(asset.accountType),
            accountTypeSubtype: InitDataFactory.translateIntegratedAccountSubType(
                asset.accountTypeSubtype,
                asset.accountTypeGroup
            ),
            accountBalance: balanceValue,
            investment: asset.allocationSet
                ? InitDataFactory.createAllocationsForIntegratedAsset(asset.allocationSet)
                : null,
            equityMix: asset.allocationSet
                ? InitDataFactory.createIntegratedAllocationtMix(asset.allocationSet)
                : 0,
            dataSource: "PCAP",
            assetType: "externalAsset",
            currentContribution: InitDataFactory.createContributionForIntegratedAsset(asset),
            plAdviseCode: "",
            assetAllocationAdvisableFlag: false,
            savingsRateAdvisableFlag: false,
            lastUpdatedTime: null,
            acctAggrId: 0,
            acctAggrStatus: "",
            externalAccountType: "",
            externalSourceName: "",
            configInstruction: "",
            connectionId: "",
            connectionSyncStatusCode: "",
            isUserActionRequired: false,
            isJointOwner: isJointOwner,
            isPrimary: asset.isPrimary,
            owners: asset.owners && asset.owners.length > 0 ? asset.owners : []
        };

        return new ExternalAsset(config);
    },

    // For integrated user
    // For accountType. 457F and 415M, set contributions to 0
    createContributionForIntegratedAsset: function (data) {
        const config = {
            id: "",
            plCurrentContributionCode: "",
            employerMatchFlag: false,
            profitSharingFlag: false,
            profitSharingPercentage: 0,
            profitSharingAmount: 0,
            employerMatchDetail: [],
            contributionDetail: []
        };
        const contributionList = [];
        const employerMatchDetail = [];

        if (!ObjectUtil.isUndefinedOrNull(data.contribution)) {
            if (data.accountType === "457F" || data.accountType === "415M") {
                data.contribution.value = 0;
            }
            const details = {
                plContributionTypeCode: data.contribution.unit,
                contributionPercentageFlag: data.contribution.unit === "PERCENT",
                contributionValue: data.contribution.value
            };

            const contributionDetails = new ExternalAssetContributionDetail(details);
            contributionList.push(contributionDetails);
        }

        if (!ObjectUtil.isUndefinedOrNull(data.employerMatch)) {
            if (data.accountType === "457F" || data.accountType === "415M") {
                data.employerMatchDetail = [];
            }
            //sort tiers to make sure they are calculated correctly
            if (data.employerMatch && data.employerMatch.length > 0) {
                data.employerMatch.sort(function (a, b) {
                    return a.seq - b.seq;
                });

                _each(data.employerMatch, function (detail) {
                    employerMatchDetail.push(detail);
                });
                config.employerMatchFlag = true;
            }
        }

        if (!ObjectUtil.isUndefinedOrNull(data.profitSharing)) {
            config.profitSharingFlag = true;
            if (String(data.profitSharing.unit).toUpperCase() === "PERCENT") {
                config.profitSharingPercentage = data.profitSharing.value
                    ? data.profitSharing.value
                    : 0;
            } else {
                config.profitSharingAmount = data.profitSharing.value
                    ? data.profitSharing.value
                    : 0;
            }
            config.profitSharingType = String(data.profitSharing.unit).toUpperCase();
        }

        config.contributionDetail = contributionList;
        config.employerMatchDetail = employerMatchDetail;

        return new ExternalAssetContribution(config);
    },

    createCurrentContributionForExternalAsset: function (data) {
        if (!ObjectUtil.isUndefinedOrNull(data)) {
            const config = {
                id: data.id,
                plCurrentContributionCode: data.plCurrentContributionCode,
                employerMatchFlag: data.employerMatchFlag,
                profitSharingFlag: data.profitSharingFlag,
                profitSharingPercentage: data.profitSharingPercentage,
                profitSharingAmount: data.profitSharingAmount
            };
            const contributionDetail = [];
            _each(data.contributionDetail, function (detail) {
                contributionDetail.push(
                    InitDataFactory.createContributionDetailForExternalAsset(detail)
                );
            });
            const employerMatchDetail = [];

            if (data.employerMatchDetail) {
                //sort tiers to make sure they are calculated correctly
                data.employerMatchDetail.sort(function (a, b) {
                    return a.sequenceNumber - b.sequenceNumber;
                });
            }
            _each(data.employerMatchDetail, function (detail) {
                employerMatchDetail.push(
                    InitDataFactory.createEmployerMatchDetailForExternalAsset(detail)
                );
            });
            config.contributionDetail = contributionDetail;
            config.employerMatchDetail = employerMatchDetail;

            return new ExternalAssetContribution(config);
        }
        return null;
    },
    createContributionDetailForExternalAsset: function (data) {
        const config = {
            id: data.id,
            plContributionTypeCode: data.plContributionTypeCode,
            contributionPercentageFlag: data.contributionPercentageFlag,
            contributionValue: data.contributionValue
        };

        return new ExternalAssetContributionDetail(config);
    },
    createEmployerMatchDetailForExternalAsset: function (data) {
        const config = {
            id: data.id,
            sequenceNumber: data.sequenceNumber,
            matchValuePercentageFlag: data.matchValuePercentageFlag,
            matchValue: data.matchValue,
            limitValuePercentageFlag: data.limitValuePercentageFlag,
            limitValue: data.limitValue
        };

        return new ExternalAssetEmployerDetail(config);
    },
    // For migrated user, non-integrated
    createInvestmentForExternalAsset: function (data) {
        const config = {
            id: data.id,
            plInvestmentTypeCode: data.plInvestmentTypeCode,
            investmentRiskBasedPortfolio: data.investmentRiskBasedPortfolio,
            investmentAssetAllocation: data.investmentAssetAllocation,
            investmentFundHoldings: data.investmentFundHoldings
        };

        return new ExternalAssetInvestment(config);
    },

    // For integrated user
    createIntegratedAllocationtMix: function (allocation) {
        let totalEquity = 0;

        if (allocation) {
            totalEquity += allocation.usStocks + allocation.intlStocks + allocation.alternatives;
            totalEquity += allocation.unclassified * 0.6;

            // bonds
            totalEquity =
                Math.round(totalEquity) <= 100
                    ? Math.round(totalEquity) >= 0
                        ? Math.round(totalEquity)
                        : 0
                    : 100;

            return totalEquity;
        } else {
            return 0;
        }
    },

    // For integrated user
    createAllocationsForIntegratedAsset: function (allocation) {
        const config = {
            usStocks: allocation.usStocks,
            intlStocks: allocation.intlStocks,
            usBonds: allocation.usBonds,
            intlBonds: allocation.intlBonds,
            alternatives: allocation.alternatives,
            cash: allocation.cash,
            unclassified: allocation.unclassified
        };

        return new ExternalAssetAllocations(config);
    },

    createRetirementAssets: function (data) {
        if (data) {
            const list = [];
            _each(data, function (asset) {
                list.push(InitDataFactory.createRetirementAsset(asset));
            });
            return list;
        }
    },

    createRetirementAsset: function (data) {
        const estimatedEndAge = !data.forever ? data.startAge + data.duration : null;
        const config = {
            name: data.goalDescription,
            investorId: Number(data.pcapPersonId),
            investorAccountId: -1,
            estimatedAnnualAmount: data.annualAmount,
            costOfLivingAdjustable: !data.isNotCOLA,
            adjustmentAmount: data.isNotCOLA ? 0 : 3.0,
            taxable: !data.isPreTax,
            pension: null,
            estimatedStartYear: null,
            estimatedEndYear: null,
            estimatedStartAge: data.startAge,
            estimatedEndAge: estimatedEndAge,
            untilLifeExpectancy: data.forever,
            lastModifiedOn: null,
            dataSource: "PCAP",
            assetType: "retirementIncome"
        };

        return new RetirementAsset(config);
    },

    createOtherAssetsRefData: function (data) {
        let config;
        if (data) {
            config = {
                riskLevels: data.riskLevels,
                accountInvestmentTypes: data.accountInvestmentTypes,
                contributionTypes: data.contributionTypes,
                assetAllocationClassTypes: data.assetAllocationClassTypes,
                adviseOptionTypes: data.adviseOptionTypes,
                accountTypes: data.accountTypes,
                accountProviders: data.accountProviders
            };
        }
        return new OtherAssetsRefData(config);
    },

    createIrsLimitsRefData: function (data) {
        const limits = {};
        if (data && Object.keys(data)) {
            _each(Object.keys(data), function (limitKey) {
                const limit = data[limitKey];

                if (data[limitKey]) {
                    data[limitKey].rateOfInflation = data[limitKey].rateOfInflation
                        ? data[limitKey].rateOfInflation / 100
                        : data[limitKey].rateOfInflation;
                    data[limitKey].discountFactor = data[limitKey].discountFactor
                        ? data[limitKey].discountFactor / 100
                        : data[limitKey].discountFactor;
                }

                limits[limitKey] = new IRSLimits(limit);
            });
        }
        return limits;
    },

    createSsInfo: function (data) {
        // if (integrated) {
        return new IntegratedSocialSecurityInfo(data);
        // } else {
        //     var config = {
        //         socialSecurityPerson: InitDataFactory.createSsInfoPerson(
        //             data.socialSecurity,
        //             data.personId,
        //             data.investorId
        //         ),
        //         ssBenefitsMap: data
        //             ? InitDataFactory.createSSBenefitsMap(data.socialSecurity)
        //             : null
        //     };

        //     return new SsInfo(config);
        // }
    },

    createSSBenefitsMap: function (data) {
        const ssMap = {};
        if (data && data.sampleSocialSecurityTable) {
            _each(data.sampleSocialSecurityTable, function (ageGroup) {
                ssMap[ageGroup.age] = ageGroup.monthly;
            });
        }
        return ssMap;
    },

    createSsInfoPerson: function (data, personId, investorId) {
        if (data) {
            const config = {
                personId: personId,
                investorId: investorId
            };
            if (data) {
                config.isMonthlyAmount = data.isMonthlyAmount;
                config.monthlyAmount = data.monthlyAmount;
                config.maxAmount = data.maxAmount;
                config.monthlyAmountOverride = data.monthlyAmountOverride;
                config.collectionAgeOverride = data.collectionAgeOverride;
                config.dataSource = data.dataSource;
                config.overrideId = data.overrideId;
                config.sampleSocialSecurityTable = data.sampleSocialSecurityTable;
            }
            return new SsInfoPerson(config);
        }

        return null;
    },

    createDeferrals: function (data, deferralInfo, isPrimaryPlan) {
        //////////////////////////////////////////////////////////////////////////
        // Raw API data
        //////////////////////////////////////////////////////////////////////////
        /*
         "deferralTypeCode": {"type": "string" },
         "pctAmtCode": {"type": "string","enum": ["AMT", "PCT"],"description": "A code denoting whether the deferral value is a percentage or dollars"},
         "value": {"type": "number","description": "the value of the deferral. Percentages are in whole numbers (ie. 8 for 8%)."},
         "payrollOption": {"type": "string","enum": ["ONGOING", "ONETIME"],"description": "Describes the frequency of the deferral"},
         "status": {"type": "string","enum": ["A", "E", "F", "C"],"description": "A single letter code that describes the current state of the deferral."},
         "effDate": {"type": "string","description": "effective date of the deferral"},
         "nextPayrollDate":
         "defaulted": {"type": "boolean","description": "will be true if the deferral was created by an auto-enrollment"},
         "active": { "type": "boolean","description": "A flag that denotes which deferral should be displayed on the slider in the UI."},
         "autoIncrease": {
         "frequency": {"type": "number"},
         "increaseValue": {"type": "number"},
         "nextScheduleDate": {"type": "string","description": "date-string of the next increase date"},
         "pctAmtCode": {"type": "string","enum": ["AMT", "PCT"]},
         "stopAtValue": {"type": "number","description": "the value at which the increase stops"}
         },
         "electedMaximizerSelection": {"type": "string","enum": ["MAXR_ONETIME", "MAXR_ONGOING"], "description": "selected Maximizer frequency"},
         "electedMaximizerType": {"type": "string","enum": ["402G_CATCHUP", "402G", "MATCH"],"description": "selected Maximizer target"},
         "config": {
         "displayName": {"type": "string","description": "Short display name"},
         "descr": {"type": "string","description": "Longer description, think sub-title"},
         "granularity": {"type": "number","enum": [1, 0.1, 0.01],"description": "defines how granular a percentage or amount the deferral value can be set to."},
         "minDeferral": {"type": "number"},
         "maxDeferral": {"type": "number", "description": "defines the max value for the deferral for both amt and pct"},
         "maxSlider": the max slider value
         "defrlAvailCode": {"type": "string","enum": ["CHGALLOWED", "VIEWONLY", "NOTVISIBLE"]},
         "taxStatus": {"type": "string", "enum": ["BEFORE", "ROTH", "AFTER"]},
         "incomeStream": {"type": "string", "enum": ["Salary", "Bonus or Commission", "UNKNOWN", "Bonus"]},
         "ageCatchupApplicable": {"type": "string","enum": ["Y", "N"],"description": "Denotes a catchup specific deferral type. Will be null for catchup combined plans."},
         "allowAgeCombinedInd": {"type": "string","enum": ["Y", "N"], "description": "Tells us whether this deferral should be considered for catchup contributions if it overflows. Wil},
         "minRequiredInd": {"type": "string","enum": ["Y", "N"],"description": "Mandating the min is a 'hard min' meaning that 0 would not be an option is the min is 2"},
         "allowScheduleInd": {"type": "string","enum": ["Y", "N"],"description": "Can the deferral be auto-increased?"},
         "maximizeEligibleInd": {"type": "string","enum": ["Y", "N"],"description": "Is the deferral type one that can be used by Maximizer?"}
         }

         OPTIONAL FIELDS
         "electedMaximizerSelection"
         "electedMaximizerType"
         "autoIncrease"
         "config" -> "allowAgeCombinedInd"
         */
        if (data) {
            InitDataFactory.logger.debug("createDeferrals( Creating {0} deferrals )", [
                data.length
            ]);
            let config;
            let deferralConfig;
            let list = [];

            _each(data, function (item) {
                //check against allowableDeferrals
                _each(deferralInfo.availableDeferrals, function (allowableDeferral) {
                    if (
                        allowableDeferral.deferralTypeCode === item.deferralTypeCode &&
                        allowableDeferral.defrlAvailCode === "GRANDFATHERED"
                    ) {
                        item.grandfathered = true;
                    }
                });
                const limitConfig = {
                    maxDeferral: item.config.maxDeferral,
                    minDeferral: item.config.minDeferral,
                    maxSlider: item.config.maxSlider,
                    granularity: item.config.granularity
                };
                deferralConfig = {
                    displayName: item.config.displayName,
                    descr: item.config.descr,
                    granularity: item.config.granularity,
                    minDeferral: item.config.minDeferral,
                    maxDeferral: item.config.maxDeferral,
                    maxSlider: item.config.maxSlider,
                    defrlAvailCode: item.grandfathered
                        ? "GRANDFATHERED"
                        : item.config.defrlAvailCode,
                    taxStatus: item.config.taxStatus,
                    //NOTE: as of 12/17, incomeStream is in flux. It exists in some DB, but it's scheduled for removal
                    incomeStream: item.config.incomeStream,
                    ageCatchupApplicable: item.config.ageCatchupApplicable,
                    allowAgeCombinedInd: ObjectUtil.isUndefinedOrNull(
                        item.config.allowAgeCombinedInd
                    )
                        ? null
                        : item.config.allowAgeCombinedInd,
                    minRequiredInd: item.config.minRequiredInd === "Y", // either "Y" or "N"
                    allowScheduleInd: item.config.allowScheduleInd,
                    //NOTE: This is currently not in use. This is here for future flexibility.
                    //      It will be used to be able to maximize across any deferral type and allow users
                    //      to make deferral changes to non-max deferrals without canceling their Maximizer enrollment
                    maximizeEligibleInd: item.config.maximizeEligibleInd === "Y",
                    enrollmentGroupCode: item.config.enrollmentGroupCode
                };

                if (!limitConfig.granularity) {
                    limitConfig.granularity = 1;
                    InitDataFactory.logger.warn("no granularity given for deferral");
                }
                config = {
                    deferralTypeCode: item.deferralTypeCode,
                    nycGoalFlag: ObjectUtil.isUndefinedOrNull(item.nycGoalFlag)
                        ? null
                        : item.nycGoalFlag,
                    value: item.value,
                    deferralLimits: new DeferralLimits(limitConfig),
                    pctAmtCode: item.pctAmtCode,
                    payrollOption: item.payrollOption,
                    status: item.status,
                    effectiveDate: new Date(item.effDate),
                    effectiveDateString: item.effDate,
                    nextPayrollDate: item.nextPayRollDate,
                    submissionDate: item.submissionDate,
                    defaulted: item.defaulted,
                    grandfathered: item.grandfathered,
                    active: item.active, //TODO: TJM this is the active deferral indicator, find where we make that determination and modify
                    autoIncrease: InitDataFactory.createAutoIncrease(item.autoIncrease),
                    futureDated: InitDataFactory.createFutureDatedDeferral(item.futures),
                    electedMaximizerSelection: ObjectUtil.isUndefinedOrNull(
                        item.electedMaximizerSelection
                    )
                        ? null
                        : item.electedMaximizerSelection,
                    electedMaximizerType: ObjectUtil.isUndefinedOrNull(item.electedMaximizerType)
                        ? null
                        : item.electedMaximizerType,
                    csor: item.csor,
                    config: new DeferralConfig(deferralConfig),
                    isPrimaryAndActive: isPrimaryPlan && item.active,

                    //TODO: TJM we could possibly do away with this, BUT, it's everywhere so maybe this is better?
                    valueUnits:
                        !ObjectUtil.isUndefinedOrNull(item.pctAmtCode) && item.pctAmtCode === "PCT"
                            ? DeferralMoneyType.PERCENT
                            : DeferralMoneyType.DOLLAR
                };
                list.push(new Deferral(config));
                deferralInfo.enrollmentGroupCodeHash[`${deferralConfig.enrollmentGroupCode}`] =
                    config.valueUnits;
            });

            list = list.sort(InitDataFactory.sortForDeferrals);
            return list;
        } else {
            InitDataFactory.logger.warn(
                "createDeferrals( Cannot create deferrals as it's not in the data. )"
            );
            return null;
        }
    },

    sortForDeferrals: function (deferralA, deferralB) {
        const groupCodeA = deferralA.config.enrollmentGroupCode,
            groupCodeB = deferralB.config.enrollmentGroupCode,
            displayOrderA = deferralA.config.displayOrder,
            displayOrderB = deferralB.config.displayOrder;
        if (groupCodeA !== groupCodeB) {
            return EnrollmentGroupSort[groupCodeA] - EnrollmentGroupSort[groupCodeB];
        } else {
            return displayOrderA - displayOrderB;
        }
    },

    createDeferralInfo: function (data, deferrals) {
        const config = {
            catchUp402GInd: false,
            allowableDeferrals: [],
            enrollmentGroupCodeHash: {},
            combinedRules: [],
            tierRules: [],
            typeCodeMap: {}
        };

        if (data) {
            config.catchUp402GInd = ObjectUtil.getBooleanFromIndicator(data.catchUp402GInd);
            const grandfatheredEnrollmentGroupCodes =
                InitDataFactory.findGrandfatheredEnrollmentGroupCodes(
                    data.allowableDeferrals,
                    deferrals
                );

            //run through a second time to see which allowableDeferrals need to be grandfathered, create allowable;
            _each(data.allowableDeferrals, function (allowableDeferral) {
                if (grandfatheredEnrollmentGroupCodes[allowableDeferral.enrollmentGroupCode]) {
                    allowableDeferral.defrlAvailCode = "GRANDFATHERED";
                }
                const allowableDeferralObject = new AllowableDeferral(allowableDeferral);
                config.allowableDeferrals.push(allowableDeferralObject);
                config.enrollmentGroupCodeHash[allowableDeferralObject.enrollmentGroupCode] = null;
            });

            config.allowableDeferrals = config.allowableDeferrals.sort(
                InitDataFactory.sortForAllowableDeferral
            );

            _each(data.combinedRules, function (rule) {
                config.combinedRules.push(new CombinedDeferralRule(rule));
            });

            _each(data.tierRules, function (rule) {
                config.tierRules.push(new TierDeferralRule(rule));
            });

            if (config.combinedRules.length && deferrals) {
                config.typeCodeMap = fillTypeCodeMap();
            }
        }

        function fillTypeCodeMap() {
            const typeCodeMap = {};

            config.combinedRules.forEach((rule, id) => {
                let pctAmtCode = null;
                let ruleIndex = id;

                rule.deferralTypeCodes.forEach((code) => {
                    let isMandatory, isViewOnly;
                    let groupCode = "";
                    let hasConflict = false;

                    hasConflict = typeCodeMap[code] ? typeCodeMap[code].hasConflict : hasConflict;

                    config.allowableDeferrals.forEach((availDeferral) => {
                        if (availDeferral.deferralTypeCode === code) {
                            isMandatory = availDeferral.mandatoryDeferralInd === "Y";
                            isViewOnly = availDeferral.defrlAvailCode !== "CHGALLOWED";
                        }
                    });

                    for (let i = 0; i < deferrals.length; i++) {
                        if (rule.deferralTypeCodes.indexOf(deferrals[i].deferralTypeCode) > -1) {
                            // check allowable deferrals for conflict
                            for (let j = 0; j < config.allowableDeferrals.length; j++) {
                                if (
                                    code === config.allowableDeferrals[j].deferralTypeCode &&
                                    config.allowableDeferrals[j].pctAmtCode !== "AMT_PCT" &&
                                    deferrals[i].pctAmtCode !==
                                        config.allowableDeferrals[j].pctAmtCode
                                ) {
                                    hasConflict = true;
                                    break;
                                }
                            }

                            pctAmtCode = deferrals[i].pctAmtCode;
                            break;
                        } else {
                            for (let k = 0; k < config.allowableDeferrals.length; k++) {
                                if (config.allowableDeferrals[k].deferralTypeCode === code) {
                                    pctAmtCode = config.allowableDeferrals[k].pctAmtCode;
                                    break;
                                }
                            }
                            break;
                        }
                    }

                    groupCode = config.allowableDeferrals.find(
                        (def) => def.deferralTypeCode === code
                    ).enrollmentGroupCode;

                    rule.deferralTypeCodes.forEach((cde) => {
                        if (typeCodeMap[cde]) {
                            if (pctAmtCode === "AMT_PCT") {
                                pctAmtCode = typeCodeMap[cde].pctAmtCode;
                            }
                            ruleIndex = typeCodeMap[cde].ruleIndex;
                        }
                    });

                    typeCodeMap[code] = {
                        pctAmtCode,
                        ruleIndex,
                        isMandatory,
                        isViewOnly,
                        hasConflict,
                        groupCode
                    };
                });
            });

            config.allowableDeferrals.forEach((def) => {
                const groupCode = def.enrollmentGroupCode;
                let pctAmtCode = "AMT_PCT";

                if (!typeCodeMap[def.deferralTypeCode]) {
                    // Loop through deferrals
                    deferrals.forEach((d) => {
                        if (d.deferralTypeCode === def.deferralTypeCode) {
                            pctAmtCode = d.pctAmtCode;
                        }
                    });

                    typeCodeMap[def.deferralTypeCode] = {
                        pctAmtCode: pctAmtCode,
                        groupCode
                    };

                    if (pctAmtCode !== "AMT_PCT") {
                        for (const key in typeCodeMap) {
                            if (
                                groupCode === typeCodeMap[key].groupCode &&
                                Object.prototype.hasOwnProperty.call(typeCodeMap[key], "ruleIndex")
                            ) {
                                const index = typeCodeMap[key].ruleIndex;
                                typeCodeMap[key].pctAmtCode = pctAmtCode;

                                for (const k in typeCodeMap) {
                                    if (
                                        typeCodeMap[k].ruleIndex === index &&
                                        k !== def.deferralTypeCode &&
                                        k !== key
                                    ) {
                                        typeCodeMap[k].pctAmtCode = pctAmtCode;
                                    }
                                }
                            }
                        }
                    }
                }

                for (const type in typeCodeMap) {
                    const index = typeCodeMap[def.deferralTypeCode].ruleIndex;

                    if (
                        groupCode === typeCodeMap[type].groupCode &&
                        type !== def.deferralTypeCode
                    ) {
                        if (
                            !typeCodeMap[def.deferralTypeCode] ||
                            typeCodeMap[def.deferralTypeCode].pctAmtCode === "AMT_PCT"
                        ) {
                            if (
                                !ObjectUtil.isUndefinedOrNull(
                                    typeCodeMap[def.deferralTypeCode].ruleIndex
                                )
                            ) {
                                typeCodeMap[def.deferralTypeCode].pctAmtCode =
                                    typeCodeMap[type].pctAmtCode;

                                Object.entries(typeCodeMap).forEach((entry) => {
                                    if (
                                        index === entry[1].ruleIndex &&
                                        entry[1].pctAmtCode === "AMT_PCT"
                                    ) {
                                        typeCodeMap[entry[0]].pctAmtCode =
                                            typeCodeMap[type].pctAmtCode;
                                    }
                                });
                            } else {
                                typeCodeMap[def.deferralTypeCode] = {
                                    pctAmtCode: typeCodeMap[type].pctAmtCode,
                                    groupCode: def.enrollmentGroupCode
                                };
                            }
                        }
                    }
                }
            });

            return typeCodeMap;
        }

        return new DeferralInfo(config);
    },

    findGrandfatheredEnrollmentGroupCodes: function (allowableDeferrals, deferrals) {
        const grandfatheredEnrollmentGroupCodes = {};
        let hasGrandfatheredDeferrals = false;

        //find out which enrollmentGroupCodes have grandfathered deferrals
        _each(allowableDeferrals, function (allowableDeferral) {
            _each(deferrals, function (deferral) {
                if (
                    allowableDeferral.defrlAvailCode === "CHGALLOWED" &&
                    deferral.deferralTypeCode === allowableDeferral.typeCode &&
                    allowableDeferral.pctAmtCode !== "AMT_PCT" &&
                    deferral.pctAmtCode !== allowableDeferral.pctAmtCode &&
                    deferral.deferralTypeCode !== "HSA"
                ) {
                    grandfatheredEnrollmentGroupCodes[allowableDeferral.enrollmentGroupCode] = true;
                    hasGrandfatheredDeferrals = true;
                }
            });
        });

        //Until Core fixes it, if a participant has any grandfathered deferrals, all deferrals are considered grandfathered.
        //after Core fixes it, just take out this if statement and only grandfathered deferrals (and deferrals in the same enrollment group code) will halt submission
        if (hasGrandfatheredDeferrals) {
            _each(allowableDeferrals, function (allowableDeferral) {
                grandfatheredEnrollmentGroupCodes[allowableDeferral.enrollmentGroupCode] = true;
            });
        }

        return grandfatheredEnrollmentGroupCodes;
    },

    sortForAllowableDeferral: function (deferralA, deferralB) {
        const groupCodeA = deferralA.enrollmentGroupCode,
            groupCodeB = deferralB.enrollmentGroupCode,
            displayOrderA = deferralA.displayOrder,
            displayOrderB = deferralB.displayOrder;
        if (groupCodeA !== groupCodeB) {
            return EnrollmentGroupSort[groupCodeA] - EnrollmentGroupSort[groupCodeB];
        } else {
            return displayOrderA - displayOrderB;
        }
    },

    createHealthViewData: function (data) {
        InitDataFactory.logger.debug("createHealthViewData()");

        const config = {
            projections: [],
            chartLegends: null,
            healthSurvey: new HealthSurvey(),
            salaryBrackets: data.salaryBrackets,
            yieldThreshold: data.yieldThreshold,
            warnThreshold: data.warnThreshold
        };

        if (!ObjectUtil.isUndefinedOrNull(data.projections)) {
            config.projections = InitDataFactory.createProjections(data.projections);
        }
        if (!ObjectUtil.isUndefinedOrNull(data.chartLegends)) {
            config.chartLegends = InitDataFactory.createChartLegends(data.chartLegends);
        }
        if (!ObjectUtil.isUndefinedOrNull(data.survey)) {
            config.healthSurvey = InitDataFactory.createHealthSurvey(data.survey);
        }
        return new HealthViewData(config);
    },

    createChartLegends: function (legends) {
        InitDataFactory.logger.debug("createChartLegends( Creating {0} legends )", [
            legends.length
        ]);

        const list = [];
        let config;
        _each(legends, function (item) {
            if (item) {
                config = {
                    id: item.id,
                    color: item.color,
                    label: item.label,
                    toolTip: item.toolTip
                };
                const object = new ChartLegend(config);
                list.push(object);
            }
        });
        return list;
    },

    createProjections: function (projections) {
        InitDataFactory.logger.debug("createProjections( Creating {0} projections )", [
            projections.length
        ]);

        const list = [];
        let config;
        _each(projections, function (item) {
            if (item) {
                config = {
                    attainedAge: item.attainedAge,
                    oopChartConfigs: InitDataFactory.createChartConfigs(item.oopChartConfigs),
                    premiumChartConfigs: InitDataFactory.createChartConfigs(
                        item.premiumChartConfigs
                    ),
                    totalHealthCareCost: item.totalHealthCareCost,
                    totalOOPCost: item.totalOOPCost,
                    totalPremiumCost: item.totalPremiumCost
                };
                const object = new HealthViewProjection(config);
                list.push(object);
            }
        });
        return list;
    },

    createHealthSurvey: function (healthSurvey) {
        InitDataFactory.logger.debug("createHealthSurvey()");

        const config = {
            pptHealthConditions: InitDataFactory.createConditions(healthSurvey.conditions),
            selectedRetirementState: healthSurvey.selectedRetirementState,
            states: healthSurvey.states
        };

        return new HealthSurvey(config);
    },

    createChartConfigs: function (chartConfigs) {
        const list = [];
        let config;
        _each(chartConfigs, function (item) {
            if (item) {
                config = {
                    amount: item.amount,
                    id: item.id,
                    percentage: item.percentage
                };
                const object = new HVSChartConfig(config);
                list.push(object);
            }
        });
        return list;
    },

    createConditions: function (conditions) {
        if (ObjectUtil.isUndefinedOrNull(conditions)) {
            conditions = [];
        }
        InitDataFactory.logger.debug("createConditions( Creating {0} conditions)", [
            conditions.length
        ]);

        const list = [];
        let config;
        _each(conditions, function (item) {
            config = {
                id: item.id,
                conditionCode: item.conditionCode,
                conditionName: item.conditionName,
                isPreselected: item.preSelected,
                actionCode: item.actionCode,
                tooltip: item.tooltip,
                faq: item.faq
            };
            const object = new Condition(config);
            list.push(object);
        });
        return list;
    },

    /**
     * Returns a HowDoICompare object. Called from the service delegate that retrieves the data.
     * @param data
     * @returns {HowDoICompare}
     */
    createHowDoICompareData: function (data) {
        InitDataFactory.logger.debug("createHowDoICompareData( {0} )", [data]);

        const config = {
            ageGroups: InitDataFactory.createAgeGroups(data.ageGroups),
            genderList: InitDataFactory.createGenderList(data.genderList),
            salaryGroups: InitDataFactory.createSalaryGroups(data.salaryGroups),
            scoreMap: data.scores,
            yieldThreshold: data.yieldThreshold,
            warnThreshold: data.warnThreshold
        };

        if (ObjectUtil.isString(config.yieldThreshold)) {
            config.yieldThreshold = Number(config.yieldThreshold);
        }
        if (ObjectUtil.isString(config.warnThreshold)) {
            config.warnThreshold = Number(config.warnThreshold);
        }

        return new HowDoICompare(config);
    },

    /**
     * Returns a list of DataGroup objects for the Age groups
     * @param data
     * @returns {Array}
     * {
                "id": "A0",
                "to": 29.99,
                "from": 0.0,
                "label": "Under 30"
              }
     */
    createAgeGroups: function (data) {
        InitDataFactory.logger.debug("createAgeGroups( {0} )", [data]);

        const list = [];
        let config;
        _each(data, function (item) {
            config = {
                id: item.id,
                to: item.to,
                from: item.from,
                label: item.label
            };
            const object = new DataGroup(config);
            list.push(object);
        });

        return list;
    },

    /**
     * Returns a list of DataGroup objects for the HDIC gender list
     * @param data
     * @returns {Array}
     * {
                "id": "G0",
                "to": 0.0,
                "from": 0.0,
                "label": "Female"
              }
     */
    createGenderList: function (data) {
        InitDataFactory.logger.debug("createGenderList( {0} )", [data]);

        const list = [];
        let config;
        _each(data, function (item) {
            config = {
                id: item.id,
                to: item.to,
                from: item.from,
                label: item.label
            };
            const object = new DataGroup(config);
            list.push(object);
        });

        return list;
    },

    /**
     * Returns a list DataGroup objects for the HDIC salary groupings
     * @param data
     * @returns {Array}
     *
     * {
                "id": "S0",
                "to": 24999.99,
                "from": 0.0,
                "label": "Under 25K"
              }
     */
    createSalaryGroups: function (data) {
        InitDataFactory.logger.debug("createSalaryGroups( {0} )", [data]);

        const list = [];
        let config;
        _each(data, function (item) {
            config = {
                id: item.id,
                to: item.to,
                from: item.from,
                label: item.label
            };
            const object = new DataGroup(config);
            list.push(object);
        });

        return list;
    },

    createOneClickSolution: function (data, currentInvestments) {
        InitDataFactory.logger.debug("createOneClickSolution()");

        const getIsEnrolled = function (data) {
            return (
                data.isCurrentInRiskBasedFund ||
                data.currentAllocationModel ||
                data.isCurrentInTargetDateFund
            );
        };

        const getIsAvailable = function (data) {
            return data.allowRiskBasedFunds || data.allowTargetDateFunds || data.assetModelsAllowed;
        };

        const config = {
            isAvailable: getIsAvailable(data),
            isEnrolled: getIsEnrolled(data),
            isCurrentInRiskBasedFund: !_isUndefined(data.isCurrentInRiskBasedFund)
                ? data.isCurrentInRiskBasedFund
                : false,
            isCurrentInTargetDateFund: !_isUndefined(data.isCurrentInTargetDateFund)
                ? data.isCurrentInTargetDateFund
                : false,
            allowRiskBasedFunds: !_isUndefined(data.allowRiskBasedFunds)
                ? data.allowRiskBasedFunds
                : false,
            allowTargetDateFunds: !_isUndefined(data.allowTargetDateFunds)
                ? data.allowTargetDateFunds
                : false,
            assetModelsAllowed: !_isUndefined(data.assetModelsAllowed)
                ? data.assetModelsAllowed
                : false,
            currentAllocationModel: !_isUndefined(data.currentAllocationModel)
                ? data.currentAllocationModel
                : false,
            modelName: !_isUndefined(data.modelName) ? data.modelName : null,
            enrolledOptionsNames: !_isUndefined(currentInvestments) ? currentInvestments : []
        };

        return new OneClickSolution(config);
    },

    createCompanyMatch: function (data) {
        InitDataFactory.logger.debug("createCompanyMatch()");
        // company match conversion

        if (!ObjectUtil.isUndefinedOrNull(data) && data.length) {
            const sortedCompanyMatchRules = _sortBy(data, "plmuRuleId");
            const matchTiers = [];
            const sources = [];
            const ruleTypes = [];
            let matchGroups = [];

            let currentId = sortedCompanyMatchRules[0].plmuRuleId;

            _each(sortedCompanyMatchRules, (rule) => {
                if (sources.indexOf(rule.source) === -1) {
                    const multipleSources = rule.source.split(",");

                    if (multipleSources.length > 1) {
                        _each(multipleSources, (source) => {
                            if (sources.indexOf(source) === -1) {
                                sources.push(source);
                            }
                        });
                    } else {
                        sources.push(rule.source);
                    }
                }
                if (ruleTypes.indexOf(rule.ruleType) === -1) {
                    ruleTypes.push(rule.ruleType);
                }
            });

            const matchableDeferralTypes = _compact(sources);

            const matchableDeferralTypesUpd = [];
            matchableDeferralTypes.forEach(function (item) {
                item = String(item).trim();
                matchableDeferralTypesUpd.push(item);
            });

            _forEach(sortedCompanyMatchRules, (tier, id) => {
                const tierPriority = Number(tier.tierCd);

                const newTier = {
                    lowThresholdPercent: tier.lowThresholdPercent,
                    highThresholdPercent: tier.highThresholdPercent,
                    lowThresholdDollar: tier.lowThresholdDollar,
                    highThresholdDollar: tier.highThresholdDollar,
                    matchPercent: tier.matchPercent,
                    maxMatchDollar: tier.maxMatchDollar,
                    maxMatchPercent: tier.maxMatchPercent,
                    minYrOfService: tier.minYrOfService,
                    maxYrOfService: tier.maxYrOfService,
                    tierPriority: tierPriority,
                    description: ObjectUtil.isUndefinedOrNull(tier.ruleDesc)
                        ? ""
                        : tier.ruleDesc.trim(),
                    profitSharing: tier.profitSharing,
                    ruleId: tier.plmuRuleId,
                    ruleType: tier.ruleType,
                    ruleDesc: tier.ruleDesc,
                    effDate: tier?.effDate,
                    termDate: tier?.termDate
                };

                if (tier.plmuRuleId === currentId) {
                    matchGroups.push(newTier);
                } else {
                    matchTiers.push(_sortBy(matchGroups, "tierPriority"));
                    matchGroups = [];
                    matchGroups.push(newTier);
                    currentId = tier.plmuRuleId;
                }
                if (id === sortedCompanyMatchRules.length - 1) {
                    matchTiers.push(_sortBy(matchGroups, "tierPriority"));
                }
            });

            const config = {
                matchTiers: matchTiers,
                matchableDeferralTypes: matchableDeferralTypesUpd,
                ruleTypes: ruleTypes
            };

            return new CompanyMatch(config);
        } else {
            return new CompanyMatch();
        }
    },

    /**
     * Employer match uses the new payroll system.  This is used for most plans with the exception of Apple
     * and possibly a couple others.
     * @param data
     * @returns {Array}
     */
    createEmployerMatch: function (data) {
        const rules = [];
        _each(data, function (matchRule) {
            const crits = InitDataFactory.createMatchRuleCriterias(matchRule.criterias);
            const config = {
                matchCriteria: MatchTierFactory.create(crits),
                moneyTypes: InitDataFactory.createMoneyTypes(matchRule.moneyTypes),
                ageCatchupErMatchCalInd: matchRule.aAgeCatchUpErMatchCalcInd
            };
            if (matchRule.planMatchRule) {
                config.id = matchRule.planMatchRule.id;
                config.planId = matchRule.planMatchRule.planId;
                config.calcType = matchRule.planMatchRule.calcType;
                config.calcLevel = matchRule.planMatchRule.calcLevel;
                config.calcSource = matchRule.planMatchRule.calcSource;
                config.sdmtCode = matchRule.planMatchRule.sdmtCode;
                config.gdmtSeqnbr = matchRule.planMatchRule.gdmtSeqnbr;
                config.description = ObjectUtil.isUndefinedOrNull(
                    matchRule.planMatchRule.description
                )
                    ? ""
                    : matchRule.planMatchRule.description.trim();
            }
            rules.push(new PlanMatchRule(config));
        });
        return rules;
    },

    createMoneyTypes: function (moneytypes) {
        const types = [];
        _each(moneytypes, function (moneytype) {
            const config = {};
            if (moneytype.planMatchRuleGrpDefMnty) {
                const pmrGrpDefMnty = moneytype.planMatchRuleGrpDefMnty;
                config.planMatchRuleGrpDefMnty = {
                    pmrId: pmrGrpDefMnty.pmrId,
                    gaId: pmrGrpDefMnty.gaId,
                    matchBasedOnSdmtCode: pmrGrpDefMnty.matchBasedOnSdmtCode,
                    matchBasedOnGdmtCode: pmrGrpDefMnty.matchBasedOnGdmtCode
                };
            }

            const grpDefMntyDefType = moneytype.aGrpDefMntyDeferralTypeDTO;
            config.grpDefMntyDefType = [];
            if (grpDefMntyDefType && grpDefMntyDefType.length > 0) {
                _each(grpDefMntyDefType, function (assoc) {
                    config.grpDefMntyDefType.push({
                        id: assoc.id,
                        sdmtCode: assoc.sdmtCode,
                        gdmtSeq: assoc.gdmtSeqnbr,
                        deferralCode: assoc.defrlCode,
                        includeInLiatMatchInd: assoc.includeinLiatMatchInd
                    });
                });
            }
            if (moneytype.grpDefMnty) {
                config.grpDefMnty = {
                    gaId: moneytype.grpDefMnty.gaId,
                    sdmtCode: moneytype.grpDefMnty.sdmtCode,
                    seqnbr: moneytype.grpDefMnty.seqnbr,
                    descr: moneytype.grpDefMnty.descr,
                    stdSourceCode: moneytype.grpDefMnty.stdSourceCode,
                    stdTaxStatus: moneytype.grpDefMnty.stdTaxStatus
                };
            }
            types.push(new PlanMatchRuleMoneyType(config));
        });
        return types;
    },

    createMatchRuleCriterias: function (criterias) {
        if (!ObjectUtil.isUndefinedOrNull(criterias)) {
            const crits = [];
            _each(criterias, function (crit) {
                const config = {
                    planMatchRuleRates: InitDataFactory.createMatchRuleRates(
                        crit.planMatchRuleRates
                    ),
                    planMatchRuleCritDetls: InitDataFactory.createMatchRuleCritDtls(
                        crit.planMatchRuleCritDetls
                    )
                };
                if (crit.planMatchRuleCrit) {
                    config.id = crit.planMatchRuleCrit.id;
                    config.pmrId = crit.planMatchRuleCrit.pmrId;
                    // PART OF THE FIX FOR DEFC-11618
                    config.seqnbr = crit.planMatchRuleCrit.seqnbr;
                    config.tierCalcLevel = crit.planMatchRuleCrit.tierCalcLevel;
                    config.matchCalculatedOn = crit.planMatchRuleCrit.matchCalculatedOn;
                }
                crits.push(new PlanMatchRuleCriteria(config));
            });
            return crits;
        }
    },

    createMatchRuleRates: function (rates) {
        if (!ObjectUtil.isUndefinedOrNull(rates)) {
            const matchRuleRates = [];
            _each(rates, function (rate) {
                const config = {
                    tierBasedOn: rate.tierBasedOn,
                    matchPct: rate.matchPct,
                    lowThreshold: rate.lowThreshold,
                    highThreshold: rate.highThreshold,
                    matchPctStartDate: rate.matchPctStartDate,
                    matchPctStopDate: rate.matchPctStopDate
                };
                matchRuleRates.push(new PlanMatchRuleRate(config));
            });
            return matchRuleRates;
        }
    },
    createMatchRuleCritDtls: function (dtls) {
        if (!ObjectUtil.isUndefinedOrNull(dtls)) {
            const critDtls = [];
            _each(dtls, function (detail) {
                const config = {
                    basis: detail.basis,
                    frequency: detail.basisFreq,
                    maxAmt: detail.maxAmt,
                    maxPct: detail.maxPct
                };
                critDtls.push(new PlanMatchRuleCriteriaDetail(config));
            });
            return critDtls;
        }
    },

    createDeferralRestrictions: function (data) {
        if (data) {
            const config = {
                restricted: data.restricted,
                code: data.code,
                message: data.message
            };
            const logMsg = "Error!";

            InitDataFactory.logger.debug("createDeferralRestrictions({0})", [logMsg]);
            return new DeferralRestrictions(config);
        } else {
            InitDataFactory.logger.warn(
                "createDeferralRestrictions( deferral restrictions does not exist, creating object with default value )"
            );
            return new DeferralRestrictions();
        }
    },

    createHsaDetails: function (trsFlexAccountInfo, hsaDetails) {
        if (
            !ObjectUtil.isUndefinedOrNull(trsFlexAccountInfo) &&
            !ObjectUtil.isUndefinedOrNull(hsaDetails)
        ) {
            const config = {
                acctStatus: trsFlexAccountInfo.acctStatus,
                expenseSelectionInd: hsaDetails.expenseSelectionInd,
                customExpenseAmount: hsaDetails.customExpenseAmount,
                aggrPriorYearSpend: trsFlexAccountInfo.aggrPriorYearSpend,
                coverageType: trsFlexAccountInfo.coverageType,
                nationalAverageSingle: hsaDetails.nationalAverageValues.hsaAverageSingle,
                nationalAverageSingleCatchUp:
                    hsaDetails.nationalAverageValues.hsaAverageSingleCatchUp,
                nationalAverageFamily: hsaDetails.nationalAverageValues.hsaAverageFamily,
                nationalAverageFamilyCatchUp:
                    hsaDetails.nationalAverageValues.hsaAverageFamilyCatchUp
            };

            InitDataFactory.logger.debug("createHsaDetails()");
            return new HsaDetails(config);
        } else {
            return null;
        }
    },

    /**
     * Last modified will always be current date.
     * PersonId is the investorId for LinkedAccountBalances
     * @param {} balanceData
     */
    createLinkedAccountBalances: function (balanceData, investorId) {
        const balances = [];
        const date = new Date();
        const strDate =
            date.getFullYear() +
            "-" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + date.getDate()).slice(-2);

        _each(balanceData, (balanceObj) => {
            _each(balanceObj.plans, (plan) => {
                const totalBalance = plan.totalBalance || 0;
                const newBalanceObj = {
                    individualId: balanceObj.individualId,
                    investorId: investorId ? investorId : "",
                    planId: plan.id,
                    name: plan.planName,
                    assetType: Object.prototype.hasOwnProperty.call(plan, "trsFlexAccountInfo")
                        ? "OAB_TRS"
                        : "OAB_PLAN",

                    trsFlexAccountInfo: Object.prototype.hasOwnProperty.call(
                        plan,
                        "trsFlexAccountInfo"
                    )
                        ? plan.trsFlexAccountInfo
                        : null,
                    value: !Object.prototype.hasOwnProperty.call(plan, "trsFlexAccountInfo")
                        ? totalBalance
                        : 0,
                    monthlyIncomeEST: 0,
                    type: 23,
                    typeString: "Lump sum ",
                    isEditable: true,
                    isDeletable: false,
                    isViewable: true,
                    employerContribution: false,
                    lastModifiedOn: strDate
                };

                if (newBalanceObj.trsFlexAccountInfo) {
                    newBalanceObj.trsFlexAccountInfo.isLinkedAccountBalances = true;

                    if (newBalanceObj.trsFlexAccountInfo.sdsvSubcode === "ANNUITY") {
                        newBalanceObj.type = 21;
                        newBalanceObj.typeString = "Monthly income in retirement ";
                    }

                    if (
                        newBalanceObj.trsFlexAccountInfo.earlyRetirementDate ||
                        newBalanceObj.trsFlexAccountInfo.normalRetirementDate
                    ) {
                        newBalanceObj.trsFlexAccountInfo.earlyRetirementDate =
                            ObjectUtil.formatDate(
                                newBalanceObj.trsFlexAccountInfo.earlyRetirementDate
                            );
                        newBalanceObj.trsFlexAccountInfo.normalRetirementDate =
                            ObjectUtil.formatDate(
                                newBalanceObj.trsFlexAccountInfo.normalRetirementDate
                            );
                    }
                    if (newBalanceObj.trsFlexAccountInfo.costOfLivingAdjustedPercentage) {
                        newBalanceObj.trsFlexAccountInfo.costOfLivingAdjustedPercentage =
                            Number(
                                newBalanceObj.trsFlexAccountInfo.costOfLivingAdjustedPercentage
                            ) / 100;
                    } else {
                        newBalanceObj.trsFlexAccountInfo.costOfLivingAdjustedPercentage = null;
                    }
                }

                balances.push(new LinkedAccountBalances(newBalanceObj));
            });
        });

        return balances;
    },

    findActivePlan: function (plans) {
        let plan = _find(plans, { isPrimary: true });
        plan = !plan && _isArray(plans) && plans.length > 0 ? plans[0] : plan;
        InitDataFactory.logger.debug(
            "findActivePlan( " + (plan ? "Found plan: " + plan.id : "WARNING: No plan found") + " )"
        );
        return _isUndefined(plan) ? null : plan;
    },
    //-----------------------------------------------------------------------
    // HELPER METHODS
    //-----------------------------------------------------------------------

    convertToBoolean: function (value) {
        return value.toLowerCase() === "y" || value === true;
    }

    /**
     * Creates the map of HDIC Scores
     * @param data
     * @returns {{}}
     *
     * @example
     * "A4S2G090": {
                "topContRate": 15.0,
                "medLisNoSS": 3.0,
                "topLisNoSS": 22.0,
                "medLis": 44.0,
                "medBalance": 55331.0,
                "medContRate": 6.0,
                "topLis": 64.0,
                "topBalance": 158647.81
              }
     */
    //createScoreMap: function(data) {
    //    InitDataFactory.logger.debug("createHowDoICompareData( {0} )", [data]);
    //
    //    var list = [];
    //    var config;
    //    _each(data, function(item) {
    //        config = {
    //            topContRate: item.topContRate,
    //            topBalance: item.topBalance,
    //            topLis: item.topLis,
    //            topLisNoSS: item.topLisNoSS,
    //            medContRate: item.medContRate,
    //            medBalance: item.medBalance,
    //            medLis: item.medLis,
    //            medLisNoSS: item.medLisNoSS
    //        };
    //        var object = new Score(config);
    //        list.push(object);
    //    });
    //
    //    return list;
    //}
};

export default InitDataFactory;
