import { setHowDoICompareData, setHDICError } from "../actions/howDoICompare/howDoICompareActions";
import { setCurrentFault } from "../actions/shared/sharedActionCreators";
import { getHowDoICompare } from "../services/howDoICompareService";

export const loadHowDoICompare = () => {
    return (dispatch) => {
        return getHowDoICompare()
            .then((response) => {
                if (response) {
                    return dispatch(setHowDoICompareData(response));
                }
            })
            .catch((fault) => {
                dispatch(setHDICError());
                if (!!fault && fault.hasCode) {
                    dispatch(setCurrentFault({ ...fault, stayOnCurrentPage: true }));
                    throw fault;
                }
            });
    };
};
