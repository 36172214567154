import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";

import { getPlanIrsCodeForDisplay, isCompanyMatchTypeCsor } from "../nextStepHelpers";

const CompanyMatchTitle = ({ plan }) => {
    const bestNextStep = selectTranslations("bestNextStep");

    const header = isCompanyMatchTypeCsor(plan)
        ? bestNextStep.employerMatchHeader
        : bestNextStep.companyMatchHeader;
    return (
        <h3 className="company-match-message-title">
            {`${getPlanIrsCodeForDisplay(plan.irsCode)} - ${header}`}
        </h3>
    );
};
CompanyMatchTitle.propTypes = {
    plan: PropTypes.object
};
export { CompanyMatchTitle };
