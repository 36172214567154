import { IOtherAsset } from "../../../../types/common";

const hasOtherAssetsAccounts = (otherAssets: IOtherAsset) => {
    return (
        otherAssets.retirementAssets.length > 0 ||
        otherAssets.linkedAccountBalances.length > 0 ||
        otherAssets.externalAssets.length > 0
    );
};

export default hasOtherAssetsAccounts;
