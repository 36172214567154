import {
    SET_SHOW_PAYCHECK_DETAILS,
    UPDATE_PAYCHECK_DETAILS_TOOLTIPS,
    SET_PLANS_WITH_MY_FUTURE_CONTRIBUTION,
    SET_NO_MATCHES_OR_PROFIT_SHARING,
    SET_PLAN_TABLES,
    SET_NQ_INFO,
    HAS_LEFT_ARROW_FOCUS
} from "../../actions/paycheck/paycheckTypes";

const initialState = {
    employerPastContribution: [],
    employerFutureContributions: [],
    myCurrentSavingPlans: [],
    myFutureSavingPlans: [],
    noMatchesOrProfitSharing: true,
    nqInfo: [],
    nqPlansEligible: [],
    plansWithEmployerPast: [],
    plansWithEmployerFuture: [],
    plansWithMyPastContributions: [],
    plansWithMyFutureContributions: [],
    showPaycheckViewDetails: false,
    showMyCurrentSavingPlansTooltip: false,
    showMyFutureSavingPlansTooltip: false,
    showEmployerPastContributionTooltip: false,
    showEmployerFutureContributionsTooltip: false,
    hasLeftArrowFocus: false
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_SHOW_PAYCHECK_DETAILS:
            return { ...state, showPaycheckViewDetails: payload };
        case UPDATE_PAYCHECK_DETAILS_TOOLTIPS:
            return { ...state, ...payload };
        case SET_PLANS_WITH_MY_FUTURE_CONTRIBUTION:
            return { ...state, plansWithMyFutureContributions: payload };
        case SET_NO_MATCHES_OR_PROFIT_SHARING:
            return { ...state, noMatchesOrProfitSharing: payload };
        case SET_PLAN_TABLES:
            return { ...state, ...payload };
        case SET_NQ_INFO:
            return { ...state, nqInfo: payload };
        case HAS_LEFT_ARROW_FOCUS:
            return { ...state, hasLeftArrowFocus: payload };
        default:
            return state;
    }
};
