/**
 * @class common.model.domain.PlanMatchRuleCritDetail
 * @copyright 2015 Empower Retirement
 * @description
 * Creates a defined object structure for the PlanMatchRuleCritDetail
 */

"use strict";

const PlanMatchRuleCritDtl = function (config) {
    this.basis = null;
    this.frequency = null;
    this.maxAmt = 0;
    this.maxPct = 0;

    if (config) {
        this.basis = config.basis;
        this.frequency = config.frequency;
        this.maxAmt = config.maxAmt;
        this.maxPct = config.maxPct;
    }
};

export default PlanMatchRuleCritDtl;
