import { getPrimaryGrpAcct } from "core-ui/client/react/core/services/accountService";

import {
    getAccountSummary,
    getAccountSummaryRefresh,
    isParticipantEnrollEligible
} from "./accountSummaryService";
import { getAuthorizedDevices, saveAuthorizedDeviceChanges } from "./authorizedDevices";
import { saveEDelivery } from "./eDeliveryService";
import { fetchIsLockheed } from "./lockheedModalService";
import {
    updateCredentials,
    getRetriveProfileInfo,
    pcapHoldGetDataInSession,
    getSubjectToFeva,
    updateMarketingPreferences,
    retrieveMarketingPreferences
} from "./profileServices";

/***** 
Creating a public empower service to allow us to expose certain services.  
 We are doing this to grant pcap access to our services for their developemnt purposes.  
 A well-defined interface so Empower is aware of exactly what APIs PCAP will call.
 Empower maintains the UI error handling to keep consistentency 
 ****/

export const publicServices = () => {
    globalThis.PublicServices = {
        getAccountSummary,
        getAccountSummaryRefresh,
        isParticipantEnrollEligible,
        fetchIsLockheed,
        saveEDelivery,
        getAuthorizedDevices,
        saveAuthorizedDeviceChanges,
        updateCredentials,
        getRetriveProfileInfo,
        pcapHoldGetDataInSession,
        getSubjectToFeva,
        updateMarketingPreferences,
        retrieveMarketingPreferences,
        getPrimaryGrpAcct
    };
};
