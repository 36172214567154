import InitDataFactory from "../factories/InitDataFactory";
import { User } from "../types/common";

import getInitData from "./getInitData";

/*
 Do we have specifc api endpoint for just the users or only from getHouseholdInitData?
 Without a service to fetch the existing users we can call getInitData for now.

 TODO(EOC): This was setup before introducing react-query. Look to remove this function when 
 we begin adding mutations. Avoid re-using this unless necessary and can use the getUser data handler instead.
 */
const getUsers = async () => {
    try {
        const response = await getInitData();
        const usersList: User[] = InitDataFactory.createUsers(response.data);
        return usersList;
    } catch (err) {
        console.error("Error with service getUsers: ", err);
        return err;
    }
};

export default getUsers;
