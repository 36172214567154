import { DiagnosticsConstants, formatTimingPrefix } from "./common";
import { getProfileSettingsRender, getLoginLastHash } from "./getEvents";
import { sendMetricEvent } from "./sendEvents";

const PROFILE_SETTING_START_MEASUREMENT_NAME = "ProfileSettingStart";
const UPDATE_PROFILE_SETTINGS_MEASUREMENT_NAME = "UpdateProfileSetting";

const timingPrefix = formatTimingPrefix();

/**
 * sentryProfileSettingStartMetrics will initiate the timestamp from liat-ui and create
 * an event listener for a custom event to be triggered within pcap-webui.

 * In order to keep the sentry data of the same instances (liat-ui) we will then send
 * the metric distributions from liat-ui as opposed to within pcap-webui.
 */

const sentryProfileSettingStartMetrics = () => {
    const startProfileSettings = new Date().getTime();

    const eventName = `${timingPrefix}${PROFILE_SETTING_START_MEASUREMENT_NAME}`;
    const profileSettingsTimeExists = sessionStorage.getItem(eventName);

    const loginLastHash = getLoginLastHash();

    // If this time already exists user may have visited recently so we want to remove and create a new time stamp
    if (profileSettingsTimeExists) {
        sessionStorage.removeItem(eventName);
    }

    sessionStorage.setItem(eventName, String(startProfileSettings));

    // turn on the listener
    window.addEventListener("emit_pcap_sentry_event", async (event) => {
        if (
            !event ||
            !event.detail ||
            event.detail.measurementName !== PROFILE_SETTING_START_MEASUREMENT_NAME
        ) {
            return null;
        }

        const hasRenderProfileSettings = getProfileSettingsRender();

        // The event is being fired multiple times and we only need to collect the first event
        if (!hasRenderProfileSettings) {
            // Add the timestamp to session
            sessionStorage.setItem(
                `${timingPrefix}${DiagnosticsConstants.RENDER_PROFILE_SETTINGS}`,
                String(startProfileSettings)
            );
            // we have received the event now we need to send to sentry
            sendMetricEvent({
                name: DiagnosticsConstants.RENDER_PROFILE_SETTINGS,
                timeToLoad: event.detail.timeToLoad,
                measurementName: PROFILE_SETTING_START_MEASUREMENT_NAME,
                startTime: startProfileSettings,
                loginLastHash
            });
        }
    });

    // update profile setting listener
    window.addEventListener("pc_integratedprofileupdatecompleted", (event) => {
        if (!event || !event.detail || !event.detail.person) {
            return null;
        }

        const timeProfiledUpdated = new Date().getTime();

        const updateProfileSettingsTime = sessionStorage.getItem(
            `${timingPrefix}${UPDATE_PROFILE_SETTINGS_MEASUREMENT_NAME}`
        );

        // This time cannot be null when sending the event
        if (updateProfileSettingsTime) {
            const timeToUpdateDelta = timeProfiledUpdated - Number(updateProfileSettingsTime);

            // we have received the event now we need to send to sentry
            sendMetricEvent({
                name: DiagnosticsConstants.UPDATE_PROFILE_SETTINGS,
                timeToLoad: timeToUpdateDelta,
                measurementName: UPDATE_PROFILE_SETTINGS_MEASUREMENT_NAME,
                startTime: Number(updateProfileSettingsTime),
                loginLastHash
            });
        }
    });
};

export const cleanupProfileSettingStartMetrics = () => {
    window.removeEventListener("emit_pcap_sentry_event", () => {});
    window.removeEventListener("pc_integratedprofileupdatecompleted", () => {});
};

export default sentryProfileSettingStartMetrics;
