export const GET_SCREEN_SIZE = "GET_SCREEN_SIZE";
export const SET_SCREEN_SIZE = "SET_SCREEN_SIZE";
export const SET_CURRENT_FAULT = "SET_CURRENT_FAULT";
export const SHOW_LOADER = "SHOW_LOADER";
export const SHOW_LOADER_MESSAGES = "SHOW_LOADER_MESSAGES";

export const ON_INIT_LOAD = "ON_INIT_LOAD";
export const READY_TO_SHOW_APP = "READY_TO_SHOW_APP";
export const READY_TO_SHOW_APP_LITE = "READY_TO_SHOW_APP_LITE";
export const UPDATE_TRANSLATIONS = "UPDATE_TRANSLATIONS";

export const SET_TXN_ACCESS = "SET_TXN_ACCESS";
export const SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION";

export const SET_LOAD_STATUS = "SET_LOAD_STATUS";
export const SET_SECONDARY_LOADER = "SET_SECONDARY_LOADER";
export const SET_PRIMARY_PLAN_RULES_SALARY_REMINDER = "SET_PRIMARY_PLAN_RULES_SALARY_REMINDER";

export const SET_OPEN_DEMIN_MODAL = "SET_OPEN_DEMIN_MODAL";

export const SET_CACHED_AS_OF_DATE = "SET_CACHED_AS_OF_DATE";
