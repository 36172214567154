import ReferenceData from "../../../../constants/ReferenceData";

interface GetDefaultAgeValue {
    atRetirement: boolean;
    currentValueWithBenefitsAge: number;
    optionsVal: string;
    retirementAge: number;
    startDateOption: string;
}

const defaultAgeText = (age: number, startDateOption: string) =>
    age + "-" + startDateOption.replace(" ", "_");

export const getDefaultAgeValue = ({
    atRetirement,
    currentValueWithBenefitsAge,
    optionsVal,
    retirementAge,
    startDateOption
}: GetDefaultAgeValue) => {
    if (retirementAge === currentValueWithBenefitsAge) {
        return atRetirement
            ? defaultAgeText(currentValueWithBenefitsAge, startDateOption)
            : optionsVal;
    }

    if (retirementAge < ReferenceData.MIN_SOCIALSECURITY_STARTAGE) {
        return atRetirement
            ? defaultAgeText(ReferenceData.MIN_SOCIALSECURITY_STARTAGE, startDateOption)
            : optionsVal;
    }

    if (retirementAge > ReferenceData.MAX_SOCIALSECURITY_LATEST_STARTAGE) {
        return atRetirement
            ? defaultAgeText(ReferenceData.MAX_SOCIALSECURITY_LATEST_STARTAGE, startDateOption)
            : optionsVal;
    }

    return atRetirement ? defaultAgeText(retirementAge, startDateOption) : optionsVal;
};
