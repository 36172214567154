import { useEffect, useState } from "react";

import checkHasSeenWelcomeWidget from "../../utils/checkHasSeenWelcomeWidget";

const useDisplayWelcomeWidget = (userGuid: string | undefined) => {
    const [hasSeenWelcomeWidget, setHasSeenWelcomeWidget] = useState(true);

    useEffect(() => {
        async function checkWelcomeWidgetStatus(userGuid) {
            const result = await checkHasSeenWelcomeWidget(userGuid);

            setHasSeenWelcomeWidget(result);
        }

        if (userGuid) {
            checkWelcomeWidgetStatus(userGuid);
        }
    }, [userGuid]);

    return hasSeenWelcomeWidget;
};

export default useDisplayWelcomeWidget;
