import React, { memo } from "react";

import { LoadingBar } from "gw-shared-components";
import { useAtomValue } from "jotai";
import PropTypes from "prop-types";

import { sentryWidgetsRenderMetrics } from "../../../utils/sentryMetricsLogging";
import { redwoodAtom, showWidgetsAtom } from "../../atoms/atoms";
import { WidgetsShell } from "../loadShells/LoadShells";

const Widgets = ({ isPAE, modalOpen }) => {
    const { pcapClass } = useAtomValue(redwoodAtom);
    const hideOnEmulator = isPAE ? "hide" : "";
    const loadClass = !pcapClass ? "" : "loaded";

    const showWidgets = useAtomValue(showWidgetsAtom);

    try {
        if (!modalOpen) {
            if (pcapClass.mountDashboard) {
                pcapClass.mountDashboard(true, false, LoadingBar);
                // Best way to see if widgets have loaded??
                sentryWidgetsRenderMetrics();
            }
        }
    } catch (err) {
        console.error("Error loading widgets: ", err);
    }

    if (!showWidgets) {
        return null;
    }

    if (!pcapClass) {
        return <WidgetsShell />;
    }

    return (
        <div>
            <div id="dashboard" className={`use-fade-in ${loadClass} ${hideOnEmulator}`} />
        </div>
    );
};

Widgets.propTypes = {
    isPAE: PropTypes.bool.isRequired,
    modalOpen: PropTypes.bool
};

export default memo(Widgets);
