import { useMemo } from "react";

import { ktmgFlags } from "gw-shared-components";

import { useIsEmulator, useIsPrivileged } from "../../../hooks/useEmulatorType";
import { getBaseUrl, subMenuExistsWithTransactionCode, myTotalRetirementHandler } from "../helpers";

import { UseIWantToLitItemsProps, ListItem } from "./useWantToListItems.types";

const useWantToListItems = (props: UseIWantToLitItemsProps) => {
    const {
        isRetail,
        hasRsgServices,
        empowerCrossSellConsent,
        isUserFeMaEnrolled,
        showManagedAccounts,
        iWantTo,
        openNewAccountLink,
        inboundSSOUser,
        hideIWantToLinks,
        referral,
        navigationMenu,
        participant,
        primaryPlan,
        txnAccess,
        provider
    } = props;
    const BASE_URL = getBaseUrl(participant.individualId, primaryPlan.id);
    const accArr = primaryPlan?.id?.split("-") ?? [];
    const isTrsflex = accArr[1]?.startsWith("T") ?? false;

    const isEmulator = useIsEmulator();
    const isPrivileged = useIsPrivileged();
    const isPAE = isEmulator && !isPrivileged;

    // Conditions to render list items
    const viewDocumentAndStatements = subMenuExistsWithTransactionCode(
        navigationMenu,
        ktmgFlags.STATEMENTS_AND_DOCUMENTS
    );
    const viewUploadDocuments =
        isRetail || subMenuExistsWithTransactionCode(navigationMenu, ktmgFlags.UPLOAD_DOCUMENTS);
    const viewBeneficiaries =
        !isRetail && subMenuExistsWithTransactionCode(navigationMenu, ktmgFlags.BENEFICIARIES);
    const viewUpdatePersonalInformation =
        txnAccess.addressUpdateForActiveEmployeeEnabled ||
        txnAccess.addressUpdateForTermEmployeeEnabled ||
        isRetail ||
        isTrsflex;
    const viewMyTotalRetirement = !!showManagedAccounts && !showManagedAccounts.missingData;
    const viewMyInvestments = subMenuExistsWithTransactionCode(
        navigationMenu,
        ktmgFlags.VIEW_MY_INVESTMENTS_OVERVIEW
    );
    const viewManageInvestments = subMenuExistsWithTransactionCode(
        navigationMenu,
        ktmgFlags.VIEW_MY_INVESTMENTS
    );
    const viewTransactionHistory = subMenuExistsWithTransactionCode(
        navigationMenu,
        ktmgFlags.TRANSACTION_HISTORY
    );
    const viewGuideLoanRequest = subMenuExistsWithTransactionCode(
        navigationMenu,
        ktmgFlags.GUIDED_LOAN_REQUEST
    );
    const viewWithdrawal = subMenuExistsWithTransactionCode(
        navigationMenu,
        ktmgFlags.WITHDRAWAL_2018_MAIN
    );
    const viewConsolidatingAccounts = subMenuExistsWithTransactionCode(
        navigationMenu,
        ktmgFlags.CONSOLIDATE_ACCOUNTS
    );
    const viewWellnessConsultation =
        hasRsgServices && empowerCrossSellConsent && txnAccess.accountOpenEnabled;
    const viewFriendReferral = txnAccess.referralEnabled && referral && !isPAE;
    const viewOpenNewAccountLink =
        openNewAccountLink !== null &&
        openNewAccountLink !== undefined &&
        Object?.keys(openNewAccountLink).length > 0;
    const { feUrl, mtrUrl, mtrLabel } = myTotalRetirementHandler({
        isUserFeMaEnrolled,
        provider,
        BASE_URL,
        showManagedAccounts
    });

    return useMemo(() => {
        if (isRetail === null || hasRsgServices === null) {
            return [];
        }
        const menuItems: ListItem[] = [
            {
                key: "documentsStatements",
                url: isRetail ? "/participant/home/#/documents" : `${BASE_URL}documents-statements`,
                label: iWantTo.viewStatements,
                order: 5,
                openNewTab: false,
                shouldView: viewDocumentAndStatements
            },
            {
                key: "uploadDocument",
                url: isRetail ? "/participant/home/#/documents/statements" : `${BASE_URL}upload`,
                label: iWantTo.uploadDocument,
                order: 7,
                openNewTab: false,
                shouldView: viewUploadDocuments as boolean
            },
            {
                key: "beneficiary",
                url: `${BASE_URL}beneficiary`,
                label: iWantTo.viewEditBeneficiary,
                order: 3,
                openNewTab: false,
                shouldView: viewBeneficiaries
            },
            {
                key: "updatePersonalInformation",
                url: "/participant/home/#/settings/profile",
                label: iWantTo.updatePersonalInformation,
                order: 2,
                openNewTab: false,
                shouldView: viewUpdatePersonalInformation
            },
            {
                key: "viewAccountDetails",
                url: `${BASE_URL}trsflex-account-details`,
                label: iWantTo.viewAccountDetails,
                openNewTab: false,
                shouldView: isTrsflex
            },
            {
                key: "myTotalRetirement",
                url: mtrUrl,
                label: mtrLabel,
                order: 4,
                openNewTab: false,
                groupId: !feUrl ? primaryPlan.id : "",
                paePreventNav: isEmulator,
                shouldView: viewMyTotalRetirement
            },
            {
                key: "viewManageInvestments",
                url: `${BASE_URL}investment-options/investment-overview`,
                label: iWantTo.viewMyInvestments,
                openNewTab: false,
                shouldView: !isRetail && viewManageInvestments
            },
            {
                key: "viewManageInvestments",
                url: `${BASE_URL}investment-options/my-investments`,
                label: iWantTo.viewManageInvestments,
                openNewTab: false,
                shouldView: !isRetail && viewMyInvestments
            },
            {
                key: "viewTransactionHistory",
                url: `${BASE_URL}transaction-history`,
                label: iWantTo.viewTransactionHistory,
                openNewTab: false,
                shouldView: !isRetail && viewTransactionHistory
            },
            {
                key: "loan",
                url: `${BASE_URL}loan/request`,
                label: iWantTo.takeLoan,
                openNewTab: false,
                shouldView: !isRetail && viewGuideLoanRequest
            },
            {
                key: "withdrawal",
                url: `${BASE_URL}distribution/landing`,
                label: iWantTo.takeWithdrawal,
                openNewTab: false,
                shouldView: !isRetail && viewWithdrawal
            },
            {
                key: "learnAboutConsolidatingAccounts",
                url: `${BASE_URL}consolidating-your-retirement-assets`,
                label: iWantTo.learnAboutConsolidatingAccounts,
                openNewTab: false,
                shouldView: !isRetail && viewConsolidatingAccounts
            },
            {
                key: "wellnessConsultation",
                url: "https://fwconsult.empowermytime.com/#/",
                label: iWantTo.wellnessConsultation,
                order: 1,
                openNewTab: true,
                shouldView: viewWellnessConsultation as boolean
            },
            {
                key: "openNewAccount",
                url: openNewAccountLink?.url || "",
                label: openNewAccountLink?.displayText || "",
                order: 8,
                enableSSOModal: inboundSSOUser,
                openNewTab: false,
                shouldView: viewOpenNewAccountLink
            },
            {
                key: "advisor",
                url: "/participant/home/#/advisor",
                label: iWantTo.advisor,
                order: 3,
                openNewTab: false,
                shouldView: isRetail
            },
            {
                key: "depositWithdrawFunds",
                url: "/participant/home/#/transfer-funds",
                label: iWantTo.depositWithdrawFunds,
                order: 9,
                openNewTab: false,
                shouldView: isRetail && !hideIWantToLinks
            },
            {
                key: "referFriend",
                url: "#/referral-page",
                label: iWantTo.referFriend,
                order: 10,
                openNewTab: false,
                shouldView: viewFriendReferral
            }
        ];
        return menuItems
            ?.filter((item) => item.shouldView)
            .map((item) => {
                const { shouldView, ...restOfItem } = item;
                return restOfItem;
            });
    }, [
        BASE_URL,
        feUrl,
        hasRsgServices,
        hideIWantToLinks,
        iWantTo,
        inboundSSOUser,
        isEmulator,
        isRetail,
        isTrsflex,
        mtrLabel,
        mtrUrl,
        openNewAccountLink?.displayText,
        openNewAccountLink?.url,
        primaryPlan.id,
        viewBeneficiaries,
        viewConsolidatingAccounts,
        viewDocumentAndStatements,
        viewFriendReferral,
        viewGuideLoanRequest,
        viewManageInvestments,
        viewMyInvestments,
        viewMyTotalRetirement,
        viewOpenNewAccountLink,
        viewTransactionHistory,
        viewUpdatePersonalInformation,
        viewUploadDocuments,
        viewWellnessConsultation,
        viewWithdrawal
    ]);
};
export default useWantToListItems;
