export enum LoginFlowEvents {
    DF_LOGIN = "DF_Login",
    MFA_LOGIN = "MFA_Login",
    SSO_LOGIN = "SSO_Login"
}

const DiagnosticsConstants = {
    TIME_TO_LIAT: "TTLIAT",
    TIME_TO_LIAT_FULL: "TTLIATF",
    TIME_TO_BALANCE: "TTB",
    TIME_TO_SKELETON_VISIBLE: "TTS",
    TIME_TO_FULL_LOAD: "TTFL",
    LIAT_APP_START: "liatAppStart",
    LIAT_APP_START_FULL: "liatAppStartFull",
    LOGIN_ACCOUNT_SETUP: "loginAccountSetup",
    // login flow events
    SSO: {
        LOGIN: LoginFlowEvents.SSO_LOGIN,
        TIME_TO_LIAT: "SSO_TTLIAT",
        TIME_TO_LIAT_FULL: "SSO_TTLIATF",
        TIME_TO_BALANCE: "SSO_TTB",
        TIME_TO_SKELETON_VISIBLE: "SSO_TTS",
        TIME_TO_FULL_LOAD: "SSO_TTFL"
    },
    MFA: {
        LOGIN: LoginFlowEvents.MFA_LOGIN,
        TIME_TO_LIAT_FULL: "MFA_TTLIATF",
        TIME_TO_LIAT: "MFA_TTLIAT",
        TIME_TO_BALANCE: "MFA_TTB",
        TIME_TO_SKELETON_VISIBLE: "MFA_TTS",
        TIME_TO_FULL_LOAD: "MFA_TTFL"
    },
    DF: {
        LOGIN: LoginFlowEvents.DF_LOGIN,
        TIME_TO_LIAT_FULL: "DF_TTLIATF",
        TIME_TO_LIAT: "DF_TTLIAT",
        TIME_TO_BALANCE: "DF_TTB",
        TIME_TO_SKELETON_VISIBLE: "DF_TTS",
        TIME_TO_FULL_LOAD: "DF_TTFL"
    },
    "#/registration-summary": "REGISTRATION_SUMMARY",
    "#/register/account-setup": "ACCOUNT_SETUP",
    "#/register/contact-update": "CONTACT_UPDATE",
    // render components
    RENDER_PAYCHECK: "renderPaycheck",
    RENDER_PROFILE_SETTINGS: "renderProfileSettings",
    RENDER_QUICKVIEW: "renderQuickView",
    RENDER_SKELETON: "renderSkeleton",
    RENDER_WIDGETS: "renderWidgets",
    // update events
    UPDATE_PROFILE_SETTINGS: "updateProfileSettings"
} as const;

export default DiagnosticsConstants;
