"use strict";
/**
 * @name app.common.model.domain.NextStep
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @type Class
 * @description
 * Creates a defined object structure for the NextStep object type.
 * The NextStep object constructor.
 * @param config This object of properties you would like to base the plan off of. Note that only primitives are used.
 *
 * @example
 * "bestNextSteps" : {
          "message" : "",
          "url" : "",
          "recommended" : true,
          "recommendedPct" : 0,
          "recommendedDollar" : 0
        }
 */

class NextStep {
    constructor(config) {
        this.message = "";
        this.url = "";
        this.recommended = false;
        this.recommendedDollar = null;
        this.recommendedPct = null;
        this.enrollToMaximizer = false;
        this.incrementPct = null;
        this.incrementDollar = null;
        this.roundedRecommendedValue = null;
        // AG - these two values don't come from the API response, but are used to simplify the use of this object in the views
        this.recommendedValue = 0;
        this.type = "";
        this.staticMessage = null;
        this.appliesToTerminated = false;

        if (config) {
            this.message = config.message;
            this.url = config.url;
            this.recommended = config.recommended;
            this.recommendedDollar = config.recommendedDollar;
            this.recommendedPct = config.recommendedPct;
            this.recommendedValue = config.recommendedValue;
            this.enrollToMaximizer = config.enrollToMaximizer;
            this.incrementPct = config.incrementPct;
            this.incrementDollar = config.incrementDollar;
            this.roundedRecommendedValue = config.roundedRecommendedValue;
            this.staticMessage = config.staticMessage;
            this.appliesToTerminated = config.appliesToTerminated;
        }
    }
}

export default NextStep;
