/**
 * @class common.model.domain.PlanMatchRule
 * @copyright 2015 Empower Retirement
 * @description
 * Creates a defined object structure for the PlanMatchRule
 */

"use strict";

const PlanMatchRule = function (config) {
    this.id = null;
    this.planId = null;
    this.calcLevel = "CUMULATIVE";
    this.calcSource = null;
    this.calcType = null;
    this.ageCatchupErMatchCalInd = false;
    this.description = null;
    this.matchCriteria = [];
    this.moneyTypes = [];
    this.sdmtCode = null;
    this.gdmtSeqnbr = null;

    if (config) {
        this.id = config.id;
        this.planId = config.planId;
        this.calcLevel = config.calcLevel;
        this.calcSource = config.calcSource;
        this.ageCatchupErMatchCalInd = config.ageCatchupErMatchCalInd;
        this.calcType = config.calcType;
        this.description = config.description;
        this.sdmtCode = config.sdmtCode;
        this.gdmtSeqnbr = config.gdmtSeqnbr;
        this.matchCriteria = config.matchCriteria;
        this.moneyTypes = config.moneyTypes;
    }
};

export default PlanMatchRule;
