import { DiagnosticsConstants } from "../common";
import formatTimingPrefix from "../common/formatTimingPrefix";

const timingPrefix = formatTimingPrefix();

export const getLiatAppFullStartTime = () => {
    return sessionStorage.getItem(`${timingPrefix}${DiagnosticsConstants.LIAT_APP_START_FULL}`);
};

export default getLiatAppFullStartTime;
