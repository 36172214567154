"use strict";
/**
 * @name app.common.model.domain.ChartLegend
 * @date 1/26/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Definition of the ChartLegend domain model object.
 */

const DeferralRuleMessage = function (config) {
    this.msgCode = null;
    this.msgType = null;
    this.message = null;

    if (config) {
        this.msgCode = config.msgCode;
        this.msgType = config.msgType;
        this.message = config.message;
    }
};

export default DeferralRuleMessage;
