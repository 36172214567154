import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import { csrfAtom } from "../../atoms/atoms";
import querySession from "../../services/querySession";
import { QUERY_KEYS } from "../constants";

interface QuerySessionResponse {
    spHeader: {
        SP_HEADER_VERSION: number;
    };
}

const useQuerySession = () => {
    const csrf = useAtomValue(csrfAtom);

    return useQuery<QuerySessionResponse>({
        queryKey: [QUERY_KEYS.QUERY_SESSION],
        queryFn: async () => {
            const response = await querySession();
            return response.data;
        },
        enabled: Boolean(csrf)
    });
};

export default useQuerySession;
