interface PieChartTranslations {
    hcCostsGreaterThanIncome: string;
    hcCostsLessThanIncome: string;
}

const getChartPopoverMessage = ({
    totalHealthCareExpenses,
    projectedIncome,
    pieChartTranslations
}: {
    pieChartTranslations: PieChartTranslations;
    projectedIncome: number;
    totalHealthCareExpenses: number;
}) => {
    if (totalHealthCareExpenses) {
        if (totalHealthCareExpenses > projectedIncome) {
            return pieChartTranslations.hcCostsGreaterThanIncome;
        }
        return pieChartTranslations.hcCostsLessThanIncome;
    }

    return "";
};

export default getChartPopoverMessage;
