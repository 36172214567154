import StringUtil from "core-ui/client/src/app/StringUtil";
import { ObjectUtil } from "gw-shared-components";
import {
    every as _every,
    has as _has,
    trim as _trim,
    extend as _extend,
    mapValues as _mapValues
} from "lodash";

import LoggerFactory from "../factories/LoggerFactory";
import RequestFactory from "../factories/RequestFactory";
import { executeGet } from "../utils/apiUtils";
import { getUrl, isLocalhost, hasMocks } from "../utils/urlUtils";

// specific plan txn Access
export const getGroupTxnAccess = (individualId, planId, txnCodes) => {
    const logger = LoggerFactory.getInstance("financialEngineService");

    const getGroupTxnAccessResult = function (result) {
        if (ObjectUtil.isUndefinedOrNull(result) || ObjectUtil.isUndefinedOrNull(result.data)) {
            logger.info("getGroupTxnAccessResult( Response is null. )");
            return null;
        } else {
            logger.info("getGroupTxnAccessResult() for " + individualId + ", " + planId);

            return result.data;
        }
    };

    const params = {
        txnCodes: txnCodes,
        individualId: individualId,
        groupId: planId // the primary plan's ID is the group ID
    };

    const url = StringUtil.supplant(getUrl("getGroupTxnAccess"), params);

    return executeGet(url, "getGroupTxnAccessFault")
        .then(getGroupTxnAccessResult)
        .catch(() => {});
};

const cache = {};

// Generic transaction access service call; ANY plan the participant is in txn Access
export const getTxnAccess = (params) => {
    const cacheUrl = StringUtil.supplant(getUrl("hasTxnAccess"), params);

    const hasAccessCodes = function (txnCodes) {
        return _every(txnCodes.split(","), function (code) {
            return _has(cache, _trim(code));
        });
    };

    const getTxnAccessResult = (resp) => {
        _extend(
            cache,
            _mapValues(resp.data, function (value) {
                return value === "true" || value === true;
            })
        );

        return cache;
    };

    const method = RequestFactory.HTTP_METHOD.GET;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;

    const url = cacheUrl;
    const mock = "data/has-txn-access.json";

    const updatedUrl = isLocalhost && hasMocks() ? mock : url;

    const request = RequestFactory.create(updatedUrl, {}, method, contentType);
    delete request.headers.samlToken;

    if (hasAccessCodes(params.txnCodes)) {
        return Promise.resolve(cache);
    } else {
        return executeGet(updatedUrl, "hasTxnAccessFault", params)
            .then(getTxnAccessResult)
            .catch(() => {});
    }
};
