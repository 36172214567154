/**
 * @name app.common.model.domain.ExternalAssetContribution
 * @date 1/11/2018
 * @copyright 2018 Empower Retirement
 */

"use strict";

class ExternalAssetContribution {
    constructor(config) {
        this.id = null;
        this.plCurrentContributionCode = null;
        this.employerMatchFlag = null;
        this.profitSharingFlag = null;
        this.profitSharingPercentage = null;
        this.profitSharingAmount = null;
        this.profitSharingType = null;
        this.contributionDetail = null;
        this.employerMatchDetail = null;

        if (config) {
            this.id = config.id;
            this.plCurrentContributionCode = config.plCurrentContributionCode;
            this.employerMatchFlag = config.employerMatchFlag;
            this.profitSharingFlag = config.profitSharingFlag;
            this.profitSharingPercentage = config.profitSharingPercentage;
            this.profitSharingAmount = config.profitSharingAmount;
            this.profitSharingType = config.profitSharingType;
            this.contributionDetail = config.contributionDetail;
            this.employerMatchDetail = config.employerMatchDetail;
        }
    }
}

export default ExternalAssetContribution;
