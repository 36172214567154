/**
 * @class common.model.domain.PlanMatchRuleMoneyTypes
 * @copyright 2015 Empower Retirement
 * @description
 * Creates a defined object structure for the PlanMatchRuleMoneyTypes
 */

"use strict";

const PlanMatchRuleMoneyTypes = function (config) {
    this.planMatchRuleGrpDefMnty = null;
    this.grpDefMntyDefType = null;
    this.grpDefMnty = null;

    if (config) {
        this.planMatchRuleGrpDefMnty = config.planMatchRuleGrpDefMnty;
        this.grpDefMntyDefType = config.grpDefMntyDefType;
        this.grpDefMnty = config.grpDefMnty;
    }
};

export default PlanMatchRuleMoneyTypes;
