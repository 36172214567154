import _filter from "lodash/filter";
import _pick from "lodash/pick";

import { SAVE_HSA_PREFERENCES, SET_HSA_LOADER } from "../../actions/hsa/hsaActionTypes";
import { SET_INIT_DATA } from "../../actions/initData";
import {
    CREATE_OTHER_ASSET_SUCCESS,
    UPDATE_OTHER_ASSET_SUCCESS,
    DELETE_OTHER_ASSET_SUCCESS,
    CREATE_MIGRATED_RETIREMENT_ASSET,
    UPDATE_MIGRATED_RETIREMENT_ASSET,
    DELETE_MIGRATED_RETIREMENT_ASSET,
    CREATE_MIGRATED_EXTERNAL_ASSET,
    DELETE_MIGRATED_EXTERNAL_ASSETS
} from "../../actions/otherAssets/otherAssetsTypes";
import {
    SET_PARTICIPANT_TYPE,
    SET_PARTICIPANT_DATE_OF_BIRTH,
    SET_PARTICIPANT_RETIREMENT_AGE,
    SET_PARTICIPANT_PROJECTIONS_MAP,
    SET_PARTICIPANT_DEFERRALS,
    SET_PARTICIPANT_PLANS_NEW_DEFERRALS,
    SET_PARTICIPANT_PLANS,
    SET_PARTICIPANT_AGE,
    SET_IS_AA_FEATURE_ENABLED,
    SET_PARTICIPANT,
    UPDATE_PARTICIPANT_SALARY,
    UPDATE_PARTICIPANT_INCOME_GOAL,
    SET_PARTICIPANT_ADJUSTED_LIFE_EXPECTANCY_AGE,
    SET_PARTICIPANT_DEFAULT_LIFE_EXPECTANCY_AGE,
    SET_PLAN_RULES_OVERRIDE_FLAGS,
    UPDATE_HAS_REDUCED_SALARY,
    SET_SHOW_CONSIDERATION_MODAL_LOADER,
    UPDATE_PARTICIPANT_RETIREMENT_ASSETS,
    UPDATE_PARTICIPANT_EXTERNAL_ASSETS,
    SET_PARTICIPANT_INDIVIDUAL_ID,
    SET_USER_MESSAGES,
    SET_WCIR_AGELIST
} from "../../actions/participantDetails/participantDetailsTypes";
import { hasHsaPlan } from "../../business/hsaLogic/hsa";
import ReferenceData from "../../constants/ReferenceData";
import OtherAssetsFactory from "../../factories/OtherAssetsFactory";
import { canShowPreRetirementView } from "../../selectors/featureFlagSelectors";
import {
    removeAssetFromArray,
    removeRetirementAsset,
    updateAssetBasedOnInvestorAccountId
} from "../../selectors/otherAssetsSelectors";
import { findPrimaryPlan } from "../../utils/planUtils";

const initialState = {
    age: 0,
    adjustedLEAge: ReferenceData.MAX_RETIREMENT_AGE,
    defaultLEAge: ReferenceData.MAX_RETIREMENT_AGE,
    clientId: "",
    dataIsLoaded: false,
    dateOfBirth: "",
    dateOfBirthSource: "",
    deferrals: [],
    availableDeferrals: [],
    enrollmentStatus: "",
    externalAssets: [],
    firstName: "",
    gender: "",
    hasReducedSalary: false,
    headOfHousehold: true,
    healthViewData: null, // NOT SURE IF WE WANT TO KEEP THIS HERE AS WELL
    hsaPlan: null,
    hsaLoader: false,
    incomeGoal: {
        term: 12, // setting a default of 12 so that we never end up with neither monthly or yearly
        value: 75,
        valueUnits: "PCT",
        default: 75
    },
    individualId: "",
    investorId: "",
    isAAFeatureEnabled: false,
    lastName: "",
    linkedAccountBalances: [],
    middleName: "",
    outsideAssetCategories: [],
    outsideAssets: [],
    pcapPersonId: -1,
    personId: "",
    plans: [],
    projectionsMap: [],
    retirementAge: ReferenceData.DEFAULT_RETIREMENT_AGE,
    retirementAssets: [],
    salary: {
        base: 0,
        variable: 0,
        total: 60000,
        tempVariable: null,
        source: "",
        salaryReminder: false,
        salaryOnFile: 0
    },
    specialLimit: { limit: 0, planEnabled: false, pourOverEnabled: false, type: null },
    type: "",
    showConsiderationModalLoader: false,
    messages: null,
    wcir: {
        retireAge: "65",
        percentGoal: 100
    }
};

const getNewStateFromUser = (state, user) => {
    const hsaPlan = hasHsaPlan(user.plans);

    return {
        ...state,
        ..._pick(user, Object.keys(state)),
        hsaPlan,
        dataIsLoaded: true
    };
};

const updateHsaInPlans = (array, hsaPlan) => {
    return array.map((plan) => {
        if (plan.id === hsaPlan.id) {
            return hsaPlan;
        }
        return plan;
    });
};

const factoryBuiltOutsideAssets = (assets, outsideAssetCategories) => {
    return OtherAssetsFactory.createOutsideAssets(assets, outsideAssetCategories);
};

export default (state = initialState, action) => {
    if (!action) return state;
    const { type, payload } = action;
    switch (type) {
        case SET_INIT_DATA:
            return getNewStateFromUser(state, payload.user);
        case SET_PARTICIPANT:
            return getNewStateFromUser(state, payload);
        case SET_PARTICIPANT_TYPE:
            return { ...state, type: payload };
        case SET_PARTICIPANT_DATE_OF_BIRTH:
            return { ...state, dateOfBirth: payload };
        case SET_PARTICIPANT_RETIREMENT_AGE:
            return { ...state, retirementAge: payload };
        case SET_PARTICIPANT_PROJECTIONS_MAP:
            return { ...state, projectionsMap: payload };

        case SET_PARTICIPANT_DEFERRALS: {
            const primaryPlan = findPrimaryPlan(state.plans);
            const activePlan = { ...primaryPlan };

            const matchingPayload = _filter(payload.deferrals, (deferralItem) => {
                return activePlan.id === deferralItem.planId;
            });

            // for the hubconfig factory we need to have the plans newDeferrals and deferrals aligned
            // our store values used in UI  /    hubconfig expected values
            // baseline.deferrals === plans.deferrals   &&   participant.deferrals === plans.newDeferrals
            if (payload.savedDeferrals) {
                activePlan.deferrals = matchingPayload;
            }
            activePlan.newDeferrals = matchingPayload;

            // update the deferrals within plan for the hubconfig createDeferrals values
            const participantPlans = state.plans.map((plan) => {
                if (plan.isPrimary) {
                    return activePlan;
                } else {
                    return plan;
                }
            });

            return { ...state, deferrals: payload.deferrals, plans: participantPlans };
        }
        case SET_PARTICIPANT_PLANS_NEW_DEFERRALS: {
            const plans = [];
            state.plans.forEach((plan) => {
                let newDeferrals = [];
                payload.forEach((deferral) => {
                    if (deferral.planId === plan.id) {
                        newDeferrals.push(deferral);
                    }
                });
                if (!newDeferrals.length) {
                    newDeferrals = plan.newDeferrals;
                }
                plans.push({ ...plan, newDeferrals });
            });
            return { ...state, plans };
        }
        case SET_PARTICIPANT_PLANS:
            return { ...state, plans: payload };
        case SET_PARTICIPANT_AGE:
            return { ...state, age: payload };

        case SET_PLAN_RULES_OVERRIDE_FLAGS: {
            const participantPlan = findPrimaryPlan(state.plans);
            const allowPreRetireeView = canShowPreRetirementView(state, participantPlan);

            const newPlans = state.plans.map((plan) => {
                if (plan.isPrimary) {
                    // can add other query params here as needed
                    plan.planRules.allowPreRetireeView = allowPreRetireeView;
                }
                return plan;
            });

            return { ...state, plans: newPlans };
        }
        case SET_IS_AA_FEATURE_ENABLED:
            return { ...state, isAAFeatureEnabled: payload };
        case UPDATE_PARTICIPANT_SALARY: {
            const updatePlans = state.plans.map((plan) => {
                if (plan.isPrimary) {
                    plan.salary = { ...payload };
                }
                return plan;
            });

            return { ...state, salary: { ...state.salary, ...payload }, plans: updatePlans };
        }
        case UPDATE_PARTICIPANT_INCOME_GOAL:
            return { ...state, incomeGoal: { ...state.incomeGoal, ...payload } };

        case CREATE_OTHER_ASSET_SUCCESS:
            return {
                ...state,
                outsideAssets: factoryBuiltOutsideAssets(payload, state.outsideAssetCategories)
            };

        case CREATE_MIGRATED_RETIREMENT_ASSET:
            return { ...state, retirementAssets: [...state.retirementAssets, payload] };

        case UPDATE_MIGRATED_RETIREMENT_ASSET:
            return {
                ...state,
                retirementAssets: updateAssetBasedOnInvestorAccountId(
                    state.retirementAssets,
                    payload
                )
            };

        case DELETE_MIGRATED_RETIREMENT_ASSET: {
            const removedRetirementAsset = removeRetirementAsset(
                state.retirementAssets,
                payload.investorAccountId
            );
            return { ...state, retirementAssets: [...removedRetirementAsset] };
        }
        case CREATE_MIGRATED_EXTERNAL_ASSET:
            return { ...state, externalAssets: [...state.externalAssets, payload] };

        case DELETE_MIGRATED_EXTERNAL_ASSETS: {
            const removedExternalAsset = removeAssetFromArray(state.externalAssets, payload);
            return { ...state, externalAssets: [...removedExternalAsset] };
        }
        case UPDATE_OTHER_ASSET_SUCCESS:
            return {
                ...state,
                outsideAssets: factoryBuiltOutsideAssets(payload, state.outsideAssetCategories)
            };

        case DELETE_OTHER_ASSET_SUCCESS:
            return {
                ...state,
                outsideAssets: factoryBuiltOutsideAssets(payload, state.outsideAssetCategories)
            };

        case SET_HSA_LOADER:
            return { ...state, hsaLoader: payload };

        case SAVE_HSA_PREFERENCES: {
            const hsaDetails = {
                ...state.hsaPlan.hsaDetails,
                expenseSelectionInd: payload.hsaSelectionInd,
                customExpenseAmount: payload.hsaExpenseAmount
            };
            const updatedHsaPlan = { ...state.hsaPlan, hsaDetails };
            const updatedPlans = updateHsaInPlans(state.plans, updatedHsaPlan);

            return {
                ...state,
                hsaPlan: updatedHsaPlan,
                plans: updatedPlans
            };
        }
        case SET_PARTICIPANT_ADJUSTED_LIFE_EXPECTANCY_AGE:
            return { ...state, adjustedLEAge: payload };

        case SET_PARTICIPANT_DEFAULT_LIFE_EXPECTANCY_AGE:
            return { ...state, defaultLEAge: payload };

        case UPDATE_HAS_REDUCED_SALARY:
            return { ...state, hasReducedSalary: payload };

        case SET_SHOW_CONSIDERATION_MODAL_LOADER:
            return { ...state, showConsiderationModalLoader: payload };

        case UPDATE_PARTICIPANT_RETIREMENT_ASSETS:
            return {
                ...state,
                retirementAssets: payload
            };

        case UPDATE_PARTICIPANT_EXTERNAL_ASSETS:
            return {
                ...state,
                externalAssets: payload
            };

        case SET_PARTICIPANT_INDIVIDUAL_ID:
            return {
                ...state,
                individualId: payload
            };
        case SET_USER_MESSAGES:
            return {
                ...state,
                messages: payload
            };
        case SET_WCIR_AGELIST:
            return {
                ...state,
                wcir: payload
            };
        default:
            return state;
    }
};
