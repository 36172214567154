import React from "react";

import PropTypes from "prop-types";

import { ACCESSIBILITY } from "../../../../constants/AccessibilityPaycheck";
import HiddenA11yWrapper from "../../../accessibility/HiddenA11yWrapper";

export const ContributionRates = ({ translations, score, me }) => {
    return (
        <tr className="contribution-rates">
            <td>
                <span>{translations.contributionRate}:</span>
            </td>
            <td>
                <span>
                    <HiddenA11yWrapper id={ACCESSIBILITY.HOW_DO_I_COMPARE["contribution-my-peers"]}>
                        {translations.contributionRate} &#32;
                        {score.medContRate}%
                    </HiddenA11yWrapper>
                    {score.medContRate}%
                </span>
            </td>
            <td>
                <span>
                    <HiddenA11yWrapper
                        id={ACCESSIBILITY.HOW_DO_I_COMPARE["contribution-top-peers"]}
                    >
                        {translations.contributionRate} &#32;
                        {score.topContRate}%
                    </HiddenA11yWrapper>
                    {score.topContRate}%
                </span>
            </td>
            <td>
                <span>
                    <HiddenA11yWrapper id={ACCESSIBILITY.MY_GOAL["contribution-me"]}>
                        {translations.contributionRate} &#32;
                        {me.contributionRate}
                    </HiddenA11yWrapper>
                    {me.contributionRate}
                </span>
            </td>
        </tr>
    );
};

ContributionRates.propTypes = {
    translations: PropTypes.object,
    score: PropTypes.object,
    me: PropTypes.object
};
