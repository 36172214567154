/**
 * @name app.common.model.domain.HealthSurvey
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Creates a defined object structure for the HealthSurvey object type.
 */

/**
 * The HealthSurvey object constructor.
 * @param config This object of properties you would like to base the plan off of. Note that only primitives are used.
 * @constructor
 */

"use strict";

class HealthSurvey {
    constructor(config) {
        this.pptHealthConditions = [];
        this.selectedRetirementState = "";
        this.states = []; //not sure what this is an array of yet TJM 4/14

        if (config) {
            this.pptHealthConditions = config.pptHealthConditions;
            this.selectedRetirementState = config.selectedRetirementState;
            this.states = config.states;
        }
    }
}

export default HealthSurvey;
