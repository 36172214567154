/**
 * @name app.common.model.domain.HealthViewProjection
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Creates a defined object structure for the HealthViewProjection object type.
 */

/**
 * The HealthViewProjection object constructor.
 * @param config This object of properties you would like to base the plan off of. Note that only primitives are used.
 * @constructor
 */

"use strict";

const Condition = function (config) {
    this.id = 0;
    this.conditionCode = "";
    this.conditionName = "";
    this.isPreselected = false;
    this.actionCode = "";
    this.tooltip = "";
    this.faq = "";

    if (config) {
        this.id = config.id;
        this.conditionCode = config.conditionCode;
        this.conditionName = config.conditionName;
        this.isPreselected = config.isPreselected;
        this.actionCode = config.actionCode;
        this.tooltip = config.tooltip;
        this.faq = config.faq;
    }
};

export default Condition;
