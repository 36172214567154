import React from "react";

import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";

export const Headers = ({ title, message }) => {
    return (
        <th>
            <OverlayTrigger
                overlay={
                    <Popover id="popover-trigger-hover-focus" title={title}>
                        {message}
                    </Popover>
                }
                placement="top"
            >
                <div className="text-uppercase text-bold">{title}</div>
            </OverlayTrigger>
        </th>
    );
};
Headers.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string
};
