import React from "react";

import { ktmgFlags } from "gw-shared-components";

import { useGroupTxnAccess, usePrimaryPlan } from "../../queries";

import SearchBox from "./SearchBox";

const SearchBoxWrapper = () => {
    const { data: primaryPlan } = usePrimaryPlan();

    const { data: txnCodesResponseData } = useGroupTxnAccess({
        individualId: primaryPlan?.indId,
        planId: primaryPlan?.gaId,
        txnCodes: ktmgFlags.CUSTOMER_WEB_SEARCH
    });

    if (
        !primaryPlan ||
        !txnCodesResponseData ||
        txnCodesResponseData[ktmgFlags.CUSTOMER_WEB_SEARCH] !== "true"
    ) {
        return null;
    }

    return <SearchBox primaryPlan={primaryPlan} />;
};

export default SearchBoxWrapper;
