import React from "react";

import PropTypes from "prop-types";

import constants from "../../constants/Constants";
import { useIsPrivileged } from "../../hooks/useEmulatorType";

/**
 * Renders the tabs
 * Uses 'userTabActive' prop to determine which tab is active
 */
const Tabs = ({
    deleteSpouse,
    spouseName,
    modalTranslations,
    userTabActive,
    spouseSaved,
    isEmulator,
    handleTabClick,
    userName
}) => {
    const spouse = deleteSpouse
        ? constants.DELETED
        : (spouseName && spouseName.toUpperCase()) || modalTranslations.addSpouse;
    const userClass = userTabActive ? "tab-active" : "";
    const spouseClass = userTabActive ? "" : "tab-active";
    const noSpouseClass = spouseSaved ? "" : "no-spouse";
    const isPrivileged = useIsPrivileged();
    const showSpouseTab = isPrivileged && spouseName;

    const handleOnClick = (e) => {
        return (!isEmulator || showSpouseTab) && handleTabClick(e);
    };

    const handleButtonFocus = (e) => {
        const isTabPressed = e.key === "Tab" || e.keyCode === 9;

        if (isTabPressed) {
            e.target.classList.add("modal-tab-focus");
        }
    };

    const handleButtonBlur = (e) => {
        e.target.classList.remove("modal-tab-focus");
    };

    return (
        <div className="modal-tabs" id="user-spouse-tab" data-testid="user-spouse-tab">
            <button
                aria-pressed={userTabActive}
                type="button"
                id="user-tab"
                className={`tab ${userClass}`}
                onClick={handleOnClick}
                onKeyUp={handleButtonFocus}
                onBlur={handleButtonBlur}
            >
                {userName}
            </button>

            {(!isEmulator || isPrivileged) && (
                <button
                    aria-pressed={!userTabActive}
                    type="button"
                    id="spouse-tab"
                    className={`tab ${spouseClass} ${noSpouseClass}`}
                    onClick={handleOnClick}
                    onKeyUp={handleButtonFocus}
                    onBlur={handleButtonBlur}
                >
                    {spouse}
                </button>
            )}
        </div>
    );
};

Tabs.propTypes = {
    spouseName: PropTypes.string,
    userTabActive: PropTypes.bool.isRequired,
    isEmulator: PropTypes.bool.isRequired,
    userName: PropTypes.string.isRequired,
    handleTabClick: PropTypes.func.isRequired,
    deleteSpouse: PropTypes.bool,
    modalTranslations: PropTypes.object,
    spouseSaved: PropTypes.bool
};

export default Tabs;
