"use strict";
/**
 * @name app.common.model.domain.HsaDetails
 * @date 4/25/2018
 * @copyright 2018 Empower Retirement
 * @type Class
 * @description
 * Creates a defined object structure for the HsaDetails object type.
 */
class HsaDetails {
    constructor(config) {
        this.acctStatus = null;
        this.expenseSelectionInd = null;
        this.customExpenseAmount = 0;
        this.aggrPriorYearSpend = null;
        this.coverageType = null;
        this.nationalAverageValues = {
            single: 0,
            singleCatchUp: 0,
            family: 0,
            familyCatchUp: 0
        };

        if (config) {
            this.acctStatus = config.acctStatus;
            this.expenseSelectionInd = config.expenseSelectionInd;
            this.customExpenseAmount = config.customExpenseAmount;
            this.aggrPriorYearSpend = config.aggrPriorYearSpend;
            this.coverageType = config.coverageType;
            this.nationalAverageValues.single = config.nationalAverageSingle;
            this.nationalAverageValues.singleCatchUp = config.nationalAverageSingleCatchUp;
            this.nationalAverageValues.family = config.nationalAverageFamily;
            this.nationalAverageValues.familyCatchUp = config.nationalAverageFamilyCatchUp;
        }
    }
}

export default HsaDetails;
