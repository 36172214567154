import React from "react";

import ReferenceData from "../../../../constants/ReferenceData";
import { calculateRoRType } from "../../helpers";
import IQuickViewAccount from "../../types";
import QuickViewLink from "../QuickViewLink";

interface RenderRoRProps {
    annualRateOfReturn: string | undefined;
    planDetails: IQuickViewAccount | null;
    rateOfReturnDateRange: string;
    translations: {
        rateOfReturn: string;
    };
}

const RenderRoR = ({
    translations,
    rateOfReturnDateRange,
    planDetails,
    annualRateOfReturn
}: RenderRoRProps) => {
    if (!planDetails || !annualRateOfReturn || !translations) {
        return null;
    }

    const rate = calculateRoRType(planDetails) as string;
    const rateClass = parseFloat(rate) > 0 ? "rateOfReturnIncrease" : "rateOfReturnDecrease";

    return (
        <div className="quick-view-item d-flex flex-column">
            <QuickViewLink
                link={ReferenceData.URL_HYPERLINK_RATE_OF_RETURN}
                planDetails={planDetails}
                title={translations.rateOfReturn}
            />
            <div className="rateOfReturnDateAndPercentaje">
                <p data-testid="rate-of-return" className={rateClass}>
                    {rate + "%"}
                </p>
                <p className="italic">({rateOfReturnDateRange})</p>
            </div>
        </div>
    );
};

export default RenderRoR;
