import React, { useEffect, useRef, memo, useCallback } from "react";

import { withProfiler } from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { queryFocusableElements } from "core-ui/client/react/core/utils/accessibilityHelpers";
import { isUndefinedOrNull, Loader } from "gw-shared-components";
import { useAtomValue } from "jotai";
import { each as _each, find as _find } from "lodash";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { HashRouter as Switch, Route, useLocation } from "react-router-dom";
import { useWindowScroll } from "react-use";

import { ROUTE_DASHBOARD, ROUTE_REFERRAL } from "../../../routes";
import { setBaselineRetirementAge } from "../../actions/baseline/baselineActions";
import { setParticipant } from "../../actions/participantDetails/participantDetailsActions";
import { updateProjectedIncome } from "../../actions/projectedIncome/projectedIncomeActions";
import { setSpouse, deleteSpouse } from "../../actions/spouse/spouseActions";
import { csrfAtom, homePageEligibilityAtom } from "../../atoms/atoms";
import { DashboardShell } from "../../components/loadShells/LoadShells";
import ErrorModal from "../../components/modals/errorModal/ErrorModal";
import SideBar from "../../components/sideBar/SideBar";
import ReferenceData from "../../constants/ReferenceData";
import IntegratedSubPageContainer from "../../containers/IntegratedSubPageContainer";
import { useAmplitudePageViews } from "../../hooks/useAmplitudePageViews";
import useDisplayPcapModal from "../../hooks/useDisplayPcapModal";
import { updateProjectionFactors } from "../../middleware/projectionFactorsMiddleware";
import { loadIntegratedSocialSecurity } from "../../middleware/socialSecurityMiddleware";
import { getReferral } from "../../middleware/userMessagesMiddleware";
import { QUERY_KEYS } from "../../queries/constants";
import getShowLiat from "../../services/getShowLiat";
import { usePageViews } from "../../utils/customHooks";
import { convertDobFormat } from "../../utils/dateUtils";
import SidebarContainer from "../sidebarContainer/SidebarContainer";

import CustomerReferral from "./../CustomerReferral";
import Dashboard from "./../Dashboard";
import ModalContainer from "./../ModalContainer";
import { getSubPageRoutes, ROUTES_WITH_D2C_SIDEBAR } from "./helpers";
import {
    useInitializedIntegrated,
    usePcComponentLoaded,
    useOpenAccountFooter,
    useRetailAccount,
    useSamlServiceError,
    useModalStackListener,
    useLocationMounting,
    useSideBarDisplay
} from "./hooks";

const IntegratedContainer = ({ loadingEligibility, eligibilityData }) => {
    const homePageEligibility = useAtomValue(homePageEligibilityAtom);
    const { data: showLIAT, isLoading: loadingShowLiat } = useQuery({
        queryKey: [QUERY_KEYS.ELIGIBLE],
        queryFn: getShowLiat
    });

    const csrf = useAtomValue(csrfAtom);

    const containerLayout = useRef();
    const switchRef = useRef();
    const collapseButtonRef = useRef();

    const dispatch = useDispatch();
    const { y } = useWindowScroll();
    usePageViews("INTEGRATED");
    useAmplitudePageViews();

    const { readyToShowApplication, txnAccess } = useSelector((state) => state.shared);

    const participant = useSelector((state) => state.participant);
    const spouse = useSelector((state) => state.spouse);

    const { data: referral } = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [QUERY_KEYS.GET_USER_MESSAGES],
        queryFn: () => getReferral(csrf),
        enabled: !!csrf
    });

    const displayModal = useDisplayPcapModal();

    const routeLocation = useLocationMounting();

    const hashLocation = useLocation();

    const {
        hideSideBar,
        showSidebarClass,
        subPageContainerClass,
        showCollapsibleDiv,
        slideClass,
        isTabletOrMobileFlag,
        setIsTabletOrMobileFlag
    } = useSideBarDisplay(routeLocation.pathname);

    const showAssetsAndLiabilitiesSidebar =
        txnAccess.showNewSidebar && !ROUTES_WITH_D2C_SIDEBAR.includes(routeLocation.pathname);

    useInitializedIntegrated({ showLIAT });

    const samlServiceResponse = useSamlServiceError();

    useRetailAccount();

    useModalStackListener({ loadingEligibility });

    // Render open account footer
    useOpenAccountFooter();

    usePcComponentLoaded();

    const callSocialSecurity = useCallback(
        (updateFactors = true) => {
            dispatch(loadIntegratedSocialSecurity()).then(() => {
                if (updateFactors) {
                    dispatch(updateProjectionFactors(false)).then(() =>
                        dispatch(updateProjectedIncome())
                    );
                }
            });
        },
        [dispatch]
    );

    const syncToProfile = useCallback(
        async (e) => {
            const payload = e.detail.person;

            if (isUndefinedOrNull(payload)) {
                dispatch(deleteSpouse());
                dispatch(updateProjectionFactors(false));
                dispatch(updateProjectedIncome());
            } else {
                const {
                    id: userId,
                    retirementAge,
                    empowerContextProfiles,
                    name,
                    relationship,
                    income,
                    maritalStatus,
                    birthday
                } = payload;
                const isParticipant = participant.personId === userId;
                const isSpouse =
                    (relationship === "SPOUSE" || maritalStatus === "MARRIED") && !isParticipant;
                const spouseAdded = !isParticipant && !spouse.exists && isSpouse;
                const updatingSpouse = spouse.exists && isSpouse;
                const userContext = isParticipant ? participant : spouse;
                const setAction = isParticipant ? setParticipant : setSpouse;
                const formattedDob = convertDobFormat(userContext.dateOfBirth, true);
                let baseSalary = 0;
                let varSalary = 0;
                let retireAge = retirementAge;
                const birthdayString =
                    payload.birthdayString || `${birthday.month}/${birthday.day}/${birthday.year}`;

                // Set retirement age if it comes in null or undefined
                if (isUndefinedOrNull(retirementAge)) {
                    if (isParticipant) {
                        retireAge = participant.retirementAge;
                    } else {
                        if (spouse.exists) {
                            retireAge = spouse.retirementAge;
                        } else {
                            retireAge = ReferenceData.DEFAULT_RETIREMENT_AGE;
                        }
                    }
                }

                //FCDX-23976 - find the context to set the correct salary
                const currentEmpowerContextProfile = empowerContextProfiles?.find(
                    (context) => context.empowerContextId == participant.individualId
                );
                // Payload salary can be a string
                // Convert all salary values to number
                if (isParticipant) {
                    baseSalary =
                        Number(currentEmpowerContextProfile?.salary) ||
                        participant.salary.salaryOnFile ||
                        Number(income.salary);
                    varSalary = Number(
                        currentEmpowerContextProfile?.additionalCompensation || varSalary
                    );
                } else if (updatingSpouse) {
                    baseSalary = Number(income.salary);
                }

                const profilePart = {
                    retirementAge: retireAge,
                    dateOfBirth: birthdayString,
                    firstName: name.firstName,
                    lastName: name.lastName,
                    salary: {
                        base: baseSalary,
                        variable: varSalary
                    }
                };

                const localPart = {
                    retirementAge: userContext.retirementAge,
                    dateOfBirth: formattedDob,
                    firstName: userContext.firstName,
                    lastName: userContext.lastName,
                    salary: {
                        base: userContext.salary.base,
                        variable: userContext.salary.variable || 0
                    }
                };

                const findChanges = (profile, local, user) => {
                    const changes = {};

                    _each(profile, (val, key) => {
                        if (key === "salary") {
                            if (local[key].base !== val.base) {
                                changes.salary = {
                                    ...user.salary,
                                    [key]: val.base
                                };
                            }

                            if (local[key].variable !== val.variable) {
                                if (changes.salary) {
                                    changes.salary[key] = val.variable;
                                } else {
                                    changes.salary = {
                                        ...user.salary,
                                        [key]: val.variable
                                    };
                                }
                            }
                        }

                        if (local[key] !== val) {
                            changes[key] = val;
                        }
                    });

                    return changes;
                };

                if (!spouseAdded) {
                    const changesObj = findChanges(profilePart, localPart, userContext);
                    const updateIncomeNeeded =
                        showLIAT &&
                        (changesObj.retirementAge || changesObj.dateOfBirth || changesObj.salary);

                    const updateFactorsNeeded =
                        showLIAT && (changesObj.retirementAge || changesObj.dateOfBirth);

                    const updateSocialSecurity =
                        showLIAT && (changesObj.dateOfBirth || changesObj.salary);

                    if (Object.keys(changesObj).length) {
                        dispatch(setAction(changesObj));
                        if (changesObj?.retirementAge) {
                            dispatch(setBaselineRetirementAge(changesObj.retirementAge));
                        }
                    }

                    if (updateSocialSecurity) {
                        callSocialSecurity(updateFactorsNeeded);
                    }

                    if (updateFactorsNeeded && !updateSocialSecurity) {
                        dispatch(updateProjectionFactors(false));
                    }

                    if (updateIncomeNeeded && !updateSocialSecurity) {
                        dispatch(updateProjectedIncome());
                    }
                } else if (spouseAdded && showLIAT) {
                    profilePart.pcapPersonId = userId;
                    profilePart.personId = userId;
                    profilePart.age = payload.age;

                    dispatch(setAction(profilePart));
                    callSocialSecurity(true);
                }
            }
        },
        [callSocialSecurity, dispatch, participant, showLIAT, spouse]
    );

    // Listen for updates on profile page
    useEffect(() => {
        if (readyToShowApplication) {
            window.addEventListener("pc_integratedprofileupdatecompleted", syncToProfile);
            window.addEventListener("social_security_income", callSocialSecurity);
            return () => {
                window.removeEventListener("pc_integratedprofileupdatecompleted", syncToProfile);
                window.removeEventListener("social_security_income", callSocialSecurity);
            };
        }
    }, [callSocialSecurity, readyToShowApplication, syncToProfile]);

    useEffect(() => {
        if (!isTabletOrMobileFlag) {
            const sideBarContent = document.querySelector("#sidebarContent > :not(.is-hidden)");
            sideBarContent?.querySelector(queryFocusableElements)?.focus();
        }
    }, [isTabletOrMobileFlag]);

    const handleClick = () => {
        setIsTabletOrMobileFlag(!isTabletOrMobileFlag);
    };

    const handleKeydown = (e) => {
        if (e.key === "Enter" || e.key === " ") {
            handleClick();
        }
    };

    const handleOnFocus = (e) => {
        if (e.relatedTarget !== collapseButtonRef.current) {
            return collapseButtonRef.current && collapseButtonRef.current.focus();
        }

        if (!switchRef.current) return;

        const switchFocusableEl = switchRef.current.querySelector(queryFocusableElements);

        if (switchFocusableEl) {
            switchFocusableEl.focus();
        }
    };

    const handleHover = () => {
        setIsTabletOrMobileFlag(!isTabletOrMobileFlag);
    };

    const leaveSideBar = () => {
        setIsTabletOrMobileFlag(true);
    };

    const siteHeader = document.querySelector(".site-header");

    let collapsibleDivTopZero = "position-top-zero";

    if (siteHeader && siteHeader.clientHeight > y) {
        // *NOTE* only for when participant goes into more responsive view
        // used to determine if sidebar should be positioned all way on top.
        // Currently sits under header. Should be pushed to top when scrolling down.
        collapsibleDivTopZero = "";
    }

    const showReferral = txnAccess.referralEnabled && referral;

    const subPageRoutes = getSubPageRoutes();

    const loadingIntegratedServices = loadingShowLiat || loadingEligibility;

    if (loadingIntegratedServices) {
        return (
            <DashboardShell>
                <Loader />
            </DashboardShell>
        );
    }

    /**
     * Because this service returns 2 different response objects, we will check to see if the response object is the one we expect
     * containing the secDeminModal and if so use that value. Otherwise, use the global value.
     */
    const secDeminModal = eligibilityData?.secDeminModal;

    return (
        <>
            <ErrorModal />
            {samlServiceResponse.error && <DashboardShell />}

            {!samlServiceResponse.error && homePageEligibility && (
                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                <div
                    className={`collapsible-div ${showCollapsibleDiv} ${collapsibleDivTopZero}`}
                    onMouseEnter={handleHover}
                    onKeyDown={handleKeydown}
                    onClick={handleClick}
                    aria-hidden={!isTabletOrMobileFlag}
                    ref={collapseButtonRef}
                    {...(isTabletOrMobileFlag && {
                        tabIndex: "0",
                        role: "button",
                        "aria-label": "Show collapsible sidebar"
                    })}
                >
                    <div className="d-flex">
                        <i className="fa fa-solid fa-chevron-right fa-icon fa-lg" />
                    </div>
                </div>
            )}

            {!samlServiceResponse.error && (
                <div className="dashboard-page-layout" ref={containerLayout}>
                    <div className="dashboard-layout d-flex">
                        {!hideSideBar &&
                            (showAssetsAndLiabilitiesSidebar ? (
                                <SidebarContainer
                                    onBlur={leaveSideBar}
                                    onFocus={handleOnFocus}
                                    shouldCollapse={isTabletOrMobileFlag}
                                    sidebarClass={`${slideClass} ${collapsibleDivTopZero} ${showSidebarClass}`}
                                />
                            ) : (
                                <SideBar
                                    ariaHidden={isTabletOrMobileFlag}
                                    onFocus={handleOnFocus}
                                    showSidebar={showSidebarClass}
                                    leaveSideBar={leaveSideBar}
                                    slideClass={`${slideClass} ${collapsibleDivTopZero}`}
                                    homePageEligibility={homePageEligibility}
                                />
                            ))}
                        <div ref={switchRef}>
                            <Switch>
                                <Route
                                    location={routeLocation}
                                    path={subPageRoutes}
                                    render={() => (
                                        <IntegratedSubPageContainer
                                            subPageContainerClass={subPageContainerClass}
                                        />
                                    )}
                                />
                                <Route
                                    location={routeLocation}
                                    path={ROUTE_DASHBOARD}
                                    render={() => (
                                        <Dashboard
                                            modalOpen={displayModal}
                                            showLIAT={showLIAT}
                                            secDeminModal={secDeminModal}
                                            location={routeLocation}
                                        />
                                    )}
                                />
                                {showReferral && (
                                    <Route
                                        location={routeLocation}
                                        path={ROUTE_REFERRAL}
                                        render={() => <CustomerReferral referral={referral} />}
                                    />
                                )}
                            </Switch>
                        </div>
                    </div>
                    {/* Routeable Modal goes here */}
                    <ModalContainer location={hashLocation} show={displayModal} />
                </div>
            )}
        </>
    );
};

IntegratedContainer.propTypes = {
    loadingEligibility: PropTypes.bool.isRequired,
    eligibilityData: PropTypes.object // can be undefined while waiting for service to finish
};

export default withProfiler(memo(IntegratedContainer), {
    name: "IntegratedContainer"
});
