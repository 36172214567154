import { isUndefinedOrNull } from "gw-shared-components";

import { SET_INIT_DATA } from "../../actions/initData";
import {
    SET_PARTICIPANT_RETIREMENT_AGE,
    SET_SPOUSE_RETIREMENT_AGE
} from "../../actions/participantDetails/participantDetailsTypes";
import {
    SET_MIGRATED_SOCIAL_SECURITY,
    SET_PARTICIPANT_UPDATE_DONE,
    SET_SPOUSE_UPDATE_DONE,
    SAVE_SOCIAL_SECURITY_CHANGE,
    SET_PARTICIPANT_START_REC_AGE,
    SET_SOCIAL_SECURITY
} from "../../actions/socialSecurity/socialSecurityActionTypes";
import { DELETE_SPOUSE } from "../../actions/spouse/spouseActionTypes";
import ReferenceData from "../../constants/ReferenceData";
import SOCIAL_SECURITY_CONSTANTS from "../../constants/SocialSecurityConstants";
import { fixedTwoDecimalPlaces } from "../../utils/currencyUtils";
import {
    getMonthlyAmountFromBenefitsList,
    validateSocialSecurityAge
} from "../../utils/socialSecurity";

const initialState = {
    socialSecuritySpouse: {
        pcapPersonId: "",
        // socialSecurityIncome is data received from service
        socialSecurityIncome: {
            socialSecurityStartAge: null,
            socialSecurityAmount: null,
            annualAmountPreference: null // Acceptable options NONE, CALCULATED, USER_INPUT
        },
        // UI values that can be updated/changed
        monthlyAmountOverride: "",
        estimatedAmountCheckboxStatus: false,
        amountDisplayed: null,
        startReceiveAge: ReferenceData.MIN_SOCIALSECURITY_STARTAGE,
        retirementAge: ReferenceData.MIN_RETIREMENT_AGE,
        ageOverride: false,
        benefitsValues: [],
        atRetirement: false
    },
    socialSecurityUser: {
        pcapPersonId: "",
        // socialSecurityIncome is data received from service
        socialSecurityIncome: {
            socialSecurityStartAge: null,
            socialSecurityAmount: null,
            annualAmountPreference: null // Acceptable options NONE, CALCULATED, USER_INPUT
        },
        // UI values that can be updated/changed
        monthlyAmountOverride: "",
        estimatedAmountCheckboxStatus: false,
        amountDisplayed: null,
        startReceiveAge: ReferenceData.MIN_SOCIALSECURITY_STARTAGE,
        retirementAge: ReferenceData.MIN_RETIREMENT_AGE,
        ageOverride: false,
        benefitsValues: [],
        atRetirement: false
    },
    sliderUpdate: false,
    ssMonthlyBenefit: null
};

const getSocialSecurityBenefitsValues = (ssBenefitByStartAge) => {
    if (!ssBenefitByStartAge) {
        return 0;
    }
    // Set the monthly and yearly values here so participant can easily toggle terms without further calculations
    return Object.entries(ssBenefitByStartAge).map(([key, value]) => {
        return {
            age: Number(key),
            yearly: fixedTwoDecimalPlaces(value),
            monthly: fixedTwoDecimalPlaces(Math.round(value / 12))
        };
    });
};

const getStartReceiveAge = (socialSecurityStartAge, retirementAge, age) => {
    if (socialSecurityStartAge) {
        return validateSocialSecurityAge(socialSecurityStartAge);
    }
    // If no socialSecurityStartAge, check and validate that the retirement age is within the params (62-70) for Social Security table
    if (retirementAge) {
        return validateSocialSecurityAge(retirementAge);
    }
    // Fallback of using the participants age and validating within params
    return validateSocialSecurityAge(age);
};

/** AmountToDisplay - examine socialSecurityAmount and annualAmountPreference  ***/
const getAmountToDisplay = (
    socialSecurityAmount,
    ssInfo,
    startReceiveAge,
    estimatedAmountCheckboxStatus
) => {
    // If participant has socialSecurityIncome then that is to be displayed and override check is false
    // if this value is null, find the amount to be displayed based on the ss Age and the benefits map. Also set override to true
    let amount = 0;
    const preference = ssInfo.socialSecurityIncome.annualAmountPreference;

    if (socialSecurityAmount !== null && socialSecurityAmount >= 0) {
        if (estimatedAmountCheckboxStatus) {
            if (startReceiveAge) {
                if (
                    preference ===
                        SOCIAL_SECURITY_CONSTANTS.ANNUAL_AMOUNT_PREFERENCES_OPTIONS.CALCULATED ||
                    preference === SOCIAL_SECURITY_CONSTANTS.ANNUAL_AMOUNT_PREFERENCES_OPTIONS.NONE
                ) {
                    amount = getMonthlyAmountFromBenefitsList(
                        ssInfo.ssBenefitByStartAge,
                        startReceiveAge
                    );
                } else {
                    amount = socialSecurityAmount / 12;
                }
            } else {
                amount = socialSecurityAmount / 12;
            }
        } else {
            if (
                preference ===
                SOCIAL_SECURITY_CONSTANTS.ANNUAL_AMOUNT_PREFERENCES_OPTIONS.USER_INPUT
            ) {
                amount = socialSecurityAmount / 12;
            } else if (
                preference === SOCIAL_SECURITY_CONSTANTS.ANNUAL_AMOUNT_PREFERENCES_OPTIONS.NONE
            ) {
                amount = getMonthlyAmountFromBenefitsList(
                    ssInfo.ssBenefitByStartAge,
                    startReceiveAge
                );
            } else {
                amount = socialSecurityAmount / 12;
            }
        }
    } else {
        amount = getMonthlyAmountFromBenefitsList(ssInfo.ssBenefitByStartAge, startReceiveAge);
    }

    return fixedTwoDecimalPlaces(amount);
};

/** Monthly Override - examine socialSecurityAmount and annualAmountPreference  ***/
const getMonthlyOverride = (ssInfo, estimatedAmountCheckboxStatus) => {
    let amountOverride = "";
    const preference = ssInfo.socialSecurityIncome.annualAmountPreference;

    if (ssInfo.socialSecurityIncome.socialSecurityAmount >= 0) {
        if (estimatedAmountCheckboxStatus) {
            amountOverride = "";
        } else {
            if (
                preference ===
                SOCIAL_SECURITY_CONSTANTS.ANNUAL_AMOUNT_PREFERENCES_OPTIONS.USER_INPUT
            ) {
                amountOverride = ssInfo.socialSecurityIncome.socialSecurityAmount / 12;
            }
        }
    }
    return amountOverride;
};

const setEstimatedCheckBoxStatus = ({ socialSecurityAmount, annualAmountPreference }) => {
    if (!isUndefinedOrNull(annualAmountPreference)) {
        return (
            annualAmountPreference ===
                SOCIAL_SECURITY_CONSTANTS.ANNUAL_AMOUNT_PREFERENCES_OPTIONS.CALCULATED ||
            annualAmountPreference ===
                SOCIAL_SECURITY_CONSTANTS.ANNUAL_AMOUNT_PREFERENCES_OPTIONS.NONE
        );
    }
    if (!isUndefinedOrNull(socialSecurityAmount)) {
        return false;
    }
    return true;
};

const getValuesFromSsInfo = ({ ssInfo, retirementAge, age }) => {
    let socialSecurityStartAge = null;
    let socialSecurityAmount = 0;
    let annualAmountPreference = null;
    let estimatedAmountCheckboxStatus = true;
    let ageOverride = true;
    let monthlyAmountOverride = "";
    let atRetirement = false;

    if (ssInfo && ssInfo.socialSecurityIncome) {
        socialSecurityStartAge = ssInfo.socialSecurityIncome.socialSecurityStartAge;
        socialSecurityAmount = ssInfo.socialSecurityIncome.socialSecurityAmount;
        annualAmountPreference = ssInfo.socialSecurityIncome.annualAmountPreference;
        estimatedAmountCheckboxStatus = setEstimatedCheckBoxStatus(ssInfo.socialSecurityIncome);
        ageOverride = estimatedAmountCheckboxStatus;
    }

    const startReceiveAge = ssInfo
        ? getStartReceiveAge(socialSecurityStartAge, retirementAge, age)
        : null;

    const amountDisplayed = ssInfo
        ? getAmountToDisplay(
              socialSecurityAmount,
              ssInfo,
              startReceiveAge,
              estimatedAmountCheckboxStatus
          )
        : 0;
    monthlyAmountOverride =
        ssInfo && ssInfo.socialSecurityIncome
            ? getMonthlyOverride(ssInfo, estimatedAmountCheckboxStatus)
            : "";
    atRetirement = startReceiveAge === retirementAge;

    const benefitsValues = ssInfo
        ? getSocialSecurityBenefitsValues(ssInfo.ssBenefitByStartAge)
        : [];

    return {
        ...ssInfo,
        startReceiveAge,
        amountDisplayed,
        estimatedAmountCheckboxStatus,
        benefitsValues,
        annualAmountPreference,
        retirementAge,
        monthlyAmountOverride,
        atRetirement,
        ageOverride
    };
};

const setIntegratedSocialSecurity = (state, users) => {
    let socialSecuritySpouse = { ...state.socialSecuritySpouse };

    const dataFromPayload = getValuesFromSsInfo(users[0]);

    const socialSecurityUser = {
        ...state.socialSecurityUser,
        ...dataFromPayload
    };

    if (users[1]) {
        const spouseDataFromPayload = getValuesFromSsInfo(users[1]);

        socialSecuritySpouse = {
            ...socialSecuritySpouse,
            ...spouseDataFromPayload
        };
    }

    return {
        ...state,
        socialSecurityUser,
        socialSecuritySpouse
    };
};

const getSocialSecurityDataFromTable = (benefitsValues, startReceiveAge) =>
    benefitsValues.find((item) => item.age === startReceiveAge);

const setAmountForDisplay = ({
    estimatedAmountCheckboxStatus,
    monthlyAmountOverride,
    benefitsValues,
    startReceiveAge,
    ageOverride
}) => {
    if (estimatedAmountCheckboxStatus || ageOverride) {
        const ssDataSet = getSocialSecurityDataFromTable(benefitsValues, startReceiveAge);
        return ssDataSet.monthly;
    } else if (monthlyAmountOverride > "") {
        return monthlyAmountOverride;
    }
    return monthlyAmountOverride;
};

const updateSocialSecurityOnSave = (state, payload) => {
    const amountDisplayed = setAmountForDisplay(payload.socialSecurityUser);
    const ageOverride =
        payload.socialSecurityUser.estimatedAmountCheckboxStatus &&
        (state.socialSecurityUser.retirementAge !== payload.socialSecurityUser.startReceiveAge ||
            (payload.socialSecurityUser.startReceiveAge > "" &&
                state.socialSecurityUser.retirementAge ===
                    payload.socialSecurityUser.startReceiveAge));
    const userAtRetirement = payload.socialSecurityUser.atRetirement;

    const socialSecurityUser = {
        ...state.socialSecurityUser,
        ...payload.socialSecurityUser,
        amountDisplayed,
        ageOverride,
        userAtRetirement
    };

    let socialSecuritySpouse = { ...state.socialSecuritySpouse };

    if (payload.hasSpouse) {
        const spouseAmountDisplayed = setAmountForDisplay(payload.socialSecuritySpouse);
        socialSecuritySpouse = {
            ...socialSecuritySpouse,
            ...payload.socialSecuritySpouse,
            amountDisplayed: spouseAmountDisplayed,
            ageOverride:
                payload.socialSecuritySpouse.estimatedAmountCheckboxStatus &&
                state.socialSecuritySpouse.retirementAge !==
                    payload.socialSecuritySpouse.startReceiveAge
        };
    }

    return {
        ...state,
        socialSecurityUser,
        socialSecuritySpouse
    };
};

const getUpdatedAmtToDisplay = (state, startRecAge) => {
    const socialSecurityUser = { ...state.socialSecurityUser };
    const row = getSocialSecurityDataFromTable(socialSecurityUser.benefitsValues, startRecAge);

    return socialSecurityUser.estimatedAmountCheckboxStatus
        ? row.monthly
        : socialSecurityUser.monthlyAmountOverride;
};

const getNewStateFromSsInfo = (state, ssInfo) => {
    const newState = { ...state };
    if (ssInfo) {
        if (ssInfo.socialSecurityPerson) {
            newState.socialSecurityUser = ssInfo.socialSecurityPerson;
        }
        if (ssInfo.socialSecuritySpouse) {
            newState.socialSecuritySpouse = ssInfo.socialSecuritySpouse;
        }
        if (ssInfo.ssMonthlyBenefit) {
            newState.ssMonthlyBenefit = ssInfo.ssMonthlyBenefit;
        }
    }

    return newState;
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_INIT_DATA: {
            return setIntegratedSocialSecurity(state, payload.users);
        }
        // circle back on this state change
        case SET_SOCIAL_SECURITY:
            return getNewStateFromSsInfo(state, payload);

        case SET_MIGRATED_SOCIAL_SECURITY:
            return setIntegratedSocialSecurity(state, payload.users);

        case DELETE_SPOUSE:
            return { ...state, socialSecuritySpouse: null };

        case SET_PARTICIPANT_UPDATE_DONE:
            return {
                ...state,
                socialSecurityUser: {
                    ...state.socialSecurityUser,
                    ageOverride: false
                }
            };

        case SET_SPOUSE_UPDATE_DONE:
            return {
                ...state,
                socialSecuritySpouse: {
                    ...state.socialSecuritySpouse,
                    ageOverride: false
                }
            };

        case SAVE_SOCIAL_SECURITY_CHANGE:
            return updateSocialSecurityOnSave(state, payload);

        case SET_PARTICIPANT_RETIREMENT_AGE:
            return {
                ...state,
                socialSecurityUser: { ...state.socialSecurityUser, retirementAge: payload }
            };

        case SET_SPOUSE_RETIREMENT_AGE:
            return {
                ...state,
                socialSecuritySpouse: { ...state.socialSecuritySpouse, retirementAge: payload }
            };
        case SET_PARTICIPANT_START_REC_AGE: {
            const amt = getUpdatedAmtToDisplay(state, payload.newStartAge);

            return {
                ...state,
                socialSecurityUser: {
                    ...state.socialSecurityUser,
                    startReceiveAge: payload.newStartAge,
                    amountDisplayed: amt
                }
            };
        }
        default:
            return state;
    }
};
