"use strict";
/**
 * @name app.infrastructure.domainModel.DeferralInfo
 * @date 1/26/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Definition of the DeferralInfo domain model object.
 */

class DeferralInfo {
    constructor(config) {
        this.catchUp402GInd = false;
        this.availableDeferrals = [];
        this.enrollmentGroupCodeHash = {};
        this.combinedRules = [];
        this.tierRules = [];
        this.typeCodeMap = {};

        if (config) {
            this.availableDeferrals = config.allowableDeferrals;
            this.enrollmentGroupCodeHash = config.enrollmentGroupCodeHash;
            this.combinedRules = config.combinedRules;
            this.tierRules = config.tierRules;
            this.catchUp402GInd = config.catchUp402GInd;
            this.typeCodeMap = config.typeCodeMap;
        }
    }
}

export default DeferralInfo;
