import _each from "lodash/each";
import _find from "lodash/find";
import _isNil from "lodash/isNil";

import ExternalAsset from "../models/ExternalAsset";
import ExternalAssetEmployerDetail from "../models/ExternalAssetEmployerDetail";
import LinkedAccountBalances from "../models/LinkedAccountBalances";
import OutsideAsset from "../models/OutsideAsset";
import OutsideAssetCategory from "../models/OutsideAssetCategory";
import ObjectUtil from "../utils/ObjectUtil";

const OtherAssetsFactory = {
    // RETIREMENT ASSETS

    createRetirementAssets: function (data) {
        if (data) {
            const list = [];
            _each(data, function (asset) {
                list.push(OtherAssetsFactory.createRetirementAsset(asset));
            });
            return list;
        }
    },
    createRetirementAsset: function (data) {
        return {
            name: data.name,
            investorId: data.investorId,
            investorAccountId: data.investorAccountId,
            estimatedAnnualAmount: data.estimatedAnnualAmount,
            costOfLivingAdjustable: data.costOfLivingAdjustable,
            adjustmentAmount: data.adjustmentAmount,
            taxable: data.taxable,
            pension: data.pension,
            estimatedStartYear: data.estimatedStartYear,
            estimatedEndYear: data.estimatedEndYear,
            estimatedStartAge: data.estimatedStartAge,
            estimatedEndAge: data.estimatedEndAge,
            untilLifeExpectancy: data.untilLifeExpectancy,
            lastModifiedOn: data.lastModifiedOn,
            dataSource: data.dataSource,
            assetType: "retirementIncome"
        };
    },

    // OUTSIDE ASSETS

    createOutsideAssets: function (outsideAssets, outsideAssetCategories) {
        //////////////////////////////////////////////////////////////////////////
        // Raw API data
        //////////////////////////////////////////////////////////////////////////
        /*
        outsideAssets = [{
                "id" : "",
                "name" : "",
                "type" : "",
                "value" : "",
                "planId" : "",
                "source" : "",
                "editable" : true,
                "deletable" : true,
                "viewable" : true,
                "employerContribution" : false
            }]
        
        outsideAssetCategories = [{
                "hover": "Hover Monthly Income in Retirement",
                "display": "Money outside of your 401(k) that you expect to have as monthly income in retirement.",
                "createdDate": null,
                "createdBy": "test",
                "updatedDate": null,
                "updatedBy": "Test",
                "faq": "FAQ Monthly Income in Retirement",
                "name": "Future monthly income",
                "id": 21,
                "status": "ACTIVE"
            }]
        }
         */

        const getCategoryPropertyValue = function (list, id, prop) {
            const item = _find(list, { id: id });
            return _isNil(item) || _isNil(item[prop]) ? null : item[prop];
        };

        let config;
        const list = [];
        _each(outsideAssets, function (item) {
            config = {
                id: item.assetId,
                name: item.name,
                type: item.categoryId,
                typeString: getCategoryPropertyValue(
                    outsideAssetCategories,
                    item.categoryId,
                    "name"
                ),
                description: getCategoryPropertyValue(
                    outsideAssetCategories,
                    item.categoryId,
                    "display"
                ),
                value: item.value,
                planId: item.planId, // mapped to plan.id
                source: item.source,
                isEditable: _isNil(item.editable) ? true : item.editable,
                isDeletable: _isNil(item.deletable) ? true : item.deletable,
                isViewable: _isNil(item.viewable) ? true : item.viewable,
                employerContribution: item.employerContribution,
                assetType: "otherAsset",
                monthlyIncomeEST: 0
            };
            list.push(new OutsideAsset(config));
        });
        return list;
    },

    createOutsideAssetCategories: (outsideAssetCategories) => {
        //////////////////////////////////////////////////////////////////////////
        // Raw API data
        //////////////////////////////////////////////////////////////////////////
        /*
         [
         {
         "hover": "Hover Monthly Income in Retirement",
         "display": "Money outside of your 401(k) that you expect to have as monthly income in retirement.",
         "createdDate": null,
         "createdBy": "test",
         "updatedDate": null,
         "updatedBy": "Test",
         "faq": "FAQ Monthly Income in Retirement",
         "name": "Future monthly income",
         "id": 21,
         "status": "ACTIVE"
         },
         ...
         ]
         */

        let config;
        const list = [];
        _each(outsideAssetCategories, (item) => {
            config = {
                hover: item.hover,
                display: item.display,
                createdDate: item.createdDate,
                createdBy: item.createdBy,
                updatedDate: item.updatedDate,
                updatedBy: item.updatedBy,
                faq: item.faq,
                name: item.name,
                id: item.id,
                status: item.status
            };
            list.push(new OutsideAssetCategory(config));
        });

        return list;
    },

    createParamsForSaveOtherAssetPost: (clientId, individualId, asset, action) => {
        const result = {
            assetId: asset.id,
            actionCode: action,
            name: asset.name,
            value: asset.value,
            categoryId: asset.type,
            clientId: clientId,
            individualId: individualId,
            presentValue: asset.type === 24 ? "FUTURE" : "PRESENT"
        };
        return result;
    },

    // EXTERNAL ASSETS

    createExternalAssets: (assets) => {
        if (assets) {
            const list = [];
            _each(assets, function (asset) {
                list.push(OtherAssetsFactory.createExternalAsset(asset));
            });
            return list;
        }
    },

    createExternalAsset: (asset) => {
        const config = {
            id: asset.id,
            retirementAccountId: asset.retirementAccountId,
            investorId: asset.investorId,
            accountTypeCode: asset.accountTypeCode,
            accountProviderName: asset.accountProviderName,
            accountTypeName: asset.accountTypeName,
            accountBalance: asset.accountBalance,
            currentContribution: OtherAssetsFactory.createCurrentContributionForExternalAsset(
                asset.currentContribution
            ),
            investment: OtherAssetsFactory.createInvestmentForExternalAsset(asset.investment),
            plAdviseCode: asset.plAdviseCode,
            assetAllocationAdvisableFlag: asset.assetAllocationAdvisableFlag,
            savingsRateAdvisableFlag: asset.savingsRateAdvisableFlag,
            lastUpdatedTime: asset.lastUpdatedTime,
            acctAggrId: asset.acctAggrId,
            acctAggrStatus: asset.acctAggrStatus,
            externalAccountType: asset.externalAccountType,
            externalSourceName: asset.externalSourceName,
            configInstruction: asset.configInstruction,
            connectionId: asset.connectionId,
            connectionSyncStatusCode: asset.connectionSyncStatusCode,
            isUserActionRequired: asset.isUserActionRequired,
            dataSource: asset.dataSource,
            assetType: "externalAsset"
        };
        return new ExternalAsset(config);
    },

    createCurrentContributionForExternalAsset: function (data) {
        if (!_isNil(data)) {
            const config = {
                id: data.id,
                plCurrentContributionCode: data.plCurrentContributionCode,
                employerMatchFlag: data.employerMatchFlag,
                profitSharingFlag: data.profitSharingFlag,
                profitSharingPercentage: data.profitSharingPercentage,
                profitSharingAmount: data.profitSharingAmount
            };
            const contributionDetail = [];
            _each(data.contributionDetail, function (detail) {
                contributionDetail.push(
                    OtherAssetsFactory.createContributionDetailForExternalAsset(detail)
                );
            });
            const employerMatchDetail = [];

            if (data.employerMatchDetail) {
                //sort tiers to make sure they are calculated correctly
                data.employerMatchDetail.sort(function (a, b) {
                    return a.sequenceNumber - b.sequenceNumber;
                });
            }
            _each(data.employerMatchDetail, function (detail) {
                employerMatchDetail.push(
                    OtherAssetsFactory.createEmployerMatchDetailForExternalAsset(detail)
                );
            });
            config.contributionDetail = contributionDetail;
            config.employerMatchDetail = employerMatchDetail;

            return config;
        }
        return null;
    },
    createContributionDetailForExternalAsset: function (data) {
        const detail = {
            id: data.id,
            plContributionTypeCode: data.plContributionTypeCode,
            contributionPercentageFlag: data.contributionPercentageFlag,
            contributionValue: data.contributionValue
        };

        return detail;
    },
    createEmployerMatchDetailForExternalAsset: function (data) {
        const config = {
            id: data.id,
            sequenceNumber: data.sequenceNumber,
            matchValuePercentageFlag: data.matchValuePercentageFlag,
            matchValue: data.matchValue,
            limitValuePercentageFlag: data.limitValuePercentageFlag,
            limitValue: data.limitValue
        };

        return new ExternalAssetEmployerDetail(config);
    },
    createInvestmentForExternalAsset: function (data) {
        return {
            id: data.id,
            plInvestmentTypeCode: data.plInvestmentTypeCode,
            investmentRiskBasedPortfolio: data.investmentRiskBasedPortfolio,
            investmentAssetAllocation: data.investmentAssetAllocation,
            investmentFundHoldings: data.investmentFundHoldings
        };
    },

    // LINKED ACCOUNT BALANCES

    createLinkedAccountBalances: function (balanceData, investorId) {
        const balances = [];
        const date = new Date();
        const strDate =
            date.getFullYear() +
            "-" +
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + date.getDate()).slice(-2);

        _each(balanceData, (balanceObj) => {
            _each(balanceObj.plans, (plan) => {
                const newBalanceObj = {
                    individualId: balanceObj.individualId,
                    investorId: investorId ? investorId : "",
                    planId: plan.id,
                    name: plan.planName,
                    assetType: Object.prototype.hasOwnProperty.call(plan, "trsFlexAccountInfo")
                        ? "OAB_TRS"
                        : "OAB_PLAN",

                    trsFlexAccountInfo: Object.prototype.hasOwnProperty.call(
                        plan,
                        "trsFlexAccountInfo"
                    )
                        ? plan.trsFlexAccountInfo
                        : null,
                    value: !Object.prototype.hasOwnProperty.call(plan, "trsFlexAccountInfo")
                        ? plan.totalBalance
                        : 0,
                    monthlyIncomeEST: 0,
                    type: 23,
                    typeString: "Lump sum ",
                    isEditable: true,
                    isDeletable: false,
                    isViewable: true,
                    employerContribution: false,
                    lastModifiedOn: strDate
                };

                if (newBalanceObj.trsFlexAccountInfo) {
                    newBalanceObj.trsFlexAccountInfo.isLinkedAccountBalances = true;

                    if (newBalanceObj.trsFlexAccountInfo.sdsvSubcode === "ANNUITY") {
                        newBalanceObj.type = 21;
                        newBalanceObj.typeString = "Monthly income in retirement ";
                    }

                    if (
                        newBalanceObj.trsFlexAccountInfo.earlyRetirementDate ||
                        newBalanceObj.trsFlexAccountInfo.normalRetirementDate
                    ) {
                        newBalanceObj.trsFlexAccountInfo.earlyRetirementDate =
                            ObjectUtil.formatDate(
                                newBalanceObj.trsFlexAccountInfo.earlyRetirementDate
                            );
                        newBalanceObj.trsFlexAccountInfo.normalRetirementDate =
                            ObjectUtil.formatDate(
                                newBalanceObj.trsFlexAccountInfo.normalRetirementDate
                            );
                    }
                }

                balances.push(new LinkedAccountBalances(newBalanceObj));
            });
        });

        return balances;
    },

    // OTHER ASSETS REF DATA

    createOtherAssetsRefData: function (data) {
        return data
            ? {
                  riskLevels: data.riskLevels,
                  accountInvestmentTypes: data.accountInvestmentTypes,
                  contributionTypes: data.contributionTypes,
                  assetAllocationClassTypes: data.assetAllocationClassTypes,
                  adviseOptionTypes: data.adviseOptionTypes,
                  accountTypes: data.accountTypes,
                  accountProviders: data.accountProviders
              }
            : null;
    }
};

export default OtherAssetsFactory;
