import axios from "axios";
import type { AxiosResponse } from "axios";

import RequestFactory from "../factories/RequestFactory";
import { getUrl } from "../utils/urlUtils";

interface PerformanceHistories {
    spData?: {
        accountSummaries: { dateRangeBalanceValueChange: number; userAccountId: number }[];
    };
}

interface PerformanceHistoriesError {
    code: string;
}

const getPerformanceHistories = (
    csrf: string,
    startDate: string,
    endDate: string,
    userAccountIds: string[] = [],
    interval = "DAY",
    requireBenchmark = false
): Promise<AxiosResponse<PerformanceHistories, PerformanceHistoriesError>> => {
    const url = getUrl("getPerformanceHistories");
    const data = new FormData();
    data.append("csrf", csrf);
    data.append("startDate", startDate);
    data.append("endDate", endDate);
    data.append("userAccountIds", JSON.stringify(userAccountIds));
    data.append("interval", interval);
    data.append("requireBenchmark", requireBenchmark.toString());

    return axios({
        method: RequestFactory.HTTP_METHOD.POST,
        headers: { "content-type": RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED },
        url,
        data,
        withCredentials: true
    });
};

export default getPerformanceHistories;
