import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { isUndefinedOrNull, isUndefined, isEmptyString, hasValue } from "gw-shared-components";

import Deferrals from "../../business/deferralsLogic/deferrals";
import {
    getDefaultNextStepMsg,
    getRoundedNextStepValue
} from "../../business/nextStepLogic/nextStep";
import DeferralMoneyType from "../../constants/DeferralMoneyType";
import { getRoundedValue } from "../../utils/numberUtils";

import { SET_COMPANY_MATCH_PROFIT_SHARING_MESSAGE, SET_NEXT_STEPS } from "./nextStepTypes";

/**
 * ACTIONS FOUND IN THIS FILE
 * @setCompanyMatchAndProfitSharingMessages
 * @setNextSteps
 */

/**
 * set Company Match and Profit Sharing Messages from Hub response
 */
export const setCompanyMatchAndProfitSharingMessages = (payload) => {
    return {
        type: SET_COMPANY_MATCH_PROFIT_SHARING_MESSAGE,
        payload
    };
};

/**
 * @memberOf common.service.business.RetirementSavingsService
 * @description sets bns values in reducer
 */
export const setNextSteps = (nextStepsData) => {
    return (dispatch, getState) => {
        const nextStepsSettings = nextStepsData;
        const { activeDeferral, participant, primaryPlan } = getState();

        const increaseContributionMessage =
            selectTranslations("retirementIncome")?.increaseContribution || "";

        const {
            appliesToTerminated,
            enrollToMaximizer,
            recommendedDollar,
            recommendedPct,
            recommended,
            message,
            url
        } = nextStepsSettings;

        const deferrals = participant.deferrals;
        const deferralsLogic = new Deferrals(deferrals, primaryPlan, null, activeDeferral);

        let nextStepUrl = "";
        let recommendedValue = 0;
        let type = "";

        const hasNextSteps = !isUndefinedOrNull(nextStepsSettings);
        const displayUrlOnly = isUndefined(enrollToMaximizer) ? false : enrollToMaximizer;
        //round these to 5 decimals to take off any floating point issues
        const numberOfDigits = 5;
        const dollar = getRoundedValue(recommendedDollar, numberOfDigits);
        const pct = getRoundedValue(recommendedPct, numberOfDigits);
        const nextStepMessage = hasValue(message)
            ? selectTranslations("bestNextStep")?.messages || message
            : "";
        const validMsg = !isEmptyString(nextStepMessage) && nextStepMessage !== message;

        // update the BNS values that don't exist in the server response
        if (hasNextSteps && activeDeferral && !isUndefinedOrNull(activeDeferral.valueUnits)) {
            type = activeDeferral.valueUnits;
            recommendedValue =
                activeDeferral.valueUnits === DeferralMoneyType.DOLLAR ? dollar : pct;
        }

        //getRoundedBnsValue will return null if bns goes over slider limits.  do not show bns in that case.
        // bns.roundedRecommendedValue = api.getRoundedBnsValue();
        const roundedRecommendedValue = getRoundedNextStepValue(
            deferrals,
            activeDeferral,
            primaryPlan,
            nextStepsSettings,
            recommendedValue
        );
        // if there are recommended messages, prepare to parse them
        if (recommended) {
            if (displayUrlOnly) {
                nextStepUrl = url;
            }
        }
        // determine if BNS can be shown
        const showNextSteps =
            hasNextSteps && recommended && (!primaryPlan.terminated || appliesToTerminated);

        const defaultNextStepMsg = getDefaultNextStepMsg(
            nextStepsSettings,
            increaseContributionMessage,
            roundedRecommendedValue
        );

        // determine the messaage to display for BNS
        const insert =
            deferralsLogic.getDeferralPrefix() +
            roundedRecommendedValue +
            deferralsLogic.getDeferralSuffix();

        const formattedMessage = validMsg
            ? StringUtil.supplant(nextStepMessage[nextStepsSettings.message], [insert])
            : defaultNextStepMsg;
        const settings = {
            ...nextStepsSettings,
            roundedRecommendedValue,
            message: nextStepMessage,
            recommendedValue,
            type
        };
        const payload = {
            nextStepUrl,
            formattedMessage,
            defaultNextStepMsg,
            showNextSteps,
            settings
        };
        dispatch({ type: SET_NEXT_STEPS, payload });
    };
};
