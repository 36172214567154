/**
 * Finds the active plan from the plans array. The active plan has a primary flag set
 * to true.
 *
 * If there are no plans set as primary then we default to the first item in the list.
 * If there are no items in the list then return null.
 */
export const findPrimaryPlan = (plans) => {
    if (Array.isArray(plans)) {
        const plan = plans.find(({ isPrimary }) => isPrimary === true);
        if (plan) {
            return plan;
        } else if (plans.length > 0) {
            return plans[0];
        }
    }
    return null;
};
