"use strict";
/**
 * @name app.infrastructure.domainModel.SsInfoPerson
 * @date 1/11/2018
 * @copyright 2018 Empower Retirement
 * @description
 * Definition of the ssInfo domain model object - only for migrated users.
 */

class SsInfoPerson {
    constructor(config) {
        this.personId = null;
        this.investorId = null;
        this.isMonthlyAmount = null;
        this.monthlyAmount = null;
        this.maxAmount = null;
        this.monthlyAmountOverride = null;
        this.collectionAgeOverride = null;
        this.dataSource = null;
        this.overrideId = null;
        this.sampleSocialSecurityTable = null;

        if (config) {
            this.personId = config.personId;
            this.investorId = config.investorId;
            this.isMonthlyAmount = config.isMonthlyAmount;
            this.monthlyAmount = config.monthlyAmount;
            this.maxAmount = config.maxAmount;
            this.monthlyAmountOverride = config.monthlyAmountOverride;
            this.collectionAgeOverride = config.collectionAgeOverride;
            this.dataSource = config.dataSource;
            this.overrideId = config.overrideId;
            this.sampleSocialSecurityTable = config.sampleSocialSecurityTable;
        }
    }
}

export default SsInfoPerson;
