import { UPDATE_ACTIVE_DEFERRAL } from "../../actions/deferrals/deferralActionTypes";
import { SET_INIT_DATA } from "../../actions/initData";
import { findActiveDeferral } from "../../utils/deferralUtils";
import { findPrimaryPlan } from "../../utils/planUtils";

const initialState = {
    active: false,
    autoIncrease: null,
    config: {
        displayName: "",
        descr: "",
        granularityPct: 0,
        granularityAmt: 0,
        minPct: 0,
        minAmt: 0,
        maxDeferral: 0,
        defrlAvailCode: "",
        taxStatus: "",
        incomeStream: "",
        ageCatchupApplicable: "",
        allowAgeCombinedInd: "",
        minRequiredInd: false,
        allowScheduleInd: "",
        maximizeEligibleInd: "",
        maxSlider: 0,
        enrollmentGroupCode: "STANDARD"
    },
    csor: false,
    defaulted: "",
    //deferralLimits: new DeferralLimits(), //this will derived in the DMF and is not part of the actual domain object
    deferralLimits: {
        maxDeferral: 75,
        minDeferral: 0,
        maxSlider: 1, //this isn't in the schema but based on 12/7 discussion it will exist
        granularity: null
    },
    deferralTypeCode: "",
    effectiveDate: new Date(),
    effectiveDateString: new Date().toDateString(), //this is for display purposes and not part of the actual domain object
    electedMaximizerSelection: "",
    electedMaximizerType: "",
    futureDated: null,
    grandfathered: false,
    isPrimaryAndActive: false, // is deferral from primary plan
    nextPayrollDate: new Date(),
    nycGoalFlag: null,
    payrollOption: "",
    pctAmtCode: 0,
    plan: null, //watch out for circular reference
    planId: null,
    status: "",
    submissionDate: new Date(),
    value: 0,
    valueUnits: "" //TODO: TJM we could possibly do away with this, BUT, it's everywhere so maybe this is better?
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_INIT_DATA: {
            const primaryPlan = findPrimaryPlan(payload.user.plans);
            const activeDeferral = findActiveDeferral(primaryPlan.deferrals);
            return { ...state, ...activeDeferral };
        }
        case UPDATE_ACTIVE_DEFERRAL:
            return { ...state, ...payload };
        default:
            return state;
    }
};
