import { useEffect } from "react";

import { useQuery } from "@tanstack/react-query";
import { Location } from "history";

import { setGetInitDataCalled } from "../../../actions/shared/sharedActionCreators";
import { useDispatch, useSelector } from "../../../hooks/use-redux-actions";
import useDisplayPcapModal from "../../../hooks/useDisplayPcapModal";
import { initialize, noLiatInitialize } from "../../../middleware/initializationMiddleware";
import { QUERY_KEYS } from "../../../queries/constants";
import getShowLiat from "../../../services/getShowLiat";

const useInitializeSubPage = ({ hasTranslations }: { hasTranslations: boolean }) => {
    const dispatch = useDispatch();

    const { initCalled } = useSelector((state) => state.shared);

    // Because we are setting the Routes location, we need to grab the true window location while on a subpage for displaying the modal.
    const [_, locationHash] = window.location.hash.split("#");
    const location: Location = {
        hash: "",
        pathname: locationHash,
        search: window.location.search,
        state: undefined,
        key: ""
    };
    const displayModal = useDisplayPcapModal(location);

    const { data: showLIAT } = useQuery({
        queryKey: [QUERY_KEYS.SHOW_LIAT],
        queryFn: getShowLiat
    });

    useEffect(() => {
        if (!hasTranslations) {
            dispatch(setGetInitDataCalled());

            dispatch(initialize());
        }

        // on unmount, refetch data to sync new information
        return () => {
            if (!displayModal) {
                if (showLIAT) {
                    if (!initCalled) {
                        dispatch(initialize());
                    }
                } else if (showLIAT === false) {
                    if (!initCalled) {
                        dispatch(noLiatInitialize());
                    }
                }
            }
        };
    }, [dispatch, hasTranslations, displayModal, initCalled, showLIAT]);
};

export default useInitializeSubPage;
