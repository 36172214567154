import React, { useState } from "react";

import { navigateFESSO, navigateFE } from "core-ui/client/react/core/services/adviceService";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { cloneDeep as _cloneDeep, get as _get, isEmpty as _isEmpty } from "lodash";
import { useLocation } from "react-router-dom";

import { setCurrentFault } from "../../actions/shared/sharedActionCreators";
import ReferenceData from "../../constants/ReferenceData";
import EventBusEvents from "../../events/eventBusEvents";
import { useSelector, useDispatch } from "../../hooks/use-redux-actions";
import { useIsEmulator } from "../../hooks/useEmulatorType";
import {
    useMaDetails,
    usePrimaryPlan,
    useAdvisoryMessaging,
    useFinancialEngineForecast
} from "../../queries";
import { getPAENavigationFault } from "../../utils/paeUtils";
import FEForecastModal from "../investmentTabs/feForecastModal/FEForecastModal";
import LeavingSiteToGoToMaModal from "../leavingSiteModal/LeavingSiteToGoToMaModal";
import { MaMessagingShell } from "../loadShells/LoadShells";

import AdvisoryButton from "./AdvisoryButton";
import AdvisoryFooter from "./AdvisoryFooter";
import useAdvisoryAttributes from "./hooks/useAdvisoryAttributes";

const SSO_FINANCIAL_ENGINES = "SSO_FINANCIAL_ENGINES";

const addGaIdAndIndIdToUrl = (url, planId, individualId) => {
    return url.replace("{gaId}", planId).replace("{indId}", individualId);
};

interface AdvisoryProps {
    additionalClasses: string;
    getDynamicMessaging: boolean;
    showLoader: boolean;
}

const AdvisoryServicesMessaging = ({
    additionalClasses = "",
    showLoader = false,
    getDynamicMessaging
}: AdvisoryProps) => {
    const dispatch = useDispatch();
    const translations = selectTranslations("advisoryServicesMessaging");
    const currentRoute = useLocation().pathname;

    const primaryPlan = useSelector((state) => state.primaryPlan);
    const participant = useSelector((state) => state.participant);
    const individualId = participant.individualId;
    const planId = primaryPlan.id;
    const txnAccess = useSelector((state) => state.shared.txnAccess);
    const { customMtrMessageEnabled, displayFeForecast } = txnAccess;
    const hasAdvisoryAccess = txnAccess.hasAdvisoryAccess;

    const [feForecastModalIsVisible, setFeForecastModalIsVisible] = useState(false);
    const [leavingSiteToGoToFeModalIsVisible, setLeavingSiteToGoToFeModalIsVisible] =
        useState(false);

    const { data: userPrimaryPlan } = usePrimaryPlan();
    const { data: maDetails } = useMaDetails(userPrimaryPlan);

    const programName = maDetails?.maProgramName;
    const provider = maDetails?.maProduct || "";
    const enrolledInFullManagedAccounts = maDetails?.enrolledInManagedAccounts || false;

    const { data: advisoryMessaging, isSuccess } = useAdvisoryMessaging(
        individualId,
        planId,
        getDynamicMessaging,
        translations,
        !!maDetails
    );
    const { data: feForecast } = useFinancialEngineForecast(
        enrolledInFullManagedAccounts,
        displayFeForecast
    );

    const professionalManagementProgramName =
        !!maDetails && !!programName
            ? String(programName).indexOf("<C>") > -1
                ? programName.replace("<C>", "\u00A9")
                : String(programName).indexOf("<TM>") > -1
                  ? programName.replace("<TM>", "\u2122")
                  : programName
            : "managed accounts";

    const isPAEUser = useIsEmulator();
    const componentClass = !showLoader ? "loaded" : "";

    const readyToShowAdvisoryMessaging = getDynamicMessaging ? isSuccess : true;

    const advisoryAttributes = useAdvisoryAttributes(
        advisoryMessaging,
        displayFeForecast,
        enrolledInFullManagedAccounts,
        provider,
        currentRoute,
        translations,
        customMtrMessageEnabled,
        readyToShowAdvisoryMessaging
    );

    /**
     * Navigate to the Financial Engines using it's SSO process.
     *
     * @returns {promise}
     */
    const navigateToFinancialEngines = (payload) => {
        // do we still need to init the advice service?
        setLeavingSiteToGoToFeModalIsVisible(false);

        const groupAccount = {
            individualId: participant.individualId,
            groupId: primaryPlan.id
        };

        return payload
            ? navigateFESSO(groupAccount, payload.pageCode, payload.treatment, payload.viewCode)
            : navigateFE(groupAccount);
    };

    const navigateTo = (url) => {
        window.location.href = url;
    };

    const dispatchButtonClick = (buttonUrl) => {
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: EventBusEvents.ADVISORY_SERVICES.BUTTON_CLICKED,
            payload: { buttonUrl }
        });
    };

    const handleButtonClick = (isSso, attributes) => {
        if (isPAEUser) {
            dispatch(setCurrentFault(getPAENavigationFault()));
            return;
        }

        dispatchButtonClick(attributes.buttonUrl);
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_DASHBOARD_TILE,
            selection: "ma_hp_dashboard_tile"
        });

        if (isSso) {
            navigateToFinancialEngines(attributes.sso);
        } else {
            navigateTo(
                addGaIdAndIndIdToUrl(attributes.buttonUrl, primaryPlan.id, participant.individualId)
            );
        }
    };

    const buttonInAttributes = "buttonUrl" in advisoryAttributes;
    const hasButton = buttonInAttributes
        ? !!advisoryAttributes.buttonUrl && !!advisoryAttributes.buttonLabel
        : false;
    const isSingleSignOn = buttonInAttributes
        ? advisoryAttributes.buttonUrl === SSO_FINANCIAL_ENGINES
        : false;

    return (
        !("missingData" in advisoryAttributes) &&
        readyToShowAdvisoryMessaging &&
        hasAdvisoryAccess && (
            <React.Fragment>
                <div
                    className={`advisory-services-messaging ${additionalClasses} ${componentClass}`}
                    data-testid="advisory-services-messaging"
                >
                    {showLoader && <MaMessagingShell />}
                    <div className={`use-fade-in ${componentClass}`}>
                        <div className="col1-tablet">
                            {!!advisoryAttributes.image && (
                                <div className={"image " + advisoryAttributes.image} />
                            )}
                            {!!advisoryAttributes.title && (
                                <div
                                    className="title"
                                    role="heading"
                                    aria-level={2}
                                    data-testid="attribute-title"
                                >
                                    {advisoryAttributes.title.replace(
                                        ReferenceData.PROGRAM_NAME_KEY,
                                        professionalManagementProgramName
                                    )}
                                </div>
                            )}
                            {(!!advisoryAttributes.bodyHighlight || !!advisoryAttributes.body) && (
                                <div className="body">
                                    <p className="body-highlight">
                                        {advisoryAttributes.bodyHighlight}
                                    </p>
                                    <section data-testid="attribute-body">
                                        <p>{advisoryAttributes.body}</p>
                                    </section>
                                </div>
                            )}
                        </div>
                        <div className="col2-tablet">
                            {hasButton && (
                                <AdvisoryButton
                                    buttonClass={`btn btn-pcap-primary ${!isSingleSignOn && "btn-save"}`}
                                    handleClick={() =>
                                        handleButtonClick(isSingleSignOn, advisoryAttributes)
                                    }
                                    testId={"attribute-button-label"}
                                    label={advisoryAttributes.buttonLabel}
                                    programName={professionalManagementProgramName}
                                />
                            )}

                            {!!advisoryAttributes.footer &&
                                advisoryAttributes.footer.length > 0 &&
                                provider !== ReferenceData.PROVIDER_MTR &&
                                provider !== ReferenceData.PROVIDER_AMA && (
                                    // Don't display this footer for AMA, MTR
                                    <AdvisoryFooter
                                        advisoryAttributes={advisoryAttributes}
                                        enrolled={enrolledInFullManagedAccounts}
                                        provider={provider}
                                        displayFeForecast={displayFeForecast}
                                        currentRoute={currentRoute}
                                        addGaIdAndIndIdToUrl={addGaIdAndIndIdToUrl}
                                        isPAEUser={isPAEUser}
                                        navigate={navigateToFinancialEngines}
                                        setFeForecastModalIsVisible={setFeForecastModalIsVisible}
                                        planId={planId}
                                        indId={individualId}
                                    />
                                )}
                        </div>
                    </div>
                </div>
                {feForecastModalIsVisible && (
                    <FEForecastModal
                        visible={feForecastModalIsVisible}
                        data={feForecast}
                        handleCloseFE={() => {
                            setFeForecastModalIsVisible(false);
                        }}
                        handleBackdropClick={() => {
                            setFeForecastModalIsVisible(false);
                        }}
                    />
                )}
                {/* <!-- SSO FE (Financial Engines) Hidden Form --> */}
                {/* <!-- Allows users to navigate to FE by dropping SAML token details into the form and submitting --> */}
                <div className="hidden">
                    <form id="SAMLFORM" method="post" target="_blank">
                        <textarea id="SAMLResponse" name="SAMLResponse" aria-label="SAMLResponse" />
                    </form>
                </div>

                {/* <!-- FE/Ibbotson/ Leaving site warning modal --> */}
                {leavingSiteToGoToFeModalIsVisible && (
                    <LeavingSiteToGoToMaModal
                        onClick={() => navigateToFinancialEngines(null)}
                        onCancel={() => setLeavingSiteToGoToFeModalIsVisible(false)}
                    />
                )}
            </React.Fragment>
        )
    );
};

export default AdvisoryServicesMessaging;
