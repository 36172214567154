/**
 *
 * @name RetirementIncomeJsonUrls
 * @memberOf common.model.constants
 * @date 10/28/2016
 * @copyright 2016 Empower Retirement
 * @description Encapsulates constants for our code.
 * @property {string} URL_FE_FORECAST_ASSUMPTIONS_LOCAL
 * @property {string} URL_FE_FORECAST_ASSUMPTIONS_LIVE
 * @property {string} URL_FAQ_LOCAL
 * @property {string} URL_FAQ_LIVE:
 *
 */

"use strict";

export const RetirementIncomeJsonUrls = {
    URL_FE_FORECAST_ASSUMPTIONS_LOCAL:
        "/customizations/Default/retirement-income/financial-engine-info-assumptions.json",
    URL_FE_FORECAST_ASSUMPTIONS_LIVE:
        "/ui/customization-ui/customizations/Default/retirement-income/financial-engine-info-assumptions.json",
    URL_FAQ_LOCAL: "customizations/Default/retirement-income/retirement-income-faq-page.json",
    URL_FAQ_LIVE:
        "/ui/customization-ui/customizations/Default/retirement-income/retirement-income-faq-page.json",
    URL_DEFAULT_FAQ_LOCAL_PREFIX: "customizations/{planAccu}/Accounts{planAccu}Customization.json",
    URL_DEFAULT_FAQ_LIVE_PREFIX:
        "/ui/customization-ui/customizations/{planAccu}/Accounts{planAccu}Customization.json",
    URL_CUSTOM_FAQ_LOCAL_PREFIX: "customizations/{planAccu}/{planAccu}AccountsCustomization.json",
    URL_CUSTOM_FAQ_LIVE_PREFIX:
        "/ui/customization-ui/customizations/{planAccu}/{planAccu}AccountsCustomization.json",
    EMPOWER_FAVICON_PATH: "customizations/Default/images/magic-login/favicon.png"
};
