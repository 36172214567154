import React from "react";

import StringUtil from "core-ui/client/src/app/StringUtil";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

import WarningMessage from "../warningMessage/warningMessage";

/**
 * Renders the header with dynamically updated goal text
 * @param {number} totalGoal - Represents user plus spouse goal, which defaults to 0
 * @param {string} period - Updates based on period chosen (monthly, yearly) or whether a spouse is saved
 * @param {string} totalSalaryText - Has a value of 'Household' if user has a spouse added
 * @param {string} portion - Total portion amount
 */
const Header = ({
    totalGoal,
    period,
    totalSalaryText,
    portion,
    mustEnterSalary,
    modalTranslations,
    isSpouse,
    isApple,
    defaultIncomeGoal,
    salaryReminder,
    promptForSalary,
    isJPM
}) => {
    const modalTitle = StringUtil.supplant(modalTranslations.title, { period });
    const incomeGoalText = modalTranslations.incomeGoal
        ? StringUtil.supplant(modalTranslations.incomeGoal, { period })
        : "";
    const maxHeaderText = `${totalSalaryText || ""} ${incomeGoalText}: `;
    const defaultBeforeTax = modalTranslations.defaultBeforeTax || "";

    // isApple logic pulled from Retirement service, looking at user plan id for Apple and not accu value in address bar
    const isApplePlan = isApple();
    const isJPMPlan = isJPM ? isJPM() : null;

    const needsDecimals = totalGoal % 1 !== 0;

    const renderSubtext = () => {
        let goalSet;
        let standard = "";
        let endLine = false;
        const goal =
            Number(portion) === defaultIncomeGoal ? (
                <span>{` ${portion}%`}</span>
            ) : (
                <strong className={"goal-text"}>{` ${portion}%`}</strong>
            );

        if (defaultIncomeGoal === portion) {
            if (defaultIncomeGoal !== 75) {
                goalSet = modalTranslations.goalSetCompany;
            } else {
                goalSet = modalTranslations.goalSetIndustry;
            }
            endLine = true;
        } else if (defaultIncomeGoal !== portion) {
            if (defaultIncomeGoal !== 75) {
                standard = modalTranslations.companyStandard
                    ? `${StringUtil.supplant(modalTranslations.companyStandard, {
                          num: defaultIncomeGoal
                      })}. `
                    : "";
            } else {
                standard = `${modalTranslations.industryStandard}. `;
            }
            goalSet = modalTranslations.goalSetCustom;
        }

        return (
            <p>
                {!isJPMPlan ? (
                    ""
                ) : (
                    <span>
                        {goalSet}
                        {goal} {modalTranslations.beforeTax}
                        {endLine ? ". " : " "}
                        {standard}
                    </span>
                )}
                {isApplePlan
                    ? !isSpouse
                        ? `${defaultBeforeTax} ${String(modalTranslations.modify).trimLeft()}`
                        : null
                    : modalTranslations.modify}
            </p>
        );
    };

    return (
        <div className="modal-header clearfix" data-testid="header">
            <div className="sr-only" id="goal-modal-title">
                {modalTitle}
            </div>
            <div className="modal-title">
                {mustEnterSalary ? modalTranslations.zeroSalHeader : maxHeaderText}
                {mustEnterSalary ? null : (
                    <div>
                        <NumericFormat
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale={needsDecimals}
                            allowNegative={false}
                            displayType={"text"}
                            prefix={"$"}
                            value={`${totalGoal}`}
                            className="goal-text"
                        />
                    </div>
                )}
            </div>
            {mustEnterSalary ? null : renderSubtext()}
            {promptForSalary && salaryReminder ? (
                <WarningMessage
                    message={modalTranslations.salaryReminderMessage}
                    classNames={["salary-reminder"]}
                />
            ) : null}
        </div>
    );
};

Header.propTypes = {
    totalGoal: PropTypes.number.isRequired,
    period: PropTypes.string.isRequired,
    totalSalaryText: PropTypes.string,
    portion: PropTypes.number,
    mustEnterSalary: PropTypes.bool,
    isSpouse: PropTypes.bool,
    isApple: PropTypes.func,
    defaultIncomeGoal: PropTypes.number,
    promptForSalary: PropTypes.bool,
    integrated: PropTypes.bool,
    isJPM: PropTypes.any,
    modalTranslations: PropTypes.object,
    salaryReminder: PropTypes.bool
};

export default Header;
