import ReferenceData from "../../constants/ReferenceData";
import { fixedTwoDecimalPlaces } from "../currencyUtils";
import { isUndefinedOrNull } from "../hubUtils";

/**
 * @description Returns the minimum retirement age for the participant, based on the participant's age.
 */
export const getMinRetirementAge = function (age: number): number {
    return age < ReferenceData.MIN_RETIREMENT_AGE ? ReferenceData.MIN_RETIREMENT_AGE : age + 1;
};

/**
 * @description Returns the maximum retirement age for the participant.
 */
export const getMaxRetirementAge = function (age: number): number {
    const minRetirement = getMinRetirementAge(age);
    return minRetirement + 5 <= ReferenceData.MAX_RETIREMENT_AGE
        ? ReferenceData.MAX_RETIREMENT_AGE
        : minRetirement + 5;
};

/**
 * @description Set the floor for social security age for ReferenceData.MIN_SOCIALSECURITY_STARTAGE
 */
export const getFlooredSocialSecurityAge = (age: number): number => {
    return age > ReferenceData.MIN_SOCIALSECURITY_STARTAGE
        ? age
        : ReferenceData.MIN_SOCIALSECURITY_STARTAGE;
};

/**
 * @description Set the ceiling for social security age for ReferenceData.MAX_SOCIALSECURITY_LATEST_STARTAGE
 */
export const getCeilingSocialSecurityAge = (age: number): number => {
    return age < ReferenceData.MAX_SOCIALSECURITY_LATEST_STARTAGE
        ? age
        : ReferenceData.MAX_SOCIALSECURITY_LATEST_STARTAGE;
};

/**
 * @description Returns the social security receive age, going no lower than the minimum (62) and no higher than the max (70)
 */
export const validateSocialSecurityAge = (age: number): number => {
    let personAge = getFlooredSocialSecurityAge(age);
    personAge = getCeilingSocialSecurityAge(personAge);
    return personAge;
};

type BenefitListItem = {
    [key: number]: number;
};

/**
 * @description Get the monthly benefits amount from the ss map. Services returns values on an annual basis, divide by 12 to get monthly numbers
 */
export const getMonthlyAmountFromBenefitsList = (
    benefitsLists: BenefitListItem,
    retirementAge: number
): string => {
    if (isUndefinedOrNull(retirementAge) || isUndefinedOrNull(benefitsLists)) {
        return "";
    }

    const monthly = Math.round(benefitsLists[retirementAge]) / 12;
    return fixedTwoDecimalPlaces(monthly);
};
