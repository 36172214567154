"use strict";

/**
 * @name app.common.model.domain.IncomeGoal
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @type Class
 * @description
 *
 *        Creates a defined object structure for the IncomeGoal object type.
 * The object constructor.
 * @param {object} config Configuration object to populate the model properties. Note that only primitives are used.
 * @constructor
 */
class IncomeGoal {
    constructor(config) {
        this.term = 12; // setting a default of 12 so that we never end up with neither monthly or yearly
        this.value = 75;
        this.valueUnits = "PCT";
        this.default = 75;

        if (config) {
            this.term = config.term;
            this.value = config.value;
            this.valueUnits = config.valueUnits;
            this.default = config.default;
        }
    }
}

export default IncomeGoal;
