import type { AxiosResponse } from "axios";

import RequestFactory from "../factories/RequestFactory";
import { pcapServiceCall } from "../utils/apiUtils";
import { getUrl } from "../utils/urlUtils.js";

interface QuerySessionResponse {
    spHeader: {
        SP_HEADER_VERSION: number;
    };
}

const querySession = async () => {
    const url = getUrl("querySession");
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED;
    const method = RequestFactory.HTTP_METHOD.POST;

    return pcapServiceCall({
        method,
        url,
        contentType
    }) as Promise<AxiosResponse<QuerySessionResponse>>;
};

export default querySession;
