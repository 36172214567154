import React from "react";

import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ToolTip = ({ text, placement, children }) => {
    const tt = <Tooltip id="modal-tooltip">{text}</Tooltip>;

    return (
        <OverlayTrigger overlay={tt} placement={placement}>
            {children}
        </OverlayTrigger>
    );
};

ToolTip.propTypes = {
    text: PropTypes.string,
    placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
    children: PropTypes.node
};

export default ToolTip;
