import React, { useState } from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { useSelector, useDispatch } from "react-redux";

import { addNewDeferral } from "../../actions/deferrals/deferralActions";
import { dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import Deferrals from "../../business/deferralsLogic/deferrals";
import DeferralMoneyType from "../../constants/DeferralMoneyType";
import ReferenceData from "../../constants/ReferenceData";

import { ADEvents } from "./events/addDeferralEvents";

const AddDeferralModal = ({
    visible,
    closeModal,
    setShowExpansion,
    availableContributionTypes
}) => {
    const dispatch = useDispatch();
    const appTranslations = selectTranslations("app");
    const translations = selectTranslations("retirementIncome");

    const deferralSetupConfig = useSelector((state) => state.deferralSetupConfig);
    const activeDeferral = useSelector((state) => state.activeDeferral);
    const deferrals = useSelector((state) => state.participant.deferrals);
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const typeCodeMap = useSelector((state) => state.primaryPlan.deferralInfo.typeCodeMap);
    const deferralsLogic = new Deferrals(
        deferrals,
        primaryPlan,
        deferralSetupConfig,
        activeDeferral
    );

    const hasMixedDeferrals = deferralSetupConfig.hasMixedDeferrals;
    const activeDeferralValueUnits = activeDeferral.valueUnits;
    const needsVariableSalary = deferralsLogic.variableDeferralNeedsVariableSalary();
    const enrollmentGroupCodeHash = primaryPlan.deferralInfo.enrollmentGroupCodeHash;
    const getDeferralGroupCodeValueUnits = deferralsLogic.getDeferralGroupCodeValueUnits;
    const isVariableDeferral = deferralsLogic.isVariableDeferral;
    const checkMapForTypeCodeRestrictions = deferralsLogic.checkMapForTypeCodeRestrictions;
    const updateTypeCodesMap = deferralsLogic.updateTypeCodesMap;
    const getDeferralHasConflict = deferralsLogic.getDeferralHasConflict;

    const [showFormat, updateShowFormat] = useState(true);
    const [selectedDeferralOption, updateSelectedOption] = useState(null);
    const [pctAmtOption, updatePctAmtOption] = useState(null);
    const [inputVal, updateInputVal] = useState("0.00");
    const optionsMap = availableContributionTypes.reduce((types, type) => {
        types[type.deferralTypeCode] = type;
        return types;
    }, {});

    const getShowPctAmtOptions = (deferral) => {
        if (!deferral) {
            deferral = selectedDeferralOption;
        }

        if (deferral) {
            if (checkMapForTypeCodeRestrictions(deferral, typeCodeMap)) {
                return false;
            }
            if (
                deferral.pctAmtCode !== "AMT_PCT" ||
                enrollmentGroupCodeHash[deferral.enrollmentGroupCode]
            ) {
                return false;
            } else if (getDeferralGroupCodeValueUnits && getDeferralGroupCodeValueUnits(deferral)) {
                return false;
            }
            return true;
        }
        return false;
    };

    const setPctAmtOption = (deferral) => {
        if (!deferral) {
            deferral = selectedDeferralOption;
        }

        if (deferral) {
            if (deferral.pctAmtCode !== "AMT_PCT") {
                updatePctAmtOption(DeferralMoneyType[deferral.pctAmtCode]);
            } else if (enrollmentGroupCodeHash[deferral.enrollmentGroupCode]) {
                deferral.pctAmtCode =
                    enrollmentGroupCodeHash[deferral.enrollmentGroupCode] === "percent"
                        ? "PCT"
                        : "AMT";
                updatePctAmtOption(enrollmentGroupCodeHash[deferral.enrollmentGroupCode]);
            } else if (getDeferralGroupCodeValueUnits(deferral)) {
                updatePctAmtOption(getDeferralGroupCodeValueUnits(deferral));
            } else if (!hasMixedDeferrals) {
                updatePctAmtOption(activeDeferralValueUnits);
            } else {
                updatePctAmtOption("percent");
            }
        } else {
            updatePctAmtOption(activeDeferralValueUnits);
        }
    };

    const changeSelectedOption = (e) => {
        const target = e.target;
        const option = optionsMap[target[target.selectedIndex].dataset.option];
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: ADEvents.OPTION_SELECT,
            payload: { option }
        });
        updateSelectedOption(option);
        setPctAmtOption(option);
    };

    const renderDropdownOptions = (types) => {
        return (
            <div className="custom-select form-control">
                <select onChange={changeSelectedOption} className="form-control">
                    <option value="">Select a contribution type:</option>
                    {types.map((option) => {
                        return (
                            <option
                                data-option={option.deferralTypeCode}
                                value={option.displayName}
                                key={option.displayName}
                            >
                                {option.displayName}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    };

    const pctAmtOptionClick = (e) => {
        const target = e.target;
        const code = target.value;

        dispatch(dispatchEventBus(ADEvents.PCT_AMT_SELECT, this, { PCT_AMT: code }));

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: ADEvents.PCT_AMT_SELECT,
            payload: { PCT_AMT: code }
        });

        updatePctAmtOption(code);
    };

    const limitSalary = (valObj) => {
        const val = valObj.floatValue;
        const min = 0;
        const max = ReferenceData.MAX_SALARY_ENTRY;
        return val <= max && val >= min;
    };

    const handleFocus = () => {
        updateShowFormat(false);
    };

    const handleBlur = () => {
        updateShowFormat(true);
    };

    const changeInput = (values) => {
        updateInputVal(values.floatValue);
    };

    const getShowVariableSalary = () => {
        const deferralSelected = selectedDeferralOption;
        const isVariable = deferralSelected && isVariableDeferral(selectedDeferralOption);

        return needsVariableSalary && isVariable;
    };

    const shouldDisableAdd = function () {
        const notEnoughVariable = getShowVariableSalary() && !Number(inputVal);
        const hasDeferral = Boolean(selectedDeferralOption);
        const hasPctAmt = Boolean(pctAmtOption);
        const hasConflict = getDeferralHasConflict(selectedDeferralOption, typeCodeMap);

        return !(hasDeferral && hasPctAmt) || notEnoughVariable || hasConflict;
    };

    const continueClick = () => {
        const variable = Number(inputVal);
        dispatch(addNewDeferral(selectedDeferralOption, pctAmtOption, variable, deferrals));
        updateTypeCodesMap(selectedDeferralOption, pctAmtOption, typeCodeMap);
        closeModal(false);
        setShowExpansion(true);
        resetScope();

        const payload = {
            option: selectedDeferralOption,
            variableSalary: inputVal,
            pctAmtOption: pctAmtOption
        };

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: ADEvents.ADD_CLICK,
            payload
        });

        dispatch(dispatchEventBus(ADEvents.ADD_CLICK, this, payload));
    };

    const resetScope = () => {
        setPctAmtOption();
        updateSelectedOption(null);
    };

    const onCancel = () => {
        closeModal(false);
        resetScope();
    };
    return (
        visible && (
            <Modal dialogClassName="add-deferral-container rivd-add-deferral" show={visible}>
                <div className="modal-dialog">
                    <div className="add-deferral-container modal-content">
                        <Modal.Header className="modal-header">
                            <span className="modal-title">
                                {translations.addContribution.addNew}
                            </span>
                        </Modal.Header>
                        <Modal.Body className="modal-body">
                            {visible &&
                                renderDropdownOptions(
                                    deferralsLogic.getNewAvailableDeferrals(
                                        availableContributionTypes
                                    )
                                )}
                            <div>
                                {getShowPctAmtOptions() && (
                                    <label className="add-deferral-unit-radio">
                                        <input
                                            type="radio"
                                            className="deferralUnitOption"
                                            name="deferral_unit_option"
                                            id="pctAmtOption1"
                                            value="percent"
                                            checked={pctAmtOption === "percent"}
                                            onChange={pctAmtOptionClick}
                                        />
                                        <span>{translations.addContribution.percent}</span>
                                    </label>
                                )}

                                {getShowPctAmtOptions() && (
                                    <label className="add-deferral-unit-radio">
                                        <input
                                            type="radio"
                                            className="deferralUnitOption"
                                            name="deferral_unit_option"
                                            id="pctAmtOption2"
                                            value="dollars"
                                            checked={pctAmtOption === "dollars"}
                                            onChange={pctAmtOptionClick}
                                        />
                                        <span>{translations.addContribution.dollar}</span>
                                    </label>
                                )}

                                {getShowVariableSalary() && (
                                    <div>
                                        <p>{translations.addContribution.variableSalExpl}</p>
                                        <label>{translations.addContribution.variableSal}</label>
                                        <NumericFormat
                                            className="form-control"
                                            id="temp-variable-salary"
                                            decimalScale={2}
                                            valueIsNumericString={true}
                                            fixedDecimalScale={showFormat}
                                            prefix={showFormat ? "$" : ""}
                                            isAllowed={limitSalary}
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            onValueChange={changeInput}
                                            value={inputVal}
                                        />
                                    </div>
                                )}
                            </div>
                        </Modal.Body>
                        <Modal.Footer className="modal-footer">
                            <div className="footer-content">
                                <div className="row">
                                    <div className="col-sm-2 col-sm-offset-7 col-xs-6">
                                        <button
                                            onClick={onCancel}
                                            type="button"
                                            className="btn btn-link btn-block"
                                        >
                                            {appTranslations.common.labels.cancel}
                                        </button>
                                    </div>
                                    <div className="col-sm-3 col-xs-6">
                                        <button
                                            type="button"
                                            onClick={continueClick}
                                            className="btn btn-primary btn-save btn-block"
                                            disabled={shouldDisableAdd()}
                                        >
                                            {appTranslations.common.labels.add}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Footer>
                    </div>
                </div>
            </Modal>
        )
    );
};
AddDeferralModal.propTypes = {
    visible: PropTypes.bool,
    closeModal: PropTypes.func,
    setShowExpansion: PropTypes.func,
    availableContributionTypes: PropTypes.array
};
export default AddDeferralModal;
