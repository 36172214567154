import { isUndefinedOrNull, isString } from "./hubUtils";

/**
 * This method formats a number to a US dollar amount with decimal places
 * @param {Number} dollarAmount
 * @returns {String}
 */
export const formatUsDollarAmount = (dollarAmount) => {
    if (isUndefinedOrNull(dollarAmount)) return null;

    return dollarAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
    });
};

/**
 * This method formats a number to a US dollar amount with NO decimal places
 * @param {Number} dollarAmount
 * @returns {String}
 */
export const formatUsDollarAmountNoDecimals = (dollarAmount) => {
    if (isUndefinedOrNull(dollarAmount)) return null;

    return dollarAmount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0
    });
};

const FORMAT_CURRENCY_OPTIONS = {
    style: "currency",
    currency: "USD"
};

// @TODO: Add usage doc
export const formatCurrency = (val, options = FORMAT_CURRENCY_OPTIONS) => {
    if (isUndefinedOrNull(val)) return null;

    const formatter = new Intl.NumberFormat("en-US", options);

    return formatter.format(val);
};

export const isEmpty = (obj) => {
    if (obj === null || obj === undefined) {
        return true;
    } else if (typeof obj === "string" && obj.replace(/ /g, "").length === 0) {
        return true;
    } else {
        return false;
    }
};

export const formatMoneyWithDecimalWithDollarPrefix = (value) => {
    if (!isEmpty(value)) {
        const n = 2;
        const x = 3;
        const isNegative = value < 0;
        value = Math.abs(value);
        const re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
        const prefix = isNegative ? "-$" : "$";
        return (
            prefix + value.toFixed(Math.max(0, Math.floor(n))).replace(new RegExp(re, "g"), "$&,")
        );
    } else {
        return "$0";
    }
};

export const fixedTwoDecimalPlaces = (num) => {
    if (isUndefinedOrNull(num)) {
        return 0;
    }

    if (isString(num)) {
        return Number(num).toFixed(2);
    }
    return num.toFixed(2);
};
