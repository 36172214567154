import { getUserMessages } from "../services/userMessagesService";

export const getReferral = async (csrf) => {
    try {
        let referral = "";
        const data = await getUserMessages(csrf);
        if (data && data.userMessages && data.userMessages.length > 0) {
            const referralMessage = data.userMessages.find((message) => {
                return message.template === "REFERRAL_PAGE";
            });
            if (referralMessage) {
                referral = referralMessage;
            }
        }
        return referral;
    } catch (err) {
        console.log("ERROR: getUserMessages", err);
    }
};
