import _pick from "lodash/pick";

import {
    SET_APPLICATION_SETTINGS_PROJECTION_SETTINGS,
    UPDATE_PROJECTION_SETTINGS
} from "../../actions/applicationSettings/applicationSettingsTypes";
import { SET_INIT_DATA } from "../../actions/initData";

const initialState = {
    dataIsLoaded: false,
    governmentBenefits: null, //UI value
    migratedContext: "",
    projectedIncome: null, //UI value       TODO is this a duplicate of projectedIncome reducer?
    projectionSettings: {
        includeSocialSecurity: false,
        confidence: 90, // Default confidence is 90
        rateOfInflation: null,
        term: 12
    },
    tradeOffs: null, //UI value
    integrated: true // temporary value as this was getting set on INIT
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_INIT_DATA: {
            return {
                ...state,
                ..._pick(payload.applicationSettings, Object.keys(state)),
                dataIsLoaded: true
            };
        }

        case SET_APPLICATION_SETTINGS_PROJECTION_SETTINGS:
            return { ...state, projectionSettings: payload };
        case UPDATE_PROJECTION_SETTINGS:
            return { ...state, projectionSettings: { ...state.projectionSettings, ...payload } };

        default:
            return state;
    }
};
