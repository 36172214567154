"use strict";
/**
 * @name app.common.model.domain.RetirementIncomeAsset
 * @date 1/26/2015
 * @copyright 2015 Empower Retirement
 * @type Class
 * @description
 * Creates a defined object structure for the retirementIncomeAsset object type.
 */
class RetirementIncomeAsset {
    constructor(config) {
        this.name = null;
        this.investorId = null;
        this.investorAccountId = null;
        this.estimatedAnnualAmount = null;
        this.costOfLivingAdjustable = null;
        this.adjustmentAmount = null;
        this.taxable = null;
        this.pension = null;
        this.estimatedStartYear = null;
        this.estimatedEndYear = null;
        this.estimatedStartAge = null;
        this.estimatedEndAge = null;
        this.untilLifeExpectancy = null;
        this.lastModifiedOn = null;
        this.dataSource = null;
        this.assetType = "retirementIncome";
        this.monthlyIncomeEST = 0;

        if (config) {
            this.name = config.name;
            this.investorId = config.investorId;
            this.investorAccountId = config.investorAccountId;
            this.estimatedAnnualAmount = config.estimatedAnnualAmount;
            this.costOfLivingAdjustable = config.costOfLivingAdjustable;
            this.adjustmentAmount = config.adjustmentAmount;
            this.taxable = config.taxable;
            this.pension = config.pension;
            this.estimatedStartYear = config.estimatedStartYear;
            this.estimatedEndYear = config.estimatedEndYear;
            this.estimatedStartAge = config.estimatedStartAge;
            this.estimatedEndAge = config.estimatedEndAge;
            this.untilLifeExpectancy = config.untilLifeExpectancy;
            this.lastModifiedOn = config.lastModifiedOn;
            this.dataSource = config.dataSource;
            this.assetType =
                config.assetType && config.assetType > "" ? config.assetType : "retirementIncome";
            this.monthlyIncomeEST =
                config.monthlyIncomeEST && config.monthlyIncomeEST > 0
                    ? config.monthlyIncomeEST
                    : 0;
        }
    }
}

export default RetirementIncomeAsset;
