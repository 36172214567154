/**
 * @name app.common.model.domain.OtherAssetsRefData
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement

 */

"use strict";

class OARefData {
    constructor(config) {
        this.riskLevels = [];
        this.accountInvestmentTypes = [];
        this.contributionTypes = [];
        this.assetAllocationClassTypes = [];
        this.adviseOptionTypes = [];
        this.accountTypes = [];
        this.accountProviders = [];

        if (config) {
            this.riskLevels = config.riskLevels;
            this.accountInvestmentTypes = config.accountInvestmentTypes;
            this.contributionTypes = config.contributionTypes;
            this.assetAllocationClassTypes = config.assetAllocationClassTypes;
            this.adviseOptionTypes = config.adviseOptionTypes;
            this.accountTypes = config.accountTypes;
            this.accountProviders = config.accountProviders;
        }
    }
}

export default OARefData;
