const initialKTMGFlags = {
    CXAOE: false,
    CXARP: false,
    CXASP: false,
    CXMCDP: false,
    CXPFD: false,
    CXASN: false,
    WNBALT: false,
    WNBEN: false,
    WNCCHB: false,
    WNHVSF: false,
    WNINLN: false,
    WNINSS: false,
    WNINVN: false,
    WNINVV: false,
    WNIPB: false,
    WNLANG: false,
    WNLNSM: false,
    WNOAIN: false,
    WNPYIN: false,
    WNROLL: false,
    WNROR: false,
    WNSDB: false,
    WNSDBT: false,
    WNSPWB: false,
    WNSTMT: false,
    WNTRHN: false,
    WNUBA: false,
    WNUPLD: false,
    WNWCIR: false,
    WNWITH: false
};

export default initialKTMGFlags;
