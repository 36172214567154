import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { isUndefinedOrNull } from "gw-shared-components";
import { useSelector } from "react-redux";

const Maximizer = () => {
    const translations = selectTranslations("maximizer");
    const planList = useSelector((state) => state.participant.plans);
    const maxUneroll = useSelector((state) => state.primaryPlan.maximizer);

    const showIsMaximized = () => {
        let showMaxMessage = false;

        planList.forEach((plan) => {
            if (
                !isUndefinedOrNull(plan.planRules) &&
                !isUndefinedOrNull(plan.planRules.maximizer) &&
                !isUndefinedOrNull(plan.maximizer) &&
                plan.maximizer.enrollmentSelection === "MAXR_ONGOING" &&
                plan.planRules.maximizer === true &&
                maxUneroll.isUnenrolling === false
            ) {
                showMaxMessage = true;
            }
        });

        return showMaxMessage;
    };

    return (
        <div id="maximizer-tabpanel" role="tabpanel">
            {showIsMaximized() ? (
                <div className="plan-label max-label">
                    <p>
                        <i className="em-checkbox-icon" />
                        {translations.enrolled}
                    </p>{" "}
                </div>
            ) : null}
        </div>
    );
};

export default Maximizer;
