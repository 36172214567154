import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import { ObjectUtil } from "gw-shared-components";
import { filter as _filter, find as _find } from "lodash";

import { splitOtherAssetsByEmployer } from "../actions/otherAssets/otherAssetsActions";
import {
    UPDATE_MY_LIFE_GOALS,
    UPDATE_ACCOUNT_LITE_ASSETS
} from "../actions/otherAssets/otherAssetsTypes";
import {
    updateParticipantRetirementAssets,
    updateParticipantExternalAssets
} from "../actions/participantDetails/participantDetailsActions";
import { updateProjectedIncome } from "../actions/projectedIncome/projectedIncomeActions";
import { setCurrentFault } from "../actions/shared/sharedActionCreators";
import {
    updateSpouseRetirementAssets,
    updateSpouseExternalAssets
} from "../actions/spouse/spouseActions";
import Constants from "../constants/Constants";
import OtherAssetsConstants from "../constants/OtherAssetsConstants";
import InitDataFactory from "../factories/InitDataFactory";
import {
    getAccountsUpdate,
    getMyLifeGoalsData,
    getAccounts2
} from "../services/otherAssetsService";

const logger = ExternalLogger.getInstance("otherAssetsMiddleware");

export const filterOutAccountsLite = (data, participantPersonId, hasSpouse, spousePersonId) => {
    const addMatchedAsset = (item, personId) => {
        let skipItem = false;

        if (!item.isExternal) {
            // check for linked accounts, new Empower Premier IRA’s hosted on PCAP
            if (item.siteName !== "empower_retail_pcap") {
                skipItem = true;
            }
        }
        if (item.accountClosedDate !== null) {
            skipItem = true;
        }

        const matchList = _filter(OtherAssetsConstants.ACCOUNTS_LITE_TABLE, (row) => {
            if (
                row.productType === item.productType &&
                row.accountTypeGroup === item.accountTypeGroup &&
                row.accountType === item.accountType &&
                (row.accountSubType === item.accountTypeSubtype ||
                    (row.accountSubType === "" &&
                        ObjectUtil.isUndefinedOrNull(item.accountTypeSubtype)))
            ) {
                return item;
            }
        });

        if (matchList.length === 0) {
            skipItem = true;
        }

        if (!skipItem) {
            const formattedItem = InitDataFactory.createExternalAsset(item, personId);
            updatedList.push(formattedItem);
        }
    };

    let updatedList = [];
    if (data) {
        for (let i = 0; i < data.length; i++) {
            const item = data[i];

            let personId = -1;
            item.isPrimary = false;
            item.isJointOwner = false;

            if (item.owners && item.owners.length > 0) {
                if (item.owners.length > 1) {
                    item.isJointOwner = true;
                }

                const participantlist = _filter(item.owners, (record) => {
                    return record.pcapPersonId === participantPersonId;
                });
                if (participantlist.length > 0) {
                    personId = participantPersonId;
                    item.isPrimary = true;

                    addMatchedAsset(item, personId);
                }
                let spouselist = [];

                spouselist = _filter(item.owners, (record) => {
                    return record.pcapPersonId === spousePersonId;
                });

                if (spouselist.length > 0) {
                    item.isPrimary = false;
                    personId = spousePersonId;

                    addMatchedAsset(item, personId);
                }
            } else {
                // if no owners array, default owner to participant
                // participantFound = true;
                personId = participantPersonId;
                item.isPrimary = true;

                addMatchedAsset(item, personId);
            }
        }
    }

    return updatedList;
};

export const continuePCAPAssetUpdate = () => {
    return (dispatch, getState) => {
        const state = getState();
        const participant = state.participant;
        const spouse = state.spouse;
        const term = state.applicationSettings.projectionSettings.term;
        const hasSpouse = spouse.exists;

        const req = {
            csrf: window.csrf
        };

        getAccountsUpdate(req).then(
            async (response) => {
                const updatedAcctsLiteList = filterOutAccountsLite(
                    response,
                    participant.pcapPersonId,
                    hasSpouse,
                    spouse.pcapPersonId
                );

                dispatch({
                    type: UPDATE_ACCOUNT_LITE_ASSETS,
                    list: updatedAcctsLiteList,
                    term,
                    storeState: getState()
                });

                const participantItems = _filter(updatedAcctsLiteList, (item) => {
                    return String(item.investorId) === participant.pcapPersonId;
                });

                dispatch(updateParticipantExternalAssets(participantItems));
                dispatch(splitOtherAssetsByEmployer());

                let spouseItems = [];
                if (hasSpouse) {
                    spouseItems = _filter(updatedAcctsLiteList, (item) => {
                        return String(item.investorId) === spouse.pcapPersonId;
                    });
                    dispatch(updateSpouseExternalAssets(spouseItems));
                }

                await dispatch(updateProjectedIncome());
            },
            (fault) => {
                dispatch(setCurrentFault(fault));
                throw fault;
            }
        );
    };
};

/** Match only on participant and spouse (if existing) */
/** Per PCAP, if personId is undefined, default asset owner to primary user on any asset */
export const filterOutMyLifeGoals = (
    incomeData,
    participantPersonId,
    hasSpouse,
    spousePersonId
) => {
    const updatedList = [];
    for (let i = 0; i < incomeData.goals.length; i++) {
        const item = incomeData.goals[i];
        let skipItem = false;
        if (ObjectUtil.isUndefinedOrNull(item.personId)) {
            item.personId = Number(participantPersonId);
            item.pcapPersonId = participantPersonId;
        }
        if (
            item.personId === Number(participantPersonId) ||
            item.pcapPersonId === participantPersonId ||
            (hasSpouse && item.personId === Number(spousePersonId)) ||
            ObjectUtil.isUndefinedOrNull(item.personId)
        ) {
            if (item.goalType === "EXPENSE") {
                skipItem = true;
            }
            if (
                item.goalCategoryKey === "ANNUAL_SAVINGS" ||
                item.goalCategoryKey === "SOCIAL_SECURITY_INCOME" ||
                item.goalCategoryKey === "SPOUSE_SOCIAL_SECURITY_INCOME"
            ) {
                skipItem = true;
            }
        } else {
            skipItem = true;
        }
        if (!skipItem) {
            const formattedItem = InitDataFactory.createRetirementAsset(item, true);
            updatedList.push(formattedItem);
        }
    }

    return updatedList;
};

export const callMyLifeGoals = () => {
    return (dispatch, getState) => {
        const state = getState();
        const participant = state.participant;
        const spouse = state.spouse;
        const term = state.applicationSettings.projectionSettings.term;
        const hasSpouse = spouse.exists;

        getMyLifeGoalsData().then(
            function (response) {
                if (response && response.goals && response.goals.length > 0) {
                    const updatedLifeGoalsList = filterOutMyLifeGoals(
                        response,
                        participant.pcapPersonId,
                        hasSpouse,
                        spouse.pcapPersonId
                    );

                    dispatch({
                        type: UPDATE_MY_LIFE_GOALS,
                        list: updatedLifeGoalsList,
                        term,
                        storeState: getState()
                    });

                    const participantItems = _filter(updatedLifeGoalsList, (item) => {
                        return String(item.investorId) === participant.pcapPersonId;
                    });

                    dispatch(updateParticipantRetirementAssets(participantItems));

                    let spouseItems = [];
                    if (hasSpouse) {
                        spouseItems = _filter(updatedLifeGoalsList, (item) => {
                            return String(item.investorId) === spouse.pcapPersonId;
                        });
                        dispatch(updateSpouseRetirementAssets(spouseItems));
                    }

                    dispatch(updateProjectedIncome());
                }
            },
            function (fault) {
                dispatch(setCurrentFault(fault));
                throw fault;
            }
        );
    };
};

export const setHasRetailAccount = async (csrf) => {
    try {
        let hasRetailAccount = false;
        const response = await getAccountsUpdate({ csrf });
        if (!ObjectUtil.isUndefinedOrNull(response)) {
            hasRetailAccount = !!_find(
                response,
                (account) =>
                    account.isExternal === false &&
                    Constants.HAS_RETAIL_ACCOUNT.SITE_NAME.some((siteName) =>
                        account.siteName.includes(siteName)
                    )
            );
        }
        globalThis.integratedEligibility.hasRetailAccount = hasRetailAccount;
        return hasRetailAccount;
    } catch (err) {
        console.log("ERROR: setHasRetailAccount", err);
    }
};

export const getAccounts = async (csrf) => {
    try {
        const res = await getAccounts2(csrf);
        let hideLinks = false;
        if (res) {
            if (res.data) {
                if (!res?.data?.spData?.accounts) {
                    logger.info("getAccounts2 - no accounts", res?.data);
                }
                const accounts = res?.data?.spData?.accounts || [];
                let hasClosedTaxableAccount = false;
                let hasOpenTaxableAccounts = false;

                accounts.forEach((account) => {
                    const closedDate = account.closedDate;

                    if (
                        account.empowerAttributes &&
                        account.empowerAttributes.type === "RETAIL" &&
                        account.accountTypeGroup === "INVESTMENT"
                    ) {
                        if (typeof closedDate !== "boolean") {
                            hasOpenTaxableAccounts = true;
                        } else {
                            hasClosedTaxableAccount = true;
                        }
                    }
                });
                hideLinks = hasClosedTaxableAccount && !hasOpenTaxableAccounts;
            } else {
                const errorMessage = res.errorMessage || res.message;

                if (res.errorMessage) {
                    throw new Error(errorMessage);
                }
            }
        }
        return hideLinks;
    } catch (err) {
        console.log("ERROR: getAccounts2", err);
    }
};
