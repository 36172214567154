import DateUtil from "core-ui/client/src/app/DateUtil";

/**
 * Input value validators
 */

function validateDOB(dob, getAge) {
    let isValid = false;
    const pattern = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    const regex = new RegExp(pattern);
    let age = 0;
    let errorMessage;

    if (regex.test(dob)) {
        const splitDob = dob.split("/");
        const formattedDob = `${splitDob[2]}-${splitDob[0]}-${splitDob[1]}`;
        const dateTime = DateUtil.getValueOf(formattedDob);
        age = getAge(dateTime);
        const inputMonth = Number(dob.split("/")[0]);
        const outputMonth = Number(new Date(dob).getMonth() + 1);
        const birthYear = dob.slice(dob.lastIndexOf("/") + 1);
        const currentYear = new Date().getFullYear();

        /** Checks if day is invalid. Ex: 02/31/YYYY  */
        if (inputMonth !== outputMonth) {
            isValid = false;
            errorMessage = "invalid";
        } else {
            isValid = age >= 16 && age <= 114;
            if (!isValid) {
                if (birthYear > currentYear || age > 114) {
                    errorMessage = "invalid";
                } else {
                    errorMessage = "max";
                }
            }
        }
    } else {
        errorMessage = "invalid";
    }
    return { isValid, errorMessage, age };
}

export { validateDOB };
