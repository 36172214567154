import React, { PropsWithChildren } from "react";

const DashboardShell = ({ children }: PropsWithChildren) => {
    if (children) {
        return (
            <div className="dashboard-page-layout" style={{ minHeight: 500 }}>
                {children}
            </div>
        );
    }

    return <div className="dashboard-page-layout" style={{ minHeight: 500 }} />;
};

export default DashboardShell;
