import * as Hub from "hub";

import HubConfigFactory from "../../factories/HubConfigFactory";
import LoggerFactory from "../../factories/LoggerFactory";
import ProjectedIncomeResponseFactory from "../../factories/ProjectedIncomeResponseFactory";
import { canShowWhenCanIRetire } from "../../selectors/featureFlagSelectors";
import {
    getMinRetirementAge,
    getMaxRetirementAge
} from "../../utils/socialSecurity/socialSecurityUtils";
import { setBaselineProjectedIncome } from "../baseline/baselineActions";
import { setCompanyMatchAndProfitSharingMessages } from "../nextSteps/nextStepsActions";
import { splitOtherAssetsByEmployer } from "../otherAssets/otherAssetsActions";
import { SET_WCIR_AGELIST } from "../participantDetails/participantDetailsTypes";
import { setCurrentFault } from "../shared/sharedActionCreators";
import { updateTradeOffMessages } from "../tradeoffMessaging/tradeoffMessagingActions";

import {
    SET_PROJECTED_INCOME,
    SET_INCOME_GOAL_VALUE,
    SET_FOCUS_ON_FIRST_ACTIONABLE,
    SET_HUB_CALCULATION_DONE,
    SET_ACTIVE_INCOME_TAB_ID
} from "./projectedIncomeTypes";

const logger = LoggerFactory.getInstance("ProjectedIncomeActions:");

export const updateProjectedIncome = (resetBaseline = false) => {
    return async (dispatch, getState) => {
        const state = getState();
        const baselineProjectedIncome = state.baseline.projectedIncome;
        const participant = state.participant;
        const primaryPlan = state.primaryPlan;

        try {
            const config = HubConfigFactory.create(state, resetBaseline);
            logger.debug("projectedIncomeActions -> Hub input:");
            logger.debug(JSON.stringify(config));
            const input = {
                type: "projections",
                userlist: config
            };
            const hubResponse = await Hub.getProjectedWebWorker(input);

            logger.debug("projectedIncomeActions -> Hub results:");
            logger.debug(JSON.stringify(hubResponse));

            if (canShowWhenCanIRetire(participant, primaryPlan)) {
                const minRetireAge = getMinRetirementAge(config[0].age);
                const maxRetireAge = getMaxRetirementAge(config[0].age);

                const wcirRequest = {
                    type: "wcir",
                    minRetireAge: minRetireAge,
                    maxRetireAge: maxRetireAge,
                    percentGoal: hubResponse.percentOfGoal,
                    config: config
                };

                const wcirResults = await Hub.getProjectedWebWorker(wcirRequest);
                logger.debug("liat wcir results {0}", [JSON.stringify(wcirResults)]);
                dispatch({
                    type: SET_WCIR_AGELIST,
                    payload: wcirResults
                });
            }

            const projectedIncome = ProjectedIncomeResponseFactory.getProjectedIncome(hubResponse);
            const payload = {
                details: hubResponse.details,
                limitsHit: hubResponse.limitsHit,
                messages: hubResponse.messages,
                retireeDetails: hubResponse.retireeDetails,
                ...projectedIncome
            };
            if (!baselineProjectedIncome.baselineSet || resetBaseline) {
                dispatch(setBaselineProjectedIncome(payload));
            }

            dispatch({ type: SET_PROJECTED_INCOME, payload });
            dispatch(setCompanyMatchAndProfitSharingMessages(payload.messages));
            dispatch(updateTradeOffMessages());
        } catch (e) {
            dispatch(setCurrentFault({ code: "HUB", message: e.toString() }));
        }
    };
};

export const setIncomeGoalValue = (value) => {
    return {
        type: SET_INCOME_GOAL_VALUE,
        payload: value
    };
};

export const setHubCalculation = (value) => {
    return {
        type: SET_HUB_CALCULATION_DONE,
        payload: value
    };
};

export const preRetireeProjectedIncome = () => {
    // Saving the plan to age should update both our baseline and UI values
    return (dispatch, getState) => {
        const state = getState();

        try {
            const config = HubConfigFactory.create(state, true);

            logger.debug("projectedIncomeActions -> Hub input:");
            logger.debug(JSON.stringify(config));
            const hubResponse = Hub.getProjectedIncome(config);

            logger.debug("projectedIncomeActions -> Hub results:");
            logger.debug(JSON.stringify(hubResponse));
            const projectedIncome = ProjectedIncomeResponseFactory.getProjectedIncome(hubResponse);

            const payload = {
                details: hubResponse.details,
                limitsHit: hubResponse.limitsHit,
                messages: hubResponse.messages,
                retireeDetails: hubResponse.retireeDetails,
                ...projectedIncome
            };

            // update baseline
            dispatch(setBaselineProjectedIncome(payload));

            // update UI
            dispatch(updateProjectedIncome(false));

            //update other assets
            dispatch(splitOtherAssetsByEmployer());
        } catch (e) {
            dispatch(setCurrentFault({ code: "HUB", message: e.toString() }));
        }
    };
};

export const setFocusOnFirstActionable = (payload) => {
    return {
        type: SET_FOCUS_ON_FIRST_ACTIONABLE,
        payload: payload
    };
};

export const setActiveIncomeTabId = (incomeTabId) => {
    return {
        type: SET_ACTIVE_INCOME_TAB_ID,
        payload: incomeTabId
    };
};
