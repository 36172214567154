/**
 * @class common.model.domain.PlanMatchRuleCriteria
 * @copyright 2015 Empower Retirement
 * @description
 * Creates a defined object structure for the PlanMatchRuleCriteria
 */

"use strict";

const PlanMatchRuleCrit = function (config) {
    this.planMatchRuleRates = [];
    this.planMatchRuleCritDetls = [];
    this.seqnbr = null;
    this.tierCalcLevel = null;
    this.matchCalculatedOn = null;

    if (config) {
        this.planMatchRuleRates = config.planMatchRuleRates;
        this.planMatchRuleCritDetls = config.planMatchRuleCritDetls;
        this.seqnbr = config.seqnbr;
        this.tierCalcLevel = config.tierCalcLevel;
        this.matchCalculatedOn = config.matchCalculatedOn;
    }
};

export default PlanMatchRuleCrit;
