/**
 *
 * @class ErrorTypes
 * @memberOf common.model.constants
 * @date 02/09/2015
 * @copyright 2015 Empower Retirement
 * @description This module encapsulates errors and holds error type constants.
 * @property {object} PREFIXES_TO_SHOW MODAL - list of prefixes that show error modal
 * @property {string} DEFAULT_MESSAGE - the default error message
 *
 */

"use strict";

const ErrorTypes = {
    PREFIXES_TO_SHOW_MODAL: {
        PAE: { message: "errors.non-participant-user", redirect: null },
        FAQ: { message: "errors.faq-not-found", redirect: "/" },
        FEP: { message: "errors.fepErrorMessage", redirect: null },
        DSB: { message: "errors.dashboardMessage", redirect: null },
        AAQ: { message: "errors.dashboardMessage", redirect: null },
        PRF: { message: "errors.goal-modal-user-pref-errors", redirect: null }
    },
    DEFAULT_MESSAGE: "errors.defaultErrorMessage",

    /**
     *
     * @function showError
     * @memberOf common.model.constants.ErrorTypes
     * @description Determines whether to show an error based on its code
     * @param {string} errorCode used to see if error object contains this message.
     * @returns {boolean} returns true if the message matches one of predetermined messages.
     *
     */
    showErrorFromPrefix: function (errorCode) {
        if (errorCode.length < 3) {
            return false;
        }
        const prefix = String(errorCode).substr(0, 3);
        return this.PREFIXES_TO_SHOW_MODAL.hasOwnProperty.call(prefix);
    },

    /**
     *
     * @function getErrorMessage
     * @memberOf common.model.constants.ErrorTypes
     * @description Returns the error message
     * @param {string} errorCode used to see if error object contains this message.
     * @returns {string} returns the error message for the given code
     *
     */
    getErrorMessage: function (code) {
        //handle numbers, since at the moment cloud services are not sending us the correct error type
        if (!isNaN(code)) {
            code = "CLD" + code;
        }
        if (!this.showErrorFromPrefix(code)) {
            return this.DEFAULT_MESSAGE;
        } else {
            const prefix = code.substr(0, 3);
            if (prefix === "PAE") {
                return "errors.non-participant-user." + code;
            }
            if (prefix === "AAQ") {
                return "errors.account-aggregation-error." + code;
            }
            return this.PREFIXES_TO_SHOW_MODAL[code.substr(0, 3)].message;
        }
    },
    /**
     *
     * @function getRedirect
     * @memberOf common.model.constants.ErrorTypes
     * @description Returns the redirect property (null or some url location)
     * @param {string} errorCode used to see if error object contains this message.
     * @returns {string} returns true if the message matches one of predetermined messages.
     *
     */
    getRedirect: function (code) {
        if (!this.showErrorFromPrefix(code)) {
            return null;
        } else {
            return this.PREFIXES_TO_SHOW_MODAL[code.substr(0, 3)].redirect;
        }
    }
};

export default ErrorTypes;
