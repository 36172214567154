type LoginLashHash = string | undefined;

const getLoginLastHash = (): LoginLashHash => {
    const loginLashHash = sessionStorage.getItem("participant-loginLastHash");

    if (loginLashHash) {
        return loginLashHash;
    }
};

export default getLoginLastHash;
