import React, { ChangeEvent, memo, useCallback, useState } from "react";

import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { useDispatch } from "react-redux";

import { sentryQuickviewRenderMetrics } from "../../../utils/sentryMetricsLogging";
import { dispatchAmplitude } from "../../actions/shared/sharedActionCreators";
import { AppDispatch } from "../../hooks/use-redux-actions";
import { useAccountSummary, useQuickViewAccounts } from "../../queries";
import { filterTrsPlans } from "../../selectors/helpers/hasTrsPlan/hasTrsPlan";
import { IAccount } from "../../types/common";
import { formatTimestamp } from "../../utils/dateUtils";
import CurrencyFormatter from "../currencyFormatter/CurrencyFormatter";
import { QuickViewShell } from "../loadShells/LoadShells";

import { QuickViewDetailsOpen, QuickViewDropdown } from "./components";
import { getPlanDetails, truncateSubsetName } from "./helpers";
import IQuickViewAccount from "./types";

const DEFAULT_QUICK_VIEW_ACCOUNTS: IQuickViewAccount[] = [];
const DEFAULT_MONTHLY_ACCOUNTS: IAccount[] = [];
const DEFAULT_NON_MONTHLY_ACCOUNTS: IAccount[] = [];

interface QuickViewProps {
    showRetirementHeader: boolean;
}

/*
 * When mocking the quickview, need to update get-init-data.json, get-account-summary.json, and get-quick-view-account-data.json
 */
const QuickView = (props: QuickViewProps) => {
    const { showRetirementHeader } = props;
    // translations
    const rivdTodayViewTranslations = selectTranslations("rivdTodayView");
    const quickViewTranslations = selectTranslations("quickView");
    const accountBalanceTranslations =
        selectTranslations("accountList").accountOverview.accountBalance;
    const header = selectTranslations("retirementIncome");

    const {
        data: quickViewData,
        isLoading: quickViewDataLoading,
        isError: isQuickViewError,
        error: quickViewError
    } = useQuickViewAccounts();

    const {
        data: accountSummary,
        isLoading: accountSummaryLoading,
        isError: isAccountSummaryError,
        error: accountSummarycError
    } = useAccountSummary();

    const [planId, setPlanId] = useState<null | string>(null);

    const dispatch = useDispatch<AppDispatch>();

    const handleChange = useCallback(
        (event: ChangeEvent<HTMLAnchorElement>) => {
            const { id } = event.target;
            setPlanId(id);
        },
        [setPlanId]
    );

    const quickViewBalanceCallbackRef = useCallback((node: HTMLParagraphElement | null) => {
        if (node) {
            sentryQuickviewRenderMetrics();
        }
    }, []);

    if (isQuickViewError || isAccountSummaryError) {
        // add an error UI state?
        console.error(
            "Quick view error: ",
            quickViewError,
            "Account summary error: ",
            accountSummarycError
        );
        return null;
    }
    if (quickViewDataLoading || accountSummaryLoading) {
        return (
            <div
                className="quick-view-wrapper gray-border-000 rounded-border bg-white"
                style={{ minHeight: 146 }}
            >
                <QuickViewShell />
            </div>
        );
    }

    const quickViewStatusFail = !!quickViewData && quickViewError;
    const isQuickViewDataNullOrEmpty = !quickViewData || quickViewData.length === 0;

    if (isQuickViewDataNullOrEmpty || quickViewStatusFail || !accountSummary) {
        return null;
    }

    if (quickViewData && !quickViewError) {
        dispatch(
            dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.VIEW_PAGE,
                event_properties: {
                    selection: "Quickview_complete_event"
                }
            })
        );
    }
    const filteredMonthlyAccounts = (
        accountSummary.monthlyAccounts || DEFAULT_MONTHLY_ACCOUNTS
    ).filter(filterTrsPlans);

    const filteredNonMonthlyAccounts = (
        accountSummary.nonMonthlyAccounts || DEFAULT_NON_MONTHLY_ACCOUNTS
    ).filter(filterTrsPlans);

    const quickViewAccounts = quickViewData || DEFAULT_QUICK_VIEW_ACCOUNTS;

    const planDetails = getPlanDetails(quickViewAccounts, planId);

    const { annualRateOfReturn, adjustedBeginDate, adjustedEndDate } = planDetails?.ror || {};

    let amount = 0;

    filteredNonMonthlyAccounts.forEach((item) => {
        if (planDetails?.gaId === item.groupId && planDetails?.indId === item.individualId) {
            amount = item.totalBalance;
        }
    });

    if (amount === 0) {
        filteredMonthlyAccounts.forEach((item) => {
            if (planDetails?.gaId === item.groupId && planDetails?.indId === item.individualId) {
                amount = item.totalBalance;
            }
        });
    }

    const formatedAdjBeginDate = formatTimestamp(adjustedBeginDate);
    const formatedAdjEndDate = formatTimestamp(adjustedEndDate);
    const rateOfReturnDateRange = `${formatedAdjBeginDate} - ${formatedAdjEndDate}`;

    const showDropdown = quickViewAccounts.length > 1;

    const componentClass = quickViewDataLoading || accountSummaryLoading ? "" : "loaded";

    const currentPlanSubsetName = planDetails?.subsetName || "";
    const truncatedSubsetName = currentPlanSubsetName && truncateSubsetName(currentPlanSubsetName);

    return (
        <>
            {showRetirementHeader && (
                <div className="header" data-testid="retirement-header">
                    {header.retirement}
                </div>
            )}
            <div
                className="quick-view-wrapper gray-border-000 rounded-border bg-white"
                style={{ minHeight: 146 }}
            >
                <div className={`use-fade-in quick-view-container d-flex open ${componentClass}`}>
                    <div className="d-flex quick-view-account-name">
                        <p className="mainTitle">{accountBalanceTranslations}</p>

                        {planDetails && showDropdown ? (
                            <QuickViewDropdown
                                inContextFullList={quickViewAccounts}
                                dropDownChangeHandler={handleChange}
                                selectedPlan={planDetails}
                                truncatedSubsetName={truncatedSubsetName}
                            />
                        ) : (
                            <p
                                className="quick-view-single-plan-name"
                                aria-label={`${planDetails?.planName} ${currentPlanSubsetName}`}
                                data-testid="quick-view-single-plan-name"
                            >
                                {planDetails?.planName}
                                {currentPlanSubsetName && (
                                    <span className="subsetName-container">
                                        {` - ${truncatedSubsetName}`}
                                    </span>
                                )}
                            </p>
                        )}
                        <p className="totalBalance" ref={quickViewBalanceCallbackRef}>
                            {amount || amount === 0 ? (
                                <CurrencyFormatter awaitHubCalc={false} amount={amount} />
                            ) : (
                                "N/A"
                            )}
                        </p>
                    </div>
                    <QuickViewDetailsOpen
                        translations={{
                            ...rivdTodayViewTranslations,
                            ...quickViewTranslations
                        }}
                        planDetails={planDetails}
                        annualRateOfReturn={annualRateOfReturn}
                        rateOfReturnDateRange={rateOfReturnDateRange}
                    />
                </div>
            </div>
        </>
    );
};

export default memo(QuickView);
