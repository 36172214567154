import axios, { all, spread } from "axios";
import { isString } from "gw-shared-components";
import { forEach as _forEach, get as _get } from "lodash";

import RequestFactory from "../factories/RequestFactory";

import { FAIL, handleFault } from "./errorUtils";

const UPDATE_PERSON_LITE = "person";

export const executeRequest = (request, faultLabel) => {
    return axios(request)
        .then((response) => {
            checkResponseForError(response);
            return response;
        })
        .catch((err) => {
            throw handleFault(err, faultLabel);
        });
};

export const executeGet = (url, faultLabel, params) => {
    return axios
        .get(url, params)
        .then((response) => {
            checkResponseForError(response);
            return response;
        })
        .catch((err) => {
            throw handleFault(err, faultLabel);
        });
};

export const executeGetAll = (urls, faultLabel) => {
    return all(urls)
        .then(
            spread((...responses) => {
                _forEach(responses, (response) => {
                    checkResponseForError(response);
                });
                return responses;
            })
        )
        .catch((err) => {
            throw handleFault(err, faultLabel);
        });
};

const checkResponseForError = (response) => {
    if (!_get(response, "data") || _get(response, "data.status") === FAIL) {
        throw response;
    }
    return response;
};

export const pcapServiceRequest = async ({
    method = RequestFactory.HTTP_CONTENT_TYPE.POST,
    url,
    name = UPDATE_PERSON_LITE,
    data,
    contentType = RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED
}) => {
    const formData = new FormData();

    let payload = data;
    if (!isString(data)) {
        payload = JSON.stringify(data);
    }

    formData.append(name, payload);
    formData.append("csrf", window.csrf);

    try {
        return axios({
            method,
            url,
            headers: { "content-type": contentType },
            data: formData,
            xhrFields: { withCredentials: true }
        });
    } catch (err) {
        const faultLabel = `pcap service request : ${name}`;
        throw handleFault(err, faultLabel);
    }
};

/**
 * pcap service call based on input parameters, sponsor id and person id
 * @param {} param0
 * @returns axios
 */
export const pcapServiceCall = ({
    method = RequestFactory.HTTP_CONTENT_TYPE.POST,
    url,
    contentType = RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED,
    faultLabel
}) => {
    const formData = new FormData();
    formData.append("csrf", window.csrf);

    return axios({
        method: method,
        headers: { "content-type": contentType },
        url: url,
        data: formData,
        withCredentials: true
    })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return handleFault(err, faultLabel);
        });
};
