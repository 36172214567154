import { executeGet } from "../utils/apiUtils.js";
import { getUrl } from "../utils/urlUtils.js";

export const getNextSteps = async (personId) => {
    const service = "getNextStep";
    const url = getUrl(service);
    let params = null;

    if (personId) {
        params = {
            topic: "NEXT_STEPS",
            channel: "NEXTGENPW",
            subjectType: "MTR_IND",
            subjectKey: personId,
            limit: 1,
            createViewEvents: true
        };
    }

    const response = await executeGet(url, "getNextStepsFault", { params });
    return response.data;
};
