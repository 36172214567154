/**
 *
 * @name app.module.projectedIncome.model.constants.MatchTypes
 * @copyright 2015 Empower Retirement
 * @description
 *
 *  This file contains the company match calcTypes
 */
"use strict";

const MatchTypes = {
    COMPANY_MATCH: "ER MATCH",
    PROFIT_SHARING: "PROFIT SHARE",
    COMPANY_MATCH_CSOR: "FLAT_AMT_CSOR",
    COMPANY_PROFIT_SHARING: "PROFIT_SHARING"
};

export default MatchTypes;
