import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

const CsorModal = ({ show, closeModal }) => {
    const translations = selectTranslations("csorModal");

    return (
        <Modal dialogClassName="csor-modal" show={show} data-testid="csor-modal">
            <Modal.Header className="modal-header">
                <h3 className="modal-title" id="modal-title" data-testid="csor-modal-title">
                    {translations.title}
                </h3>
            </Modal.Header>
            <Modal.Body className="modal-body" id="modal-body" data-testid="csor-modal-content">
                {translations.content}
            </Modal.Body>
            <Modal.Footer className="modal-footer">
                <div className="footer-content">
                    <div className="btns">
                        <button
                            className="done-btn btn btn-primary"
                            type="button"
                            onClick={closeModal}
                            data-testid="csor-modal-button"
                        >
                            {translations.ok}
                        </button>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
CsorModal.propTypes = {
    show: PropTypes.bool,
    closeModal: PropTypes.func
};
export { CsorModal };
