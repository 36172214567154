"use strict";
/**
 * @name app.infrastructure.domainModel.DataGroup
 * @date 1/26/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Definition of the DataGroup domain model object.
 */

const DataGroup = function (config) {
    this.from = 0;
    this.to = 50;
    this.label = "Some range of values...";
    this.id = "12345";
    this.sortValue = 0;

    if (config) {
        this.from = config.from;
        this.to = config.to;
        this.label = config.label;
        this.id = config.id;
        this.sortValue = config.sortValue;
    }
};

export default DataGroup;
