import {
    TypedUseSelectorHook,
    useDispatch as useAppDispatch,
    useSelector as useAppSelector
} from "react-redux";

import { store } from "../../store/store";
import { AppState } from "../../types/common";

// Infer `AppDispatch` types from the store itself
export type AppDispatch = typeof store.dispatch;

// https://redux.js.org/usage/usage-with-typescript#define-root-state-and-dispatch-types
// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => AppDispatch;
export const useDispatch: DispatchFunc = useAppDispatch;
export const useSelector: TypedUseSelectorHook<AppState> = useAppSelector;
