import {
    GET_RETIREMENT_INCOME_SLIDERS,
    GET_MIN_RETIREMENT_AGE
} from "../../actions/retirementSavings/retirementSavingsTypes";
import ReferenceData from "../../constants/ReferenceData";

const initialState = {
    sliders: [],
    minRetirementAge: ReferenceData.MIN_RETIREMENT_AGE
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_RETIREMENT_INCOME_SLIDERS:
            return { ...state, sliders: payload };
        case GET_MIN_RETIREMENT_AGE:
            return { ...state, minRetirementAge: payload };
        default:
            return state;
    }
};
