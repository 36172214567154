import {
    getVerifyCodeSignIn,
    getLoginButtonClick,
    getLoginAppStart,
    getLoginLastHash
} from "../../getEvents";
import { LoginFlowEvents } from "../diagnosticsConstants";

const lastLoginSsoSpinner = (str: string): boolean => {
    return str.includes("ssoSpinner");
};

interface DetermineLoginFlow {
    endTime: number;
    flowName: LoginFlowEvents | string;
    loginLastHash: string | undefined;
    startHash: string;
}

const determineLoginFlow = (): DetermineLoginFlow => {
    const verifyCodeSignIn = getVerifyCodeSignIn();
    const loginLastHash = getLoginLastHash();
    const loginButtonClick = getLoginButtonClick();
    const ssoSpinner = loginLastHash ? lastLoginSsoSpinner(loginLastHash) : false;
    const loginAppStart = getLoginAppStart();

    let flowName = "";
    let startHash = "";
    let endTime = 0;

    // If true, we are in the MFA Sign in login flow
    if (verifyCodeSignIn) {
        flowName = LoginFlowEvents.MFA_LOGIN;
        startHash = verifyCodeSignIn.hash;
        endTime = Number(verifyCodeSignIn.timestamp);
    }
    // If true, we are in the DF login flow
    else if (loginButtonClick) {
        flowName = LoginFlowEvents.DF_LOGIN;
        startHash = loginButtonClick.hash;
        endTime = Number(loginButtonClick.timestamp);
    } else if (ssoSpinner && loginAppStart) {
        flowName = LoginFlowEvents.SSO_LOGIN;
        startHash = loginAppStart.hash;
        endTime = Number(loginAppStart.timestamp);
    }

    return {
        loginLastHash,
        flowName,
        startHash,
        endTime
    };
};

export default determineLoginFlow;
