import React, { useState } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { ObjectUtil } from "gw-shared-components";
import { get as _get } from "lodash";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { setCurrentFault } from "../../../actions/shared/sharedActionCreators";
import ErrorTypes from "../../../constants/ErrorTypes";
import ReferenceData from "../../../constants/ReferenceData";

const STAY_ON_CURRENT_PAGE = "stayOnCurrentPage";

const ErrorModal = () => {
    const dispatch = useDispatch();
    const currentFault = useSelector((state) => state.shared.currentFault);
    const appTranslations = selectTranslations("app");
    const errorTranslations = selectTranslations("error");

    const apiError = useSelector((state) => state.shared.apiError);
    const [dismissed, handleDismissed] = useState(false);

    const hasPrefixToShowModal = () => {
        if (
            !ObjectUtil.isUndefinedOrNull(currentFault) &&
            !ObjectUtil.isUndefinedOrNull(currentFault.code)
        ) {
            return ErrorTypes.showErrorFromPrefix(currentFault.code);
        }
        return false;
    };

    function getUrlParam(prop) {
        let val = null;
        const params = {};
        const search = decodeURIComponent(
            window.location.href.slice(window.location.href.indexOf("?") + 1)
        );
        const definitions = search.split("&");

        definitions.forEach(function (val) {
            const parts = val.split("=", 2);
            params[parts[0]] = parts[1];
        });

        val = prop && prop in params ? params[prop] : null;
        return val;
    }

    const showDebug = getUrlParam("debug") === "true" || hasPrefixToShowModal();

    const hasError = () => {
        const _hasFault =
            !ObjectUtil.isUndefinedOrNull(currentFault) &&
            !ObjectUtil.isUndefinedOrNull(currentFault.code);

        // if there is no fault, just exit..
        if (!_hasFault) {
            return false;
        }
        // if there is a fault, and it has an error code, or the user is in debug mode, return the hasFault.
        if (
            (!ObjectUtil.isUndefinedOrNull(currentFault) &&
                !ObjectUtil.isUndefinedOrNull(currentFault.code)) ||
            showDebug
        ) {
            return _hasFault;
        }

        return false;
    };

    const showError = () => {
        return (
            !ObjectUtil.isUndefinedOrNull(currentFault) &&
            !ObjectUtil.isUndefinedOrNull(currentFault.code)
        );
    };

    const canShowErrorCode = () => {
        const errorCode =
            currentFault && !ObjectUtil.isUndefinedOrNull(currentFault.code)
                ? currentFault.code
                : null;

        return (showDebug || showError()) && !ObjectUtil.isUndefinedOrNull(errorCode);
    };

    const canShowRequestData = () => {
        const requestData =
            currentFault && !ObjectUtil.isUndefined(currentFault.requestData)
                ? currentFault.requestData
                : null;

        return showDebug && !ObjectUtil.isUndefinedOrNull(requestData);
    };

    const canShowStack = () => {
        const stack =
            currentFault && !ObjectUtil.isUndefinedOrNull(currentFault.stack)
                ? currentFault.stack
                : null;

        return (showDebug || showError()) && !ObjectUtil.isUndefinedOrNull(stack);
    };

    const closeModal = () => {
        handleDismissed(false);

        if (showError() && !_get(currentFault, STAY_ON_CURRENT_PAGE)) {
            if (!ErrorTypes.showErrorFromPrefix(currentFault.code)) {
                location.href = ReferenceData.URL_ACCOUNTS_OVERVIEW;
            } else {
                const redirect = ErrorTypes.getRedirect(currentFault.code);

                if (!ObjectUtil.isUndefinedOrNull(redirect)) {
                    location.pathname = redirect;
                }
            }
        }

        // reset current fault
        dispatch(setCurrentFault({}));
    };

    const getTitle = () => {
        if (currentFault && !ObjectUtil.isUndefinedOrNull(currentFault.message)) {
            return currentFault.message;
        }

        const apiErrorTitle =
            appTranslations &&
            !ObjectUtil.isUndefinedOrNull(appTranslations.errors) &&
            !ObjectUtil.isUndefinedOrNull(appTranslations.errors.api)
                ? appTranslations.errors.api.title
                : "Error";

        return apiError() ? apiErrorTitle : "Runtime Error";
    };

    const getErrorMessage = function () {
        if (showError() || showDebug) {
            let message = currentFault.message;
            const catchException = /(com|org\.).+/g;

            if (catchException.test(currentFault.message)) {
                message = errorTranslations.errorOccurred;
            }

            return message;
        }
    };

    const showTitle = !!(currentFault && !ObjectUtil.isUndefinedOrNull(currentFault.title));

    return appTranslations ? (
        <Modal show={hasError() && !dismissed}>
            <div
                id="errorModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                aria-hidden="true"
                data-testid="error-modal"
            >
                <Modal.Header>
                    <Modal.Title>
                        {(canShowStack() || showTitle) && (
                            <div>
                                <h4 className="modal-title" id="myModalLabel">
                                    {getTitle()}
                                </h4>
                            </div>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>{getErrorMessage()}</div>
                    {canShowRequestData() && (
                        <div>
                            <strong>Request Data</strong>:{currentFault.requestData}
                        </div>
                    )}
                    {canShowStack() && (
                        <div>
                            <strong>Stack Trace</strong>:<pre>{currentFault.stack}</pre>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <hr />
                    {canShowErrorCode() && (
                        <div className="errorCode text-muted">
                            <strong>Code</strong>: {currentFault.code}
                        </div>
                    )}
                    <div className="buttonContainer">
                        <button type="button" className="btn btn-link" onClick={closeModal}>
                            {appTranslations.common.labels.dismiss}
                        </button>
                    </div>
                </Modal.Footer>
            </div>
        </Modal>
    ) : null;
};

export default ErrorModal;
