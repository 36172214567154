import { isUndefinedOrNull } from "gw-shared-components";

/**
 * @memberOf liat.utils.numberUtils
 * @description Returns a value that rounds a number to a specified number of digits
 * @param {number} number The number that you want to round.
 * @param {number} numberOfDigits The number of digits to which you want to round the number argument.
 * @returns {number}
 */
export const getRoundedValue = (number, numberOfDigits) => {
    if (isUndefinedOrNull(number) || isUndefinedOrNull(numberOfDigits)) return null;
    const rounder = Math.pow(10, numberOfDigits);
    return Math.round(number * rounder) / rounder;
};

/**
 * @description convert string as number to a percentage
 * @param value
 * @returns {number}
 */
export const getStringDisplayPercent = (value) => {
    if (isUndefinedOrNull(value)) return null;
    value = Math.round(value);
    return value === 0.0 ? "<1%" : Math.round(value * 100) / 100 + "%";
};

/**
 * This method converts a number to a percent
 * @param {Number} input
 * @returns {Number}
 */
export const toPercent = (input) => {
    if (Number.isNaN(input)) {
        throw new Error("input needs to be a number");
    } else {
        return input / 100;
    }
};

/**
 * This method converts a decimal to a rounded percent
 * @param {Number} input
 * @returns {Number}
 */
export const decimalToPercent = (input) => {
    if (Number.isNaN(input)) {
        throw new Error("input needs to be a number");
    } else {
        return Math.round(input * 100);
    }
};
