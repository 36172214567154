import { useRef, useEffect, useState } from "react";

import { getLastArrayItem } from "gw-shared-components";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { dispatchEventBus } from "../actions/shared/sharedActionCreators";
import EventBusEvents from "../events/eventBusEvents";

/**
 * Hook for getting a previous value
 */
export const usePrevious = (value) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};

export const usePageViews = (view = "LIAT") => {
    const location = useLocation();

    const dispatch = useDispatch();
    const name = `.${getLastArrayItem(location.pathname.split("/"))}`;
    let pageViewName;
    if (name === ".emergency-fund") {
        pageViewName = `${EventBusEvents[view].WITHDRAWAL_PLANNER}`;
    } else {
        pageViewName = `${EventBusEvents[view].PAGE_VIEW + name}`;
    }
    useEffect(() => {
        dispatch(dispatchEventBus(pageViewName));
    }, [dispatch, location, pageViewName]);
};

export const useDebounce = (value, delay) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );

    return debouncedValue;
};
