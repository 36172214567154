import { useQuery } from "@tanstack/react-query";

import { getTopNavLinks } from "../../services";
import type { Participant, TopNavLinks, IPrimaryPlan } from "../../types/common";
import { QUERY_KEYS } from "../constants";

type Args = {
    participant: Participant;
    primaryPlan: IPrimaryPlan;
};

const useGetTopNavLinks = ({ participant, primaryPlan }: Args) => {
    const individualId = participant.individualId;
    const planId = primaryPlan.planId;

    return useQuery<TopNavLinks>({
        queryKey: [QUERY_KEYS.GET_TOP_NAV_LINKS, individualId, planId],
        queryFn: getTopNavLinks,
        enabled: !!individualId && !!planId
    });
};

export default useGetTopNavLinks;
