import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import constants from "../../../../constants/Constants";

import { TableHeaders, ContributionRates, Balances, MobileCharts } from "./index";

const TabledDataView = ({ toggleHDICModal, topPeerPct, myPeerPct }) => {
    const translations = selectTranslations("hdic");
    const score = useSelector((state) => state.howDoICompare.score);
    const me = useSelector((state) => state.howDoICompare.me);

    const isMobile = useMediaQuery({
        query: "(max-width: " + constants.MAX_SCREEN_WIDTH.PHONE + "px)"
    });

    return (
        <React.Fragment>
            {isMobile ? (
                <div className="hidden-lg hidden-md hidden-sm visible-xs mobile-view">
                    <div className="mobile-charts-container">
                        <MobileCharts
                            type="peers"
                            translations={translations}
                            peerPct={myPeerPct}
                        />
                        <MobileCharts
                            type="topPeers"
                            translations={translations}
                            peerPct={topPeerPct}
                        />
                        <MobileCharts type="me" translations={translations} peerPct={null} />
                    </div>
                    <table className="secondary-how-do-i-compare-table-data">
                        <tbody>
                            <TableHeaders
                                toggleHDICModal={toggleHDICModal}
                                display={"header2"}
                                translations={translations}
                            />
                            <ContributionRates translations={translations} score={score} me={me} />
                            <Balances translations={translations} score={score} me={me} />
                        </tbody>
                    </table>
                </div>
            ) : null}

            <div className="visible-lg visible-md visible-sm hidden-xs desktop-view">
                <table className="secondary-how-do-i-compare-table">
                    <tbody>
                        <ContributionRates translations={translations} score={score} me={me} />
                        <Balances translations={translations} score={score} me={me} />
                    </tbody>
                </table>
                <hr className="separateLine" />
            </div>
        </React.Fragment>
    );
};
TabledDataView.propTypes = {
    translations: PropTypes.object,
    toggleHDICModal: PropTypes.func,
    topPeerPct: PropTypes.number,
    myPeerPct: PropTypes.number
};

export default TabledDataView;
