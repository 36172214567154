import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import { isNil as _isNil } from "lodash";

import { saveHsaPreferencesAction, setHsaLoader } from "../actions/hsa/hsaActions";
import { setCurrentFault } from "../actions/shared/sharedActionCreators";
import RequestFactory from "../factories/RequestFactory";
import { executeRequest } from "../utils/apiUtils";
import { handleFault } from "../utils/errorUtils";
import { handleErrorPAE, isNotEmulator } from "../utils/paeUtils";
import { getUrl } from "../utils/urlUtils.js";

const logger = ExternalLogger.getInstance("HSAService");

export const saveHsaPreferences = function (clientId, individualId, planId, config, dispatch) {
    dispatch(setHsaLoader(true));

    savePreference(clientId, individualId, planId, config, dispatch).then(
        () => {
            dispatch(saveHsaPreferencesAction(config));
        },
        (fault) => {
            logger.warn("savePreferencesFault()");
            dispatch(setCurrentFault(fault));
        }
    );
};

const savePreference = (clientId, individualId, planId, config, dispatch) => {
    logger.info("save hsa Preference( clientId = {0}, individualId = {1}, planId = {2} )", [
        clientId,
        individualId,
        planId
    ]);

    const serviceConfig = {
        actionCode: "UPDATE_USER_PREF",
        clientId: clientId,
        planId: planId,
        individualId: individualId,
        hsaSelectionInd: config.hsaSelectionInd,
        hsaExpenseAmount: config.hsaExpenseAmount
    };

    const saveHSAPreferencesResult = function (result) {
        if (_isNil(result) || _isNil(result.data)) {
            logger.error("saveHSAPreferencesResult( Bad response data. )");
            throw "There is no response data.";
        } else {
            logger.info("saveHSAPreferencesResult() completed successfully");
        }

        return result.data;
    };

    const saveHSAPreferencesFault = function (fault) {
        logger.error("saveHSAPreferencesFault( fault = {0} )", [fault.message]);
        return handleFault(fault);
    };

    const url = getUrl("saveHsaPreferences");
    const method = RequestFactory.HTTP_METHOD.POST;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const request = RequestFactory.create(url, serviceConfig, method, contentType);
    delete request.headers.samlToken;

    const proceedParticipantRequest = () => {
        return executeRequest(request, "saveHSAPreferences")
            .then(saveHSAPreferencesResult)
            .catch(saveHSAPreferencesFault);
    };

    return isNotEmulator()
        .then(proceedParticipantRequest)
        .catch(() => {
            handleErrorPAE(dispatch);
        });
};
