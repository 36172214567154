import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { isUndefinedOrNull } from "gw-shared-components";

import { setCurrentFault } from "../actions/shared/sharedActionCreators";
import { getThenSetToken } from "../services/setSamlTokenService";

export const setSamlToken = async function () {
    const nonFeUser =
        !globalThis.integratedEligibility.isFeUser &&
        globalThis.integratedEligibility.eligibilityLoaded;
    if (nonFeUser) {
        return Promise.resolve("non-FeUser, no need to set");
    }

    return async (dispatch) => {
        const response = await getThenSetToken();
        if (!isUndefinedOrNull(response)) {
            if (Object.prototype.hasOwnProperty.call(response, "isApiError")) {
                const translations = selectTranslations("errors");

                response.message = translations.defaultErrorMessage;
                return dispatch(setCurrentFault(response));
            }
        }
    };
};
