import React from "react";

import PropTypes from "prop-types";

/**
 * Render select tag with options from 50 to 200 incremented by 5
 * @param {function} handlePortion - Handler function that takes an option value and updates state
 * @param {number} portion - Percent amount
 */
const Percent = ({ handlePortion, portion }) => {
    const generateOptions = () => {
        const options = [];
        for (let i = 50; i <= 200; i += 5) {
            options.push(i);
        }
        return (
            <select
                onChange={(e) => handlePortion(Number(e.target.value), true)}
                id="income-replacement"
                name="income-replacement"
                label=""
                className="btn dropdown-toggle"
                value={portion}
            >
                {options.map((option) => {
                    return (
                        <option
                            className="percent-value"
                            key={option}
                            label={option}
                            value={option}
                        >
                            {option}
                        </option>
                    );
                })}
            </select>
        );
    };

    /** For MAX eligible users, remove border from parent div tag */
    return (
        <div
            className="custom-select custom-select-medium form-control no-border"
            data-testid="percent"
        >
            {generateOptions()}
        </div>
    );
};

Percent.propTypes = {
    handlePortion: PropTypes.func.isRequired,
    portion: PropTypes.number
};

export default Percent;
