import React, { memo, useMemo } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";

import { useSelector } from "../../../../liat/hooks/use-redux-actions/index";
import { hasHsaDeferralInPlan } from "../../../business/hsaLogic/hsa";
import AddContributionButton from "../../addDeferralModal/AddDeferralButton";
import ContributionSliders from "../components/ContributionsSliders";

interface SummarySliderBreakdownProps {
    availableDeferrals: { id: number; name: string }[];
    canShowSummary: boolean;
    handleShowAddDeferralModal: (show: boolean) => void;
    setShowCsorModal: () => void;
    showSummaryExpansion: boolean;
}

const SummarySliderBreakdown: React.FC<SummarySliderBreakdownProps> = ({
    showSummaryExpansion,
    canShowSummary,
    setShowCsorModal,
    handleShowAddDeferralModal,
    availableDeferrals
}) => {
    const translations = selectTranslations("retirementIncome");
    const plans = useSelector((state) => state.participant.plans);
    const deferrals = useSelector((state) => state.participant.deferrals);

    const hsaDeferralInPlan = useMemo(
        () => hasHsaDeferralInPlan(plans, deferrals),
        [plans, deferrals]
    );

    return (
        <div className={`summaryBreakdown ${canShowSummary ? "multipleDeferrals" : ""}`}>
            <AddContributionButton
                handleShowAddDeferralModal={handleShowAddDeferralModal}
                availableDeferrals={availableDeferrals}
            />

            <ContributionSliders
                canShowSummary={canShowSummary}
                showSummaryExpansion={showSummaryExpansion}
                handleShowCsorModal={setShowCsorModal}
            />
            {hsaDeferralInPlan && translations.addContribution.deferralAlert && (
                <div className="alert alert-warning rivd-customDeferralAlert">
                    <p>{translations.addContribution.deferralAlert}</p>
                </div>
            )}
        </div>
    );
};

export default memo(SummarySliderBreakdown);
