import { find as _find, isNil as _isNil, round as _round } from "lodash";

import Constants from "../constants/Constants";
import ObjectUtil from "../utils/ObjectUtil";

export const RETIREMENT_INCOME_CODE = "retirementIncome";
export const MY_CONTRIBUTIONS_CODE = "myContributions";
export const EMPLOYER_CONTRIBUTIONS_CODE = "employerContributions";
export const OTHER_ASSETS_CODE = "otherAssets";
export const GOVERNMENT_BENEFITS_CODE = "governmentBenefits";
export const HSA_CODE = "hsa";
export const INCOME_GAP_CODE = "incomeGap";

export const getLoadingRadialChartSegments = () => {
    return [
        {
            code: RETIREMENT_INCOME_CODE,
            class: `${RETIREMENT_INCOME_CODE}-color`,
            percent: 100,
            sortOrder: 1
        }
    ];
};

export const getRadialChartSegmentsFromIncomeParts = (
    incomeParts,
    splitEmployerContributions = false
) => {
    // These are set in the ProjectedIncomeResponseFactory
    const myContributionIncomePart = _find(incomeParts, (incomePart) => {
        return incomePart.id === MY_CONTRIBUTIONS_CODE;
    });

    const employerContributionIncomePart = _find(incomeParts, (incomePart) => {
        return incomePart.id === EMPLOYER_CONTRIBUTIONS_CODE;
    });

    const otherAssetsIncomePart = _find(incomeParts, (incomePart) => {
        return incomePart.id === OTHER_ASSETS_CODE;
    });

    const governmentBenefitsIncomePart = _find(incomeParts, (incomePart) => {
        return incomePart.id === GOVERNMENT_BENEFITS_CODE;
    });

    const hsaIncomePart = _find(incomeParts, (incomePart) => {
        return incomePart.id === HSA_CODE;
    });

    const incomeGapIncomePart = _find(incomeParts, (incomePart) => {
        return incomePart.id === INCOME_GAP_CODE;
    });

    // Check that they exists (HSA is optional)
    if (!myContributionIncomePart) {
        throw new Error("Missing myContribution income part.");
    }
    if (!employerContributionIncomePart) {
        throw new Error("Missing employerContribution income part.");
    }
    if (!otherAssetsIncomePart) {
        throw new Error("Missing otherAssets income part.");
    }
    if (!governmentBenefitsIncomePart) {
        throw new Error("Missing governmentBenefits income part.");
    }
    if (!incomeGapIncomePart) {
        throw new Error("Missing incomeGap income part.");
    }

    const totalIncome = incomeParts.reduce((sum, incomePart) => {
        return sum + incomePart.value;
    }, 0.0);

    const radialChartSegments = [];

    // my contributions and employer contributions are combined together and go first in rivd
    // with redwood design we want to split these out on the chart. splitEmployerContributions flag will determine this
    if (splitEmployerContributions) {
        radialChartSegments.push({
            code: RETIREMENT_INCOME_CODE,
            class: `${RETIREMENT_INCOME_CODE}-color`,
            percent: (myContributionIncomePart.value / totalIncome) * 100,
            sortOrder: 0
        });

        radialChartSegments.push({
            code: RETIREMENT_INCOME_CODE,
            class: `${EMPLOYER_CONTRIBUTIONS_CODE}-color`,
            percent: (employerContributionIncomePart.value / totalIncome) * 100,
            sortOrder: 1
        });
    } else {
        radialChartSegments.push({
            code: RETIREMENT_INCOME_CODE,
            class: `${RETIREMENT_INCOME_CODE}-color`,
            percent:
                ((myContributionIncomePart.value + employerContributionIncomePart.value) /
                    totalIncome) *
                100,
            sortOrder: 1
        });
    }

    // hsa goes next.  it is optional
    if (hsaIncomePart) {
        radialChartSegments.push({
            code: HSA_CODE,
            class: `${HSA_CODE}-color`,
            percent: (hsaIncomePart.value / totalIncome) * 100,
            sortOrder: 2
        });
    }

    // social security is next
    radialChartSegments.push({
        code: GOVERNMENT_BENEFITS_CODE,
        class: `${GOVERNMENT_BENEFITS_CODE}-color`,
        percent: (governmentBenefitsIncomePart.value / totalIncome) * 100,
        sortOrder: 3
    });

    // other assets
    radialChartSegments.push({
        code: OTHER_ASSETS_CODE,
        class: `${OTHER_ASSETS_CODE}-color`,
        percent: (otherAssetsIncomePart.value / totalIncome) * 100,
        sortOrder: 4
    });

    // income gap
    radialChartSegments.push({
        code: INCOME_GAP_CODE,
        class: `${INCOME_GAP_CODE}-color`,
        percent: (incomeGapIncomePart.value / totalIncome) * 100,
        sortOrder: 5
    });

    return radialChartSegments;
};

export const calculateGoal = (portion, salary, period, isPercent) => {
    salary = typeof salary === "string" ? Number(salary) : salary;
    portion = typeof portion === "string" ? Number(portion) : portion;

    switch (period) {
        case "yearly":
            return _round(isPercent ? (portion / 100) * salary : portion, 2);
        case "monthly":
            return _round(isPercent ? ((portion / 100) * salary) / 12 : portion / 12, 2);
    }
};

export const getResponseFromData = (config, data = null) => {
    let spousePersonId, spouseInvestorId, spouseLifeExpectancy;
    const response = {
        salaryUpdateRequired: false,
        variableUpdateRequired: false,
        percentageOfSalaryUpdateRequired: false,
        dateOfBirthUpdateRequired: false,
        updateProjectedIncomeNeeded: false,
        //spouse
        spousalPercentageOfSalaryUpdateRequired: false,
        spousalSalaryUpdateRequired: false,
        spousalDateOfBirthUpdateRequired: false,
        spousalNameUpdateRequired: false,
        spousalGenderUpdateRequired: false,
        spousalRetAgeUpdateRequired: false,
        spousalAgeUpdateRequired: false,
        spouseDeleted: {
            deleted: false,
            error: "",
            errorCode: ""
        },
        spousePersonId,
        spouseInvestorId,
        spouseLifeExpectancy
    };

    if (config.changeBooleans) {
        if (config.changeBooleans.newSpouseAdded && data) {
            // Lodash's each doesn't seem to work here
            data.forEach((item) => {
                response.spousePersonId = item.spData.pcapPersonId;
            });
        }
        response.salaryUpdateRequired = config.changeBooleans.salary;
        response.variableUpdateRequired = config.changeBooleans.additional;
        response.dateOfBirthUpdateRequired = config.changeBooleans.dob;
        response.percentageOfSalaryUpdateRequired = config.changeBooleans.incomeGoal;
        response.updateProjectedIncomeNeeded =
            config.changeBooleans.termOnly || !_isNil(config.confidence);
        response.updateMigratedAssetsNeeded = config.changeBooleans.termOnly;
        response.spouseAdded = config.changeBooleans.newSpouseAdded;
        response.spousalPercentageOfSalaryUpdateRequired = config.changeBooleans.spouseIncomeGoal;
        response.spousalSalaryUpdateRequired = config.changeBooleans.spouseSalary;
        response.spousalDateOfBirthUpdateRequired = config.changeBooleans.spouseDob;
        response.spousalNameUpdateRequired = config.changeBooleans.name;
        response.spousalGenderUpdateRequired = config.changeBooleans.gender;
        response.spousalRetAgeUpdateRequired = config.changeBooleans.retirementAge;
        response.spousalAgeUpdateRequired = config.changeBooleans.age;
        response.spouseDeleted.deleted = config.spouseDeleted;

        // Data is null when in emulator
        if (!_isNil(data)) {
            response.spouseDeleted.error = data[0].data.spHeader.errors
                ? data[0].data.spHeader.errors[0].message
                : "";
            response.spouseDeleted.errorCode = data[0].data.spHeader.errors
                ? data[0].data.spHeader.errors[0].code
                : "";
        }
    }

    return response;
};

export const createUpdatePayload = (config, individualId) => {
    let userStructure = {};

    if (config.retirementAge) {
        userStructure.pcapPersonId = String(config.pcapPersonId);
        userStructure.retirementAge = config.retirementAge;
    }

    if (config.confidence) {
        userStructure.confidence = config.confidence;
    }

    if (config.userInfo) {
        const context = {
            indId: individualId,
            accessCustomization: window.accu
        };

        userStructure = {
            pcapPersonId: config.user
                ? String(config.user.personId)
                : String(config.userInfo.personId),
            salary: config.userInfo.base,
            retirementIncomeGoal: {
                incomeGoal: config.userInfo.incomeGoalValue,
                incomeGoalType: Constants.INTEGRATED_TYPE_MAP[config.userInfo.incomeGoalValueUnits],
                incomeGoalSource: "USER_INPUT"
            }
        };

        if (config.userInfo.type === "USER") {
            if (config.changeBooleans.salary || config.changeBooleans.additional) {
                userStructure.context = context;
            }
        }

        if (config.changeBooleans.additional) {
            userStructure.additionalCompensation = config.userInfo.variable;
        }

        if (config.changeBooleans.gender) {
            userStructure.gender = Constants.INTEGRATED_GENDER_MAP[config.userInfo.gender];
        }

        if (config.userInfo.dateOfBirth) {
            userStructure.dateOfBirth = config.userInfo.dateOfBirth;
        }

        if (config.changeBooleans.name) {
            userStructure.name = {
                firstName: config.userInfo.firstName
            };
        }

        if (config.changeBooleans.retirementAge) {
            userStructure.retirementAge = config.userInfo.retirementAge;
        }
    }

    return userStructure;
};

export const createSpousePayload = (config) => {
    const spouseStructure = {
        relationship: "SPOUSE",
        name: {
            firstName: config.firstName
        },
        dateOfBirth: config.dateOfBirth,
        salary: config.salary,
        retirementAge: config.retirementAge,
        gender: Constants.INTEGRATED_GENDER_MAP[config.gender],
        retirementIncomeGoal: {
            incomeGoal: config.incomeGoalValue,
            incomeGoalType: Constants.INTEGRATED_TYPE_MAP[config.incomeGoalValueUnits],
            incomeGoalSource: "USER_INPUT"
        }
    };

    return spouseStructure;
};

export const hasTermConfidenceChangesOnly = (changeBooleans) => {
    let hasChanges = false;

    if (changeBooleans.termOnly || changeBooleans.confidence) {
        hasChanges = true;
    }

    Object.keys(changeBooleans).forEach((key) => {
        if (key !== "term" && key !== "confidence") {
            if (changeBooleans[key] && hasChanges) {
                hasChanges = false;
            }
        }
    });

    return hasChanges;
};

export const showSalaryMessage = (salary, rules) => {
    const { total, source } = salary;
    const totalGtZero = !ObjectUtil.isNull(total) && total > 0;

    return totalGtZero && source !== "PARTNER" && rules.allowSalaryReset;
};
