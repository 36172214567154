import {
    SET_BASELINE_PROJECTED_INCOME,
    SET_BASELINE_RETIREMENT_AGE,
    SET_BASELINE_EQUITY_MIX,
    SET_BASELINE_DEFERRALS,
    SET_BASELINE_SALARY
} from "./baselineTypes";

/**
 * ACTIONS FOUND IN THIS FILE
 * @setParticipantBaseline
 * @setBaselineProjectedIncome
 * @setBaselineRetirementAge
 * @setBaselineEquityMix
 * @setBaselineDeferrals
 * @setBaselineSalary
 */

export const setParticipantBaseline = (user) => {
    const { retirementAge, deferrals, salary } = user;
    return (dispatch) => {
        dispatch(setBaselineRetirementAge(retirementAge));
        dispatch(setBaselineDeferrals(deferrals));
        dispatch(setBaselineSalary(salary));
    };
};

export const setBaselineProjectedIncome = (payload) => {
    return {
        type: SET_BASELINE_PROJECTED_INCOME,
        payload
    };
};

export const setBaselineRetirementAge = (payload) => {
    return {
        type: SET_BASELINE_RETIREMENT_AGE,
        payload
    };
};

export const setBaselineEquityMix = (payload) => {
    return {
        type: SET_BASELINE_EQUITY_MIX,
        payload
    };
};

export const setBaselineDeferrals = (payload) => {
    return {
        type: SET_BASELINE_DEFERRALS,
        payload
    };
};

export const setBaselineSalary = (payload) => {
    return {
        type: SET_BASELINE_SALARY,
        payload
    };
};
