/**
 *
 * @name DeferralMoneyTypes
 * @memberOf common.model.constants
 * @date 02/09/2015
 * @copyright 2015 Empower Retirement
 * @description This module encapsulates deferral money type codes.
 * @property {string} DOLLAR "dollars"
 * @property {string} PERCENT "percent"
 *
 */

"use strict";

export default {
    DOLLAR: "dollars",
    PERCENT: "percent",
    PCT: "percent",
    AMT: "dollars"
};
