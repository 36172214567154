import { useEffect } from "react";

import { useSelector, useDispatch } from "../../hooks/use-redux-actions";
import { loadHealthViewData } from "../../middleware/healthCareMiddleware";
import { canShowHealthCareView } from "../../selectors/featureFlagSelectors";

const useHealthcareData = () => {
    const dispatch = useDispatch();

    const participant = useSelector((state) => state.participant);
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const showHealthcareView = canShowHealthCareView(participant, primaryPlan);

    const { attainedAge, healthCareDataLoaded, showLoader, pendingChanges } = useSelector(
        (state) => state.healthCare
    );

    useEffect(() => {
        // if user has can show healthcare view refetch data if undefined
        if (showHealthcareView && !healthCareDataLoaded) {
            dispatch(loadHealthViewData());
        }
    }, [showHealthcareView, healthCareDataLoaded, dispatch]);

    return {
        showHealthcareView,
        attainedAge,
        healthCareDataLoaded,
        showLoader,
        pendingChanges
    };
};

export default useHealthcareData;
