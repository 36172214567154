import React from "react";

import { Loader } from "gw-shared-components";
import { useSelector } from "react-redux";

import IntegratedOtherAssets from "../components/otherAssets/IntegratedOtherAssets";
import { useAccountSummary } from "../queries";

const OtherAssetsControls = () => {
    const irsLimitsRefData = useSelector((state) => state.irsLimitsRefData);
    const participant = useSelector((state) => state.participant);
    const applicationSettings = useSelector((state) => state.applicationSettings);
    const otherAssets = useSelector((state) => state.otherAssets);
    const spouse = useSelector((state) => state.spouse);

    const { data: accountSummary, isLoading: accountSummaryLoading } = useAccountSummary();

    let integratedIsLoaded =
        otherAssets.dataIsLoaded && participant.dataIsLoaded && irsLimitsRefData.dataIsLoaded;

    if (spouse.exists) {
        integratedIsLoaded = integratedIsLoaded && spouse.dataIsLoaded;
    }

    if (accountSummaryLoading || !integratedIsLoaded || !applicationSettings.dataIsLoaded) {
        return (
            <div role="tabpanel" data-testid="other-assets-controls">
                <Loader useSvg />
            </div>
        );
    }

    return (
        <div role="tabpanel" data-testid="other-assets-controls">
            <IntegratedOtherAssets accountSummary={accountSummary} />
        </div>
    );
};

export default OtherAssetsControls;
