import { isArrayEmpty, isUndefinedOrNull } from "gw-shared-components";
import _filter from "lodash/filter";

import { getMigratedAssetIncome } from "../services/otherAssetsService";
import { formatTimestamp, getDateString } from "../utils/dateUtils";

/**
 * @description determine if participant has a spouse
 * @param {object} spouse
 * @return {boolean}
 */
export const participantHasSpouse = (spouse) => {
    if (isUndefinedOrNull(spouse)) return false;
    return spouse.dataIsLoaded && spouse.exists;
};

/**
 * @description determine if investor id is the participant
 * @param {object} participant
 * @param {number} investorId
 * @return {boolean}
 */
export const isParticipantInvestorId = (participant, investorId) => {
    if (isUndefinedOrNull(participant) || isUndefinedOrNull(investorId)) return false;
    return investorId === participant.investorId;
};

/**
 * For integrated users, use personId
 * @param {*} participant
 * @param {*} investorId
 * @returns {boolean}
 */
export const isParticipantPersonId = (participant, investorId) => {
    if (isUndefinedOrNull(participant) || isUndefinedOrNull(investorId)) return false;
    return investorId === participant.personId;
};

/**
 * @description remove external asset
 * @param {array} array
 * @param {object} asset containing retirementAccountId and id
 * @return {array}
 */
export const removeAssetFromArray = (array, asset) => {
    if (isArrayEmpty(array)) return [];
    return array.filter(
        (item) => item.id !== asset.id && item.retirementAccountId !== asset.retirementAccountId
    );
};

/**
 * @description find asset in array with same id and update asset
 * @param {array} array
 * @param {object} asset
 * @return {array}
 */
export const updateAssetBasedOnID = (array, updatedAsset) => {
    if (isArrayEmpty(array)) return [];
    return array.map((asset) => {
        if (asset.id === updatedAsset.id) {
            return updatedAsset;
        } else {
            return asset;
        }
    });
};

/**
 * @description find asset in array with same investorAccountId and update asset
 * @param {array} array
 * @param {object} asset
 * @return {array}
 */
export const updateAssetBasedOnInvestorAccountId = (array, payload) => {
    if (isArrayEmpty(array)) return [payload];
    let replaced = false;
    const updated = array.map((asset) => {
        if (payload.investorAccountId === asset.investorAccountId) {
            replaced = true;
            return payload;
        } else {
            return asset;
        }
    });

    if (!replaced) {
        updated.push(payload);
    }

    return updated;
};

/**
 * @description remove retirement asset
 * @param {array} array
 * @param {number} investorAccountId
 * @return {array}
 */
export const removeRetirementAsset = (array, investorAccountId) => {
    if (isArrayEmpty(array)) return [];
    return array.filter((item) => item.investorAccountId !== investorAccountId);
};

const getUserSummaryDetails = (config) => {
    const details = {
        firstName: "",
        birthDate: "",
        retirementAgeDetails: [],
        retirementAge: null
    };

    if (config) {
        details.firstName = config.firstName;
        details.birthDate = config.birthDate;
        details.retirementAgeDetails = config.retirementAgeDetails;
        details.retirementAge = config.retirementAge;
    }

    return { ...config, ...details };
};

export const getUserSummary = (participant, spouse, hasSpouse) => {
    let spouseDetails = null;

    const personConfig = {
        ...participant,
        birthDate: getDateString(participant.dateOfBirth),
        retirementAgeDetails: populateRetirementAgeDetails(
            getDateString(participant.dateOfBirth),
            participant.age,
            participant.retirementAge
        )
    };

    const personDetails = getUserSummaryDetails(personConfig);
    personDetails.hasSpouse = hasSpouse;

    if (hasSpouse) {
        const spouseConfig = {
            ...spouse,
            birthDate: getDateString(spouse.dateOfBirth),
            retirementAgeDetails: populateRetirementAgeDetails(
                getDateString(spouse.dateOfBirth),
                spouse.age,
                spouse.retirementAge
            )
        };
        spouseDetails = getUserSummaryDetails(spouseConfig);
        spouseDetails.spouseRetirementAge = spouse.retirementAge; // ma-ui references spouseRetirementAge
    }

    const userSummary = {
        personDetails,
        familyDetails: {
            spouse: spouseDetails
        }
    };

    return userSummary;
};

export const populateRetirementAgeDetails = (dateOfBirth, currentAge, retireAge) => {
    const retirementAgeDetails = [];
    const dob = formatTimestamp(dateOfBirth);
    let retireYear;

    for (let age = currentAge; age <= 114; age++) {
        retirementAgeDetails.push({ age: age, year: dob.year() + age });
        if (age === retireAge) {
            retireYear = dob.year() + age;
        }
    }

    // replace index 0 with retirement age
    retirementAgeDetails[0] = {
        age: retireAge,
        year: retireYear
    };

    return retirementAgeDetails;
};

export const getFilteredOutsideAssets = (outsideAssets, storeState) => {
    let externalAssets = _filter(outsideAssets, function (o) {
        return o.assetType === "externalAsset";
    });

    let retirementAssets = _filter(outsideAssets, function (o) {
        return o.assetType === "retirementIncome";
    });

    let linkedAccountBalances = _filter(outsideAssets, function (o) {
        if (o.assetType === "OAB_PLAN" || o.assetType === "OAB_TRS") {
            return o;
        }
    });

    if (externalAssets.length > 0) {
        externalAssets.map(function (item) {
            item.monthlyIncomeEST = getMigratedAssetIncome(item, storeState);
        });
    } else {
        externalAssets = [];
    }

    if (retirementAssets.length > 0) {
        retirementAssets.map(function (item) {
            item.monthlyIncomeEST = getMigratedAssetIncome(item, storeState);
        });
    } else {
        retirementAssets = [];
    }

    if (linkedAccountBalances.length > 0) {
        linkedAccountBalances.map(function (item) {
            let result = {
                value: 0,
                income: 0
            };
            result = getMigratedAssetIncome(item, storeState);
            if (item.assetType === "OAB_TRS") {
                item.value = result.value;
                item.monthlyIncomeEST = result.income;
            } else {
                item.monthlyIncomeEST = result;
            }
        });
    } else {
        linkedAccountBalances = [];
    }

    const filteredOutsideAssets = {
        externalAssets,
        retirementAssets,
        linkedAccountBalances
    };
    return filteredOutsideAssets;
};

/**
 * @description find the asset by matching the Account Agg Ids
 * @param {number} investorAccountId
 * @param {array} allAssets
 * @return {object}
 */
export const getMatchingAcctAggIds = function (accID, allAssets) {
    if (isUndefinedOrNull(accID) || isUndefinedOrNull(allAssets)) return null;
    const id = typeof accID === Number ? accID : parseInt(accID);
    return allAssets.find((asset) => asset.acctAggrId === id);
};
