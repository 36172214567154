import { isUndefinedOrNull } from "gw-shared-components";
import { each as _each } from "lodash";

import IrsCodes from "../../constants/IrsCodes";
import MatchRuleTypes from "../../constants/MatchRuleTypes";

export const getPlanIrsCodeForDisplay = (irsCode) => {
    return IrsCodes[irsCode] || irsCode;
};

export const isCompanyMatchTypeCsor = (plan) => {
    return (
        plan.companyMatch &&
        plan.companyMatch.ruleTypes.indexOf(MatchRuleTypes.COMPANY_MATCH_CSOR) > -1
    );
};

export const isRuleEmployerMatch = (rule) => {
    return rule.calcType === MatchRuleTypes.COMPANY_MATCH;
};

export const isRuleProfitSharing = (rule) => {
    return rule.calcType === MatchRuleTypes.PROFIT_SHARING;
};

export const getHasPSDescriptionsOfTypeForPlan = function (index, plans) {
    function ruleHasDescription(rule) {
        return !isUndefinedOrNull(rule.description) && rule.description !== "";
    }

    function ruleHasDesc(rule) {
        return !isUndefinedOrNull(rule.ruleDesc) && rule.ruleDesc !== "";
    }

    let hasMatchDescription = false;
    _each(plans[index].employerMatch, function (rule) {
        if (rule.calcType === MatchRuleTypes.PROFIT_SHARING && ruleHasDescription(rule)) {
            hasMatchDescription = true;
        }
    });

    if (!isUndefinedOrNull(plans[index].companyMatch)) {
        const plan = plans[index];
        _each(plan.companyMatch.matchTiers, function (tiers) {
            _each(tiers, function (rule) {
                if (rule.profitSharing === "Y" && ruleHasDesc(rule)) {
                    hasMatchDescription = true;
                }
            });
        });
    }

    return hasMatchDescription;
};

export const getProfitSharingDescriptionsExistForPlan = (index, plans) => {
    return getHasPSDescriptionsOfTypeForPlan(index, plans);
};
