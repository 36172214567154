"use strict";
/**
 * @name app.infrastructure.domainModel.DeferralLimits
 * @date 1/26/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Definition of the DeferralLimits domain model object.
 */

const DeferralLimits = function (config) {
    this.maxDeferral = 75;
    this.minDeferral = 0;
    this.maxSlider = 1;
    this.granularity = null;

    if (config) {
        this.maxDeferral = config.maxDeferral;
        this.minDeferral = config.minDeferral;
        this.maxSlider = config.maxSlider;
        this.granularity = config.granularity;
    }
};

export default DeferralLimits;
