import {
    formatTimingPrefix,
    DiagnosticsConstants,
    determineLoginFlow,
    LoginFlowEvents
} from "./common";
import { getLiatAppStartTime } from "./getEvents";
import { sendMetricEventMFA, sendMetricEvent } from "./sendEvents";

const measurementName = "Quickview";

const timingPrefix = formatTimingPrefix();

const sentryQuickviewRenderMetrics = () => {
    const renderQuickView = new Date().getTime();

    const renderQuickViewStartTimeExists = sessionStorage.getItem(
        `${timingPrefix}${DiagnosticsConstants.RENDER_QUICKVIEW}`
    );

    /**
     * With this logic we will check to see if we have set the quick view metrics in the current session.
     * If we have not then proceed with getting and setting the data.
     */
    if (!renderQuickViewStartTimeExists) {
        const liatAppStartTime = getLiatAppStartTime();

        const renderQuickViewDelta = renderQuickView - Number(liatAppStartTime);

        sessionStorage.setItem(
            timingPrefix + DiagnosticsConstants.RENDER_QUICKVIEW,
            String(renderQuickView)
        );
        sessionStorage.setItem(
            timingPrefix + DiagnosticsConstants.RENDER_QUICKVIEW + "Delta",
            String(renderQuickViewDelta)
        );

        const { loginLastHash, flowName, startHash, endTime } = determineLoginFlow();
        // If true, we are in the MFA Sign in login flow
        if (flowName === LoginFlowEvents.MFA_LOGIN) {
            // Measure from when the user clicked mfa sign in to when paycheck renders
            const timeToBalanceFromVerify = renderQuickView - endTime;

            sendMetricEventMFA({
                measurementName,
                startHash,
                loginLastHash,
                endTime,
                startTime: renderQuickView,
                name: DiagnosticsConstants.MFA.TIME_TO_BALANCE,
                timeToLoad: timeToBalanceFromVerify
            });
            // If true, we are in the DF login flow
        } else if (flowName === LoginFlowEvents.DF_LOGIN) {
            // Measure from when the user clicked login to when they can see the LIS score
            const timeToBalanceFromCLick = renderQuickView - endTime;

            sendMetricEvent({
                measurementName,
                startHash,
                loginLastHash,
                endTime,
                startTime: renderQuickView,
                name: DiagnosticsConstants.DF.TIME_TO_BALANCE,
                timeToLoad: timeToBalanceFromCLick
            });
            // If true, we are in the SSO login flow
        } else if (flowName === LoginFlowEvents.SSO_LOGIN) {
            const timeToBalanceFromSSO = renderQuickView - endTime;

            sendMetricEvent({
                measurementName,
                startHash,
                loginLastHash,
                endTime,
                startTime: renderQuickView,
                name: DiagnosticsConstants.SSO.TIME_TO_BALANCE,
                timeToLoad: timeToBalanceFromSSO
            });
        } // Collect the registration flow data points
        else if (loginLastHash && DiagnosticsConstants[loginLastHash]) {
            sendMetricEvent({
                name: `${DiagnosticsConstants.TIME_TO_BALANCE}_${DiagnosticsConstants[loginLastHash]}`,
                timeToLoad: renderQuickViewDelta,
                measurementName,
                startTime: renderQuickView,
                endTime: Number(liatAppStartTime),
                loginLastHash
            });
        }

        // We will keep this as a common metric independent of any login flow
        sendMetricEvent({
            name: DiagnosticsConstants.TIME_TO_BALANCE,
            timeToLoad: renderQuickViewDelta,
            measurementName,
            startTime: renderQuickView,
            endTime: Number(liatAppStartTime),
            loginLastHash
        });
    }
};

export default sentryQuickviewRenderMetrics;
