import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";

import { useSelector } from "../../../../hooks/use-redux-actions";

const PremierIRA = () => {
    const insightsWidgetLoaded = useSelector(
        (state) => state.shared.loadStatus.insightsWidgetLoaded
    );
    const loadClass = /*!insightsHasPriority ||*/ !insightsWidgetLoaded ? "" : "loaded";

    const translations = selectTranslations("retirementIncome");

    return (
        <>
            {insightsWidgetLoaded && (
                <span className="header" style={{ display: "flex" }} data-testid="insights-header">
                    {translations.insights}
                </span>
            )}
            <div
                id="insights-widget"
                data-testid="insights-widget"
                className={`use-fade-in rounded-border bg-white insights-widget--container  ${loadClass}`}
            />
        </>
    );
};

export default PremierIRA;
