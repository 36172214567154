const TABS = {
    RETIREMENT_INCOME: "Retirement income",
    HEALTHCARE_COSTS: "Health care costs",
    HOW_DO_I_COMPARE: "How do I compare",
    PRE_RETIREMENT: "Pre-retirement",
    SOCIAL_SECURITY: "Social Security",
    OTHER_ASSETS: "Other Assets",
    INCOME_GAP: "Income Gap",
    HSA: "Health Savings Account",
    MAXIMIZER: "Maximizer",
    WHEN_CAN_I_RETIRE: "When can I retire"
};

export default TABS;
