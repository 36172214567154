import { useEffect, useCallback } from "react";

import { setLoadStatus } from "../../../../actions/shared/sharedActionCreators";
import LoggerFactory from "../../../../factories/LoggerFactory";
import { useDispatch } from "../../../../hooks/use-redux-actions";

type LoadStatusName =
    | "sidebarLoaded"
    | "compMeterLoaded"
    | "bottomWidgetsLoaded"
    | "insightsWidgetLoaded"
    | "aceBlockLoaded"
    | "";

const usePcComponentLoaded = () => {
    const dispatch = useDispatch();
    const logger = LoggerFactory.getInstance("IntegratedContainer:");

    const pcComponentLoaded = useCallback(
        (e: CustomEvent) => {
            const componentName: string = e.detail.component;
            let loadStatusName: LoadStatusName = "";

            switch (componentName) {
                case "sidebar":
                    loadStatusName = "sidebarLoaded";
                    break;
                case "completeness-meter":
                    loadStatusName = "compMeterLoaded";
                    break;
                case "dashboard-widgets":
                    loadStatusName = "bottomWidgetsLoaded";
                    break;
                case "insights-widget":
                    loadStatusName = "insightsWidgetLoaded";
                    break;
                case "ace-block":
                    loadStatusName = "aceBlockLoaded";
                    break;
            }

            logger.debug("pc_component_loaded: " + loadStatusName);

            dispatch(setLoadStatus({ [loadStatusName]: true }));
        },
        [dispatch, logger]
    );

    useEffect(() => {
        window.addEventListener("pc_component_loaded", pcComponentLoaded as EventListener);
        return () =>
            window.removeEventListener("pc_component_loaded", pcComponentLoaded as EventListener);
    }, [pcComponentLoaded]);
};

export default usePcComponentLoaded;
