import { AxiosResponse } from "axios";

import { executeGet } from "../utils/apiUtils.js";
import { getUrl } from "../utils/urlUtils.js";

type Reasons = Record<string, string>;
// When the service is failing it will still return a 200 with reasons populated
interface ShowLiatResponse {
    eligible: boolean;
    reasons: Reasons;
}

/*
 Do we have specifc api endpoint for just the users or only from getHouseholdInitData?
 Without a service to fetch the existing users we can call getInitData for now.
 */
const getShowLiat = async (): Promise<boolean> => {
    try {
        const url = getUrl("getShowLiat");
        const response: AxiosResponse<ShowLiatResponse> = await executeGet(
            url,
            "getCheckPaeUserFault"
        );
        return response.data.eligible;
    } catch (err) {
        console.error("Error with service getShowLiat: ", err);
        throw err;
    }
};

export default getShowLiat;
