import React from "react";

import ReferenceData from "../../constants/ReferenceData";

interface ButtonLabelProps {
    label: string;
    professionalManagementProgramName: string;
}

const ButtonLabel = ({ label, professionalManagementProgramName }: ButtonLabelProps) => {
    return (
        <span className="btn-label">
            {label.replace(ReferenceData.PROGRAM_NAME_KEY, professionalManagementProgramName)}
        </span>
    );
};

export default ButtonLabel;
