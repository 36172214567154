import React, { useEffect, useState, memo } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { ObjectUtil } from "gw-shared-components";
import { uniq as _uniq, find as _find, findIndex as _findIndex } from "lodash";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import {
    RETIREMENT_INCOME_ROUTE,
    SOCIAL_SECURITY_ROUTE,
    OTHER_ASSETS_ROUTE,
    INCOME_GAP_ROUTE,
    HSA_ROUTE
} from "../../../routes";
import { setPlansWithMyFutureContribution } from "../../actions/paycheck/paycheckActions";
import {
    getEmployerOtherAssets,
    getEmployerMatchExistsForPlan,
    getProfitSharingExistsForPlan,
    getOtherAssetsDisplayFlag
} from "../../business/paycheck";
import { getDisplayOtherAssets, canShowIncomeGap } from "../../selectors/featureFlagSelectors";

import PaycheckItemDetails from "./PaycheckItemDetails";
import PaycheckOptionDetails from "./PaycheckOptionDetails";

const INCOME_PART_CSS = {
    myCurrentSavings: "section-savings-marker",
    myFutureSavings: "section-savings-marker",
    employerPastContributions: "employer-contributions-marker",
    employerFutureContributions: "employer-contributions-marker",
    hsa: "section-hsa-marker",
    governmentBenefits: "section-social-security-marker",
    otherAssets: "section-other-assets-marker",
    incomeGap: "section-income-gap-marker"
};

const PaycheckDetails = ({ isMobileView, open = false }) => {
    const dispatch = useDispatch();
    const incomePartsTranslations = selectTranslations("incomeParts");
    const viewDetailsToolTipsTranslations = selectTranslations("viewDetailsToolTips");

    const participant = useSelector((state) => state.participant);
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const showPaycheckViewDetails =
        useSelector((state) => state.paycheck.showPaycheckViewDetails) || open;
    const showMyCurrentSavingPlansTooltip = useSelector(
        (state) => state.paycheck.showMyCurrentSavingPlansTooltip
    );
    const showMyFutureSavingPlansTooltip = useSelector(
        (state) => state.paycheck.showMyFutureSavingPlansTooltip
    );
    const showEmployerPastContributionTooltip = useSelector(
        (state) => state.paycheck.showEmployerPastContributionTooltip
    );
    const showEmployerFutureContributionsTooltip = useSelector(
        (state) => state.paycheck.showEmployerFutureContributionsTooltip
    );
    const myFutureSavingPlans = useSelector((state) => state.paycheck.myFutureSavingPlans);
    const myCurrentSavingPlans = useSelector((state) => state.paycheck.myCurrentSavingPlans);

    const assetsWithEmployerFuture = useSelector((state) => state.otherAssets.employerFutureAssets);
    const employerPastContribution = useSelector(
        (state) => state.paycheck.employerPastContribution
    );
    const employerFutureContributions = useSelector(
        (state) => state.paycheck.employerFutureContributions
    );

    const plansWithEmployerFuture = useSelector((state) => state.paycheck.plansWithEmployerFuture);

    const plansWithMyFutureContributions = useSelector(
        (state) => state.paycheck.plansWithMyFutureContributions
    );
    const deferralSetupConfig = useSelector((state) => state.deferralSetupConfig);
    const projectedIncome = useSelector((state) => state.projectedIncome);

    const applicationSettings = useSelector(
        (state) => state.applicationSettings.projectionSettings
    );
    const govtBenefitsAllowed =
        applicationSettings.includeSocialSecurity && primaryPlan.planRules.ssEnabled;

    const [paycheckPlansWithMyFutureContribution, setPaycheckPlansWithMyFutureContribution] =
        useState(null);

    /**
     * Error: A state mutation was detected between dispatches, in the path 'paycheck.plansWithMyFutureContributions.0'.
     * This may cause incorrect behavior. (https://redux.js.org/style-guide/style-guide#do-not-mutate-state).
     */
    useEffect(() => {
        if (paycheckPlansWithMyFutureContribution) {
            dispatch(setPlansWithMyFutureContribution(paycheckPlansWithMyFutureContribution));
        }
    }, [dispatch, paycheckPlansWithMyFutureContribution]);

    const getIncomePart = (id) => {
        //$log.log("getIncomePart({0})", [ id ]);
        // NOTE: Only uncomment this for dev, but do not leave in for prod as it logs quite a bit for AJS digest cycle's data binding
        const ip = _find(projectedIncome.incomeParts, { id: id });
        let planIndx = -1;

        if (!ObjectUtil.isUndefinedOrNull(ip) && id === "myContributions") {
            const mf = plansWithMyFutureContributions;

            // check if this plan has already been loaded
            planIndx = _findIndex(plansWithMyFutureContributions, function (o) {
                return o.id === primaryPlan.id;
            });

            if (deferralSetupConfig.deferralSummaryTotal >= 0) {
                if (planIndx === -1) {
                    mf.push(primaryPlan);
                    setPaycheckPlansWithMyFutureContribution(_uniq(mf));
                }
            } else {
                if (planIndx === -1 && mf.length > 0) {
                    setPaycheckPlansWithMyFutureContribution(mf);
                }
            }
        }

        return ip;
    };

    const getFutureEmployerExists = () => {
        let nonZeroEmployerFuture = false;
        const employerContributions = getIncomePart("employerContributions");
        if (
            employerContributions &&
            employerContributions.details &&
            employerContributions.details.future
        ) {
            nonZeroEmployerFuture = employerContributions.details.future.value > 0.01;
        }
        const planList = plansWithEmployerFuture;
        const hasEmployerFutureBalance = planList && planList.length > 0;
        return (
            nonZeroEmployerFuture &&
            (getEmployerMatchExistsForPlan(0) ||
                getEmployerOtherAssets(assetsWithEmployerFuture).length > 0 ||
                getProfitSharingExistsForPlan(0) ||
                hasEmployerFutureBalance)
        );
    };

    const myContribution = getIncomePart("myContributions");
    const myCurrentContribution = myContribution ? myContribution.details.current.value : 0;
    const myFutureContribution = myContribution ? myContribution.details.future.value : 0;

    const myEmployerContribution = getIncomePart("employerContributions");
    const myCurrentEmployerContribution = myEmployerContribution
        ? myEmployerContribution.details.current.value
        : 0;
    const myFutureEmployerContribution = myEmployerContribution
        ? myEmployerContribution.details.future.value
        : 0;

    const myHsa = getIncomePart("hsa");
    const myCurrentHsaContribution = myHsa ? myHsa.details.currentHSAContributions.value : 0;
    const myFutureHsaContribution = myHsa ? myHsa.details.futureHSAContributions.value : 0;

    const mySocSec = getIncomePart("governmentBenefits");
    const mySocSecValue = mySocSec ? mySocSec.value : 0;
    const mySocSecContribution = mySocSecValue > 0 && govtBenefitsAllowed ? mySocSecValue : "N/A";

    const myOtherAsset = getIncomePart("otherAssets");
    const myOtherAssetContributions = myOtherAsset ? myOtherAsset.value : 0;

    const myIncomeGap = getIncomePart("incomeGap");
    const myIncomeGapValue = myIncomeGap ? myIncomeGap.value : 0;

    const futureEmployerExists = getFutureEmployerExists();

    const showSegment = (id) => {
        // NOTE: Only uncomment this for dev, but do not leave in for prod as it logs quite a bit for AJS digest cycle's data binding
        // $log.debug("showSegment({0}", [ id ]);

        const incomePart = getIncomePart(id);
        //RI-5505 other assets slice shown even for $0 with min width
        if (id === "otherAssets") {
            // use declared myOtherAsset instead of calling function again
            return getOtherAssetsDisplayFlag(id, myOtherAsset, primaryPlan, participant.type);
        } else if (!incomePart || incomePart.value < 0.5) {
            //PW-1476 indicates that only non-zero values should ever be shown
            return false;
        } else if (id === "governmentBenefits") {
            // viewing social security has additional business logic
            // return users setting for SS and if the plan allows it
            return applicationSettings.includeSocialSecurity && primaryPlan.planRules.ssEnabled;
        } else {
            // everything else should be viewed if there is a non-zero amount
            return true;
        }
    };

    const mySavingLabel = incomePartsTranslations.myContributions;
    const employerContributionsLabel = incomePartsTranslations.employerContributions;
    const currentEELabel = incomePartsTranslations.currentEmployeeContributions;
    const futureEELabel = incomePartsTranslations.futureEmployeeContributions;
    const currentERLabel = incomePartsTranslations.currentEmployerContributions;
    const futureERLabel = incomePartsTranslations.futureEmployerContributions;

    const displayOtherAssets = getDisplayOtherAssets(participant, primaryPlan);
    const shouldShowOtherAssets =
        displayOtherAssets || (!displayOtherAssets && myOtherAssetContributions > 0);

    const paycheckDetailsList = [
        {
            id: "currentEE",
            label: currentEELabel,
            cssClassName: INCOME_PART_CSS["myCurrentSavings"],
            hasTooltip: showMyCurrentSavingPlansTooltip,
            tooltipTitle: viewDetailsToolTipsTranslations.title,
            tooltipContent: myCurrentSavingPlans,
            value: myCurrentContribution,
            routeTo: RETIREMENT_INCOME_ROUTE,
            display: true,
            hasPlan: false,
            disabled: false
        },
        {
            id: "futureEE",
            label: futureEELabel,
            cssClassName: INCOME_PART_CSS["myFutureSavings"],
            hasTooltip: showMyFutureSavingPlansTooltip,
            tooltipTitle: viewDetailsToolTipsTranslations.title,
            tooltipContent: myFutureSavingPlans,
            value: myFutureContribution,
            routeTo: RETIREMENT_INCOME_ROUTE,
            display: true,
            hasPlan: false,
            disabled: false
        },
        {
            id: "currentER",
            label: currentERLabel,
            cssClassName: INCOME_PART_CSS["employerPastContributions"],
            hasTooltip: showEmployerPastContributionTooltip,
            tooltipTitle: viewDetailsToolTipsTranslations.title,
            tooltipContent: employerPastContribution,
            value: myCurrentEmployerContribution,
            routeTo: RETIREMENT_INCOME_ROUTE,
            display: myEmployerContribution && myCurrentEmployerContribution > 0,
            hasPlan: false,
            disabled: false
        },
        {
            id: "futureER",
            label: futureERLabel,
            cssClassName: INCOME_PART_CSS["employerFutureContributions"],
            hasTooltip: showEmployerFutureContributionsTooltip,
            tooltipTitle: viewDetailsToolTipsTranslations.title,
            tooltipContent: employerFutureContributions,
            value: myFutureEmployerContribution,
            routeTo: RETIREMENT_INCOME_ROUTE,
            display:
                myEmployerContribution && myFutureEmployerContribution > 0 && futureEmployerExists,
            hasPlan: false,
            disabled: false
        },
        {
            id: "currentHSA",
            label: incomePartsTranslations.currentHsaSavings,
            cssClassName: INCOME_PART_CSS["hsa"],
            hasTooltip: null,
            tooltipTitle: "",
            tooltipContent: "",
            value: myCurrentHsaContribution,
            routeTo: HSA_ROUTE,
            display: myHsa && myCurrentHsaContribution > 0,
            hasPlan: myHsa,
            disabled: false
        },
        {
            id: "futureHSA",
            label: incomePartsTranslations.futureHsaSavings,
            cssClassName: INCOME_PART_CSS["hsa"],
            hasTooltip: null,
            tooltipTitle: "",
            tooltipContent: "",
            value: myFutureHsaContribution,
            routeTo: HSA_ROUTE,
            display: myHsa && myFutureHsaContribution > 0,
            hasPlan: myHsa,
            disabled: false
        },
        {
            id: "socialSecurity",
            label: incomePartsTranslations.governmentBenefits,
            cssClassName: INCOME_PART_CSS["governmentBenefits"],
            hasTooltip: null,
            tooltipTitle: "",
            tooltipContent: "",
            value: mySocSecContribution,
            routeTo: SOCIAL_SECURITY_ROUTE,
            display: true,
            hasPlan: false,
            disabled: false
        },
        {
            id: "otherAssets",
            label: incomePartsTranslations.otherAssets,
            cssClassName: INCOME_PART_CSS["otherAssets"],
            hasTooltip: null,
            tooltipTitle: "",
            tooltipContent: "",
            value: myOtherAssetContributions,
            routeTo: OTHER_ASSETS_ROUTE,
            display: shouldShowOtherAssets,
            hasPlan: false,
            disabled: !displayOtherAssets
        },
        {
            id: "incomeGap",
            label: incomePartsTranslations.incomeGap,
            cssClassName: INCOME_PART_CSS["incomeGap"],
            hasTooltip: null,
            tooltipTitle: "",
            tooltipContent: "",
            value: myIncomeGapValue,
            routeTo: INCOME_GAP_ROUTE,
            display: canShowIncomeGap(primaryPlan),
            hasPlan: false,
            disabled: !canShowIncomeGap(primaryPlan)
        }
    ];

    const renderViewWithTooltip = (item, indx) => {
        return (
            <PaycheckItemDetails
                key={item.id + indx}
                id={item.id + indx}
                label={item.label}
                value={item.value}
                cssClassName={item.cssClassName}
                hasTooltip={item.hasTooltip}
                tooltipTitle={item.tooltipTitle}
                tooltipContent={item.tooltipContent}
                showPaycheckViewDetails={showPaycheckViewDetails}
            />
        );
    };

    const renderWithNoTooltip = (item, indx) => {
        return (
            <PaycheckItemDetails
                key={item.id + indx}
                id={item.id + indx}
                label={item.label}
                value={item.value}
                cssClassName={item.cssClassName}
            />
        );
    };

    /**    <!-- My Contributions -->
            <!-- Always show, even if zero -->
            <!-- Future Employee Contributions -->
            <!-- Always show, even if zero -->
            <!-- Social Security -->
            <!-- Display N/A if not included or offered -->

            <!-- Other Assets -->
            <!-- Display if KTMG indicator is on -->
            <!-- Always show, even if zero -->

            <!-- Income Gap -->
            <!-- Always show, even if zero -->
    **/
    const renderDesktopTabletView = (details) => {
        return details.map((item, indx) => {
            switch (item.id) {
                case "currentEE":
                case "futureEE":
                    return renderViewWithTooltip(item, indx);
                /* <!-- Employer Past Contributions -->
                <!-- Do not display if zero --> */
                case "currentER":
                    if (myEmployerContribution && myCurrentEmployerContribution > 0) {
                        return renderViewWithTooltip(item, indx);
                    }
                    break;
                /* <!-- Employer Future Contributions -->
                <!-- Do not display if employer does not offer match, Do not display if zero --> */
                case "futureER":
                    if (
                        myEmployerContribution &&
                        myFutureEmployerContribution > 0 &&
                        futureEmployerExists
                    ) {
                        return renderViewWithTooltip(item, indx);
                    }
                    break;
                /* <!-- HSA -->
                <!-- Do not display if zero --> */
                case "currentHSA":
                    if (myHsa && myCurrentHsaContribution > 0) {
                        return renderWithNoTooltip(item, indx);
                    }
                    return;
                case "futureHSA":
                    if (myHsa && myFutureHsaContribution > 0) {
                        return renderWithNoTooltip(item, indx);
                    }
                    break;
                case "otherAssets":
                    if (showSegment("otherAssets")) {
                        return renderWithNoTooltip(item, indx);
                    }
                    break;
                case "socialSecurity":
                case "incomeGap":
                    return renderWithNoTooltip(item, indx);
                default:
                    break;
            }
        });
    };

    /**
     * Combine Employee Contributions in one row and combine Employer Contribution in one row
     * and add to array; bypass add of ee and er contributions
     * @param {} details
     */
    let mySavingsHasUpdated = false;
    const renderMobileView = (details) => {
        if (details && details.length > 0) {
            const mobileDetailsList = [];

            let EEContributions = 0;
            let ERContributions = 0;
            let HSAContributions = 0;
            let hasHSAPlan = false;

            for (let i = 0; i < details.length; i++) {
                const temp = {
                    id: "",
                    label: "",
                    value: "",
                    cssClassName: "",
                    routeTo: "",
                    display: false
                };
                if (details[i].id === "currentEE" || details[i].id === "futureEE") {
                    EEContributions += details[i].value;
                    if (details[i].id === "futureEE") {
                        if (details[i].value > 0 || details[i - 1].value > 0) {
                            mySavingsHasUpdated = true;
                        }
                        temp.id = "mySavings";
                        temp.label = mySavingLabel;
                        temp.value = EEContributions;
                        temp.cssClassName = details[i].cssClassName;
                        temp.routeTo = RETIREMENT_INCOME_ROUTE;
                        temp.display = true;
                        mobileDetailsList.push(temp);
                    }
                } else if (details[i].id === "currentER" || details[i].id === "futureER") {
                    ERContributions += details[i].value;
                    if (details[i].id === "futureER") {
                        temp.id = "employerContributions";
                        temp.label = employerContributionsLabel;
                        temp.value = ERContributions;
                        temp.cssClassName = details[i].cssClassName;
                        temp.routeTo = RETIREMENT_INCOME_ROUTE;
                        temp.display = ERContributions > 0;
                        mobileDetailsList.push(temp);
                    }
                } else if (details[i].id === "currentHSA" || details[i].id === "futureHSA") {
                    /* -- Do not display if zero -- */
                    HSAContributions += details[i].value;
                    if (details[i].hasPlan) {
                        hasHSAPlan = true;
                    }
                    if (details[i].id === "futureHSA") {
                        temp.id = "hsa";
                        temp.label = "HSA";
                        temp.value = HSAContributions;
                        temp.cssClassName = details[i].cssClassName;
                        temp.routeTo = HSA_ROUTE;
                        temp.display = hasHSAPlan;
                        if (hasHSAPlan && HSAContributions > 0) {
                            mobileDetailsList.push(temp);
                        }
                    }
                } else {
                    mobileDetailsList.push(details[i]);
                }
            }
            return (
                <PaycheckOptionDetails
                    options={mobileDetailsList}
                    mySavingsHasUpdated={mySavingsHasUpdated}
                />
            );
        }
    };

    return (
        <div>
            {isMobileView ? (
                <div className="selectPaycheckDetails">{renderMobileView(paycheckDetailsList)}</div>
            ) : (
                <div className="view-details shadowify" data-testid="paycheck-details">
                    <div
                        className={
                            "view-details-container paycheck-details-container hidden-xs" +
                            (showPaycheckViewDetails ? " active" : "")
                        }
                    >
                        <ul className="list-unstyled view-details-list hidden-xs">
                            {renderDesktopTabletView(paycheckDetailsList)}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

PaycheckDetails.propTypes = {
    isMobileView: PropTypes.bool.isRequired,
    open: PropTypes.bool
};

export default memo(PaycheckDetails);
