import React, { useEffect, useRef } from "react";

import { dispatchAmplitude, AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { ModuleContainer } from "gw-shared-components";
import { useAtomValue } from "jotai";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";

import { ROUTE_SETTINGS_PROFILE } from "../../../routes";
import { sentryProfileSettingStartMetrics } from "../../../utils/sentryMetricsLogging";
import { cleanupProfileSettingStartMetrics } from "../../../utils/sentryMetricsLogging/sentryProfileSettingStartMetrics";
import { redwoodAtom } from "../../atoms/atoms";
import useDisplayPcapModal from "../../hooks/useDisplayPcapModal";
import { useIsEmulator, useIsPrivileged } from "../../hooks/useEmulatorType";
import privilegedRoutes from "../../utils/privilegedEmulatorUtils";

import { getSubPage, getContainerClass } from "./helpers";

const PaeView = ({ error }) => {
    return <div className="dashboard-subpage-pae">{error}</div>;
};

const SubPage = () => {
    const { pathname, search } = useLocation();
    const redwood = useAtomValue(redwoodAtom);
    const isSettingPageRef = useRef(false);

    const translations = selectTranslations("error");
    const isPrivileged = useIsPrivileged();
    const isPrivilegedRoute = privilegedRoutes.includes(pathname) && isPrivileged;
    const isEmulator = useIsEmulator();
    const isPAE = isEmulator && !isPrivileged;
    const zeroStateTranslations = selectTranslations("zeroState");

    // Because we are setting the Routes location, we need to grab the true window location while on a subpage for displaying the modal.
    const [_, locationHash] = window.location.hash.split("#");
    const location = {
        hash: "",
        pathname: locationHash,
        search: window.location.search,
        state: undefined
    };
    const displayModal = useDisplayPcapModal(location);

    const error = StringUtil.supplant(translations.paeError, { page: getSubPage(pathname) });
    const containerClass = getContainerClass(pathname);

    const isSettingsPage = pathname === ROUTE_SETTINGS_PROFILE;

    const handleDispatchAmplitude = ({ eventType, eventProperty }) => {
        dispatchAmplitude({
            eventType: eventType ?? AMPLITUDE_EVENTS.SELECT_BUTTON,
            ...eventProperty
        });
    };

    useEffect(() => {
        let ignore = false;
        if (!ignore && isSettingsPage && !isSettingPageRef.current) {
            isSettingPageRef.current = true;
            sentryProfileSettingStartMetrics();
        }

        return () => {
            ignore = true;
            isSettingPageRef.current = false;
            cleanupProfileSettingStartMetrics();
        };
    }, [isSettingsPage]);

    //allow settings to display in emulator
    return !isPAE || isSettingsPage || isPrivilegedRoute ? (
        redwood.pcapClass && redwood.pcapScriptLoaded && (
            <ModuleContainer
                zeroStateTranslations={zeroStateTranslations}
                containerClass={containerClass}
                hash={`#${pathname}${search}`}
                dispatchAmplitude={handleDispatchAmplitude}
                pcapModalOpen={displayModal}
            />
        )
    ) : (
        <PaeView error={error} />
    );
};

PaeView.propTypes = {
    error: PropTypes.string
};

export default SubPage;
