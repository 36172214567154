import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { SliderWrapper } from "gw-shared-components";
import { omit } from "lodash";
import { useSelector, useDispatch } from "react-redux";

import { handleInvestmentSliderChange } from "../../actions/investments/investmentsActions";
import { dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import { setSliderMovement } from "../../actions/slider/sliderActions";
import {
    isNonAllMoneyTypeGroupingsFlow,
    canChangeEquityMix,
    canSubmitEquityMix
} from "../../business/investments";
import { ariaControlsSlidersHomePage } from "../../constants/AccessibilityPaycheck";

const Investments = () => {
    const dispatch = useDispatch();

    const translations = selectTranslations("retirementIncome");
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const individualOptions = useSelector((state) => state.investments.individualOptions);
    const individualId = useSelector((state) => state.participant.individualId);
    const equityMix = useSelector((state) => state.investments.equityMix);
    const sliderBeingMoved = useSelector((state) => state.slider).sliderBeingMoved;
    const canSubmit = individualOptions ? canSubmitEquityMix(individualOptions) : false;
    const canChange = canChangeEquityMix(primaryPlan, individualOptions);
    const nonAllMoneyTypeGroupings = isNonAllMoneyTypeGroupingsFlow(individualOptions);

    const handleEventBus = (type, target, data) => {
        const payload = omit(data, ["action"]);
        dispatch(dispatchEventBus(type, target, payload));
    };

    const handleSliderMoving = (value) => {
        dispatch(setSliderMovement(value));
    };

    const sliderChange = (slider, value) => {
        dispatch(
            handleInvestmentSliderChange({
                slider,
                value,
                individualId,
                primaryPlan,
                nonAllMoneyTypeGroupings
            })
        );
    };
    const investmentStyleTiers = translations.sliders["rivd-investmentStyle"].tiers;
    const ariaDescribedBy = ariaControlsSlidersHomePage;
    const prefix =
        investmentStyleTiers.find((tier) => equityMix >= tier.min && equityMix <= tier.max)?.label +
        " ";

    return (
        <div className="rs-investment-tabs">
            <div id="doItMyself" className="do-it-myself-tab">
                <div className="investment-mix-slider rivd-investment-style-slider">
                    <SliderWrapper
                        ariaLabelText={translations.investmentStyleEditableTextAria}
                        sliderId="rivd-investmentStyle"
                        identifier="investment-mix-slider"
                        increment={5}
                        editableStep={5}
                        //action to take if it is being moved
                        sliderMoving={handleSliderMoving}
                        //state of if it is being moved
                        sliderBeingMoved={sliderBeingMoved}
                        sliderChange={sliderChange}
                        useBothSidesOfValue={true}
                        value={equityMix}
                        prefix={prefix}
                        suffix=""
                        maxDecimals={0}
                        maxEditableEntry={100}
                        enabled={canChange}
                        showMessage={!canSubmit}
                        srContentId="srProjectedIncome"
                        sliderTranslations={translations}
                        showBottomText={true}
                        dispatchEventBus={handleEventBus}
                        valueUnit="split"
                        /*  checkUpdatedValue={val => this.checkUpdatedValue(val)} // Will this be needed ? */
                        message={translations.sliders.investmentMix.investmentTooltip}
                        leftSliderSubtitle={translations.sliders.investmentMix.conservative}
                        rightSliderSubtitle={translations.sliders.investmentMix.aggressive}
                        thumbValue={"||"}
                        ariaDescribedBy={ariaDescribedBy}
                    />
                </div>
            </div>
        </div>
    );
};

export { Investments };
