import { AxiosResponse } from "axios";

import type { TopNavLinks } from "../types/common";
import { executeGet } from "../utils/apiUtils.js";
import { getUrl } from "../utils/urlUtils.js";

interface ITopNavLinksResponse {
    data: TopNavLinks;
}

const getTopNavLinks = async (): Promise<TopNavLinks> => {
    const url = getUrl("getTopNavLinks");
    const response: AxiosResponse<ITopNavLinksResponse> = await executeGet(url, "getTopNavLinks");
    return response.data.data;
};

export default getTopNavLinks;
