/**
 *
 * @name ConfirmChangesEvents
 * @memberOf projectedIncome.view.events
 * @copyright 2018 Empower Retirement
 * @description These are the events for the add deferral modal
 */

const root = "ConfirmChangesEvent.";

export const ConfirmEvents = {
    PRINT_CLICK: root + "print_clicked_event",
    DONE_CLICK: root + "done_clicked_event",
    CLICK_OUT: root + "modal_shade_clicked_event"
};
