import { getAccountSetup } from "../getEvents";

import sendMetricEvent from "./sendMetricEvent";

const FROM_ACCOUNT_SETUP = "FROM_ACCOUNT_SETUP";
interface SendMetricEventMFAArgs {
    endTime: number;
    loginLastHash?: string;
    measurementName: string;
    name: string;
    startHash?: string;
    startTime: number;
    timeToLoad: number;
}

const sendMetricEventMFA = ({
    name,
    timeToLoad,
    measurementName,
    startHash = "",
    loginLastHash = "",
    startTime,
    endTime
}: SendMetricEventMFAArgs) => {
    const accountSetupExists = getAccountSetup();
    /**
     * If account setup item exists then we want to skip sending the MFA metrics. The time spent
     * on the setup page can potentially skew the metric we are aiming to collect here which is directly
     * from mfa login click to {liat component render}
     */
    if (!accountSetupExists) {
        sendMetricEvent({
            name,
            timeToLoad,
            measurementName,
            startHash,
            loginLastHash,
            startTime,
            endTime
        });
    } else {
        // collect the events for users going through the account setup flow
        sendMetricEvent({
            name: `${FROM_ACCOUNT_SETUP}-${name}`,
            timeToLoad,
            measurementName,
            startHash,
            loginLastHash,
            startTime,
            endTime
        });
    }
};

export default sendMetricEventMFA;
