import { AccountHeader } from "../accounts";

export enum AggregationLevel {
    ALL = "ALL",
    MANUAL = "MANUAL",
    NONE = "NONE"
}
export interface FunnelAttributes {
    aggregationLevel: AggregationLevel;
    currentContextUserAccountIds: number[];
    daysSinceAggregationQualified: number;
    daysSinceFirstContactMade: number;
    daysSinceFirstOpportunityCreated: number;
    daysSinceLastContactMade: number;
    daysSinceLastEmailOpened: number;
    daysSinceQuestionnaireQualified: number;
    daysSinceRegistration: number;
    daysSinceStartedTrading: number;
    disclosureText: string;
    enrollmentEligibility: boolean;
    enrollmentStatus: string;
    hasAMAPlan: boolean;
    hasAdvisorAssigned: boolean;
    hasHeldInfoGathering: boolean;
    hasHeldRecommendations: boolean;
    hasOpenOpportunity: boolean;
    hasPSAccount: boolean;
    isClient: boolean;
    isClientReferral: boolean;
    isReferral: boolean;
    isSuspicious: boolean;
    lastLoginDate: number;
    mtrProgramName: string;
    numOfBankAccounts: number;
    numOfCreditCardAccounts: number;
    numOfExternalAccounts: number;
    numOfRecordKeptAccounts: number;
    offerDOLAdvice: boolean;
    offerMtr: boolean;
    offerOnlineAdvice: boolean;
    onlineAdviceProgramName: string;
    referralCampaign: string;
    salesType: string;
    sfTier: string;
    sfType: string;
    showRetailStrategyTakeaway: boolean;
    strategyTakeaway: {
        contentUrl: string;
        text: string;
    };
    userType: string;
}

export interface FunnelData {
    data: {
        spData: FunnelAttributes;
        spHeader: AccountHeader;
    };
}
