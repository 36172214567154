import React from "react";

import PropTypes from "prop-types";

export const Header = ({ title }) => {
    return <h3 className="header__paycheck-primary-controls">{title}</h3>;
};

Header.propTypes = {
    title: PropTypes.string.isRequired
};
