import { SET_FAQ_DATA, EXPAND_COLLAPSE_FAQ } from "../../actions/faq/faqActionTypes";

const initialState = {
    faqData: null,
    originalFaqData: [] // not to be changed - only used for resetting the faqData values
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_FAQ_DATA:
            return {
                ...state,
                faqData: payload,
                originalFaqData: payload
            };

        case EXPAND_COLLAPSE_FAQ:
            return {
                ...state,
                faqData: state.faqData.map((data) => {
                    if (payload.shouldExpand) {
                        data.show = true;
                    } else {
                        data.show = false;
                    }

                    return data;
                })
            };

        default:
            return state;
    }
};
