import { isArrayEmpty } from "gw-shared-components";
import _cloneDeep from "lodash/cloneDeep";
import _concat from "lodash/concat";
import _each from "lodash/each";
import _isNumber from "lodash/isNumber";

import { SET_INIT_DATA } from "../../actions/initData";
import {
    CALL_FOR_AA_TERMS_OF_SERVICE,
    LOAD_OTHER_ASSETS_REF_DATA,
    SAVE_TERMS_OF_SERVICE,
    SET_HAS_RETAIL_ACCOUNT,
    SET_HIDE_I_WANT_TO_LINKS,
    SET_TEMP_SUPPORTED_ACCOUNTS,
    SPLIT_OTHER_ASSETS_BY_EMPLOYER,
    UPDATE_ACCOUNT_LITE_ASSETS,
    UPDATE_ASSETS_ESTIMATED_INCOME,
    UPDATE_MY_LIFE_GOALS
} from "../../actions/otherAssets/otherAssetsTypes";
import OtherAssetsConstants from "../../constants/OtherAssetsConstants";
import { getFilteredOutsideAssets } from "../../selectors/otherAssetsSelectors";
import {
    getMigratedAssetIncome,
    getNonMigratedAssetIncome
} from "../../services/otherAssetsService";

const initialState = {
    accountAggregationToken: null, // where is this used/needed
    dataIsLoaded: false,
    employerFutureAssets: [],
    employerPastAssets: [],
    externalAssets: [],
    hasSavedAATermsOfService: null, // where is this used/needed
    hasCalledForAATermsOfService: false, // where is this used/needed
    linkedAccountBalances: [],
    otherAssetsRefData: {},
    retirementAssets: [],
    showLoader: true, //@TODO - REFACTOR AND SET A globalThis LOADER
    tableAssets: [],
    tempSupportedAccounts: [],
    accountDetailsLoaded: false,
    getAccountsLoaded: false,
    hideIWantToLinks: false
};

/**
 * @description update the monthly income estimate of an asset when changing the term view
 * @param {array} tableAssets - Non migrated other assets
 * @param {Object} store
 * @return {array}
 */
const updateIncomeForNonMigrated = (tableAssets, storeState) => {
    if (isArrayEmpty(tableAssets)) return tableAssets;

    return tableAssets.map((asset) => ({ ...asset, ...getProjections(asset) }));

    function getProjections(stateAsset) {
        const asset = _cloneDeep(stateAsset);
        if (_isNumber(Number(asset.value)) && asset.value >= 0) {
            const result = getNonMigratedAssetIncome(asset, storeState);

            if (asset.assetType === OtherAssetsConstants.OAB_TRS) {
                asset.value = result.value;
                asset.monthlyIncomeEST = result.income;
            } else {
                asset.monthlyIncomeEST = result;
            }
            return asset;
        }
    }
};

/**
 * @description update the monthly income estimate of an asset when changing the term view
 * @param {array} arr - Migrated other assets
 * @param {Object} store
 * @param {number} term
 * @return {array}
 */
export const updateAssetIncomeEstimated = (arr, storeState, term) => {
    if (isArrayEmpty(arr)) return arr;
    return arr.map((asset) => {
        const monthlyIncome = getMigratedAssetIncome(asset, storeState, term);
        asset.monthlyIncomeEST = monthlyIncome;
        return asset;
    });
};

export default (state = initialState, action) => {
    const { type, otherAssetsRefData, participant, spouse, payload, storeState } = action;

    const tableAssets = [];
    const employerPastAssets = [];
    const employerFutureAssets = [];

    switch (type) {
        case SET_INIT_DATA:
            return {
                ...state,
                otherAssetsRefData: payload.otherAssetsRefData
            };

        case LOAD_OTHER_ASSETS_REF_DATA:
            return { ...state, otherAssetsRefData, dataIsLoaded: true };

        case SPLIT_OTHER_ASSETS_BY_EMPLOYER: {
            _each([participant, spouse], (user) => {
                // often there is no spouse
                if (!user) {
                    return;
                }

                _each(user.linkedAccountBalances, (asset) => {
                    tableAssets.push(_cloneDeep(asset));
                });

                const externalAndRetirementAssets = _concat(
                    user.externalAssets || [],
                    user.retirementAssets || []
                );

                _each(externalAndRetirementAssets, (asset) => {
                    if (asset.dataSource !== "emp") {
                        tableAssets.push(asset);
                    } else if (asset.legacyAssets) {
                        //todo fc: need to get this from balances / match
                        _each(asset.legacyAssets, function (legacyAsset) {
                            if (legacyAsset.type === 23) {
                                employerPastAssets.push(legacyAsset);
                            } else {
                                employerFutureAssets.push(legacyAsset);
                            }
                        });
                    }
                });
            });

            const filteredAssets = getFilteredOutsideAssets(tableAssets, storeState);

            const projectedTableAssets = updateIncomeForNonMigrated(tableAssets, storeState);

            return {
                ...state,
                ...{
                    tableAssets: projectedTableAssets,
                    employerPastAssets,
                    employerFutureAssets,
                    showLoader: false,
                    externalAssets: filteredAssets.externalAssets,
                    retirementAssets: filteredAssets.retirementAssets,
                    linkedAccountBalances: filteredAssets.linkedAccountBalances,
                    dataIsLoaded: true
                }
            };
        }
        case SAVE_TERMS_OF_SERVICE:
            return {
                ...state,
                hasSavedAATermsOfService: payload.isTOSAccepted,
                accountAggregationToken: payload.uiToken
            };

        case CALL_FOR_AA_TERMS_OF_SERVICE:
            return { ...state, hasCalledForAATermsOfService: payload };

        case SET_TEMP_SUPPORTED_ACCOUNTS:
            return { ...state, tempSupportedAccounts: payload };

        case UPDATE_ASSETS_ESTIMATED_INCOME:
            return {
                ...state,
                externalAssets: updateAssetIncomeEstimated(
                    state.externalAssets,
                    storeState,
                    action.term
                ),
                retirementAssets: updateAssetIncomeEstimated(
                    state.retirementAssets,
                    storeState,
                    action.term
                ),
                linkedAccountBalances: updateAssetIncomeEstimated(
                    state.linkedAccountBalances,
                    storeState,
                    action.term
                ),
                tableAssets: updateIncomeForNonMigrated(state.tableAssets, storeState)
            };
        case UPDATE_MY_LIFE_GOALS:
            return {
                ...state,
                retirementAssets: updateAssetIncomeEstimated(action.list, storeState, action.term)
            };
        case UPDATE_ACCOUNT_LITE_ASSETS:
            return {
                ...state,
                externalAssets: updateAssetIncomeEstimated(action.list, storeState, action.term)
            };
        case SET_HAS_RETAIL_ACCOUNT:
            return {
                ...state,
                hasRetailAccount: action.hasRetailAccount,
                accountDetailsLoaded: true
            };
        case SET_HIDE_I_WANT_TO_LINKS:
            return {
                ...state,
                hideIWantToLinks: payload,
                getAccountsLoaded: true
            };
        default:
            return state;
    }
};
