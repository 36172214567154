import { findIndex as _findIndex } from "lodash";

import ReferenceData from "../../../../constants/ReferenceData";

const fixDateForAllBrowsers = (dateString) => dateString.replace(/-/g, "/");

const calculateAge = (birthDate) => {
    // birthday is a date
    const fixedBirthdate = fixDateForAllBrowsers(birthDate);
    const birthday = new Date(fixedBirthdate);
    const ageDifMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return isNaN(
        Math.abs(ageDate.getUTCFullYear() - ReferenceData.SOCIAL_SECURITY_CALCULATE_AGE_METHOD)
    )
        ? null
        : Math.abs(ageDate.getUTCFullYear() - ReferenceData.SOCIAL_SECURITY_CALCULATE_AGE_METHOD);
};

const personDropdown = (birthDate, retirementAge, translations, ssStartRecAge) => {
    const dropdownObj = [];
    let i = ReferenceData.MIN_SOCIALSECURITY_STARTAGE;
    const birthYear = birthDate.split("-");
    const personAge = calculateAge(birthDate);
    const startAge = personAge + 1 < ssStartRecAge ? personAge + 1 : ssStartRecAge;

    if (startAge < ReferenceData.MIN_SOCIALSECURITY_STARTAGE) {
        i = ReferenceData.MIN_SOCIALSECURITY_STARTAGE;
    } else if (
        startAge >= ReferenceData.MIN_SOCIALSECURITY_STARTAGE &&
        startAge < ReferenceData.MAX_SOCIALSECURITY_LATEST_STARTAGE
    ) {
        i = startAge;
    } else if (startAge >= ReferenceData.MAX_SOCIALSECURITY_LATEST_STARTAGE) {
        i = ReferenceData.MAX_SOCIALSECURITY_LATEST_STARTAGE;
    }

    for (i; i <= ReferenceData.MAX_SOCIALSECURITY_LATEST_STARTAGE; i++) {
        const yearVal = parseInt(birthYear[0], 10) + i;
        const text = i + " " + "(" + yearVal + ")";
        dropdownObj.push({ value: text, optionsVal: i + "-" + yearVal, age: i });
    }

    // At Retirement option
    const retireIndx = _findIndex(dropdownObj, (item) => item.age === retirementAge);

    if (retireIndx > -1 && dropdownObj.length > 1) {
        dropdownObj.push({
            age: Math.min(retirementAge, ReferenceData.MAX_SOCIALSECURITY_LATEST_STARTAGE),
            value: translations.details.startDateOption,
            optionsVal:
                Math.min(retirementAge, ReferenceData.MAX_SOCIALSECURITY_LATEST_STARTAGE) +
                "-" +
                String(translations.details.startDateOption).replace(" ", "_")
        });
    } else {
        const outOfRangeRetireAge =
            retirementAge > ReferenceData.MAX_SOCIALSECURITY_LATEST_STARTAGE
                ? ReferenceData.MAX_SOCIALSECURITY_LATEST_STARTAGE
                : retirementAge < ReferenceData.MIN_SOCIALSECURITY_STARTAGE
                  ? ReferenceData.MIN_SOCIALSECURITY_STARTAGE
                  : null;
        if (outOfRangeRetireAge) {
            dropdownObj.push({
                age: outOfRangeRetireAge,
                value: translations.details.startDateOption,
                optionsVal:
                    outOfRangeRetireAge +
                    "-" +
                    String(translations.details.startDateOption).replace(" ", "_")
            });
        }
    }

    return dropdownObj;
};

export default personDropdown;
