/**
 * ACTIONS FOUND IN THIS FILE
 * @updateTradeOffMessages
 * @setTradeOffs
 * @getTradeOffs
 */
import { isUndefinedOrNull } from "gw-shared-components";
import * as Hub from "hub";

import HubConfigFactory from "../../factories/HubConfigFactory";

import { SET_TRADE_OFFS } from "./tradeoffMessagingTypes";

/**
 * @memberOf common.service.business.RetirementSavingsService
 * @description
 * Prepares domain model data for hubService call for getting trade offs, then updates tradeoffs with the results
 * @param {number} contributionRate The current contribution rate is used to create a modeled deferral.
 */
export const updateTradeOffMessages = (projectedIncomeParam) => {
    return (dispatch, getState) => {
        const state = getState();
        const primaryPlan = state.primaryPlan;
        const participant = state.participant;
        const term = state.applicationSettings.projectionSettings.term;
        const projectedIncome = projectedIncomeParam ? projectedIncomeParam : state.projectedIncome;
        const baselineProjectedIncome = state.baseline.projectedIncome;
        const salary = participant.salary;
        const payFrequency = primaryPlan.payFrequency;
        const maximizer = primaryPlan.maximizer;
        const baselineDeferrals = state.baseline.deferrals;
        const hasDeferralChanged = state.deferralSetupConfig.hasDeferralChanged;

        const updatedProjectedIncome = {
            ...projectedIncome,
            gap: projectedIncome.incomeGap,
            projected: projectedIncome.total,
            percentOfGoal: projectedIncome.percentageOfGoal
        };
        const updatedBaselineProjectedIncome = {
            ...baselineProjectedIncome,
            gap: baselineProjectedIncome.incomeGap,
            projected: baselineProjectedIncome.total,
            percentOfGoal: baselineProjectedIncome.percentageOfGoal
        };

        const result = getTradeOffs(
            updatedProjectedIncome,
            baselineDeferrals,
            participant.deferrals,
            salary,
            payFrequency,
            term,
            updatedBaselineProjectedIncome,
            maximizer
        );

        const tradeOffMessages = { ...state.nextSteps.tradeOffMessages };
        dispatch(
            setTradeOffs(
                result,
                primaryPlan.planRules.showLimitMsg,
                hasDeferralChanged,
                tradeOffMessages
            )
        );
    };
};

const TRADEOFF_CATEGORIES = [
    {
        prop: "retirementIncome",
        type: "future",
        typeIndex: 0,
        messageEnd: "RetirementIncome"
    },
    {
        prop: "employerMatch",
        type: "future",
        typeIndex: 1,
        messageEnd: "EmployerMatch"
    },
    {
        prop: "paycheck",
        type: "today",
        typeIndex: 0,
        messageEnd: "PaycheckPretax"
    }
];
/**
 * @memberOf common.model.ViewModel
 * Trade off messages are provided by the hub calculations are not provided via the data model. They are here in
 * the view model since they are somewhat transient and are themselves not part of the domain model objects
 * @typedef {Object} future: {amount: number, message: string}[], today: {amount: number, message: string}[]
 */

const setTradeOffs = function (tradeoff, showMsg, hasDeferralChg, tradeOffMessages) {
    TRADEOFF_CATEGORIES.forEach((category) => {
        // Spanish translation breaking key, 'today'
        if (category.type === "hoy") {
            category.type = "today";
        }
        const currentTradeOff = tradeOffMessages[category.type][category.typeIndex];
        const adj = tradeoff[category.prop] > 0 ? "more" : "less";
        currentTradeOff.message = adj + category.messageEnd;
        // trade off amounts are always in monthly, however the Hub always returns term-appropriate values
        // therefore, we need to always set the value to monthly
        currentTradeOff.amount = tradeoff[category.prop];
    });

    // Add message for limitHit message; display only if PlanRules.showLimitMsg is set to true
    // Verify if deferral has changed
    let blnInit = true;
    if (showMsg && hasDeferralChg && !isUndefinedOrNull(tradeoff.limitsHit)) {
        const limitChk = tradeoff.limitsHit;
        if (
            limitChk.limit402g ||
            limitChk.limit415 ||
            limitChk.limit457 ||
            limitChk.limitHSA ||
            limitChk.limit457bSpecialCatchup
        ) {
            tradeOffMessages.reachedLimits.limitMsg = "";
            tradeOffMessages.reachedLimits.showLimitMsg = true;
            if (String(limitChk.source).indexOf(",") > -1) {
                limitChk.source = String(limitChk.source).replace(/,\s([^,]+)$/, ", and $1");
                const arrCnt = String(limitChk.source).split(",");
                tradeOffMessages.reachedLimits.sourcecnt = arrCnt.length;
            }
            tradeOffMessages.reachedLimits.source =
                limitChk.source != undefined ? String(limitChk.source).toLowerCase() : "";
            blnInit = false;
        }
    }

    if (blnInit) {
        tradeOffMessages.reachedLimits.showLimitMsg = false;
        tradeOffMessages.reachedLimits.limitMsg = "";
        tradeOffMessages.reachedLimits.source = "";
        tradeOffMessages.reachedLimits.sourcecnt = 0;
    }

    return {
        type: SET_TRADE_OFFS,
        payload: tradeOffMessages
    };
};

/**
 * The getTradeOffs method takes in an income projection, a set of original deferrals, a set of updated
 * deferrals, the user's salaries and their pay frequency. It then leverage's the Hub library and compares
 * the inputs against the baselineProjectedIncome it has in it's data model.
 * @param {Hub.IncomeProjection} updatedIncomeProj - the updated income projection to use for calculations
 * @param {Hub.Deferral.Config[]} baseDeferrals - The base deferrals to use for calculations
 * @param {Hub.Deferral.Config[]} updatedDeferrals - The updated income projection to use for calculations
 * @param {Number[]} salaries - An array of salaries. If one is passed, it will be treated as a total annual salary.
 * If two are passed, the first will be used as the base salary, the second will be used as the variable salary.
 * @param {Number} payFrequency
 * @returns {Object}
 * ******* FROM THE HUB SERVICE ******
 */
const getTradeOffs = function (
    projectedIncome,
    deferrals,
    newDeferrals,
    salary,
    payFrequency,
    term,
    baselineProjectedIncome,
    maximizer
) {
    let result = null;
    const plan = { deferrals, newDeferrals };

    const originalDeferrals = HubConfigFactory.createDeferrals(plan, maximizer, true);
    const updatedDeferrals = HubConfigFactory.createDeferrals(plan, maximizer, false);

    result = Hub.getTradeOffs(
        baselineProjectedIncome,
        projectedIncome,
        originalDeferrals,
        updatedDeferrals,
        salary,
        payFrequency,
        term
    );
    return result;
};
