type LoginButtonClick = {
    hash: string;
    timestamp: number;
} | null;

const getLoginButtonClick = (): LoginButtonClick => {
    const loginButtonClick = sessionStorage.getItem("participant-loginButtonClick");

    if (loginButtonClick) {
        return JSON.parse(loginButtonClick);
    }

    return null;
};

export default getLoginButtonClick;
