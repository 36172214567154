import { max as _max, min as _min, each as _each } from "lodash";

/**
 * Looops through the vhFactors and sets max and min values for both age and retirementAge.   These values are needed by the HUB.
 * @param {*} user
 */
const getVhFactorBoundsFromVhFactors = function (vhFactors) {
    const _ages = [];
    const _retirementAges = [];
    const result = {
        // make sure we have a default
        age: { upper: 0, lower: 0 },
        retirementAge: { upper: 0, lower: 0 }
    };

    // parse out the age/retirement age
    _each(vhFactors, function (f, k) {
        //ex: "50~85~10~90": age~retirementAge~equityMix~confidenceLevel
        const parts = k.split("~");

        // this is going to duplicate values in these arrays at times, but it doesn't matter since it would likely
        // be more expensive to conditionally check for and add vs just adding them in duplicate.
        _ages.push(parts[0]);
        _retirementAges.push(parts[1]);
    });

    // find and populate the min/max
    result.age.upper = parseInt(_max(_ages));
    result.age.lower = parseInt(_min(_ages));
    result.retirementAge.upper = parseInt(_max(_retirementAges));
    result.retirementAge.lower = parseInt(_min(_retirementAges));

    return result;
};

function isUndefined(value) {
    return value === undefined;
}

function isNull(value) {
    return value === null;
}

function isUndefinedOrNull(value) {
    return isUndefined(value) || isNull(value);
}

function isString(val) {
    return typeof val === "string" || val instanceof String;
}

export { getVhFactorBoundsFromVhFactors, isUndefinedOrNull, isString };
