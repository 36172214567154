import { generateHashValue } from "core-ui/client/react/core/utils/generateHashValue";

const checkHasSeenWelcomeWidget = (userGuid) => {
    return generateHashValue(userGuid).then((hashedUserGuid) => {
        const key = `hasSeenWelcomeWidget-${hashedUserGuid}`;
        const hasSeenWelcomeWidget = localStorage.getItem(key);
        window.widgetStates = {
            hasSeenWelcomeWidget: Boolean(hasSeenWelcomeWidget)
        };
        return hasSeenWelcomeWidget;
    });
};
export default checkHasSeenWelcomeWidget;
