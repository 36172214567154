/**
 * @name app.common.model.domain.HVSChartConfig
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Creates a defined object structure for the HVSChartConfig object type.
 */

/**
 * The HVSChartConfig object constructor.
 * @param config This object of properties you would like to base the plan off of. Note that only primitives are used.
 * @constructor
 *
 * @example
 "percentage": 2.0,
 "id": "TYP4",
 "amount": 18.0
 */

"use strict";

const HVSChartConfig = function (config) {
    this.amount = 0.0;
    this.id = "";
    this.percentage = 0.0;

    if (config) {
        this.amount = config.amount;
        this.id = config.id;
        this.percentage = config.percentage;
    }
};

export default HVSChartConfig;
