import React from "react";

import PropTypes from "prop-types";

const PersonView = ({
    name,
    messaging,
    amountDisplayed,
    btnTxt = "Modify",
    btnClass,
    spouseIcon = "",
    setShowModal,
    isSpouse = false,
    handleEventBus
}) => {
    const numericalAmount = amountDisplayed && parseFloat(amountDisplayed.replace(/\$|,/g, ""));
    return (
        <div className="social-security-person d-flex" data-testid="person-view">
            <div className="social-security--avatar">
                <div className="dashboard-icon">
                    <div className={`user-icon ${spouseIcon}`} />
                </div>
            </div>
            <div className="social-security--person-details">
                <h4 data-testid={isSpouse ? "spouse-name" : "participant-name"}>{name}</h4>
                <div className="form-group social-security-person-text">
                    {messaging}{" "}
                    <strong data-testid={isSpouse ? "spouse-amt" : "participant-amt"}>
                        {`$${Math.round(numericalAmount).toLocaleString()}`}
                    </strong>
                </div>
            </div>
            <div className="social-security--btn-actions">
                <button
                    className={`btn ${btnClass}`}
                    onClick={() => {
                        handleEventBus(isSpouse);
                        setShowModal(true);
                    }}
                    data-testid={`modify-btn--${isSpouse ? "spouse" : "participant"}`}
                >
                    {btnTxt}
                </button>
            </div>
        </div>
    );
};
PersonView.propTypes = {
    name: PropTypes.string,
    messaging: PropTypes.string,
    amountDisplayed: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    btnTxt: PropTypes.string,
    btnClass: PropTypes.string,
    spouseIcon: PropTypes.string,
    setShowModal: PropTypes.func,
    isSpouse: PropTypes.bool,
    handleEventBus: PropTypes.func
};
export default PersonView;
