import { useQuery } from "@tanstack/react-query";

import { getAdvisoryMessaging } from "../../services/financialEngineService";
import { QUERY_KEYS } from "../constants";

const useAdvisoryMessaging = (
    individualId: string,
    planId: string,
    shouldGetAdvisoryData: boolean,
    translations: object,
    maDetails: boolean
) => {
    return useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [QUERY_KEYS.GET_ADVISORY_MESSAGING, individualId, planId],
        queryFn: () => getAdvisoryMessaging(individualId, planId, translations),
        enabled: shouldGetAdvisoryData && maDetails
    });
};

export default useAdvisoryMessaging;
