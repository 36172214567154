const ColorConstants = {
    HEALTHCARE_COSTS: {
        ESTIMATED_HEALTHCARE_COSTS: "#51A8B9",
        HSA_PROJECTED_TO_COVER: "#84D5CE",
        YIELD_STYLE: "#E49D00",
        WARN_STYLE: "#FF0000",
        HEALTHY_STYLE: "#4D8E0C"
    },
    COLOR_PATTERNS: {
        GOAL_MODAL_COLOR_PATTERN:
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIIAAACGCAYAAAARklLrAAAD/ElEQVR4Ae3cwW7iQAzGcd7/rbbavsT2sj0gcQPBjUtWaVewLAnJZOyxx/5XigQhY0/9/eA4u8/P3wNX2xl8fPwa3t9/Dm9vP9xcu4E/kwlcr9fhcDgM+/3exbW7XC4mg6DpMHjCsBtFgsGOpRcMXxDAYAdh7OwBww0CGHJjeIAAhrwYniCAISeGSQhgyIdhFgIYcmF4CQEMeTAsQgBDDgyrIIAhPobVEMAQG0MRBDDExVAMAQwxMWyCAIZ4GDZDAEMsDFUQwBAHQzUEMMTAIAIBDP1jEIMAhr4xiEIAQ78YxCGAoU8MKhDA0B8GNQhg6AuDKgQw9INBHQIY+sDQBAIY/GNoBgEMvjE0hQAGvxiaQwCDTwwmEMDgD4MZBDD4wmAKAQx+MJhDAIMPDC4ggMEegxsIYLDF4AoCGOwwuIMABhsMLiGAoT0GtxBGDOfzuf1EknbcHY9HFwc+jsFPXWBoI/Pr5FUwtBm25y63I3jB4Dkm/b3dIIytwKA/cK8dHiCAwWtM+vt6ggAG/aF77DAJAQweo9Ld0ywEMOgO3lv1lxDA4C0uvf0sQgCD3vA9VV4FAQyeItPZy2oIYNAJwEvVIghg8BKb/D6KIYBBPgQPFTdBAIOH6GT3sBkCGGSDsK5WBQEM1vHJ9a+GAAa5MCwriUAAg2WEMr3FIIBBJhCrKqIQwGAVY31fcQhgqA/FooIKBDBYRFnXUw0CGOqCab1aFQIYWse5vZ86BDBsD6flyiYQwNAy0m29mkEAw7aAWq1qCgEMrWIt79McAhjKQ2qxwgQCGFpEW9bDDAIYyoLSftoUwvjPnU6nyXMRps5KsLiX5XwGcwhg0P6ur6vvAgIY1oWl+ZQbCGDQjHm5tisIYFgOTOsJdxDAoBX167ouIYDhdWgan7qFAAaNuOdruoYAhvngpD9xDwEM0pFP1+sCAhimw5O82w0EMEjG/lyrKwhgeA5Q6k53EMAgFf1jnS4hgOExRIl33UIAg0T89xpdQwDDPcjaV91DAEMtge/1ISCAoR5DGAhgqMMQCgIYtmMIBwEM2zCEhACGcgxhIYChDENoCGBYjyE8BDCsw5ACAhiWMaSBAIbXGFJBAMM8hnQQwDCNISUEMDxjSAsBDI8YUkMAwx1Deghg+MYAhL9fiuwntwDh/uuY+hgfIPwDYXyZ9ZcBCP9ByIoBCBMQMmIAwgyEbBiA8AJCJgxAWICQBQMQVkDIgAEIKyFExwCEAgiRMQChEEJUDEDYACEiBiBshBANAxAqIETCAIRKCFEwAEEAQgQMQBCC0DsGIAhC6BkDEIQh9IoBCAoQesQABCUIvWEAgiKEnjAAQRlCLxiA0ABCDxiA0AiCdwx/APzj3Kr0xQ2iAAAAAElFTkSuQmCC"
    }
};

export default ColorConstants;
