import {
    SET_CONFIRMATION_DATA,
    SET_SHOW_CONFIRM_MODAL,
    SET_LAST_CHANGES
} from "../../actions/confirmChanges/confirmChangesTypes";
import {
    SET_SHOW_TRADE_OFFS,
    SET_SHOW_REVIEW_BUTTON,
    SET_SHOW_REVIEW_MODAL
} from "../../actions/reviewReset/reviewResetTypes";

const initialState = {
    confirmModalId: "confirmChanges",
    confirmationData: null,
    contributionRate: "",
    lastChanges: { retirementAge: null, equity: 0, deferrals: [] },
    maximizer: "",
    printClass: "",
    showConfirmModal: false,
    showReviewButton: false,
    showReviewModal: false,
    showTradeOff: false
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_SHOW_TRADE_OFFS:
            return { ...state, showTradeOff: payload };
        case SET_SHOW_REVIEW_BUTTON:
            return { ...state, showReviewButton: payload };
        case SET_SHOW_REVIEW_MODAL:
            return { ...state, showReviewModal: payload };
        case SET_LAST_CHANGES:
            return { ...state, lastChanges: { ...state.lastChanges, ...payload } };
        case SET_CONFIRMATION_DATA:
            return { ...state, confirmationData: payload };
        case SET_SHOW_CONFIRM_MODAL:
            return { ...state, showConfirmModal: payload };
        default:
            return state;
    }
};
