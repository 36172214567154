import React, { useState, useEffect } from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { Loader } from "gw-shared-components";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { useSelector, useDispatch } from "react-redux";

import Deferrals from "../../business/deferralsLogic/deferrals";
import { canChangeEquityMix } from "../../business/investments";
import {
    getBeingUnEnrolledFromMaximizer,
    hasRetirementAgeChanged,
    hasEquityMixChanged,
    getAffectedPlanNames,
    canSubmitChanges,
    getHasEquityMixWarnings,
    equityMixChangeOnly
} from "../../business/retirementIncome";
import DeferralConstants from "../../constants/DeferralConstants";
import {
    saveChanges,
    resetSliderValues,
    resetContributionSlidersOnly
} from "../../middleware/reviewChangesMiddleware";

import { ReviewModalEvents } from "./ReviewModalEvents";

const ReviewChangesModal = ({ showModal }) => {
    const appTranslations = selectTranslations("app");
    const reviewChangesTranslations = selectTranslations("reviewChanges");
    const retirementIncomeTranslations = selectTranslations("retirementIncome");
    const maximizerTranslations = selectTranslations("maximizer");

    const primaryPlan = useSelector((state) => state.primaryPlan);
    const individualOptions = useSelector((state) => state.investments.individualOptions);
    const activeDeferral = useSelector((state) => state.activeDeferral);
    const deferralList = useSelector((state) => state.participant.deferrals);
    const deferralSetupConfig = useSelector((state) => state.deferralSetupConfig);
    const deferralWarnings = useSelector((state) => state.deferralSetupConfig.deferralWarnings);
    const deferralSubmissionWarnings = useSelector(
        (state) => state.deferralSetupConfig.deferralSubmissionWarnings
    );
    const equityMixWarnings = useSelector((state) => state.investments.equityMixWarnings);
    const equityMix = useSelector((state) => state.investments.equityMix);
    const priorEquityMix = useSelector((state) => state.baseline.investments.equityMix);
    const retireAge = useSelector((state) => state.participant.retirementAge);
    const priorRetireAge = useSelector((state) => state.baseline.retirementAge);
    const priorDeferrals = useSelector((state) => state.baseline.deferrals);
    const individualId = useSelector((state) => state.participant.individualId);
    const { ignoreHighContributions } = useSelector((state) => state.shared.txnAccess);
    const dispatch = useDispatch();
    const [pendingSubmit, setPendingSubmit] = useState(false);
    const deferralClass = new Deferrals(
        deferralList,
        primaryPlan,
        deferralSetupConfig,
        activeDeferral
    );
    const deferralPercentTotal = deferralList.reduce((prev, deferral) => {
        if (
            deferralClass.isDollarDeferral(deferral) ||
            deferralClass.isVariableDeferral(deferral)
        ) {
            return prev;
        }
        return prev + deferral.value;
    }, 0);

    const hasMixedPctAmt =
        deferralSetupConfig.hasDeferralChanged &&
        deferralSetupConfig.hasMixedDeferrals &&
        activeDeferral.isMixedDeferral;

    const submit = (def) => {
        setPendingSubmit(true);
        if (hasMixedPctAmt) {
            dispatch(resetContributionSlidersOnly());
        }
        dispatch(saveChanges(def));
    };

    useEffect(() => {
        if (!showModal) {
            setPendingSubmit(false);
        }
    }, [showModal]);

    const handleCancelChanges = () => {
        dispatch(resetSliderValues());
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: "cancel_changes_button"
        });
    };

    const deferralWarningsHasLength = () => deferralWarnings && deferralWarnings.length > 0;

    const getWarningsOnlyModal = () => {
        const retirementAgeChange = hasRetirementAgeChanged(priorRetireAge, retireAge);

        if (retirementAgeChange) {
            return false;
        }

        const equityMixChanged = hasEquityMixChanged(
            false,
            individualOptions,
            priorEquityMix,
            equityMix
        );
        const equityWarnings =
            hasEquityMixChanged(true, individualOptions, priorEquityMix, equityMix) &&
            getHasEquityMixWarnings(equityMixWarnings);
        const fullDeferralsChanged = deferralClass.getChangedDeferrals("full").length > 0;
        const fullDeferralWarnings =
            deferralClass.getChangedDeferrals("full").length && deferralWarningsHasLength();
        const modelDeferralsChanged =
            deferralClass.getChangedDeferrals("model").length > 0 ||
            deferralClass.getChangedDeferrals("grandfathered").length > 0;
        const deferralWarningsOnly =
            (fullDeferralsChanged && fullDeferralWarnings) ||
            (!fullDeferralsChanged && modelDeferralsChanged);
        return (
            (deferralWarningsOnly && (equityWarnings || !equityMixChanged)) ||
            (equityWarnings && !fullDeferralsChanged)
        );
    };

    const getReviewChangesTitle = () => {
        if (getWarningsOnlyModal()) {
            return reviewChangesTranslations.warnings.title;
        } else {
            return reviewChangesTranslations.title;
        }
    };

    const getEquityMixDelta = () => {
        if (!canChangeEquityMix(primaryPlan, individualOptions)) {
            return 0;
        }
        return Math.round(equityMix) - priorEquityMix;
    };

    const hasEquityMixErrors = function () {
        return equityMixWarnings && equityMixWarnings.length > 0;
    };

    const hasEquityMixChanges = (ignoreWarnings) => {
        if (!ignoreWarnings && hasEquityMixErrors()) {
            return false;
        }
        return getEquityMixDelta() !== 0;
    };

    const showEquityMixWarnings = () => {
        return hasEquityMixChanges(true) || hasEquityMixErrors();
    };

    const shouldShowDeferralRestrictionsMessage = () => {
        return !deferralClass.canChangeDeferrals() && deferralClass.getHasDeferralRestrictions();
    };

    const hasContributionRateChanges = (ignoreWarnings) => {
        if (
            !deferralClass.canChangeDeferrals() ||
            (!ignoreWarnings &&
                deferralClass.getHasDeferralWarnings(deferralWarnings, deferralSubmissionWarnings))
        ) {
            return false;
        }

        const changed =
            deferralClass.getChangedDeferrals(DeferralConstants.category.FULL).length > 0;
        return changed;
    };

    const myContributionsHref = deferralClass.getMyContributionUrl(individualId, primaryPlan);

    const getFooterClass = () => {
        if (getWarningsOnlyModal()) {
            return "warning-footer";
        } else {
            return "";
        }
    };

    const requestRA = () => {
        if (hasContributionRateChanges()) {
            return priorRetireAge < retireAge ? "andIncreaseRA" : "andDecreaseRA";
        } else {
            return priorRetireAge < retireAge ? "noChangeCRIncreaseRA" : "noChangeCRDecreaseRA";
        }
    };

    const requestCR = (isFirst, deferral) => {
        const first = isFirst;
        const increase = deferral.priorValue < deferral.value;

        if (increase) {
            if (first) {
                return "requestIncreaseCR";
            }
            return "requestIncreaseNext";
        } else {
            if (first) {
                return "requestDecreaseCR";
            }
            return "requestDecreaseNext";
        }
    };

    const getPayload = (isAmplitude = false) => {
        const payload = {
            //! There's an issue with the value for the property below. It referece itself, creating a
            //! circular reference which don't allow to parse the value into JSON.
            //! For this reason is exclude from the payload
            // deferrals: deferralClass.getChangedDeferrals(DeferralConstants.category.FULL),
            retireAge: retireAge,
            hasInvestmentChange: showContinueButton(),
            type: ReviewModalEvents.SAVE_CLICK_EVENT,
            source: ReviewModalEvents.REVIEW_CHANGES_MODAL
        };
        if (isAmplitude) {
            delete payload.type;
        }
        return payload;
    };

    const handleAnalyticsActions = () => {
        // eventBus previously expected "this" as a primary argument, and "payload" as secondary argument
        // instead of passing the application/components state/props to GA, we will pass payload 2x in this event
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: ReviewModalEvents.SAVE_CLICK_EVENT,
            payload: { ...getPayload() }
        });

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: "submit_changes_button",
            payload: { ...getPayload(true) }
        });
    };

    const showContinueButton = () => {
        return hasEquityMixChanges() && equityMixWarnings.length === 0;
    };

    const renderCsorDeferrals = (deferrals) => {
        return deferrals.map((deferral, id) => {
            return (
                // eslint-disable-next-line react/no-array-index-key
                <span key={`${deferral.deferralTypeCode}-${id}`}>
                    {deferralClass.isCsorDeferral(deferral) ? (
                        <span>{retirementIncomeTranslations.estimated} </span>
                    ) : null}
                    {deferral.config.displayName}
                    {id !== deferrals.length - 1 ? <span>, </span> : null}
                </span>
            );
        });
    };

    const renderMaxLinks = () => {
        return getWarningsOnlyModal() ? (
            <span>
                <a href={myContributionsHref} className="max-links">
                    {appTranslations.deferrals.contributionType.heading.headline}
                </a>
                .
            </span>
        ) : (
            <span>
                {appTranslations.deferrals.contributionType.heading.headline}{" "}
                {appTranslations.deferrals.restrictions.messagePart2}.
            </span>
        );
    };

    const renderShowRestrictions = () => {
        const showMessage = shouldShowDeferralRestrictionsMessage();

        switch (showMessage) {
            case true:
                return (
                    <p>
                        {appTranslations.deferrals.restrictions.messagePart1}
                        <span>{renderMaxLinks()}</span>

                        {!getWarningsOnlyModal() ? (
                            <span>
                                <br />
                                <br />
                                {appTranslations.deferrals.restrictions.continueRequest}
                            </span>
                        ) : null}
                    </p>
                );
            default:
                return (
                    <p>
                        {reviewChangesTranslations.warnings["model-only-1"]}
                        <span>
                            {renderCsorDeferrals(
                                deferralClass.getChangedDeferrals(DeferralConstants.category.MODEL)
                            )}
                        </span>
                        {reviewChangesTranslations.warnings["model-only-2"]}
                    </p>
                );
        }
    };

    const renderChangedDeferrals = (deferrals, isGrandfathered) => {
        return deferrals.map((deferral, id) => {
            const showDecimals = deferral.value % 1 !== 0;
            const showPriorDecimals = deferral.priorValue % 1 !== 0;

            return isGrandfathered ? (
                <span key={deferral.deferralTypeCode}>
                    {id !== 0 && id === deferrals.length - 1 ? (
                        <span> {reviewChangesTranslations.andYour}</span>
                    ) : null}
                    <span> {deferral.config.displayName}</span>
                    {id !== deferrals.length - 1 ? <span>, </span> : <span> </span>}
                </span>
            ) : (
                <span key={deferral.deferralTypeCode}>
                    {id !== 0 && id !== deferrals.length - 1 ? (
                        <span>{reviewChangesTranslations.andYour}</span>
                    ) : null}
                    {deferrals.length > 1 ? <span>{deferral.config.displayName} </span> : null}
                    {reviewChangesTranslations.contributionRate}
                    <span className="text-success">
                        <strong className="prior-deferral-review">
                            {" " + deferralClass.getDeferralPrefix(deferral)}
                            <NumericFormat
                                thousandSeparator={true}
                                decimalScale={deferralClass.getDeferralDecimals(deferral)}
                                fixedDecimalScale={showPriorDecimals}
                                displayType="text"
                                value={deferral.priorValue}
                            />
                            {deferralClass.getDeferralSuffix(deferral) + " "}
                        </strong>
                    </span>
                    {reviewChangesTranslations.to}
                    <span className="text-success">
                        <strong className="changed-deferral-review">
                            {" " + deferralClass.getDeferralPrefix(deferral)}
                            <NumericFormat
                                thousandSeparator={true}
                                decimalScale={deferralClass.getDeferralDecimals(deferral)}
                                fixedDecimalScale={showDecimals}
                                displayType="text"
                                value={deferral.value}
                            />
                            {deferralClass.getDeferralSuffix(deferral)}
                        </strong>
                    </span>
                    {id === deferrals.length - 1 ? <span>.</span> : null}
                </span>
            );
        });
    };

    const renderDeferralSubmissionWarnings = (submissionWarnings) => {
        return submissionWarnings.length > 0
            ? submissionWarnings.map((warning) => {
                  return (
                      <p key={warning.message}>
                          {warning.message}.
                          <span className="review-msg-part review-contribution-msg-part">
                              {renderChangedDeferrals(
                                  deferralClass.getChangedDeferrals(DeferralConstants.category.FULL)
                              )}
                          </span>
                      </p>
                  );
              })
            : null;
    };

    const renderDeferralWarnings = (deferralWarnings) => {
        return deferralWarningsHasLength()
            ? deferralWarnings.map((warning) => {
                  if (warning === "mixPctAmtCode") {
                      return (
                          <div key={warning} className="alert alert-warning">
                              <p>
                                  {reviewChangesTranslations.warnings.grandfatheredDeferrals1}{" "}
                                  <span className="review-msg-part review-contribution-msg-part">
                                      {renderChangedDeferrals(
                                          deferralClass.getChangedDeferrals(
                                              DeferralConstants.category.FULL
                                          ),
                                          true
                                      )}
                                  </span>
                                  {reviewChangesTranslations.warnings.grandfatheredDeferrals2}
                                  <a href={myContributionsHref}>
                                      {" "}
                                      {reviewChangesTranslations.warnings.myContributions}{" "}
                                  </a>
                                  {reviewChangesTranslations.warnings.grandfatheredDeferrals3}
                              </p>
                              {!getWarningsOnlyModal() ? (
                                  <p>
                                      <span>
                                          {
                                              reviewChangesTranslations.warnings
                                                  .grandfatheredDeferrals4
                                          }
                                      </span>
                                  </p>
                              ) : null}
                          </div>
                      );
                  }
                  return (
                      <div key={warning} className="alert alert-warning">
                          <p>{warning}</p>
                      </div>
                  );
              })
            : null;
    };

    const renderInvestmentWarnings = (investmentWarnings) => {
        return investmentWarnings.length > 0
            ? investmentWarnings.map((warning) => {
                  return (
                      <div key={warning.url} className="alert alert-warning investment-mix-warning">
                          <p>
                              {warning.message1}
                              {getWarningsOnlyModal() ? (
                                  <a href={warning.url}>{warning.urlMessage}</a>
                              ) : null}
                              {!getWarningsOnlyModal() ? (
                                  <span>{`"${warning.urlMessage}"`}</span>
                              ) : null}
                              {warning.message2}
                          </p>
                      </div>
                  );
              })
            : null;
    };

    const shouldShowPerPayPeriodText = (deferral) => {
        const bonusDeferralCodes = [
            "ABONUS",
            "AFTVAR",
            "AFTVR2",
            "AGBVAR",
            "AGRVAR",
            "BBONUS",
            "BEFVAR",
            "BEFVR2",
            "BONUS1",
            "BONUS2",
            "BONUS3",
            "BONUS4",
            "BONUS5",
            "BONUS6",
            "RBONUS",
            "RTHVAR",
            "RTHVR2"
        ];
        return (
            deferralClass.isDollarDeferral(deferral) &&
            !bonusDeferralCodes.some((code) => code === deferral.deferralTypeCode)
        );
    };

    const renderFullDeferrals = (fullDeferrals) => {
        return fullDeferrals.map((deferral, id) => {
            const showDecimals = deferral.value % 1 !== 0;
            const showPriorDecimals = deferral.priorValue % 1 !== 0;

            return (
                <span key={deferral.deferralTypeCode}>
                    {reviewChangesTranslations[requestCR(id === 0, deferral)] + " "}
                    {deferralClass.isCsorDeferral(deferral) ? (
                        <span>{retirementIncomeTranslations.estimated} </span>
                    ) : null}
                    {deferral.config.displayName + " "}
                    {reviewChangesTranslations.contributionRate + " "}
                    <span className="text-success">
                        <strong className="prior-deferral-review">
                            <NumericFormat
                                thousandSeparator={true}
                                decimalScale={deferralClass.getDeferralDecimals(deferral)}
                                fixedDecimalScale={showPriorDecimals}
                                displayType="text"
                                value={deferral.priorValue}
                                prefix={deferralClass.getDeferralPrefix(deferral)}
                                suffix={deferralClass.getDeferralSuffix(deferral)}
                                data-testid={`prev-contribution-${deferral.deferralTypeCode}`}
                            />
                        </strong>
                    </span>
                    {" " + reviewChangesTranslations.to + " "}
                    <span className="text-success">
                        <strong className="changed-deferral-review">
                            <NumericFormat
                                thousandSeparator={true}
                                decimalScale={deferralClass.getDeferralDecimals(deferral)}
                                fixedDecimalScale={showDecimals}
                                displayType="text"
                                value={deferral.value}
                                prefix={deferralClass.getDeferralPrefix(deferral)}
                                suffix={deferralClass.getDeferralSuffix(deferral)}
                            />
                        </strong>
                    </span>
                    {shouldShowPerPayPeriodText(deferral) &&
                        " " + reviewChangesTranslations.perPayPeriod}
                    {hasRetirementAgeChanged(priorRetireAge, retireAge) ||
                    id !== fullDeferrals.length - 1 ? (
                        <span>, </span>
                    ) : (
                        <span>. </span>
                    )}
                </span>
            );
        });
    };

    const renderSummaryRow = (fullDeferrals) => {
        return fullDeferrals.map((deferral, indx) => {
            const showDecimals = deferral.value % 1 !== 0;

            return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`${deferral.deferralTypeCode}-${indx}`}>
                    <div
                        className="change-summary-label"
                        data-testid={`displayname-${deferral.config.displayName}}`}
                    >
                        {deferral.config.displayName} {reviewChangesTranslations.contribRate}:
                    </div>
                    <div className="change-summary-item">
                        <span className="deferral-summary">
                            {deferralClass.getDeferralPrefix(deferral)}
                            <NumericFormat
                                thousandSeparator={true}
                                decimalScale={deferralClass.getDeferralDecimals(deferral)}
                                fixedDecimalScale={showDecimals}
                                displayType="text"
                                value={deferral.value}
                                data-testid={`updated-contribution-${deferral.deferralTypeCode}`}
                            />
                            {deferralClass.getDeferralSuffix(deferral)},{" "}
                            {reviewChangesTranslations.effective}{" "}
                        </span>
                        <span data-testid={`effective-date-${deferral.deferralTypeCode}`}>
                            {deferralClass.getFormattedEffectiveDate(deferral)}
                        </span>
                    </div>
                </div>
            );
        });
    };

    const renderPlanNames = (names) => {
        return names.map((name, id) => {
            return (
                // eslint-disable-next-line react/no-array-index-key
                <span key={`${name}-${id}`}>
                    <span>{name}</span>
                    {id !== names.length - 1 ? <span>,</span> : null}
                </span>
            );
        });
    };

    return (
        <Modal
            dialogClassName="review-changes-container"
            show={showModal}
            onHide={handleCancelChanges}
        >
            {pendingSubmit && <Loader />}
            <div className="change-summary-dialog" data-testid="review-changes-modal">
                <div className="change-summary-container">
                    <Modal.Header>
                        <h2 id={`${getReviewChangesTitle()}Label`} data-testid="review-modal-title">
                            {getReviewChangesTitle()}
                        </h2>

                        <div className="submission-warnings">
                            {!ignoreHighContributions &&
                                deferralPercentTotal >
                                    reviewChangesTranslations.highContributionsThreshold && (
                                    <div
                                        className="alert alert-warning"
                                        data-testid="high-contributions-warning"
                                    >
                                        <p>
                                            {reviewChangesTranslations.warnings.highContributions}
                                        </p>
                                    </div>
                                )}
                            {hasContributionRateChanges(true)
                                ? renderDeferralWarnings(deferralWarnings)
                                : null}
                            {deferralClass.getChangedDeferrals(DeferralConstants.category.MODEL)
                                .length > 0 ? (
                                <div className="alert alert-warning">
                                    {renderShowRestrictions()}
                                </div>
                            ) : null}
                            {deferralClass.getChangedDeferrals(
                                DeferralConstants.category.GRANDFATHERED
                            ).length > 0 ? (
                                <div className="alert alert-warning">
                                    <p>
                                        {reviewChangesTranslations.warnings.grandfatheredDeferrals1}{" "}
                                        <span className="review-msg-part review-contribution-msg-part">
                                            {renderChangedDeferrals(
                                                deferralClass.getChangedDeferrals(
                                                    DeferralConstants.category.GRANDFATHERED
                                                ),
                                                true
                                            )}
                                        </span>
                                        {reviewChangesTranslations.warnings.grandfatheredDeferrals2}
                                        {getWarningsOnlyModal() ? (
                                            <a href={myContributionsHref}>
                                                {" "}
                                                {
                                                    reviewChangesTranslations.warnings
                                                        .myContributions
                                                }{" "}
                                            </a>
                                        ) : (
                                            <span>
                                                {" "}
                                                {
                                                    reviewChangesTranslations.warnings
                                                        .myContributions
                                                }{" "}
                                            </span>
                                        )}
                                        {reviewChangesTranslations.warnings.grandfatheredDeferrals3}
                                    </p>
                                    {!getWarningsOnlyModal() ? (
                                        <p>
                                            <span>
                                                {
                                                    reviewChangesTranslations.warnings
                                                        .grandfatheredDeferrals4
                                                }
                                            </span>
                                        </p>
                                    ) : null}
                                </div>
                            ) : null}

                            {hasContributionRateChanges(true) ? (
                                <div
                                    className={
                                        deferralSubmissionWarnings.length
                                            ? "alert alert-warning"
                                            : ""
                                    }
                                >
                                    {renderDeferralSubmissionWarnings(deferralSubmissionWarnings)}
                                </div>
                            ) : null}

                            {showEquityMixWarnings()
                                ? renderInvestmentWarnings(equityMixWarnings)
                                : null}
                        </div>

                        {!getWarningsOnlyModal() ? (
                            <div className="modal-description">
                                {!deferralWarningsHasLength() ? (
                                    <span className="review-msg-part review-contribution-msg-part">
                                        {renderFullDeferrals(
                                            deferralClass.getChangedDeferrals(
                                                DeferralConstants.category.FULL
                                            )
                                        )}
                                    </span>
                                ) : null}

                                {hasRetirementAgeChanged(priorRetireAge, retireAge) ? (
                                    <span className="review-msg-part review-retirement-msg-part">
                                        <span>{reviewChangesTranslations[requestRA()] + " "}</span>
                                        <span className="text-success">
                                            <strong data-testid="prev-retirement-age">{`${priorRetireAge} `}</strong>
                                        </span>
                                        {reviewChangesTranslations.to}
                                        <span className="text-success">
                                            <strong data-testid="updated-retirement-age">{` ${retireAge}.`}</strong>
                                        </span>
                                    </span>
                                ) : null}
                                {hasEquityMixChanged(
                                    false,
                                    individualOptions,
                                    priorEquityMix,
                                    equityMix
                                ) ? (
                                    <span>
                                        <span className="review-msg-part review-investment-msg-part">
                                            {reviewChangesTranslations.requestIM}
                                        </span>
                                        <span className="review-msg-part review-investment-msg-part">
                                            <span className="text-success">
                                                <strong> {priorEquityMix}%</strong>
                                            </span>{" "}
                                            {reviewChangesTranslations.to}
                                            <span className="text-success">
                                                <strong> {equityMix}%</strong>
                                            </span>
                                            .
                                        </span>
                                    </span>
                                ) : null}
                            </div>
                        ) : null}
                    </Modal.Header>

                    {!getWarningsOnlyModal() ? (
                        <Modal.Body className="change-summary-body">
                            <div className="change-summary-content">
                                {!deferralWarningsHasLength() ? (
                                    <div className="change-summary-row">
                                        {renderSummaryRow(
                                            deferralClass.getChangedDeferrals(
                                                DeferralConstants.category.FULL
                                            )
                                        )}
                                    </div>
                                ) : null}
                                {getBeingUnEnrolledFromMaximizer(
                                    false,
                                    deferralClass,
                                    primaryPlan
                                ) ? (
                                    <div className="change-summary-row">
                                        <div className="change-summary-label">
                                            {
                                                maximizerTranslations.leavingMaximizer
                                                    .reviewConfirmationModal.label
                                            }
                                            :
                                        </div>
                                        <div className="change-summary-item">
                                            {
                                                maximizerTranslations.leavingMaximizer
                                                    .reviewConfirmationModal.value
                                            }
                                        </div>
                                    </div>
                                ) : null}
                                {hasRetirementAgeChanged(priorRetireAge, retireAge) ? (
                                    <div className="change-summary-row">
                                        <div className="change-summary-label">
                                            {reviewChangesTranslations.retireAge}:
                                        </div>
                                        <div
                                            className="change-summary-item"
                                            data-testid="current-retirement-age"
                                        >
                                            {retireAge}
                                        </div>
                                    </div>
                                ) : null}
                                <div className="change-summary-row">
                                    <div className="change-summary-label">
                                        {reviewChangesTranslations.affectedPlan}:
                                    </div>
                                    <ul className="change-summary-item">
                                        <li
                                            className="list-unstyled"
                                            data-testid="review-plan-name"
                                        >
                                            {renderPlanNames(
                                                getAffectedPlanNames(
                                                    deferralClass,
                                                    primaryPlan,
                                                    retireAge,
                                                    priorRetireAge,
                                                    equityMix,
                                                    priorEquityMix,
                                                    individualOptions,
                                                    priorDeferrals,
                                                    deferralWarnings,
                                                    deferralSubmissionWarnings
                                                )
                                            )}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="disclaimer">
                                {hasContributionRateChanges() ? (
                                    <small
                                        className="text-muted"
                                        data-testid="deferral-change-note"
                                    >
                                        {reviewChangesTranslations.deferralChangesNote}
                                    </small>
                                ) : null}

                                {hasEquityMixChanges() &&
                                !equityMixChangeOnly(
                                    false,
                                    deferralList,
                                    priorRetireAge,
                                    retireAge,
                                    individualOptions,
                                    priorEquityMix,
                                    equityMix,
                                    deferralWarnings,
                                    deferralSubmissionWarnings
                                ) ? (
                                    <small className="text-muted">
                                        <br />
                                        {reviewChangesTranslations.mixChangesNote}
                                    </small>
                                ) : null}
                            </div>
                        </Modal.Body>
                    ) : null}

                    <Modal.Footer className={`modal-footer ${getFooterClass()}`}>
                        <div className="footer-content">
                            <div className="review-changes-notice">
                                {hasContributionRateChanges() ? (
                                    <span data-testid="review-change-note">
                                        {reviewChangesTranslations.submitNote}
                                    </span>
                                ) : null}
                            </div>
                            <div className="btns">
                                {!getWarningsOnlyModal() ? (
                                    <div>
                                        <div className="cancel-btn">
                                            <button
                                                type="button"
                                                data-dismiss="modal"
                                                onClick={handleCancelChanges}
                                            >
                                                {appTranslations.common.labels.cancel}
                                            </button>
                                        </div>
                                        <div className="submit-btn">
                                            <button
                                                id="review-changes-btn"
                                                type="button"
                                                disabled={
                                                    !canSubmitChanges(
                                                        deferralList,
                                                        priorRetireAge,
                                                        retireAge,
                                                        individualOptions,
                                                        priorEquityMix,
                                                        equityMix,
                                                        equityMixWarnings
                                                    ) || pendingSubmit
                                                }
                                                onClick={() => {
                                                    handleAnalyticsActions();
                                                    submit(deferralClass);
                                                }}
                                            >
                                                {!equityMixChangeOnly(
                                                    false,
                                                    deferralList,
                                                    priorRetireAge,
                                                    retireAge,
                                                    individualOptions,
                                                    priorEquityMix,
                                                    equityMix,
                                                    deferralWarnings,
                                                    deferralSubmissionWarnings
                                                ) ? (
                                                    <span>{reviewChangesTranslations.submit}</span>
                                                ) : null}
                                                {equityMixChangeOnly(
                                                    false,
                                                    deferralList,
                                                    priorRetireAge,
                                                    retireAge,
                                                    individualOptions,
                                                    priorEquityMix,
                                                    equityMix,
                                                    deferralWarnings,
                                                    deferralSubmissionWarnings
                                                ) ? (
                                                    <span>
                                                        {appTranslations.common.labels.continue}
                                                    </span>
                                                ) : null}
                                            </button>
                                        </div>
                                    </div>
                                ) : null}

                                {getWarningsOnlyModal() ? (
                                    <div className="okay-btn">
                                        <button
                                            id="review-okay-btn"
                                            type="button"
                                            onClick={handleCancelChanges}
                                        >
                                            {appTranslations.common.labels.okay}
                                        </button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </Modal.Footer>
                </div>
            </div>
        </Modal>
    );
};
ReviewChangesModal.propTypes = {
    showModal: PropTypes.bool
};
export default ReviewChangesModal;
