import DeferralConstants from "../../../../constants/DeferralConstants";

interface Config {
    ageCatchupApplicable: string;
    allowAgeCombinedInd: string;
    allowScheduleInd: string;
    defrlAvailCode: string;
    displayName: string;
    displayOrder: number;
    enrollmentGroupCode: string;
    granularity: number;
    incomeStream: string;
    maxDeferral: number;
    maxSlider: number;
    maximizeEligibleInd: string;
    minDeferral: number;
    minRequiredInd: string;
    taxStatus: string;
    type: string;
}

interface Deferral {
    active: boolean;
    config: Config;
    csor: boolean;
    current: boolean;
    defaulted: boolean;
    deferralTypeCode: string;
    effDate: string;
    isPrimaryAndActive: boolean;
    nextPayRollDate: string;
    payrollOption: string;
    pctAmtCode: string;
    specialCatchup: boolean;
    statusCode: string;
    submissionDate: string;
    value: number;
}

const displayDeferral = ({
    deferral,
    id,
    canShowSummary
}: {
    canShowSummary: boolean;
    deferral: Deferral;
    id: number;
}) => {
    const { active, deferralTypeCode, isPrimaryAndActive } = deferral;
    return (
        canShowSummary ||
        active ||
        isPrimaryAndActive ||
        deferralTypeCode === DeferralConstants.typeCodes.HSA ||
        (!active && id === 0)
    );
};

export default displayDeferral;
