import React from "react";

import { RadioOption } from "gw-shared-components";
import PropTypes from "prop-types";

const HsaExpenseOption = ({
    showExpenseOption,
    hsaExpenseOption,
    handleRadioSelected,
    message,
    amount,
    spendCode
}) => {
    const isChecked = hsaExpenseOption === spendCode;

    return showExpenseOption ? (
        <div className="hsa-tab-radio-button-row">
            <RadioOption
                option={message}
                isChecked={isChecked}
                onChangeHandler={handleRadioSelected}
                value={spendCode}
                strongValue={amount}
            />
        </div>
    ) : null;
};
HsaExpenseOption.propTypes = {
    showExpenseOption: PropTypes.bool,
    hsaExpenseOption: PropTypes.string,
    handleRadioSelected: PropTypes.func,
    message: PropTypes.string,
    amount: PropTypes.string,
    spendCode: PropTypes.string
};
export { HsaExpenseOption };
