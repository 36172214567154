import { UPDATE_ACTIVE_DEFERRAL } from "./deferralActionTypes";

/**
 * ACTIONS FOUND IN THIS FILE
 * @updateActiveDeferral
 */

export const updateActiveDeferral = (payload) => {
    return {
        type: UPDATE_ACTIVE_DEFERRAL,
        payload
    };
};
