import React from "react";

interface PaycheckHeaderProps {
    additionalClass?: string;
    title: string;
}

/**
 * Reusable header for the paycheck across the different views.
 */
const PaycheckHeader = (props: PaycheckHeaderProps) => {
    const { title, additionalClass = "" } = props;
    return (
        <header>
            <h2
                className={`title ${additionalClass}`}
                aria-level={2}
                data-testid="paycheck-header"
                id="paycheck-header"
            >
                {title}
            </h2>
        </header>
    );
};

export default PaycheckHeader;
