import { executeGet } from "../utils/apiUtils";
import { getUrl } from "../utils/urlUtils.js";

interface InitDataLiteResponse {
    asOfDate: Date;
    integratedAssets: IntegratedAsset[];
    integratedGoals: IntegratedGoal[];
    irsLimitsRefData: IRSLimits;
    users: User[];
}

interface IRSLimits {
    "1165E": IRSLimitsRefData;
    "401A": IRSLimitsRefData;
    "401K": IRSLimitsRefData;
    "403B": IRSLimitsRefData;
    "457": IRSLimitsRefData;
    "457B": IRSLimitsRefData;
    HSA: IRSLimitsRefData;
    IRA: IRSLimitsRefData;
}
interface IRSLimitsRefData {
    SSWageCap: number;
    catchupEligibleAge: number;
    discountFactor: number;
    higher402gCatchupLimit?: number;
    higher457CatchupLimit?: number;
    higherCatchupEligibleAgeMax?: number;
    higherCatchupEligibleAgeMin?: number;
    higherCatchupLimit?: number;
    hsaFamily?: number;
    hsaFamilyCatchUp?: number;
    hsaSingle?: number;
    hsaSingleCatchUp?: number;
    limit402g: number;
    limit402gCatchup?: number;
    limit402gNextYear: number;
    limit415: number;
    limit457Catchup?: number;
    limit457b?: number;
    limit457bCatchup?: number;
    limitCatchup: number;
    limitIRA?: number;
    limitIRACatchup?: number;
    limitIRANextYear?: number;
    matchableSalaryCap: number;
    rateOfInflation: number;
}

interface IntegratedGoal {
    additionalAttributes: unknown | null;
    annualAmount: number;
    annualAmountPreference: string | null;
    duration: number | null;
    forever: boolean;
    goalCategoryKey: string;
    goalDescription: string;
    goalType: string;
    isNotCOLA: boolean;
    isPreTax: boolean;
    pcapPersonId: string | null;
    startAge: number;
}

interface IntegratedAsset {
    accountType: string;
    accountTypeGroup: string;
    allocationSet: AllocationSet;
    currentBalance: number;
    custodianAccountNumber: string;
    employerMatch: unknown[];
    firmName: string;
    hasSponsorMatch: string;
    isExternal: boolean;
    isManual: boolean;
    owners: Owner[];
    pcapAccountId: string;
    pcapUserId: string;
    productType: string;
}

interface AllocationSet {
    alternatives: number;
    cash: number;
    intlBonds: number;
    intlStocks: number;
    unclassified: number;
    usBonds: number;
    usStocks: number;
}

interface Owner {
    pcapPersonId: string;
    percentage: number;
    role: string;
}

interface NYCGoalData {
    planEnabled: boolean;
    pourOverEnabled: boolean;
}

interface Balance {
    moneyType: string;
    taxStatus: string;
    totalBalance: number;
    vestedBalance: number;
    vestedPercent: number;
}

interface Fund {
    balance: number;
    cusip: string;
    effDate: string;
    fundName: string;
    percent: number;
}

interface Salary {
    base: number;
    nqContributionPct: number | null;
    total: number;
    variable: number;
}

interface CompanyMatchRule {
    effDate: string;
    highThresholdDollar: number;
    highThresholdPercent: number;
    lowThresholdDollar: number;
    lowThresholdPercent: number;
    matchPercent: number;
    maxMatchDollar: number;
    maxMatchPercent: number;
    maxYrOfService: number;
    minYrOfService: number;
    plmuRuleId: string;
    profitSharing: string;
    ruleDesc: string;
    ruleType: string;
    source: string;
    termDate: string;
    tierCd: string;
}

interface DeferralConfig {
    ageCatchupApplicable: string;
    allowAgeCombinedInd: string;
    enrollmentGroupCode: string;
    incomeStream: string;
    taxStatus: string;
}

interface Fund {
    balance: number;
    cusip: string;
    effDate: string;
    fundName: string;
    percent: number;
}

interface Salary {
    base: number;
    nqContributionPct: number | null;
    total: number;
    variable: number;
}

interface Deferral {
    active: boolean;
    config: DeferralConfig;
    current: boolean;
    deferralTypeCode: string;
    payrollOption: string;
    pctAmtCode: string;
    specialCatchup: boolean;
    statusCode: string;
    value: number;
}

interface Limits {
    ageCatchUpMethodCode: string;
    ageCatchupAllowed: boolean;
}

interface Rules {
    allowMultipleDeferrals: boolean;
    antiDiscountEnabled: boolean;
    fwEnabled: boolean;
    hdicEnabled: boolean;
    hvsEnabled: boolean;
    otherAssetsEnabled: boolean;
    specialCatchup: boolean;
    ssEnabled: boolean;
    usePayrollEmployerMatch: boolean;
}

interface RetirementOption {
    confidence: number;
    equityPct: number;
    inclSocialSec: boolean;
    retireAge: number;
}

interface IRSLimits {
    SSWageCap: number;
    catchupEligibleAge: number;
    discountFactor: number;
    higher402gCatchupLimit: number;
    higherCatchupEligibleAgeMax: number;
    higherCatchupEligibleAgeMin: number;
    higherCatchupLimit: number;
    limit402g: number;
    limit402gCatchup: number;
    limit402gNextYear: number;
    limit415: number;
    limitCatchup: number;
    matchableSalaryCap: number;
    rateOfInflation: number;
}

interface Plan {
    balances: Balance[];
    companyMatchRules: CompanyMatchRule[];
    deferrals: Deferral[];
    funds: Fund[];
    id: string;
    irsCode: string;
    irsLimits: IRSLimits;
    limits: Limits;
    payFrequency: string;
    planLvlNycGoalFlag: string;
    planName: string;
    primary: boolean;
    retirementOption: RetirementOption;
    rules: Rules;
    salary: Salary;
    terminated: boolean;
    totalSvcYrs: number;
}

interface Projection {
    a: number;
    c: number;
}

interface ProjectionsMap {
    [key: string]: Projection;
}

interface User {
    age: number;
    clientId: string;
    dateOfBirth: number;
    firstName: string;
    gender: string;
    headOfHousehold: boolean;
    incomeGoalTerm: number;
    incomeGoalUnits: string;
    incomeGoalValue: number;
    individualId: string;
    lastName: string;
    middleName: string;
    nycGoalData: NYCGoalData;
    pcapPersonId: string;
    personId: number;
    plans: Plan[];
    projectionsMap: ProjectionsMap;
    type: string;
}

const getInitDataLite = async (): Promise<InitDataLiteResponse> => {
    const url = getUrl("getInitDataLite");
    const response = await executeGet(url, "initializeDataLiteFault");
    return response.data as InitDataLiteResponse;
};

export default getInitDataLite;
