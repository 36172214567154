import React from "react";

import PropTypes from "prop-types";

/**
 * Renders the modal footer
 */
const Footer = ({
    handleClose,
    canSave,
    mustEnterSalary,
    prompt,
    salary,
    maxSalary,
    spouseSalary,
    hasReducedSalary,
    reducedSalaryMessage
}) => {
    const checkSalaries = salary > maxSalary || spouseSalary > maxSalary;

    return (
        <div className="modal-footer goal-modal" data-testid="footer">
            {hasReducedSalary ? (
                <div className="modal-footer-message">
                    <p>
                        <strong>
                            <sup>*</sup>
                        </strong>
                        {reducedSalaryMessage}
                    </p>
                </div>
            ) : null}
            <div className="modal-footer-buttons">
                <button
                    id="cancel"
                    onClick={() => handleClose(true)}
                    type="button"
                    className="btn btn-link btn-cancel integrated-footer"
                    disabled={mustEnterSalary && prompt}
                >
                    Cancel
                </button>
                <button
                    id="save"
                    type="submit"
                    className="btn btn-pcap-primary btn-save"
                    disabled={!canSave || checkSalaries}
                    data-testid="integrated-goal-modal-save-btn"
                >
                    Save
                </button>
            </div>
        </div>
    );
};

Footer.propTypes = {
    handleClose: PropTypes.func.isRequired,
    canSave: PropTypes.bool.isRequired,
    mustEnterSalary: PropTypes.bool.isRequired,
    prompt: PropTypes.bool.isRequired,
    salary: PropTypes.number.isRequired,
    maxSalary: PropTypes.number.isRequired,
    spouseSalary: PropTypes.number.isRequired,
    hasReducedSalary: PropTypes.bool,
    reducedSalaryMessage: PropTypes.string
};

export default Footer;
