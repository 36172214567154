/**
 *
 * @class common.factory.HubConfigFactory
 * @memberOf common.factory
 * @date 02/23/2015
 * @copyright 2015 Empower Retirement
 * @description
 *      Creates a Hub configuration object from the domain and view model data. When used, it has the feel
 *      of a factory object with static methods like `HubConfigFactory.create()` so you don't need to
 *      create a new object of it to use its methods.
 *
 */

import StringUtil from "core-ui/client/src/app/StringUtil";
import { ObjectUtil } from "gw-shared-components";
import _cloneDeep from "lodash/cloneDeep";
import _each from "lodash/each";
import _find from "lodash/find";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _isNil from "lodash/isNil";
import _max from "lodash/max";
import _min from "lodash/min";

import acctAggrConstants from "../constants/AccountAggregationConstants";
import DeferralMoneyType from "../constants/DeferralMoneyType.js";
import EquityRiskLevels from "../constants/EquityRiskLevel";
import HSAConstants from "../constants/HSAConstants.js";
import IncomeStreams from "../constants/IncomeStreams.js";
import payPeriods from "../constants/PayPeriods.js";
import LoggerFactory from "../factories/LoggerFactory";

const HubConfigFactory = {
    /**
     * Logger
     */
    logger: LoggerFactory.getInstance("HubConfigFactory"),

    /**
     * Creates a hub config object from state.
     *
     * @param state
     * @returns {}
     */
    create: function (state, useOriginal) {
        if (_isNil(state)) {
            throw new Error(StringUtil.supplant(ObjectUtil.errors.UNDEFINED_OR_NULL, ["state"]));
        }

        if (_isNil(useOriginal)) {
            useOriginal = false;
        }

        HubConfigFactory.logger.debug("create()");

        const userHubConfigs = [];

        let dataList = [].concat(state.participant);
        if (state.spouse.exists) {
            dataList = dataList.concat(state.spouse);
        }

        _each(dataList, function (user) {
            if (
                !user.headOfHousehold &&
                (_isNil(user.salary) || _isNil(user.salary.base) || user.salary.base === 0)
            ) {
                HubConfigFactory.logger.debug("User has salary as undefined or zero");
            }

            const incomeGoal = user.incomeGoal.value
                ? user.incomeGoal.value
                : user.incomeGoal.default;
            const incomeGoalValueUnits = user.incomeGoal.valueUnits;
            const sliders = useOriginal
                ? {
                      retirementAge: state.baseline.retirementAge,
                      investmentMix: state.baseline.investments.equityMix
                  }
                : {
                      retirementAge: user.retirementAge,
                      investmentMix: state.investments.equityMix
                  };

            const hubConfig = {
                headOfHousehold: user.headOfHousehold,
                age: user.age,
                salary: HubConfigFactory.createSalary(user.salary),
                dateOfBirth: ObjectUtil.formatTimestamp(user.dateOfBirth),
                specialLimit: user.specialLimit,
                plans: HubConfigFactory.createPlans(state, user, sliders, useOriginal),
                vhFactorSet: user.projectionsMap,
                vhFactorSetBounds: HubConfigFactory.getVhFactorBounds(
                    user,
                    state.investments.equityMix,
                    state.applicationSettings.projectionSettings.confidence
                ), //@TODO spouse equityMix
                applicationSettings: {
                    confidenceLevel: state.applicationSettings.projectionSettings.confidence,
                    monthlyIncomeGoal: incomeGoal,
                    monthlyIncomeGoalUnits: incomeGoalValueUnits,
                    term: state.applicationSettings.projectionSettings.term,
                    equityMix: sliders.investmentMix,
                    rateOfInflation: state.applicationSettings.projectionSettings.rateOfInflation
                },
                otherAssets: HubConfigFactory.createOtherAssets(user.outsideAssets),
                retirementAssets: HubConfigFactory.createRetirementAssets(
                    user.retirementAssets,
                    state.primaryPlan.planRules.allowAntiDiscount
                ),
                socialSecurityInfo: HubConfigFactory.createIntegratedSSInfo(user, state),
                adjustedLEAge: user.adjustedLEAge > 0 ? user.adjustedLEAge : user.defaultLEAge,
                defaultLEAge: user.defaultLEAge
            };
            if (user.linkedAccountBalances) {
                hubConfig.linkedAccountBalances = user.linkedAccountBalances; //@TODO
            }
            if (user.headOfHousehold) {
                hubConfig.retirementAge = sliders.retirementAge;
            } else {
                hubConfig.retirementAge = user.retirementAge;
            }
            hubConfig.externalAssets = HubConfigFactory.createExternalAssets(
                user,
                hubConfig.retirementAge,
                hubConfig.applicationSettings.equityMix,
                state
            );

            userHubConfigs.push(hubConfig);
        });

        return userHubConfigs;
    },

    /**
     * Looops through the vhFactors and sets max and min values for both age and retirementAge.   These values are needed by the HUB.
     * @param {*} user
     */
    getVhFactorBounds: (user, equityPercent, confidenceLevel) => {
        const _ages = [];
        const _retirementAges = [];
        const factors = user.projectionsMap;
        const result = {
            // make sure we have a default
            age: { upper: 0, lower: 0 },
            retirementAge: { upper: 0, lower: 0 },
            equityPercent,
            confidenceLevel
        };

        // parse out the age/retirement age
        _each(factors, function (f, k) {
            //ex: "50~85~10~90": age~retirementAge~equityMix~confidenceLevel
            const parts = k.split("~");

            // this is going to duplicate values in these arrays at times, but it doesn't matter since it would likely
            // be more expensive to conditionally check for and add vs just adding them in duplicate.
            _ages.push(parts[0]);
            _retirementAges.push(parts[1]);
        });

        // find and populate the min/max
        result.age.upper = parseInt(_max(_ages));
        result.age.lower = parseInt(_min(_ages));
        result.retirementAge.upper = parseInt(_max(_retirementAges));
        result.retirementAge.lower = parseInt(_min(_retirementAges));

        return result;
    },

    createSalary: function (salary) {
        if (_isNil(salary)) {
            return {
                base: 0,
                variable: 0
            };
        }
        const configSalary = {};
        configSalary.base = _isNil(salary.base) ? 0 : salary.base;
        //configSalary.base = salary.base;
        // if variable is null or undefined default it to zero, otherwise the Hub produces NaN for many of it's results
        configSalary.variable = 0;
        if (!_isNil(salary.tempVariable)) {
            configSalary.variable = salary.tempVariable;
        } else if (!_isNil(salary.variable)) {
            configSalary.variable = salary.variable;
        }

        return configSalary;
    },

    /**
     * Creates a list of plans for the hub config object from state.
     *
     * @returns {Object} Plan
     */
    createPlans: function (state, user, sliders, useOriginal) {
        if (!user.headOfHousehold) {
            return [];
        }

        HubConfigFactory.logger.debug("createPlans()");
        const equityMix = useOriginal
            ? state.baseline.investments.equityMix
            : state.investments.equityMix;

        const result = [];
        const plansList = user.plans;
        let maximizer;

        // Latest Updates for maximizer is on state.primaryPlan.maximizer

        const userHasSalary =
            !_isNil(user.salary) &&
            (user.salary.base + (user.salary.tempVariable || 0) + (user.salary.variable || 0) ===
                user.salary.total ||
                user.salary.base + (user.salary.variable || 0) === user.salary.total);

        if (plansList.length > 0) {
            _each(plansList, function (plan) {
                maximizer =
                    plan.isPrimary && plan.maximizer ? state.primaryPlan.maximizer : plan.maximizer;
                const planObj = {};

                planObj.retirementAge = sliders.retirementAge;
                planObj.equityPercent = equityMix;

                // salary - use the active plan's salary if a plans' salary does not exist;
                planObj.salary = {};

                // Basically we want all plans to have a salary object for the purposes
                // of projecting deferrals - for now if there is no salary we use the activePlan's
                // salary;

                const planSalary = userHasSalary
                    ? user.salary
                    : !_isNil(plan.salary)
                      ? plan.salary
                      : state.primaryPlan.salary;

                planObj.salary = HubConfigFactory.createSalary(planSalary);

                // balances
                planObj.balances = [];
                const balancesArray = plan.balances;
                _each(balancesArray, function (balance) {
                    const balanceObj = {};
                    let moneyType = ""; //Using local variable to set source rahter then balanceObj since moneyType is no longer part of this object.
                    balanceObj.value = balance.totalBalance;

                    switch (balance.moneyType) {
                        case "BEF":
                            moneyType = "EE";
                            break;
                        case "ERB":
                            moneyType = "ER";
                            break;
                        default:
                            moneyType = balance.moneyType;
                            break;
                    }
                    balanceObj.source = moneyType;
                    balanceObj.taxStatus = balance.taxStatus;
                    planObj.balances.push(balanceObj);
                });

                // new employer match using payroll
                const planRules = plan.planRules;
                if (planRules && !_isNil(plan.employerMatch) && plan.employerMatch.length) {
                    planObj.planMatchRules = HubConfigFactory.createEmployerMatch(
                        plan.employerMatch
                    );
                }
                //old company match for plans like apple
                if (planRules && !_isNil(plan.companyMatch)) {
                    planObj.companyMatch = HubConfigFactory.createCompanyMatch(plan.companyMatch);
                }

                //special catchup  ; allowWCIRView ; allowPreRetireeView
                if (planRules) {
                    planObj.isSpecialCatchup = planRules.specialCatchup;
                    planObj.allowPreRetireeView = _isNil(planRules.allowPreRetireeView)
                        ? false
                        : planRules.allowPreRetireeView;
                    planObj.allowWCIRView = _isNil(planRules.allowWCIRView)
                        ? false
                        : planRules.allowWCIRView;
                }

                // deferrals
                planObj.deferrals = HubConfigFactory.createDeferrals(plan, maximizer, useOriginal);

                planObj.payFrequency = !_isNil(plan.payFrequency)
                    ? plan.payFrequency
                    : state.primaryPlan.payFrequency;

                //TODO: we don't know about NDC yet
                planObj.nondiscretionaryContribution = 0; // ObjectUtil.isDefined(plan.discretionaryContribution) ?

                // plan limits
                if (!_isNil(plan.limits)) {
                    planObj.isEligibleForCatchup = plan.limits.ageCatchupAllowed
                        ? plan.limits.ageCatchupAllowed
                        : false;

                    /**
                     * a code of "C" means combined and the Hub would expect true,
                     * "S" is separate and Hub would expect false
                     */
                    switch (plan.limits.ageCatchupMethodCode) {
                        case "C":
                            planObj.catchupCombined = true;
                            break;
                        case "S":
                            planObj.catchupCombined = false;
                            break;
                    }
                }

                // is this the primary plan
                planObj.isPrimary = plan.isPrimary;

                //IRS Limits - now part of the Plan - for MCP
                planObj.limits = HubConfigFactory.createLimits(
                    plan.IRSLimits,
                    state.primaryPlan.IRSLimits,
                    plan
                );

                planObj.userIsValidForHigherLimit = false;
                planObj.validRange = "";
                if (plan.higherCULimitInd) {
                    // age = 60 - 63
                    if (
                        user.age >= planObj.limits.higherCatchupEligibleAgeMin &&
                        user.age <= planObj.limits.higherCatchupEligibleAgeMax &&
                        plan.isPrimary
                    ) {
                        planObj.userIsValidForHigherLimit = true;

                        if (plan.irsCode === "401K" || plan.irsCode === "403B") {
                            planObj.limits.higher402gLimit =
                                planObj.limits.higher402gCatchupLimit -
                                planObj.limits.higherCatchupLimit;
                        }

                        if (plan.irsCode === "457") {
                            planObj.limits.higher457Limit =
                                planObj.limits.higher457CatchupLimit -
                                planObj.limits.higherCatchupLimit;
                        }
                        planObj.limits.higherCatchupDiffLimit =
                            planObj.limits.higherCatchupLimit - planObj.limits.limitCatchup;

                        if (
                            // ie. retire age 61 - 63
                            planObj.retirementAge > planObj.limits.higherCatchupEligibleAgeMin &&
                            planObj.retirementAge <= planObj.limits.higherCatchupEligibleAgeMax
                        ) {
                            planObj.validRange = "mid";
                        } else if (
                            planObj.retirementAge > planObj.limits.higherCatchupEligibleAgeMax
                        ) {
                            // retire age 64 and up
                            planObj.validRange = "high";
                        }
                    }
                }

                //HSA Stuff - Optum HSA
                planObj.hsa =
                    plan.irsCode === HSAConstants.IRS_CODE
                        ? HubConfigFactory.createHSAObject(plan)
                        : {};

                // name of the plan
                planObj.planName = plan.name;
                planObj.id = plan.id;

                // the plan funds
                planObj.funds = plan.investmentHoldings;

                // is ppt still part of plan
                planObj.isTerminated = plan.terminated;

                // years of service at plan
                planObj.yearsOfService = plan.yearsOfService;

                if (!_isNil(plan.trsFlexAccountInfo)) {
                    planObj.trsFlexAccountInfo = _cloneDeep(plan.trsFlexAccountInfo);
                    planObj.trsFlexAccountInfo.earlyRetirementDate = ObjectUtil.formatDate(
                        planObj.trsFlexAccountInfo.earlyRetirementDate
                    );
                    planObj.trsFlexAccountInfo.normalRetirementDate = ObjectUtil.formatDate(
                        planObj.trsFlexAccountInfo.normalRetirementDate
                    );
                    //todo: fix in hub, this should be unnecessary.
                    planObj.trsFlexAccountInfo.trsYearsOfService =
                        planObj.trsFlexAccountInfo.yearsOfService;
                    /* It's ok to send a null value for the payCreditRate to the hub when it's not applicable */
                    planObj.trsFlexAccountInfo.payCreditRate = _isNil(
                        plan.trsFlexAccountInfo.payCreditRate
                    )
                        ? null
                        : plan.trsFlexAccountInfo.payCreditRate;

                    planObj.trsFlexAccountInfo.presentvalue = planObj.trsFlexAccountInfo
                        .presentvalue
                        ? planObj.trsFlexAccountInfo.presentvalue
                        : false;
                    planObj.trsFlexAccountInfo.costOfLivingAdjustedPercentage = planObj
                        .trsFlexAccountInfo.costOfLivingAdjustedPercentage
                        ? Number(planObj.trsFlexAccountInfo.costOfLivingAdjustedPercentage) / 100
                        : null;
                    planObj.trsFlexAccountInfo.addnlRetirementData =
                        HubConfigFactory.reformatTRSFlexAddnlData(plan.trsFlexAccountInfo);

                    // " retirementpartner.com" in the URL means it is a dev environment
                    //TODO: Tech Debt, make dynamic
                    const PROD_CREDIT_GAID = "150012-T1";
                    const DEV_CREDIT_GAID = "150026-T1";
                    const DEV_URL = "retirementpartner.com";
                    const isDevelopment = window.location.hostname.indexOf(DEV_URL) >= 0;
                    if (isDevelopment) {
                        if (
                            !_isNil(plan.trsFlexAccountInfo.gaId) &&
                            planObj.trsFlexAccountInfo.gaId === DEV_CREDIT_GAID
                        ) {
                            planObj.trsFlexAccountInfo.gaId = PROD_CREDIT_GAID;
                        }
                    }
                    //END hack
                }

                planObj.irsCode = plan.irsCode;

                result.push(planObj);
            });
        }

        return result;
    },

    /**
     * Create company match object using liat tables for plans like apple which are not covered by the payroll system
     * @param companyMatch
     * @returns {{ruleId: (*|string), matchableDeferralTypes: (*|matchableDeferralTypes|Array), matchOnCatchup: boolean, matchTiers: Array}}
     */
    createCompanyMatch: function (companyMatch) {
        if (!_isNil(companyMatch)) {
            const companyMatchObj = {
                matchableDeferralTypes: companyMatch.matchableDeferralTypes,
                matchOnCatchup:
                    companyMatch.matchableDeferralTypes &&
                    companyMatch.matchableDeferralTypes.indexOf("CATCHUP") > -1,
                matchTiers: [],
                ruleTypes: companyMatch.ruleTypes
            };

            _each(companyMatch.matchTiers, (tier) => {
                const matchTier = tier.map((match) => {
                    return {
                        lowThresholdPercent: match.lowThresholdPercent,
                        highThresholdPercent: match.highThresholdPercent,
                        lowThresholdAmount: match.lowThresholdDollar, //lowThresholdDollar in DM
                        highThresholdAmount: match.highThresholdDollar, //highThresholdDollar in DM
                        minYrOfService: match.minYrOfService,
                        maxYrOfService: match.maxYrOfService,
                        matchPercent: match.matchPercent,
                        maxMatchAmount: match.maxMatchDollar, //maxMatchDollar in DM
                        maxSalaryMatchPercent: match.maxMatchPercent, //maxMatchPercent in DM
                        tierPriority: match.tierPriority, //defines the order the tiers should be calculated in. Consider adding to DM.
                        profitSharing: match.profitSharing,
                        ruleId: match.ruleId,
                        ruleType: match.ruleType,
                        ruleDesc: match.ruleDesc,
                        effDate: match?.effDate,
                        termDate: match?.termDate
                    };
                });

                companyMatchObj.matchTiers.push({ matchTier });
            });

            return companyMatchObj;
        }
    },

    /**
     * Create employer match array using payroll tables
     * @param matchRules
     * @returns {Array}
     */
    createEmployerMatch: function (matchRules) {
        const rules = [];
        _each(matchRules, function (rule) {
            const criteria = [];
            const mnty = [];
            _each(rule.matchCriteria, function (crit) {
                criteria.push({
                    id: crit.id,
                    pmrId: crit.pmrId,
                    sequenceNo: crit.sequenceNo,
                    matchCalculatedOn: crit.matchCalculatedOn,
                    tierCalcLevel: crit.tierCalcLevel,
                    tiersBasedOn: crit.tiersBasedOn,
                    tiers: crit.tiers,
                    detailSet: crit.detailSet
                });
            });

            _each(rule.moneyTypes, function (moneyType) {
                mnty.push({
                    planMatchRuleGrpDefMnty: moneyType.planMatchRuleGrpDefMnty,
                    grpDefMntyDefType: moneyType.grpDefMntyDefType,
                    grpDefMnty: moneyType.grpDefMnty
                });
            });

            rules.push({
                id: rule.id,
                planId: rule.planId,
                calcLevel: rule.calcLevel,
                calcSource: rule.calcSource,
                calcType: rule.calcType,
                ageCatchupErMatchCalInd: rule.ageCatchupErMatchCalInd,
                sdmtCode: rule.sdmtCode,
                gdmtSeqnbr: rule.gdmtSeqnbr,
                description: rule.description,
                matchCriteria: criteria,
                moneyTypes: mnty
            });
        });

        return rules;
    },

    /**
     * Indicates if the user is enrolled in maximizer.
     * @returns {boolean}
     */
    isEnrolledInMaximizer: function (maximizer, useOriginal) {
        return (
            !_isNil(maximizer) && maximizer.isOngoing && (!maximizer.isUnenrolling || useOriginal)
        );
    },

    /**
     * Indicates if the maximizer deferral values should be used over the default, plan deferral values.
     * In order to use maximizer the following must be true:
     *
     *        - User must be enrolled in maximizer
     *        - The deferral we could use the maximizer value for must be percent based.
     *        - Maximizer must have deferral percent values available to supplement.
     *
     * @param deferral The deferral to check if percent based.
     * @param maximizer.
     * @returns {boolean}
     */
    useMaximizerValue: function (deferral, maximizer, useOriginal) {
        return (
            HubConfigFactory.isEnrolledInMaximizer(maximizer, useOriginal) &&
            deferral.valueUnits === DeferralMoneyType.PERCENT &&
            maximizer.deferralPercent
        );
    },

    /**
     * Accessor method to the maximizer's deferral value. Must find a match in the plan and maximizer deferrals
     * by comparing the code: BEFORE || ROTH
     *
     * @param deferral The deferral to check the type of.
     * @returns {number} Maximizer deferral value.
     */
    getMaximizerValue: function (deferral, maximizer) {
        const item = _find(maximizer.deferralPercent, { deferralCode: deferral.deferralTypeCode });
        return item && item.deferralPercent ? item.deferralPercent : deferral.value;
    },

    createDeferrals: function (plan, maximizer, useOriginal) {
        const deferrals = [];
        const planDeferrals = useOriginal ? plan.deferrals : plan.newDeferrals;
        let deferral;

        _each(planDeferrals, function (def) {
            deferral = {};
            deferral.deferralTypeCode = def.deferralTypeCode;
            deferral.salaryComponent = IncomeStreams[def.config.incomeStream];
            deferral.valueUnits = def.valueUnits;
            deferral.taxStatus = def.config.taxStatus;
            deferral.catchupApplicable = def.config.ageCatchupApplicable;
            deferral.catchupAllowAgeCombined = def.config.allowAgeCombinedInd;
            deferral.displayName = def.config.displayName;

            if (def.autoIncrease && def.value !== def.autoIncrease.stopAtValue) {
                deferral.autoIncrease = def.autoIncrease;
                deferral.autoIncrease.valueUnits = deferral.valueUnits;
                deferral.autoIncrease.nextScheduleDate = ObjectUtil.formatDate(
                    deferral.autoIncrease.nextScheduleDate
                );
            }

            // make sure to use the maximizer deferral values if the user is enrolled in maximizer
            // BUT only use the maximizer values if this is the first calculation and the deferral
            // is percent.
            // if a user moves the slider they are unenrolled from maximizer
            // we only project BEFORE deferrals. Wiping out a ROTH if there is one -IM

            const originalDeferral = _find(plan.deferrals, {
                deferralTypeCode: def.deferralTypeCode
            });
            deferral.value = HubConfigFactory.getDeferralValue(
                originalDeferral,
                def,
                maximizer,
                useOriginal
            );

            // use future dated deferral value and units for hub
            if (
                originalDeferral &&
                originalDeferral.futureDated &&
                !originalDeferral.futureDated.isOneTime &&
                originalDeferral.value === def.value
            ) {
                deferral.valueUnits =
                    originalDeferral.futureDated.units === "AMT" ? "dollars" : "percent";
            }

            deferrals.push(deferral);
        });

        return deferrals;
    },

    getDeferralValue: function (originalDeferral, newdDeferral, maximizer, useOriginal) {
        let value = newdDeferral.value;

        /*
         * use future dated deferral value for hub
         *
         * NOTE: we are not displaying one-time future dated deferrals
         * still performing the one-time flag check to be future-proof
         */
        if (
            originalDeferral &&
            originalDeferral.futureDated &&
            !originalDeferral.futureDated.isOneTime &&
            originalDeferral.value === newdDeferral.value
        ) {
            value = originalDeferral.futureDated.value;
        }

        if (_isNil(newdDeferral.value)) {
            value = 0;
        }
        // eslint-disable-next-line react-hooks/rules-of-hooks
        if (HubConfigFactory.useMaximizerValue(newdDeferral, maximizer, useOriginal)) {
            value = HubConfigFactory.getMaximizerValue(newdDeferral, maximizer);
            HubConfigFactory.logger.info(
                "createPlans( Using maximizer deferral value `{0}` for code `{1}`. " +
                    "Normal deferral value without maximizer = `{2}` )",
                [newdDeferral.value, newdDeferral.deferralTypeCode, newdDeferral.value]
            );
        }
        HubConfigFactory.logger.info(
            "createPlans( Using plan deferral value `{1}` for code `{1}` )",
            [newdDeferral.value, newdDeferral.deferralTypeCode]
        );
        return value;
    },

    createSSInfo: function (user, state) {
        const config = {
            ssBenefitsMap: [],
            ageOverride: null,
            valueOverride: null
        };
        //case where there is no social security
        if (
            !state.primaryPlan.planRules.ssEnabled ||
            !state.applicationSettings.projectionSettings.includeSocialSecurity
        ) {
            return config;
        }

        config.ssBenefitsMap = state.socialSecurity.ssBenefitsMap || [];

        let ssData = user.headOfHousehold
            ? _get(state, "socialSecurity.socialSecurityUser", null)
            : _get(state, "socialSecurity.socialSecuritySpouse", null);

        if (ssData) {
            if (ssData.socialSecurityPerson) {
                ssData = ssData.socialSecurityPerson;
            }
            const ssBenefitsMap = {};

            if (ssData.sampleSocialSecurityTable) {
                ssData.sampleSocialSecurityTable.forEach(
                    (row) => (ssBenefitsMap[row.age.toString()] = row.monthly)
                );
            }

            config.ssBenefitsMap = ssBenefitsMap;
            if (ssData) {
                switch (ssData.overrideId) {
                    case "0": // age override by system
                    case "1": // age override by user
                        config.ageOverride = ssData.collectionAgeOverride;
                        break;
                    case "2": // age and amount override by user
                    case "4": // amount override by user, retirement age
                    case "5": // amount override by user, default age
                        config.valueOverride = ssData.monthlyAmountOverride;
                        break;
                    default:
                        break;
                    //case "3"
                    //this is 'at retirement age' with no value override, do nothing
                }
            }
        }
        return config;
    },

    createIntegratedSSInfo: function (user, state) {
        const config = {
            ssBenefitsMap: [],
            ageOverride: null,
            valueOverride: null
        };
        //case where there is no social security
        if (
            !state.primaryPlan.planRules.ssEnabled ||
            !state.applicationSettings.projectionSettings.includeSocialSecurity
        ) {
            return config;
        }

        const ssData = user.headOfHousehold
            ? _get(state, "socialSecurity.socialSecurityUser", null)
            : _get(state, "socialSecurity.socialSecuritySpouse", null);

        function setBenefitsMap(benefits) {
            if (_isNil(benefits)) {
                return [];
            }
            const ssBenefitsMap = {};
            Object.entries(benefits).map(([key, value]) => {
                const monthlyAmount = Math.round(value / 12);
                ssBenefitsMap[key] = monthlyAmount;
                return { [key]: value };
            });
            return ssBenefitsMap;
        }

        config.ssBenefitsMap = setBenefitsMap(ssData.ssBenefitByStartAge) || [];
        config.ageOverride = null;

        if (
            ssData.monthlyAmountOverride !== "" &&
            !ObjectUtil.isUndefinedOrNull(ssData.monthlyAmountOverride)
        ) {
            config.valueOverride = Number(ssData.monthlyAmountOverride);
        } else if (ssData.estimatedAmountCheckboxStatus || ssData.ageOverride) {
            config.ageOverride = ssData.startReceiveAge;
        }

        return config;
    },

    findRetirementAgeFitForSSMap: function (ra, map) {
        let min = 65;
        let max = 65;
        for (const k in map) {
            if (Object.prototype.hasOwnProperty.call(map, k)) {
                const num = Number(k);
                max = Math.max(num, max);
                min = Math.min(num, min);
            }
        }
        return Math.min(Math.max(ra, min), max);
    },

    assetIsActive: function (asset) {
        return _isNil(asset.acctAggrStatus)
            ? true
            : asset.acctAggrStatus === acctAggrConstants.ACTIVE;
    },

    /**
     * Create the list of other assets for the Hub config object.
     * @param assets
     * @returns {Array}
     */
    createOtherAssets: function (assets) {
        const result = [];
        _each(assets, function (asset) {
            const config = {};
            config.categoryId = asset.type;
            config.value = asset.value;
            config.employerContribution = asset.employerContribution;
            result.push(config);
        });

        return result;
    },

    createExternalAssets: function (user, retirementAge, equityMix, state) {
        const externalAssets = [];
        if (user.externalAssets && user.externalAssets.length > 0) {
            _each(user.externalAssets, function (asset) {
                externalAssets.push(
                    HubConfigFactory.createIntegratedExternalAsset(
                        asset,
                        user,
                        retirementAge,
                        equityMix,
                        state.irsLimitsRefData,
                        state.primaryPlan.IRSLimits
                    )
                );
            });
        }

        return externalAssets;
    },

    createIntegratedExternalAsset: function (
        asset,
        user,
        retirementAge,
        equityMix,
        irsLimitsRefData,
        irsLimits
    ) {
        const assetForLimit = asset.accountTypeCode === "457F" ? "457" : asset.accountTypeCode;
        const catchupApplicable = user.age >= 50;
        const employer = String(asset.dataSource).toUpperCase() === "EMP";
        if (asset.accountTypeCode === "IRA") {
            irsLimitsRefData[assetForLimit].limit415 = irsLimitsRefData[assetForLimit].limitIRA;
        }
        const assetPlan = {
            retirementAge: retirementAge,
            equityMix: HubConfigFactory.createIntegratedAllocationtMix(asset, equityMix),
            salary: HubConfigFactory.createSalary(user.salary),
            balances: [
                {
                    source: employer ? "ER" : "EE",
                    taxStatus: asset.accountTypeSubtype,
                    value: asset.accountBalance ? asset.accountBalance : 0
                }
            ],
            companyMatch: HubConfigFactory.createCompanyMatchForIntegratedAsset(asset, user),
            profitSharing: HubConfigFactory.createProfitSharingForIntegratedAsset(asset, user),
            isSpecialCatchup: false,
            deferrals: HubConfigFactory.createDeferralsForIntegratedAsset(
                asset,
                catchupApplicable,
                employer
            ),
            payFrequency: payPeriods["A"],
            isEligibleForCatchup: catchupApplicable,
            catchupCombined: "Y",
            limits: HubConfigFactory.createLimits(irsLimitsRefData[assetForLimit], irsLimits),
            planName: asset.accountProviderName + asset.accountTypeCode,
            isTerminated: false,
            yearsOfService: 0,
            irsCode: asset.accountTypeCode,
            employer: employer,
            acctAggrStatus: asset.acctAggrStatus,
            externalSourceName: asset.externalSourceName,
            monthlyIncomeEST: 0,
            assetType: "externalAsset",
            owners: asset.owners,
            source: "PCAP"
        };

        return assetPlan;
    },

    createExternalAsset: function () {
        return null;
    },

    createRetirementAssets: function (assets, allowAntiDiscount) {
        const retirementAssets = [];
        _each(assets, function (asset) {
            const antiDiscountAmount =
                allowAntiDiscount && asset.costOfLivingAdjustable ? asset.adjustmentAmount : 0;
            const retAsset = {
                ...asset,
                antiDiscountAmount
            };
            retirementAssets.push(HubConfigFactory.createRetirementAsset(retAsset));
        });
        return retirementAssets;
    },

    createRetirementAsset: function (asset) {
        const assetPlan = asset;
        assetPlan.assetType = "retirementIncome";
        assetPlan.monthlyIncomeEST = asset.monthlyIncomeEST || 0;

        return assetPlan;
    },

    createAssetInvestmentMix: function (asset, equityMix) {
        let newEquityMix = equityMix;
        if (asset && asset.investment) {
            switch (asset.investment.plInvestmentTypeCode) {
                case "ASSTALLOC":
                    // eslint-disable-next-line no-case-declarations
                    let mix = 0;
                    _each(asset.investment.investmentAssetAllocation, function (allocation) {
                        if (EquityRiskLevels.assetCodes[allocation.plAssetCode]) {
                            mix += allocation.assetAllocationPercentage;
                        }
                    });
                    newEquityMix = Math.round(mix);
                    break;
                case "RSKPRT":
                    if (
                        EquityRiskLevels.riskLevels[
                            asset.investment.investmentRiskBasedPortfolio.plRiskLevelCode
                        ]
                    ) {
                        newEquityMix =
                            EquityRiskLevels.riskLevels[
                                asset.investment.investmentRiskBasedPortfolio.plRiskLevelCode
                            ] * 100;
                    }
                    break;
                default:
                    break;
            }
        }
        newEquityMix = newEquityMix > 100 ? 100 : newEquityMix;
        return newEquityMix;
    },

    // integrated user
    createIntegratedAllocationtMix: function (asset) {
        let totalEquity = 0;

        if (asset.investment) {
            totalEquity +=
                asset.investment.usStocks +
                asset.investment.intlStocks +
                asset.investment.alternatives;
            totalEquity += asset.investment.unclassified * 0.6;

            totalEquity =
                Math.round(totalEquity) <= 100
                    ? Math.round(totalEquity) > 0
                        ? Math.round(totalEquity)
                        : 0
                    : 100;

            return totalEquity;
        } else {
            return 0;
        }
    },

    // integrated user
    createDeferralsForIntegratedAsset: function (asset, catchupApplicable, employer) {
        const deferrals = [];

        if (asset && asset.currentContribution && asset.currentContribution.contributionDetail) {
            _each(asset.currentContribution.contributionDetail, function (assetDeferral) {
                const deferral = {
                    catchupAllowAgeCombined: catchupApplicable ? "Y" : "N",
                    catchupApplicable: catchupApplicable ? "Y" : "N",
                    deferralTypeCode: asset.accountTypeSubtype,
                    displayName: asset.accountTypeSubtype,
                    salaryComponent: "base",
                    taxStatus: asset.accountTypeSubtype,
                    value: assetDeferral.contributionValue,
                    valueUnits: assetDeferral.contributionPercentageFlag ? "percent" : "dollars",
                    employer: employer
                };

                deferrals.push(deferral);
            });
        }
        return deferrals;
    },

    /**
     * called from createExternalAsset.  converts external asset deferrals into plan-like deferrals
     * @param {*} asset
     * @param {String} catchupApplicable //should be "Y" or "N"
     */
    createDeferralsForAsset: function (asset, catchupApplicable, employer) {
        const deferrals = [];
        const findTaxStatus = function (type) {
            //PRE=BEFORE, ROTH = ROTH, PST=AFTER
            switch (type) {
                case "PRE":
                    return "BEFORE";
                case "PST":
                    return "AFTER";
                default:
                    return type;
            }
        };

        if (asset && asset.currentContribution && asset.currentContribution.contributionDetail) {
            _each(asset.currentContribution.contributionDetail, function (assetDeferral) {
                const hasCatchup =
                    assetDeferral.plContributionTypeCode === "PST" ? "N" : catchupApplicable;
                const contributionCode =
                    assetDeferral.plContributionTypeCode === "PRE"
                        ? "BEFORE"
                        : assetDeferral.plContributionTypeCode === "PST"
                          ? "AFTER"
                          : assetDeferral.plContributionTypeCode;
                const deferral = {
                    catchupAllowAgeCombined: hasCatchup,
                    catchupApplicable: hasCatchup,
                    deferralTypeCode: contributionCode,
                    displayName: contributionCode,
                    salaryComponent: "base",
                    taxStatus: findTaxStatus(assetDeferral.plContributionTypeCode),
                    value: assetDeferral.contributionValue,
                    valueUnits: assetDeferral.contributionPercentageFlag ? "percent" : "dollars",
                    employer: employer
                };

                deferrals.push(deferral);
            });
        }
        return deferrals;
    },

    // integrated user
    // dollar amount for contribution is considered annual amount
    createCompanyMatchForIntegratedAsset: function (asset, user) {
        if (!_isNil(asset.currentContribution)) {
            const companyMatchObj = {
                ruleTypes: ["RULE4"],
                matchableDeferralTypes: [String(asset.accountTypeSubtype)],
                matchOnCatchup: false,
                matchTiers: []
            };

            if (
                !_isNil(asset.currentContribution.employerMatchDetail) &&
                asset.currentContribution.employerMatchDetail.length > 0
            ) {
                //sort tiers to make sure they are calculated correctly
                asset.currentContribution.employerMatchDetail.sort(function (a, b) {
                    return a.seq - b.seq;
                });

                let lowThreshold = 0;
                const matchTier = [];
                let counter = 1;

                _each(asset.currentContribution.employerMatchDetail, function (tier) {
                    // Determine Percent vs Dollar
                    let startPercentValue = 0;
                    let endPercentValue = 0;
                    let startDollarValue = 0;
                    let endDollarValue = 0;

                    // match limit value
                    if (tier.contributionDollarRange) {
                        // convert dollar to percent
                        startDollarValue = tier.contributionDollarRange.start
                            ? tier.contributionDollarRange.start
                            : 0;
                        startPercentValue = (startDollarValue / user.salary.base) * 100;

                        endDollarValue = tier.contributionDollarRange.end
                            ? tier.contributionDollarRange.end
                            : 0;
                        endPercentValue = Math.round((endDollarValue / user.salary.base) * 100);
                    } else if (tier.contributionPctRange) {
                        startPercentValue = tier.contributionPctRange.start
                            ? tier.contributionPctRange.start
                            : 0;
                        endPercentValue = tier.contributionPctRange.end
                            ? tier.contributionPctRange.end
                            : 0;
                    }

                    const matchTierItem = {
                        startPercentValue,
                        lowThresholdPercent: lowThreshold,
                        highThresholdPercent: endPercentValue + lowThreshold,
                        lowThresholdAmount: 0,
                        highThresholdAmount: 0,
                        minYrOfService: 0,
                        maxYrOfService: 0,
                        maxMatchAmount: 0,
                        maxSalaryMatchPercent: 0,
                        profitSharing: "N",
                        matchPercent: tier.matchPercent ? tier.matchPercent : 0,
                        tierPriority: tier.seq, // defines the order the tiers should be calculated in.
                        ruleType: "RULE4",
                        ruleId: String(counter),
                        ruleDesc: null
                    };
                    counter++;
                    matchTier.push(matchTierItem);
                    lowThreshold = tier.matchPercent + lowThreshold;
                });

                const matchTierObj = {
                    matchTier: matchTier
                };

                companyMatchObj.matchTiers.push(matchTierObj);
            }

            return companyMatchObj;
        }
    },

    createCompanyMatchForAsset: function (asset) {
        if (
            !_isNil(asset.currentContribution) &&
            !_isNil(asset.currentContribution.employerMatchDetail)
        ) {
            const companyMatchObj = {
                ruleTypes: ["RULE4"],
                matchableDeferralTypes: ["BEFORE", "ROTH"],
                matchOnCatchup: false,
                matchTiers: []
            };

            //tiers are sorted in domainModelFactory.  this could cause issues on adding a new other asset
            // We should call the createExternalAsset function for any new asset.
            let lowThreshold = 0;
            const matchTier = [];
            let counter = 1;
            _each(asset.currentContribution.employerMatchDetail, function (tier) {
                const matchTierItem = {
                    lowThresholdPercent: lowThreshold,
                    highThresholdPercent: tier.limitValue + lowThreshold,
                    matchPercent: tier.matchValue,
                    tierPriority: tier.sequenceNumber, //defines the order the tiers should be calculated in.
                    ruleType: "RULE4",
                    ruleId: String(counter),
                    ruleDesc: null
                };
                counter++;
                matchTier.push(matchTierItem);
                lowThreshold = tier.limitValue + lowThreshold;
            });
            const matchTierObj = {
                matchTier: matchTier
            };
            companyMatchObj.matchTiers.push(matchTierObj);
            return companyMatchObj;
        }
    },

    // integrated user
    createProfitSharingForIntegratedAsset: function (asset, user) {
        let profitSharing = null;
        if (asset.currentContribution) {
            if (!_isNil(asset.currentContribution.profitSharingAmount)) {
                // convert dollar to percent logic
                if (
                    String(asset.currentContribution.profitSharingType).toUpperCase() === "DOLLAR"
                ) {
                    asset.currentContribution.profitSharingPercentage = Math.round(
                        (asset.currentContribution.profitSharingAmount / user.salary.base) * 100
                    );
                    asset.currentContribution.profitSharingType = "PERCENT";
                }
            }

            profitSharing = [
                {
                    matchPercent: asset.currentContribution.profitSharingPercentage,
                    matchAmount: 0,
                    maxMatchDollar: 0, //todo FC: this is not yet supported by the hub
                    maxMatchPercent: 0,
                    valueUnits: "percent",
                    ruleType: "RULE_NA",
                    gradedFlag: "F"
                }
            ];
        }
        return profitSharing;
    },

    createProfitSharingForAsset: function (asset, user) {
        let profitSharing = null;
        if (asset.currentContribution && asset.currentContribution.profitSharingFlag) {
            if (_isNil(asset.currentContribution.profitSharingPercentage)) {
                asset.currentContribution.profitSharingPercentage =
                    (asset.currentContribution.profitSharingAmount / user.salary.base) * 100;
                asset.currentContribution.profitSharingType = "PERCENT";
            }
            profitSharing = [
                {
                    matchPercent: asset.currentContribution.profitSharingPercentage,
                    maxMatchDollar: asset.currentContribution.profitSharingAmount, //todo FC: this is not yet supported by the hub
                    maxMatchPercent: 0,
                    valueUnits: _isNil(asset.currentContribution.profitSharingPercentage)
                        ? "dollars"
                        : "percent",
                    ruleType: "RULE_NA",
                    gradedFlag: "F"
                }
            ];
        }
        return profitSharing;
    },

    /**
     * Create the per plan limits
     * @param limits
     * @returns {}
     */
    createLimits: function (limits, irsLimits, plan) {
        let result;
        if (!_isNil(limits) && !_isEmpty(limits)) {
            result = limits;
            result.salaryCap = limits.matchableSalaryCap;
            result.rateOfInflation = limits.rateOfInflation;
        } else {
            result = {};
            result = irsLimits;
            result.salaryCap = irsLimits.matchableSalaryCap;
            result.rateOfInflation = irsLimits.rateOfInflation;
        }
        //NOTE: this does not currently support HSA as an external asset
        result.limitHSA =
            plan && plan.irsCode === HSAConstants.IRS_CODE ? HubConfigFactory.getHSALimit(plan) : 0;
        return result;
    },

    /**
     * Get the specific IRS Limit for the HSA plan
     * @param plan
     * @returns {limit}
     */
    getHSALimit: function (plan) {
        const trsFlexAccountInfo = plan.trsFlexAccountInfo;
        const coverageType =
            trsFlexAccountInfo && trsFlexAccountInfo.coverageType
                ? trsFlexAccountInfo.coverageType
                : "";
        let limit = 0;
        switch (coverageType) {
            case HSAConstants.COVERAGE_CODES.SINGLE:
            case HSAConstants.COVERAGE_CODES.SINGLE_CATCHUP:
            case HSAConstants.COVERAGE_CODES.FAMILY:
            case HSAConstants.COVERAGE_CODES.FAMILY_CATCHUP:
                limit = plan.IRSLimits[HSAConstants.IRS_LIMITS[coverageType]] || 0;
                break;
            default:
                limit = 0;
        }
        return limit;
    },

    /**
     * Create the per plan limits
     * @param limits
     * @returns {}
     */
    createHSAObject: function (plan) {
        const returnObj = {
            expenseAmount: 0
        };
        const trsFlexAccountInfo = plan.trsFlexAccountInfo;

        switch (plan.hsaDetails.expenseSelectionInd) {
            case HSAConstants.SPEND_CODES.PRIOR:
                returnObj.expenseAmount = trsFlexAccountInfo.aggrPriorYearSpend;
                break;
            case HSAConstants.SPEND_CODES.NATIONAL_AVG: {
                const coverageType =
                    trsFlexAccountInfo && trsFlexAccountInfo.coverageType
                        ? trsFlexAccountInfo.coverageType
                        : "";
                returnObj.expenseAmount =
                    plan.hsaDetails.nationalAverageValues[
                        HSAConstants.NATIONAL_AVERAGES[coverageType]
                    ];
                break;
            }
            case HSAConstants.SPEND_CODES.CUSTOM:
                returnObj.expenseAmount = plan.hsaDetails.customExpenseAmount;
                break;
        }
        return returnObj;
    },

    /**
     * Reformat TRS Flex additional retirement date and additional retirement amount; Format into an array list.
     * Specific additional retirement data for 15 years for Minnesota
     * @param {} TRSFlexData
     * @returns {}
     */
    reformatTRSFlexAddnlData: function (TRSFlexData) {
        const addnlRetirementList = [];

        let item = {
            addnlRetirementDate: null,
            addnlRetirementDbAmt: null
        };
        if (
            !_isNil(TRSFlexData.addnlRetirementDate1) &&
            !_isNil(TRSFlexData.addnlRetirementDbAmt1)
        ) {
            item = {
                addnlRetirementDate: ObjectUtil.formatDate(TRSFlexData.addnlRetirementDate1),
                addnlRetirementDbAmt: TRSFlexData.addnlRetirementDbAmt1
            };
            addnlRetirementList.push(item);
        }

        if (
            !_isNil(TRSFlexData.addnlRetirementDate2) &&
            !_isNil(TRSFlexData.addnlRetirementDbAmt2)
        ) {
            item = {
                addnlRetirementDate: ObjectUtil.formatDate(TRSFlexData.addnlRetirementDate2),
                addnlRetirementDbAmt: TRSFlexData.addnlRetirementDbAmt2
            };
            addnlRetirementList.push(item);
        }

        if (
            !_isNil(TRSFlexData.addnlRetirementDate3) &&
            !_isNil(TRSFlexData.addnlRetirementDbAmt3)
        ) {
            item = {
                addnlRetirementDate: ObjectUtil.formatDate(TRSFlexData.addnlRetirementDate3),
                addnlRetirementDbAmt: TRSFlexData.addnlRetirementDbAmt3
            };
            addnlRetirementList.push(item);
        }

        if (
            !_isNil(TRSFlexData.addnlRetirementDate4) &&
            !_isNil(TRSFlexData.addnlRetirementDbAmt4)
        ) {
            item = {
                addnlRetirementDate: ObjectUtil.formatDate(TRSFlexData.addnlRetirementDate4),
                addnlRetirementDbAmt: TRSFlexData.addnlRetirementDbAmt4
            };
            addnlRetirementList.push(item);
        }

        if (
            !_isNil(TRSFlexData.addnlRetirementDate5) &&
            !_isNil(TRSFlexData.addnlRetirementDbAmt5)
        ) {
            item = {
                addnlRetirementDate: ObjectUtil.formatDate(TRSFlexData.addnlRetirementDate5),
                addnlRetirementDbAmt: TRSFlexData.addnlRetirementDbAmt5
            };
            addnlRetirementList.push(item);
        }

        if (
            !_isNil(TRSFlexData.addnlRetirementDate6) &&
            !_isNil(TRSFlexData.addnlRetirementDbAmt6)
        ) {
            item = {
                addnlRetirementDate: ObjectUtil.formatDate(TRSFlexData.addnlRetirementDate6),
                addnlRetirementDbAmt: TRSFlexData.addnlRetirementDbAmt6
            };
            addnlRetirementList.push(item);
        }

        if (
            !_isNil(TRSFlexData.addnlRetirementDate7) &&
            !_isNil(TRSFlexData.addnlRetirementDbAmt7)
        ) {
            item = {
                addnlRetirementDate: ObjectUtil.formatDate(TRSFlexData.addnlRetirementDate7),
                addnlRetirementDbAmt: TRSFlexData.addnlRetirementDbAmt7
            };
            addnlRetirementList.push(item);
        }

        if (
            !_isNil(TRSFlexData.addnlRetirementDate8) &&
            !_isNil(TRSFlexData.addnlRetirementDbAmt8)
        ) {
            item = {
                addnlRetirementDate: ObjectUtil.formatDate(TRSFlexData.addnlRetirementDate8),
                addnlRetirementDbAmt: TRSFlexData.addnlRetirementDbAmt8
            };
            addnlRetirementList.push(item);
        }

        if (
            !_isNil(TRSFlexData.addnlRetirementDate9) &&
            !_isNil(TRSFlexData.addnlRetirementDbAmt9)
        ) {
            item = {
                addnlRetirementDate: ObjectUtil.formatDate(TRSFlexData.addnlRetirementDate9),
                addnlRetirementDbAmt: TRSFlexData.addnlRetirementDbAmt9
            };
            addnlRetirementList.push(item);
        }

        if (
            !_isNil(TRSFlexData.addnlRetirementDate10) &&
            !_isNil(TRSFlexData.addnlRetirementDbAmt10)
        ) {
            item = {
                addnlRetirementDate: ObjectUtil.formatDate(TRSFlexData.addnlRetirementDate10),
                addnlRetirementDbAmt: TRSFlexData.addnlRetirementDbAmt10
            };
            addnlRetirementList.push(item);
        }

        if (
            !_isNil(TRSFlexData.addnlRetirementDate11) &&
            !_isNil(TRSFlexData.addnlRetirementDbAmt11)
        ) {
            item = {
                addnlRetirementDate: ObjectUtil.formatDate(TRSFlexData.addnlRetirementDate11),
                addnlRetirementDbAmt: TRSFlexData.addnlRetirementDbAmt11
            };
            addnlRetirementList.push(item);
        }

        if (
            !_isNil(TRSFlexData.addnlRetirementDate12) &&
            !_isNil(TRSFlexData.addnlRetirementDbAmt12)
        ) {
            item = {
                addnlRetirementDate: ObjectUtil.formatDate(TRSFlexData.addnlRetirementDate12),
                addnlRetirementDbAmt: TRSFlexData.addnlRetirementDbAmt12
            };
            addnlRetirementList.push(item);
        }

        if (
            !_isNil(TRSFlexData.addnlRetirementDate13) &&
            !_isNil(TRSFlexData.addnlRetirementDbAmt13)
        ) {
            item = {
                addnlRetirementDate: ObjectUtil.formatDate(TRSFlexData.addnlRetirementDate13),
                addnlRetirementDbAmt: TRSFlexData.addnlRetirementDbAmt13
            };
            addnlRetirementList.push(item);
        }

        if (
            !_isNil(TRSFlexData.addnlRetirementDate14) &&
            !_isNil(TRSFlexData.addnlRetirementDbAmt14)
        ) {
            item = {
                addnlRetirementDate: ObjectUtil.formatDate(TRSFlexData.addnlRetirementDate14),
                addnlRetirementDbAmt: TRSFlexData.addnlRetirementDbAmt14
            };
            addnlRetirementList.push(item);
        }

        return addnlRetirementList;
    }
};

export default HubConfigFactory;
