/**
 *
 * @name PayPeriods
 * @memberOf common.model.constants
 * @date 2015
 * @copyright 2015 Empower Retirement
 * @description This module contains pay period string conversions
 * @property {string} W weekly (52)
 * @property {string} BW bi-weekly (26)
 * @property {string} M monthly (12)
 * @property {string} SM semi-monthly (24)
 * @property {string} A annually (1)
 * @property {string} SA semi-annually (2)
 *
 */
"use strict";

export default {
    H: 2080,
    W: 52,
    BW: 26,
    SM: 24,
    M: 12,
    Q: 4,
    SA: 2,
    S: 2,
    A: 1
};
