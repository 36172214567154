/**
 *
 * @name SdsvSubcodes
 * @memberOf common.model.constants
 * @description Stores Standard Service Subcodes
 * @property {string} CASH_BALANCE "CASH_BAL"
 *
 */
"use strict";

const SdsvSubcodes = {
    codes: {
        CASH_BALANCE: "CASH_BAL"
    }
};

export default SdsvSubcodes;
