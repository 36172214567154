import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";

import AgeTicker from "./AgeTicker";
import ThingsToConsider from "./ThingsToConsider";

/**
 * This is the view for participants <=49years. The idea is for them to understand their retirement need based on the retirement age selected by the participants.
 * From a UI experience perspective, this will be a new tab visible only to participants <=49years. Age displays 50 - 85 years.
 * @returns
 */
const WhenCanIRetire = () => {
    const translations = selectTranslations("whenCanIRetire");

    return (
        <div className="wcir-container" data-testid="when-can-i-retire" id="when-can-i-retire">
            <h2 className="wcir-header" data-testid="wcir-header">
                {translations.title}
            </h2>
            <div className="wcir-body">
                <AgeTicker />
                <ThingsToConsider />
            </div>
        </div>
    );
};

export default WhenCanIRetire;
