import axios from "axios";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { ObjectUtil } from "gw-shared-components";
import { each as _each } from "lodash";

import DeferralMoneyType from "../constants/DeferralMoneyType";
import { ResponseFaultFactory } from "../factories/FaultFactory";
import InitDataFactory from "../factories/InitDataFactory";
import { executeGet } from "../utils/apiUtils";
import { convertToLongFormat } from "../utils/dateUtils";
import { getUrl } from "../utils/urlUtils";

const logger = ExternalLogger.getInstance("DeferralService");

export const save = (deferrals, planId) => {
    logger.info("save( value = {0}, type = {1}, planId = {2} )", [
        deferrals[0].value,
        deferrals[0].deferralTypeCode,
        planId
    ]);

    /**
     * Service success handler and promise resolver.
     * @param result
     */
    const saveDeferralServiceResult = (result) => {
        if (
            ObjectUtil.isUndefinedOrNull(result) ||
            ObjectUtil.isUndefinedOrNull(result.data.data) ||
            ObjectUtil.isUndefinedOrNull(result.data.data.deferralsSaveResponse)
        ) {
            const code = result ? result.data.error.code : null;
            logger.error("saveDeferralServiceResult( Bad response data. )");
            return Promise.reject(ResponseFaultFactory.create(null, "POST", null, code));
        } else if (
            result.data.data.deferralsSaveResponse.statusCode !== "201" &&
            result.data.data.deferralsSaveResponse.statusCode !== "200"
        ) {
            logger.info("saveDeferralServiceResult( Bad response -- data is null or undefined. )");
            return Promise.reject(
                ResponseFaultFactory.create(null, "POST", null, result.data.error.code)
            );
        } else {
            logger.info("saveDeferralServiceResult()");
            return result.data.data.deferralsSaveResponse;
        }
    };

    /**
     * Service fault handler and promise rejection.
     * @param fault
     */
    const saveDeferralServiceFault = (fault) => {
        logger.error("saveDeferralServiceFault( fault = {0} )", [fault.message]);
        return Promise.reject(fault);
    };

    const getDeferrals = (deferralsList) => {
        const list = [];
        let deferral;
        let isDollar;
        let amount;
        let goalFlag;

        _each(deferralsList, (item) => {
            // determine if the deferral is dollar or percent based
            isDollar = item.valueUnits === DeferralMoneyType.DOLLAR;
            let granularity = item.deferralLimits.granularity;
            if (ObjectUtil.isUndefinedOrNull(granularity)) {
                granularity = 0.01;
            }
            amount =
                Math.round(Math.round(Number(item.value) / granularity) * granularity * 100) / 100;

            goalFlag = ObjectUtil.isUndefinedOrNull(item.nycGoalFlag) ? null : item.nycGoalFlag;

            const formatDate = ObjectUtil.isUndefinedOrNull(item.autoIncrease)
                ? null
                : convertToLongFormat(item.autoIncrease.nextScheduleDate);

            const hasExceededAutoIncreaseLimit = ObjectUtil.isUndefinedOrNull(item.autoIncrease)
                ? false
                : amount >= item.autoIncrease.stopAtValue || amount === 0;

            // create the deferral
            deferral =
                ObjectUtil.isUndefinedOrNull(item.autoIncrease) || hasExceededAutoIncreaseLimit
                    ? {
                          type: item.deferralTypeCode, // Describes the contribution type - "BEFORE","AFTER","ROTH","AGERTH" etc
                          nycGoalFlag: goalFlag,
                          amount: amount,
                          amountType: isDollar ? "AMT" : "PCT",
                          effectiveDate: item.submissionDate
                      }
                    : {
                          type: item.deferralTypeCode,
                          nycGoalFlag: goalFlag,
                          amount: amount,
                          amountType: isDollar ? "AMT" : "PCT",
                          effectiveDate: item.submissionDate,
                          autoIncrease: {
                              increaseBy: item.autoIncrease.increaseValue,
                              maxIncreaseTo: item.autoIncrease.stopAtValue,
                              startDate: formatDate
                          },
                          maximize: null,
                          fddOneTime: false
                      };

            // add the deferral to the list
            list.push(deferral);
        });

        return list;
    };

    const saveDeferralURL = (params) => {
        return StringUtil.supplant(getUrl("saveDeferral"), params);
    };

    const getParams = (deferrals) => {
        return {
            participant: {
                contributions: getDeferrals(deferrals)
            }
        };
    };

    const endPointParams = {
        groupId: planId // the primary plan's ID is the group ID
    };
    const url = saveDeferralURL(endPointParams);
    const params = getParams(deferrals);

    return axios.post(url, params).then(saveDeferralServiceResult, saveDeferralServiceFault);
};

export const get = () => {
    const getDeferralsResult = (result) => {
        if (ObjectUtil.isUndefinedOrNull(result) || ObjectUtil.isUndefinedOrNull(result.data)) {
            logger.error("getDeferralsResult( Bad response data. )");
            throw new Error("There is no response data.");
        } else {
            logger.info("getDeferralsResult()");
            return InitDataFactory.createDeferrals(result.data.deferrals);
        }
    };

    const url = getUrl("getDeferrals");

    return executeGet(url, "getDeferralsFault").then(getDeferralsResult);
};
