/**
 * @name app.infrastructure.domainModel.FutureDatedDeferral
 * @date 2/26/2018
 * @copyright 2018 Empower Retirement
 * @description
 *
 *  Definition of the FutureDatedDeferral domain model object.
 *
 * @example
 * // do not include if future dated is not needed
 * "future": {
 *     "date": "21-May-2018",
 *     "value": "12.5",
 *     "units": "PCT",
 *     "isOneTime": true
 * }
 */

"use strict";

class FutureDatedDeferral {
    constructor(config) {
        this.date = null;
        this.value = 0;
        this.units = null;
        this.isOneTime = false;

        if (config) {
            this.date = config.date;
            this.value = config.value;
            this.units = config.units;
            this.isOneTime = config.isOneTime;
        }
    }
}

export default FutureDatedDeferral;
