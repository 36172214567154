import * as Sentry from "@sentry/react";
import { PerformanceTrackingKeys } from "core-ui/client/react/core/constants/constants";

// eslint-disable-next-line import/prefer-default-export
export function setRedwoodSidebarObserver() {
    const showTrackTime =
        localStorage.getItem(PerformanceTrackingKeys.PT_SIDEBAR_FIRST_LOAD) === "true";
    if (showTrackTime) {
        localStorage.setItem(PerformanceTrackingKeys.PT_SIDEBAR_FIRST_LOAD, "false");
        // create timestamp
        const clickedTime =
            localStorage.getItem(PerformanceTrackingKeys.PT_MFA_CLICKED) ??
            localStorage.getItem(PerformanceTrackingKeys.PT_SIGNIN_CLICKED);
        const d1 = Number(clickedTime);
        // create Sentry transaction to begin tracking
        const transaction = Sentry.startTransaction({ name: "http.client.sidebar.trxn" });

        // Select the node that will be observed for mutations
        const targetNode = document.body;
        // Options for the observer (which mutations to observe)
        const config = { subtree: true, childList: true };
        // Callback function to execute when mutations are observed
        const callback = function (mutationsList) {
            for (const mutation of mutationsList) {
                if (mutation.type === "childList") {
                    if (mutation.target.classList.contains("js-sidebar-networth-amount")) {
                        // create second timestamp
                        const d2 = new Date().getTime();
                        // calculate difference
                        const delta = d2 - d1;
                        // manually measure the time delta
                        transaction?.setMeasurement(
                            "http.client.sidebar.loaded",
                            delta,
                            "millisecond"
                        );
                        console.log("sidebar loaded: ", delta + "ms");
                        // stop the transaction
                        transaction?.finish();
                    }
                }
            }
        };
        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);
        observer.observe(targetNode, config);
    }
}
