import React from "react";

import PropTypes from "prop-types";

const Backdrop = ({ handleBackdropClick }) => {
    return <button onClick={handleBackdropClick} className="progress-modal-backdrop"></button>;
};
Backdrop.propTypes = {
    handleBackdropClick: PropTypes.func
};
export default Backdrop;
