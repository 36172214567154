import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import StringUtil from "core-ui/client/src/app/StringUtil";
import {
    formatDate,
    getBooleanFromIndicator,
    isArrayEmpty,
    isUndefinedOrNull,
    roundDownValueToStep,
    roundUpValueToStep
} from "gw-shared-components";
import { each as _each, find as _find, includes as _includes, round as _round } from "lodash";

import deferralChangeProperties from "../../constants/DeferralChangeProperties";
import DeferralMoneyType from "../../constants/DeferralMoneyType";
import IncomeStreams from "../../constants/IncomeStreams";
import ReferenceData from "../../constants/ReferenceData";
import trsCodes from "../../constants/TrsSdsvCodes";
import { areShallowEqual } from "../../utils/deferralUtils";

const DeferralAvailCodes = {
    FULL: "CHGALLOWED",
    MODEL: "VIEWONLY",
    HIDDEN: "NOTVISIBLE",
    GRANDFATHERED: "GRANDFATHERED",
    FROZEN: "FROZEN"
};

// ****** DEFERRALS PURE FUNCTIONS ******* //

export const isDollarDeferral = (deferral) => {
    return deferral.valueUnits === DeferralMoneyType.DOLLAR;
};

export const getDollarDeferralToPercentConversion = (salary, payFrequency) => {
    if (salary === 0) {
        return 0;
    }
    return payFrequency / salary;
};

export const getDeferralTerm = (payFrequency) => {
    if (payFrequency) {
        return payFrequency;
    } else {
        return 1;
    }
};

export const getDeferralPaycheckToTermConversion = (payFrequency) => {
    return payFrequency / getDeferralTerm(payFrequency);
};

export const getActiveDeferral = (deferrals) => {
    let activeDeferral = deferrals.find((def) => def.active === true);
    if (isUndefinedOrNull(activeDeferral)) {
        // Possible use case where all deferals are not active
        activeDeferral = deferrals[0];
    }
    return activeDeferral;
};
/**
 * @description gets max editable entry (largest user-entered number) for each slider.
 * Not to be confused with max value or max editable value.
 * see slider docs for more info.
 * @param {string} sliderName
 * @param {object} deferral
 * @returns {number}
 */
export const getMaxEditableEntry = (sliderName, deferral) => {
    //maxEditableEntry
    switch (sliderName) {
        case "contributionRate": //TODO: Make this a constant
            if (isDollarDeferral(deferral)) {
                return ReferenceData.MAX_DOLLAR_ENTRY;
            }
            return ReferenceData.DEFAULT_MAX_ENTRY;
        case "investment-mix-slider": //TODO: Make this a constant
            return ReferenceData.MAX_INVESTMENT_MIX;
        default:
            return ReferenceData.DEFAULT_MAX_ENTRY;
    }
};

/**
 * @description gets if the deferral has warnings
 * @param {array} warnings
 * @param {array} submissionWarnings
 * @returns {boolean}
 */
export const getHasDeferralWarnings = (warnings, submissionWarnings) => {
    const hasSubmissionWarnings =
        !isUndefinedOrNull(submissionWarnings) && submissionWarnings.length > 0;
    const hasWarnings = !isUndefinedOrNull(warnings) && warnings.length > 0;
    return hasSubmissionWarnings || hasWarnings;
};

/**
 * @description returns if deferralWarnings has a length
 * @param {array} deferralWarnings
 * @returns {boolean}
 */
export const hasDeferralWarnings = (deferralWarnings) => {
    return !isUndefinedOrNull(deferralWarnings) && !isArrayEmpty(deferralWarnings);
};

// ****** END OF DEFERRALS PURE FUNCTIONS ******* //

/**
 * @class business.Deferrals
 * @date 02/06/2020
 * @copyright 2020 Empower Retirement
 * @description
 *
 *      Encapsulates deferral specific business logic, like finding the active deferral from
 *      the list of deferrals. Use the Deferrals class when access to many of the interconnected
 *      functions are needed -- otherwise please try to use the **pure functions** above --
 *
 * Creating variables inside the methods which will allow each method to be used independently.
 * Example variables:
 * @participantDeferrals
 * @participantSalary
 * @participantPayFrequency
 */
export default class Deferrals {
    constructor(deferrals, primaryPlan, deferralSetupConfig, activeDeferral) {
        this.deferrals = deferrals;
        this.primaryPlan = primaryPlan;
        this.deferralSetupConfig = deferralSetupConfig;
        this.activeDeferral = activeDeferral;
        const deferralRules = selectTranslations("deferralRules");
        const reviewChanges = selectTranslations("reviewChanges");
        const translations = { ...reviewChanges, ...deferralRules };
        this.translations = translations;

        this.salary = primaryPlan ? primaryPlan.salary : 60000;
        this.payFrequency = primaryPlan ? primaryPlan.payFrequency : 12;
    }

    /**
     * @description if no deferral passed as parameter reference this activeDeferral
     * @param {object} deferral
     * @returns {object} deferral
     */
    checkDeferral = (deferral) => {
        if (isUndefinedOrNull(deferral)) {
            return this.activeDeferral;
        }
        return deferral;
    };

    /**
     * @description if deferral value units is dollars
     * @param {object} def
     * @returns {boolean}
     */
    isDollarDeferral = (def) => {
        const deferral = this.checkDeferral(def);
        return deferral.valueUnits === DeferralMoneyType.DOLLAR;
    };

    /**
     * @description if no frequency passed as parameter reference this frequency
     * @param {number} frequency
     * @returns {number} frequency
     */
    getPayFrequency = (frequency) => {
        if (isUndefinedOrNull(frequency)) {
            return this.payFrequency;
        }
        return frequency;
    };

    /**
     * @description if no salary passed as parameter reference this salary
     * @param {object} salary
     * @returns {object} salary
     */
    getSalary = (salary) => {
        if (isUndefinedOrNull(salary)) {
            return this.salary;
        }
        return salary;
    };

    /**
     * @description returns the active plan. synonymous with primaryPlan
     * @param {object}
     * @returns {object} plan
     */
    getActivePlan = (plan) => {
        if (isUndefinedOrNull(plan)) {
            return this.primaryPlan;
        }
        return plan;
    };

    setOriginalDeferralSummaryTotal = (deferrals) => {
        const participantDeferrals = deferrals ? deferrals : this.deferrals;
        let value = 0;
        let max = 0;
        let newVal = 0;
        let newMax = 0;

        participantDeferrals.forEach((def) => {
            const conversion = this.getDeferralSummaryConversionFactor(def);
            const defMax = this.getMaxContributionSliderRate(def);
            newVal = def.value * conversion;
            newMax = defMax * conversion;
            value += newVal;
            max = Math.max(max, newMax);
        });
        return {
            deferralSummaryTotal: value,
            deferralSummaryMax: max
        };
    };

    getDeferralSummaryConversionFactor = (def) => {
        const deferral = this.checkDeferral(def);
        const {
            isDollarDeferral,
            getHasMixedDeferrals,
            getDollarDeferralToPercentConversion,
            getTotalSalary,
            getDeferralPaycheckToTermConversion,
            getSalaryForDeferral
        } = this;
        //in the future, we may get a pay frequency per deferral, even though that makes no sense to me
        if (isDollarDeferral(deferral) && getHasMixedDeferrals()) {
            //convert to annual, divide by salary
            return getDollarDeferralToPercentConversion(getTotalSalary()) * 100;
        } else if (isDollarDeferral(deferral)) {
            return getDeferralPaycheckToTermConversion();
        }
        //For percent deferrals, it is no longer a 1 to 1 percent change for the summary slider
        // the ratio is deferral salary/totalsalary

        return getSalaryForDeferral(deferral) / getTotalSalary();
    };

    getHasMixedDeferrals = () => {
        return this.deferralSetupConfig.hasMixedDeferrals;
    };

    getDollarDeferralToPercentConversion = (salary, payFrequency) => {
        const participantSalary = this.getSalary(salary);
        const participantPayFrequency = this.getPayFrequency(payFrequency);
        if (participantSalary === 0) {
            return 0;
        }
        return participantPayFrequency / participantSalary;
    };

    getTotalSalary = (salary) => {
        const participantSalary = this.getSalary(salary);
        if (this.getHasVariableDeferral()) {
            return participantSalary.base + this.getVariableSalary(participantSalary);
        } else {
            return participantSalary.base;
        }
    };

    getVariableSalary = (salary) => {
        const participantSalary = this.getSalary(salary);
        return isUndefinedOrNull(participantSalary.variable)
            ? participantSalary.tempVariable || 0
            : participantSalary.variable;
    };

    getHasVariableDeferral = () => {
        return this.deferralSetupConfig.hasVariableDeferral;
    };

    getDeferralPaycheckToTermConversion = (payFrequency) => {
        const participantPayFrequency = this.getPayFrequency(payFrequency);
        return participantPayFrequency / this.getDeferralTerm();
    };

    getDeferralTerm = (payFrequency) => {
        const participantPayFrequency = this.getPayFrequency(payFrequency);
        if (participantPayFrequency) {
            return participantPayFrequency;
        } else {
            return 1;
        }
    };

    getSalaryForDeferral = (def, salary) => {
        const deferral = this.checkDeferral(def);
        const participantSalary = this.getSalary(salary);

        if (this.isVariableDeferral(deferral)) {
            return this.getVariableSalary();
        } else {
            return participantSalary.base;
        }
    };

    isVariableDeferral = (def) => {
        const deferral = this.checkDeferral(def);
        const incomeStream = isUndefinedOrNull(deferral.config)
            ? deferral.incomeStream
            : deferral.config.incomeStream;
        return incomeStream !== IncomeStreams.BASE;
    };

    getMaxContributionSliderRate = (def) => {
        const deferral = this.checkDeferral(def);
        const maxDeferral = roundDownValueToStep(
            this.getMaxContributionRate(deferral),
            this.getDeferralStep(deferral)
        );
        //set default max
        let maxSliderDeferral = ReferenceData.MAX_SLIDER_CONTRIBUTION_RATE;
        if (this.isDollarDeferral(deferral)) {
            maxSliderDeferral = this.getMaxSliderValueForDollarDeferral(
                deferral,
                ReferenceData.MAX_SLIDER_CONTRIBUTION_RATE
            );
        }
        //set max based on service response
        const limit = deferral.deferralLimits;
        if (limit && limit.maxSlider) {
            maxSliderDeferral = limit.maxSlider;
            if (this.isDollarDeferral(deferral)) {
                maxSliderDeferral = this.getMaxSliderValueForDollarDeferral(
                    deferral,
                    limit.maxSlider
                );
            }
        }
        //compare maximums
        return Math.min(maxDeferral, maxSliderDeferral);
    };

    /**
     * @description
     * this provides the maximum for the editable text directive on the contribution rate slider, using the
     * current active deferral
     * @returns {number}
     */
    getMaxContributionRate = (def) => {
        const deferral = this.checkDeferral(def);
        const limit = deferral.deferralLimits;
        let maxDeferral = ReferenceData.MAX_CONTRIBUTION_RATE;

        if (this.isDollarDeferral(deferral)) {
            maxDeferral = this.getMaxPercentOfPaycheckDollar(maxDeferral, deferral);
        }
        if (limit && limit.maxDeferral) {
            maxDeferral = limit.maxDeferral;
        }
        return maxDeferral;
    };

    getMaxPercentOfPaycheckDollar = (max, def) => {
        const deferral = this.checkDeferral(def);
        const salary =
            this.getSalaryForDeferral(deferral) > 0
                ? this.getSalaryForDeferral(deferral)
                : this.salary.base;
        let maxPercent = Math.round((salary * max) / 100 / this.payFrequency);

        if (maxPercent <= 1) {
            maxPercent /= max / 100;
        }

        return Math.max(maxPercent, 1);
    };

    /**
     * @description
     * returns increment for sliding.  usually 25 for dollar deferrals, 1 for percent deferrals
     * @returns {number}
     */
    getDeferralStep = (def) => {
        const deferral = this.checkDeferral(def);
        if (deferral) {
            if (this.isDollarDeferral(deferral)) {
                const maxContrib = Math.min(
                    this.getMaxSliderValueForDollarDeferral(
                        deferral,
                        ReferenceData.MAX_SLIDER_CONTRIBUTION_RATE
                    ),
                    this.getMaxContributionRate(deferral)
                );
                const minContrib = this.getMinContributionRate(deferral);
                const incrementSlice =
                    (maxContrib - minContrib) / ReferenceData.DOLLAR_DEFERRAL_INCREMENT;
                const roundedSlice = Math.max(
                    Math.round(incrementSlice / ReferenceData.DOLLAR_DEFERRAL_ROUND) *
                        ReferenceData.DOLLAR_DEFERRAL_ROUND,
                    1
                );
                const steps = Math.ceil(maxContrib / roundedSlice) + 1;
                let stepAmount;

                if (steps >= ReferenceData.DOLLAR_DEFERRAL_MAX_STEP) {
                    if (roundedSlice < ReferenceData.DOLLAR_DEFERRAL_ROUND) {
                        stepAmount = ReferenceData.DOLLAR_DEFERRAL_ROUND;
                    } else {
                        stepAmount = roundedSlice + ReferenceData.DOLLAR_DEFERRAL_ROUND;
                    }
                } else {
                    stepAmount = roundedSlice;
                }

                return stepAmount;
            } else if (deferral.deferralLimits && deferral.deferralLimits.granularity > 1) {
                return deferral.deferralLimits.granularity;
            }
        }

        return ReferenceData.MIN_CONTRIBUTION_RATE;
    };

    getMaxSliderValueForDollarDeferral = (def, max) => {
        const deferral = this.checkDeferral(def);
        return Math.round(
            Math.min(
                this.getMaxPercentOfPaycheckDollar(max, deferral),
                this.getMaxContributionRate(deferral)
            )
        );
    };

    /**
     * @description
     * get min editable contribution rate.  See slider docs for how min editable is used.
     * @returns {number}
     */
    getMinContributionRate = (def) => {
        const deferral = this.checkDeferral(def);
        if (deferral.config.minRequiredInd) {
            const limit = deferral.deferralLimits;
            let minDeferral = ReferenceData.MIN_CONTRIBUTION_RATE;
            if (this.isDollarDeferral(deferral)) {
                minDeferral = ReferenceData.MIN_CONTRIBUTION_RATE_DOLLAR;
            }
            //deferral limit is given in per pay period for dollar deferrals
            if (limit && limit.minDeferral >= 0) {
                minDeferral = limit.minDeferral;
            }
            return minDeferral;
        }
        // For now let's always return 0, so the PPT can zero out thier deferrals!
        // We need to be able to also handle the case of doing this while in Model Only Mode
        // CAB 5-12-2017
        return 0;
    };

    adjustNonZeroVariableDeferralCount = (amt) => {
        return Math.max(0, this.deferralSetupConfig.nonZeroVariableDeferralCount + amt);
    };

    getCanShowMultiple = (plan) => {
        const activePlan = this.getActivePlan(plan);
        let show = false;
        if (activePlan.planRules) {
            show = activePlan.planRules.allowMultipleDeferrals;
        }
        // NOTES!
        // if allowMultipleDeferrals is false from primary plan, we don't show Summary slider regardless of whether HSA deferral is present
        // if(dataModel.user.hsaPlan){
        //     show = show || (dataModel.user.hsaPlan.planRules && dataModel.user.hsaPlan.planRules.allowAddNewContributions && !dataModel.user.hsaPlan.terminated);
        // }
        return show;
    };

    updateTotalsForSalaryTypes = (def, delta) => {
        const deferral = this.checkDeferral(def);
        //option to use the deferral value or just apply the delta
        const hasDelta = !isUndefinedOrNull(delta);
        let val = hasDelta ? delta : deferral.value;
        val = this.isDollarDeferral(deferral)
            ? val *
              this.getDollarDeferralToPercentConversion(this.getSalaryForDeferral(deferral)) *
              100
            : val;

        return val;
    };

    adjustCsorEstimatedCount = (adj) => {
        let newVal = this.deferralSetupConfig.csorEstimatedCount + adj;
        if (newVal < 0) {
            newVal = 0;
        }

        if (newVal > this.deferrals.length) {
            newVal = this.deferrals.length;
        }
        return newVal;
    };

    hasSpecialCatchup = (plan) => {
        const activePlan = this.getActivePlan(plan);
        return activePlan.planRules && activePlan.planRules.specialCatchup;
    };

    getHasTemporaryVariableSalary = (plan) => {
        const activePlan = this.getActivePlan(plan);
        return !isUndefinedOrNull(activePlan.salary.tempVariable);
    };
    variableDeferralNeedsVariableSalary = () => {
        //Only show the dialog if there is no variable salary or if it is '0' - getVariableSalary() accounts
        //for this - (i.e. it will return 0 if the variable salary is not present) - but if there is no variable
        //salary and you add a variable deferral and enter one it is stored in as temporary - so that must also
        // be considered.
        return this.getVariableSalary() === 0 && !this.getHasTemporaryVariableSalary();
    };

    getDeferralGroupCodeValueUnits = (availableDeferral) => {
        let valueUnits = null;
        //go through ruleGroupCodes
        _each(availableDeferral.ruleGroupCodes, (groupCode) => {
            // eslint-disable-next-line no-undef
            if (dataModel.activePlan.deferralInfo.deferralGroupCodes[groupCode]) {
                // eslint-disable-next-line no-undef
                valueUnits = dataModel.activePlan.deferralInfo.deferralGroupCodes[groupCode];
            }
        });
        return valueUnits;
    };

    checkIfTotalDeferralPercentIsOver = (threshold) => {
        return (
            this.getBaseSummaryTotalPct() > threshold ||
            (this.getVarSummaryTotalPct() > threshold && !this.getHasVariableDollarDeferral())
        );
    };

    getBaseSummaryTotalPct = () => {
        return this.deferralSetupConfig.deferralTotalBasePct;
    };

    getVarSummaryTotalPct = () => {
        return this.deferralSetupConfig.deferralTotalVarPct;
    };

    getHasVariableDollarDeferral = () => {
        return this.deferralSetupConfig.hasVariableDollarDeferral;
    };

    /**
     * @description
     * returns $ if dollar deferral
     * @param deferral
     * @returns {string}
     */
    getDeferralPrefix = (def) => {
        const deferral = this.checkDeferral(def);
        return this.isDollarDeferral(deferral) ? "$" : "";
    };

    /**
     * @description
     * returns % if percent deferral
     * @param deferral
     * @returns {string}
     */
    getDeferralSuffix = (def) => {
        const deferral = this.checkDeferral(def);
        return this.isDollarDeferral(deferral) ? "" : "%";
    };

    getDeferralSummaryPrefix = (def) => {
        const deferral = this.checkDeferral(def);
        const prefix = this.isDollarDeferral(deferral) ? "$" : "";
        return this.getHasMixedDeferrals() ? "" : prefix;
    };
    /**
     *
     * @returns {string}
     */
    getDeferralSummarySuffix = (def) => {
        const deferral = this.checkDeferral(def);
        const suffix = this.isDollarDeferral(deferral) ? "" : "%";
        return this.getHasMixedDeferrals() ? "%" : suffix;
    };

    //TODO - get rid of this? re-evaluate when we change to per paycheck
    getOverallSummaryConversionFactor = (def) => {
        const deferral = this.checkDeferral(def);
        if (this.getHasMixedDeferrals() || !this.isDollarDeferral(deferral)) {
            return 1;
        }
        return 1;
    };
    getMinContributionSliderRate = (def) => {
        const deferral = this.checkDeferral(def);
        const limit = this.getMinContributionRate(deferral);

        return Math.round(limit);
    };

    /**
     * @memberOf common.service.business.RetirementSavingsService
     * @description
     * future looking function.  now returns constant conversion from per paycheck to monthly for dollar deferrals,
     * but could be used to change slider from monthly to yearly
     * @returns {number}
     */
    getConversionFactor = (def, plan) => {
        const deferral = this.checkDeferral(def);
        const activePlan = this.getActivePlan(plan);
        const factor = activePlan.payFrequency / this.getDeferralTerm();
        return this.isDollarDeferral(deferral) ? factor : 1;
    };
    getIsFrozenDeferral = (def) => {
        const deferral = this.checkDeferral(def);
        if (deferral.defrlAvailCode) {
            return deferral.defrlAvailCode === DeferralAvailCodes.FROZEN;
        } else {
            return deferral.config.defrlAvailCode === DeferralAvailCodes.FROZEN;
        }
    };
    /**
     * @memberOf common.service.business.RetirementSavingsService
     * @description
     * Determines if the contribution slider should be in enabled or disabled state.
     *
     * The three states of the contribution slider are:
     *
     *      1) Standard: The slider is enabled (allowDeferral = true, deferralEnabled = true)
     *      2) Model-only: The slider is enabled (allowDeferral = false, deferralEnabled = true)
     *      3) Disabled: The slider displays the current deferral value (or 0 if none exists) but is not enabled
     *      and can not be edited by the user (allowDeferral = false, deferralEnabled = false)
     *
     * NOTE: We don't currently have a case that covers allowDeferral = true and deferralEnabled = false,
     * but it would end up being treated the same way as the "Disabled" case.
     *
     * PW-4523 5/15/2015 The contribution rate slider should be disabled if primary plan indicates ppt is terminated
     *                   To date, there is only one plan, the active plan
     *
     * PW-17368 8/15/2016 The contribution rate slider should be disabled if primary plan has specialCatchupIndicator
     *
     * @returns {boolean}
     */
    getIsContributionSliderEnabled = (def) => {
        const deferral = this.checkDeferral(def);
        const isFrozen = this.getIsFrozenDeferral(deferral);
        //this only occurs when the user is terminated or has 457, or has a 401A csor deferral, or it is frozen
        return (
            !(
                deferral.plan.terminated ||
                (deferral.plan.planRules && deferral.plan.planRules.specialCatchup)
            ) && !isFrozen
        );
    };

    /**
     * return editable step for deferral, if necessary
     * @param deferral
     * @returns {number|*|null}
     */
    getDeferralEditableStep = (def) => {
        const deferral = this.checkDeferral(def);
        if (deferral && deferral.config) {
            return deferral.deferralLimits.granularity;
        }
        return null;
    };

    /**
     * @memberOf common.service.business.RetirementSavingsService
     * @description
     * get number of allowed decimals for deferral slider
     * @returns {*}
     */
    getDeferralDecimals = (def) => {
        const deferral = this.checkDeferral(def);
        if (deferral && deferral.deferralLimits && deferral.deferralLimits.granularity) {
            let gran = deferral.deferralLimits.granularity;

            gran = String(gran).split(".");
            if (gran.length === 1) {
                return 0;
            } else if (gran.length > 1) {
                return gran[1].length;
            }
        }
        //if neither case above applies, use defaults
        if (this.isDollarDeferral(deferral)) {
            return ReferenceData.DOLLAR_DEFERRAL_DECIMALS;
        }
        return ReferenceData.PERCENT_DEFERRAL_DECIMALS;
    };

    /**
     * @memberOf common.service.business.RetirementSavingsService
     * @description
     * get slider max to display on tooltip for contribution rate slider
     * @returns {*}
     */
    getDisplaySliderMax = (def) => {
        const deferral = this.checkDeferral(def);
        let sliderMax = this.getMaxContributionSliderRate(deferral);
        if (this.isDollarDeferral(deferral)) {
            sliderMax = this.getDeferralPaycheckToTermConversion() * sliderMax;
        }
        return (
            this.getDeferralPrefix(deferral) +
            roundUpValueToStep(sliderMax, this.getDeferralStep(deferral)) +
            this.getDeferralSuffix(deferral)
        );
    };

    /**
     * @memberOf common.service.business.RetirementSavingsService
     * @description
     * returns true if slider max and editable max are different for contribution rate slider
     * @returns {boolean}
     */
    getShowDeferralMaxTooltip = (def) => {
        const deferral = this.checkDeferral(def);
        return (
            this.getMaxContributionRate(deferral) !== this.getMaxContributionSliderRate(deferral)
        );
    };

    /**
     * @description gets deferral slider link for auto increase based on
     * the deferral that is passed
     * @returns {{}}
     */
    getContributionSliderLinkLabel = (def) => {
        const deferral = this.checkDeferral(def);
        // if there were ai's and the user has moved the slider, then we don't want to display a message
        // if the user moves the slider back to the original value, slider will set this boolean correctly
        // For auto increase, display ai if deferral value less than stopAtValue
        let message = null;

        if (!this.getChangedStatusForDeferral(deferral)) {
            if (!isUndefinedOrNull(deferral.autoIncrease)) {
                message = {
                    isAutoIncrease: true,
                    code: deferral.autoIncrease.pctAmtCode,
                    value: deferral.autoIncrease.stopAtValue
                };
            }

            if (!isUndefinedOrNull(deferral.futureDated)) {
                message = {
                    isFutureDated: true,
                    code: deferral.futureDated.units,
                    value: deferral.futureDated.value,
                    isOneTime: deferral.isOneTime
                };
            }
        }

        return message;
    };

    /**
     * @description
     * Builds and returns the url for the link to AI on the retirement slider label if AI is available
     * @returns {*}
     */
    getContributionSliderLinkUrl = (individualId, plan) => {
        // /ui/participant-ui/#/accounts/{individualId}/{groupId}/deferrals/contributions
        const url = ReferenceData.URL_MY_CONTRIBUTIONS;
        const activePlan = this.getActivePlan(plan);
        const params = {
            individualId: individualId,
            groupId: activePlan.id // the primary plan's ID is the group ID
        };

        return StringUtil.supplant(url, params);
    };

    getDeferralWarningValue = (def) => {
        const deferral = this.checkDeferral(def);
        if (this.isDollarDeferral(deferral)) {
            return this.getSalaryForDeferral(deferral) / this.primaryPlan.payFrequency;
        }
        return 100;
    };

    setDeferralSummaryTotal = (addValue, defSummaryTotal) => {
        const deferralSummaryTotal = defSummaryTotal
            ? defSummaryTotal
            : this.deferralSetupConfig.deferralSummaryTotal;
        return deferralSummaryTotal + addValue;
    };

    updateDeferralSlider = (type, value, def) => {
        const deferral = this.checkDeferral(def);
        //figure out which deferral was changed
        if (deferral.deferralTypeCode !== type) {
            // REVIST CHANGING ACTIVE DEF
            //api.changeActiveDeferral(type);
        }
        const newDeferralConfig = { ...this.deferralSetupConfig };

        //figure out how much to add to the deferral summary slider total
        const originalValue = deferral.value;
        const addValue = value - originalValue;
        const valueChanged = addValue !== 0;
        if (!valueChanged) {
            return;
        }
        const isVariableDeferral = this.isVariableDeferral(deferral);
        if (isVariableDeferral) {
            newDeferralConfig.deferralTotalVarPct =
                newDeferralConfig.deferralTotalVarPct +
                this.updateTotalsForSalaryTypes(deferral, addValue);
        } else {
            newDeferralConfig.deferralTotalBasePct =
                newDeferralConfig.deferralTotalBasePct +
                this.updateTotalsForSalaryTypes(deferral, addValue);
        }

        // TO DO NEED TO CHANGE THE MAZIMIER AND AUTO-INCREASES IF VALUE CHANGED

        if (isVariableDeferral && originalValue === 0) {
            if (newDeferralConfig.nonZeroVariableDeferralCount === 0) {
                if (newDeferralConfig.nonZeroVariableDeferralCount === 0) {
                    //going from no non-zero deferrals to one
                    // TODO keep this in case we want to not count the var deferral if it goes to/from 0
                    //  _viewModel.deferralSetupConfig.deferralSummaryTotal *= api.getBaseSalaryRatio();
                }
                newDeferralConfig.nonZeroVariableDeferralCount =
                    this.adjustNonZeroVariableDeferralCount(1);
            }
        }

        const conversion = this.getDeferralSummaryConversionFactor(deferral);
        const totalToAdd = addValue * conversion;
        newDeferralConfig.deferralSummaryTotal =
            this.deferralSetupConfig.deferralSummaryTotal + totalToAdd;

        if (isVariableDeferral && value === 0) {
            if (newDeferralConfig.nonZeroVariableDeferralCount === 1) {
                //Going from one non-zero deferrals to none
                //TODO keep this in case we want to not count the var deferral if it goes to/from 0
                //  _viewModel.deferralSetupConfig.deferralSummaryTotal *= 1 / api.getBaseSalaryRatio();
            }
            newDeferralConfig.nonZeroVariableDeferralCount =
                this.adjustNonZeroVariableDeferralCount(-1);
        }

        //Update the Hash if necessary
        //api.this.checkDeferralSliderChanges();
        const newDeferral = { ...deferral, value };

        return {
            newDeferral,
            newDeferralConfig
        };
    };

    getDeferralStringDisplay = (def) => {
        const deferral = this.checkDeferral(def);
        let value = deferral.value,
            dollars = "",
            stringPercent = Math.round(value * 100) / 100 + "%";
        if (this.isDollarDeferral(deferral)) {
            dollars = "($" + (value * this.getDeferralPaycheckToTermConversion()).toFixed() + ") ";
            //if it's a deferral of $0.00, show 0%
            if (Math.round(value * 100) !== 0) {
                value =
                    this.getDollarDeferralToPercentConversion(this.getSalaryForDeferral(deferral)) *
                    value *
                    100;
                stringPercent = this.getStringDisplayPercent(value);
            }
        }
        return deferral.config.displayName + ": " + dollars + stringPercent;
    };

    getStringDisplayPercent = (value) => {
        value = Math.round(value);
        return value === 0.0 ? "<1%" : Math.round(value * 100) / 100 + "%";
    };

    getAdditionalDeferralList = () => {
        let list = [];
        _each(this.deferrals, (def) => {
            if (!this.getCanShowMultiple()) {
                if (!def.active && def.value > 0) {
                    list.push(this.getDeferralStringDisplay(def));
                }
            } else {
                // Show all deferrals if multiple (even if 0)
                list.push(this.getDeferralStringDisplay(def));
            }
        });

        /* If there is only 1 deferral found and multi is enabled reset the list back to empty */
        if (list.length === 1 && this.getCanShowMultiple()) {
            list = [];
        }

        return list;
    };

    updateDeferralEffectiveDates = () => {
        const deferrals = this.deferrals;
        const plan = this.primaryPlan;

        if (
            isUndefinedOrNull(plan.deferralInfo) ||
            isUndefinedOrNull(plan.deferralInfo.availableDeferrals)
        ) {
            return;
        }
        _each(deferrals, (deferral) => {
            const code = deferral.deferralTypeCode;
            const availableDeferrals = this.getAvailableDeferrals(plan);
            const availDef = _find(availableDeferrals, { deferralTypeCode: code });

            //Set the deferral next payroll date based on the plan type - shouldUseCalculatedEffectiveDate() is basically
            // telling us if it's a 457 plan or not.
            if (!isUndefinedOrNull(availDef)) {
                deferral.nextPayrollDate = availDef.nextPayrollDate;
            }
        });

        return deferrals;
    };

    isTotalEstimated = () => {
        return this.deferralSetupConfig.csorEstimatedCount > 0;
    };

    isCsorDeferral = (def) => {
        const deferral = this.checkDeferral(def);
        if (isUndefinedOrNull(deferral.csor)) {
            return false;
        }
        return deferral.csor;
    };

    getCsorPercentDeferralsAfterSalaryChange = (data) => {
        const deferrals = this.deferrals;
        const newDeferrals = this.primaryPlan.newDeferrals;

        for (let i = 0; i < deferrals.length; i++) {
            if (this.isCsorDeferral(deferrals[i]) && !this.isDollarDeferral(deferrals[i])) {
                let defValue = deferrals[i].value;
                if (this.isVariableDeferral(deferrals[i])) {
                    if (data.var > 0) {
                        defValue *= data.originalVar / data.var;
                    }
                } else {
                    if (data.base > 0) {
                        defValue *= data.originalBase / data.base;
                    }
                }
                deferrals[i].value = _round(defValue, 2);
                newDeferrals[i].value = deferrals[i].value;
            }
        }
        return {
            deferrals,
            newDeferrals
        };
    };

    isHSAPlan = (plan) => {
        return (
            plan.trsFlexAccountInfo &&
            trsCodes.HSA_OPTUM.indexOf(plan.trsFlexAccountInfo.sdsvCode) > -1
        );
    };

    planAllowsLiatDeferrals = (plan) => {
        return plan.isPrimary || this.isHSAPlan(plan);
    };

    getAvailableDeferrals = (plan) => {
        plan = plan || this.primaryPlan;
        if (plan.deferralInfo) {
            return plan.deferralInfo.availableDeferrals;
        } else if (this.isHSAPlan(plan)) {
            return plan.deferrals;
        }
        return null;
    };

    getAllDeferralChangedHashes = (plans) => {
        let config = {};
        let deferrals = [];
        _each(plans, (plan) => {
            if (this.planAllowsLiatDeferrals(plan)) {
                deferrals = deferrals.concat(this.getAvailableDeferrals(plan));
            }
        });
        _each(deferralChangeProperties, (value) => {
            config = { deferrals: deferrals };
            this.deferralSetupConfig[value.name].initializeHashForConfig(config);
        });
    };

    /**
     * @memberOf common.service.business.DeferralService
     * @description checks to see if a deferral is a full deferral (aka not a model only)
     * @param deferral({})
     * @returns boolean
     */
    isFullDeferral = (def) => {
        const deferral = this.checkDeferral(def);
        return deferral.config.defrlAvailCode === DeferralAvailCodes.FULL;
    };

    isGrandfatheredDeferral = (def) => {
        const deferral = this.checkDeferral(def);
        return deferral.config.defrlAvailCode === DeferralAvailCodes.GRANDFATHERED;
    };

    getHasDeferralWarnings = (warnings, submissionWarnings) => {
        const hasSubmissionWarnings =
            !isUndefinedOrNull(submissionWarnings) && submissionWarnings.length > 0;
        const hasWarnings = !isUndefinedOrNull(warnings) && warnings.length > 0;
        return hasSubmissionWarnings || hasWarnings;
    };

    canChangeDeferrals = () => {
        return this.primaryPlan.planRules.allowDeferral;
    };

    getHasDeferralRestrictions = () => {
        const deferralRestrictionsObj = this.primaryPlan.deferralRestrictions;

        return deferralRestrictionsObj ? deferralRestrictionsObj.restricted : false;
    };

    getChangedDeferrals = (type) => {
        switch (type) {
            case "full":
                return this.deferralSetupConfig.changedDeferralsFull;
            case "grandfathered":
                return this.deferralSetupConfig.changedDeferralsGrandfathered;
            default:
                return this.deferralSetupConfig.changedDeferralsModel;
        }
    };

    checkChangedDeferralsForMaximizer = (deferrals) => {
        let changingMaximizer = false;

        _each(deferrals, (def) => {
            if (def.config.maximizeEligibleInd) {
                changingMaximizer = true;
            }
        });

        return changingMaximizer;
    };

    /**
     * @memberOf common.service.business.RetirementSavingsService
     * @description Used for submission: returns list of changed deferrals.  Not used for display purposes and they
     * only include non-model only deferrals (FULL)
     * @param forceAllowChange - true (all), false(FULL only)
     * @returns {Array}
     */
    getChangedDeferralsList = (forceAllowChange, priorDeferrals, warnings, submissionWarnings) => {
        const hasWarnings = (warnings && warnings.length) || submissionWarnings.length;
        if ((!hasWarnings && this.canChangeDeferrals()) || forceAllowChange) {
            const deferrals = this.deferrals;
            const changedDeferrals = [];

            if (priorDeferrals.length) {
                for (let i = 0; i < deferrals.length; i++) {
                    const sameType =
                        deferrals[i].deferralTypeCode === priorDeferrals[i].deferralTypeCode;
                    const differentValue = deferrals[i].value !== priorDeferrals[i].value;
                    const fullDeferral = forceAllowChange
                        ? true
                        : this.isFullDeferral(deferrals[i]);

                    if (sameType && differentValue && fullDeferral) {
                        changedDeferrals.push(deferrals[i]);
                    } else if (!sameType) {
                        throw new Error("deferrals and newDeferrals are no longer in sync.");
                    }
                }
            }

            return changedDeferrals;
        }
        return [];
    };

    getMyContributionUrl = (individualId, primaryPlan) => {
        return StringUtil.supplant(ReferenceData.URL_MY_CONTRIBUTIONS, {
            individualId: individualId,
            groupId: primaryPlan.id
        });
    };

    getChangedStatusForDeferral = (deferral) => {
        if (!isUndefinedOrNull(deferral.autoIncrease)) {
            if (deferral.value < deferral.autoIncrease.stopAtValue && deferral.value !== 0) {
                return false;
            } else {
                return true;
            }
        } else {
            return (
                this.deferralSetupConfig.changedDeferralsFull.findIndex((d) =>
                    areShallowEqual(d, deferral)
                ) > -1
            );
        }
    };

    findApplicableDeferralDisplayNames = (deferralList, excludeFromAll) => {
        const applicableNames = [];
        let applicableNamesDisplay = "";
        if (isUndefinedOrNull(deferralList)) {
            return applicableNamesDisplay;
        }

        _each(this.primaryPlan.deferralInfo.availableDeferrals, (deferral) => {
            if (_includes(deferralList, deferral.deferralTypeCode)) {
                if (!excludeFromAll || !this.getIsFrozenDeferral(deferral)) {
                    applicableNames.push(deferral);
                }
            }
        });

        for (let i = 0; i < applicableNames.length; i++) {
            if (i !== 0 && applicableNames.length > 2) {
                applicableNamesDisplay += ", ";
            } else if (i !== 0) {
                applicableNamesDisplay += " ";
            }
            if (i !== 0 && i === applicableNames.length - 1) {
                applicableNamesDisplay += this.translations.andOr;
            }
            applicableNamesDisplay += applicableNames[i].displayName;
        }
        return applicableNamesDisplay;
    };

    isDeferralGroupCodeAll = (code) => {
        return code === "ACROSS_ALL_DEFERRALS";
    };

    getDeferralAsPercent = (deferral) => {
        if (deferral) {
            if (this.isDollarDeferral(deferral)) {
                return (
                    this.getDollarDeferralToPercentConversion(this.getSalaryForDeferral(deferral)) *
                    deferral.value *
                    100
                );
            }
            return deferral.value;
        }
    };

    addUpDeferralsForDeferralRule = (deferralTypes, deferrals) => {
        if (isUndefinedOrNull(deferralTypes)) {
            return {};
        }
        let totalValueDollars = 0;
        let totalValuePercent = 0;
        let deferralListIsDollars = true;
        let containedDeferrals = false;
        let invalidCheckedDeferrals = false;

        //The tiered rule is applied only if the deferrals within the contribDefrlTypeCode  list have ageCatchupAvailable as false or catchUp402gInd is false.
        _each(deferrals, (deferral) => {
            let deferralValue = deferral.value;

            if (_includes(deferralTypes, deferral.deferralTypeCode)) {
                //check for ageCatchupAvailable
                if (getBooleanFromIndicator(deferral.config.ageCatchupApplicable)) {
                    invalidCheckedDeferrals = true;
                }

                //todo: we need to convert future dated and auto-increase to be the right units if they are different
                //change deferral value if it's auto-increase or future dated
                if (
                    deferral.autoIncrease &&
                    deferral.autoIncrease.pctAmtCode === deferral.pctAmtCode
                ) {
                    deferralValue = Math.max(deferral.value, deferral.autoIncrease.stopAtValue);
                }
                if (deferral.futureDated && deferral.futureDated.units === deferral.pctAmtCode) {
                    deferralValue = Math.max(deferral.value, deferral.futureDated.value);
                }

                // check that the deferral value isn't 0
                if (deferralValue !== 0) {
                    containedDeferrals = true;
                }

                if (!this.isDollarDeferral(deferral) && deferralListIsDollars) {
                    deferralListIsDollars = false;
                } else if (deferralListIsDollars) {
                    totalValueDollars += deferralValue;
                }
                //add deferral percent to total percent
                if (this.isDollarDeferral(deferral)) {
                    totalValuePercent += this.getDeferralAsPercent(deferral);
                } else {
                    totalValuePercent += deferralValue;
                }
            }
        });

        return {
            isDollars: deferralListIsDollars,
            totalAmt: totalValueDollars,
            totalPct: totalValuePercent,
            containedDeferral: containedDeferrals,
            invalidCheckedDeferrals: invalidCheckedDeferrals
        };
    };

    checkCombinedDeferralRules = (deferrals, config) => {
        if (isUndefinedOrNull(this.primaryPlan.deferralInfo.combinedRules)) {
            return [];
        }
        const rules = this.primaryPlan.deferralInfo.combinedRules;
        const brokenRules = [];
        const combinedRulesMap = this.primaryPlan.deferralInfo.typeCodeMap;
        const changedModelDeferrals = config.changedDeferralsModel;

        _each(rules, (rule) => {
            const combinedDeferralInfo = this.addUpDeferralsForDeferralRule(
                rule.deferralTypeCodes,
                deferrals
            );
            let combinedDeferralsDisplay;

            if (combinedDeferralInfo.containedDeferral) {
                combinedDeferralsDisplay = this.findApplicableDeferralDisplayNames(
                    rule.deferralTypeCodes,
                    this.isDeferralGroupCodeAll(rule.deferralGroupCode)
                );

                const displayConfig = {
                    deferralMsg: combinedDeferralsDisplay,
                    dollarType: "",
                    percentType: "",
                    minValue: "",
                    maxValue: ""
                };

                let viewOnlyValue = 0;

                _each(rule.deferralTypeCodes, (code) => {
                    if (combinedRulesMap[code].isViewOnly) {
                        const viewOnlyDeferral = changedModelDeferrals.find(
                            (def) => def.deferralTypeCode === code
                        );

                        if (viewOnlyDeferral) {
                            viewOnlyValue = viewOnlyDeferral.value;
                        }
                    }
                });

                if (!combinedDeferralInfo.isDollars) {
                    displayConfig.percentType = "%";
                    //breaks min
                    if (
                        !isUndefinedOrNull(rule.minPct) &&
                        combinedDeferralInfo.totalPct < rule.minPct
                    ) {
                        displayConfig.minValue = rule.minPct;
                        const newText = StringUtil.supplant(this.translations.combineRuleMinimums, {
                            deferralMsg: displayConfig.deferralMsg,
                            dollarType: displayConfig.dollarType,
                            minValue: displayConfig.minValue,
                            percentType: displayConfig.percentType
                        });
                        brokenRules.push(newText);
                    } else if (
                        !isUndefinedOrNull(rule.maxPct) &&
                        combinedDeferralInfo.totalPct - viewOnlyValue > rule.maxPct
                    ) {
                        //breaks max
                        displayConfig.maxValue = rule.maxPct;
                        const newText = StringUtil.supplant(this.translations.combineRuleMaximums, {
                            deferralMsg: displayConfig.deferralMsg,
                            dollarType: displayConfig.dollarType,
                            maxValue: displayConfig.maxValue,
                            percentType: displayConfig.percentType
                        });
                        brokenRules.push(newText);
                    }
                } else {
                    displayConfig.dollarType = "$";
                    //breaks min
                    if (
                        !isUndefinedOrNull(rule.minAmt) &&
                        combinedDeferralInfo.totalAmt < rule.minAmt
                    ) {
                        displayConfig.minValue = rule.minAmt;
                        const newText = StringUtil.supplant(this.translations.combineRuleMinimums, {
                            deferralMsg: displayConfig.deferralMsg,
                            dollarType: displayConfig.dollarType,
                            minValue: displayConfig.minValue,
                            percentType: displayConfig.percentType
                        });
                        brokenRules.push(newText);
                    } else if (
                        !isUndefinedOrNull(rule.maxAmt) &&
                        combinedDeferralInfo.totalAmt - viewOnlyValue > rule.maxAmt
                    ) {
                        //breaks max
                        displayConfig.maxValue = rule.maxAmt;
                        const newText = StringUtil.supplant(this.translations.combineRuleMaximums, {
                            deferralMsg: displayConfig.deferralMsg,
                            dollarType: displayConfig.dollarType,
                            maxValue: displayConfig.maxValue,
                            percentType: displayConfig.percentType
                        });
                        brokenRules.push(newText);
                    }
                }
            }
        });

        return brokenRules;
    };

    checkTieredDeferralRules = (deferrals) => {
        if (isUndefinedOrNull(this.primaryPlan.deferralInfo.tierRules)) {
            return [];
        }
        const rules = this.primaryPlan.deferralInfo.tierRules;
        const brokenRules = [];

        _each(rules, (rule) => {
            const requiredDeferralInfo = this.addUpDeferralsForDeferralRule(
                rule.requiredDeferralTypeCodes,
                true,
                deferrals
            );
            const contributingDeferralInfo = this.addUpDeferralsForDeferralRule(
                rule.contributingDeferralTypeCodes,
                true,
                deferrals
            );
            let requiredDeferralsDisplay;
            let contributingDeferralsDisplay;

            const invalidRule =
                this.primaryPlan.deferralInfo.catchUp402GInd &&
                contributingDeferralInfo.invalidCheckedDeferrals;
            if (contributingDeferralInfo.containedDeferral && !invalidRule) {
                requiredDeferralsDisplay = this.findApplicableDeferralDisplayNames(
                    rule.requiredDeferralTypeCodes
                );
                contributingDeferralsDisplay = this.findApplicableDeferralDisplayNames(
                    rule.contributingDeferralTypeCodes
                );

                const displayConfig = {
                    dollarType: "",
                    percentType: "",
                    requiredDefrlMsg: requiredDeferralsDisplay,
                    contrDefrlMsg: contributingDeferralsDisplay,
                    minPercent: "",
                    minAmount: ""
                };
                if (requiredDeferralInfo.containedDeferral) {
                    if (!requiredDeferralInfo.isDollars) {
                        displayConfig.percentType = "%";
                        //breaks min
                        if (
                            !isUndefinedOrNull(rule.reqPct) &&
                            requiredDeferralInfo.totalPct < rule.reqPct
                        ) {
                            displayConfig.minPercent = rule.reqPct;
                            const newText = StringUtil.supplant(
                                this.translations.tieredRulesReqPercent,
                                {
                                    percentType: displayConfig.percentType,
                                    requiredDefrlMsg: displayConfig.requiredDefrlMsg,
                                    contrDefrlMsg: displayConfig.contrDefrlMsg,
                                    minPercent: displayConfig.minPercent
                                }
                            );
                            brokenRules.push(newText);
                        } else if (
                            !isUndefinedOrNull(rule.conPct) &&
                            requiredDeferralInfo.totalPct > rule.conPct
                        ) {
                            //breaks max
                            displayConfig.minPercent = rule.conPct;
                            const newText = StringUtil.supplant(
                                this.translations.tieredRulesConPercent,
                                {
                                    percentType: displayConfig.percentType,
                                    requiredDefrlMsg: displayConfig.requiredDefrlMsg,
                                    contrDefrlMsg: displayConfig.contrDefrlMsg,
                                    minPercent: displayConfig.minPercent
                                }
                            );
                            brokenRules.push(newText);
                        }
                    } else {
                        displayConfig.dollarType = "$";
                        //breaks min
                        if (
                            !isUndefinedOrNull(rule.reqAmt) &&
                            requiredDeferralInfo.totalAmt < rule.reqAmt
                        ) {
                            displayConfig.minAmount = rule.reqAmt;
                            const newText = StringUtil.supplant(
                                this.translations.tieredRulesReqAmount,
                                {
                                    dollarType: displayConfig.dollarType,
                                    requiredDefrlMsg: displayConfig.requiredDefrlMsg,
                                    contrDefrlMsg: displayConfig.contrDefrlMsg,
                                    minAmount: displayConfig.minAmount
                                }
                            );
                            brokenRules.push(newText);
                        } else if (
                            !isUndefinedOrNull(rule.conAmt) &&
                            requiredDeferralInfo.totalAmt > rule.conAmt
                        ) {
                            //breaks max
                            displayConfig.minAmount = rule.conAmt;
                            const newText = StringUtil.supplant(
                                this.translations.tieredRulesConAmount,
                                {
                                    dollarType: displayConfig.dollarType,
                                    requiredDefrlMsg: displayConfig.requiredDefrlMsg,
                                    contrDefrlMsg: displayConfig.contrDefrlMsg,
                                    minAmount: displayConfig.minAmount
                                }
                            );
                            brokenRules.push(newText);
                        }
                    }
                } else if (!isUndefinedOrNull(rule.reqPct)) {
                    displayConfig.percentType = "%";
                    displayConfig.minPercent = rule.reqPct;
                    const newText = StringUtil.supplant(this.translations.tieredRulesReqPercent, {
                        percentType: displayConfig.percentType,
                        requiredDefrlMsg: displayConfig.requiredDefrlMsg,
                        contrDefrlMsg: displayConfig.contrDefrlMsg,
                        minPercent: displayConfig.minPercent
                    });
                    brokenRules.push(newText);
                } else if (!isUndefinedOrNull(rule.reqAmt)) {
                    displayConfig.dollarType = "$";
                    displayConfig.minAmount = rule.reqAmt;
                    const newText = StringUtil.supplant(this.translations.tieredRulesReqAmount, {
                        dollarType: displayConfig.dollarType,
                        requiredDefrlMsg: displayConfig.requiredDefrlMsg,
                        contrDefrlMsg: displayConfig.contrDefrlMsg,
                        minAmount: displayConfig.minAmount
                    });
                    brokenRules.push(newText);
                }
            }
        });

        return brokenRules;
    };

    checkMinDeferralRules = (deferrals) => {
        const brokenRules = [];
        const displayConfig = {
            minAmt: "",
            units: "",
            name: ""
        };

        _each(deferrals, (deferral) => {
            displayConfig.units = this.isDollarDeferral(deferral) ? "dollars" : "percent";
            if (deferral.value > 0 && deferral.value < deferral.deferralLimits.minDeferral) {
                displayConfig.minAmt = deferral.deferralLimits.minDeferral;
                displayConfig.name = deferral.config.displayName;

                const newText = StringUtil.supplant(this.translations.minContributionRule, {
                    minAmt: displayConfig.minAmt,
                    units: displayConfig.units,
                    name: displayConfig.name
                });
                brokenRules.push(newText);
            }
        });
        return brokenRules;
    };

    /**
     * @memberOf common.service.business.RetirementSavingsService
     * @description
     * The effective date of the current deferral.
     *
     * NOTE: We don't have future dates coming back from the API so it's suitable to use the next pay roll date.
     *
     * @returns {Deferral.nextPayrollDate}
     */
    getEffectiveDate = (deferral) => {
        if (deferral) {
            return deferral.submissionDate;
        } else {
            return this.activeDeferral.submissionDate;
        }
    };

    getFormattedEffectiveDate = (deferral) => {
        return formatDate(this.getEffectiveDate(deferral));
    };

    getNonZeroVariableDeferralCount = () => {
        return this.deferralSetupConfig.nonZeroVariableDeferralCount;
    };

    getNewAvailableDeferrals = (availableDeferrals) => {
        let allModelOnly = true;
        let availableDeferral, index, isAddable;
        const newAvailableDeferrals = [];

        const canAdd =
            this.primaryPlan &&
            this.primaryPlan.planRules &&
            this.primaryPlan.planRules.allowAddNewContributions;

        for (let i = 0; i < availableDeferrals.length; i++) {
            availableDeferral = availableDeferrals[i];
            allModelOnly =
                allModelOnly && availableDeferral.defrlAvailCode !== DeferralAvailCodes.FULL;

            index = this.deferrals.findIndex((def) => {
                return def.deferralTypeCode === availableDeferral.deferralTypeCode;
            });

            isAddable =
                availableDeferral.defrlAvailCode !== DeferralAvailCodes.HIDDEN &&
                availableDeferral.defrlAvailCode !== DeferralAvailCodes.FROZEN;

            if (index === -1 && isAddable && canAdd) {
                newAvailableDeferrals.push(availableDeferral);
            }
        }

        return newAvailableDeferrals;
    };

    resetDeferralPriorValues = () => {
        const updatedDeferrals = [];

        for (let i = 0; i < this.deferrals.length; i++) {
            const deferral = this.deferrals[i];

            // sync up the prior value and value on the deferral so that we will have everything we need in the review changes modal
            // sync up values to be the same
            deferral.priorValue = deferral.value;
            updatedDeferrals.push(deferral);
        }

        return updatedDeferrals;
    };

    /**
     * If type code exists in combined rules map, restrict pct_amt option
     * @param {object} deferral
     */
    checkMapForTypeCodeRestrictions = (deferral, map) => {
        const typeCode = deferral.deferralTypeCode;
        let restrictPctAmtCode = false;

        if (map[typeCode]) {
            if (map[typeCode].hasConflict) {
                return true;
            }

            restrictPctAmtCode = map[typeCode].pctAmtCode !== "AMT_PCT";

            if (map[typeCode].isMandatory || map[typeCode].isViewOnly) {
                restrictPctAmtCode = false;
            }
        }

        return restrictPctAmtCode;
    };

    /**
     * If combined rules map has AMT_PCT code, update map with new code
     * @param {object} deferral
     * @param {string} pctAmtCode
     */
    updateTypeCodesMap = (deferral, pctAmtCode, map) => {
        const typeCode = deferral.deferralTypeCode;
        const groupCode = deferral.enrollmentGroupCode;

        if (map[typeCode]) {
            const ruleIndex = map[typeCode].ruleIndex;

            for (const key in map) {
                if (key !== typeCode) {
                    if (map[key].ruleIndex === ruleIndex && map[key].pctAmtCode === "AMT_PCT") {
                        map[key].pctAmtCode = pctAmtCode;
                    }

                    if (map[key].groupCode === groupCode && map[key].pctAmtCode === "AMT_PCT") {
                        map[key].pctAmtCode = pctAmtCode;
                    }
                }
            }
        }
    };

    getDeferralHasConflict = (deferral, map) => {
        let hasConflict = false;

        if (deferral) {
            const typeCode = deferral.deferralTypeCode;

            if (map[typeCode]) {
                hasConflict = !isUndefinedOrNull(map[typeCode].hasConflict)
                    ? map[deferral.deferralTypeCode].hasConflict
                    : hasConflict;
            }
        }

        return hasConflict;
    };
}
