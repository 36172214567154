/**
 * @name app.common.model.domain.ModelPortfolio
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 */

"use strict";

class ModelPortfolio {
    constructor(config) {
        this.modelDescription = "Default Model Portfolio Name";
        this.modelNumber = "123456";
        this.effectiveDate = null;
        this.rebalanceDate = null;

        if (config) {
            this.modelDescription = config.modelDescription;
            this.modelNumber = config.modelNumber;
            this.effectiveDate = config.effectiveDate;
            this.rebalanceDate = config.rebalanceDate;
        }
    }
}

export default ModelPortfolio;
