export const SET_SPOUSE = "SET_SPOUSE";
export const SET_SPOUSE_PROJECTIONS_MAP = "SET_SPOUSE_PROJECTIONS_MAP";
export const SET_SPOUSE_FIRST_NAME = "SET_SPOUSE_FIRST_NAME";
export const SET_SPOUSE_SALARY = "SET_SPOUSE_SALARY";
export const SET_SPOUSE_GENDER = "SET_SPOUSE_GENDER";
export const SET_SPOUSE_DATE_OF_BIRTH = "SET_SPOUSE_DATE_OF_BIRTH";
export const SET_SPOUSE_AGE = "SET_SPOUSE_AGE";
export const SET_SPOUSE_RETIREMENT_AGE = "SET_SPOUSE_RETIREMENT_AGE";
export const DELETE_SPOUSE = "DELETE_SPOUSE";
export const UPDATE_SPOUSE_INCOME_GOAL = "UPDATE_SPOUSE_INCOME_GOAL";
export const UPDATE_SPOUSE_RETIREMENT_ASSETS = "UPDATE_SPOUSE_RETIREMENT_ASSETS";
export const UPDATE_SPOUSE_EXTERNAL_ASSETS = "UPDATE_SPOUSE_EXTERNAL_ASSETS";
