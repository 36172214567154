import type { Investments } from "../../../types/common/index";

/**
 * Does the participant have portfolio management
 * @returns {boolean}
 */
const getIsPortfolioManagementAllowed = (
    individualOptions: Investments["individualOptions"]
): boolean => {
    return individualOptions.portfolioManagementAllowed;
};
export default getIsPortfolioManagementAllowed;
