/**
 * @name app.common.model.domain.OneClickSolution
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Creates a defined object structure for the OneClickSolution object type.
 */

/**
 * The OneClickSolution object constructor.
 * NOTE: TJM 6/24/15 modified this to fit the data since it doesn't conform to the DM diagram
 * @param config This object of properties you would like to base the plan off of. Note that only primitives are used.
 * @constructor
 */

"use strict";

class OneClickSolution {
    constructor(config) {
        this.allowRiskBasedFunds = null;
        this.allowTargetDateFunds = null;
        this.isCurrentInRiskBasedFund = null;
        this.isCurrentInTargetDateFund = null;
        this.assetModelsAllowed = null;
        this.currentAllocationModel = null;
        this.enrolledOptionsNames = null;

        this.isAvailable = null;
        this.isEnrolled = null;

        if (config) {
            this.allowRiskBasedFunds = config.allowRiskBasedFunds;
            this.allowTargetDateFunds = config.allowTargetDateFunds;
            this.isCurrentInRiskBasedFund = config.isCurrentInRiskBasedFund;
            this.isCurrentInTargetDateFund = config.isCurrentInTargetDateFund;
            this.enrolledOptionsNames = config.enrolledOptionsNames;
            this.assetModelsAllowed = config.assetModelsAllowed;
            this.currentAllocationModel = config.currentAllocationModel;
            this.modelName = config.modelName;
            this.isAvailable = config.isAvailable;
            this.isEnrolled = config.isEnrolled;
        }
    }
}

export default OneClickSolution;
