/**
 *
 * @class common.factory.ProjectedIncomeResponseFactory
 * @memberOf common.factory
 * @date 02/04/2014
 * @copyright 2015 Empower Retirement
 * @type Class
 * @description return properly structured projectedIncome data with default fallbacks
 *
 */

const ProjectedIncomeResponseFactory = function () {
    const IProjectedIncomeResponseFactory = {
        /**
         *  CURRENT RESPONSE FROM SERVER
         var incomeProjection = {
                        projected: 0,
                        gap: 0,
                        details: {
                            currentEmployerContributions: 0,
                            currentEmployeeContributions: 0,
                            futureEmployerContributions: 0,
                            futureEmployeeContributions: 0,
                            governmentBenefits: ( typeof appSettings.governmentBenefits !== "undefined" ) ? appSettings.governmentBenefits : 0,
                            otherEmployee: 0,
                            percentOfGoal: 0,
                            incomeGoalValue: 0
                        }

                    };
         */

        getProjectedIncome: function (projectedIncome) {
            const result = {
                total: 0,
                percentageOfGoal: 0,
                incomeGoalValue: 0,
                incomeParts: [],
                incomeGap: 0
            };

            if (projectedIncome === undefined || projectedIncome === null) {
                return result;
            }

            //set the total and percentage of goal
            result.total = projectedIncome.projected ? projectedIncome.projected : 0;
            // this is the value displayed in the goal circle
            result.percentageOfGoal = projectedIncome.percentOfGoal
                ? projectedIncome.percentOfGoal
                : 0;
            //cap goal at 999%
            result.percentageOfGoal = Math.min(result.percentageOfGoal, 999);
            result.incomeGap = projectedIncome.gap ? projectedIncome.gap : 0;
            // this is the value displayed in the goal modal "My goal is $x per term"
            result.incomeGoalValue = projectedIncome.incomeGoalValue
                ? projectedIncome.incomeGoalValue
                : 0;

            const piDetails = projectedIncome.details;

            // build income parts
            const myContributions = {
                id: "myContributions",
                details: {
                    current: {
                        id: "currentEmployeeContributions",
                        value: piDetails.currentEmployeeContributions
                    },
                    future: {
                        id: "futureEmployeeContributions",
                        value: piDetails.futureEmployeeContributions
                    }
                },
                value:
                    piDetails.currentEmployeeContributions + piDetails.futureEmployeeContributions
            };

            const employerContributions = {
                id: "employerContributions",
                details: {
                    current: {
                        id: "currentEmployerContributions",
                        value: piDetails.currentEmployerContributions
                    },
                    future: {
                        id: "futureEmployerContributions",
                        value: piDetails.futureEmployerContributions
                    }
                },
                value:
                    piDetails.currentEmployerContributions + piDetails.futureEmployerContributions
            };

            const otherAssets = {
                id: "otherAssets",
                value: piDetails.otherEmployee
            };

            result.incomeParts.push(myContributions);
            result.incomeParts.push(employerContributions);
            result.incomeParts.push(otherAssets);
            result.incomeParts.push({
                id: "governmentBenefits",
                value: piDetails.governmentBenefits
            });

            if (piDetails.hsa) {
                const hsa = {
                    id: "hsa",
                    details: {
                        currentHSAContributions: {
                            id: "currentHSAContributions",
                            value: piDetails.hsa.currentContributions
                        },
                        futureHSAContributions: {
                            id: "futureHSAContributions",
                            value: piDetails.hsa.futureContributions
                        }
                    },
                    value: piDetails.hsa.currentContributions + piDetails.hsa.futureContributions
                };

                result.incomeParts.push(hsa);
            }

            // add the income gap
            result.incomeParts.push({
                id: "incomeGap",
                value: projectedIncome.gap ? projectedIncome.gap : 0
            });

            return result;
        }
    };

    return IProjectedIncomeResponseFactory;
};

export default new ProjectedIncomeResponseFactory();
