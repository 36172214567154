import { remove as _remove } from "lodash";

import {
    SET_HEALTH_VIEW_DATA,
    SET_HEALTH_CONDITION,
    SET_SELECTED_RETIREMENT_STATE_CODE,
    RESET_PENDING_HEALTHCARE_CHANGES,
    SET_ATTAINED_AGE,
    SET_SHOW_LOADER,
    SET_HEALTH_VIEW_ERROR
} from "../../actions/healthcare";
import { SET_INIT_DATA } from "../../actions/initData";
import {
    selectCondition,
    selectPendingHealthConditionChanges,
    selectPendingHealthConditionChange
} from "../../selectors/healthCareSelectors";

const initialState = {
    attainedAge: null,
    healthViewData: {
        chartLegends: [],
        notifications: [],
        projections: [],
        salaryBrackets: [],
        yieldThreshold: 0,
        warnThreshold: 0,
        healthCareDataLoaded: false,
        healthSurvey: {
            pptHealthConditions: [],
            selectedRetirementState: "",
            states: [] //not sure what this is an array of yet TJM 4/14
        }
    },
    pendingChanges: {
        healthConditions: [],
        selectedRetirementStateCode: null
    },
    showHealthCareCostsDetails: false,
    showLoader: true,
    errorOccurred: false
};

const addPendingHealthConditionChange = (state, condition) => {
    const originalCondition = selectCondition(
        state.healthViewData.healthSurvey,
        condition.conditionCode
    );

    const isAdd = condition.isPreselected && !originalCondition.isPreselected;
    const isRemove = !condition.isPreselected && originalCondition.isPreselected;

    const config = { ...condition };

    if (isAdd) {
        config.actionCode = "NEW";
    } else if (isRemove) {
        config.actionCode = "DELETE";
    } else {
        config.actionCode = null;
    }

    const healthConditions = [...selectPendingHealthConditionChanges(state)];
    const pendingChangeExistForCondition = !!selectPendingHealthConditionChange(
        state,
        condition.conditionCode
    );

    // if it's not in the list of pending changes, we want to add it
    if (!pendingChangeExistForCondition && (isAdd || isRemove)) {
        healthConditions.push(config);
    } else {
        // this condition IS in the list of pending changes already, but it doesn't differ from original value
        // so this *should* be a reversal, so remove it...
        _remove(healthConditions, function (item) {
            return item.conditionCode === config.conditionCode;
        });
    }

    return healthConditions;
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_INIT_DATA:
            return {
                ...state,
                attainedAge: payload.user.retirementAge
            };

        case SET_HEALTH_VIEW_DATA:
            return {
                ...state,
                healthViewData: payload,
                healthCareDataLoaded: true,
                showLoader: false
            };
        case SET_HEALTH_CONDITION:
            return {
                ...state,
                pendingChanges: {
                    ...state.pendingChanges,
                    healthConditions: addPendingHealthConditionChange(state, payload)
                }
            };
        case SET_SELECTED_RETIREMENT_STATE_CODE:
            return {
                ...state,
                pendingChanges: {
                    ...state.pendingChanges,
                    selectedRetirementStateCode: payload
                }
            };

        case RESET_PENDING_HEALTHCARE_CHANGES:
            return { ...state, pendingChanges: initialState.pendingChanges };
        case SET_ATTAINED_AGE:
            return { ...state, attainedAge: payload };

        case SET_SHOW_LOADER:
            return {
                ...state,
                showLoader: payload
            };
        case SET_HEALTH_VIEW_ERROR:
            return {
                ...state,
                errorOccurred: true
            };

        default:
            return state;
    }
};
