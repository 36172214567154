import {
    SET_PRIMARY_PLAN,
    SET_PRIMARY_PLAN_RULES_SALARY_REMINDER,
    SET_DEFERRAL_RESTRICTIONS,
    SET_ALLOW_DEFERRAL,
    SET_MAXIMIZER_IS_ONGOING,
    SET_DEFERRAL_INFO,
    UPDATE_PRIMARY_PLAN_SALARY,
    SET_PRIMARY_PLAN_ID
} from "./plansActionsTypes";

/**
 * ACTIONS FOUND IN THIS FILE
 * @setPrimaryPlan
 */
export const setPrimaryPlan = (payload) => {
    return {
        type: SET_PRIMARY_PLAN,
        payload
    };
};

export const setPrimaryPlanRulesSalaryReminder = (payload) => {
    return {
        type: SET_PRIMARY_PLAN_RULES_SALARY_REMINDER,
        payload
    };
};

export const setMaximizerIsOngoing = (payload) => {
    return {
        type: SET_MAXIMIZER_IS_ONGOING,
        payload
    };
};

export const setDeferralRestrictions = (payload) => {
    return {
        type: SET_DEFERRAL_RESTRICTIONS,
        payload
    };
};

export const setAllowDeferral = (payload) => {
    return {
        type: SET_ALLOW_DEFERRAL,
        payload
    };
};

export const setDeferralInfo = (payload) => {
    return {
        type: SET_DEFERRAL_INFO,
        payload
    };
};

export const updatePrimaryPlanSalary = (payload) => {
    return {
        type: UPDATE_PRIMARY_PLAN_SALARY,
        payload
    };
};

export const setPrimaryPlanId = (payload) => {
    return {
        type: SET_PRIMARY_PLAN_ID,
        payload
    };
};
