"use strict";
/**
 * @name app.common.model.domain.HowDoICompare
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @type Class
 * @description
 * Creates a defined object structure for the HowDoICompare object type.
 */

class HowDoICompare {
    constructor(config) {
        this.ageGroups = [];
        this.genderList = [];
        this.salaryGroups = [];
        this.scoreMap = {};
        this.yieldThreshold = 0.0;
        this.warnThreshold = 0.0;

        if (config) {
            this.ageGroups = config.ageGroups;
            this.genderList = config.genderList;
            this.salaryGroups = config.salaryGroups;
            this.scoreMap = config.scoreMap;
            this.yieldThreshold = config.yieldThreshold;
            this.warnThreshold = config.warnThreshold;
        }
    }
}

export default HowDoICompare;
