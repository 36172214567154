import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const DefaultMessage = ({ showNextSteps }) => {
    const checkBack = selectTranslations("bestNextStep").checkBack;
    const hasNoMatchesOrProfitSharing = useSelector(
        (state) =>
            state &&
            state.nextSteps &&
            state.nextSteps.companyMatch &&
            state.nextSteps.companyMatch.matchAndProfitSharingMessages &&
            state.nextSteps.companyMatch.matchAndProfitSharingMessages.length === 0
    );

    return (
        <div className="no-bns-company-match-content">
            {!showNextSteps && hasNoMatchesOrProfitSharing ? (
                <ul className="trade-offs list-unstyled">
                    <li className="best-next-step-default-text">
                        <i className="fa fa-solid fa-circle-check gray-color-400" />
                        <span>{` ${checkBack}`}</span>
                    </li>
                </ul>
            ) : null}
        </div>
    );
};
DefaultMessage.propTypes = {
    showNextSteps: PropTypes.bool
};

export { DefaultMessage };
