export const SET_SOCIAL_SECURITY = "SET_SOCIAL_SECURITY";
// PARTICIPANT
export const SET_PARTICIPANT_MONTHLY_AMOUNT_OVERRIDE = "SET_PARTICIPANT_MONTHLY_AMOUNT_OVERRIDE";
export const SAVE_SOCIAL_SECURITY_CHANGE = "SAVE_SOCIAL_SECURITY_CHANGE";
export const SET_PARTICIPANT_START_REC_AGE = "SET_PARTICIPANT_START_REC_AGE";

export const SAVE_SOCIAL_SECURITY_UPDATE_SUCCESS = "SAVE_SOCIAL_SECURITY_UPDATE_SUCCESS";
export const SAVE_SOCIAL_SECURITY_UPDATE_FAIL = "SAVE_SOCIAL_SECURITY_UPDATE_FAIL";

export const SET_MIGRATED_SOCIAL_SECURITY = "SET_MIGRATED_SOCIAL_SECURITY";
export const SET_SPOUSE_MONTHLY_AMOUNT_OVERRIDE = "SET_SPOUSE_MONTHLY_AMOUNT_OVERRIDE";

export const SET_PARTICIPANT_UPDATE_DONE = "SET_PARTICIPANT_UPDATE_DONE";
export const SET_SPOUSE_UPDATE_DONE = "SET_PARTICIPANT_UPDATE_DONE";
