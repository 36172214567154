import { hasHsaPlan } from "../../../../business/hsaLogic/hsa";

const showPlanLabel = ({ planList, linkedAccounts, allAccounts, is1Id }) => {
    let showLabel = true;

    const singleWithHsa = planList.length < 3 && hasHsaPlan(planList);
    const hasLinkedAccounts = linkedAccounts?.length > 0;

    if ((planList.length < 2 || singleWithHsa) && !hasLinkedAccounts) {
        showLabel = false;
    }

    if (allAccounts.length > 1 && is1Id) {
        showLabel = true;
    }

    return showLabel;
};

export default showPlanLabel;
