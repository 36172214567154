/**
 * @name app.common.model.domain.HealthViewProjection
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Creates a defined object structure for the HealthViewProjection object type.
 */

/**
 * The HealthViewProjection object constructor.
 * @param config This object of properties you would like to base the plan off of. Note that only primitives are used.
 * @constructor
 */

"use strict";

const HealthViewProjection = function (config) {
    this.attainedAge = 0;
    this.oopChartConfigs = [];
    this.premiumChartConfigs = [];
    this.totalHealthCareCost = 0.0; //TJM not getting this
    this.totalOOPCost = 0.0;
    this.totalPremiumCost = 0.0;

    if (config) {
        this.attainedAge = config.attainedAge;
        this.oopChartConfigs = config.oopChartConfigs;
        this.premiumChartConfigs = config.premiumChartConfigs;
        this.totalHealthCareCost = config.totalHealthCareCost;
        this.totalOOPCost = config.totalOOPCost;
        this.totalPremiumCost = config.totalPremiumCost;
    }
};

export default HealthViewProjection;
