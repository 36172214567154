import React, { useState, useEffect, useRef, useCallback } from "react";

import { queryFocusableElements } from "core-ui/client/react/core/utils/accessibilityHelpers";
import { isNil as _isNil } from "lodash";
import { useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import { RETIREMENT_INCOME_ROUTE } from "../../../routes";
import TABS from "../../constants/Tabs";
import { useIsEmulator, useIsPrivileged } from "../../hooks/useEmulatorType";
import { canShowMaximizer, canShowPreRetirementView } from "../../selectors/featureFlagSelectors";
import NavLink from "../navigation/CustomNavLink";

import getDesktopTabsRoutes from "./helpers/getDesktopTabsRoutes";

const DesktopTabs = () => {
    const [activeTab, setActiveTab] = useState(TABS.RETIREMENT_INCOME);
    const [focusTabIndex, setFocusTabIndex] = useState(-1);
    const location = useLocation();
    const navigation = useSelector((state) => state.navigation.tabs);
    const participant = useSelector((state) => state.participant);
    const primaryPlan = useSelector((state) => state.primaryPlan);

    const howDoICompare = useSelector((state) => state.howDoICompare);
    const healthCare = useSelector((state) => state.healthCare);
    const isEmulator = useIsEmulator();
    const isPrivileged = useIsPrivileged();
    const isPAE = isEmulator && !isPrivileged;

    const history = useHistory();

    const tabsList = getDesktopTabsRoutes({
        primaryPlan,
        participant,
        howDoICompare,
        healthCare,
        isPAE
    });
    const routes = tabsList.map((tab) => tab.route);
    const visibleTabs = tabsList.map((tab) => tab.tabName);

    const getRouteIndex = useCallback(() => {
        let routeIndex = -1;
        if (
            routes.some((route, index) => {
                if (location.pathname.includes(route)) {
                    routeIndex = index;
                    return true;
                }
            })
        )
            return routeIndex;
    }, [location.pathname, routes]);

    const isFocusOnPrimaryTabs = () =>
        document.activeElement.id && document.activeElement.id.includes("income-parts-tabs");
    const isFocusOnNavArrow = () =>
        document.activeElement.id && document.activeElement.id.includes("arrow");

    const isFocusOnDesktopTabs = useCallback(
        () =>
            routes.some(
                (route) =>
                    document.activeElement.href && document.activeElement.href.includes(route)
            ),
        [routes]
    );

    const checkResetActiveTab = useCallback(() => {
        const resetPreRetirement = canShowPreRetirementView(participant, primaryPlan);

        if (!resetPreRetirement && activeTab === TABS.PRE_RETIREMENT) {
            history.push(RETIREMENT_INCOME_ROUTE);
        }
    }, [activeTab, history, participant, primaryPlan]);

    useEffect(() => {
        const route = navigation.find(
            (route) =>
                getLastStringSlashSeparatedPart(route.route) ===
                getLastStringSlashSeparatedPart(location.pathname)
        );
        if (!_isNil(route)) {
            setActiveTab(route.title);
        }
        checkResetActiveTab();
    }, [checkResetActiveTab, location.pathname, navigation]);

    useEffect(() => {
        const routeIndex = getRouteIndex();
        if (routeIndex > -1 && !isFocusOnPrimaryTabs && !isFocusOnNavArrow) {
            setFocusTabIndex(routeIndex);
        }
    }, [getRouteIndex, location]);

    const getLastStringSlashSeparatedPart = (str) => {
        const splittedArray = str.split("/");
        return splittedArray.pop();
    };

    useEffect(() => {
        const isFocusOnTabs = isFocusOnDesktopTabs();
        if (focusTabIndex > -1 && listElements && isFocusOnTabs) {
            listElements.current[focusTabIndex].focus();
        }
    }, [focusTabIndex, isFocusOnDesktopTabs]);

    const handleTabListKeydown = (event) => {
        event.preventDefault();
        let nextFocusTabIndex;
        let index;
        switch (event.key) {
            case "ArrowLeft":
                index =
                    ((focusTabIndex === -1 ? visibleTabs.indexOf(activeTab) : focusTabIndex) - 1) %
                    visibleTabs.length;
                nextFocusTabIndex = index === -1 ? visibleTabs.length - 1 : index;
                setFocusTabIndex(nextFocusTabIndex);
                break;
            case "ArrowRight":
                nextFocusTabIndex =
                    ((focusTabIndex === -1 ? visibleTabs.indexOf(activeTab) : focusTabIndex) + 1) %
                    visibleTabs.length;
                setFocusTabIndex(nextFocusTabIndex);
                break;
            case "Tab":
                if (event.shiftKey) {
                    const rightArrowEl = document.querySelector(".right.arrow");
                    if (rightArrowEl) {
                        rightArrowEl.focus();
                    } else {
                        document.querySelector("#income-parts-tabs-1").focus();
                    }

                    setFocusTabIndex(-1);
                } else {
                    document
                        .querySelector("#retirement-savings-slide")
                        .querySelector(queryFocusableElements)
                        .focus();

                    setFocusTabIndex(-1);
                }
                break;
            case "Enter":
                listElements.current[focusTabIndex].click();
                break;
            case " ":
                listElements.current[focusTabIndex].click();
                break;
        }
    };

    const handleTabListFocus = () => {
        listElements.current[focusTabIndex === -1 ? getRouteIndex() : focusTabIndex].focus();
    };

    visibleTabs.push(TABS.RETIREMENT_INCOME);

    const tablist = useRef(null);
    const listElements = useRef([]);

    const tabListClassName = `list-middle-tabs ${canShowMaximizer(primaryPlan) ? " includes-maximizer" : ""}`;

    return (
        <div className="middle-tabs--desktop" role="complementary" data-testid="desktop-tabs">
            <div className={`middle-tabs-nav`}>
                <div className={tabListClassName} ref={tablist} role="tablist">
                    {tabsList.map((tab, index) => (
                        <NavLink
                            id={tab.id}
                            to={tab.route}
                            key={tab.tabName}
                            onKeyDown={handleTabListKeydown}
                            onFocus={handleTabListFocus}
                            aria-selected={activeTab === tab.tabName}
                            {...(activeTab === tab.tabName && {
                                "aria-controls": tab.ariaControls
                            })}
                            ref={(el) => (listElements.current[index] = el)}
                            role="tab"
                            disableLink={tab.disableLink}
                            tooltip={tab.tooltip || ""}
                            activeClassName={"is-active"}
                        >
                            {tab.translationKey}
                        </NavLink>
                    ))}
                </div>
            </div>
        </div>
    );
};

export { DesktopTabs };
