import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";

import {
    SET_HEALTH_VIEW_DATA,
    resetPendingChanges,
    setShowLoader,
    setHealthViewError
} from "../actions/healthcare";
import { updateProjectedIncome } from "../actions/projectedIncome/projectedIncomeActions";
import { setCurrentFault } from "../actions/shared/sharedActionCreators";
import InitDataFactory from "../factories/InitDataFactory";
import { updateProjectionFactors } from "../middleware/projectionFactorsMiddleware";
import { getHealthViewData, saveHealthViewData } from "../services/healthCareService";

export const loadHealthViewData = () => {
    return (dispatch, getState) => {
        const state = getState();

        return getHealthViewData(state.participant.personId)
            .then((result) => {
                dispatch({
                    type: SET_HEALTH_VIEW_DATA,
                    payload: InitDataFactory.createHealthViewData(result)
                });
            })
            .catch(() => {
                dispatch(setHealthViewError());

                dispatch(setShowLoader(false));
            });
    };
};

export const saveHealthViewConditions = function () {
    return (dispatch, getState) => {
        dispatch(setShowLoader(true));

        const state = getState();
        const pendingChanges = state.healthCare.pendingChanges;
        const personId = state.participant.personId;
        const selectedRetirementState =
            state.healthCare.healthViewData.healthSurvey.selectedRetirementState;

        return saveHealthViewData(pendingChanges, personId, selectedRetirementState).then(
            async (result) => {
                if (Object.prototype.hasOwnProperty.call(result, "isApiError")) {
                    const translations = selectTranslations("errors");

                    result.message = translations.defaultErrorMessage;
                    dispatch(setShowLoader(false));
                    return dispatch(setCurrentFault(result));
                }

                dispatch(resetPendingChanges());
                await dispatch(updateProjectionFactors(true));
                await dispatch(updateProjectedIncome());

                return dispatch(loadHealthViewData());
            }
        );
    };
};
