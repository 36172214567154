import { pick as _pick, isEmpty as _isEmpty } from "lodash";

import { SET_INIT_DATA } from "../../actions/initData";
import {
    CREATE_MIGRATED_SPOUSE_EXTERNAL_ASSET,
    DELETE_MIGRATED_SPOUSE_RETIREMENT_ASSET,
    DELETE_MIGRATED_SPOUSE_EXTERNAL_ASSETS,
    CREATE_MIGRATED_SPOUSE_RETIREMENT_ASSET,
    UPDATE_MIGRATED_SPOUSE_RETIREMENT_ASSET
} from "../../actions/otherAssets/otherAssetsTypes";
import {
    SET_SPOUSE,
    SET_SPOUSE_PROJECTIONS_MAP,
    SET_SPOUSE_FIRST_NAME,
    SET_SPOUSE_SALARY,
    SET_SPOUSE_GENDER,
    SET_SPOUSE_DATE_OF_BIRTH,
    SET_SPOUSE_AGE,
    SET_SPOUSE_RETIREMENT_AGE,
    DELETE_SPOUSE,
    UPDATE_SPOUSE_INCOME_GOAL,
    UPDATE_SPOUSE_RETIREMENT_ASSETS,
    UPDATE_SPOUSE_EXTERNAL_ASSETS
} from "../../actions/spouse/spouseActionTypes";
import ReferenceData from "../../constants/ReferenceData";
import {
    removeAssetFromArray,
    removeRetirementAsset,
    updateAssetBasedOnInvestorAccountId
} from "../../selectors/otherAssetsSelectors";

const initialState = {
    age: 0,
    adjustedLEAge: ReferenceData.MAX_RETIREMENT_AGE,
    defaultLEAge: ReferenceData.MAX_RETIREMENT_AGE,
    clientId: "",
    dataIsLoaded: false,
    dateOfBirth: "",
    dateOfBirthSource: "",
    exists: false,
    externalAssets: [],
    firstName: "",
    gender: "U",
    headOfHousehold: false,
    individualId: "",
    investorId: "",
    incomeGoal: {
        value: 75,
        valueUnits: "PCT",
        default: 75
    },
    lastName: "",
    middleName: "",
    outsideAssetCategories: [],
    outsideAssets: [],
    personId: "",
    pcapPersonId: -1,
    projectionsMap: [],
    retirementAge: ReferenceData.DEFAULT_RETIREMENT_AGE,
    retirementAssets: [],
    salary: { base: 0, variable: 0, total: 60000, tempVariable: null, source: "" },
    specialLimit: { limit: 0, planEnabled: false, pourOverEnabled: false, type: null },
    type: ""
};

const getNewStateFromSpouse = (state, spouse) => {
    if (_isEmpty(spouse)) {
        return { ...state, dataIsLoaded: true, exists: false };
    }
    return {
        ...state,
        ..._pick(spouse, Object.keys(state)),
        dataIsLoaded: true,
        exists: true
    };
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case DELETE_SPOUSE:
            return { ...initialState };
        case SET_INIT_DATA:
            return getNewStateFromSpouse(state, payload.spouse);
        case SET_SPOUSE:
            return getNewStateFromSpouse(state, payload);
        case SET_SPOUSE_FIRST_NAME:
            return { ...state, firstName: payload };
        case SET_SPOUSE_AGE:
            return { ...state, age: payload };
        case SET_SPOUSE_DATE_OF_BIRTH:
            return { ...state, dateOfBirth: payload };
        case SET_SPOUSE_GENDER:
            return { ...state, gender: payload };
        case SET_SPOUSE_RETIREMENT_AGE:
            return { ...state, retirementAge: payload };
        case SET_SPOUSE_SALARY:
            return { ...state, salary: payload };
        case SET_SPOUSE_PROJECTIONS_MAP:
            return { ...state, projectionsMap: payload };
        case UPDATE_SPOUSE_INCOME_GOAL:
            return { ...state, incomeGoal: { ...state.incomeGoal, ...payload } };

        case CREATE_MIGRATED_SPOUSE_EXTERNAL_ASSET:
            return { ...state, externalAssets: [...state.externalAssets, payload] };

        case DELETE_MIGRATED_SPOUSE_EXTERNAL_ASSETS: {
            const removedExternalAsset = removeAssetFromArray(state.externalAssets, payload);
            return { ...state, externalAssets: [...removedExternalAsset] };
        }

        case CREATE_MIGRATED_SPOUSE_RETIREMENT_ASSET:
            return { ...state, retirementAssets: [...state.retirementAssets, payload] };

        case UPDATE_MIGRATED_SPOUSE_RETIREMENT_ASSET:
            return {
                ...state,
                retirementAssets: updateAssetBasedOnInvestorAccountId(
                    state.retirementAssets,
                    payload
                )
            };

        case DELETE_MIGRATED_SPOUSE_RETIREMENT_ASSET: {
            const removedRetirementAsset = removeRetirementAsset(
                state.retirementAssets,
                payload.investorAccountId
            );
            return { ...state, retirementAssets: [...removedRetirementAsset] };
        }

        case UPDATE_SPOUSE_RETIREMENT_ASSETS:
            return {
                ...state,
                retirementAssets: payload
            };

        case UPDATE_SPOUSE_EXTERNAL_ASSETS:
            return {
                ...state,
                externalAssets: payload
            };
        default:
            return state;
    }
};
