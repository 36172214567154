import { getPrimaryGrpAcct } from "core-ui/client/react/core/services/accountService";
import { ktmgFlags } from "gw-shared-components";

import { setParticipantIndividualId } from "../actions/participantDetails/participantDetailsActions";
import { setPrimaryPlanId } from "../actions/plans/primaryPlanActions";

import { getTxnAccessSettings, loadGroupTxnAccess } from "./sharedMiddleware";

interface PrimaryGroupAccountResponse {
    accountsDetailsLoaded?: boolean;
    database: string;
    groupId: string;
    individualId: string;
    planId: string;
    totalBal: number;
}

const {
    REFERRAL_ENABLE, // CXACR
    OPEN_NEW_ACCOUNT, // CXAOE
    OTHER_ASSETS, // WNOAIN
    INCLUDE_SOCIAL_SECURUTIY_BENFITS, // WNINSS
    PCAP_WIDGETS, // CXPFD
    HIDE_ADVISORY_SVC_MSG_SIDEBAR, // WNHASM
    SHOW_BUDGETING_WIDGET // CXNBW
} = ktmgFlags;

const setManagedAccountsData = (dispatch) => {
    dispatch(getTxnAccessSettings());
    getPrimaryGrpAcct().then(
        // TODO(EOC): Update the getPrimaryGrpAcct to clearly defined the expected response.
        (res: void | PrimaryGroupAccountResponse) => {
            if (res?.accountsDetailsLoaded) {
                dispatch(setParticipantIndividualId(res.individualId));
                dispatch(setPrimaryPlanId(res.groupId));
                dispatch(
                    loadGroupTxnAccess(
                        res.individualId,
                        res.groupId,
                        `${REFERRAL_ENABLE},${HIDE_ADVISORY_SVC_MSG_SIDEBAR},${OPEN_NEW_ACCOUNT},${OTHER_ASSETS},${INCLUDE_SOCIAL_SECURUTIY_BENFITS},${PCAP_WIDGETS},${SHOW_BUDGETING_WIDGET}`
                    )
                );
            }
        },
        (error: Error) => {
            console.log("getPrimaryGrpAcct() :: ERROR :: ", error);
        }
    );
};

export default setManagedAccountsData;
