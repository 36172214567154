import { SET_SLIDER_MOVEMENT } from "../../actions/slider/sliderActionTypes";

const initialState = {
    sliderBeingMoved: false
};

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SET_SLIDER_MOVEMENT:
            return { ...state, sliderBeingMoved: payload };
        default:
            return state;
    }
};
