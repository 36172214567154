import React, { useState, memo } from "react";

import {
    handleKeyboardDownEvent,
    keyboardInteractions
} from "core-ui/client/react/core/utils/accessibilityHelpers";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { Loader } from "gw-shared-components";
import { useSelector, useDispatch } from "react-redux";

import { dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import EventBusEvents from "../../events/eventBusEvents";
import { useMaDetails, usePrimaryPlan } from "../../queries";
import {
    getDisplayOtherAssets,
    getDisplayGovernmentBenefits
} from "../../selectors/featureFlagSelectors";
import { updatePlanToAgeOverride } from "../../services/preRetireeService";

import ConsiderationsModal from "./ConsiderationsModal";
import PreRetireeChart from "./PreRetireeChart";
import PreRetireeError from "./PreRetireeError";
import PreRetireeHeader from "./PreRetireeHeader";

const PreRetireeView = () => {
    const dispatch = useDispatch();
    const translations = selectTranslations("preRetiree");
    const participant = useSelector((state) => state.participant);
    const userRetireAge = participant.retirementAge;
    const spouseRetireAge = useSelector((state) => state.spouse).retirementAge;
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const showConsiderationModalLoader = useSelector(
        (state) => state.participant.showConsiderationModalLoader
    );
    const txn = useSelector((state) => state.shared.txnAccess);

    const [showModal, setShowModal] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    // userPrimaryPlan is different from redux primary plan
    // primaryPlan does not always have indId or gaId if there is no GHID call
    const { data: userPrimaryPlan } = usePrimaryPlan();
    const { data: maDetails } = useMaDetails(userPrimaryPlan);

    const eligibleForManagedAccounts = !maDetails?.maProduct;
    const enrolledInFullManagedAccounts = maDetails?.enrolledInManagedAccounts;
    const enrolledInAdvisorManagedAccounts = maDetails?.enrolledInManagedAccounts;

    const displayOtherAssets = getDisplayOtherAssets(participant, primaryPlan);
    const displaySocialSecurity = getDisplayGovernmentBenefits(primaryPlan);

    const canShowConsiderationsTab =
        (eligibleForManagedAccounts ||
            enrolledInFullManagedAccounts ||
            displayOtherAssets ||
            displaySocialSecurity) &&
        txn.showConsiderationsTab;

    const handleMessageClick = () => {
        const index = canShowConsiderationsTab ? 1 : 0;
        setActiveTab(index);
        dispatch(dispatchEventBus(EventBusEvents.PRERETIREE.PLAN_TO_AGE_MODAL_TEXT_CLICK, this));
        toggleModal();
    };

    const saveLEAge = (lifeExpectancyAge) => {
        dispatch(updatePlanToAgeOverride(lifeExpectancyAge));
    };

    const projectedIncome = useSelector((state) => state.projectedIncome);
    const retireeDetails = projectedIncome.retireeDetails;

    const canShowRetireeGraph = () => {
        return participant.retirementAge < participant.adjustedLEAge;
    };

    return (
        !!retireeDetails && (
            <div id="integrated-pre-retiree-view" className="pre-retiree-view">
                {showConsiderationModalLoader ? <Loader /> : null}
                {canShowRetireeGraph() ? (
                    <React.Fragment>
                        <PreRetireeHeader toggleModal={toggleModal} setActiveTab={setActiveTab} />
                        <div className="pre-retiree-row">
                            <div className="left-side">
                                <PreRetireeChart
                                    retireeDetails={retireeDetails}
                                    userRetireAge={userRetireAge}
                                    spouseRetireAge={spouseRetireAge}
                                />
                            </div>

                            <div className="right-side">
                                <p>{translations.planToAge.changeMessage}</p>
                                <button
                                    onClick={handleMessageClick}
                                    className="btn-link no-padding no-underline"
                                    onKeyDown={(e) =>
                                        handleKeyboardDownEvent({
                                            event: e,
                                            keys: keyboardInteractions.button,
                                            output: handleMessageClick
                                        })
                                    }
                                >
                                    {" "}
                                    {translations.planToAge.modifyMessage}{" "}
                                </button>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <PreRetireeError toggleModal={toggleModal} />
                )}
                <ConsiderationsModal
                    showModal={showModal}
                    toggleModal={toggleModal}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    saveLEAge={saveLEAge}
                    showConsiderationsBooleans={{
                        canShowConsiderationsTab,
                        eligibleForManagedAccounts,
                        enrolledInFullManagedAccounts,
                        enrolledInAdvisorManagedAccounts,
                        displayOtherAssets,
                        displaySocialSecurity
                    }}
                />
            </div>
        )
    );
};
export default memo(PreRetireeView);
