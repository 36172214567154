import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { NumericFormat } from "react-number-format";

import SOCIAL_SECURITY_CONSTANTS from "../../../constants/SocialSecurityConstants";

const renderPlanningData = (personData) => {
    return Object.entries(personData).map(([key, value]) => {
        const monthlyAmount = Math.round(value / 12);
        return (
            <tr key={value}>
                <td className="text-left">{key}</td>
                <td className="text-right">
                    <NumericFormat
                        value={monthlyAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                    />
                </td>
                <td className="text-right">
                    <NumericFormat
                        value={Math.round(value)}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                    />
                </td>
            </tr>
        );
    });
};

const SocialSecurityTableModal = ({ socialSecurityInfo, show, handleCloseTable, firstName }) => {
    const translations = selectTranslations("maSocialSecurity")?.SocialSecurity?.landing;

    return (
        <Modal
            show={show}
            onHide={handleCloseTable}
            className="social-security-table-modal-container"
        >
            <Modal.Header>
                <p className="person-social-security-modal-header">
                    {translations.details.ModalText1}
                    <a
                        href={SOCIAL_SECURITY_CONSTANTS.SSA_GOV_QUICKCALC}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {translations.details.ModalText2}
                    </a>
                    &nbsp;{firstName}’s
                    {translations.details.ModalText3}
                </p>
            </Modal.Header>

            <Modal.Body>
                <table className="table">
                    <thead>
                        <tr>
                            <th className="text-left">
                                <h4>{translations.details.EndAge}</h4>
                            </th>
                            <th className="text-right">
                                <h4>{translations.details.Monthly}</h4>
                            </th>
                            <th className="text-right">
                                <h4>{translations.details.Annually}</h4>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="allocation-available-funds">
                        {renderPlanningData(socialSecurityInfo.ssBenefitByStartAge)}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={handleCloseTable}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    );
};
SocialSecurityTableModal.propTypes = {
    socialSecurityInfo: PropTypes.object,
    show: PropTypes.bool,
    handleCloseTable: PropTypes.func,
    firstName: PropTypes.string
};
export default SocialSecurityTableModal;
