import { equityMixChangesAllowed } from "..";
import type { Investments } from "../../../types/common/index";

/**
 * Can submit investment mix - if this returns false, the investment mix slider is model only.
 * Look in the retirementSavingsService to change when the slider is model only, this function
 * is used to hide helpMeDoIt in the case of model only investments.
 * @returns {boolean}
 */
const canSubmitEquityMix = (individualOptions: Investments["individualOptions"]) => {
    return equityMixChangesAllowed(individualOptions);
};

export default canSubmitEquityMix;
