enum SliderText {
    NORMAL = "summarySliderTextNormal",
    OVER100 = "summarySliderTextOver100"
}

const getTextColorClass = (checkTotalDeferralPercentOver100: boolean): SliderText => {
    return checkTotalDeferralPercentOver100 ? SliderText.OVER100 : SliderText.NORMAL;
};

export default getTextColorClass;
