import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import { get as _get } from "lodash";

// in case the fault it thrown before the translations are retrieved, or there is an error in the api
// call to get translations, we default to a standard error message.
export const DEFAULT_MESSAGE =
    "An error has occurred. Please contact a Participant Services Representative if the problem persists.";
export const FAIL = "FAIL";
export const UNKNOWN = "Unknown";

const logErrorObj = (errorObj, faultLabel = "Fault") => {
    const logger = ExternalLogger.getInstance("errorUtils");
    const message = `Failed to execute ${errorObj.method} request to ${errorObj.url}. Response code: ${errorObj.httpCode}. Status: ${errorObj.status}. Code: ${errorObj.code}. ${errorObj.errorMessage}`;

    logger.error("{0}( fault = {1} )", [faultLabel, message]);
};

export const handleFault = (fault, faultLabel = "Fault") => {
    const httpCode = _get(fault, "status") || _get(fault, "response.status") || UNKNOWN;

    const url = _get(fault, "request.responseURL") || _get(fault, "config.url") || UNKNOWN;
    const method = _get(fault, "config.method") || UNKNOWN;
    const isApiError = true;
    const code = _get(fault, "data.error.code") || UNKNOWN;
    const message = _get(fault, "data.error.message") || DEFAULT_MESSAGE;
    let errorMessage = _get(fault, "message") || "";

    const status = _get(fault, "data.status") || UNKNOWN;

    if (!_get(fault, "data")) {
        errorMessage = "Empty data response from server";
    }

    const errorObj = {
        isApiError,
        httpCode,
        code,
        url,
        method,
        errorMessage,
        message,
        status,
        hasCode: !!code && code !== UNKNOWN
    };

    if (_get(fault, "config.data")) {
        errorObj.requestData = JSON.stringify(fault.config.data);
    }

    logErrorObj(errorObj, faultLabel);

    return errorObj;
};
