export const LOAD_OTHER_ASSETS = "LOAD_OTHER_ASSETS";
export const LOAD_OTHER_ASSETS_REF_DATA = "LOAD_OTHER_ASSETS_REF_DATA";
export const SPLIT_OTHER_ASSETS_BY_EMPLOYER = "SPLIT_OTHER_ASSETS_BY_EMPLOYER";
export const CREATE_OTHER_ASSET_FAILURE = "CREATE_OTHER_ASSET_FAILURE";
export const CREATE_OTHER_ASSET_SUCCESS = "CREATE_OTHER_ASSET_SUCCESS";
export const UPDATE_OTHER_ASSET_FAILURE = "UPDATE_OTHER_ASSET_FAILURE";
export const UPDATE_OTHER_ASSET_SUCCESS = "UPDATE_OTHER_ASSET_SUCCESS";
export const DELETE_OTHER_ASSET_FAILURE = "DELETE_OTHER_ASSET_FAILURE";
export const DELETE_OTHER_ASSET_SUCCESS = "DELETE_OTHER_ASSET_SUCCESS";

export const CREATE_EXTERNAL_ASSET_FAILURE = "CREATE_EXTERNAL_ASSET_FAILURE";
export const CREATE_EXTERNAL_ASSET_SUCCESS = "CREATE_EXTERNAL_ASSET_SUCCESS";
export const UPDATE_EXTERNAL_ASSET_FAILURE = "UPDATE_EXTERNAL_ASSET_FAILURE";
export const UPDATE_EXTERNAL_ASSET_SUCCESS = "UPDATE_EXTERNAL_ASSET_SUCCESS";
export const DELETE_EXTERNAL_ASSET_FAILURE = "DELETE_EXTERNAL_ASSET_FAILURE";
export const DELETE_EXTERNAL_ASSET_SUCCESS = "DELETE_EXTERNAL_ASSET_SUCCESS";

export const CREATE_MIGRATED_RETIREMENT_ASSET = "CREATE_MIGRATED_RETIREMENT_ASSET";
export const CREATE_MIGRATED_SPOUSE_RETIREMENT_ASSET = "CREATE_MIGRATED_SPOUSE_RETIREMENT_ASSET";
export const UPDATE_MIGRATED_RETIREMENT_ASSET = "UPDATE_MIGRATED_RETIREMENT_ASSET";
export const UPDATE_MIGRATED_SPOUSE_RETIREMENT_ASSET = "UPDATE_MIGRATED_SPOUSE_RETIREMENT_ASSET";
export const DELETE_MIGRATED_RETIREMENT_ASSET = "DELETE_MIGRATED_RETIREMENT_ASSET";
export const DELETE_MIGRATED_SPOUSE_RETIREMENT_ASSET = "DELETE_MIGRATED_SPOUSE_RETIREMENT_ASSET";
export const CREATE_MIGRATED_EXTERNAL_ASSET = "CREATE_MIGRATED_EXTERNAL_ASSET";
export const CREATE_MIGRATED_SPOUSE_EXTERNAL_ASSET = "CREATE_MIGRATED_SPOUSE_EXTERNAL_ASSET";
export const DELETE_MIGRATED_EXTERNAL_ASSETS = "DELETE_MIGRATED_EXTERNAL_ASSETS";
export const DELETE_MIGRATED_SPOUSE_EXTERNAL_ASSETS = "DELETE_MIGRATED_SPOUSE_EXTERNAL_ASSETS";

export const SAVE_TERMS_OF_SERVICE = "SAVE_TERMS_OF_SERVICE";
export const CALL_FOR_AA_TERMS_OF_SERVICE = "CALL_FOR_AA_TERMS_OF_SERVICE";
export const SET_TEMP_SUPPORTED_ACCOUNTS = "SET_TEMP_SUPPORTED_ACCOUNTS";
export const UPDATE_ASSETS_ESTIMATED_INCOME = "UPDATE_ASSETS_ESTIMATED_INCOME";
export const UPDATE_MY_LIFE_GOALS = "UPDATE_MY_LIFE_GOALS";
export const UPDATE_ACCOUNT_LITE_ASSETS = "UPDATE_ACCOUNT_LITE_ASSETS";

export const SET_HAS_RETAIL_ACCOUNT = "SET_HAS_RETAIL_ACCOUNT";
export const SET_HIDE_I_WANT_TO_LINKS = "SET_HIDE_I_WANT_TO_LINKS";
