import { isUndefinedOrNull } from "gw-shared-components";

import { setNextSteps } from "../actions/nextSteps/nextStepsActions";
import { setCurrentFault } from "../actions/shared/sharedActionCreators";
import { getNextSteps } from "../services/nextStepService";

export const loadNextSteps = () => {
    return (dispatch, getState) => {
        const state = getState();
        return getNextSteps(state.participant.personId)
            .then((response) => {
                const nextSteps = Array.isArray(response.data.bestNextSteps)
                    ? response.data.bestNextSteps[0]
                    : response.data.bestNextSteps;

                if (!isUndefinedOrNull(nextSteps)) {
                    dispatch(setNextSteps(nextSteps));
                }
            })
            .catch((fault) => {
                dispatch(setCurrentFault(fault));
                throw fault;
            });
    };
};
