const replaceDashes = (name: string) => {
    if (name && name.indexOf("-") !== -1) {
        name = name.replace(/-/g, " ");
    }

    return name;
};

const showSubRoute = (subRoute: string) => {
    if (subRoute.indexOf("?") !== -1) {
        return false;
    }

    return true;
};

const getSubPage = (hash: string) => {
    const splitHash = hash.split("/");
    let subPage = splitHash[1];
    let subRoute = splitHash[2];

    subPage = replaceDashes(subPage);
    subPage = `${subPage.slice(0, 1).toUpperCase()}${subPage.slice(1)}`;

    // Only show sub route if it is not a param
    if (subRoute) {
        if (showSubRoute(subRoute)) {
            subRoute = replaceDashes(subRoute);
            subPage += ` - ${subRoute}`;
        }
    }

    if (subPage === "Cash flow - spending") {
        subPage = "Budgeting";
    }
    return `'${subPage}'`;
};

export default getSubPage;
