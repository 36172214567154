import StringUtil from "core-ui/client/src/app/StringUtil";

import { executeGet } from "../utils/apiUtils.js";
import { getUrl } from "../utils/urlUtils.js";

export const getCompanyMatch = async (annualSalary) => {
    const url = StringUtil.supplant(getUrl("getCompanyMatch"), { annualSalary });

    const response = await executeGet(url, "getCompanyMatchFault");
    return response.data.data;
};
