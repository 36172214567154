import ReferenceData from "../../../../constants/ReferenceData";

export interface MtrHandlerParams {
    BASE_URL: string | null;
    isUserFeMaEnrolled: boolean;
    provider: string;
    showManagedAccounts: {
        key?: string;
        linkName?: string;
    };
}
interface MtrHandlerOutput {
    feUrl: string;
    mtrLabel: string;
    mtrUrl: string;
}
/**
 * Generates URLs and labels for retirement account management based on user enrollment and account settings.
 * This function determines the appropriate URL for the front-end enrollment page or the managed accounts page,
 * and constructs a label for the action button based on the user's enrollment status.
 *
 * @param {MtrHandlerParams} params - The parameters including user enrollment status, base URL, managed account options, and display settings.
 * @returns {Object} - An object containing:
 *                      - `feUrl`: URL for the front-end investment options or an empty string if already enrolled.
 *                      - `mtrUrl`: URL directing to either the front-end enrollment or the managed accounts page.
 *                      - `mtrLabel`: Text for the action button, varying based on enrollment status.
 */

const myTotalRetirementHandler = ({
    isUserFeMaEnrolled,
    provider,
    BASE_URL,
    showManagedAccounts
}: MtrHandlerParams): MtrHandlerOutput => {
    const feUrl = isUserFeMaEnrolled
        ? ""
        : `${BASE_URL}investment-options/allocations/express-enroll`;
    const mtrUrl =
        provider === ReferenceData.PROVIDER_FE ? feUrl : "/participant/home/#/managed-accounts";
    const mtrLabel =
        (showManagedAccounts?.key?.includes("not_enrolled") ? "Enroll in " : "Go To ") +
        showManagedAccounts.linkName;

    return { feUrl, mtrUrl, mtrLabel };
};

export default myTotalRetirementHandler;
