// ACTIVE DEFERRAL
export const UPDATE_ACTIVE_DEFERRAL = "UPDATE_ACTIVE_DEFERRAL";

// DEFERRAL CONFIG
export const SET_DEFERRAL_SETUP_CONFIG = "SET_DEFERRAL_SETUP_CONFIG";
export const SET_DEFERRAL_SUMMARY_TOTAL = "SET_DEFERRAL_SUMMARY_TOTAL";
export const SET_DEFERRAL_SUMMARY_TOTAL_BASE_AND_VAR = "SET_DEFERRAL_SUMMARY_TOTAL_BASE_AND_VAR";
export const DEFERRAL_SLIDER_CHANGE = "DEFERRAL_SLIDER_CHANGE";
export const SET_ADDITIONAL_DEFERRAL_LIST = "SET_ADDITIONAL_DEFERRAL_LIST";
export const CHANGED_AUTO_INCREASE_DEFERRALS = "CHANGED_AUTO_INCREASE_DEFERRALS";
export const CHANGED_FUTURE_DATED_DEFERRALS = "CHANGED_FUTURE_DATED_DEFERRALS";
export const DEFERRAL_CHANGED_PRIMARY_PLAN_MAXIMZER_UNENROLLING =
    "DEFERRAL_CHANGED_PRIMARY_PLAN_MAXIMZER_UNENROLLING";
export const SET_CHANGED_DEFERRALS_ARRAY = "SET_CHANGED_DEFERRALS_ARRAY";
export const SET_DEFERRAL_SUBMISSION_WARNING = "SET_DEFERRAL_SUBMISSION_WARNING";
export const SET_DEFERRAL_WARNING = "SET_DEFERRAL_WARNING";
export const SET_MAXIMIZER_CHANGES = "SET_MAXIMIZER_CHANGES";
export const RESET_HAS_DEFERRAL_CHANGED = "RESET_HAS_DEFERRAL_CHANGED";
export const SET_HAS_VARIABLE_DEFERRAL = "SET_HAS_VARIABLE_DEFERRAL";
export const RESET_AUTO_INCREASE_DEFERRALS = "RESET_AUTO_INCREASE_DEFERRALS";
export const RESET_FUTURE_DATED_DEFERRALS = "RESET_FUTURE_DATED_DEFERRALS";
export const SET_PRIMARY_PLAN_RULES_SALARY_REMINDER = "SET_PRIMARY_PLAN_RULES_SALARY_REMINDER";
