import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { ObjectUtil } from "gw-shared-components";

import SOCIAL_SECURITY_CONSTANTS from "../constants/SocialSecurityConstants";
import RequestFactory from "../factories/RequestFactory";
import { executeRequest, pcapServiceRequest, pcapServiceCall } from "../utils/apiUtils.js";
import { handleFault } from "../utils/errorUtils.js";
import { isNotEmulator, handleErrorPAE } from "../utils/paeUtils.js";
import { getUrl } from "../utils/urlUtils.js";

const logger = ExternalLogger.getInstance("SocialSecurityService");

export const saveUpdate = (data, investorId, dispatch) => {
    const handleResult = function (result) {
        if (ObjectUtil.isUndefinedOrNull(result) || ObjectUtil.isUndefinedOrNull(result.data)) {
            logger.error("saveSocialSecurityUpdate( Bad response data. )");
            throw new Error("There is no response data.");
        } else {
            logger.info("saveSocialSecurityUpdateResult()");
            return result.data.data;
        }
    };

    const params = data;
    const urlParams = {
        investorId: investorId
    };
    const url = StringUtil.supplant(getUrl("saveSocialSecurity"), urlParams);
    const method = RequestFactory.HTTP_METHOD.PUT;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const request = RequestFactory.create(url, params, method, contentType);
    delete request.headers.samlToken;

    const proceedParticipantRequest = () => {
        return executeRequest(request, "saveSocialSecurityUpdate")
            .then(handleResult)
            .catch(handleFault);
    };

    return isNotEmulator()
        .then(proceedParticipantRequest)
        .catch(() => {
            handleErrorPAE(dispatch);
        });
};

/**
 * @description Update Participant social security assumptions
 * @param {object} data
 * @param {string} personId
 * @param {string} accessCustomization
 * @param {string} context  - optional {accessCustomization, indId}
 * @return {array}
 */
export const saveSocialSecurityChangeService = (data, dispatch) => {
    const url = getUrl("updatePersonLite");

    // if annualAmountPreference is CALCULATED, it means the socialSecurityAmount is based on the ssBenefit table.
    const {
        pcapPersonId: personId,
        startReceiveAge,
        estimatedAmountCheckboxStatus,
        monthlyAmountOverride
    } = data;

    // This is an annual mount
    let socialSecurityAmount = null;
    let annualAmountPreference = SOCIAL_SECURITY_CONSTANTS.ANNUAL_AMOUNT_PREFERENCES_OPTIONS.NONE;
    const socialSecurityStartAge = Number(startReceiveAge);

    if (!estimatedAmountCheckboxStatus && monthlyAmountOverride) {
        annualAmountPreference =
            SOCIAL_SECURITY_CONSTANTS.ANNUAL_AMOUNT_PREFERENCES_OPTIONS.USER_INPUT;
        socialSecurityAmount = Number(monthlyAmountOverride) * 12;
    }

    if (estimatedAmountCheckboxStatus) {
        annualAmountPreference =
            SOCIAL_SECURITY_CONSTANTS.ANNUAL_AMOUNT_PREFERENCES_OPTIONS.CALCULATED;
    }

    const payload = {
        pcapPersonId: personId,
        socialSecurityIncome: {
            socialSecurityAmount,
            socialSecurityStartAge,
            annualAmountPreference
        }
    };

    const method = RequestFactory.HTTP_METHOD.POST;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED;
    const request = RequestFactory.create(url, payload, method, contentType);
    delete request.headers.samlToken;

    const name = "person";

    const saveUpdate = async () => {
        try {
            const result = await pcapServiceRequest({ name, ...request });
            return result;
        } catch (fault) {
            return handleFault(fault);
        }
    };

    return isNotEmulator()
        .then(saveUpdate)
        .catch(() => {
            handleErrorPAE(dispatch);
        });
};

export const getIntegratedSS = () => {
    const url = getUrl("getIntegratedSocialSecurity");
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED;
    const method = RequestFactory.HTTP_METHOD.POST;
    const faultLabel = "getIntegratedSSFault";

    return pcapServiceCall({ method, url, contentType, faultLabel })
        .then((response) => {
            if (response && response.data) {
                return response.data;
            }
        })
        .catch((err) => {
            logger.error(method + "getIntegratedSS Service Error: {0}", [err.message]);
        });
};
