import React from "react";

import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { Loader } from "gw-shared-components";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";

import Constants from "../../constants/Constants";
import { removeLeadingZeroFromDate } from "../../utils/dateUtils";

import Footer from "./Footer";
import Header from "./Header";
import SpouseBody from "./SpouseBody";
import Tabs from "./Tabs";
import UserBody from "./UserBody";
import { validateDOB } from "./validators";

/**
 * Goal Modal component. Dynamically renders modal body based on user props
 */
export default class GoalModal extends React.Component {
    constructor(props) {
        super(props);
    }
    /**
     * Validates the date of birth input field if event type is keydown
     * Passes value to dispatch function if event type is blur
     * Rejects non matching values
     * @param {object} e
     */
    handleDOBValidation = (e, isSpouse, id = "") => {
        const props = this.props;
        function handleValue(dob) {
            const validObj = validateDOB(dob, props.getAge);
            const error = validObj.errorMessage;
            const newAge = validObj.age;
            let message = "";

            if (!validObj.isValid) {
                switch (error) {
                    case "invalid":
                        message = props.labelTranslations.invalidDate;
                        break;
                    case "max":
                        message = props.labelTranslations.lowAgeLimit;
                        break;
                }
                props.updateErrorMessage(message, isSpouse);
                props.handleError(id, true);
            } else {
                if (props.userDobError || props.spouseDobError) {
                    props.updateErrorMessage(message, isSpouse);
                }
                props.handleError(id, false);
            }
            if (validObj.isValid) {
                if (isSpouse) {
                    props.handleSpouseDobChange(dob, newAge);
                } else {
                    props.handleParticipantDobChange(dob);
                }
            }
        }
        if (e.type === "paste") {
            e.preventDefault();
        } else {
            /**
             * Sanitize input value
             * IE 11 adds extra characters to value, therefore was not passing regex test
             */
            let cleanDob = e.target.value.replace(/[^\d/]/g, "");
            let len = cleanDob.length;
            let start, end;

            if (e.type === "keydown") {
                const isTabPressed = e.key === "Tab" || e.keyCode === 9;

                if (isTabPressed) {
                    return;
                }

                // IE 11 interprets numpad '/' as 'Divide' key
                // Append '/' and increment len
                if (e.key === "Divide") {
                    cleanDob += "/";
                    len++;
                }

                if (len < e.target.value.length) {
                    e.target.value = cleanDob;
                }

                start = e.target.selectionStart;
                end = e.target.selectionEnd;

                if (e.key === "Backspace" && cleanDob) {
                    if (start && start === end) {
                        if (start === len) {
                            cleanDob = `${cleanDob.slice(0, start - 1)}`;
                        } else {
                            cleanDob = `${cleanDob.slice(0, start - 1)}${cleanDob.slice(start)}`;
                        }
                    } else if (start !== end) {
                        cleanDob = `${cleanDob.slice(0, start)}${cleanDob.slice(end)}`;
                    }
                } else if ((/[^\d/]/.test(e.key) || len === 10) && e.key !== "Divide") {
                    if (e.key !== "Unidentified") {
                        e.preventDefault();
                    }
                    return;
                } else {
                    if (start === len) {
                        cleanDob += e.key;
                    } else {
                        cleanDob = `${cleanDob.slice(0, start)}${e.key}${cleanDob.slice(start)}`;
                    }
                }
                handleValue(cleanDob);
            } else if (e.type === "change") {
                /*** Using this if they select a preset option based on browser history fields */
                if (len > 7 && len <= 10) {
                    handleValue(cleanDob);
                }
            } else if (e.type === "keyup") {
                /**
                 * Fix for mobile browswers
                 * If key is unidentified use keyup event to sanitize input and remove extra characters
                 */
                if (e.key === "Unidentified") {
                    let shouldCall = true;

                    if (/[^\d/]/.test(e.target.value)) {
                        shouldCall = true;
                        cleanDob = isSpouse ? props.spouseDOB : props.dob;
                        e.target.value = cleanDob;
                    } else if (len > 10) {
                        shouldCall = false;
                        cleanDob = isSpouse ? props.spouseDOB : props.dob;
                        e.target.value = cleanDob;
                    }
                    if (shouldCall) {
                        handleValue(cleanDob);
                    }
                }
            }
        }
    };

    /**
     * Validates dollar amount portions
     * @param {number} val - Dollar amount
     * @param {number} salary - User/Spouse salary
     * @param {number} additional - Additional compensation (defaulted at 0)
     * @param {boolean} isPercent - Determines if value is dollar amount or percent
     * @param {string} id - Element id; used for setting error states
     */
    portionValidator = (val, salary, additional, isPercent, id, isSpouse) => {
        const errors = this.props.errorId;
        const hasPortion = errors.includes("part-portion") || errors.includes("spouse-portion");
        const portionMin = 0.01;
        const totalSalary = isSpouse ? salary : salary + additional;
        const errObj = {
            salLimitErr: false,
            varLimitErr: false,
            portionErr: hasPortion
        };

        if (salary > this.props.maxSalary) {
            errObj.salLimitErr = true;
        }
        if (!totalSalary) {
            if (!isSpouse) {
                id = "part-salary";
                errObj.salLimitErr = true;
            }
        }
        if (additional > this.props.maxSalary) {
            errObj.varLimitErr = true;
        }
        if (errObj.salLimitErr) {
            if (id.includes("salary")) {
                this.props.handleError(id, true, errObj, isSpouse);
            }
        } else {
            if (id.includes("salary")) {
                this.props.handleError(id, false, errObj, isSpouse);
            }
        }
        if (errObj.varLimitErr) {
            if (id === "part-var") {
                this.props.handleError(id, true, errObj, isSpouse);
            }
        } else {
            if (id === "part-var") {
                this.props.handleError(id, false, errObj, isSpouse);
            }
        }
        if (!id.includes("salary") && !id.includes("var")) {
            if (!isPercent) {
                if (val < portionMin || val > this.props.maxSalary) {
                    errObj.portionErr = true;
                    this.props.handleError(id, true, errObj, isSpouse);
                } else {
                    if (!errObj.salLimitErr && !errObj.varLimitErr) {
                        this.props.handleError(id, false, errObj, isSpouse);
                    }
                }
            } else {
                errObj.portionErr = false;
                this.props.handleError(id, false, errObj, isSpouse);
            }
        }
    };

    periodChangeHandler = (e) => {
        const period = e.target.value;
        const { handleChangeView, eventBus, mtrGoalModalEvents } = this.props;
        handleChangeView(period);
        eventBus.dispatch(mtrGoalModalEvents.UPDATE_VIEW, this, { period: period });
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.VIEW_PAGE,
            event_properties: {
                selection: mtrGoalModalEvents.UPDATE_VIEW,
                period: period
            }
        });
    };

    confidenceLevelChangeHandler = (e) => {
        const level = e.target.value;
        this.props.handleChangeConfidenceLevel(Number(level));
    };

    setErrorHighlights = (partialId) => {
        if (this.props.errorId.length) {
            for (let i = 0; i < this.props.errorId.length; i++) {
                if (this.props.errorId[i].includes(partialId)) {
                    const el = document.getElementById(this.props.errorId[i]);
                    el.classList.add("has-error");
                }
            }
        }
    };

    handleDobBlur = (e) => {
        e.target.value = removeLeadingZeroFromDate(e.target.value);
    };

    render() {
        const reducedSalaryClass = this.props.labelTranslations.reducedSalaryMessage
            ? "column"
            : "row";
        const modalClass = this.props.showModal ? "fade in" : "";
        const totalSalaryText = this.props.spouseSaved
            ? this.props.labelTranslations.household
            : this.props.labelTranslations.estimated;
        const portion = this.props.isPercent
            ? this.props.portion
            : Math.round((this.props.portion / this.props.salary) * 100);
        let totalGoal =
            this.props.spouseSaved && !this.props.deleteSpouse
                ? this.props.userGoal + this.props.spouseGoal
                : this.props.userGoal;
        totalGoal = Math.round(totalGoal);
        /** Show tabs and spouse modal body if user is MAX eligible */
        const userBodyMax = (
            <div>
                <Tabs
                    handleTabClick={this.props.handleTabClick}
                    spouseSaved={this.props.spouseSaved}
                    spouseName={this.props.spouseName}
                    userName={this.props.userName}
                    userTabActive={this.props.userTabActive}
                    isEmulator={this.props.isEmulator}
                    deleteSpouse={this.props.deleteSpouse}
                    modalTranslations={this.props.labelTranslations}
                />
                {!this.props.userTabActive ? (
                    <SpouseBody
                        handleNameChange={this.props.handleNameChange}
                        handleSpouseSalary={this.props.handleSpouseSalary}
                        handleGenderChange={this.props.handleGenderChange}
                        handleAgeChange={this.props.handleAgeChange}
                        handleSpousePortion={this.props.handleSpousePortion}
                        handleError={this.props.handleError}
                        handleDOBValidation={this.handleDOBValidation}
                        portionValidator={this.portionValidator}
                        period={this.props.period}
                        spouseName={this.props.spouseName}
                        spouseSaved={this.props.spouseSaved}
                        spouseGender={this.props.spouseGender}
                        spouseSalary={this.props.spouseSalary}
                        spouseDob={this.props.spouseDob}
                        spouseAge={this.props.spouseAge}
                        desiredRetAge={this.props.desiredRetAge}
                        spousePortion={this.props.spousePortion}
                        spouseGoal={this.props.spouseGoal}
                        isSpouse={!this.props.userTabActive}
                        canSave={this.props.canSave}
                        isPercentSpouse={this.props.isPercentSpouse}
                        getAge={this.props.getAge}
                        tooltipTranslations={this.props.tooltipTranslations}
                        labelTranslations={this.props.labelTranslations}
                        dobError={this.props.spouseDobError}
                        deleteSpouse={this.props.deleteSpouse}
                        handleDeleteSpouse={this.props.handleDeleteSpouse}
                        eventBus={this.props.eventBus}
                        mtrGoalModalEvents={this.props.mtrGoalModalEvents}
                        errorId={this.props.errorId}
                        setErrorHighlights={this.setErrorHighlights}
                        defaultIncomeGoal={this.props.defaultIncomeGoal}
                        handleDobBlur={this.handleDobBlur}
                    />
                ) : null}
            </div>
        );
        const showUser =
            this.props.userTabActive && this.props.tooltipTranslations ? (
                <UserBody
                    handleChangeView={this.props.handleChangeView}
                    handleBlurPortion={this.props.handleBlurPortion}
                    handleBlurSalary={this.props.handleBlurSalary}
                    handleAdditionalComp={this.props.handleAdditionalComp}
                    handleError={this.props.handleError}
                    handleDOBValidation={this.handleDOBValidation}
                    portionValidator={this.portionValidator}
                    hasOtherVariableComp={this.props.hasOtherVariableComp}
                    additionalComp={this.props.additionalComp}
                    portion={this.props.portion}
                    salary={this.props.salary}
                    period={this.props.period}
                    userGoal={this.props.userGoal}
                    dob={this.props.dob}
                    canSave={this.props.canSave}
                    isPercent={this.props.isPercent}
                    dobDisabled={this.props.dobDisabled}
                    tooltipTranslations={this.props.tooltipTranslations}
                    labelTranslations={this.props.labelTranslations}
                    errorId={this.props.errorId}
                    dobError={this.props.userDobError}
                    mtrGoalModalEvents={this.props.mtrGoalModalEvents}
                    eventBus={this.props.eventBus}
                    mustEnterSalary={this.props.mustEnterSalary}
                    setErrorHighlights={this.setErrorHighlights}
                    isSpouse={!this.props.userTabActive}
                    defaultIncomeGoal={this.props.defaultIncomeGoal}
                    handleDobBlur={this.handleDobBlur}
                />
            ) : null;

        return (
            <div
                className={`progress-modal modal-content ${modalClass}`}
                role="dialog"
                id="goal-modal"
            >
                {this.props.isLoading && <Loader />}
                <Header
                    defaultIncomeGoal={this.props.defaultIncomeGoal}
                    portion={portion}
                    period={this.props.period}
                    totalGoal={totalGoal}
                    totalSalaryText={totalSalaryText}
                    isPercent={this.props.isPercent}
                    mustEnterSalary={this.props.prompt}
                    modalTranslations={this.props.labelTranslations}
                    isSpouse={!this.props.userTabActive}
                    isApple={this.props.isApple}
                    salaryReminder={this.props.salaryReminder}
                    promptForSalary={this.props.promptForSalary}
                    isJPM={this.props.isJPM}
                />
                <div id={this.props.userTabActive ? "" : "spouse-body"} className="modal-body">
                    <form
                        onSubmit={this.props.handleSubmit}
                        name="updateGoal"
                        className="form-horizontal"
                    >
                        {userBodyMax}
                        {this.props.showModal ? showUser : null}
                        <div className={`form-bottom-container ${reducedSalaryClass}`}>
                            <div className="progress-modal-projection-settings">
                                <div className="progress-modal-projection-setting-group">
                                    <label className="control-label inline-label with-right-margin">
                                        View:{" "}
                                    </label>
                                    <div className="input-group">
                                        <label id="lblMonthlyOption" className="radio-inline">
                                            <input
                                                type="radio"
                                                name="active_salary_view"
                                                id="monthlyOption"
                                                value="monthly"
                                                className="period-radio"
                                                checked={
                                                    this.props.period === Constants.DATE.MONTHLY
                                                }
                                                onChange={this.periodChangeHandler}
                                                aria-labelledby="lblMonthlyOption"
                                            />
                                            {this.props.labelTranslations.monthly}
                                        </label>
                                        <label id="lblYearlyOption" className="radio-inline">
                                            <input
                                                type="radio"
                                                name="active_salary_view"
                                                id="yearlyOption"
                                                value="yearly"
                                                className="period-radio"
                                                checked={
                                                    this.props.period === Constants.DATE.YEARLY
                                                }
                                                onChange={this.periodChangeHandler}
                                                aria-labelledby="lblYearlyOption"
                                            />
                                            {this.props.labelTranslations.yearly}
                                        </label>
                                    </div>
                                </div>
                                <div className="progress-modal-projection-setting-group">
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Popover id={`confidence-level-explaination`}>
                                                <div>
                                                    {
                                                        this.props.tooltipTranslations
                                                            .levelOfConfidence.tooltip
                                                    }
                                                </div>
                                            </Popover>
                                        }
                                    >
                                        <label className="control-label inline-label with-right-margin">
                                            {this.props.labelTranslations["rivd-confidenceLevel"] +
                                                ":"}
                                        </label>
                                    </OverlayTrigger>
                                    <div className="input-group">
                                        <label id="lblGoal_probability_1" className="radio-inline">
                                            <input
                                                type="radio"
                                                name="goal_probability"
                                                id="goal_probability_1"
                                                value={80}
                                                className="period-radio"
                                                checked={this.props.confidenceLevel === 80}
                                                onChange={this.confidenceLevelChangeHandler}
                                                aria-labelledby="lblGoal_probability_1"
                                            />
                                            {
                                                this.props.labelTranslations[
                                                    "rivd-confidenceLevelOption1"
                                                ]
                                            }
                                        </label>
                                        <label id="lblGoal_probability_2" className="radio-inline">
                                            <input
                                                type="radio"
                                                name="goal_probability"
                                                id="goal_probability_2"
                                                value={90}
                                                className="period-radio"
                                                checked={this.props.confidenceLevel === 90}
                                                onChange={this.confidenceLevelChangeHandler}
                                                aria-labelledby="lblGoal_probability_2"
                                            />
                                            {
                                                this.props.labelTranslations[
                                                    "rivd-confidenceLevelOption2"
                                                ]
                                            }
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <Footer
                                handleClose={this.props.handleClose}
                                canSave={this.props.canSave}
                                mustEnterSalary={this.props.mustEnterSalary}
                                prompt={this.props.prompt}
                                salary={this.props.salary}
                                maxSalary={this.props.maxSalary}
                                spouseSalary={this.props.spouseSalary}
                                hasReducedSalary={this.props.getHasReducedSalary}
                                reducedSalaryMessage={
                                    this.props.labelTranslations.reducedSalaryMessage
                                }
                            />
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

GoalModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    handleChangeView: PropTypes.func.isRequired,
    handleChangeConfidenceLevel: PropTypes.func.isRequired,
    handleBlurPortion: PropTypes.func.isRequired,
    handleBlurSalary: PropTypes.func.isRequired,
    handleNameChange: PropTypes.func.isRequired,
    handleSpouseSalary: PropTypes.func.isRequired,
    handleGenderChange: PropTypes.func.isRequired,
    handleParticipantDobChange: PropTypes.func.isRequired,
    handleSpouseDobChange: PropTypes.func.isRequired,
    handleAgeChange: PropTypes.func.isRequired,
    handleAdditionalComp: PropTypes.func.isRequired,
    handleSpousePortion: PropTypes.func.isRequired,
    hasOtherVariableComp: PropTypes.bool.isRequired,
    additionalComp: PropTypes.number,
    portion: PropTypes.number,
    userGoal: PropTypes.number.isRequired,
    salary: PropTypes.number.isRequired,
    dob: PropTypes.string.isRequired,
    period: PropTypes.string.isRequired,
    confidenceLevel: PropTypes.number.isRequired,
    spouseSaved: PropTypes.bool.isRequired,
    userName: PropTypes.string.isRequired,
    spouseName: PropTypes.string,
    spouseGender: PropTypes.string,
    spouseSalary: PropTypes.number,
    spouseDob: PropTypes.string,
    spouseAge: PropTypes.number.isRequired,
    desiredRetAge: PropTypes.number,
    spousePortion: PropTypes.number,
    spouseGoal: PropTypes.number,
    handleAddSpouse: PropTypes.func.isRequired,
    canSave: PropTypes.bool.isRequired,
    isPercent: PropTypes.bool.isRequired,
    isPercentSpouse: PropTypes.bool,
    percentageOfGoal: PropTypes.number.isRequired,
    dobDisabled: PropTypes.bool.isRequired,
    getAge: PropTypes.func.isRequired,
    isEmulator: PropTypes.bool.isRequired,
    tooltipTranslations: PropTypes.object,
    labelTranslations: PropTypes.object,
    toggleProgressModal: PropTypes.func.isRequired,
    handleTabClick: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    userTabActive: PropTypes.bool.isRequired,
    errorId: PropTypes.array.isRequired,
    spouseDobError: PropTypes.string,
    userDobError: PropTypes.string.isRequired,
    updateErrorMessage: PropTypes.func.isRequired,
    mustEnterSalary: PropTypes.bool,
    prompt: PropTypes.bool,
    hasReducedSalaryOff: PropTypes.func,
    getHasReducedSalary: PropTypes.bool,
    isApple: PropTypes.func,
    defaultIncomeGoal: PropTypes.number,
    salaryReminder: PropTypes.bool,
    promptForSalary: PropTypes.bool,
    isLoading: PropTypes.bool,
    isJPM: PropTypes.func,
    maxSalary: PropTypes.number,
    spouseDOB: PropTypes.string,
    eventBus: PropTypes.object,
    deleteSpouse: PropTypes.bool,
    handleDeleteSpouse: PropTypes.func,
    mtrGoalModalEvents: PropTypes.object
};
