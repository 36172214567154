import {
    formatTimingPrefix,
    DiagnosticsConstants,
    determineLoginFlow,
    LoginFlowEvents
} from "./common";
import { getLiatAppStartTime, getLiatAppFullStartTime } from "./getEvents";
import { sendMetricEventMFA, sendMetricEvent } from "./sendEvents";

const measurementName = "Paycheck";
const timingPrefix = formatTimingPrefix();

const logMetricEvent = (
    name: string,
    renderPaycheck: number,
    endTime: number,
    startHash: string,
    loginLastHash: string | null | undefined,
    timeToLoad: number,
    isMFA: boolean = false
) => {
    const sendEvent = isMFA ? sendMetricEventMFA : sendMetricEvent;

    sendEvent({
        measurementName: "LiatApp",
        startHash,
        loginLastHash: loginLastHash as string | undefined,
        endTime,
        startTime: renderPaycheck,
        name,
        timeToLoad
    });
};

const logMetricNoStartHashEvent = (
    name: string,
    renderPaycheck: number,
    renderPaycheckDelta: number,
    endTime: number,
    loginLastHash: string | null | undefined
) => {
    sendMetricEvent({
        measurementName: "LiatApp",
        timeToLoad: renderPaycheckDelta,
        loginLastHash: loginLastHash as string | undefined,
        endTime,
        startTime: renderPaycheck,
        name
    });
};

const logCommonMetricEvent = (
    name: string,
    renderPaycheckDelta: number,
    renderPaycheck: number,
    liatAppStartTime: number,
    loginLastHash: string | null | undefined
) => {
    sendMetricEvent({
        name,
        timeToLoad: renderPaycheckDelta,
        measurementName,
        startTime: renderPaycheck,
        endTime: liatAppStartTime,
        loginLastHash: loginLastHash as string | undefined
    });
};

const sentryPaycheckRenderMetrics = () => {
    const renderPaycheck = new Date().getTime();

    const renderPaycheckStartTimeExists = sessionStorage.getItem(
        `${timingPrefix}${DiagnosticsConstants.RENDER_PAYCHECK}`
    );

    /**
     * With this logic we will check to see if we have set the paycheck metrics in the current session.
     * If we have not then proceed with getting and setting the data.
     */
    if (!renderPaycheckStartTimeExists) {
        const liatAppStartTime = getLiatAppStartTime();
        const liatAppFullStartTime = getLiatAppFullStartTime();

        const renderPaycheckDelta = renderPaycheck - Number(liatAppStartTime);

        sessionStorage.setItem(
            timingPrefix + DiagnosticsConstants.RENDER_PAYCHECK,
            String(renderPaycheck)
        );
        sessionStorage.setItem(
            timingPrefix + DiagnosticsConstants.RENDER_PAYCHECK + "Delta",
            String(renderPaycheckDelta)
        );

        const { loginLastHash, flowName, startHash, endTime } = determineLoginFlow();
        // If true, we are in the MFA Sign in login flow
        if (flowName === LoginFlowEvents.MFA_LOGIN) {
            // Measure from when the user clicked mfa sign in to when paycheck renders
            const timeToLiatFromVerify = renderPaycheck - endTime;
            logMetricEvent(
                DiagnosticsConstants.MFA.TIME_TO_LIAT,
                renderPaycheck,
                endTime,
                startHash,
                loginLastHash,
                timeToLiatFromVerify,
                true
            );
            logMetricEvent(
                DiagnosticsConstants.MFA.TIME_TO_LIAT_FULL,
                renderPaycheck,
                endTime,
                startHash,
                loginLastHash,
                timeToLiatFromVerify,
                true
            );
            // If true, we are in the DF login flow
        } else if (flowName === LoginFlowEvents.DF_LOGIN) {
            // Measure from when the user clicked login to when they can see the LIS score
            const timeToLiatFromClick = renderPaycheck - endTime;
            logMetricEvent(
                DiagnosticsConstants.DF.TIME_TO_LIAT,
                renderPaycheck,
                endTime,
                startHash,
                loginLastHash,
                timeToLiatFromClick
            );
            logMetricEvent(
                DiagnosticsConstants.DF.TIME_TO_LIAT_FULL,
                renderPaycheck,
                endTime,
                startHash,
                loginLastHash,
                timeToLiatFromClick
            );
            // If true, we are in the SSO login flow
        } else if (flowName === LoginFlowEvents.SSO_LOGIN) {
            const timeToStartLiatFromSSO = renderPaycheck - endTime;
            logMetricEvent(
                DiagnosticsConstants.SSO.TIME_TO_LIAT,
                renderPaycheck,
                endTime,
                startHash,
                loginLastHash,
                timeToStartLiatFromSSO
            );
            logMetricEvent(
                DiagnosticsConstants.SSO.TIME_TO_LIAT_FULL,
                renderPaycheck,
                endTime,
                startHash,
                loginLastHash,
                timeToStartLiatFromSSO
            );
        } // Collect the registration flow data points
        else if (loginLastHash && DiagnosticsConstants[loginLastHash]) {
            const baseName = `${DiagnosticsConstants.TIME_TO_LIAT}`;
            logMetricNoStartHashEvent(
                `${baseName}_${DiagnosticsConstants[loginLastHash]}`,
                renderPaycheck,
                Number(liatAppStartTime),
                renderPaycheckDelta,
                loginLastHash
            );
            logMetricNoStartHashEvent(
                `${baseName}_FULL_${DiagnosticsConstants[loginLastHash]}`,
                renderPaycheck,
                Number(liatAppFullStartTime),
                renderPaycheckDelta,
                loginLastHash
            );
        }

        // We will keep this as a common metric independent of any login flow
        logCommonMetricEvent(
            DiagnosticsConstants.TIME_TO_LIAT,
            renderPaycheckDelta,
            renderPaycheck,
            Number(liatAppStartTime),
            loginLastHash
        );
        logCommonMetricEvent(
            DiagnosticsConstants.TIME_TO_LIAT_FULL,
            renderPaycheckDelta,
            renderPaycheck,
            Number(liatAppFullStartTime),
            loginLastHash
        );
    }
};

export default sentryPaycheckRenderMetrics;
