"use strict";
/**
 * @name app.common.model.domain.ProfitSharing
 * @date 1/26/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Definition of the ProfitSharing domain model object.
 */

const ProfitSharing = function (config) {
    this.matchPercent = 0;
    this.maxMatchDollar = 0;
    this.maxMatchPercent = 0;
    this.description = "Mock Profit Sharing Rule";
    this.tierCd = "";
    this.profitSharing = "";
    this.lowThresholdPercent = 0;
    this.highThresholdPercent = 0;
    this.lowThresholdDollar = 0;
    this.highThresholdDollar = 0;
    this.minYrOfService = 0;
    this.maxYrOfService = 0;
    this.ruleType = "";

    if (config) {
        this.matchPercent = config.matchPercent;
        this.maxMatchDollar = config.maxMatchDollar;
        this.maxMatchPercent = config.maxMatchPercent;
        this.description = config.ruleDesc;
        this.tierCd = config.tierCd;
        this.profitSharing = config.profitSharing;
        this.lowThresholdPercent = config.lowThresholdPercent;
        this.highThresholdPercent = config.highThresholdPercent;
        this.lowThresholdDollar = config.lowThresholdDollar;
        this.highThresholdDollar = config.highThresholdDollar;
        this.minYrOfService = config.minYrOfService;
        this.maxYrOfService = config.maxYrOfService;
        this.ruleType = config.ruleType;
    }
};

export default ProfitSharing;
