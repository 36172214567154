/**
 *
 * @name SdsvSubcodes
 * @memberOf common.model.constants
 * @description Stores Standard Service codes
 * @property {string} CASH_BALANCE "CASH_BAL"
 *
 */
"use strict";

const TrsSdsvCodes = {
    AGGREGATION: "AGGREGATION",
    ANNUITY: {
        subCode: "ANNUITY",
        codes: []
    },
    CASH_BALANCE: "CASH_BAL",
    HSA: ["TRS_HEALTH_SAV_ACCT"],
    HSA_OPTUM: ["TRS_EMP_HSA_OPTUM"],
    DEFINED_CONTRIBUTION: ["TRS_DEF_CONT_QUAL", "TRS_DEF_CONT_NONQUAL", "TRS_RET_HEALTH_WELF"],
    DEFINED_BENEFIT: [
        "TRS_DEF_BFT_QUAL",
        "TRS_DEF_BFT_NONQUAL",
        "TRS_RESTRICTED_STOCK",
        "TRS_STOCK_OPTION"
    ],
    STOCK_PURCHASE: ["TRS_STOCK_PURCHASE"],
    YODLEE: "YODLEE"
};

export default TrsSdsvCodes;
