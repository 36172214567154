// For integrated user

"use strict";

class ExternalAssetAllocations {
    constructor(config) {
        this.usStocks = 0;
        this.intlStocks = 0;
        this.usBonds = 0;
        this.intlBonds = 0;
        this.alternatives = 0;
        this.cash = 0;
        this.unclassified = 0;

        if (config) {
            this.usStocks = config.usStocks;
            this.intlStocks = config.intlStocks;
            this.usBonds = config.usBonds;
            this.intlBonds = config.intlBonds;
            this.alternatives = config.alternatives;
            this.cash = config.cash;
            this.unclassified = config.unclassified;
        }
    }
}

export default ExternalAssetAllocations;
