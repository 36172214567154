"use strict";
/**
 * @name app.infrastructure.domainModel.createSsInfo
 * @date 1/11/2018
 * @copyright 2018 Empower Retirement
 * @description
 * Definition of the ssInfo domain model object - only for migrated users.
 */

const SsInfo = function (config) {
    this.socialSecurityPerson = null;
    this.ssBenefitsMap = {};

    if (config) {
        this.socialSecurityPerson = config.socialSecurityPerson;
        this.ssBenefitsMap = config.ssBenefitsMap;
    }
};

export default SsInfo;
