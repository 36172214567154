/**
 *
 * @name HSAConstants
 * @memberOf common.model.constants
 * @date 5/01/2018
 * @copyright 2018 Empower Retirement
 * @description Constants for HSA
 *
 */

"use strict";

const HSAConstants = {
    IRS_CODE: "HSA",
    COVERAGE_CODES: {
        SINGLE: "S",
        SINGLE_CATCHUP: "SO",
        FAMILY: "F",
        FAMILY_CATCHUP: "FO"
    },
    SPEND_CODES: {
        PRIOR: "P",
        NATIONAL_AVG: "N",
        CUSTOM: "C"
    },
    NATIONAL_AVERAGES: {
        S: "single",
        SO: "singleCatchUp",
        F: "family",
        FO: "familyCatchUp"
    },
    IRS_LIMITS: {
        S: "hsaSingle",
        SO: "hsaSingleCatchUp",
        F: "hsaFamily",
        FO: "hsaFamilyCatchUp"
    }
};

export default HSAConstants;
