import StringUtil from "core-ui/client/src/app/StringUtil";

import ReferenceData from "../constants/ReferenceData";

const checkDeminWithdrawalLanding = () => {
    const getUrlLocation = window.location.href.indexOf("open-account") > -1;
    const deminAccountForWithdrawalLanding =
        globalThis.integratedEligibility.deminAccountForWithdrawalLanding;
    const individualId = globalThis.integratedEligibility.groupDetails.IndividualId;

    if (deminAccountForWithdrawalLanding && !getUrlLocation) {
        const deminGroupId = deminAccountForWithdrawalLanding;
        const hashRoot = window.location.hash.split("/")[1];

        if (
            hashRoot === ReferenceData.URL_INTEGRATED_HOME_ROOT ||
            hashRoot === ReferenceData.URL_HOME_ROOT
        ) {
            window.location.href = StringUtil.supplant(ReferenceData.URL_DEMIN, {
                deminGroupId,
                individualId
            });
        }
    }
};

export default checkDeminWithdrawalLanding;
