/**
 * @name app.common.model.domain.InvestmentHolding
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Creates a defined object structure for the Fund object type.
 */

/**
 * The InvestmentHolding object constructor.
 * @param config This object of properties you would like to base the plan off of. Note that only primitives are used.
 * @constructor
 */

"use strict";

const InvestmentHolding = function (config) {
    this.cusip = "";
    this.fundName = "";
    this.balance = 0;
    this.percent = 0;
    this.effectiveDate = "";
    this.effectiveDateString = "";

    if (config) {
        this.cusip = config.cusip;
        this.fundName = config.fundName;
        this.balance = config.balance;
        this.percent = config.percent;
        this.effectiveDate = config.effectiveDate;
        this.effectiveDateString = config.effectiveDateString;
    }
};

export default InvestmentHolding;
