/**
 * @name app.common.model.domain.TierDeferralRule
 * @description
 * Definition of the tier rule domain model object.
 */

import _each from "lodash/each";

import DeferralRuleMessage from "./DeferralRuleMessage";

const TierRule = function (config) {
    this.reqPct = null;
    this.conPct = null;
    this.reqAmt = null;
    this.conAmt = null;
    this.requiredDeferralTypeCodes = [];
    this.contributingDeferralTypeCodes = [];
    this.messages = [];

    if (config) {
        this.reqPct = config.reqPct;
        this.conPct = config.conPct;
        this.reqAmt = config.reqAmt;
        this.conAmt = config.conAmt;
        this.requiredDeferralTypeCodes = config.requiredDefrlTypeCode;
        this.contributingDeferralTypeCodes = config.contribDefrlTypeCode;
        const messages = [];
        if (config.messages) {
            _each(config.messages, function (message) {
                messages.push(new DeferralRuleMessage(message));
            });
        }
        this.messages = messages;
    }
};

export default TierRule;
