import { isNil as _isNil } from "lodash";
import { createSelector } from "reselect";

import Constants from "../constants/Constants";
import { isEmulator } from "../utils/paeUtils";
import { getMinRetirementAge, getMaxRetirementAge } from "../utils/socialSecurity";

import { selectExpenseList } from "./healthCareSelectors";

// PER THE RESELECT DOCs   https://github.com/reduxjs/reselect
// Reselect provides a function createSelector for creating memoized selectors. createSelector takes an array of input-selectors and a transform function as its arguments.
// If the Redux state tree is mutated in a way that causes the value of an input-selector to change,
// the selector will call its transform function with the values of the input-selectors as arguments and return the result.
// If the values of the input-selectors are the same as the previous call to the selector, it will return the previously computed value instead of calling the transform function.

// PER THE REDUX DOCS   https://react-redux.js.org/next/api/hooks#useselector-examples
// When using useSelector with an inline selector as shown above, a new instance of the selector is created whenever the component is rendered.
// This works as long as the selector does not maintain any state. However, memoizing selectors (e.g. created via createSelector from reselect) do have internal state, and therefore care must be taken when using them.
// When the selector does only depend on the state, simply ensure that it is declared outside of the component so that the same selector instance is used for each render:

/************* HEALTH CARE SELECTORS ************/

// takes in state and chartConfigsName
export const healthcareInsuranceLists = createSelector(
    (state) => state.healthCare,
    (state) => state.applicationSettings.projectionSettings.term,
    (_, config) => config,
    (healthCare, term, config) => selectExpenseList(healthCare, config, term)
);

// export const reselectHealthCare = createSelector(
//     (state) => state.healthCare.attainedAge,
//     (state) => state.healthCare.healthCareDataLoaded,
//     (state) => state.healthCare.showLoader,
//     (state) => state.healthCare.pendingChanges,
//     (attainedAge, healthCareDataLoaded, showLoader, pendingChanges) => {
//         return { attainedAge, healthCareDataLoaded, showLoader, pendingChanges };
//     }
// );

// export const reselectHealthViewData = createSelector(
//     (state) => state.healthCare.healthViewData.chartLegends,
//     (state) => state.healthCare.healthViewData.healthSurvey,
//     (state) => state.healthCare.healthViewData.projections,
//     (state) => state.healthCare.healthViewData.warnThreshold,
//     (state) => state.healthCare.healthViewData.yieldThreshold,
//     (chartLegends, healthSurvey, healthViewProjections, warnThreshold, yieldThreshold) => {
//         return { chartLegends, healthSurvey, healthViewProjections, warnThreshold, yieldThreshold };
//     }
// );

/************* SOCIAL SECURITY SELECTORS **********/
// replacing selectShowSocialSecurity fn
export const reselectShowSocialSecurity = createSelector(
    (state) => state.primaryPlan.planRules,
    (state) => state.primaryPlan.retirementOption,
    (state) => state.applicationSettings.projectionSettings.includeSocialSecurity,
    (planRules, retirementOption, includeSocialSecurity) => {
        return includeSocialSecurity && planRules.ssEnabled && retirementOption.inclSocialSec;
    }
);

/************* PARTICIPANT SELECTORS ************/
export const reselectParticipantHasSpouse = createSelector(
    (state) => state.spouse.dataIsLoaded,
    (state) => state.spouse.exists,
    (dataIsLoaded, exists) => dataIsLoaded && exists
);

export const reselectParticipantPortionPct = createSelector(
    (state) => state.participant.incomeGoal,
    (state) => state.participant.salary,
    (incomeGoal, salary) => {
        if (incomeGoal.valueUnits != Constants.PCT) {
            const salaryTotal = Number(salary) ? salary : salary.total;
            return (incomeGoal.value / salaryTotal) * 100;
        }

        return incomeGoal.value;
    }
);

export const reselectReviewLastChanges = createSelector(
    (state) => state.reviewChanges.lastChanges,
    (state) => state.reviewChanges.lastChanges.retirementAge,
    (state) => state.reviewChanges.lastChanges.equityMix,
    (state) => state.reviewChanges.lastChanges.deferrals,
    (lastChanges, priorRetirementAge, priorEquityMix, deferrals) => {
        const priorDeferrals = lastChanges ? deferrals : [];
        return { priorRetirementAge, priorEquityMix, priorDeferrals };
    }
);

export const reselectTermPeriod = createSelector(
    (state) => state.applicationSettings.projectionSettings.term,
    (term) => (term === 12 ? Constants.DATE.MONTHLY : Constants.DATE.YEARLY)
);

export const reselectParticipantAges = createSelector(
    (state) => state.participant.age,
    (state) => state.participant.retirementAge,
    (age, retirementAge) => {
        const minRetirementAge = getMinRetirementAge(age);
        const maxRetirementAge = getMaxRetirementAge(age);
        return { age, retirementAge, minRetirementAge, maxRetirementAge };
    }
);

/*************   INVESTMENTS  ************/
export const reselectCanShowInvestmentsSlider = createSelector(
    (state) => state.investments.managedAccountsOptions,
    (state) => state.participant,
    (managedAccounts) =>
        _isNil(managedAccounts) || !managedAccounts.enrolledInFullManagedAccounts || isEmulator()
);

/************* TRADEOFF MESSAGING  **********/
export const reselectTradeOffMessages = createSelector(
    (state) => state.nextSteps.tradeOffMessages.future,
    (state) => state.nextSteps.tradeOffMessages.today,
    (state) => state.nextSteps.tradeOffMessages.reachedLimits,
    (future, today, reachedLimits) => {
        return { future, today, reachedLimits };
    }
);

/************* DEFERRALS **********/
export const reselectAddDeferralButton = createSelector(
    (primaryPlan) => primaryPlan.terminated,
    (primaryPlan) => primaryPlan.planRules.allowMultipleDeferrals,
    (primaryPlan) => primaryPlan.planRules.allowAddNewContributions,
    (primaryPlan) => primaryPlan.planRules.specialCatchup,
    (_, availableDeferralsIsEmpty) => availableDeferralsIsEmpty,
    (
        terminated,
        allowMultipleDeferrals,
        allowAddNewContributions,
        specialCatchup,
        availableDeferralsIsEmpty
    ) => {
        if (terminated) {
            return {
                displayMulti: allowMultipleDeferrals,
                canShowAddNewContributions: false
            };
        }

        const getCanShowAddNewContributions = () => {
            if (allowAddNewContributions && allowMultipleDeferrals && !specialCatchup) {
                return !availableDeferralsIsEmpty;
            }
            return false;
        };

        const canShowAddNewContributions = getCanShowAddNewContributions();

        return {
            displayMulti: allowMultipleDeferrals,
            canShowAddNewContributions
        };
    }
);
