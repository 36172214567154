import React from "react";

import PropTypes from "prop-types";

const MaximizerTradeOffMessage = ({ list }) => {
    return list.map((listItem, id) => {
        return (
            // eslint-disable-next-line react/no-array-index-key
            <span key={`${listItem.deferralTypeCode}-${id}`}>
                {listItem.config.displayName}
                {id !== list.length - 1 ? <span>,</span> : <span>.</span>}
            </span>
        );
    });
};

const DeferralSpecialtyChanged = ({ hasDeferralChanges, translations, changedDeferral }) => {
    return hasDeferralChanges ? (
        <div className="maximizer-tradeoff-message" data-testid="deferral-specialty-changed">
            <span data-testid="deferral-translation">{translations}</span>
            <MaximizerTradeOffMessage list={changedDeferral} />
        </div>
    ) : null;
};

MaximizerTradeOffMessage.propTypes = {
    list: PropTypes.array.isRequired
};

DeferralSpecialtyChanged.propTypes = {
    translations: PropTypes.string.isRequired,
    hasDeferralChanges: PropTypes.bool.isRequired,
    changedDeferral: PropTypes.array.isRequired
};

export { DeferralSpecialtyChanged };
