import React, { memo, useEffect, useState } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";

import { getDefaultAgeValue } from "../helpers/getDefaultAgeValue";
import { MonthlyAmountCheckBox, CustomInput } from "../index";

const ErrorMessage = ({ name }) => {
    return (
        <div>
            <p>Error handling the social security table for {name} </p>
            <p>Please contact a representative with any questions.</p>
        </div>
    );
};

const PersonDetails = ({
    user,
    ssBenefitByStartAge,
    handleOpenTable,
    handleChange,
    estimatedAmountCheckboxStatus,
    handleCustomAmount,
    monthlyAmountOverride,
    handleStartAgeChange,
    dropDownValues,
    options,
    atRetirement
}) => {
    const translations = selectTranslations("maSocialSecurity")?.SocialSecurity?.landing || "";
    const { currentValueWithBenefits, amount } = dropDownValues;

    const defaultAge = getDefaultAgeValue({
        atRetirement,
        currentValueWithBenefitsAge: currentValueWithBenefits.age,
        optionsVal: currentValueWithBenefits.optionsVal,
        retirementAge: user.retirementAge,
        startDateOption: translations.details.startDateOption
    });

    const [defaultValue, setDefaultValue] = useState(defaultAge);

    let startAge = translations.details.startAge;
    startAge = String(startAge).replace("YEAR", "Year").replace("year", "Year");

    useEffect(() => {
        setDefaultValue(
            getDefaultAgeValue({
                atRetirement,
                currentValueWithBenefitsAge: currentValueWithBenefits.age,
                optionsVal: currentValueWithBenefits.optionsVal,
                retirementAge: user.retirementAge,
                startDateOption: translations.details.startDateOption
            })
        );
    }, [
        user.retirementAge,
        currentValueWithBenefits.age,
        atRetirement,
        currentValueWithBenefits.optionsVal,
        translations.details.startDateOption
    ]);

    return ssBenefitByStartAge ? (
        <div className="person-details-container d-flex flex-column" data-testid="person-details">
            <h5
                className="person-name"
                data-testid={user?.exists ? "spouse-name" : "participant-name"}
            >
                {user.firstName}
            </h5>
            <p className="input-header">{startAge}</p>
            <div className="custom-select custom-select-container">
                <select
                    name="Social Security Dropdown selection"
                    id="social-security-participant-start-age-slct"
                    className="form-control"
                    onChange={handleStartAgeChange}
                    defaultValue={defaultValue}
                    aria-label="Social Security Dropdown selection"
                >
                    {options.map(({ value, optionsVal }) => {
                        return (
                            <option key={value} value={optionsVal}>
                                {value}
                            </option>
                        );
                    })}
                </select>
            </div>

            <MonthlyAmountCheckBox
                estimatedAmount={amount}
                translations={translations}
                handleOpenTable={handleOpenTable}
                estimatedAmountCheckboxStatus={estimatedAmountCheckboxStatus}
                handleChange={handleChange}
            />
            {!estimatedAmountCheckboxStatus && (
                <CustomInput
                    translations={translations}
                    handleCustomAmount={handleCustomAmount}
                    monthlyAmountOverride={monthlyAmountOverride}
                />
            )}
        </div>
    ) : (
        <ErrorMessage name={user.firstName} />
    );
};
PersonDetails.propTypes = {
    user: PropTypes.object,
    ssBenefitByStartAge: PropTypes.array,
    handleOpenTable: PropTypes.func,
    handleChange: PropTypes.func,
    estimatedAmountCheckboxStatus: PropTypes.bool,
    handleCustomAmount: PropTypes.func,
    monthlyAmountOverride: PropTypes.string,
    handleStartAgeChange: PropTypes.func,
    dropDownValues: PropTypes.object,
    options: PropTypes.array,
    atRetirement: PropTypes.bool
};
ErrorMessage.propTypes = {
    name: PropTypes.string
};
export default memo(PersonDetails);
