import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

const LeavingSiteToGoToMaModal = ({ onClick, onCancel }) => {
    const appTranslations = selectTranslations("app");
    const translations = selectTranslations("myInvestments");

    return (
        <div data-testid="leaving-site-to-go-to-ma-modal">
            <Modal show={true}>
                <Modal.Header>
                    <Modal.Title className="modal-title" data-testid="leavingModal-title">
                        {translations.leavingModal.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body data-testid="leavingModal-text">
                    {translations.leavingModal.text}
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-link" onClick={onCancel}>
                        {appTranslations.common.labels.cancel}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                        onClick={onClick}
                    >
                        {appTranslations.common.labels.continue}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
LeavingSiteToGoToMaModal.propTypes = {
    onClick: PropTypes.func,
    onCancel: PropTypes.func
};
export default LeavingSiteToGoToMaModal;
