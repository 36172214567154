export const ROUTE_BASE = "/";
export const ROUTE_TEST_HARNESS_LOGIN = "/projected-income/login";

export const ROUTE_FAQ = "/faq";
export const ROUTE_MAX_DASHBOARD = "/max-dashboard";
export const ROUTE_ADVICE_DASHBOARD = "/advice-dashboard";

// Cxd Routes
export const ROUTE_DASHBOARD = "/dashboard";
//Income Part Details
export const RETIREMENT_INCOME_ROUTE = ROUTE_DASHBOARD + "/retirement-income";
export const SOCIAL_SECURITY_ROUTE = ROUTE_DASHBOARD + "/social-security";
export const OTHER_ASSETS_ROUTE = ROUTE_DASHBOARD + "/other-assets";
export const INCOME_GAP_ROUTE = ROUTE_DASHBOARD + "/income-gap";
export const HSA_ROUTE = ROUTE_DASHBOARD + "/hsa";
//Paycheck
export const MAXIMIZER_ROUTE = ROUTE_DASHBOARD + "/maximizer";
export const HOW_DO_I_COMPARE_ROUTE = ROUTE_DASHBOARD + "/how-do-i-compare";
export const HEALTHCARE_COSTS_ROUTE = ROUTE_DASHBOARD + "/healthcare-costs";
export const WHEN_CAN_I_RETIRE_ROUTE = ROUTE_DASHBOARD + "/when-can-i-retire";
export const PRE_RETIREMENT_ROUTE = ROUTE_DASHBOARD + "/pre-retirement";

// Sub Page Routes
export const ROUTE_NETWORTH = "/net-worth";
export const ROUTE_TRANSACTIONS = "/all-transactions";
export const ROUTE_CASHFLOW = "/cash-flow";
export const ROUTE_PORTFOLIO = "/portfolio";
export const ROUTE_RETIREMENT_PLANNER = "/retirement-planner";
export const ROUTE_SAVINGS_PLANNER = "/savings-planner";
export const ROUTE_MANAGED_ACCOUNTS = "/managed-accounts";
export const ROUTE_MANAGED_ACCOUNTS_NEW = "/managed-accounts-new";
export const ROUTE_MANAGED_ACCOUNTS_NEW_MARKETING = "/managed-accounts-intro";
export const ROUTE_ONLINE_ADVICE_NEW = "/online-advice";
export const ROUTE_INVESTMENT_CHECKUP = "/investment-checkup";
export const ROUTE_INVESTMENT_LINEUP = "/investment-lineup";
export const ROUTE_ENROLL = "/enroll";
export const ROUTE_SETTINGS = "/settings";
export const ROUTE_CLOSED_ACCOUNTS = "/closed-accounts";
export const ROUTE_ACCOUNTS_ADD = "/accounts/add";
export const ROUTE_MANUAL_CLASSIFICATIONS = "/manual-classifications";
export const ROUTE_EXCLUDE_ACCOUNTS = "/exclude-accounts";
export const ROUTE_STOCK_OPTIONS = "/stock-options";
export const ROUTE_OPEN_ACCOUNT = "/open-account";
export const ROUTE_OPEN_ACCOUNT_PS = "/open-account-ps";
export const ROUTE_OPEN_ACCOUNT_IRA = "/open-account-ira";
export const ROUTE_FAST_LINK = "/fast-link";
export const ROUTE_EDIT_ACCOUNT = "/edit-account";

export const ROUTE_EDUCATION = "/education";
export const ROUTE_APPT = "/appointment";
export const ROUTE_GOALS = "/goals/view";
export const ROUTE_WELLNESS = "/wellness";
export const ROUTE_ACCOUNTS_DETAILS = "/accounts/details";
export const ROUTE_NOTIFICATIONS = "/notifications";

export const ROUTE_TRANSFER_FUNDS = "/transfer-funds";
export const ROUTE_DOCUMENTS = "/documents";
export const ROUTE_MANAGE_BENEFICIARIES = "/manage-beneficiaries";
export const ROUTE_VIEW_MEETING = "/view-meeting";
export const ROUTE_MANAGE_INVESTMENTS = "/manage-investments";
export const ROUTE_ADVISOR = "/advisor";
export const ROUTE_EMERGENCY_FUND_ACCOUNTS = "/emergency-fund-accounts";
export const ROUTE_GMWB = "/glwb-strategy";
export const ROUTE_RETIREMENT_INCOME_STRATEGY = "/retirement-income-strategy";
export const ROUTE_ACCOUNT_ACTIONS = "/account-actions";
export const ROUTE_OPEN_BANK_ACCOUNT = "/open-bank-account";
export const ROUTE_OPEN_BANK_ACCOUNT_V2 = "/open-bank-account-v2";
export const ROUTE_CLOSE_BANK_ACCOUNT = "/close-bank-account";
export const ROUTE_REFERRAL = "/referral-page";
export const ROUTE_TRANSFER_MANAGER = "/transfer-manager";
export const ROUTE_OPEN_ACCOUNT_OPTIONS = "/open-account-options";
export const ROUTE_MARKETING_CONSENT = "/marketing-consent";
export const ROUTE_FP_TOPICS = "/fp-topics";
export const ROUTE_MANAGE_BENEFICIARIES_PS = "/manage-beneficiaries-ps";
export const ROUTE_PLANNING_HISTORY = "/planning-history";
export const ROUTE_EMPLOYER_PLAN_ANALYSIS = "/401k-recommendation";
export const ROUTE_INSURANCE_WIZARD = "/insurance-wizard";
export const ROUTE_SOCIAL_SECURITY_WIZARD = "/social-security-wizard";
export const ROUTE_EQUITY_COMPENSATION_WIZARD = "/equity-compensation-wizard";
export const ROUTE_DEBT_REVIEW_WIZARD = "/debt-review-wizard";
export const ROUTE_EMERGENCY_FUND_WIZARD = "/emergency-fund-wizard";
export const ROUTE_SAVINGS_STRATEGY_WIZARD = "/savings-strategy-wizard";
export const ROUTE_REQUEST_ADVICE_WIZARD = "/request-advice";
export const ROUTE_NEXT_BEST_ACTIONS = "/next-best-actions";
export const ROUTE_ROLLOVER = "/rollover";
export const ROUTE_RETIREMENT_FEE_ANALYZER = "/401k";

// Sub Page Routes (Managed accounts tab)
export const ROUTE_MANAGED_ACCOUNTS_HOME = ROUTE_MANAGED_ACCOUNTS + "/home";
export const ROUTE_MANAGED_ACCOUNTS_INTRO = ROUTE_MANAGED_ACCOUNTS + "/intro";
export const ROUTE_MANAGED_ACCOUNTS_STARTED = ROUTE_MANAGED_ACCOUNTS + "/get-started";
export const ROUTE_MANAGED_ACCOUNTS_ADVICE = ROUTE_MANAGED_ACCOUNTS + "/get-advice";

// Sub Page Routes (Managed accounts tab for V2)
export const ROUTE_MANAGED_ACCOUNTS_HOME_NEW = ROUTE_MANAGED_ACCOUNTS_NEW + "/home";
export const ROUTE_MANAGED_ACCOUNTS_INTRO_NEW = ROUTE_MANAGED_ACCOUNTS_NEW + "/intro";
export const ROUTE_MANAGED_ACCOUNTS_STARTED_NEW = ROUTE_MANAGED_ACCOUNTS_NEW + "/get-started";
export const ROUTE_MANAGED_ACCOUNTS_ADVICE_NEW = ROUTE_MANAGED_ACCOUNTS_NEW + "/get-advice";
export const ROUTE_MANAGED_ACCOUNTS_FIRST_USE_INTERVIEW =
    ROUTE_MANAGED_ACCOUNTS_NEW + "/first-use-interview";

// Sub Page Routes (Online advice enrollment V2)
export const ROUTE_ONLINE_ADVICE_GET_STARTED = ROUTE_ONLINE_ADVICE_NEW + "/get-started";
export const ROUTE_ONLINE_ADVICE_PREVIEW = ROUTE_ONLINE_ADVICE_NEW + "/preview";

// Sub Page Routes (Network accounts tab)
export const ROUTE_NETWORTH_CASH = ROUTE_NETWORTH + "/cash";
export const ROUTE_NETWORTH_INVESTMENT = ROUTE_NETWORTH + "/investment";
export const ROUTE_NETWORTH_CREDIT = ROUTE_NETWORTH + "/credit";
export const ROUTE_NETWORTH_LOAN = ROUTE_NETWORTH + "/loan";
export const ROUTE_NETWORTH_MORTGAGE = ROUTE_NETWORTH + "/mortgage";
export const ROUTE_NETWORTH_OTHER = ROUTE_NETWORTH + "/other";
export const ROUTE_NETWORTH_LIABILITY = ROUTE_NETWORTH + "/other/liability";

// Sub Page Routes (Settings tab)
export const ROUTE_SETTINGS_PROFILE = ROUTE_SETTINGS + "/profile";

// Sub Page Routes (Transactions tab)
export const ROUTE_TRANSACTIONS_ALL = ROUTE_TRANSACTIONS + "/ALL";
export const ROUTE_TRANSACTIONS_BANK = ROUTE_TRANSACTIONS + "/BANK";
export const ROUTE_TRANSACTIONS_INVESTMENT = ROUTE_TRANSACTIONS + "/INVESTMENT";
export const ROUTE_TRANSACTIONS_CREDIT_CARD = ROUTE_TRANSACTIONS + "/CREDIT_CARD";
export const ROUTE_TRANSACTIONS_LOAN = ROUTE_TRANSACTIONS + "/LOAN";
export const ROUTE_TRANSACTIONS_MORTGAGE = ROUTE_TRANSACTIONS + "/MORTGAGE";

// Sub Page Routes (Cash flow tab)
export const ROUTE_CASHFLOW_CASHFLOW = ROUTE_CASHFLOW + "/";
export const ROUTE_CASHFLOW_SPENDING = ROUTE_CASHFLOW + "/spending";
export const ROUTE_CASHFLOW_INCOME = ROUTE_CASHFLOW + "/income";
export const ROUTE_CASHFLOW_EXPENSE = ROUTE_CASHFLOW + "/expense";

// Sub Page Routes (Porfolio tab)
export const ROUTE_PORTFOLIO_HOLDINGS = ROUTE_PORTFOLIO + "/holdings";
export const ROUTE_PORTFOLIO_BALANCES = ROUTE_PORTFOLIO + "/balances";
export const ROUTE_PORTFOLIO_PERFORMANCE = ROUTE_PORTFOLIO + "/performance";
export const ROUTE_PORTFOLIO_ALLOCATION = ROUTE_PORTFOLIO + "/allocation";
export const ROUTE_PORTFOLIO_SECTORS = ROUTE_PORTFOLIO + "/sectors";

export const ROUTE_RECURRING_TRANSFERS = "/recurring-transfers";

// Sub Page Routes (Retirement planner tab)
export const ROUTE_RETIREMENT_PLANNER_INDEX = ROUTE_RETIREMENT_PLANNER + "/index";
export const ROUTE_RETIREMENT_PLANNER_PAYCHECK = ROUTE_RETIREMENT_PLANNER + "/paycheck";
export const ROUTE_RETIREMENT_PLANNER_INCOME_EVENT = ROUTE_RETIREMENT_PLANNER + "/income-event";
export const ROUTE_RETIREMENT_PLANNER_SPENDING_GOAL = ROUTE_RETIREMENT_PLANNER + "/spending-goal";
export const ROUTE_RETIREMENT_PLANNER_SS_INCOME =
    ROUTE_RETIREMENT_PLANNER + "/social-security-income";

// Sub Page Routes (Savings tab)
export const ROUTE_SAVINGS_PLANNER_EMERGENCY_FUND = ROUTE_SAVINGS_PLANNER + "/emergency-fund";
export const ROUTE_SAVINGS_PLANNER_DEBT_PAYDOWN = ROUTE_SAVINGS_PLANNER + "/debt-paydown";

export const ROUTE_LEAVE_ROUTE = "/leave-route";

// Sub Page Routes (Personal Strategy and Strategy Review)
export const ROUTE_PERSONAL_STRATEGY = "/strategy";
export const ROUTE_PERSONAL_STRATEGY_TRADES = ROUTE_PERSONAL_STRATEGY + "/trades";
export const ROUTE_PERSONAL_STRATEGY_DIVIDENDS = ROUTE_PERSONAL_STRATEGY + "/dividends";
export const ROUTE_PERSONAL_STRATEGY_RECOMMENDATION = ROUTE_PERSONAL_STRATEGY + "/recommendation";
export const ROUTE_STRATEGY_REVIEW_WIZARD = "/strategy-review-wizard";

// Sub page Routes (Personal Strategy Plus)
export const ROUTE_PERSONAL_STRATEGY_PLUS = "/enroll-retirement-account";
export const ROUTE_PERSONAL_STRATEGY_PLUS_INTRO = ROUTE_PERSONAL_STRATEGY_PLUS + "/intro";
export const ROUTE_PERSONAL_STRATEGY_PLUS_OWNER_INFO =
    ROUTE_PERSONAL_STRATEGY_PLUS + "/owner-information";
export const ROUTE_PERSONAL_STRATEGY_PLUS_LINK_PAYMENT =
    ROUTE_PERSONAL_STRATEGY_PLUS + "/link-payment";
export const ROUTE_PERSONAL_STRATEGY_PLUS_ABOUT = ROUTE_PERSONAL_STRATEGY_PLUS + "/about";

// Sub page Routes (Personal Strategy Plus - FeeX Errors)
export const ROUTE_RECONNECT_RETIREMENT_ACCOUNT = "/reconnect-retirement-account";
export const ROUTE_UPDATE_FORWARDING_CONTACT_INFORMATION = "/update-forwarding-contact-information";
