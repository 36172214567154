import React from "react";

import currencyFormatter from "currency-formatter";

import { useSelector } from "../../hooks/use-redux-actions";

interface CurrencyFormatterProps {
    amount: number | undefined;
    awaitHubCalc?: boolean;
    precision?: number;
    useSuperscriptSymbol?: boolean;
}

const CurrencyFormatter = ({
    amount = 0,
    precision = 2,
    useSuperscriptSymbol = false,
    awaitHubCalc = true
}: CurrencyFormatterProps) => {
    const hubCalculated = useSelector((state) => state.projectedIncome).hubCalculationDone;

    return (
        <span className="amountValue" data-testid="current-currency">
            {useSuperscriptSymbol ? <sup>$</sup> : "$"}
            {hubCalculated || !awaitHubCalc
                ? currencyFormatter.format(amount, { precision: precision })
                : " ---"}
        </span>
    );
};

export default CurrencyFormatter;
