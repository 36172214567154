import {
    SALARY_CHANGE,
    PORTION_CHANGE,
    CHANGE_VIEW,
    CHANGE_SPOUSE_NAME,
    SPOUSE_SALARY_CHANGE,
    GENDER_CHANGE,
    SPOUSE_DESIRED_AGE,
    SPOUSE_CHANGE_PORTION,
    ADD_COMP,
    ADD_SPOUSE,
    CANCEL,
    SET_SHOW_MODAL,
    DELETE_SPOUSE,
    NO_PROMPT,
    SAVE_DELETE,
    PARTICIPANT_DOB_CHANGE,
    SPOUSE_DOB_CHANGE,
    SET_GOAL_SAVING,
    TAB_CHANGE,
    HAS_GOAL_FOCUS
} from "../../actions/goal/goalModalActionTypes";

const initialState = {
    showModal: false,
    primaryTabIndex: "user",
    hasGoalFocus: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SALARY_CHANGE:
            return {
                ...state,
                salary: action.salary
            };
        case PORTION_CHANGE:
            return {
                ...state,
                portion: action.portion,
                isPercent: action.isPercent
            };
        case CHANGE_VIEW:
            return {
                ...state,
                period: action.period
            };
        case CHANGE_SPOUSE_NAME:
            return {
                ...state,
                spouseName: action.name
            };
        case SPOUSE_SALARY_CHANGE:
            return {
                ...state,
                spouseSalary: action.salary
            };
        case GENDER_CHANGE:
            return {
                ...state,
                spouseGender: action.gender
            };
        case SPOUSE_DESIRED_AGE:
            return {
                ...state,
                desiredRetAge: action.age
            };
        case SPOUSE_CHANGE_PORTION:
            return {
                ...state,
                spousePortion: action.portion,
                isPercentSpouse: action.isPercent
            };
        case ADD_COMP:
            return {
                ...state,
                additionalComp: action.comp
            };
        case ADD_SPOUSE:
            return {
                ...state,
                spouseSaved: true
            };
        case CANCEL:
            return {
                ...state,
                ...action.props,
                showModal: false
            };
        case SET_SHOW_MODAL:
            return {
                ...state,
                showModal: action.payload
            };
        case DELETE_SPOUSE:
            return {
                ...state,
                deleteSpouse: !state.deleteSpouse
            };
        case NO_PROMPT:
            return {
                ...state,
                prompt: false
            };
        case SAVE_DELETE:
            return {
                ...state,
                deleteSpouse: false,
                spouseSaved: false,
                spouseGoal: 0,
                spouseName: "",
                spouseSalary: 0,
                spouseDOB: "",
                spouseGender: "",
                spouseAge: 0,
                desiredRetAge: 0,
                spousePortion: 0
            };
        case PARTICIPANT_DOB_CHANGE:
            return {
                ...state,
                participantDob: action.dob
            };
        case SPOUSE_DOB_CHANGE:
            return {
                ...state,
                participantDob: action.dob
            };
        case SET_GOAL_SAVING:
            return {
                ...state,
                isSaving: action.value
            };
        case TAB_CHANGE:
            return {
                ...state,
                primaryTabIndex: action.primaryTabIndexUpdated
            };
        case HAS_GOAL_FOCUS:
            return {
                ...state,
                hasGoalFocus: action.payload
            };
        default:
            return state;
    }
};
