import React from "react";

import PropTypes from "prop-types";

const Tooltip = ({ message, id }) => {
    return (
        <div role="tooltip" id={id} className="popoverMessageContainer" data-testid="tooltip">
            <div className="popoverMessage"> {message} </div>
            <div className="popoverMessageArrow arrowDown">
                <div className="insideArrowDown" />
            </div>
        </div>
    );
};

Tooltip.propTypes = {
    message: PropTypes.string.isRequired,
    id: PropTypes.string
};

export default Tooltip;
