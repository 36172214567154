import { ObjectUtil } from "gw-shared-components";
import {
    sortBy as _sortBy,
    uniq as _uniq,
    each as _each,
    isEmpty as _isEmpty,
    uniqBy as _uniqBy
} from "lodash";

import {
    getProfitSharingExistsForPlan,
    getEmployerMatchExistsForPlan
} from "../../business/paycheck";
import HSAConstants from "../../constants/HSAConstants";
import SdsvSubcode from "../../constants/SdsvSubcode";
import TrsSdsvCodes from "../../constants/TrsSdsvCodes";

import {
    SET_SHOW_PAYCHECK_DETAILS,
    UPDATE_PAYCHECK_DETAILS_TOOLTIPS,
    SET_PLANS_WITH_MY_FUTURE_CONTRIBUTION,
    SET_NO_MATCHES_OR_PROFIT_SHARING,
    SET_PLAN_TABLES,
    SET_NQ_INFO,
    HAS_LEFT_ARROW_FOCUS
} from "./paycheckTypes";

export const setShowPaycheckViewDetails = (payload) => {
    return {
        type: SET_SHOW_PAYCHECK_DETAILS,
        payload
    };
};

export const updatePayDetailsToolTips = (payload) => {
    return {
        type: UPDATE_PAYCHECK_DETAILS_TOOLTIPS,
        payload
    };
};

export const setPlansWithMyFutureContribution = (payload) => {
    return {
        type: SET_PLANS_WITH_MY_FUTURE_CONTRIBUTION,
        payload
    };
};

export const setNoMatchesOrProfitSharing = (payload) => {
    return {
        type: SET_NO_MATCHES_OR_PROFIT_SHARING,
        payload
    };
};

export const setNqInfo = (payload) => {
    return {
        type: SET_NQ_INFO,
        payload
    };
};

export const setLeftArrowFocus = () => {
    return {
        type: HAS_LEFT_ARROW_FOCUS,
        payload: true
    };
};

export const unSetLeftArrowFocus = () => {
    return {
        type: HAS_LEFT_ARROW_FOCUS,
        payload: false
    };
};

export const setPlansWithBalances = () => {
    return (dispatch, getState) => {
        const state = getState();
        const participant = state.participant;
        const nqInfo = state.paycheck.nqInfo;

        const employerPastPlans = [];
        const employerFuturePlans = [];
        const employeePastPlans = [];
        const employeeFuturePlans = [];
        const nqPlans = [];

        /*
         * //Get earliest nqEnrollment date (if applicable to ppt)
         * and add all plans
         */

        const checkNqEnrollmentDate = (plan) => {
            //
            const planNqInfo = plan.nqEnrollmentInfo;
            if (
                !ObjectUtil.isUndefinedOrNull(planNqInfo) &&
                planNqInfo.eligible &&
                (ObjectUtil.isUndefinedOrNull(planNqInfo.enrolledDate) ||
                    isNaN(Date.parse(planNqInfo.enrolledDate)))
            ) {
                const payload = {
                    planId: plan.id,
                    id: plan.id,
                    effDate: ObjectUtil.cleanDateString(planNqInfo.effDate),
                    eligible: true,
                    planName: plan.name
                };
                // put the plan into the nqInfo
                nqInfo.push(payload);
                // dispatch to load in nqInfo
                dispatch(setNqInfo(nqInfo));
            }
        };

        /*
         * //Get earliest nqEnrollment date (if applicable to ppt)
         * and add all plans
         */

        const setNQPlans = (plan) => {
            const nqInfo = plan.nqEnrollmentInfo;
            if (
                !ObjectUtil.isUndefinedOrNull(nqInfo) &&
                nqInfo.eligible &&
                (ObjectUtil.isUndefinedOrNull(nqInfo.enrolledDate) ||
                    isNaN(Date.parse(nqInfo.enrolledDate)))
            ) {
                const parsedNqDate = Date.parse(nqInfo.effDate);
                const nqPlan = {};
                nqPlan.effDate = parsedNqDate;
                nqPlan.planName = plan.name;
                nqPlan.planId = plan.id;
                nqPlan.id = plan.id;
                nqPlans.push(nqPlan);
            }
        };

        /*
         * Helper function to check the plan balances for ee/er past
         */

        const checkPlanBalances = (balances) => {
            const ret = {
                erPast: false,
                eePast: false
            };
            _each(balances, (balance) => {
                if (balance.moneyType === "ER" && balance.totalBalance > 0) {
                    ret.erPast = true;
                } else if (balance.moneyType === "EE" && balance.totalBalance > 0) {
                    ret.eePast = true;
                }
            });
            return ret;
        };

        const isTrsStockPurchase = (codeToCheck) => {
            return TrsSdsvCodes.STOCK_PURCHASE.indexOf(codeToCheck) > -1;
        };

        const isTrsDefinedContribution = (codeToCheck) => {
            return TrsSdsvCodes.DEFINED_CONTRIBUTION.indexOf(codeToCheck) > -1;
        };

        const isTrsDefinedBenefit = (codeToCheck) => {
            return TrsSdsvCodes.DEFINED_BENEFIT.indexOf(codeToCheck) > -1;
        };

        const isTrsHSA = (codeToCheck) => {
            return TrsSdsvCodes.HSA.indexOf(codeToCheck) > -1;
        };

        const getTrsInfo = (trsFlexAccountInfo) => {
            const trs = {};
            trs.isTrsCashBalance =
                trsFlexAccountInfo.sdsvSubcode === SdsvSubcode.codes.CASH_BALANCE;
            trs.isStockPurchase = isTrsStockPurchase(trsFlexAccountInfo.sdsvCode);

            trs.isDefinedCont = isTrsDefinedContribution(trsFlexAccountInfo.sdsvCode);
            trs.isDefinedBenefit = isTrsDefinedBenefit(trsFlexAccountInfo.sdsvCode);
            trs.isHSA = isTrsHSA(trsFlexAccountInfo.sdsvCode);

            trs.periodBalance =
                !ObjectUtil.isUndefinedOrNull(trsFlexAccountInfo.periodEndBalance) &&
                trsFlexAccountInfo.periodEndBalance > 0
                    ? trsFlexAccountInfo.periodEndBalance
                    : 0;
            trs.eeEndBalance =
                !ObjectUtil.isUndefinedOrNull(trsFlexAccountInfo.employeeEndBalance) &&
                trsFlexAccountInfo.employeeEndBalance > 0
                    ? trsFlexAccountInfo.employeeEndBalance
                    : 0;
            trs.erEndBalance =
                !ObjectUtil.isUndefinedOrNull(trsFlexAccountInfo.employerEndBalance) &&
                trsFlexAccountInfo.employerEndBalance > 0
                    ? trsFlexAccountInfo.employerEndBalance
                    : 0;
            trs.investmentBalance =
                !ObjectUtil.isUndefinedOrNull(trsFlexAccountInfo.investmentBalance) &&
                trsFlexAccountInfo.investmentBalance > 0
                    ? trsFlexAccountInfo.investmentBalance
                    : 0;
            trs.payCreditRate =
                !ObjectUtil.isUndefinedOrNull(trsFlexAccountInfo.payCreditRate) &&
                trsFlexAccountInfo.payCreditRate > 0
                    ? trsFlexAccountInfo.payCreditRate
                    : 0;
            return trs;
        };

        //Employee past is based on TRS and cash balances

        const hasEmployeePast = (plan, balanceInfo) => {
            if (plan.irsCode === HSAConstants.IRS_CODE) {
                return false;
            }
            let eeTrs = false;
            const isTrs = !ObjectUtil.isUndefinedOrNull(plan.trsFlexAccountInfo);
            if (isTrs) {
                const trsInfo = getTrsInfo(plan.trsFlexAccountInfo);
                eeTrs =
                    trsInfo.periodBalance > 0 &&
                    (trsInfo.isStockPurchase ||
                        (trsInfo.isDefinedCont && trsInfo.eeEndBalance > 0) ||
                        (trsInfo.isHSA && trsInfo.investmentBalance > 0) ||
                        trsInfo.eeEndBalance > 0);
            }

            return eeTrs || balanceInfo.eePast;
        };

        /*
         * //Check for non-zero deferrals for EE FUTURE
         */

        const hasEmployeeFuture = (plan) => {
            if (plan.irsCode === HSAConstants.IRS_CODE) {
                return false;
            }
            if (plan.deferrals && !_isEmpty(plan.deferrals)) {
                let deferralTotal = 0;
                _each(plan.deferrals, (deferral) => {
                    if (deferral.futureDated) {
                        deferralTotal += deferral.futureDated.value;
                    } else {
                        deferralTotal += deferral.value;
                    }
                });
                return deferralTotal > 0;
            }
            return false;
        };

        /*
         * //Check for Employer Past (Balances/TRS)
         */

        const hasEmployerPast = (plan, balanceInfo) => {
            if (plan.irsCode === HSAConstants.IRS_CODE) {
                return false;
            }
            let erTrs = false;
            const isTrs = !ObjectUtil.isUndefinedOrNull(plan.trsFlexAccountInfo);
            if (isTrs) {
                const trsInfo = getTrsInfo(plan.trsFlexAccountInfo);
                erTrs =
                    trsInfo.periodBalance > 0 &&
                    ((trsInfo.isDefinedCont && trsInfo.erEndBalance > 0) ||
                        trsInfo.isDefinedBenefit ||
                        (trsInfo.isHSA && trsInfo.investmentBalance > 0) ||
                        trsInfo.erEndBalance > 0);
            }
            return erTrs || balanceInfo.erPast;
        };

        /*
         * //Check for Employer Future (TRS - NOT A CASH BALANCE OR A CASH BALANCE WITE paycredit rate)
         */
        const hasEmployerFuture = (plan) => {
            if (plan.irsCode === HSAConstants.IRS_CODE) {
                return false;
            }
            const isTrs = !ObjectUtil.isUndefinedOrNull(plan.trsFlexAccountInfo);
            let erTrsFuture = false;
            let hasMatch = false;
            let hasProfitSharing = false;
            if (isTrs) {
                const trsInfo = getTrsInfo(plan.trsFlexAccountInfo);

                erTrsFuture = !trsInfo.isTrsCashBalance || trsInfo.payCreditRate > 0;
            } else {
                const index = participant.plans.indexOf(plan);
                hasProfitSharing = getProfitSharingExistsForPlan(index, plan);
                hasMatch = getEmployerMatchExistsForPlan(index, plan);
            }
            return erTrsFuture || hasMatch || hasProfitSharing;
        };

        // reset the non-qual info in the viewModel
        dispatch(setNqInfo([]));

        _each(participant.plans, (plan) => {
            //First find nqEnrollment and if multiple, use the earliest date available for enrollment modal
            checkNqEnrollmentDate(plan);

            //Set NQ Plans - THis will replace the line above eventually
            setNQPlans(plan);

            /* Evaluate Plan Balances */
            const planBalanceInfo = checkPlanBalances(plan.balances);

            if (hasEmployeePast(plan, planBalanceInfo)) {
                employeePastPlans.push(plan);
            }

            if (hasEmployeeFuture(plan)) {
                employeeFuturePlans.push(plan);
            }

            if (hasEmployerPast(plan, planBalanceInfo)) {
                employerPastPlans.push(plan);
            }

            if (hasEmployerFuture(plan)) {
                employerFuturePlans.push(plan);
            }
        });

        // Use _uniq to prevent duplicates - ng-dupes issue and we only want
        // to display the plan once on the tooltip
        const payload = {
            plansWithEmployerPast: _uniqBy(employerPastPlans, "name"),
            plansWithEmployerFuture: _uniqBy(employerFuturePlans, "name"),
            plansWithMyPastContributions: _uniq(employeePastPlans),
            plansWithMyFutureContributions: _uniq(employeeFuturePlans),
            nqPlansEligible: _sortBy(nqPlans, ["effDate"])
        };

        dispatch({
            type: SET_PLAN_TABLES,
            payload
        });
    };
};
