import RequestFactory from "../factories/RequestFactory";
import { executeRequest } from "../utils/apiUtils";
import { getUrl } from "../utils/urlUtils.js";

const DEFAULT_PARAMS = {};

export const saveEDelivery = async (params = DEFAULT_PARAMS) => {
    const url = getUrl("saveEDelivery");
    const method = RequestFactory.HTTP_METHOD.POST;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const request = RequestFactory.create(url, params, method, contentType);

    const response = await executeRequest(request, "saveEDeliveryFault");
    return response.data;
};
