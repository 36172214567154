/**
 *
 * @name AddDeferralEvents
 * @memberOf projectedIncome.view.events
 * @copyright 2018 Empower Retirement
 * @description These are the events for the add deferral modal
 */

const root = "AddDeferralModalEvent.";

export const ADEvents = {
    OPTION_SELECT: root + "select_deferral_event",
    PCT_AMT_SELECT: root + "pct_amt_selected",
    ADD_CLICK: root + "add_button_click"
};
