import { useEffect } from "react";

import { useDispatch } from "react-redux";

import { setOpenDeminModal } from "../../../../actions/shared/sharedActionCreators";
import { useSelector } from "../../../../hooks/use-redux-actions";
import { modalStack } from "../../../../services/modalStackService";

const useModalStackListener = ({ loadingEligibility }: { loadingEligibility: boolean }) => {
    const { readyToShowApplication, txnAccess, txnAccessSet } = useSelector(
        (state) => state.shared
    );
    const dispatch = useDispatch();

    const { secDeminModal } = globalThis.integratedEligibility;

    const appReadyWithTxnAccess = readyToShowApplication && txnAccessSet;

    const modalStackLength = modalStack.getLength();

    useEffect(() => {
        if (appReadyWithTxnAccess && modalStackLength > 0) {
            if (!modalStack.getStackInitialized()) {
                // Remove PLA modal if ace messaging not enabled
                if (!txnAccess.aceMessagingEnabled) {
                    modalStack.filterStack();
                }

                modalStack.init();
                modalStack.setListener();
            }
        }
    }, [appReadyWithTxnAccess, modalStackLength, txnAccess.aceMessagingEnabled]);

    useEffect(() => {
        // check for deminModalArray
        //  If exists add to modal stack
        if (!loadingEligibility && secDeminModal.length > 0) {
            const deminAction = () => dispatch(setOpenDeminModal(true));
            modalStack.addModal("deminModal", 1, deminAction);
        }
    }, [dispatch, loadingEligibility, secDeminModal]);
};

export default useModalStackListener;
