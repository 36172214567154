import type { Investments } from "../../../types/common/index";

/**
 * Can model investment mix - if this returns false, the investment mix slider will be grayed out
 * @returns {boolean}
 */
const canChangeEquityMix = (individualOptions: Investments["individualOptions"]): boolean => {
    return !individualOptions.disableInvestmentMix;
};

export default canChangeEquityMix;
