import { ktmgFlags } from "gw-shared-components";

const TXN_CODES = [
    ktmgFlags.RETIREMENT_PLANNER,
    ktmgFlags.MARKETING_PREFERENCE_EDELIVERY,
    ktmgFlags.PCAP_WIDGETS,
    ktmgFlags.CHATBOT,
    ktmgFlags.LIVE_CHAT,
    ktmgFlags.BALANCE_TAB,
    ktmgFlags.BENEFICIARIES,
    ktmgFlags.HEALTHCARE_COST_PROJECTION,
    ktmgFlags.INVESTMENT_LINEUP,
    ktmgFlags.INCLUDE_SOCIAL_SECURUTIY_BENFITS,
    ktmgFlags.VIEW_MY_INVESTMENTS_OVERVIEW,
    ktmgFlags.VIEW_MANAGE_MY_INVESTMENTS,
    ktmgFlags.CONSOLIDATE_ACCOUNTS,
    ktmgFlags.CHANGE_LANGUAGE,
    ktmgFlags.LOAN_SUMMARY,
    ktmgFlags.OTHER_ASSETS,
    ktmgFlags.PAYCHECK_CONTRIBUTIONS_VIEW,
    ktmgFlags.RATE_OF_RETURN,
    ktmgFlags.SELF_DIRECTED_BROKERAGE,
    ktmgFlags.PICK_NEW_LANGUAGE,
    ktmgFlags.STATEMENTS_AND_DOCUMENTS,
    ktmgFlags.TRANSACTION_HISTORY,
    ktmgFlags.MANAGE_BANK_ACCOUNTS,
    ktmgFlags.UPLOAD_DOCUMENTS,
    ktmgFlags.WHEN_COULD_I_RETIRE,
    ktmgFlags.WITHDRAWAL_2018_MAIN,
    ktmgFlags.ROLLOVER,
    ktmgFlags.CUSTOM_CHAT,
    ktmgFlags.OPEN_NEW_ACCOUNT,
    ktmgFlags.SAVINGS_PLANNER,
    ktmgFlags.ENTERPRISE_CHAT_ENABLED
];

export default TXN_CODES;
