/**
 *
 * @name OtherAssetsConstants
 * @memberOf common.model.constants
 * @date 10/30/2019
 * @copyright 2019 Empower Retirement
 * @description Constants for Other Assets
 * @OAB_PLAN Other Assets Balance Plan
 * @OAB_TRS Other Assets Balance Total Retirement Solutions
 * @ACCOUNTS_LITE_TABLE  PCAP Product/Account Types supported in LIAT
 */

"use strict";

const OtherAssetsConstants = {
    OAB_PLAN: "OAB_PLAN",
    OAB_TRS: "OAB_TRS",

    ACCOUNTS_LITE_TABLE: [
        { productType: "BANK", accountTypeGroup: "BANK", accountType: "MMA", accountSubType: "" },
        {
            productType: "BANK",
            accountTypeGroup: "BANK",
            accountType: "SAVINGS",
            accountSubType: ""
        },
        {
            productType: "BANK",
            accountTypeGroup: "BUSINESS",
            accountType: "SAVINGS",
            accountSubType: ""
        },
        { productType: "BANK", accountTypeGroup: "CD", accountType: "CD", accountSubType: "" },
        { productType: "BANK", accountTypeGroup: "HEALTH", accountType: "HSA", accountSubType: "" },
        { productType: "BANK", accountTypeGroup: "TRUST", accountType: "CD", accountSubType: "" },
        {
            productType: "BANK",
            accountTypeGroup: "TRUST",
            accountType: "SAVINGS",
            accountSubType: ""
        },
        {
            productType: "INSURANCE",
            accountTypeGroup: "LIFE_INSURANCE",
            accountType: "LIFE_INSURANCE",
            accountSubType: "TERM"
        },
        {
            productType: "INSURANCE",
            accountTypeGroup: "LIFE_INSURANCE",
            accountType: "LIFE_INSURANCE",
            accountSubType: "UNIVERSAL"
        },
        {
            productType: "INSURANCE",
            accountTypeGroup: "LIFE_INSURANCE",
            accountType: "LIFE_INSURANCE",
            accountSubType: "WHOLE"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "ESOP",
            accountType: "ESOP",
            accountSubType: ""
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "ESPP",
            accountType: "ESPP",
            accountSubType: ""
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "HEALTH",
            accountType: "HSA",
            accountSubType: ""
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "INVESTMENT",
            accountType: "INVESTMENT",
            accountSubType: ""
        },

        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "457",
            accountSubType: "ROTH"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "457",
            accountSubType: "TRADITIONAL"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "457B",
            accountSubType: "ROTH"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "457B",
            accountSubType: "TRADITIONAL"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "457F",
            accountSubType: "ROTH"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "457F",
            accountSubType: "TRADITIONAL"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "1165E",
            accountSubType: ""
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "401A",
            accountSubType: "AFTER_TAX"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "401A",
            accountSubType: "TRADITIONAL"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "401H",
            accountSubType: ""
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "401K",
            accountSubType: "AFTER_TAX"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "401K",
            accountSubType: "ROTH"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "401K",
            accountSubType: "TRADITIONAL"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "403B",
            accountSubType: "AFTER_TAX"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "403B",
            accountSubType: "ROTH"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "403B",
            accountSubType: "TRADITIONAL"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "415M",
            accountSubType: ""
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "INDIVIDUAL_K",
            accountSubType: "AFTER_TAX"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "INDIVIDUAL_K",
            accountSubType: "ROTH"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "INDIVIDUAL_K",
            accountSubType: "TRADITIONAL"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "IRA",
            accountSubType: "INHERITED_ROTH"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "IRA",
            accountSubType: "INHERITED_TRADITIONAL"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "IRA",
            accountSubType: "NOT_SURE"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "IRA",
            accountSubType: "ROLLOVER"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "IRA",
            accountSubType: "ROTH"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "IRA",
            accountSubType: "SEP"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "IRA",
            accountSubType: "SIMPLE"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "IRA",
            accountSubType: "TRADITIONAL"
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "NON_QUAL",
            accountSubType: ""
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "OTHER_PENSION_PLAN",
            accountSubType: ""
        },
        {
            productType: "INVESTMENT",
            accountTypeGroup: "RETIREMENT",
            accountType: "PROFIT_SHARING_PLAN",
            accountSubType: ""
        }
    ]
};

export default OtherAssetsConstants;
