import React from "react";

import PropTypes from "prop-types";

import { ACCESSIBILITY } from "../../../../constants/AccessibilityPaycheck";
import HiddenA11yWrapper from "../../../accessibility/HiddenA11yWrapper";
import CurrencyFormatter from "../../../currencyFormatter/CurrencyFormatter";

const getBalanceText = (val) => {
    // Only uncomment the following for dev as it's called quite a bit due to data-binding
    //$log.debug("getBalanceText( {0} )", [val]);
    let result = "";
    let roundedVal = (Math.round(val / 1000) * 1000) / 1000;

    if (roundedVal < 999) {
        if (roundedVal <= 1) {
            result += "<";
        }
        result += "$" + roundedVal + "K";
    } else {
        roundedVal = roundedVal / 1000;
        result += "$" + roundedVal + "M";
    }

    return result;
};

export const Balances = ({ translations, score, me }) => {
    return (
        <tr className="balances">
            <td>
                <span>{translations.balance}:</span>
            </td>
            <td>
                <HiddenA11yWrapper id={ACCESSIBILITY.HOW_DO_I_COMPARE["balance-my-peers"]}>
                    {translations.balance} &#32;
                    {getBalanceText(score.medBalance)}
                </HiddenA11yWrapper>
                {getBalanceText(score.medBalance)}
            </td>
            <td>
                <HiddenA11yWrapper id={ACCESSIBILITY.HOW_DO_I_COMPARE["balance-top-peers"]}>
                    {translations.balance} &#32;
                    {getBalanceText(score.topBalance)}
                </HiddenA11yWrapper>
                {getBalanceText(score.topBalance)}
            </td>
            <td>
                <CurrencyFormatter amount={me.balance} precision={0} />
            </td>
        </tr>
    );
};

Balances.propTypes = {
    translations: PropTypes.object,
    score: PropTypes.object,
    me: PropTypes.object
};
