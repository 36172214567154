import {
    SET_PARTICIPANT_TYPE,
    SET_PARTICIPANT_DATE_OF_BIRTH,
    SET_PARTICIPANT_RETIREMENT_AGE,
    SET_PARTICIPANT_PROJECTIONS_MAP,
    SET_PARTICIPANT_DEFERRALS,
    SET_PARTICIPANT_PLANS_NEW_DEFERRALS,
    SET_PARTICIPANT_PLANS,
    SET_PARTICIPANT,
    UPDATE_PARTICIPANT_SALARY,
    UPDATE_PARTICIPANT_INCOME_GOAL,
    SET_PARTICIPANT_ADJUSTED_LIFE_EXPECTANCY_AGE,
    SET_PARTICIPANT_DEFAULT_LIFE_EXPECTANCY_AGE,
    UPDATE_HAS_REDUCED_SALARY,
    SET_SHOW_CONSIDERATION_MODAL_LOADER,
    UPDATE_PARTICIPANT_RETIREMENT_ASSETS,
    UPDATE_PARTICIPANT_EXTERNAL_ASSETS,
    SET_PARTICIPANT_AGE,
    SET_PARTICIPANT_INDIVIDUAL_ID,
    SET_USER_MESSAGES
} from "./participantDetailsTypes";
/**
 * ACTIONS FOUND IN THIS FILE
 * @setParticipantType
 * @setParticipantDateOfBirth
 * @setParticipantRetirementAge
 * @setParticipantProjectsMap
 * @setParticipantDeferrals
 * @setParticipant
 * @updateParticipantSalary
 */

export const setParticipant = (user) => {
    return {
        type: SET_PARTICIPANT,
        payload: user
    };
};

export const setType = (participantType) => {
    return {
        type: SET_PARTICIPANT_TYPE,
        payload: participantType
    };
};

export const setDateOfBirth = (dateOfBirth) => {
    return {
        type: SET_PARTICIPANT_DATE_OF_BIRTH,
        payload: dateOfBirth
    };
};

export const setRetirementAge = (retirementAge) => {
    return {
        type: SET_PARTICIPANT_RETIREMENT_AGE,
        payload: retirementAge
    };
};

export const setParticipantProjectionsMap = (projectionsMap) => {
    return {
        type: SET_PARTICIPANT_PROJECTIONS_MAP,
        payload: projectionsMap
    };
};

export const setParticipantDeferrals = (deferrals, savedDeferrals = false) => {
    return {
        type: SET_PARTICIPANT_DEFERRALS,
        payload: { deferrals, savedDeferrals }
    };
};

export const setParticipantPlansNewDeferrals = (payload) => {
    return {
        type: SET_PARTICIPANT_PLANS_NEW_DEFERRALS,
        payload
    };
};

export const setParticipantPlans = (payload) => {
    return {
        type: SET_PARTICIPANT_PLANS,
        payload
    };
};

export const updateParticipantSalary = (payload) => {
    return {
        type: UPDATE_PARTICIPANT_SALARY,
        payload
    };
};

export const updateParticipantIncomeGoal = (payload) => {
    return {
        type: UPDATE_PARTICIPANT_INCOME_GOAL,
        payload
    };
};

export const setParticipantLifeExpectancyAge = (payload) => {
    return {
        type: SET_PARTICIPANT_ADJUSTED_LIFE_EXPECTANCY_AGE,
        payload
    };
};

export const setParticipantLifeDefaultAge = (payload) => {
    return {
        type: SET_PARTICIPANT_DEFAULT_LIFE_EXPECTANCY_AGE,
        payload
    };
};

export const updateHasReducedSalary = (payload) => {
    return {
        type: UPDATE_HAS_REDUCED_SALARY,
        payload
    };
};
export const setConsiderationModalLoader = (payload) => {
    return {
        type: SET_SHOW_CONSIDERATION_MODAL_LOADER,
        payload
    };
};

export const updateParticipantRetirementAssets = (payload) => {
    return {
        type: UPDATE_PARTICIPANT_RETIREMENT_ASSETS,
        payload
    };
};

export const updateParticipantExternalAssets = (payload) => {
    return {
        type: UPDATE_PARTICIPANT_EXTERNAL_ASSETS,
        payload
    };
};

export const setParticipantAge = (payload) => {
    return {
        type: SET_PARTICIPANT_AGE,
        payload
    };
};

export const setParticipantIndividualId = (payload) => {
    return {
        type: SET_PARTICIPANT_INDIVIDUAL_ID,
        payload
    };
};

export const setMessages = (payload) => {
    return {
        type: SET_USER_MESSAGES,
        payload
    };
};
