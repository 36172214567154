import React from "react";

import PropTypes from "prop-types";
import { Overlay } from "react-bootstrap";

import PaycheckItemDetailsPopover from "./PaycheckItemDetailsPopover";

/**
 *
 * @param {bool} show whether show the popover
 * @param {func} onHide onHide callback
 * @param {string} placement popover placement, "top", "bottom", "left", "right"
 * @param {any} animation popover animation, defaults to false
 * @param {object} styles style for the custom popover object, currently used for positioning
 * @param {string} title popover title
 * @param {array} content popover content
 *
 */
const PaycheckItemDetailsPopoverOverlay = ({
    show,
    onHide,
    placement = "top",
    animation = false,
    styles = null,
    title = "",
    content = null
}) => {
    return (
        <div data-testid="paycheck-item-details-popover-overlay">
            <Overlay show={show} onHide={onHide} placement={placement} animation={animation}>
                {({ ...props }) => {
                    return (
                        <PaycheckItemDetailsPopover
                            {...props}
                            styles={styles}
                            title={title}
                            content={content}
                        />
                    );
                }}
            </Overlay>
        </div>
    );
};

PaycheckItemDetailsPopoverOverlay.propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    placement: PropTypes.string,
    animation: PropTypes.any,
    styles: PropTypes.object,
    title: PropTypes.string,
    content: PropTypes.any,
    target: PropTypes.any
};

export default PaycheckItemDetailsPopoverOverlay;
