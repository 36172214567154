// MOVING TO CURRENCY UTILS. TO BE REMOVED
export const formatCurrency = (val) => {
    let valDec = "00";
    let valStr = val.toString();
    if (val) {
        if (valStr.includes(".")) {
            valDec = valStr.split(".")[1];
            valStr = valStr.slice(0, valStr.indexOf("."));
        }
        if (valStr.length > 3) {
            valStr = valStr.split("");
            for (let i = valStr.length - 3; i > 0; i -= 3) {
                if (i <= 0) {
                    break;
                }
                valStr.splice(i, 0, ",");
            }
            valStr = valStr.join("");
        }
        valStr = `$${valStr}.${valDec}`;
    }
    return valStr;
};
