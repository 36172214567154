import { ObjectUtil } from "gw-shared-components";
import { each as _each, findIndex as _findIndex } from "lodash";

import MatchRuleTypes from "../constants/MatchRuleTypes";
import ReferenceData from "../constants/ReferenceData";

/**
 * Add check for employerMatch and companyMatch tags
 * @param {*} type
 * @param {*} index
 * @param {*} plan
 */
export const getHasEmployerMatchOfTypeForPlan = function (type, index, plan) {
    let hasMatch = false;
    if (plan && plan.employerMatch) {
        _each(plan.employerMatch, (rule) => {
            if (rule.calcType === type) {
                hasMatch = true;
            }
        });
    }

    if (!hasMatch) {
        let indx = -1;
        if (plan && plan.companyMatch) {
            indx = _findIndex(plan.companyMatch.ruleTypes, function (o) {
                return o === type;
            });
            if (indx > -1) {
                hasMatch = true;
            }
        }
    }

    return hasMatch;
};

/** check if employer match exists
 *  no need to check plan.planRules.usePayrollEmployerMatch
 */
export const getEmployerMatchExistsForPlan = (index, plan) => {
    let returnValue;

    returnValue = getHasEmployerMatchOfTypeForPlan(MatchRuleTypes.COMPANY_MATCH, index, plan);

    if (!returnValue) {
        returnValue = ObjectUtil.hasValue(plan) && ObjectUtil.hasValue(plan.companyMatch);
    }

    return returnValue;
};

export const getHasCompanyMatchOfTypeForPlan = function (type, index, plan) {
    let hasMatch = false;
    if (!ObjectUtil.isUndefinedOrNull(plan.companyMatch)) {
        _each(plan.companyMatch.matchTiers, function (tiers) {
            _each(tiers, function (rule) {
                if (rule.profitSharing === "Y") {
                    hasMatch = true;
                }
            });
        });
    }

    return hasMatch;
};

/**
 *  Check employer match and company match for profit sharing section
 */
export const getProfitSharingExistsForPlan = (index, plan) => {
    let returnValue;

    returnValue = getHasEmployerMatchOfTypeForPlan(MatchRuleTypes.PROFIT_SHARING, index, plan);

    if (!returnValue && plan && plan.companyMatch) {
        returnValue = getHasCompanyMatchOfTypeForPlan(
            MatchRuleTypes.COMPANY_PROFIT_SHARING,
            index,
            plan
        );
    }

    return returnValue;
};

export const getEmployerOtherAssets = (otherAssets) => {
    const empOA = [];
    if (otherAssets) {
        for (let i = 0; i < otherAssets.length; i++) {
            const asset = otherAssets[i];
            if (asset.employerContribution) {
                empOA.push(asset);
            }
        }
    }
    return empOA;
};

//RI-5505 other assets slice shown even for $0 with min width
//Except
//Other assets are disabled
//Emulator = true, otherassets slice is not visisble on rainbow bar
//RI-6277 - dispay other assets in rainbow bar in PAE emulator;
//        - also only display if KTMG indicator is on or if KTMG is false and other assets value > 0
// other assets enabled == false and has value, still show
// other assets enabled === false and value === 0 do not show
export const getOtherAssetsDisplayFlag = (id, incomePart, primaryPlan, user) => {
    let otherAssetFlag = false;
    const isEmulator = user.type !== ReferenceData.USER_TYPE_PARTICIPANT;
    if (isEmulator) {
        if (primaryPlan.planRules.otherAssetsEnabled) {
            otherAssetFlag = true;
        } else {
            otherAssetFlag = !!(incomePart && Number(incomePart.value) > 0);
        }
    } else {
        otherAssetFlag = primaryPlan.planRules.otherAssetsEnabled;
    }
    return otherAssetFlag;
};
