// Privileged emulator routes
const privilegedRoutes = [
    "/accounts/add",
    "/accounts/details",
    "/advisor",
    "/all-transactions",
    "/cash-flow",
    "/cash-flow/spending",
    "/dashboard",
    "/dashboard/other-assets",
    "/networth",
    "/open-account",
    "/portfolio",
    "/portfolio/allocation",
    "/portfolio/balances",
    "/portfolio/performance",
    "/portfolio/sectors",
    "/retirement-planner",
    "/savings-planner",
    "/retirement-planner/paycheck",
    "/savings-planner/debt-paydown",
    "/savings-planner/emergency-fund",
    "/settings/profile",
    "/settings/settings"
];

export default privilegedRoutes;
