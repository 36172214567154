// Action type definitions for Goal Modal

const SALARY_CHANGE = "GOAL_MODAL_SALARY_CHANGE";
const PORTION_CHANGE = "GOAL_MODAL_PORTION_CHANGE";
const CHANGE_VIEW = "GOAL_MODAL_CHANGE_VIEW";
const CHANGE_SPOUSE_NAME = "GOAL_MODAL_CHANGE_SPOUSE_NAME";
const SPOUSE_SALARY_CHANGE = "GOAL_MODAL_SPOUSE_SALARY_CHANGE";
const GENDER_CHANGE = "GOAL_MODAL_GENDER_CHANGE";
const SPOUSE_DESIRED_AGE = "GOAL_MODAL_SPOUSE_DESIRED_AGE";
const SPOUSE_CHANGE_PORTION = "GOAL_MODAL_SPOUSE_CHANGE_PORTION";
const SPOUSE_DOB_CHANGE = "GOAL_MODAL_SPOUSE_DOB_CHANGE";
const PARTICIPANT_DOB_CHANGE = "GOAL_MODAL_PARTICIPANT_DOB_CHANGE";
const ADD_COMP = "GOAL_MODAL_ADD_COMP";
const ADD_SPOUSE = "GOAL_MODAL_ADD_SPOUSE";
const CANCEL = "GOAL_MODAL_CANCEL";
const SET_SHOW_MODAL = "GOAL_MODAL_SHOW_MODAL";
const DELETE_SPOUSE = "GOAL_MODAL_DELETE_SPOUSE";
const SAVE_DELETE = "GOAL_MODAL_SAVE_DELETE";
const NO_PROMPT = "GOAL_MODAL_NO_PROMPT";
const TAB_CHANGE = "GOAL_MODAL_TAB_CHANGE";
const SET_GOAL_SAVING = "SET_GOAL_SAVING";
const HAS_GOAL_FOCUS = "HAS_GOAL_FOCUS";

export {
    SALARY_CHANGE,
    PORTION_CHANGE,
    CHANGE_VIEW,
    CHANGE_SPOUSE_NAME,
    SPOUSE_SALARY_CHANGE,
    GENDER_CHANGE,
    SPOUSE_DESIRED_AGE,
    SPOUSE_CHANGE_PORTION,
    SPOUSE_DOB_CHANGE,
    PARTICIPANT_DOB_CHANGE,
    ADD_COMP,
    ADD_SPOUSE,
    CANCEL,
    SET_SHOW_MODAL,
    DELETE_SPOUSE,
    NO_PROMPT,
    SAVE_DELETE,
    TAB_CHANGE,
    SET_GOAL_SAVING,
    HAS_GOAL_FOCUS
};
