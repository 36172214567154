"use strict";
/**
 * @name app.common.model.domain.IRSLimits
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @type Class
 * @description
 *
 *        Creates a defined object structure for the IRSLimits object type.
 */

class IRSLimits {
    constructor(config) {
        this.limit402g = 0;
        this.limit402gNextYear = 0;
        this.limit415 = 0;
        this.limit457 = 0;
        this.limit457Catchup = 0;
        this.limitCatchup = 0;
        this.limit402gCatchup = 0;
        this.limitHSA = 0;
        this.limitIRA = 0;
        this.matchableSalaryCap = 0;
        this.discountFactor = 0;
        this.rateOfInflation = 0;
        this.catchupEligibleAge = 0;
        this.SSWageCap = 0;
        this.hsaSingle = 0;
        this.hsaSingleCatchUp = 0;
        this.hsaFamily = 0;
        this.hsaFamilyCatchUp = 0;
        this.limit457b = 0;
        this.limit457bCatchup = 0;
        this.higherCatchupEligibleAgeMax = 0;
        this.higherCatchupEligibleAgeMin = 0;
        this.higher402gCatchupLimit = 0;
        this.higher457CatchupLimit = 0;
        this.higherCatchupLimit = 0;

        if (config) {
            if (config.isHsa) {
                this.hsaSingle = config.hsaSingle;
                this.hsaSingleCatchUp = config.hsaSingleCatchUp;
                this.hsaFamily = config.hsaFamily;
                this.hsaFamilyCatchUp = config.hsaFamilyCatchUp;
            } else {
                this.limit402g = config.limit402g;
                this.limit402gNextYear = config.limit402gNextYear;
                this.limit415 = config.limit415;
                this.limit457 = config.limit457;
                this.limit457Catchup = config.limit457Catchup;
                this.limitCatchup = config.limitCatchup;
                this.limit402gCatchup = config.limit402gCatchup;
                this.limitIRA = config.limitIRA;
                this.matchableSalaryCap = config.matchableSalaryCap;
                this.discountFactor = config.discountFactor;
                this.rateOfInflation = config.rateOfInflation;
                this.catchupEligibleAge = config.catchupEligibleAge;
                this.SSWageCap = config.SSWageCap;
                this.limit457b = config.limit457b;
                this.limit457bCatchup = config.limit457bCatchup;
                this.higherCatchupEligibleAgeMax = config.higherCatchupEligibleAgeMax;
                this.higherCatchupEligibleAgeMin = config.higherCatchupEligibleAgeMin;
                this.higher402gCatchupLimit = config.higher402gCatchupLimit;
                this.higher457CatchupLimit = config.higher457CatchupLimit;
                this.higherCatchupLimit = config.higherCatchupLimit;
            }
        }
    }
}

export default IRSLimits;
