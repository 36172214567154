/**
 *
 * @name MultiSliderEvents
 * @memberOf common.directive.multislider.events
 * @copyright 2015 Empower Retirement
 * @description These are the events for the print directive.
 * property {string} PRINT_BUTTON PrintEvent.print_button_event
 *
 */

"use strict";

const root = "MultiSliderEvent.";

export const MultiSliderEvents = {
    TOGGLE: root + "toggle_multi_slider_expand_event",
    ADD_CLICK: root + "add_contribution_link_clicked_event"
};
