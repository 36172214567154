import { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { ROUTE_OPEN_ACCOUNT } from "../../../../../routes";

const useOpenAccountFooter = () => {
    const { pathname } = useLocation();
    const openAccountFooterContainer = document.querySelector(".site-footer");
    // Render open account footer
    useEffect(() => {
        if (openAccountFooterContainer && globalThis.showOpenAccountFooter) {
            window.dispatchEvent(
                new CustomEvent("show-account-footer", {
                    detail: { show: pathname.indexOf(ROUTE_OPEN_ACCOUNT) !== -1 }
                })
            );
        }
    }, [pathname, openAccountFooterContainer]);
};

export default useOpenAccountFooter;
