import StringUtil from "core-ui/client/src/app/StringUtil";
import { ObjectUtil } from "gw-shared-components";
import { each as _each } from "lodash";

import ReferenceData from "../constants/ReferenceData";

import { getHasDeferralWarnings } from "./deferralsLogic/deferrals";
import { equityMixChangesAllowed } from "./investments";

/**
 *
 * business.RetirementIncome
 * @date 02/12/2020
 * @copyright 2020 Empower Retirement
 *
 **/
export const hasEquityMixChanged = (
    forceAllowMix,
    individualOptions,
    originalEquityMix,
    sliderEquityMix
) => {
    if (ObjectUtil.isUndefined(forceAllowMix)) {
        forceAllowMix = false;
    }

    const changesAllowed = equityMixChangesAllowed(individualOptions);
    const baselineEquityMix = originalEquityMix;

    const equityMixSliderChanged = Math.round(sliderEquityMix) !== Math.round(baselineEquityMix);

    return equityMixSliderChanged && (changesAllowed || forceAllowMix);
};

/**
 * @description Determines if the retirement age slider has changed.
 *     when retirement age slider changes, slider update is in participant.retirementAge
 *          original retirement age is in baseline.retirementAge
 * @returns {boolean|*}
 */
export const hasRetirementAgeChanged = (originalRetireAge, currentRetireAge) => {
    return Math.round(currentRetireAge) !== Math.round(originalRetireAge);
};

export const hasDeferralsChanged = (deferrals) => {
    let changed = false;

    if (deferrals) {
        deferrals.forEach((item) => {
            if (!ObjectUtil.isUndefinedOrNull(item.priorValue) && item.value !== item.priorValue) {
                changed = true;
            }
        });
    }

    return changed;
};

/**
 * Check if sliders have changed
 * @param {*} forceAllowDeferrals
 * @param {*} forceAllowMix
 * @description Returns boolean representing if the sliders have changed from their original/stored values.
 **/
export const hasPendingChanges = (
    forceAllowDeferrals,
    forceAllowMix,
    deferrals,
    originalRA,
    currentRA,
    individualOptions,
    originalEquityMix,
    currentEquityMix
) => {
    const hasQualifyingDeferralChange = hasDeferralsChanged(deferrals);
    const hasQualifyingRetirementAgeChange = hasRetirementAgeChanged(originalRA, currentRA);
    const hasQualifyingMixChange = hasEquityMixChanged(
        forceAllowMix,
        individualOptions,
        originalEquityMix,
        currentEquityMix
    );

    // eval if any valid changes exist
    const pendingChanges =
        hasQualifyingDeferralChange || hasQualifyingRetirementAgeChange || hasQualifyingMixChange;

    return pendingChanges;
};

/**
 * Determine if there are investment warnings
 */
export const getHasEquityMixWarnings = (equityMixWarnings) => {
    return !ObjectUtil.isUndefinedOrNull(equityMixWarnings) && equityMixWarnings.length > 0;
};

/**
 * Determine if review button should be displayed
 */
export const shouldShowReviewButton = (
    deferrals,
    originalRA,
    currentRA,
    individualOptions,
    originalEquityMix,
    currentEquityMix,
    warnings
) => {
    const hasChanges = hasPendingChanges(
        true,
        false,
        deferrals,
        originalRA,
        currentRA,
        individualOptions,
        originalEquityMix,
        currentEquityMix,
        warnings
    );
    const hasWarnings = getHasEquityMixWarnings(warnings);
    const pendingWarnings = hasChanges || hasWarnings;

    return pendingWarnings;
};

/**
 * @description Returns boolean indicating of ONLY the investment mix has changed
 * @returns {boolean|boolean|*}
 */
export const equityMixChangeOnly = (
    includeWarnings,
    deferrals,
    originalRA,
    currentRA,
    individualOptions,
    originalEquityMix,
    currentEquityMix,
    warnings,
    submissionWarnings
) => {
    const hasChanged = hasDeferralsChanged(deferrals);
    const hasDeferralWarnings =
        includeWarnings && getHasDeferralWarnings(warnings, submissionWarnings);

    const hasAllChanged = !hasDeferralWarnings && hasChanged;
    const anyChanges =
        !hasAllChanged &&
        !hasRetirementAgeChanged(originalRA, currentRA) &&
        hasEquityMixChanged(false, individualOptions, originalEquityMix, currentEquityMix);
    return anyChanges;
};

export const getShouldTakeToEquityMixPage = (
    forceAllowMix,
    individualOptions,
    originalEquityMix,
    currentEquityMix,
    warnings
) => {
    return (
        hasEquityMixChanged(
            forceAllowMix,
            individualOptions,
            originalEquityMix,
            currentEquityMix
        ) && !getHasEquityMixWarnings(warnings)
    );
};

export const shouldReplaceReviewChangesWithEquityMixPage = (
    deferrals,
    originalRetireAge,
    currentRetireAge,
    individualOptions,
    originalEquityMix,
    currentEquityMix,
    investmentWarnings,
    deferralWarnings,
    deferralSubmissionWarnings
) => {
    const change = equityMixChangeOnly(
        false,
        deferrals,
        originalRetireAge,
        currentRetireAge,
        individualOptions,
        originalEquityMix,
        currentEquityMix,
        deferralWarnings,
        deferralSubmissionWarnings
    );
    const takeToInvestmentPage = getShouldTakeToEquityMixPage(
        false,
        individualOptions,
        originalEquityMix,
        currentEquityMix,
        investmentWarnings
    );
    return change && takeToInvestmentPage;
};

/**
 * @memberOf common.service.business.RetirementSavingsService
 * @description Parses the appropriate url for going to the investment mix change app/site
 * @returns {*}
 */
export const getEquityMixUpdateURL = (indId, gaId, targetMix, individualOptions) => {
    if (!individualOptions.notAllMoneyTypeGroupingsFlow) {
        return StringUtil.supplant(ReferenceData.URL_INVESTMENT_CHANGE, {
            indId: indId,
            gaId: gaId,
            targetMix: Math.round(targetMix)
        });
    }
};

/**
 * @memberOf common.service.business.RetirementSavingsService
 * @description Handles launching the off-app/site investment mix url
 */
export const launchEquityMix = (indivId, planId, equityMix, individualOptions) => {
    const equityMixURL =
        window.location.protocol +
        "//" +
        window.location.host +
        getEquityMixUpdateURL(indivId, planId, equityMix, individualOptions);
    window.location.href = equityMixURL;
};

/**
 * @memberOf common.service.business.RetirementSavingsService
 * @description is being unenrolled
 * @param forceAllowDeferral
 * @returns {boolean|*|*|maximizer|{leavingMaximizer}|string|null|boolean|*}
 */
export const getBeingUnEnrolledFromMaximizer = (forceAllowDeferral, deferralClass, primaryPlan) => {
    const changedDeferrals = deferralClass.getChangedDeferrals("full");
    return (
        getEnrolledInMaximizer(primaryPlan) &&
        changedDeferrals.length > 0 &&
        deferralClass.checkChangedDeferralsForMaximizer(changedDeferrals)
    );
};

export const getEnrolledInMaximizer = (primaryPlan) => {
    return (
        getMaximizerAvailable(primaryPlan) &&
        getMaximizerEnrolledAvailable(primaryPlan) &&
        getMaximizer(primaryPlan).isOngoing
    );
};

export const getAffectedPlanNames = (
    deferral,
    activePlan,
    retireAge,
    priorRetireAge,
    equityMix,
    originalEquityMix,
    individualOptions,
    priorDeferrals,
    deferralWarnings,
    deferralSubmissionWarnings
) => {
    const names = [];

    _each(
        deferral.getChangedDeferralsList(
            false,
            priorDeferrals,
            deferralWarnings,
            deferralSubmissionWarnings
        ),
        function (def) {
            if (names.indexOf(def.plan.name) === -1) {
                names.push(def.plan.name);
            }
        }
    );

    if (
        (hasRetirementAgeChanged(retireAge, priorRetireAge) ||
            hasEquityMixChanged(false, individualOptions, equityMix, originalEquityMix)) &&
        names.indexOf(activePlan.name) === -1
    ) {
        names.push(activePlan.name);
    }

    return names;
};

export const canSubmitChanges = (
    deferrals,
    priorRetireAge,
    retireAge,
    individualOptions,
    priorEquityMix,
    equityMix,
    investmentWarnings
) => {
    return hasPendingChanges(
        true,
        false,
        deferrals,
        priorRetireAge,
        retireAge,
        individualOptions,
        priorEquityMix,
        equityMix,
        investmentWarnings
    );
};

/**
 * @description is maximizer avail
 * @returns {*|maximizer|{leavingMaximizer}|string|null}
 */
export const getMaximizerAvailable = (primaryPlan) => {
    // NOTE: Only uncomment this for dev, but do not leave in for prod as it logs quite a bit for AJS digest cycle's data binding
    //logger.debug("getMaximizer( exists = {0} )", [String(!ObjectUtil.isUndefinedOrNull(dataModel.activePlan.maximizer))]);
    return primaryPlan.planRules.maximizer;
};

/**
 * @description is maximizer enrolled
 * @returns {boolean}
 */
export const getMaximizerEnrolledAvailable = (primaryPlan) => {
    return !ObjectUtil.isUndefinedOrNull(primaryPlan.maximizer);
};

/**
 *
 * @description gets maximzier object
 * @returns {*}
 */
export const getMaximizer = (primaryPlan) => {
    if (!getMaximizerAvailable(primaryPlan) || !getMaximizerEnrolledAvailable(primaryPlan)) {
        //do not set maximizer to be an object here. we are checking for maximizer eligibility via object is null
        return { wasEnrolled: false };
    }
    return primaryPlan.maximizer;
};
