import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";

import { DiagnosticsConstants } from "./common";
import { sendMetricEvent } from "./sendEvents";

const TIME_TO_LIAT = DiagnosticsConstants.TIME_TO_LIAT;
const TIME_TO_LIAT_FULL = DiagnosticsConstants.TIME_TO_LIAT_FULL;

const logMetricEvent = (
    measurementName: string,
    name: string,
    startTime: number,
    endTime: number
) => {
    const timeToLoad = endTime - startTime;
    // We will keep this as a common metric independent of any login flow
    const payload = {
        measurementName,
        name,
        timeToLoad,
        startTime,
        endTime
    };
    const selectionName = name === "TTLIAT" ? "time_to_liat_load" : "time_to_liat_full_load";
    sendMetricEvent(payload);
    dispatchAmplitude({
        eventType: AMPLITUDE_EVENTS[name],
        selection: selectionName,
        payload
    });
};

const sentryLiatInitLoadMetrics = (startTime: number, endTime: number, serviceName: string) => {
    if (serviceName === "TIME_TO_LIAT") {
        logMetricEvent("Cached Liat Load Time", TIME_TO_LIAT, startTime, endTime);
    } else {
        logMetricEvent("Full Liat Load Time", TIME_TO_LIAT_FULL, startTime, endTime);
    }
};
export default sentryLiatInitLoadMetrics;
