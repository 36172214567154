import axios, { AxiosResponse } from "axios";

import { getUrl } from "../../utils/urlUtils";

interface Notification {
    code: string;
    display: boolean;
    message: string;
    sequence: number;
    source: string;
    type: string;
}

interface Diagnostics {
    advisoryServicesProfile: number;
    deminAccountForLanding: number;
    gaServiceRules: number;
    groupDetailList: number;
    isLiatEligible: number;
    samlAssertion: number;
    showLIATStatus: number;
}

interface LogInfo {
    AccuCd: string;
    ClientId: string;
    GroupId: string;
    IndividualId: string;
    LoginType: string;
    ParticipantName: string;
    PersonId: string;
    SponsorId: string;
    diagnostics: Diagnostics;
}

export interface EligiblityData {
    deminAccountForWithdrawalLanding: string;
    hasRsgServices: boolean;
    homePageEligibility: boolean;
    integrated: boolean;
    integratedCSS: string;
    integratedJS: string;
    isRetail: boolean;
    postOriginUrl: string;
    samlToken: string;
    secDeminModal: {
        groupId: string;
        individualId: string;
        planName: string;
    }[];
    showLIAT: boolean;
    showWidgets: boolean;
    type: string;
}

// Not sure why this is the case that the service will return a completely different object...
export type PartialEligiblityData = Pick<
    EligiblityData,
    "hasRsgServices" | "homePageEligibility" | "integrated"
>;
interface EligiblityResponse {
    data: EligiblityData | PartialEligiblityData | null;
    error: unknown;
    logInfo: LogInfo;
    notications: Notification[];
    status: string;
}

const eligibility = async (): Promise<AxiosResponse<EligiblityResponse>> => {
    const url = getUrl("getIntegratedEligibility");

    return await axios.get(url, { withCredentials: true });
};

export default eligibility;
