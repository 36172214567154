import { cloneDeep as _cloneDeep } from "lodash";

import {
    SET_SPOUSE,
    SET_SPOUSE_PROJECTIONS_MAP,
    SET_SPOUSE_FIRST_NAME,
    SET_SPOUSE_SALARY,
    SET_SPOUSE_GENDER,
    SET_SPOUSE_AGE,
    SET_SPOUSE_DATE_OF_BIRTH,
    SET_SPOUSE_RETIREMENT_AGE,
    DELETE_SPOUSE,
    UPDATE_SPOUSE_INCOME_GOAL,
    UPDATE_SPOUSE_RETIREMENT_ASSETS,
    UPDATE_SPOUSE_EXTERNAL_ASSETS
} from "./spouseActionTypes";

export const setSpouse = (inUser) => {
    // All non-migrated accounts have no spouse.   For migrated accounts, only some have a spouse.
    // for those that don't we'll set a null value for the spouse.
    if (!inUser) {
        return {
            type: SET_SPOUSE,
            payload: null
        };
    }
    const outUser = _cloneDeep(inUser);
    return {
        type: SET_SPOUSE,
        payload: outUser
    };
};

export const setSpouseProjectionsMap = (map) => {
    return {
        type: SET_SPOUSE_PROJECTIONS_MAP,
        payload: map
    };
};

export const setSpouseFirstName = (name) => {
    return {
        type: SET_SPOUSE_FIRST_NAME,
        payload: name
    };
};

export const setSpouseSalary = (salary) => {
    return {
        type: SET_SPOUSE_SALARY,
        payload: salary
    };
};

export const setSpouseGender = (gender) => {
    return {
        type: SET_SPOUSE_GENDER,
        payload: gender
    };
};

export const setSpouseAge = (age) => {
    return {
        type: SET_SPOUSE_AGE,
        payload: age
    };
};

export const setSpouseDateOfBirth = (dob) => {
    return {
        type: SET_SPOUSE_DATE_OF_BIRTH,
        payload: dob
    };
};

export const setSpouseRetirementAge = (age) => {
    return {
        type: SET_SPOUSE_RETIREMENT_AGE,
        payload: age
    };
};

export const updateSpouseIncomeGoal = (payload) => {
    return {
        type: UPDATE_SPOUSE_INCOME_GOAL,
        payload
    };
};

export const deleteSpouse = () => {
    return {
        type: DELETE_SPOUSE
    };
};

export const updateSpouseRetirementAssets = (payload) => {
    return {
        type: UPDATE_SPOUSE_RETIREMENT_ASSETS,
        payload
    };
};

export const updateSpouseExternalAssets = (payload) => {
    return {
        type: UPDATE_SPOUSE_EXTERNAL_ASSETS,
        payload
    };
};
