/**
 * @name app.common.model.domain.PlanLimits
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Creates a defined object structure for the PlanLimits object type.
 */

/**
 * The PlanLimits object constructor.
 * @param config This object of properties you would like to base the plan off of. Note that only primitives are used.
 * @constructor
 */

"use strict";

class PlanLimits {
    constructor(config) {
        this.ageCatchupAllowed = null;
        this.ageCatchupMethodCode = null;

        if (config) {
            this.ageCatchupAllowed = config.ageCatchupAllowed;
            this.ageCatchupMethodCode = config.ageCatchupMethodCode;
        }
    }
}

export default PlanLimits;
