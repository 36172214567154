import React from "react";

import { dispatchAmplitude, AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { ObjectUtil, Print } from "gw-shared-components";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { useSelector, useDispatch } from "react-redux";

import { setShowConfirmModal } from "../../actions/confirmChanges/confirmChangesAction";
import { dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import Deferrals, { hasDeferralWarnings } from "../../business/deferralsLogic/deferrals";
import { equityMixChangesAllowed } from "../../business/investments";
import { getMaximizer, launchEquityMix } from "../../business/retirementIncome";
import { confirmDone } from "../../middleware/reviewChangesMiddleware";
import { reselectReviewLastChanges } from "../../selectors/reselectSelectors";

import { ConfirmEvents } from "./events/ConfirmChangesEvents";

const ConfirmChanges = ({ showConfirmModal }) => {
    const dispatch = useDispatch();
    const confirmModalId = useSelector((state) => state.reviewChanges.confirmModalId);
    const equityMixWarnings = useSelector((state) => state.investments.equityMixWarnings);
    const { priorRetirementAge, priorEquityMix } = useSelector(reselectReviewLastChanges);

    const equityMix = useSelector((state) => state.investments.equityMix);
    const retireAge = useSelector((state) => state.participant.retirementAge);
    const deferralList = useSelector((state) => state.participant.deferrals);
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const deferralSetupConfig = useSelector((state) => state.deferralSetupConfig);
    const activeDeferral = useSelector((state) => state.activeDeferral);
    const confirmationData = useSelector((state) => state.reviewChanges.confirmationData);
    const individualOptions = useSelector((state) => state.investments.individualOptions);
    const indivId = useSelector((state) => state.participant.individualId);
    const deferralSubmissionWarnings = useSelector(
        (state) => state.deferralSetupConfig.deferralSubmissionWarnings
    );
    const deferralWarnings = useSelector((state) => state.deferralSetupConfig.deferralWarnings);
    const planId = primaryPlan.id;
    const title = selectTranslations("title");

    const confirmChangesText = selectTranslations("confirmChanges");
    const { title: confirmModalTitle, contributionRate, to } = confirmChangesText;

    const app = selectTranslations("app");
    const maximizer = selectTranslations("maximizer");
    const reviewChanges = selectTranslations("reviewChanges");
    const effective = reviewChanges.effective;

    const printLbl = app.common.labels.print;
    const resetValues = (...args) => dispatch(confirmDone(...args));

    const titleReplace = {
        class: "confirm-title",
        content: `${title} - ${confirmModalTitle}`
    };

    const deferralClass = new Deferrals(
        deferralList,
        primaryPlan,
        deferralSetupConfig,
        activeDeferral
    );
    const showWarningsOnModal = () => {
        return false;
    };

    const renderEquityMixWarnings = (warnings) => {
        return warnings.map((warning) => {
            return (
                <p className="investment-warnings" key={warning.url}>
                    {warning.message}
                    <a href={warning.url}>{warning.urlMessage}</a>
                    {warning.message2}
                </p>
            );
        });
    };

    /**
     * Last changes is the prior state of baseline before service call for Review Changes
     * baseline has been updated by the time we get to this page.
     */

    /**
     * Compare new deferrals in participant.deferrals against baseline deferrals for changes
     * @param {*} i
     */

    const hasContributionRateChanges = () => {
        if (hasDeferralWarnings(deferralWarnings)) {
            return false;
        }
        const changed = deferralClass.getChangedDeferrals("full").length > 0;
        return changed;
    };

    const requestRA = () => {
        if (hasContributionRateChanges()) {
            return getRetirementAgeDelta() > 0 ? "andIncreaseRA" : "andDecreaseRA";
        } else {
            return getRetirementAgeDelta() > 0 ? "noChangeCRIncreaseRA" : "noChangeCRDecreaseRA";
        }
    };

    const requestCR = (isFirst, deferral) => {
        if (isFirst) {
            return deferral.value > deferral.priorValue ? "requestIncreaseCR" : "requestDecreaseCR";
        } else {
            return deferral.value > deferral.priorValue
                ? "requestIncreaseNext"
                : "requestDecreaseNext";
        }
    };

    const getDecimals = (value) => {
        if (value % 1) {
            return deferralClass.getDeferralDecimals();
        } else {
            return 0;
        }
    };

    const getRetirementAgeDelta = () => {
        if (ObjectUtil.isUndefinedOrNull(priorRetirementAge)) {
            return 0;
        }
        return Math.round(retireAge - priorRetirementAge);
    };

    const hasRetirementAgeChanges = () => {
        return getRetirementAgeDelta() !== 0;
    };

    const renderChangedDeferrals = (deferrals) => {
        return deferrals.map((deferral, id) => {
            const showDecimals = deferral.value % 1 !== 0;

            return (
                <span
                    key={deferral.config.displayName}
                    className="confirm-msg-part confirm-contribution-msg-part"
                >
                    {confirmChangesText[requestCR(id === 0, deferral)]}
                    <span>{deferral.config.displayName}</span>
                    {` ${contributionRate}`}
                    {to}
                    <span className="text-success">
                        <strong className="changed-deferral-confirm">
                            <NumericFormat
                                fixedDecimalScale={showDecimals}
                                decimalScale={getDecimals(deferral.value)}
                                value={deferral.value}
                                allowNegative={false}
                                prefix={deferralClass.getDeferralPrefix(deferral)}
                                suffix={deferralClass.getDeferralSuffix(deferral)}
                                displayType={"text"}
                                data-testid={`updated-contribution-${deferral.deferralTypeCode}`}
                            />
                        </strong>
                    </span>{" "}
                    ({effective}{" "}
                    <span data-testid={`deferral-effective-date-${deferral.deferralTypeCode}`}>
                        {deferralClass.getFormattedEffectiveDate(deferral)}
                    </span>
                    )
                    {id === deferrals.length - 1 && !hasRetirementAgeChanges() ? (
                        <span>. </span>
                    ) : (
                        " "
                    )}
                </span>
            );
        });
    };

    const getPayload = () => {
        return {
            deferrals: deferralClass.getChangedDeferrals("full"),
            retireAge: retireAge,
            hasInvestmentChange: showContinueButton()
        };
    };

    const getWasEnrolledInMaximizer = () => {
        return showConfirmModal ? getMaximizer(primaryPlan).wasEnrolled : false;
    };

    const renderConfirmationData = (confirmations) => {
        return confirmations
            ? confirmations.map((confirmation) => {
                  return (
                      <div key={confirmation.planName}>
                          {hasContributionRateChanges() ? (
                              <div className="change-summary-row">
                                  <div className="change-summary-label">
                                      {confirmChangesText.confirmNum}:
                                  </div>
                                  <div
                                      className="change-summary-item"
                                      data-testid="confirmation-number"
                                  >
                                      {confirmation.confirmationNumber}
                                  </div>
                              </div>
                          ) : null}
                          {getWasEnrolledInMaximizer() ? (
                              <div className="change-summary-row">
                                  <div className="change-summary-label">
                                      {maximizer.leavingMaximizer.reviewConfirmationModal.label}:
                                  </div>
                                  <div className="change-summary-item">
                                      {maximizer.leavingMaximizer.reviewConfirmationModal.value}
                                  </div>
                              </div>
                          ) : null}
                          <div className="change-summary-row">
                              <div className="change-summary-label">
                                  {confirmChangesText.affectedPlan}:
                              </div>
                              <div className="change-summary-item" data-testid="plan-name">
                                  {confirmation.planName}
                              </div>
                          </div>
                      </div>
                  );
              })
            : null;
    };

    const getEquityMixDelta = () => {
        return Math.round(equityMix - priorEquityMix);
    };

    const hasEquityMixChanges = () => {
        const canChangeEquityMix = equityMixChangesAllowed(individualOptions);
        return canChangeEquityMix && getEquityMixDelta() !== 0;
    };

    const hasEquityMixWarnings = () => {
        return ObjectUtil.isUndefinedOrNull(equityMixWarnings) ||
            (equityMixWarnings && equityMixWarnings.length === 0)
            ? false
            : equityMixWarnings.length > 0;
    };

    const showContinueButton = () => {
        return hasEquityMixChanges() && !hasEquityMixWarnings();
    };

    // InvestmentMix renamed to EquityMix
    const goToEquityMix = () => {
        dispatch(dispatchEventBus(ConfirmEvents.DONE_CLICK, this, getPayload()));
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: ConfirmEvents.DONE_CLICK,
            payload: {
                ...getPayload()
            }
        });

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: "continue_to_equity_mix_button",
            payload: {
                ...getPayload()
            }
        });

        if (
            !ObjectUtil.isUndefinedOrNull(
                launchEquityMix(indivId, planId, equityMix, individualOptions)
            )
        ) {
            dispatch(setShowConfirmModal(false));
            launchEquityMix(indivId, planId, equityMix, individualOptions);
        }
    };

    const handleConfirmationDone = (e) => {
        e.preventDefault();
        dispatch(dispatchEventBus(ConfirmEvents.DONE_CLICK, this, getPayload()));
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: ConfirmEvents.DONE_CLICK,
            payload: {
                ...getPayload()
            }
        });
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: "confirmation_done_button",
            payload: {
                ...getPayload()
            }
        });
        resetValues(
            indivId,
            primaryPlan,
            deferralClass.resetDeferralPriorValues(),
            deferralWarnings
        );
    };

    return (
        showConfirmModal && (
            <Modal dialogClassName="confirm-changes-container" show={showConfirmModal}>
                <div id={confirmModalId} data-testid="confirm-changes">
                    <div className="change-summary-dialog">
                        <div className="change-summary-container">
                            <div className="change-summary-middle-container">
                                <Modal.Header className="modal-header">
                                    <Modal.Title
                                        className="modal-title confirm-title"
                                        id={`${confirmModalTitle}Label`}
                                        data-testid="confirm-modal-title"
                                    >
                                        {confirmModalTitle}
                                    </Modal.Title>
                                    <div className="submission-warnings">
                                        {showWarningsOnModal() ? (
                                            <div className="alert alert-warning">
                                                {renderEquityMixWarnings(equityMixWarnings)}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="actions">
                                        <Print
                                            text={printLbl}
                                            printElementClass={"change-summary-middle-container"}
                                            additionalClasses={"printAdditionalClass"}
                                            sideEffects={null}
                                            replace={titleReplace}
                                        />
                                    </div>
                                    <div className="with-success-check">
                                        <i className="em-checkbox-icon" />
                                        {!deferralClass.getHasDeferralWarnings(
                                            deferralWarnings,
                                            deferralSubmissionWarnings
                                        ) ? (
                                            <span>
                                                {renderChangedDeferrals(
                                                    deferralClass.getChangedDeferrals("full")
                                                )}
                                            </span>
                                        ) : null}
                                        {hasRetirementAgeChanges() ? (
                                            <span className="confirm-msg-part confirm-retirement-msg-part">
                                                {confirmChangesText[requestRA()]}
                                                <span className="text-success">
                                                    <strong>{retireAge}</strong>
                                                </span>
                                                .
                                            </span>
                                        ) : null}
                                    </div>
                                </Modal.Header>

                                <Modal.Body className="change-summary-body">
                                    <div className="change-summary-content">
                                        {renderConfirmationData(confirmationData)}
                                    </div>
                                    <div className="disclaimer">
                                        {hasContributionRateChanges() ? (
                                            <small
                                                className="confirm-note"
                                                data-testid="confirm-note"
                                            >
                                                {confirmChangesText.confirmNote}
                                            </small>
                                        ) : null}
                                    </div>
                                </Modal.Body>
                            </div>
                            <Modal.Footer className="modal-footer">
                                <div className="footer-content">
                                    {!showContinueButton() ? (
                                        <div className="done-btn-container">
                                            <button
                                                type="button"
                                                className="done-btn"
                                                onClick={handleConfirmationDone}
                                            >
                                                {app.common.labels.done}
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="continue-btn-container">
                                            <button
                                                type="button"
                                                className="continue-btn"
                                                onClick={goToEquityMix}
                                            >
                                                {app.common.labels.continue}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </Modal.Footer>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    );
};
ConfirmChanges.propTypes = {
    showConfirmModal: PropTypes.bool
};
export default ConfirmChanges;
