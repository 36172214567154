import axios from "axios";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { ObjectUtil } from "gw-shared-components";

import { ResponseFaultFactory } from "../factories/FaultFactory";
import { executeGet } from "../utils/apiUtils";
import { getUrl } from "../utils/urlUtils";

const logger = ExternalLogger.getInstance("DeferralService");

export const save = (deferrals, planId) => {
    logger.info("save( value = {0}, type = {1}, planId = {2} )", [
        deferrals[0].value,
        deferrals[0].deferralTypeCode,
        planId
    ]);

    /**
     * Service success handler and promise resolver.
     * @param result
     */
    const saveDeferralServiceResult = (result) => {
        if (
            ObjectUtil.isUndefinedOrNull(result) ||
            ObjectUtil.isUndefinedOrNull(result.data.data) ||
            ObjectUtil.isUndefinedOrNull(result.data.data.deferralsSaveResponse)
        ) {
            const code = result ? result.data.error.code : null;
            logger.error("saveDeferralServiceResult( Bad response data. )");
            return Promise.reject(ResponseFaultFactory.create(null, "POST", null, code));
        } else if (
            result.data.data.deferralsSaveResponse.statusCode !== "201" &&
            result.data.data.deferralsSaveResponse.statusCode !== "200"
        ) {
            logger.info("saveDeferralServiceResult( Bad response -- data is null or undefined. )");
            return Promise.reject(
                ResponseFaultFactory.create(null, "POST", null, result.data.error.code)
            );
        } else {
            logger.info("saveDeferralServiceResult()");
            return result.data.data.deferralsSaveResponse;
        }
    };

    /**
     * Service fault handler and promise rejection.
     * @param fault
     */
    const saveDeferralServiceFault = (fault) => {
        logger.error("saveDeferralServiceFault( fault = {0} )", [fault.message]);
        return Promise.reject(fault);
    };

    const saveDeferralURL = (params) => {
        return StringUtil.supplant(getUrl("saveDeferral"), params);
    };

    const getParams = (deferrals) => {
        const savingsHeader = [];
        deferrals[0].deferralTypeCode.forEach((element, index) => {
            savingsHeader.push({
                type: element, // Describes the contribution type - "BEFORE","AFTER","ROTH","AGERTH" etc
                nycGoalFlag: null,
                amount: deferrals[0].value[index],
                amountType: deferrals[0].pctAmtCode,
                effectiveDate: deferrals[0].submissionDate
            });
        });
        return {
            participant: {
                contributions: savingsHeader
            }
        };
    };

    const endPointParams = {
        groupId: planId // the primary plan's ID is the group ID
    };
    const url = saveDeferralURL(endPointParams);
    const params = getParams(deferrals);

    return axios.post(url, params).then(saveDeferralServiceResult, saveDeferralServiceFault);
};

export const getdeferralforSave = () => {
    const getDeferralsResult = (result) => {
        if (ObjectUtil.isUndefinedOrNull(result) || ObjectUtil.isUndefinedOrNull(result.data)) {
            logger.error("getDeferralsResult( Bad response data. )");
            throw new Error("There is no response data.");
        } else {
            logger.info("getDeferralsResult()");
            return result;
        }
    };
    const url = getUrl("getDeferrals");
    return executeGet(url, "getDeferralsFault").then(getDeferralsResult);
};

export const getGlobalSearchData = (plan) => {
    const getGlobalAPiResult = (result) => {
        if (ObjectUtil.isUndefinedOrNull(result) || ObjectUtil.isUndefinedOrNull(result.data)) {
            logger.error("getIndividualIdGroupAccountId()( Bad response data. )");
            throw new Error("There is no response data.");
        } else {
            logger.info("getIndividualIdGroupAccountId()");
            return result;
        }
    };

    const params = {
        personId: plan.personId,
        accountId: plan.accountId // the primary plan's ID is the group ID
    };
    const getGlobalApiURL = (params) => {
        return StringUtil.supplant(getUrl("getGlobalApi"), params);
    };
    let url = getGlobalApiURL(params);
    url = url.replace('"', "");
    url = url.replace('"', "");

    return executeGet(url, "getGlobalAPIFault").then(getGlobalAPiResult);
};

export const get = (planId) => {
    const geDeferralsbyGroupIdResult = (result) => {
        if (ObjectUtil.isUndefinedOrNull(result) || ObjectUtil.isUndefinedOrNull(result.data)) {
            logger.error("getDeferralsByGroupID( Bad response data. )");
            throw new Error("There is no response data.");
        } else {
            logger.info("getDeferralsByGroupID()");
            return result;
        }
    };
    const getDeferralURL = (params) => {
        return StringUtil.supplant(getUrl("getDeferralsByGroupID"), params);
    };
    const endPointParams = {
        groupId: planId // the primary plan's ID is the group ID
    };
    const url = getDeferralURL(endPointParams);
    return executeGet(url, "getDeferralsByGroupIDFault").then(geDeferralsbyGroupIdResult);
};
