import React from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getShowNextSteps } from "../../business/nextStepLogic/nextStep";
import { ACCESSIBILITY } from "../../constants/AccessibilityPaycheck";
import { reselectTradeOffMessages } from "../../selectors/reselectSelectors";
import { hasTradeOffs } from "../tradeoffMessaging/components/tradeoffHelpers";
import { TradeOffMessaging } from "../tradeoffMessaging/TradeOffMessaging";

import { CompanyMatch, DefaultMessage, MessageContent } from "./components/index";

const NextStep = ({ expandPaycheckOpen }) => {
    const baseline = useSelector((state) => state.baseline);
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const isTerminated = primaryPlan.terminated;
    const nextSteps = useSelector((state) => state.nextSteps);
    const showTradeOff = useSelector((state) => state.reviewChanges.showTradeOff);

    const showNextSteps = getShowNextSteps(
        nextSteps.settings,
        baseline.deferrals,
        isTerminated,
        showTradeOff,
        primaryPlan
    );

    // show logic from TradeOffMessaging
    const { future, today } = useSelector(reselectTradeOffMessages);

    const hasFutureTradeOffs = hasTradeOffs(future);
    const hasTodayTradeOffs = hasTradeOffs(today);
    const isTradeOffPresent = hasFutureTradeOffs || hasTodayTradeOffs || false;

    return (
        <div className="rivd-liat-bns" id={ACCESSIBILITY.MESSAGE_UPDATE_ID}>
            {showTradeOff && isTradeOffPresent ? (
                <div>
                    <TradeOffMessaging />
                </div>
            ) : (
                <div>
                    <div id="best-next-step">
                        <MessageContent showNextSteps={showNextSteps} />
                        <CompanyMatch expandPaycheckOpen={expandPaycheckOpen} />
                        <DefaultMessage showNextSteps={showNextSteps} />
                    </div>
                </div>
            )}
        </div>
    );
};

NextStep.propTypes = {
    expandPaycheckOpen: PropTypes.bool.isRequired
};

export default NextStep;
