/**
 * Finds the active deferral from the primaryPlan deferrals array.
 * The active deferral has a primary flag {active} set to true.
 *
 * If there are no deferrals set as primary then we default to the first item in the list.
 * If there are no items in the list then return null.
 */
export const findActiveDeferral = (deferrals) => {
    if (Array.isArray(deferrals)) {
        const deferral = deferrals.find(({ active }) => active === true);
        if (deferral) {
            return deferral;
        } else if (deferrals.length > 0) {
            return deferrals[0];
        }
    }
    return null;
};

export const areShallowEqual = (a, b) => {
    for (const key in a) {
        if (!(key in b) || a[key] !== b[key]) {
            return false;
        }
    }
    for (const key in b) {
        if (!(key in a) || a[key] !== b[key]) {
            return false;
        }
    }
    return true;
};
