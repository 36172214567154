"use strict";
/**
 * @name app.common.model.domain.DeferralConstants
 * @date 1/26/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Definition of the DeferralConstants domain model object. This object contains definitions for the enumerable
 * properties of the Deferral object.
 */

const DeferralConstants = {
    moneyTypes: {
        DOLLARS: "dollars",
        PERCENT: "percent"
    },
    statusCodes: {
        CURRENT: "C",
        ELECTIVE: "E",
        FUTURE: "F"
    },
    AMT: "AMT",
    PCT: "PCT",
    typeCodes: {
        BEFORE: "BEFORE",
        ROTH: "ROTH",
        AFTER: "AFTER",
        HSA: "HSA"
    },
    category: {
        FULL: "full",
        MODEL: "model",
        GRANDFATHERED: "grandfathered"
    }
};

export default DeferralConstants;
