import { reject as _reject } from "lodash";

import { SET_HDIC_ERROR } from "../../actions/howDoICompare/howDoICompareTypes";
import { SET_INIT_DATA } from "../../actions/initData";
import NAVIATION_TABS from "../../constants/NavigationTabs";
import TABS from "../../constants/Tabs";
import {
    canShowHealthCareView,
    canShowHDICView,
    getDisplayOtherAssets,
    getDisplayGovernmentBenefits,
    canShowPreRetirementView,
    canShowWhenCanIRetire,
    getDisplayHsa,
    canShowMaximizer,
    canShowIncomeGap
} from "../../selectors/featureFlagSelectors";
import { findPrimaryPlan } from "../../utils/planUtils";

const initialState = {
    tabs: [
        NAVIATION_TABS.RETIREMENT_INCOME,
        NAVIATION_TABS.HEALTHCARE_COSTS,
        NAVIATION_TABS.HOW_DO_I_COMPARE,
        NAVIATION_TABS.PRE_RETIREMENT,
        NAVIATION_TABS.WHEN_CAN_I_RETIRE,
        NAVIATION_TABS.MAXIMIZER,
        NAVIATION_TABS.HSA,
        NAVIATION_TABS.SOCIAL_SECURITY,
        NAVIATION_TABS.OTHER_ASSETS,
        NAVIATION_TABS.INCOME_GAP
    ]
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_INIT_DATA: {
            const primaryPlan = findPrimaryPlan(payload.user.plans);

            const navigation = [NAVIATION_TABS.RETIREMENT_INCOME];

            // healthcare view
            if (canShowHealthCareView(payload.user, primaryPlan)) {
                navigation.push(NAVIATION_TABS.HEALTHCARE_COSTS);
            }

            // how do I compare
            if (canShowHDICView(primaryPlan)) {
                navigation.push(NAVIATION_TABS.HOW_DO_I_COMPARE);
            }

            if (canShowPreRetirementView(payload.user, primaryPlan)) {
                navigation.push(NAVIATION_TABS.PRE_RETIREMENT);
            }

            if (canShowWhenCanIRetire(payload.user, primaryPlan)) {
                navigation.push(NAVIATION_TABS.WHEN_CAN_I_RETIRE);
            }

            // maximizer
            if (canShowMaximizer(primaryPlan)) {
                navigation.push(NAVIATION_TABS.MAXIMIZER);
            }

            // hsa enabled
            if (getDisplayHsa(payload.user.plans)) {
                navigation.push(NAVIATION_TABS.HSA);
            }

            // social security enabled
            if (getDisplayGovernmentBenefits(primaryPlan)) {
                navigation.push(NAVIATION_TABS.SOCIAL_SECURITY);
            }

            // other assets enabled
            if (getDisplayOtherAssets(payload.user, primaryPlan)) {
                navigation.push(NAVIATION_TABS.OTHER_ASSETS);
            }

            // income gap hidden for terminated ppt - PART-32396
            if (canShowIncomeGap(primaryPlan)) {
                navigation.push(NAVIATION_TABS.INCOME_GAP);
            }

            return { ...state, tabs: [...navigation] };
        }

        case SET_HDIC_ERROR:
            return {
                ...state,
                tabs: _reject(state.tabs, (tab) => {
                    return tab.title === TABS.HOW_DO_I_COMPARE;
                })
            };

        default:
            return state;
    }
};
