import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { useSelector } from "react-redux";

import DeferralConstants from "../../../constants/DeferralConstants";

import { getEnrolledInMaximizer } from "./tradeoffHelpers";

/** AFTER deferral not valid for maximizer logic */
const MaximizerTradeOff = () => {
    const tradeOff = selectTranslations("maximizer")?.leavingMaximizer?.tradeOff;
    const deferralSetupConfig = useSelector((state) => state.deferralSetupConfig);
    let changedDeferrals = [];
    changedDeferrals = deferralSetupConfig.changedDeferralsFull.concat(
        deferralSetupConfig.changedDeferralsGrandfathered,
        deferralSetupConfig.changedDeferralsModel
    );
    const primaryPlan = useSelector((state) => state.primaryPlan);
    let getShouldShowMaximizerTradeoff = getEnrolledInMaximizer(primaryPlan);
    if (
        changedDeferrals.length === 1 &&
        changedDeferrals[0].deferralTypeCode === DeferralConstants.typeCodes.AFTER
    ) {
        getShouldShowMaximizerTradeoff = false;
    }

    return getShouldShowMaximizerTradeoff ? (
        <h4 className="maximizer-tradeoff-message" data-testid="maximizer-trade-off">
            {tradeOff}
        </h4>
    ) : null;
};

export { MaximizerTradeOff };
