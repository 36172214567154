import React from "react";

import ReferenceData from "../../../../constants/ReferenceData";
import IQuickViewAccount from "../../types";
import QuickViewLink from "../QuickViewLink";

interface RenderBeneficiariesProps {
    planDetails: IQuickViewAccount | null;
    translations: {
        planDetails: {
            beneficiaries: { bottomPrimary: string; title: string };
        };
    };
}
const RenderBeneficiaries = ({ planDetails, translations }: RenderBeneficiariesProps) => {
    if (!planDetails || !translations) {
        return null;
    }

    const beneficiariesCount = planDetails.beneficiaries?.total || 0;

    if (beneficiariesCount !== 0) {
        return (
            <div className="quick-view-item d-flex flex-column">
                <QuickViewLink
                    link={ReferenceData.URL_HYPERLINK_BENEFICIARY}
                    planDetails={planDetails}
                    title={translations.planDetails.beneficiaries.title}
                />
                <p className="info">
                    {beneficiariesCount === 1
                        ? `${beneficiariesCount} ${translations.planDetails.beneficiaries.bottomPrimary} `
                        : beneficiariesCount}
                </p>
            </div>
        );
    }
    return null;
};

export default RenderBeneficiaries;
