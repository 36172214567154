/**
 *
 * @name AccountsAggregationConstants
 * @memberOf common.model.constants
 * @date 8/27/2018
 * @copyright 2018 Empower Retirement
 * @description Encapsulates constants for account aggregation.
 *
 */

"use strict";

const AccountsAggregationConstants = {
    INACTIVE: "INACTIVE",
    NEW: "NEW",
    ACTIVE: "ACTIVE"
};

export default AccountsAggregationConstants;
