import { useQuery } from "@tanstack/react-query";

import { getAccountCustomization } from "../../services";
import { QUERY_KEYS } from "../constants";

const useAccountCustomization = () => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_ACCOUNT_CUSTOMIZATION, globalThis.accu],
        queryFn: () => getAccountCustomization({ accu: globalThis.accu }),
        enabled: !!globalThis.accu
    });
};

export default useAccountCustomization;
