import axios from "axios";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";

import RequestFactory from "../factories/RequestFactory";
import { executeRequest } from "../utils/apiUtils";
import { getUrl } from "../utils/urlUtils.js";

const logger = ExternalLogger.getInstance("authorizedDevices");
const DEFAULT_PARAMS = {};
/** Reference ticket PART-38273 for services */

export const getAuthorizedDevices = () => {
    const getAuthorizedDevicesResult = function (response) {
        if (response && response.data) {
            return response.data;
        } else {
            return { data: [], error: null, status: "SUCCESSFUL" };
        }
    };

    const getAuthorizedDevicesFault = function (fault) {
        let errorMsg = "";
        if (fault.error) {
            fault.message = fault.error.message || fault.error.userMessage;
            errorMsg = fault.message;
            logger.error("getAuthorizedDevicesFault( fault error code = {0}, error message {1} )", [
                fault.error.code,
                fault.message
            ]);
        }

        return {
            data: [
                {
                    created: "",
                    deviceCustomName: "ERROR",
                    deviceFingerPrintHash: "" + errorMsg,
                    lastSignIn: "",
                    userAgent: ""
                }
            ],
            error: fault.error,
            status: "FAIL"
        };
    };

    const url = getUrl("getAuthorizedDevices");
    const method = RequestFactory.HTTP_METHOD.GET;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED;
    const request = RequestFactory.create(url, null, method, contentType);
    delete request.headers.samlToken;

    return axios(request).then(getAuthorizedDevicesResult, getAuthorizedDevicesFault);
};

export const saveAuthorizedDeviceChanges = async (params = DEFAULT_PARAMS) => {
    const url = getUrl("saveAuthorizedDeviceChanges");
    const method = RequestFactory.HTTP_METHOD.POST;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const request = RequestFactory.create(url, params, method, contentType);

    const response = await executeRequest(request, "saveAuthorizedDeviceChangesFault");
    return response.data;
};
