import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { ObjectUtil } from "gw-shared-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const MessageContent = ({ showNextSteps }) => {
    const appTranslations = selectTranslations("app");
    const bestNextStepTranslations = selectTranslations("bestNextStep");

    const nextSteps = useSelector((state) => state.nextSteps);

    const showBestNextStepUrl = !ObjectUtil.isEmptyString(nextSteps.nextStepUrl);
    const nextStepUrl = nextSteps.nextStepUrl;
    const bestNextStepMessage = nextSteps.formattedMessage;

    const applySpan = (bnsText) => {
        const bnsTextArr = bnsText.split(" ");
        const re = /(?:\$)(\d+)|(\d+)(?:%)/;

        return bnsTextArr.map((ele) => {
            const matches = re.exec(ele);

            if (matches && matches.length) {
                const newElement = <span className="bns-message-value">{`${matches[0]} `}</span>;
                return <span key={ele}>{newElement}</span>;
            } else {
                return `${ele} `;
            }
        });
    };

    return showNextSteps ? (
        <div className="best-next-step-message-content">
            <h3 className="best-next-step-message-title">
                <span className="best-next-step-icon" />
                {bestNextStepTranslations.header}
            </h3>
            <ul className="best-next-step-messages trade-offs list-unstyled">
                <li className="best-next-step-item">
                    <i className="fa fa-solid fa-circle-check gray-color-400" />
                    <span className="best-next-step-text">{applySpan(bestNextStepMessage)}</span>
                    {showBestNextStepUrl ? (
                        <a className="best-next-step-link" href={nextStepUrl}>
                            {appTranslations.common.labels.clickhere}
                        </a>
                    ) : null}
                </li>
            </ul>
        </div>
    ) : null;
};
MessageContent.propTypes = {
    showNextSteps: PropTypes.bool
};
export { MessageContent };
