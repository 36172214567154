import { SET_INIT_DATA } from "../../actions/initData";
import {
    SET_EQUITY_MIX,
    SET_INVESTMENTS_MIX_WARNINGS
} from "../../actions/investments/investmentActionTypes";
import { findPrimaryPlan } from "../../utils/planUtils";

const initialState = {
    individualOptions: {
        disableInvestmentMix: false,
        portfolioManagementAllowed: false,
        notAllMoneyTypeGroupingsFlow: false
    },
    equityMix: 0,
    equityMixWarnings: []
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_INIT_DATA: {
            const primaryPlan = findPrimaryPlan(payload.user.plans);
            return {
                ...state,
                individualOptions: { ...payload.data.investmentMixOptions },
                equityMix: primaryPlan.equityPercent * 100
            };
        }

        case SET_EQUITY_MIX:
            return { ...state, equityMix: payload };
        case SET_INVESTMENTS_MIX_WARNINGS:
            return { ...state, equityMixWarnings: payload };
        default:
            return state;
    }
};
