import { redwoodAtom } from "../../atoms/atoms";
import { redwoodStore } from "../../store/redwoodStore";
import { publicServices } from "../publicServices";

/**
 * Loads the Redwood bundle by updating the source of the bundle script element and initializing the PersonalCapital class.
 */
const loadRedwoodBundle = ({
    integratedJS,
    userGuid,
    originUrl
}: {
    integratedJS: string;
    originUrl: string;
    userGuid: string;
}) => {
    const bundleScript = document.getElementById("pcap-bundle") as HTMLScriptElement;
    if (bundleScript) {
        bundleScript.src = integratedJS;

        publicServices();

        bundleScript.onload = () => {
            globalThis.pcapClass = new PersonalCapital({ originUrl, userGuid });

            globalThis.pcapScriptLoaded = true;

            globalThis.pcapInitComponents = true;
            redwoodStore.set(redwoodAtom, {
                pcapScriptLoaded: true,
                pcapClass: globalThis.pcapClass,
                pcapInitComponents: true
            });
        };
    }
};

export default loadRedwoodBundle;
