import { ACCOUNT_INTERFACE } from "../../../types/common/accounts";
import { AggregationLevel, FunnelAttributes } from "../../../types/common/funnelAttributes";
import { LinkedAccountBalance } from "../../../types/common/participant";

import hasBankOrCCAccount from "./hasBankOrCCAccount";
import hasExternalAccounts from "./hasExternalAccounts";

interface shouldShowWelcomeWidgetData {
    accounts: ACCOUNT_INTERFACE[] | undefined;
    funnelData: FunnelAttributes | undefined;
    hasSeenWelcomeWidget: boolean;
    linkedAccounts: LinkedAccountBalance[];
}

const shouldShowWelcomeWidget = ({
    accounts,
    linkedAccounts,
    funnelData,
    hasSeenWelcomeWidget
}: shouldShowWelcomeWidgetData) => {
    if (linkedAccounts?.length > 0 || !accounts || !funnelData) return false;
    const isZeroState = !hasBankOrCCAccount(accounts);
    const hasAnExternalAccounts = hasExternalAccounts(accounts);

    return (
        isZeroState &&
        !hasAnExternalAccounts &&
        !hasSeenWelcomeWidget &&
        funnelData.aggregationLevel === AggregationLevel.ALL
    );
};

export default shouldShowWelcomeWidget;
