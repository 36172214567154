/**
 * @name app.common.model.domain.PlanRules
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @description
 * Creates a defined object structure for the PlanRules object type.
 */

/**
 * The PlanRules object constructor.
 * @param config This object of properties you would like to base the plan off of. Note that only primitives are used.
 * @constructor
 */

"use strict";

class PlanRules {
    constructor(config) {
        this.allowSalaryReset = false;
        this.allowDeferral = null;
        this.allowMultipleDeferrals = false;
        this.allowPreRetireeView = false;
        this.allowWCIRView = false;
        this.deferralMessage = null;
        this.monthlyDeferralsEnabled = null;
        this.hdicEnabled = null;
        this.hvsEnabled = null;
        this.maximizer = null;
        this.otherAssetsEnabled = null;
        this.promptForAge = null;
        this.promptForSalary = null;
        this.showSalary = null;
        this.ssEnabled = null;
        this.specialCatchup = null;
        this.usePayrollEmployerMatch = true;
        this.feForecastEnabled = false;
        this.allowAddNewContributions = false;
        this.showLimitMsg = true;
        this.employerDirectedAllocationsOnly = false;
        this.allowAntiDiscount = false;

        if (config) {
            this.allowSalaryReset = config.allowSalaryReset;
            this.allowDeferral = config.allowDeferral;
            this.allowMultipleDeferrals = config.allowMultipleDeferrals;
            this.allowPreRetireeView = config.allowPreRetireeView;
            this.allowWCIRView = config.allowWCIRView;
            this.deferralMessage = config.deferralMessage;
            this.monthlyDeferralsEnabled = config.monthlyDeferralsEnabled;
            this.hdicEnabled = config.hdicEnabled;
            this.hvsEnabled = config.hvsEnabled;
            this.maximizer = config.maximizer;
            this.otherAssetsEnabled = config.otherAssetsEnabled;
            this.promptForAge = config.promptForAge;
            this.promptForSalary = config.promptForSalary;
            this.showSalary = config.showSalary;
            this.ssEnabled = config.ssEnabled;
            this.specialCatchup = config.specialCatchup;
            this.usePayrollEmployerMatch = config.usePayrollEmployerMatch;
            this.feForecastEnabled = config.feForecastEnabled;
            this.allowAddNewContributions = config.allowAddNewContributions;
            this.showLimitMsg = true;
            this.employerDirectedAllocationsOnly = config.employerDirectedAllocationsOnly;
            this.allowAntiDiscount = config.allowAntiDiscount;
        }
    }
}

export default PlanRules;
