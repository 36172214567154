import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import { ObjectUtil } from "gw-shared-components";

export const ResponseFaultFactory = {
    /**
     * Logger.
     */
    logger: ExternalLogger.getInstance("ResponseFaultFactory"),

    /**
     * Creates a generic request object that's typically the same for app server API endpoints.
     *
     * @param {string} url The service URL.
     * @param {string} method The HTTP method or action like "GET", "POST", "DELETE", etc.
     * @param {string} message The message describing the fault.
     * @param {string} code The error code
     * @returns {object}
     */
    create: function (url, method, message, code) {
        if (ObjectUtil.isUndefinedOrNull(url)) {
            url = "Unknown";
        }

        if (ObjectUtil.isUndefinedOrNull(method)) {
            method = "Unknown";
        }

        if (ObjectUtil.isUndefinedOrNull(message)) {
            message =
                "An error has occurred. Please contact a Participant Services Representative if the problem persists.";
        }

        return {
            url: url,
            method: method,
            message: message,
            code: code,
            genericError: true
        };
    }
};
