import { useEffect } from "react";

import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { getLastArrayItem } from "gw-shared-components";
import { useLocation } from "react-router-dom";

import {
    RETIREMENT_INCOME_ROUTE,
    HEALTHCARE_COSTS_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    HSA_ROUTE,
    INCOME_GAP_ROUTE,
    MAXIMIZER_ROUTE,
    OTHER_ASSETS_ROUTE,
    SOCIAL_SECURITY_ROUTE,
    PRE_RETIREMENT_ROUTE
} from "../../../routes";
import { dispatchAmplitude } from "../../actions/shared/sharedActionCreators";
import EventBusEvents from "../../events/eventBusEvents";
import { useDispatch } from "../use-redux-actions";

const TRACKED_AMPLITUDE_ROUTES = [
    RETIREMENT_INCOME_ROUTE,
    HEALTHCARE_COSTS_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    HSA_ROUTE,
    INCOME_GAP_ROUTE,
    MAXIMIZER_ROUTE,
    OTHER_ASSETS_ROUTE,
    SOCIAL_SECURITY_ROUTE,
    PRE_RETIREMENT_ROUTE
];

export const useAmplitudePageViews = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (TRACKED_AMPLITUDE_ROUTES.includes(location.pathname)) {
            const slug = getLastArrayItem(location.pathname.split("/"));
            dispatch(
                dispatchAmplitude({
                    event_type: AMPLITUDE_EVENTS.SELECT_VIEW,
                    event_properties: {
                        selection: `${EventBusEvents.LIAT.PAGE_VIEW}.${slug}`
                    }
                })
            );
        }
    }, [dispatch, location]);
};
