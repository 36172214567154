import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { isUndefinedOrNull } from "gw-shared-components";
import { filter as _filter } from "lodash";

import { updateProjectedIncome } from "../actions/projectedIncome/projectedIncomeActions";
import { setCurrentFault } from "../actions/shared/sharedActionCreators";
import {
    saveSocialSecurityUpdateSuccess,
    saveSocialSecurityUpdateFail,
    setParticipantUpdateDone,
    setSpouseUpdateDone
} from "../actions/socialSecurity/socialSecurityActions";
import {
    SET_MIGRATED_SOCIAL_SECURITY,
    SET_SOCIAL_SECURITY
} from "../actions/socialSecurity/socialSecurityActionTypes";
import ReferenceData from "../constants/ReferenceData";
import {
    saveUpdate,
    saveSocialSecurityChangeService,
    getIntegratedSS
} from "../services/socialSecurityService";
import { isEmulator } from "../utils/paeUtils";

const getErrorTranslations = () => {
    return selectTranslations("errors");
};

export const updateSSStartAge = (ssAge, retireAge) => {
    return (dispatch, getState) => {
        const state = getState();

        const { socialSecurityUser, socialSecuritySpouse } = state.socialSecurity;
        const spouse = state.spouse;
        let ssAtRetirement = socialSecurityUser.atRetirement;
        if (socialSecurityUser.atRetirement) {
            if (
                retireAge < ReferenceData.MIN_SOCIALSECURITY_STARTAGE ||
                retireAge > ReferenceData.MAX_SOCIALSECURITY_LATEST_STARTAGE
            ) {
                ssAtRetirement = false;
            }
        }

        const payload = {
            socialSecurityUser: {
                ...socialSecurityUser,
                startReceiveAge: ssAge,
                atRetirement: ssAtRetirement
            },
            hasSpouse: spouse.exists
        };

        if (spouse.exists) {
            payload.socialSecuritySpouse = {
                ...socialSecuritySpouse
            };
        }

        dispatch(saveSocialSecurityChange(payload));
    };
};

export const saveSocialSecurityUpdate = (payload) => {
    return (dispatch, getState) => {
        const state = getState();

        const investorId = state.participant.investorId;
        return saveUpdate(payload, investorId, dispatch).then(
            (response) => {
                let socialSecuritySpouse, socialSecurityPerson;

                if (!isUndefinedOrNull(response)) {
                    socialSecurityPerson = response.socialSecurityPerson.socialSecurity;
                    socialSecuritySpouse = response.socialSecuritySpouse
                        ? response.socialSecuritySpouse.socialSecurity
                        : null;
                }

                dispatch({
                    type: SET_SOCIAL_SECURITY,
                    payload: { socialSecuritySpouse, socialSecurityPerson }
                });
                dispatch(saveSocialSecurityUpdateSuccess());
                dispatch(updateProjectedIncome());
            },
            (fault) => {
                const translations = getErrorTranslations();

                fault.message = translations.defaultErrorMessage;

                dispatch(setCurrentFault(fault));
                dispatch(saveSocialSecurityUpdateFail());
            }
        );
    };
};

export const saveIndividualSocialSecurityChange = (payload, type, dispatch) => {
    return saveSocialSecurityChangeService(payload)
        .then((response) => {
            if (type === "user") {
                dispatch(setParticipantUpdateDone());
            } else if (type === "spouse") {
                dispatch(setSpouseUpdateDone());
            }
            return response;
        })
        .catch((error) => {
            dispatch(setCurrentFault(error));
            dispatch(saveSocialSecurityUpdateFail());
        });
};

export const saveSocialSecurityChange = (payload) => {
    return (dispatch) => {
        const isPAEUser = isEmulator();

        dispatch({ type: "SAVE_SOCIAL_SECURITY_CHANGE", payload });
        dispatch(updateProjectedIncome());

        if (!isPAEUser) {
            saveIndividualSocialSecurityChange(payload.socialSecurityUser, "user", dispatch);
            if (payload.hasSpouse) {
                saveIndividualSocialSecurityChange(
                    payload.socialSecuritySpouse,
                    "spouse",
                    dispatch
                );
            }
        } else {
            dispatch(setParticipantUpdateDone());
            if (payload.hasSpouse) {
                dispatch(setSpouseUpdateDone());
            }
        }
    };
};

export const loadIntegratedSocialSecurity = () => {
    return (dispatch, getState) => {
        const state = getState();
        const { spouse, participant } = state;

        return getIntegratedSS()
            .then(async (res) => {
                if (res.spData) {
                    const ssList = [];
                    const user = _filter(res.spData, (item) => {
                        return Number(item.pcapPersonId) === Number(participant.pcapPersonId);
                    });

                    const userObj = {
                        retirementAge: participant.retirementAge,
                        age: participant.age,
                        ssInfo: user[0]
                    };

                    ssList.push(userObj);

                    if (spouse.exists) {
                        await getIntegratedSS()
                            .then((res) => {
                                if (res.spData) {
                                    const spouseItem = _filter(res.spData, (item) => {
                                        return (
                                            Number(item.pcapPersonId) ===
                                            Number(spouse.pcapPersonId)
                                        );
                                    });

                                    const spouseObj = {
                                        retirementAge: spouse.retirementAge,
                                        age: spouse.age,
                                        ssInfo: spouseItem[0]
                                    };

                                    ssList.push(spouseObj);

                                    dispatch({
                                        type: SET_MIGRATED_SOCIAL_SECURITY,
                                        payload: {
                                            users: ssList
                                        }
                                    });
                                }
                            })
                            .catch((err) => {
                                return err;
                            });
                    } else {
                        dispatch({
                            type: SET_MIGRATED_SOCIAL_SECURITY,
                            payload: {
                                users: ssList
                            }
                        });
                    }
                }
            })
            .catch((err) => {
                return err;
            });
    };
};
