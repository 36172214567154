import * as Sentry from "@sentry/react";
import {
    userLanguagePreference,
    pageLanguageSetting
} from "core-ui/client/react/core/utils/browserLocaleUtils";

import eventBus from "../../utils/setEventBus";
import { isLocalhost } from "../utils/urlUtils";

const setSentryConfig = () => {
    if (!isLocalhost) {
        const groupId = globalThis.integratedEligibility.groupDetails.GroupId;
        const individualId = globalThis.integratedEligibility.groupDetails.IndividualId;

        // Should we have this conditional and/or better way to handle this?
        if (groupId && individualId) {
            Sentry.setUser({
                id: groupId + "~" + individualId
            });
            Sentry.setTag("ga_id", groupId);
            Sentry.setTag("gc_id", groupId.split("-")[0]);
            Sentry.setTag("ind_id", individualId);
            Sentry.setTag("user_locale", userLanguagePreference());
            Sentry.setTag("page_locale", pageLanguageSetting());
        } else {
            console.warn(
                "setSentryConfig: No groupId or individualId found in integratedEligibility"
            );
        }
    }
};

// Initialize analytics services (Amplitude, Sentry)
const initAnalytics = () => {
    const userId = globalThis.integratedEligibility.userGuid;
    // AMPLITUDE
    // passing GUID as the userId
    if (userId) {
        eventBus.amplitudeInit({ userId });
    } else {
        console.warn("initAnalytics: No userGuid found in integratedEligibility");
    }

    setSentryConfig();
};

export default initAnalytics;
