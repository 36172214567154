import React, { useState, useEffect, useCallback } from "react";

import { ErrorBoundary, withProfiler } from "@sentry/react";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { Loader, isArrayEmpty } from "gw-shared-components";
import { isNil as _isNil } from "lodash";
import PropTypes from "prop-types";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { tabChange } from "../../actions/goal/goalModalActionCreators";
import Constants from "../../constants/Constants";
import { mtrGoalModalEvents } from "../../events/mtrGoalModalEvents";
import { useIsEmulator } from "../../hooks/useEmulatorType";
import { saveGoalChanges } from "../../middleware/goalMiddleware";
import { reselectTermPeriod } from "../../selectors/reselectSelectors";
import {
    changeDateFormat,
    removeLeadingZeroFromDate,
    getAgeFromDateOfBirth
} from "../../utils/dateUtils";
import { showSalaryMessage } from "../../utils/goalUtils";
import FocusLock from "../accessibility/FocusLock";
import FallbackComponent from "../errorBoundary/Fallback";

import Footer from "./Footer";
import Header from "./Header";
import getSpouseChanges from "./helpers/get-spouse-changes/get-spouse-changes";
import SpouseBody from "./SpouseBody";
import Tabs from "./Tabs";
import UserBody from "./UserBody";
import { validateDOB } from "./validators";

const sentryFallback = <FallbackComponent />;

const MAX_SALARY = 10000000;

const DEFAULT_STATE_SPOUSE = {
    spouseSaved: false,
    spouseName: "",
    spouseSalary: 0,
    spouseDob: "",
    spouseGender: "",
    spouseAge: 0,
    spousePortion: 75,
    isPercentSpouse: true
};

const IntegratedGoalModal = ({
    additionalComp,
    callUpdateSalaryReminder,
    canSave,
    deleteSpouse,
    errorId,
    eventBus,
    handleClose,
    handleUserGoal,
    isEnabled,
    isEnrolled,
    isLoading,
    isPercent,
    portionValue,
    setCanSave,
    setDeleteSpouse,
    setErrorId,
    setSpouseGoal,
    showModal,
    spouseGoal,
    spouseDobError,
    spouseSaved,
    stateDesiredRetAge,
    stateIsPercent,
    statePortion,
    statePeriod,
    stateSalary,
    stateSpouse,
    stateParticipantDob,
    stateConfidenceLevel,
    totalGoal,
    userGoal,
    userDobError,
    userTabActive,
    dob,
    spouseDob
}) => {
    const dispatch = useDispatch();
    const labelTranslations = selectTranslations("paycheck")["goalModal"];
    const maRetirementGoals = selectTranslations("maRetirementGoals");
    const maCommon = selectTranslations("maCommon");
    const retirementIncome = selectTranslations("retirementIncome");
    const tooltipTranslations = {
        ...maRetirementGoals,
        ...maCommon,
        ...retirementIncome
    };

    const participant = useSelector((state) => state.participant);
    const { firstName, dateOfBirthSource, hasReducedSalary, incomeGoal, salary } = participant;
    const {
        base: baseSalary,
        salaryReminder,
        variable: otherVariableCompensation,
        salaryOnFile
    } = salary;

    const spouse = useSelector((state) => state.spouse);

    const spouseSalary = spouse.salary ? spouse.salary.base : 0;
    const spousePortion = spouse.incomeGoal.value
        ? spouse.incomeGoal.value
        : spouse.incomeGoal.default;

    const primaryPlan = useSelector((state) => state.primaryPlan);
    const promptForSalary = !!primaryPlan.planRules.promptForSalary; // Needs to be boolean for PropTypes

    const projectedIncome = useSelector((state) => state.projectedIncome);
    const percentageOfGoal = projectedIncome.percentageOfGoal;

    const period = useSelector(reselectTermPeriod);

    const confidenceLevel = useSelector(
        (state) => state.applicationSettings.projectionSettings.confidence
    );

    const linkedAccounts = useSelector((state) => state.participant.linkedAccountBalances);
    const hasLinkedAccounts = linkedAccounts && linkedAccounts.length > 0;

    const isPAE = useIsEmulator();
    const dateOfBirthDisabled = dateOfBirthSource === "FILE" || isPAE || isEnrolled;

    const isApple = () => primaryPlan.id === "150550-01";
    const isJPM = () => primaryPlan.id.slice(0, primaryPlan.id.indexOf("-")) === "150012";

    const mustEnterSalary = primaryPlan.salary.base === 0 && primaryPlan.terminated;

    const defaultIncomeGoal = incomeGoal.default;

    const hasOtherVariableCompensation = !_isNil(otherVariableCompensation);
    const roundedTotalGoal = Math.round(totalGoal);
    const showSalaryDisplay = showSalaryMessage(salary, primaryPlan.planRules);

    const [prompt, setPrompt] = useState(mustEnterSalary);
    const [salaryMessageChecked, setSalaryMessageChecked] = useState(false);

    const callSetCanSave = useCallback(
        (canSaveVal) => {
            if (!canSave && !errorId.length && (!prompt || salary)) {
                setCanSave(canSaveVal);
            }
        },
        [canSave, errorId.length, prompt, salary, setCanSave]
    );

    useEffect(() => {
        if (isEnabled.value) {
            callSetCanSave(true);
        }
    }, [callSetCanSave, isEnabled.value]);

    const totalSalaryText = spouseSaved.value
        ? labelTranslations.household
        : labelTranslations.estimated;

    const handleParticipantDobChange = (dob) => {
        stateParticipantDob.set(dob);
    };

    const handleSpouseDobChange = (dob, age) => {
        stateSpouse.set({ ...stateSpouse.value, spouseDob: dob, spouseAge: age });
    };

    const handleAddSpouse = () => {
        spouseSaved.set(true);
    };

    const handleNoPrompt = () => {
        setPrompt(false);
    };

    const handleSaveDelete = useCallback(() => {
        setDeleteSpouse(false);
        setSpouseGoal(0);
        stateDesiredRetAge.set(0);
        spouseSaved.set(false);
        stateSpouse.set(DEFAULT_STATE_SPOUSE);
    }, [setDeleteSpouse, setSpouseGoal, spouseSaved, stateDesiredRetAge, stateSpouse]);

    /**
     * Save user and/or spouse information onSubmit
     * @param {object} e
     */
    const handleSubmit = (e) => {
        e.preventDefault();

        const term = statePeriod.value === "monthly" ? 12 : 1;
        const termChange = statePeriod.value === period;
        const baselineScale = termChange ? (term === 1 ? 12 : 1 / 12) : 1;
        const formattedUserDob = changeDateFormat(stateParticipantDob.value);

        const userInfo = {
            base: salaryMessageChecked ? null : stateSalary.value,
            variable: additionalComp.value,
            incomeGoalValue: statePortion.value,
            incomeGoalValueUnits: stateIsPercent.value ? "PCT" : "AMT",
            dateOfBirth: formattedUserDob,
            type: "USER"
        };

        const { spouseFieldChanges, spouseInfoHasChanged, spouseAdded, spouseInfo } =
            getSpouseChanges(stateSpouse, spouse, stateDesiredRetAge.value, deleteSpouse);

        if (spouseAdded) {
            handleAddSpouse();
        }

        if (salaryMessageChecked) {
            stateSalary.set(salaryOnFile);
            setSalaryMessageChecked(false);
        }

        const changeBooleans = {
            salary: salaryMessageChecked || stateSalary.value !== baseSalary,
            additional: additionalComp.value !== otherVariableCompensation,
            incomeGoal: statePortion.value !== portionValue || stateIsPercent.value !== isPercent,
            dob: stateParticipantDob.value !== dob,
            termOnly: statePeriod.value !== period,
            confidence: stateConfidenceLevel.value !== confidenceLevel,
            spouseAdded,
            spouseInfoChanged: spouseInfoHasChanged,
            ...spouseFieldChanges
        };

        if (spouseInfo) {
            if (!stateSpouse.value.spouseDob) {
                dispatch(tabChange("spouse"));
                handleError("spouse-dob", true);

                return;
            }
        }

        if (mustEnterSalary) {
            handleNoPrompt();
        }

        if (deleteSpouse) {
            handleSaveDelete();
        }

        //ts type in client\src\app\liat\middleware\goalMiddleware.ts
        const saveGoalChangesParams = {
            term,
            goalValue: percentageOfGoal,
            baselineScale,
            userInfo,
            spouseInfo,
            changeBooleans,
            spouseDeleted: deleteSpouse,
            confidence: stateConfidenceLevel.value,
            closeModal: handleClose,
            isPAE
        };

        dispatch(saveGoalChanges(saveGoalChangesParams));

        if (salaryReminder) {
            callUpdateSalaryReminder();
        }

        handleClose(false);

        eventBus.dispatch(mtrGoalModalEvents.SAVE_CHANGES, this);
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: mtrGoalModalEvents.SAVE_CHANGES
        });
    };

    const handleSalaryMessage = () => {
        setSalaryMessageChecked(!salaryMessageChecked);

        if (!isEnabled.value) {
            isEnabled.set(true);
        }
    };

    /**
     * Validates dollar amount portions
     * @param {number} val - Dollar amount
     * @param {number} salary - User/Spouse salary
     * @param {number} additional - Additional compensation (defaulted at 0)
     * @param {boolean} isPercent - Determines if value is dollar amount or percent
     * @param {string} id - Element id; used for setting error states
     */
    const portionValidator = (val, salary, additional, isPercent, id, isSpouse) => {
        const errors = errorId;
        const hasPortion = errors.includes("part-portion") || errors.includes("spouse-portion");
        const portionMin = 0.01;
        const totalSalary = isSpouse ? salary : salary + additional;
        const errObj = {
            salLimitErr: false,
            varLimitErr: false,
            portionErr: hasPortion
        };

        if (salary > MAX_SALARY || (!totalSalary && !isSpouse)) {
            if (!isSpouse && !totalSalary) {
                id = "part-salary";
            }
            errObj.salLimitErr = true;
        }
        if (additional > MAX_SALARY) {
            errObj.varLimitErr = true;
        }
        if (errObj.salLimitErr) {
            if (id.includes("salary")) {
                handleError(id, true, errObj, isSpouse);
            }
        } else {
            if (id.includes("salary")) {
                handleError(id, false, errObj, isSpouse);
            }
        }
        if (errObj.varLimitErr) {
            if (id === "part-var") {
                handleError(id, true, errObj, isSpouse);
            }
        } else {
            if (id === "part-var") {
                handleError(id, false, errObj, isSpouse);
            }
        }
        if (!id.includes("salary") && !id.includes("var")) {
            if (!isPercent) {
                if (val < portionMin || val > MAX_SALARY) {
                    errObj.portionErr = true;
                    handleError(id, true, errObj, isSpouse);
                } else {
                    if (!errObj.salLimitErr && !errObj.varLimitErr) {
                        handleError(id, false, errObj, isSpouse);
                    }
                }
            } else {
                errObj.portionErr = false;
                handleError(id, false, errObj, isSpouse);
            }
        }
    };

    /**
     * Validates the date of birth input field if event type is keydown
     * Passes value to dispatch function if event type is blur
     * Rejects non matching values
     * @param {object} e
     */
    const handleDOBValidation = (e, isSpouse, id = "") => {
        function handleValue(dob) {
            const validObj = validateDOB(dob, getAgeFromDateOfBirth);
            const error = validObj.errorMessage;
            const newAge = validObj.age;
            let message = "";

            if (!validObj.isValid) {
                switch (error) {
                    case "invalid":
                        message = labelTranslations.invalidDate;
                        break;
                    case "max":
                        message = labelTranslations.lowAgeLimit;
                        break;
                }
                updateErrorMessage(message, isSpouse);
                handleError(id, true);
            } else {
                if (userDobError.value || spouseDobError.value) {
                    updateErrorMessage(message, isSpouse);
                }
                handleError(id, false);
            }
            if (validObj.isValid) {
                isEnabled.set(true);
                if (isSpouse) {
                    handleSpouseDobChange(dob, newAge);
                } else {
                    handleParticipantDobChange(dob);
                }
            }
        }
        if (e.type === "paste") {
            e.preventDefault();
        } else {
            /**
             * Sanitize input value
             * IE 11 adds extra characters to value, therefore was not passing regex test
             */
            let cleanDob = e.target.value.replace(/[^\d/]/g, "");
            let len = cleanDob.length;

            let start, end;

            if (e.type === "keydown") {
                const isTabPressed = e.key === "Tab" || e.keyCode === 9;

                if (isTabPressed) {
                    return;
                }

                // IE 11 interprets numpad '/' as 'Divide' key
                // Append '/' and increment len
                if (e.key === "Divide") {
                    cleanDob += "/";
                    len++;
                }

                if (len < e.target.value.length) {
                    e.target.value = cleanDob;
                }

                start = e.target.selectionStart;
                end = e.target.selectionEnd;

                if (e.key === "Backspace" && cleanDob) {
                    if (start && start === end) {
                        if (start === len) {
                            cleanDob = `${cleanDob.slice(0, start - 1)}`;
                        } else {
                            cleanDob = `${cleanDob.slice(0, start - 1)}${cleanDob.slice(start)}`;
                        }
                    } else if (start !== end) {
                        cleanDob = `${cleanDob.slice(0, start)}${cleanDob.slice(end)}`;
                    }
                } else if (((/[^\d/]/.test(e.key) || len === 10) && e.key !== "Divide") || isPAE) {
                    if (e.key !== "Unidentified") {
                        e.preventDefault();
                    }
                    return;
                } else {
                    if (start === len) {
                        cleanDob += e.key;
                    } else {
                        cleanDob = `${cleanDob.slice(0, start)}${e.key}${cleanDob.slice(start)}`;
                    }
                }
                handleValue(cleanDob);
            } else if (e.type === "change") {
                /*** Using this if they select a preset option based on browser history fields */
                if (len > 7 && len <= 10) {
                    handleValue(cleanDob);
                }
            } else if (e.type === "keyup") {
                /**
                 * Fix for mobile browswers
                 * If key is unidentified use keyup event to sanitize input and remove extra characters
                 */
                if (e.key === "Unidentified") {
                    let shouldCall = true;

                    if (/[^\d/]/.test(e.target.value)) {
                        shouldCall = true;
                        cleanDob = isSpouse ? spouseDob : dob;
                        e.target.value = cleanDob;
                    } else if (len > 10) {
                        shouldCall = false;
                        cleanDob = isSpouse ? spouseDob : dob;
                        e.target.value = cleanDob;
                    }
                    if (shouldCall) {
                        handleValue(cleanDob);
                    }
                }
            }
        }
    };

    /**
     * Updates date of birth error messages upon clicking out of field
     * @param {string} message - Error message
     * @param {boolean} isSpouse - Determines if message is for user or spouse tab
     */
    const updateErrorMessage = (message, isSpouse) => {
        if (isSpouse) {
            spouseDobError.set(message);
        } else {
            userDobError.set(message);
        }
    };

    /**
     * Negate the userTabActive property to alternate between tabs
     * @param {object} e
     */
    const handleTabClick = (e) => {
        if (e.target.nodeName === "BUTTON") {
            const userActive = userTabActive.value;
            const id = userActive ? "spouse-dob" : "part-dob";
            const nextPrimaryTabIndex = userActive ? "spouse" : "user";

            if (e.target.classList.contains("tab-active")) {
                return;
            }
            if (errorId.includes(id)) {
                setErrorId(errorId.filter((ele) => ele !== id));

                updateErrorMessage("", userTabActive.value);
                callSetCanSave(true);
            }
            dispatch(tabChange(nextPrimaryTabIndex));
        }
    };

    const handleDobBlur = (e) => {
        e.target.value = removeLeadingZeroFromDate(e.target.value);
    };

    const periodChangeHandler = (e) => {
        const period = e.target.value;
        handleChangeView(period);
        eventBus.dispatch(mtrGoalModalEvents.UPDATE_VIEW, this, { period: period });
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.VIEW_PAGE,
            selection: mtrGoalModalEvents.UPDATE_VIEW,
            payload: {
                period: period
            }
        });
    };

    const handleChangeView = (period) => {
        handleUserGoal(
            stateSalary.value,
            stateSpouse.value.spouseSalary,
            statePortion.value,
            stateSpouse.value.spousePortion,
            period,
            isPercent,
            stateSpouse.value.isPercentSpouse,
            additionalComp.value
        );

        statePeriod.set(period);
        callSetCanSave(true);
    };

    const handleBlurPortion = (portion, isPercent) => {
        handleUserGoal(
            stateSalary.value,
            stateSpouse.value.spouseSalary,
            portion,
            spousePortion,
            statePeriod.value,
            isPercent,
            stateSpouse.value.isPercentSpouse,
            additionalComp.value
        );

        statePortion.set(portion);
        stateIsPercent.set(isPercent);

        callSetCanSave(true);
    };

    const handleBlurSalary = (salary) => {
        handleUserGoal(
            salary,
            stateSpouse.value.spouseSalary,
            statePortion.value,
            stateSpouse.value.spousePortion,
            period,
            isPercent,
            stateSpouse.value.isPercentSpouse,
            additionalComp.value
        );
        stateSalary.set(salary);
    };

    const handleAdditionalComp = (comp) => {
        handleUserGoal(
            stateSalary.value,
            stateSpouse.value.spouseSalary,
            statePortion.value,
            stateSpouse.value.spousePortion,
            statePeriod.value,
            stateIsPercent.value,
            stateSpouse.value.isPercentSpouse,
            comp
        );

        additionalComp.set(comp);
    };

    /**
     * Adds or removes error states from elements based on id
     * @param {string} id - The element's id
     * @param {boolean} disabled - Determines if save button is disabled
     */
    const handleError = (id, disabled, errObj = {}, isSpouse) => {
        const el = document.getElementById(id);
        if (disabled) {
            el.classList.add("has-error");
            if (!errorId.includes(id)) {
                setErrorId([...errorId, id]);

                updateErrorMessage("", userTabActive.value);
            }
            setCanSave(false);
            isEnabled.set(false);
        } else {
            const portion =
                document.getElementById("part-portion") ||
                document.getElementById("spouse-portion");
            const additional = document.getElementById("part-var");
            const salary =
                document.getElementById("part-salary") || document.getElementById("spouse-salary");
            const partialId = isSpouse ? "spouse" : "part";
            el.classList.remove("has-error");

            if (
                id !== "part-dob" &&
                id !== "spouse-dob" &&
                !errObj.varLimitErr &&
                !errObj.salLimitErr &&
                !errObj.portionErr
            ) {
                if (portion) {
                    portion.classList.remove("has-error");
                }
                if (additional) {
                    additional.classList.remove("has-error");
                }
                if (salary) {
                    salary.classList.remove("has-error");
                }

                if (!isArrayEmpty(errorId)) {
                    setErrorId(
                        errorId.filter((ele) => {
                            return (
                                ele === "part-dob" ||
                                ele === "spouse-dob" ||
                                !ele.includes(partialId)
                            );
                        }),
                        id
                    );
                }
            } else {
                if (!isArrayEmpty(errorId)) {
                    setErrorId(errorId.filter((ele) => ele !== id));
                }
            }

            updateErrorMessage("", userTabActive.value);
            isEnabled.set(true);
        }
    };

    const setErrorHighlights = (partialId) => {
        if (errorId.length) {
            for (let i = 0; i < errorId.length; i++) {
                if (errorId[i].includes(partialId)) {
                    const el = document.getElementById(errorId[i]);
                    el.classList.add("has-error");
                }
            }
        }
    };

    const handleNameChange = (name) => {
        stateSpouse.set({ ...stateSpouse.value, spouseName: name });
        callSetCanSave(true);
    };

    const handleSpouseSalary = (spouseSalary) => {
        handleUserGoal(
            stateSalary.value,
            spouseSalary,
            statePortion.value,
            stateSpouse.value.spousePortion,
            statePeriod.value,
            stateIsPercent.value,
            stateSpouse.value.isPercentSpouse,
            additionalComp.value
        );

        stateSpouse.set({ ...stateSpouse.value, spouseSalary });
    };

    const handleGenderChange = (gender) => {
        stateSpouse.set({ ...stateSpouse.value, spouseGender: gender });

        callSetCanSave(true);
    };

    const handleAgeChange = (age) => {
        stateDesiredRetAge.set(age);

        if (spouseSaved.value) {
            callSetCanSave(true);
        }
    };

    const handleSpousePortion = (portion, isPercentSpouse) => {
        handleUserGoal(
            stateSalary.value,
            stateSpouse.value.spouseSalary,
            statePortion.value,
            portion,
            statePeriod.value,
            stateIsPercent.value,
            isPercentSpouse,
            additionalComp.value
        );

        stateSpouse.set({
            ...stateSpouse.value,
            spousePortion: portion,
            isPercentSpouse: isPercentSpouse
        });

        callSetCanSave(true);
    };

    const handleDeleteSpouse = () => {
        setDeleteSpouse(!deleteSpouse);

        callSetCanSave(true);
    };

    const handleChangeConfidenceLevel = (level) => {
        stateConfidenceLevel.set(level);

        callSetCanSave(true);
    };

    const confidenceLevelChangeHandler = (e) => {
        const level = e.target.value;
        handleChangeConfidenceLevel(Number(level));
    };

    const reducedSalaryClass = labelTranslations.reducedSalaryMessage ? "column" : "row";

    const modalClass = showModal ? "fade in" : "";

    /** Show tabs and spouse modal body if user is MAX eligible */
    const userBodyMax = (
        <div>
            <Tabs
                deleteSpouse={deleteSpouse}
                handleTabClick={handleTabClick}
                isEmulator={isPAE}
                modalTranslations={labelTranslations}
                spouseName={stateSpouse.value.spouseName}
                spouseSaved={spouseSaved.value}
                userName={firstName}
                userTabActive={userTabActive.value}
            />
            {!userTabActive.value && (
                <SpouseBody
                    canSave={canSave}
                    defaultIncomeGoal={defaultIncomeGoal}
                    deleteSpouse={deleteSpouse}
                    desiredRetAge={stateDesiredRetAge.value}
                    dobError={spouseDobError.value}
                    errorId={errorId}
                    eventBus={eventBus}
                    getAge={getAgeFromDateOfBirth}
                    handleAgeChange={handleAgeChange}
                    handleDeleteSpouse={handleDeleteSpouse}
                    handleDOBValidation={handleDOBValidation}
                    handleError={handleError}
                    handleGenderChange={handleGenderChange}
                    handleNameChange={handleNameChange}
                    handleSpousePortion={handleSpousePortion}
                    handleSpouseSalary={handleSpouseSalary}
                    handleDobBlur={handleDobBlur}
                    isPercentSpouse={stateSpouse.value.isPercentSpouse}
                    isSpouse={!userTabActive.value}
                    labelTranslations={labelTranslations}
                    mtrGoalModalEvents={mtrGoalModalEvents}
                    portionValidator={portionValidator}
                    period={statePeriod.value}
                    setErrorHighlights={setErrorHighlights}
                    spouseAge={stateSpouse.value.spouseAge}
                    spouseDob={stateSpouse.value.spouseDob}
                    spouseGender={stateSpouse.value.spouseGender}
                    spouseName={stateSpouse.value.spouseName}
                    spousePortion={stateSpouse.value.spousePortion}
                    spouseSaved={spouseSaved.value}
                    spouseSalary={stateSpouse.value.spouseSalary}
                    spouseGoal={spouseGoal}
                    tooltipTranslations={tooltipTranslations}
                    isJPM={isJPM}
                    isPAE={isPAE}
                />
            )}
        </div>
    );

    const showUser = userTabActive.value && (
        <UserBody
            additionalComp={additionalComp.value}
            canSave={canSave}
            dob={stateParticipantDob.value}
            defaultIncomeGoal={defaultIncomeGoal}
            dobDisabled={dateOfBirthDisabled}
            dobError={userDobError.value}
            employerSalary={salaryOnFile}
            errorId={errorId}
            eventBus={eventBus}
            handleAdditionalComp={handleAdditionalComp}
            handleChangeView={handleChangeView}
            handleBlurPortion={handleBlurPortion}
            handleBlurSalary={handleBlurSalary}
            handleDobBlur={handleDobBlur}
            handleDOBValidation={handleDOBValidation}
            handleError={handleError}
            handleSalaryMessage={handleSalaryMessage}
            hasLinkedAccounts={hasLinkedAccounts}
            hasOtherVariableComp={hasOtherVariableCompensation}
            isPercent={stateIsPercent.value}
            isSpouse={!userTabActive.value}
            labelTranslations={labelTranslations}
            mtrGoalModalEvents={mtrGoalModalEvents}
            mustEnterSalary={mustEnterSalary}
            period={statePeriod.value}
            portion={statePortion.value}
            portionValidator={portionValidator}
            salary={stateSalary.value}
            setErrorHighlights={setErrorHighlights}
            showSalaryDisplay={showSalaryDisplay}
            tooltipTranslations={tooltipTranslations}
            userGoal={userGoal}
            isJPM={isJPM}
        />
    );

    return (
        <ErrorBoundary fallback={sentryFallback}>
            <FocusLock>
                <div
                    className={`progress-modal integrated-progress-modal modal-content ${modalClass}`}
                    role="dialog"
                    id="goal-modal"
                    data-testid="integrated-goal-modal"
                >
                    {isLoading && <Loader />}
                    <Header
                        defaultIncomeGoal={defaultIncomeGoal}
                        portion={statePortion.value}
                        period={statePeriod.value}
                        totalGoal={roundedTotalGoal}
                        totalSalaryText={totalSalaryText}
                        isPercent={isPercent}
                        mustEnterSalary={mustEnterSalary}
                        modalTranslations={labelTranslations}
                        isSpouse={!userTabActive.value}
                        isApple={isApple}
                        salaryReminder={salaryReminder}
                        promptForSalary={promptForSalary}
                        isJPM={isJPM}
                    />
                    <div id={userTabActive.value ? "" : "spouse-body"} className="modal-body">
                        <form onSubmit={handleSubmit} name="updateGoal" className="form-horizontal">
                            {userBodyMax}

                            {showModal && showUser}
                            <div className={`form-bottom-container ${reducedSalaryClass}`}>
                                <div className="progress-modal-projection-settings">
                                    <div className="progress-modal-projection-setting-group">
                                        <label className="control-label inline-label with-right-margin">
                                            View:{" "}
                                        </label>
                                        <div className="input-group">
                                            <label id="lblMonthlyOption" className="radio-inline">
                                                <input
                                                    type="radio"
                                                    name="active_salary_view"
                                                    id="monthlyOption"
                                                    value="monthly"
                                                    className="period-radio"
                                                    checked={
                                                        statePeriod.value === Constants.DATE.MONTHLY
                                                    }
                                                    onChange={periodChangeHandler}
                                                    aria-labelledby="lblMonthlyOption"
                                                />
                                                {labelTranslations.monthly}
                                            </label>
                                            <label id="lblYearlyOption" className="radio-inline">
                                                <input
                                                    type="radio"
                                                    name="active_salary_view"
                                                    id="yearlyOption"
                                                    value="yearly"
                                                    className="period-radio"
                                                    checked={
                                                        statePeriod.value === Constants.DATE.YEARLY
                                                    }
                                                    onChange={periodChangeHandler}
                                                    aria-labelledby="lblYearlyOption"
                                                />
                                                {labelTranslations.yearly}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="progress-modal-projection-setting-group">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Popover id={`confidence-level-explaination`}>
                                                    <div>
                                                        {
                                                            tooltipTranslations.levelOfConfidence
                                                                .tooltip
                                                        }
                                                    </div>
                                                </Popover>
                                            }
                                        >
                                            <label className="control-label inline-label with-right-margin">
                                                {labelTranslations["rivd-confidenceLevel"] + ":"}
                                            </label>
                                        </OverlayTrigger>
                                        <div className="input-group">
                                            <label
                                                id="lblGoal_probability_1"
                                                className="radio-inline"
                                            >
                                                <input
                                                    type="radio"
                                                    name="goal_probability"
                                                    id="goal_probability_1"
                                                    value={80}
                                                    className="period-radio"
                                                    checked={stateConfidenceLevel.value === 80}
                                                    onChange={confidenceLevelChangeHandler}
                                                    aria-labelledby="lblGoal_probability_1"
                                                />
                                                {labelTranslations["rivd-confidenceLevelOption1"]}
                                            </label>
                                            <label
                                                id="lblGoal_probability_2"
                                                className="radio-inline"
                                            >
                                                <input
                                                    type="radio"
                                                    name="goal_probability"
                                                    id="goal_probability_2"
                                                    value={90}
                                                    className="period-radio"
                                                    checked={stateConfidenceLevel.value === 90}
                                                    onChange={confidenceLevelChangeHandler}
                                                    aria-labelledby="lblGoal_probability_2"
                                                />
                                                {labelTranslations["rivd-confidenceLevelOption2"]}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <Footer
                                    handleClose={handleClose}
                                    canSave={canSave}
                                    mustEnterSalary={mustEnterSalary}
                                    prompt={prompt}
                                    salary={baseSalary}
                                    maxSalary={MAX_SALARY}
                                    spouseSalary={spouseSalary}
                                    hasReducedSalary={hasReducedSalary}
                                    reducedSalaryMessage={labelTranslations.reducedSalaryMessage}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </FocusLock>
        </ErrorBoundary>
    );
};
IntegratedGoalModal.propTypes = {
    additionalComp: PropTypes.object,
    callUpdateSalaryReminder: PropTypes.func,
    canSave: PropTypes.bool,
    deleteSpouse: PropTypes.bool,
    errorId: PropTypes.array,
    eventBus: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    handleClose: PropTypes.func,
    handleUserGoal: PropTypes.func,
    isEnrolled: PropTypes.bool,
    isEnabled: PropTypes.object,
    isLoading: PropTypes.bool,
    isPercent: PropTypes.bool,
    portionValue: PropTypes.number,
    setCanSave: PropTypes.func,
    setDeleteSpouse: PropTypes.func,
    setErrorId: PropTypes.func,
    setSpouseGoal: PropTypes.func,
    showModal: PropTypes.bool,
    spouseGoal: PropTypes.number,
    spouseDobError: PropTypes.object,
    spouseSaved: PropTypes.object,
    stateDesiredRetAge: PropTypes.object,
    stateIsPercent: PropTypes.object,
    statePortion: PropTypes.object,
    statePeriod: PropTypes.object,
    stateSalary: PropTypes.object,
    stateSpouse: PropTypes.object,
    stateParticipantDob: PropTypes.object,
    stateConfidenceLevel: PropTypes.object,
    totalGoal: PropTypes.number,
    userGoal: PropTypes.number,
    userDobError: PropTypes.object,
    userTabActive: PropTypes.object,
    dob: PropTypes.string,
    spouseDob: PropTypes.string
};
export default withProfiler(IntegratedGoalModal, { name: "IntegratedGoalModal" });
