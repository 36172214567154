import { isUndefinedOrNull, isArrayEmpty } from "gw-shared-components";

import { isHSAPlan } from "../business/hsaLogic/hsa";
import ReferenceData from "../constants/ReferenceData";
import { isEmulator, isPrivileged } from "../utils/paeUtils";
import {
    canShowPreRetireeMocksEnabled,
    canShowWhenCanIRetireMocksEnabled
} from "../utils/urlUtils";

/**
 * @description Only users bewtween 35 and 85 can/should see health care costs, even if the plan allows such
 *         Display Healthcare cost tab based on hvsEnable setting, regardless if in emulator or particpant
 *         If in emulator, navigate to feature will be disabled
 * @param {object} participant
 * @param {object} primaryPlan
 * @returns {boolean}
 */
export const canShowHealthCareView = (participant, primaryPlan) => {
    if (isUndefinedOrNull(participant) || isUndefinedOrNull(primaryPlan)) {
        return false;
    }

    const age = participant.age;
    const planRules = !isUndefinedOrNull(primaryPlan) ? primaryPlan.planRules : null;
    const doesPlanAllow = !isUndefinedOrNull(planRules) ? planRules.hvsEnabled : false;

    if (doesPlanAllow) {
        return (
            age !== null &&
            age >= ReferenceData.MIN_HEALTHCARE_VIEW_AGE &&
            age <= ReferenceData.MAX_RETIREMENT_AGE
        );
    } else {
        return false;
    }
};

/**
 * @description Only users that have hdicEnabled in their primary plan -- planrules
 * @param {object} primaryPlan
 * @returns {boolean}
 */
export const canShowHDICView = (primaryPlan) => {
    if (isUndefinedOrNull(primaryPlan)) {
        return false;
    }
    const planRules = !isUndefinedOrNull(primaryPlan) ? primaryPlan.planRules : null;
    const doesPlanAllow = !isUndefinedOrNull(planRules) ? planRules.hdicEnabled : false;

    return doesPlanAllow;
};

export const canShowMaximizer = (primaryPlan) => {
    if (isUndefinedOrNull(primaryPlan)) {
        return false;
    }
    const planRules = !isUndefinedOrNull(primaryPlan) ? primaryPlan.planRules : null;
    const doesPlanAllow = !isUndefinedOrNull(planRules) ? planRules.maximizer : false;

    return doesPlanAllow;
};

/**
 * @description gets is participant (not emulator) and has other assets enabled
 *              if emulator, hide rainbow if amount is zero, otherise display rainbow and disable click
 *              to display contents
 *              if otherAssetsEnabled is false, we still want to display, tab and totals, but disable click
 * @param {object} participant
 * @param {object} primaryPlan
 * @returns {boolean}
 */
export const getDisplayOtherAssets = (participant, primaryPlan) => {
    if (isUndefinedOrNull(participant) || isUndefinedOrNull(primaryPlan)) {
        return false;
    }
    //do not show for emulated participants
    const isPAE = isEmulator() && !isPrivileged();
    return primaryPlan.planRules.otherAssetsEnabled && !isPAE;
};

/**
 * @description returns false if plan doesn't allow govt ben
 * @param {object} primaryPlan
 * @returns {boolean}
 */
export const getDisplayGovernmentBenefits = (primaryPlan) => {
    if (isUndefinedOrNull(primaryPlan)) {
        return false;
    }
    return primaryPlan.planRules.ssEnabled;
};

/**
 * @description returns true if participant able to view pre-retirement feature
 * @param {object} participant
 * @param {object} primaryPlan
 * @returns {boolean}
 *   To Do - Turn off feature for June release; change this later in July
 */
export const canShowPreRetirementView = (participant, primaryPlan) => {
    if (isUndefinedOrNull(primaryPlan) || isUndefinedOrNull(participant)) {
        return false;
    }
    // allow override of the view for development/testing purposes
    if (canShowPreRetireeMocksEnabled()) {
        return true;
    }

    const participantOverFifty = participant.age >= ReferenceData.MIN_RETIREMENT_AGE;
    return primaryPlan.planRules.allowPreRetireeView && participantOverFifty;
};

/**
 * @description returns true if participant able to view when can i retirement feature
 * @param {object} participant
 * @param {object} primaryPlan
 * @returns {boolean}
 */
export const canShowWhenCanIRetire = (participant, primaryPlan) => {
    if (isUndefinedOrNull(primaryPlan) || isUndefinedOrNull(participant)) {
        return false;
    }
    // allow override of the view for development/testing purposes
    if (canShowWhenCanIRetireMocksEnabled()) {
        return true;
    }

    const participantUnderFifty = participant.age < ReferenceData.MIN_RETIREMENT_AGE;
    return primaryPlan.planRules.allowWCIRView && participantUnderFifty;
};

/**
 * @description income gap hidden for terminated ppt
 * @param {object} primaryPlan
 * @returns {boolean}
 */
export const canShowIncomeGap = (primaryPlan) => {
    if (isUndefinedOrNull(primaryPlan)) {
        return false;
    }
    // return true we can show if ppt is not terminated
    return !primaryPlan.terminated;
};

/**
 * @description determine whether to display hsa
 * @param {array} plans
 * @returns {boolean}
 */
export const getDisplayHsa = (plans) => {
    if (isUndefinedOrNull(plans) || isArrayEmpty(plans)) {
        return false;
    }

    return plans.some((plan) => {
        return isHSAPlan(plan);
    });
};

/**
 * @description determine whether to display quick view feature
 * @returns {boolean}
 */
export const canShowQuickView = (homePageEligibility) => {
    if (isUndefinedOrNull(globalThis.quickView)) {
        return false;
    }

    // {enabled} KTMG flag (WNQVD)
    if (!globalThis.quickView.enabled) {
        return false;
    }

    return homePageEligibility;
};
