import { DiagnosticsConstants } from "../common";
import formatTimingPrefix from "../common/formatTimingPrefix";

const timingPrefix = formatTimingPrefix();

const getProfileSettingsRender = () => {
    return sessionStorage.getItem(`${timingPrefix}${DiagnosticsConstants.RENDER_PROFILE_SETTINGS}`);
};

export default getProfileSettingsRender;
