/**
 * @name app.common.model.domain.SalaryInfo
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 *
 */

"use strict";

//TODO: TJM this needs to be changed waiting on decisions

class SalaryInfo {
    constructor(config) {
        this.base = 0;
        this.variable = 0;
        this.total = 60000;
        this.tempVariable = null;
        this.source = "";
        this.nqContributionPct = null;
        this.salaryReminder = false;
        this.salaryOnFile = 0;

        if (config) {
            this.base = config.base;
            this.variable = config.variable;
            this.total = config.total;
            this.source = config.source;
            this.nqContributionPct = config.nqContributionPct;
            this.salaryReminder = config.salaryReminder;
            this.salaryOnFile = config.salaryOnFile;
        }
    }
}

export default SalaryInfo;
