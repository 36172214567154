import {
    SAVE_SOCIAL_SECURITY_UPDATE_SUCCESS,
    SAVE_SOCIAL_SECURITY_UPDATE_FAIL,
    SET_PARTICIPANT_UPDATE_DONE,
    SET_SPOUSE_UPDATE_DONE,
    SET_PARTICIPANT_START_REC_AGE
} from "./socialSecurityActionTypes";

export const saveSocialSecurityUpdateSuccess = () => {
    return {
        type: SAVE_SOCIAL_SECURITY_UPDATE_SUCCESS
    };
};

export const saveSocialSecurityUpdateFail = () => {
    return {
        type: SAVE_SOCIAL_SECURITY_UPDATE_FAIL
    };
};

export const setParticipantUpdateDone = (payload) => {
    return {
        type: SET_PARTICIPANT_UPDATE_DONE,
        payload
    };
};

export const setSpouseUpdateDone = (payload) => {
    return {
        type: SET_SPOUSE_UPDATE_DONE,
        payload
    };
};

export const setParticipantStartRecAge = (startAge) => {
    const payload = {
        newStartAge: startAge
    };
    return {
        type: SET_PARTICIPANT_START_REC_AGE,
        payload
    };
};
