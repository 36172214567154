import React from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import AcessibilityUtil from "core-ui/client/src/app/core/util/AccessibilityUtil";
import { ObjectUtil } from "gw-shared-components";
import { isEmpty as _isEmpty } from "lodash";
import PropTypes from "prop-types";

import { ACCESSIBILITY } from "../../constants/AccessibilityPaycheck";
import { mtrGoalModalEvents } from "../../events/mtrGoalModalEvents";
import { changeDateFormat } from "../../utils/dateUtils";
import {
    getRadialChartSegmentsFromIncomeParts,
    getLoadingRadialChartSegments,
    calculateGoal
} from "../../utils/goalUtils";
import HiddenA11yWrapper from "../accessibility/HiddenA11yWrapper";
import Backdrop from "../backdrop";
import GoalModal from "../goalModal/Modal";
import RadialChart from "../radialChart/RadialChart";

/**
 * Is this component deprecated with IntegratedGoalModal and can be removed?
 */

/**
 * The main goal component that contains the radial chart and modal components
 */
export default class Goal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            /** Show the main user's tab as active upon render */
            userTabActive: true,
            /** Holds element id's with errors */
            errorId: [],
            spouseDobError: "",
            userDobError: "",
            userGoal: 0,
            spouseGoal: 0,
            spouseAge: 0,
            canSave: props.promptForSalary,
            showModal: false,
            deleteSpouse: false,
            maxSalary: 10000000,
            confidenceLevel: this.props.confidenceLevel,
            ACCESSIBILITY: ACCESSIBILITY
        };

        this.originalProps = {};
    }

    setUserGoal = (
        salary,
        spouseSalary,
        portion,
        spousePortion,
        period,
        isPercent,
        isPercentSpouse,
        additionalComp
    ) => {
        const additional = additionalComp ? additionalComp : 0;
        const totalComp = Number(salary) + additional;
        const userGoal = calculateGoal(portion, totalComp, period, isPercent);
        const spouseGoal = spouseSalary
            ? calculateGoal(spousePortion, spouseSalary, period, isPercentSpouse)
            : 0;
        this.setState({
            userGoal: userGoal,
            spouseGoal: spouseGoal
        });
    };

    componentDidUpdate(nextProps) {
        if (nextProps.userName || nextProps.percentageOfGoal) {
            if (!this.state.userName || !this.state.percentageOfGoal) {
                this.setUserGoal(
                    nextProps.salary,
                    nextProps.spouseSalary,
                    nextProps.portion,
                    nextProps.spousePortion,
                    nextProps.period,
                    nextProps.isPercent,
                    nextProps.isPercentSpouse,
                    nextProps.additionalComp
                );

                this.setState(
                    {
                        isPercent: nextProps.isPercent,
                        isPercentSpouse: nextProps.isPercentSpouse,
                        period: nextProps.period,
                        spouseSaved: nextProps.spouseSaved,
                        spouseName: nextProps.spouseName,
                        spouseSalary: nextProps.spouseSalary,
                        salary: nextProps.salary,
                        spouseGender: nextProps.spouseGender,
                        spouseAge: nextProps.spouseAge,
                        desiredRetAge: nextProps.desiredRetAge,
                        spousePortion: nextProps.spousePortion,
                        portion: nextProps.portion,
                        dobDisabled: nextProps.dobDisabled,
                        additionalComp: nextProps.additionalComp,
                        prompt: nextProps.mustEnterSalary,
                        participantDob: nextProps.dob,
                        spouseDob: nextProps.spouseDob,
                        percentageOfGoal: nextProps.percentageOfGoal,
                        userName: nextProps.userName,
                        confidenceLevel: nextProps.confidenceLevel
                    },
                    () => {
                        this.originalProps = {
                            ...this.state
                        };
                    }
                );
            } else {
                if (this.props.percentageOfGoal !== nextProps.percentageOfGoal) {
                    this.setState({ previousPercentageOfGoal: this.props.percentageOfGoal });
                }
                const haveDetailsChanged = this.detailsChanged(nextProps);

                if (haveDetailsChanged.spouseChanged || haveDetailsChanged.userChanged) {
                    this.originalProps = {
                        ...this.originalProps,
                        ...haveDetailsChanged.changedProperties
                    };
                    this.setState({
                        ...this.state,
                        ...haveDetailsChanged.changedProperties
                    });

                    // From MTR, Retirement Goals, the retirement percentage can be updated
                    if (
                        haveDetailsChanged.changedProperties.hasOwnProperty.call("portion") ||
                        haveDetailsChanged.changedProperties.hasOwnProperty.call("spousePortion")
                    ) {
                        this.setUserGoal(
                            nextProps.salary,
                            nextProps.spouseSalary,
                            nextProps.portion,
                            nextProps.spousePortion,
                            nextProps.period,
                            nextProps.isPercent,
                            nextProps.isPercentSpouse,
                            nextProps.additionalComp
                        );
                    }
                }
            }
        }
        if (
            nextProps.additionalComp !== this.state.additionalComp &&
            this.state.userName &&
            !this.state.canSave
        ) {
            this.setState({
                additionalComp: nextProps.additionalComp
            });

            this.setUserGoal(
                this.state.salary,
                this.state.spouseSalary,
                this.state.portion,
                this.state.spousePortion,
                this.state.period,
                this.state.isPercent,
                this.state.isPercentSpouse,
                nextProps.additionalComp
            );
        }
    }

    detailsChanged = (details) => {
        let spouseChanged = false;
        let userChanged = false;
        const changedProperties = {};
        const spouse = {
            spouseSalary: this.state.spouseSalary,
            isPercentSpouse: this.state.isPercentSpouse,
            spouseSaved: this.state.spouseSaved,
            spouseName: this.state.spouseName,
            spousePortion: this.state.spousePortion,
            spouseGender: this.state.spouseGender,
            spouseDob: this.state.spouseDob,
            desiredRetAge: this.state.desiredRetAge
        };
        const user = {
            salary: this.state.salary,
            portion: this.state.portion,
            isPercent: this.state.isPercent,
            additionalComp: this.state.additionalComp,
            participantDob: this.state.participantDob,
            userName: this.state.userName
        };

        for (const spouseDetail in spouse) {
            if (
                spouse[spouseDetail] !== details[spouseDetail] &&
                !ObjectUtil.isUndefinedOrNull(details[spouseDetail]) &&
                !this.state.canSave
            ) {
                spouseChanged = true;
                changedProperties[spouseDetail] = details[spouseDetail];
            }
        }

        for (const userDetail in user) {
            if (
                user[userDetail] !== details[userDetail] &&
                !ObjectUtil.isUndefinedOrNull(details[userDetail]) &&
                !this.state.canSave
            ) {
                userChanged = true;
                changedProperties[userDetail] = details[userDetail];
            }
        }

        return { spouseChanged, userChanged, changedProperties };
    };

    /**
     * Toggle the goal modal on/off when chart is clicked
     */
    handleClick = () => {
        if (this.state.prompt) {
            return;
        }
        if (!this.props.goalModalVisible) {
            this.props.toggleProgressModal();
        } else if (!this.props.isLoading) {
            this.handleClose(false);
        }

        this.props.eventBus.dispatch(mtrGoalModalEvents.TOGGLE_MODAL, this);
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.TOGGLE_EVENT,
            selection: mtrGoalModalEvents.TOGGLE_MODAL
        });
    };

    handleBackdropClick = () => {
        if ((this.props.mustEnterSalary && this.state.prompt) || this.props.isLoading) {
            return;
        }
        this.handleClose(false);
        this.props.eventBus.dispatch(mtrGoalModalEvents.TOGGLE_MODAL, this);
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.TOGGLE_EVENT,
            selection: mtrGoalModalEvents.TOGGLE_MODAL
        });
    };

    /**
     * Negate the userTabActive property to alternate between tabs
     * @param {object} e
     */
    handleTabClick = (e) => {
        if (e.target.nodeName === "BUTTON") {
            const userActive = this.state.userTabActive;
            const id = userActive ? "spouse-dob" : "part-dob";

            if (e.target.classList.contains("tab-active")) {
                return;
            }
            if (this.state.errorId.includes(id)) {
                this.setState(
                    {
                        errorId: [...this.state.errorId.filter((ele) => ele !== id)]
                    },
                    () => {
                        this.updateErrorMessage("", userActive);
                        this.callSetCanSave(true);
                    }
                );
            }

            this.setState({
                userTabActive: !this.state.userTabActive
            });
        }
    };

    /**
     * Save user and/or spouse information onSubmit
     * @param {object} e
     */
    handleSubmit = async (e) => {
        e.preventDefault();

        let spouseInfoChanged = false;
        const term = this.state.period === "monthly" ? 12 : 1;
        const termChange = this.state.period === this.originalProps.period;
        const baselineScale = termChange ? (term === 1 ? 12 : 1 / 12) : 1;
        let newSpouseAdded = false;
        const userDob = changeDateFormat(this.state.participantDob);
        const spouseDob = changeDateFormat(this.state.spouseDob);
        const spouseDeleted = this.state.deleteSpouse;

        /** A new spouse has been added if state copy has falsy spouseName value and state has truthy spouseName */
        if (!this.originalProps.spouseName && this.state.spouseName && !spouseDeleted) {
            newSpouseAdded = true;
            this.handleAddSpouse();
        }

        const userInfo = {
            base: this.state.salary,
            variable: this.state.additionalComp,
            incomeGoalValue: this.state.portion,
            incomeGoalValueUnits: this.state.isPercent ? "PCT" : "AMT",
            dateOfBirth: userDob
        };

        const spouseChanges = spouseDeleted
            ? {}
            : {
                  name: this.state.spouseName !== this.originalProps.spouseName,
                  gender: this.state.spouseGender !== this.originalProps.spouseGender,
                  age: this.state.spouseAge !== this.originalProps.spouseAge,
                  retirementAge: this.state.desiredRetAge !== this.originalProps.desiredRetAge,
                  spouseSalary: this.state.spouseSalary !== this.originalProps.spouseSalary,
                  spouseIncomeGoal:
                      this.state.spousePortion !== this.originalProps.spousePortion ||
                      this.state.isPercentSpouse !== this.originalProps.isPercentSpouse,
                  spouseDob: this.state.spouseDob !== this.originalProps.spouseDob
              };

        if (!spouseDeleted) {
            /** Iterates over spouseChanges to find at least one true value */
            for (const props in spouseChanges) {
                if (spouseChanges[props]) {
                    if (!newSpouseAdded) {
                        spouseInfoChanged = true;
                    }
                    break;
                }
            }
        }

        const spouse =
            (newSpouseAdded || spouseInfoChanged) && !this.state.deleteSpouse
                ? {
                      firstName: this.state.spouseName,
                      gender: this.state.spouseGender,
                      dateOfBirth: spouseDob,
                      retirementAge: this.state.desiredRetAge,
                      dataSource: "UXAUV",
                      salary: this.state.spouseSalary,
                      incomeGoalValueUnits: this.state.isPercentSpouse ? "PCT" : "AMT",
                      incomeGoalValue: this.state.spousePortion
                  }
                : null;

        const changeBooleans = {
            salary: this.state.salary !== this.originalProps.salary,
            additional: this.state.additionalComp !== this.originalProps.additionalComp,
            incomeGoal:
                this.state.portion !== this.originalProps.portion ||
                this.state.isPercent !== this.originalProps.isPercent,
            dob: this.state.participantDob !== this.originalProps.participantDob,
            termOnly: this.state.period !== this.originalProps.period,
            confidence: this.state.confidenceLevel !== this.originalProps.confidenceLevel,
            newSpouseAdded: newSpouseAdded,
            spouseInfoChanged: spouseInfoChanged,
            ...spouseChanges
        };

        if (spouse) {
            if (!this.state.spouseDob) {
                this.setState(
                    {
                        userTabActive: false
                    },
                    () => {
                        if (!this.state.spouseDob) {
                            this.handleError("spouse-dob", true);
                        }
                    }
                );

                return;
            }
        }

        if (this.props.mustEnterSalary) {
            await this.handleNoPrompt();
        }

        if (this.state.deleteSpouse) {
            await this.handleSaveDelete();
        }

        if (changeBooleans.salary) {
            this.props.hasReducedSalaryOff();
        }

        await this.props.saveChanges(
            term,
            this.props.percentageOfGoal,
            baselineScale,
            userInfo,
            spouse,
            changeBooleans,
            spouseDeleted,
            this.state.confidenceLevel,
            this.props.toggleProgressModal
        );

        if (this.props.salaryReminder) {
            this.callUpdateSalaryReminder();
        }

        this.setState(
            {
                userTabActive: true,
                canSave: false
            },
            () => {
                this.originalProps = {
                    ...this.state
                };
            }
        );

        this.props.eventBus.dispatch(mtrGoalModalEvents.SAVE_CHANGES, this);
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: mtrGoalModalEvents.SAVE_CHANGES
        });
    };

    /**
     * Adds or removes error states from elements based on id
     * @param {string} id - The element's id
     * @param {boolean} disabled - Determines if save button is disabled
     */
    handleError = (id, disabled, errObj = {}, isSpouse) => {
        const el = document.getElementById(id);
        if (disabled) {
            el.classList.add("has-error");
            if (!this.state.errorId.includes(id)) {
                this.setState({
                    errorId: [...this.state.errorId, id]
                });
            }
            this.setCanSave(false);
        } else {
            const portion =
                document.getElementById("part-portion") ||
                document.getElementById("spouse-portion");
            const additional = document.getElementById("part-var");
            const salary =
                document.getElementById("part-salary") || document.getElementById("spouse-salary");
            const partialId = isSpouse ? "spouse" : "part";
            el.classList.remove("has-error");

            if (
                id !== "part-dob" &&
                id !== "spouse-dob" &&
                !errObj.varLimitErr &&
                !errObj.salLimitErr &&
                !errObj.portionErr
            ) {
                if (portion) {
                    portion.classList.remove("has-error");
                }
                if (additional) {
                    additional.classList.remove("has-error");
                }
                if (salary) {
                    salary.classList.remove("has-error");
                }

                this.setState(
                    {
                        errorId: this.state.errorId.filter((ele) => {
                            return (
                                ele === "part-dob" ||
                                ele === "spouse-dob" ||
                                !ele.includes(partialId)
                            );
                        })
                    },
                    () => {
                        this.callSetCanSave(true);
                    }
                );
            } else {
                this.setState(
                    {
                        errorId: this.state.errorId.filter((ele) => ele !== id)
                    },
                    () => {
                        if (!errObj.varLimitErr && !errObj.salLimitErr && !errObj.portionErr) {
                            this.callSetCanSave(true);
                        }
                    }
                );
            }
        }
    };

    /**
     * Fires when user hits cancel button
     * Switch back to user tab
     * Remove error states from all corresponding elements
     */
    handleClose = async () => {
        let el;
        for (let i = 0; i < this.state.errorId.length; i++) {
            el = document.getElementById(this.state.errorId[i]);

            if (el) {
                el.classList.remove("has-error");
            }
        }

        // Wait for set state before closing modal
        await this.handleCancel(this.props.originalProps);

        this.callUpdateSalaryReminder();

        this.props.toggleProgressModal();
        this.setCanSave(false);
        this.setState({
            userTabActive: true,
            errorId: [],
            spouseDobError: "",
            userDobError: ""
        });

        this.props.eventBus.dispatch(mtrGoalModalEvents.CANCEL_CHANGES, this);
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: mtrGoalModalEvents.CANCEL_CHANGES
        });
        AcessibilityUtil.focusElement("progress-to-goal-radial-chart-wrapper");
    };

    /**
     * Updates date of birth error messages upon clicking out of field
     * @param {string} message - Error message
     * @param {boolean} isSpouse - Determines if message is for user or spouse tab
     */
    updateErrorMessage = (message, isSpouse) => {
        if (isSpouse) {
            this.setState({
                spouseDobError: message
            });
        } else {
            this.setState({
                userDobError: message
            });
        }
    };

    setCanSave = (val) => {
        this.setState({
            canSave: val
        });
    };

    handleParticipantDobChange = (dob) => {
        this.setState({
            participantDob: dob
        });

        this.callSetCanSave(true);
    };

    handleSpouseDobChange = (dob, age) => {
        this.setState({
            spouseDob: dob,
            spouseAge: age
        });

        this.callSetCanSave(true);
    };

    handleChangeView = (period) => {
        this.setUserGoal(
            this.state.salary,
            this.state.spouseSalary,
            this.state.portion,
            this.state.spousePortion,
            period,
            this.state.isPercent,
            this.state.isPercentSpouse,
            this.state.additionalComp
        );

        this.setState({
            period: period
        });

        this.callSetCanSave(true);
    };

    handleChangeConfidenceLevel = (level) => {
        this.setState({
            confidenceLevel: level
        });

        this.callSetCanSave(true);
    };

    handleBlurSalary = (salary) => {
        this.setUserGoal(
            salary,
            this.state.spouseSalary,
            this.state.portion,
            this.state.spousePortion,
            this.state.period,
            this.state.isPercent,
            this.state.isPercentSpouse,
            this.state.additionalComp
        );

        this.setState({
            salary: salary
        });
    };

    handleBlurPortion = (portion, isPercent) => {
        this.setUserGoal(
            this.state.salary,
            this.state.spouseSalary,
            portion,
            this.state.spousePortion,
            this.state.period,
            isPercent,
            this.state.isPercentSpouse,
            this.state.additionalComp
        );

        this.setState({
            portion: portion,
            isPercent: isPercent
        });

        this.callSetCanSave(true);
    };

    handleNameChange = (name) => {
        this.setState({
            spouseName: name
        });

        this.callSetCanSave(true);
    };

    handleSpouseSalary = (spouseSalary) => {
        this.setUserGoal(
            this.state.salary,
            spouseSalary,
            this.state.portion,
            this.state.spousePortion,
            this.state.period,
            this.state.isPercent,
            this.state.isPercentSpouse,
            this.state.additionalComp
        );

        this.setState({
            spouseSalary: spouseSalary
        });
    };

    handleGenderChange = (gender) => {
        this.setState({
            spouseGender: gender
        });

        this.callSetCanSave(true);
    };

    handleAgeChange = (age) => {
        this.setState({
            desiredRetAge: age
        });
        if (this.state.spouseSaved) {
            this.callSetCanSave(true);
        }
    };

    handleSpousePortion = (portion, isPercentSpouse) => {
        this.setUserGoal(
            this.state.salary,
            this.state.spouseSalary,
            this.state.portion,
            portion,
            this.state.period,
            this.state.isPercent,
            isPercentSpouse,
            this.state.additionalComp
        );

        this.setState({
            spousePortion: portion,
            isPercentSpouse: isPercentSpouse
        });

        this.callSetCanSave(true);
    };

    handleAdditionalComp = (comp) => {
        this.setUserGoal(
            this.state.salary,
            this.state.spouseSalary,
            this.state.portion,
            this.state.spousePortion,
            this.state.period,
            this.state.isPercent,
            this.state.isPercentSpouse,
            comp
        );

        this.setState({
            additionalComp: comp
        });

        this.callSetCanSave(true);
    };

    handleAddSpouse = () => {
        this.setState({
            spouseSaved: true
        });
    };

    handleCancel = () => {
        this.setState({
            ...this.originalProps,
            showModal: false
        });
    };

    handleToggleModal = () => {
        this.setState({
            showModal: !this.state.showModal
        });
    };

    handleDeleteSpouse = () => {
        this.setState({
            deleteSpouse: !this.state.deleteSpouse
        });

        this.callSetCanSave(true);
    };

    handleSaveDelete = () => {
        this.setState({
            deleteSpouse: false,
            spouseSaved: false,
            spouseGoal: 0,
            spouseName: "",
            spouseSalary: 0,
            spouseDob: "",
            spouseGender: "",
            spouseAge: 0,
            desiredRetAge: 0,
            spousePortion: 75,
            isPercentSpouse: true
        });
    };

    handleNoPrompt = () => {
        this.setState({
            prompt: false
        });
    };

    callSetCanSave = (canSave) => {
        if (
            !this.state.canSave &&
            !this.state.errorId.length &&
            (!this.state.prompt || this.state.salary)
        ) {
            this.setCanSave(canSave);
        }
    };

    callUpdateSalaryReminder = () => {
        if (this.props.salaryReminder) {
            this.props.updateSalaryReminder();
        }
    };
    getDescription = () => {
        const value = Math.round(this.props.percentageOfGoal);
        const text = this.props.labelTranslations.ofMyGoal;
        return `${value}% ${text}`;
    };

    render() {
        const showModal = this.props.goalModalVisible;
        const progressClass = showModal ? "active" : "";
        const tabName = this.props.activeTab ? "how-do-i-compare" : "";
        const showBackdrop = showModal ? (
            <Backdrop handleBackdropClick={this.handleBackdropClick} />
        ) : null;

        let goalSegments;
        if (!_isEmpty(this.props.incomeParts)) {
            goalSegments = getRadialChartSegmentsFromIncomeParts(this.props.incomeParts);
        } else {
            goalSegments = getLoadingRadialChartSegments();
        }

        const wrapperId = this.state.ACCESSIBILITY.MY_GOAL["my-goal-percentage-value"];
        return (
            <div id="progress-to-goal" className={`${progressClass}`} data-paycheck={tabName}>
                {showBackdrop}
                <div className="radial-chart-and-shadow-wrapper">
                    <HiddenA11yWrapper id={wrapperId}>
                        {Math.round(this.props.percentageOfGoal)}%
                        {this.props.labelTranslations.ofMyGoal}
                    </HiddenA11yWrapper>
                    <div id="integrated-hdic">
                        <RadialChart
                            wrapperId="progress-to-goal-radial-chart-wrapper"
                            wrapperClass="progress-to-goal-radial-chart-wrapper"
                            segments={goalSegments}
                            handleClick={this.handleClick}
                            donutHoleClass="integrated-hdic-radial-chart-inner"
                            selectedSegmentCode={this.props.selectedSegmentCode}
                            arcAnimationDuration="2s"
                            arcAnimationDelay="0.5"
                            altText={this.props.labelTranslations.altText}
                            description={this.getDescription()}
                            tabIndex={this.props.tabIndex}
                            hasFocus={this.props.hasFocus}
                            onTabNav={this.props.onTabNav}
                        >
                            <text x="52%" y="56%">
                                <tspan
                                    className={
                                        "score integrated-hdic-score goal-percent" +
                                        (Math.round(this.props.percentageOfGoal) > 99
                                            ? " three-digits"
                                            : "")
                                    }
                                >
                                    {Math.round(this.props.percentageOfGoal)}%
                                </tspan>
                            </text>
                            <text
                                className="text-bold integrated-hdic-score-goal-subtext"
                                x="50%"
                                y="69%"
                                fill="#0473CE"
                            >
                                {this.props.labelTranslations.ofMyGoal}
                            </text>
                        </RadialChart>
                    </div>
                    {!ObjectUtil.isUndefinedOrNull(this.state.salary) ? (
                        <GoalModal
                            showModal={showModal}
                            handleChangeView={this.handleChangeView}
                            handleChangeConfidenceLevel={this.handleChangeConfidenceLevel}
                            handleBlurPortion={this.handleBlurPortion}
                            handleBlurSalary={this.handleBlurSalary}
                            handleNameChange={this.handleNameChange}
                            handleParticipantDobChange={this.handleParticipantDobChange}
                            handleSpouseDobChange={this.handleSpouseDobChange}
                            handleSpouseSalary={this.handleSpouseSalary}
                            handleGenderChange={this.handleGenderChange}
                            handleAgeChange={this.handleAgeChange}
                            handleAdditionalComp={this.handleAdditionalComp}
                            handleSpousePortion={this.handleSpousePortion}
                            handleSpouseView={this.handleSpouseView}
                            hasOtherVariableComp={this.props.hasOtherVariableComp}
                            additionalComp={this.state.additionalComp}
                            portion={this.state.portion}
                            userGoal={this.state.userGoal}
                            salary={this.state.salary}
                            dob={this.state.participantDob}
                            period={this.state.period}
                            confidenceLevel={this.state.confidenceLevel}
                            spouseSaved={this.state.spouseSaved}
                            userName={this.props.userName}
                            spouseName={this.state.spouseName}
                            spouseGender={this.state.spouseGender}
                            spouseSalary={this.state.spouseSalary}
                            spouseDob={this.state.spouseDob}
                            spouseAge={this.state.spouseAge}
                            desiredRetAge={this.state.desiredRetAge}
                            spousePortion={this.state.spousePortion}
                            spouseGoal={this.state.spouseGoal}
                            totalGoal={this.state.totalGoal}
                            handleAddSpouse={this.handleAddSpouse}
                            canSave={this.state.canSave}
                            isPercent={this.state.isPercent}
                            isPercentSpouse={this.state.isPercentSpouse}
                            percentageOfGoal={this.props.percentageOfGoal}
                            dobDisabled={this.props.dobDisabled}
                            getAge={this.props.getAge}
                            isEmulator={this.props.isEmulator}
                            labelTranslations={this.props.labelTranslations.goalModal}
                            tooltipTranslations={this.props.tooltipTranslations}
                            toggleProgressModal={this.props.toggleProgressModal}
                            handleTabClick={this.handleTabClick}
                            handleSubmit={this.handleSubmit}
                            handleError={this.handleError}
                            handleClose={this.handleClose}
                            userTabActive={this.state.userTabActive}
                            errorId={this.state.errorId}
                            spouseDobError={this.state.spouseDobError}
                            userDobError={this.state.userDobError}
                            updateErrorMessage={this.updateErrorMessage}
                            deleteSpouse={this.state.deleteSpouse}
                            handleDeleteSpouse={this.handleDeleteSpouse}
                            mtrGoalModalEvents={mtrGoalModalEvents}
                            eventBus={this.props.eventBus}
                            mustEnterSalary={this.props.mustEnterSalary}
                            prompt={this.state.prompt}
                            callSetCanSave={this.callSetCanSave}
                            maxSalary={this.state.maxSalary}
                            hasReducedSalaryOff={this.props.hasReducedSalaryOff}
                            getHasReducedSalary={this.props.getHasReducedSalary}
                            isApple={this.props.isApple}
                            defaultIncomeGoal={this.props.defaultIncomeGoal}
                            salaryReminder={this.props.salaryReminder}
                            promptForSalary={this.props.promptForSalary}
                            isLoading={this.props.isLoading}
                            isJPM={this.props.isJPM}
                        />
                    ) : null}
                </div>
            </div>
        );
    }
}

Goal.propTypes = {
    hasOtherVariableComp: PropTypes.bool.isRequired,
    additionalComp: PropTypes.number,
    salary: PropTypes.number.isRequired,
    dob: PropTypes.string.isRequired,
    portion: PropTypes.number.isRequired,
    period: PropTypes.string.isRequired,
    confidenceLevel: PropTypes.number.isRequired,
    spouseSaved: PropTypes.bool.isRequired,
    userName: PropTypes.string.isRequired,
    spouseName: PropTypes.string,
    spouseGender: PropTypes.string,
    spouseSalary: PropTypes.number,
    spouseDob: PropTypes.string,
    spouseAge: PropTypes.number.isRequired,
    desiredRetAge: PropTypes.number,
    spousePortion: PropTypes.number,
    saveChanges: PropTypes.func.isRequired,
    isPercent: PropTypes.bool.isRequired,
    isPercentSpouse: PropTypes.bool,
    percentageOfGoal: PropTypes.number.isRequired,
    dobDisabled: PropTypes.bool.isRequired,
    getAge: PropTypes.func.isRequired,
    isEmulator: PropTypes.bool.isRequired,
    tooltipTranslations: PropTypes.object,
    labelTranslations: PropTypes.object,
    toggleProgressModal: PropTypes.func.isRequired,
    mustEnterSalary: PropTypes.bool,
    primaryTabIndexUpdated: PropTypes.bool,
    hasReducedSalaryOff: PropTypes.func,
    getHasReducedSalary: PropTypes.bool,
    isApple: PropTypes.func,
    defaultIncomeGoal: PropTypes.number,
    salaryReminder: PropTypes.bool,
    promptForSalary: PropTypes.bool,
    updateSalaryReminder: PropTypes.func,
    translations: PropTypes.any,
    incomeParts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired
        })
    ).isRequired,
    selectedSegmentCode: PropTypes.string,
    isLoading: PropTypes.bool,
    isJPM: PropTypes.func,
    eventBus: PropTypes.object,
    goalModalVisible: PropTypes.bool,
    activeTab: PropTypes.bool,
    tabIndex: PropTypes.any,
    onTabNav: PropTypes.any,
    hasFocus: PropTypes.any,
    originalProps: PropTypes.object
};
