import React from "react";

import { Loader, LoadingBar } from "gw-shared-components";
import PropTypes from "prop-types";

export { default as LiatShell } from "./LiatShell";
export { default as DashboardShell } from "./DashboardShell";

export const WidgetsShell = () => {
    return (
        <div className="bottom-widgets__load-shell">
            <Loader useSvg={true} />
        </div>
    );
};

export const WantToShell = () => {
    return <LoadingBar barWidth="80%" loadingTime={4} position="center" restartLoading />;
};

export const SidebarShell = () => {
    return <LoadingBar barWidth="80%" loadingTime={4} position="center" restartLoading />;
};

export const QuickViewShell = () => {
    return <LoadingBar barWidth="80%" loadingTime={4} position="center" restartLoading />;
};

export const MaMessagingShell = () => {
    return (
        <div className="ma-messaging__load-shell">
            <Loader useSvg={true} />
        </div>
    );
};

SidebarShell.propTypes = {
    loaded: PropTypes.string
};
