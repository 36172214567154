import {
    SET_COMPANY_MATCH_PROFIT_SHARING_MESSAGE,
    SET_NEXT_STEPS
} from "../../actions/nextSteps/nextStepTypes";
import { SET_TRADE_OFFS } from "../../actions/tradeoffMessaging/tradeoffMessagingTypes";

const initialState = {
    bnsMsgKey: "", // @TODO REMOVE ??? View Model Only property that holds the msg key to attempt to use for the BNS message
    bnsMsgParams: [], // @TODO REMOVE ??? View Model only collection of params to be dynamically parsed in the BNS message
    nextStepUrl: "", // View Model only flag indicating of the best next step has a url that should be shown
    companyMatch: { matchAndProfitSharingMessages: [] },
    defaultNextStepMsg: "",
    formattedMessage: "", // View Model only key to hold the formatted Best Next Step
    settings: {
        // SETTINGS IF FORMERLY THE APPLICATIONSETTINGS --> BNS
        appliesToTerminated: false,
        enrollToMaximizer: false,
        incrementDollar: null,
        incrementPct: null,
        message: "",
        recommended: false,
        recommendedDollar: null,
        recommendedPct: null,
        recommendedValue: 0,
        // AG - these two values don't come from the API response, but are used to simplify the use of this object in the views
        roundedRecommendedValue: null,
        staticMessage: null,
        type: "",
        url: ""
    },
    showNextSteps: true, // View Model only flag indicating if best next steps should be shown. This is here in order to avoid getters that repeatedly process the 'show' logic triggering excess digest cycles.
    tradeOffMessages: {
        future: [
            { amount: 0, message: "" },
            { amount: 0, message: "" }
        ],
        today: [{ amount: 0, message: "" }],
        reachedLimits: { limitMsg: "No Change", showLimitMsg: false, source: "", sourcecnt: 0 }
    }
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_TRADE_OFFS:
            return { ...state, tradeOffMessages: payload };
        case SET_COMPANY_MATCH_PROFIT_SHARING_MESSAGE: {
            const updatedCompanyMatch = { ...state.companyMatch };
            updatedCompanyMatch.matchAndProfitSharingMessages = payload;
            return { ...state, companyMatch: updatedCompanyMatch };
        }
        case SET_NEXT_STEPS: {
            const settings = { ...state.settings, ...payload.settings };
            return {
                ...state,
                settings,
                nextStepUrl: payload.nextStepUrl,
                formattedMessage: payload.formattedMessage,
                defaultNextStepMsg: payload.defaultNextStepMsg,
                showNextSteps: payload.showNextSteps
            };
        }
        default:
            return state;
    }
};
