import {
    ROUTE_RETIREMENT_PLANNER,
    ROUTE_RETIREMENT_PLANNER_INDEX,
    ROUTE_RETIREMENT_PLANNER_PAYCHECK,
    ROUTE_RETIREMENT_PLANNER_INCOME_EVENT,
    ROUTE_RETIREMENT_PLANNER_SPENDING_GOAL,
    ROUTE_RETIREMENT_PLANNER_SS_INCOME
} from "../../../../routes";

export default [
    ROUTE_RETIREMENT_PLANNER,
    ROUTE_RETIREMENT_PLANNER_INDEX,
    ROUTE_RETIREMENT_PLANNER_PAYCHECK,
    ROUTE_RETIREMENT_PLANNER_INCOME_EVENT,
    ROUTE_RETIREMENT_PLANNER_SPENDING_GOAL,
    ROUTE_RETIREMENT_PLANNER_SS_INCOME
];
