/**
 * @name app.infrastructure.domainModel.DeferralAutoIncrease
 * @date 1/26/2015
 * @copyright 2015 Empower Retirement
 * @description
 *
 *        Definition of the DeferralAutoIncrease domain model object.
 * @example
 * "autoIncrease": // do not include if auto increase is not needed
 *    {
 *        frequency: 1
 *        increaseValue: 3000
 *        nextScheduleDate: "12-Jun-2016"
 *        pctAmtCode: "AMT"
 *        stopAtValue: 18000
 *    }
 */

"use strict";

class DeferralAutoIncrease {
    constructor(config) {
        this.frequency = 0;
        this.increaseValue = 0.0;
        this.stopAtValue = 0.0;
        this.nextScheduleDate = null;
        this.pctAmtCode = "";

        if (config) {
            this.frequency = config.frequency;
            this.increaseValue = config.increaseValue;
            this.stopAtValue = config.stopAtValue;
            this.nextScheduleDate = config.nextScheduleDate;
            this.pctAmtCode = config.pctAmtCode;
        }
    }
}

export default DeferralAutoIncrease;
