import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";

const SaveButton = ({ showSaveBtn, handleSave, customClass }) => {
    const save = selectTranslations("app")?.common?.labels?.save;
    return showSaveBtn ? (
        <div className={`hsa-tab-save-button ${customClass}`}>
            <button className={"btn btn-primary btn-save__hsa review-button"} onClick={handleSave}>
                {save}
            </button>
        </div>
    ) : null;
};
SaveButton.propTypes = {
    showSaveBtn: PropTypes.bool,
    handleSave: PropTypes.func,
    customClass: PropTypes.string
};
export { SaveButton };
