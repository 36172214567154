import { AxiosResponse } from "axios";

import RequestFactory from "../factories/RequestFactory";
import { executeRequest } from "../utils/apiUtils.js";
import { getUrl } from "../utils/urlUtils.js";

interface DeminModalTrackingResponse {
    data: [];
    error: string;
    logInfo: {};
    notifications: [];
    status: string;
}

const DEMIN_MODAL_TRACKING = "DEMIN_MODAL_TRACKING";

export const updateDeminModalTracking = async () => {
    const params = {
        key: DEMIN_MODAL_TRACKING
    };
    const url = getUrl("updateModalDeminTracking");
    const method = RequestFactory.HTTP_METHOD.POST;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const request = RequestFactory.create(url, params, method, contentType, null);

    const response: AxiosResponse<DeminModalTrackingResponse> = await executeRequest(
        request,
        "updateModalDeminTracking"
    );
    return response.data;
};

export default updateDeminModalTracking;
