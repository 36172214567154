import {
    RETIREMENT_INCOME_ROUTE,
    HEALTHCARE_COSTS_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    MAXIMIZER_ROUTE,
    PRE_RETIREMENT_ROUTE,
    WHEN_CAN_I_RETIRE_ROUTE,
    HSA_ROUTE,
    SOCIAL_SECURITY_ROUTE,
    OTHER_ASSETS_ROUTE,
    INCOME_GAP_ROUTE
} from "../../routes";

import TABS from "./Tabs";

const NAVIATION_TABS = {
    RETIREMENT_INCOME: {
        title: TABS.RETIREMENT_INCOME,
        route: RETIREMENT_INCOME_ROUTE,
        color: "retirementIncome-color"
    },
    HEALTHCARE_COSTS: {
        title: TABS.HEALTHCARE_COSTS,
        route: HEALTHCARE_COSTS_ROUTE,
        color: "retirementIncome-color"
    },
    HOW_DO_I_COMPARE: {
        title: TABS.HOW_DO_I_COMPARE,
        route: HOW_DO_I_COMPARE_ROUTE,
        color: "retirementIncome-color"
    },
    PRE_RETIREMENT: {
        title: TABS.PRE_RETIREMENT,
        route: PRE_RETIREMENT_ROUTE,
        color: "retirementIncome-color"
    },
    WHEN_CAN_I_RETIRE: {
        title: TABS.WHEN_CAN_I_RETIRE,
        route: WHEN_CAN_I_RETIRE_ROUTE,
        color: "retirementIncome-color"
    },
    MAXIMIZER: {
        title: TABS.MAXIMIZER,
        route: MAXIMIZER_ROUTE,
        color: "retirementIncome-color"
    },
    HSA: {
        title: TABS.HSA,
        route: HSA_ROUTE,
        color: "hsa-color"
    },
    SOCIAL_SECURITY: {
        title: TABS.SOCIAL_SECURITY,
        route: SOCIAL_SECURITY_ROUTE,
        color: "governmentBenefits-color"
    },
    OTHER_ASSETS: {
        title: TABS.OTHER_ASSETS,
        route: OTHER_ASSETS_ROUTE,
        color: "otherAssets-color"
    },
    INCOME_GAP: {
        title: TABS.INCOME_GAP,
        route: INCOME_GAP_ROUTE,
        color: "incomeGap-color"
    }
};

export default NAVIATION_TABS;
