import { batch } from "react-redux";

import {
    setParticipantProjectionsMap,
    setParticipantLifeExpectancyAge,
    setParticipantLifeDefaultAge
} from "../actions/participantDetails/participantDetailsActions";
import { setSpouseProjectionsMap } from "../actions/spouse/spouseActions";
import { canShowHealthCareView } from "../selectors/featureFlagSelectors";
import { getProjectionFactors } from "../services/projectionFactorsService";

export const updateProjectionFactors = (shouldSetLEAge) => {
    return (dispatch, getState) => {
        const state = getState();
        const hasSpouse = state.spouse.exists;
        const checkPersonHealthCondition = canShowHealthCareView(
            state.participant,
            state.primaryPlan
        );

        const config = {
            individualId: state.participant.individualId,
            groupId: state.primaryPlan.id,
            age: state.participant.age,
            gender: state.participant.gender,
            checkPersonHealthCondition
        };

        return getProjectionFactors(config, hasSpouse).then((result) => {
            const personId = String(state.participant.personId);
            const spouseId = String(state.spouse.personId);
            const participantProjections = result[personId].projectionsMap;
            const spouseProjections = hasSpouse ? result[spouseId].projectionsMap : null;
            const participantLEAgeDefault = result[personId].defaultLEAge;
            const participantAgeOverride = result[personId].planToAgeOverride;

            batch(() => {
                dispatch(setParticipantProjectionsMap(participantProjections));

                if (shouldSetLEAge) {
                    if (!participantAgeOverride) {
                        dispatch(setParticipantLifeExpectancyAge(participantLEAgeDefault));
                    }
                    dispatch(setParticipantLifeDefaultAge(participantLEAgeDefault));
                }

                if (spouseProjections) {
                    dispatch(setSpouseProjectionsMap(spouseProjections));
                }
            });
        });
    };
};
