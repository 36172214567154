import StringUtil from "core-ui/client/src/app/StringUtil";
import { isUndefinedOrNull, isArrayEmpty, isUndefined, isNull } from "gw-shared-components";

import DeferralMoneyType from "../../constants/DeferralMoneyType";
import Deferrals, { getActiveDeferral } from "../deferralsLogic/deferrals";

/**
 * FUNCTIONS FOUND IN THIS FILE
 * @getDefaultNextStepMsg
 * @getShowBestNextSteps
 * @getRoundedNextStepValue
 * @getTargetValue
 */

/**
 * @memberOf common.service.business.RetirementSavingsService
 * @description get default
 * @returns {Object|*}
 */
export const getDefaultNextStepMsg = function (bns, message, recommendedValue) {
    const isDollar = bns.type === DeferralMoneyType.DOLLAR;
    const bnsValue = recommendedValue ? recommendedValue : bns.roundedRecommendedValue;
    const localizedMsg = message + (isDollar ? " $" + bnsValue : " " + bnsValue + "% ");

    return StringUtil.supplant(localizedMsg, [bnsValue]);
};

/**
 * @memberOf common.service.business.RetirementSavingsService
 * @description should show bns
 * @returns {boolean}
 */
export const getShowNextSteps = (
    bestNextSteps,
    deferrals,
    isTerminated,
    showTradeOff,
    primaryPlan
) => {
    //multi-deferral only matters for non-terminated users
    const bns = bestNextSteps;
    const isRecommended = bns.recommended;
    const hasBNS = !isUndefinedOrNull(bestNextSteps);
    // determine if BNS can be shown
    const multiDeferral = deferrals.length > 1 && !isTerminated;
    const activeDeferral = getActiveDeferral(deferrals);

    const noDeferrals = deferrals <= 0;
    const showBestNextSteps = hasBNS && isRecommended && (!isTerminated || bns.appliesToTerminated);

    return (
        showBestNextSteps &&
        !showTradeOff &&
        isNextStepMoreThanFuture(bestNextSteps, activeDeferral) &&
        getValidBnsValueOrStatic(bestNextSteps, deferrals, activeDeferral, primaryPlan) &&
        !multiDeferral &&
        !noDeferrals
    );
};

/**
 * @memberOf common.service.business.RetirementSavingsService
 * @description
 * Should be rounding the recommended BNS value up to the nearest $25 or 1% increment, as long as that is not above
 * the max editable value. if it is, it should return the max editable value.
 * @returns {*}
 */
export const getRoundedNextStepValue = function (
    deferrals,
    activeDeferral,
    primaryPlan,
    bestNextSteps,
    recommendedValue
) {
    if (isArrayEmpty(deferrals) || isUndefinedOrNull(deferrals) || deferrals.length > 1)
        return null;
    const deferralService = new Deferrals(deferrals, primaryPlan, null, activeDeferral);
    const deferralStep = deferralService.getDeferralStep();
    const value = !isUndefinedOrNull(recommendedValue)
        ? recommendedValue
        : bestNextSteps.recommendedValue;

    const target = Math.round(getTargetValue(bestNextSteps, value) * 1000) / 1000; // precision is important here, or numbers get inflated incorrectly
    if (!isUndefined(target) && !isNaN(target)) {
        // round the target value to the nearest slider step
        const roundedTarget = Math.ceil(target / deferralStep) * deferralStep;
        // round the max slider to the nearest slider step
        const roundedMaxSlider =
            Math.ceil(deferralService.getMaxContributionSliderRate() / deferralStep) * deferralStep;
        // return target only if value is above deferral value and below max slider
        //todo: possibly convert for dollar deferrals
        // shouldnt this be prior value comparison ?
        const originalContrib = activeDeferral.priorValue
            ? activeDeferral.priorValue
            : activeDeferral.value;
        if (
            roundedTarget <= roundedMaxSlider &&
            roundedTarget > originalContrib &&
            roundedTarget >= deferralService.getMinContributionRate() &&
            isNextStepMoreThanFuture(roundedTarget, activeDeferral) &&
            !deferralService.getIsFrozenDeferral()
        ) {
            return roundedTarget;
        }
        return null;
    }
};

export const isNextStepMoreThanFuture = function (bnsValue, deferral) {
    if (deferral && deferral.futureDated) {
        return bnsValue > deferral.futureDated.value;
    }
    return true;
};

/**
 * @memberOf common.service.business.RetirementSavingsService
 * @description Returns the maximum retirement age for the participant.
 * @returns {number}
 */
export const getTargetValue = function (bestNextSteps, recommendedValue) {
    if (!isUndefinedOrNull(bestNextSteps) && bestNextSteps.recommended) {
        return recommendedValue;
    }
    return false;
};

export const getValidBnsValueOrStatic = (
    nextSteps,
    deferrals,
    activeDeferral,
    primaryPlan,
    forceValue = false
) => {
    const nonStatic = !nextSteps.staticMessage || forceValue;
    const recommended = nextSteps.recommendedValue;

    if (nextSteps && nonStatic) {
        if (!deferrals || deferrals.length === 0) {
            return false;
        }
        return !isNull(
            getRoundedNextStepValue(deferrals, activeDeferral, primaryPlan, nextSteps, recommended)
        );
    }

    return true;
};
