import {
    SET_BASELINE_PROJECTED_INCOME,
    SET_BASELINE_RETIREMENT_AGE,
    SET_BASELINE_EQUITY_MIX,
    SET_BASELINE_DEFERRALS,
    SET_BASELINE_SALARY,
    SET_BASELINE_DEFERRAL_SUMMARY_TOTAL,
    SET_BASELINE_DEFERRAL_TOTAL_BASE_AND_VAR
} from "../../actions/baseline/baselineTypes";
import { SET_INIT_DATA } from "../../actions/initData";
import { hasHsaPlan } from "../../business/hsaLogic/hsa";
import ReferenceData from "../../constants/ReferenceData";
import { findPrimaryPlan } from "../../utils/planUtils";

/*
 *   This reducer should only get updated in initial load of get-init-data.
 *   these values are being stored as our baseline to compare against any participant changes
 */

const initialState = {
    deferrals: [],
    deferralSetupConfig: {
        deferralSummaryMax: 25,
        deferralSummaryTotal: 0,
        deferralTotalBasePct: 0,
        deferralTotalVarPct: 0
    },
    hsaPlan: null,
    investments: { equityMix: 0.0 },
    projectedIncome: {
        baselineSet: false,
        details: null,
        incomeGap: 0,
        incomeGoalValue: 0,
        incomeParts: [],
        limitsHit: null,
        messages: null,
        percentageOfGoal: 0,
        total: 0
    },
    retirementAge: ReferenceData.DEFAULT_RETIREMENT_AGE,
    salary: { base: 0, variable: 0, total: 60000, tempVariable: null, source: "" }
};

export default (state = initialState, action) => {
    if (!action) return state;
    const { type, payload } = action;
    switch (type) {
        case SET_INIT_DATA: {
            const primaryPlan = findPrimaryPlan(payload.user.plans);
            const equityMix = primaryPlan.equityPercent * 100;
            const { retirementAge, deferrals, salary } = payload.user;
            const hsaPlan = hasHsaPlan(payload.user.plans);
            return {
                ...state,
                retirementAge,
                deferrals,
                salary,
                hsaPlan,
                investments: { ...state.investments, equityMix }
            };
        }
        case SET_BASELINE_PROJECTED_INCOME: {
            const projectedIncome = { ...state.projectedIncome, ...payload, baselineSet: true };
            return { ...state, projectedIncome };
        }
        case SET_BASELINE_RETIREMENT_AGE:
            return { ...state, retirementAge: payload };
        case SET_BASELINE_EQUITY_MIX: {
            const investments = { ...state.investments, equityMix: payload };
            return { ...state, investments };
        }
        case SET_BASELINE_DEFERRALS:
            return { ...state, deferrals: payload };
        case SET_BASELINE_SALARY:
            return { ...state, salary: payload };

        case SET_BASELINE_DEFERRAL_SUMMARY_TOTAL: {
            const setDeferralSetupConfig = {
                deferralSummaryTotal: payload.deferralSummaryTotal,
                deferralSummaryMax: payload.deferralSummaryMax
            };
            return {
                ...state,
                deferralSetupConfig: { ...state.deferralSetupConfig, ...setDeferralSetupConfig }
            };
        }
        case SET_BASELINE_DEFERRAL_TOTAL_BASE_AND_VAR: {
            const setDeferralSummaryConfig = {
                deferralTotalBasePct: payload.deferralTotalBasePct,
                deferralTotalVarPct: payload.deferralTotalVarPct
            };
            return {
                ...state,
                deferralSetupConfig: { ...state.deferralSetupConfig, ...setDeferralSummaryConfig }
            };
        }
        default:
            return state;
    }
};
