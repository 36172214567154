const loadRedwoodCss = ({ integratedCSS }: { integratedCSS: string }) => {
    const link = document.createElement("link");
    link.href = integratedCSS;
    link.setAttribute("rel", "stylesheet");
    link.setAttribute("type", "text/css");

    const head = document.getElementsByTagName("head")[0];
    head.insertBefore(link, head.firstChild);
};

export default loadRedwoodCss;
