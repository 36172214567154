/**
 * @name app.common.model.domain.User
 * @date 1/16/2015
 * @copyright 2015 Empower Retirement
 * @description
 *
 *    Domain Object.
 *
 */

import HealthViewData from "./HealthViewData";
import HowDoICompare from "./HowDoICompare";
import IncomeGoal from "./IncomeGoal";
import SpecialLimit from "./SpecialLimit";
import ssInfo from "./SsInfo";

/**
 * The object constructor.
 * @param {object} config Configuration object to populate the model properties. Note that only primitives are used.
 * @constructor
 */
class User {
    constructor(config) {
        this.individualId = "";
        this.investorId = "";
        this.personId = "";
        this.pcapPersonId = null;
        this.firstName = "";
        this.middleName = "";
        this.lastName = "";
        this.age = 0;
        this.gender = "";
        this.clientId = "";
        this.headOfHousehold = false;
        this.plans = [];
        this.outsideAssets = [];
        this.outsideAssetCategories = [];
        this.hdic = new HowDoICompare();
        this.healthViewData = new HealthViewData();
        this.type = "";
        this.dateOfBirth = "";
        this.dateOfBirthSource = "";
        this.specialLimit = new SpecialLimit();
        this.ssInfo = new ssInfo();
        this.externalAssets = null;
        this.retirementAssets = [];
        this.incomeGoal = new IncomeGoal();
        this.retirementAge = null;
        this.hasReducedSalary = false;
        // this.spouse = null;
        // all available deferrals to show sliders for incl. HSA from other plans
        this.deferrals = [];
        this.newDeferrals = [];
        this.availableDeferrals = [];
        this.newAvailableDeferrals = [];
        this.isAAFeatureEnabled = [];
        this.salary = 50000;
        this.projectionsMap = [];
        this.ssBenefitsMap = [];
        this.linkedAccountBalances = null;
        this.adjustedLEAge = -1;
        this.defaultLEAge = -1;

        if (config) {
            this.individualId = config.individualId;
            this.investorId = config.investorId;
            this.personId = config.personId;
            this.pcapPersonId = config.pcapPersonId;
            this.firstName = config.firstName;
            this.middleName = config.middleName;
            this.lastName = config.lastName;
            this.age = config.age;
            this.gender = config.gender;
            this.clientId = config.clientId;
            this.headOfHousehold = config.headOfHousehold;
            this.type = config.type;
            this.plans = config.plans;
            this.outsideAssets = config.outsideAssets;
            this.outsideAssetCategories = config.outsideAssetCategories;
            this.externalAssets = config.externalAssets;
            this.hdic = null;
            this.healthViewData = null;
            this.dateOfBirth = config.dateOfBirth;
            this.dateOfBirthSource = config.dateOfBirthSource;
            this.specialLimit = config.specialLimit;
            this.ssInfo = config.ssInfo;
            this.retirementAssets = config.retirementAssets;
            this.incomeGoal = config.incomeGoal;
            this.retirementAge = config.retirementAge;
            this.deferrals = config.deferrals;
            this.newDeferrals = config.newDeferrals;
            this.availableDeferrals = config.availableDeferrals;
            this.newAvailableDeferrals = config.newAvailableDeferrals;
            this.isAAFeatureEnabled = config.isAAFeatureEnabled;
            this.salary = config.salary;
            this.projectionsMap = config.projectionsMap;
            this.ssBenefitsMap = config.ssBenefitsMap;
            this.hasReducedSalary = config.hasReducedSalary;
            this.linkedAccountBalances = config.linkedAccountBalances;
            this.adjustedLEAge = config.adjustedLEAge;
            this.defaultLEAge = config.defaultLEAge;
        }
    }
}

export default User;
