/**
 *
 * @name FEForecastModalEvents
 * @memberOf projectedIncome.view.events
 * @copyright 2015 Empower Retirement
 * @description These are the events for the review Changes modal
 * @property {string} CLOSE FEForecastModalEvent.close_clicked_event
 * @property {string} CLICK_OUT FEForecastModalEvent.modal_shade_clicked_event
 *
 */

const root = "FEForecastModalEvent.";

const FEForecastEvents = {
    CLOSE: root + "close_clicked_event",
    CLICK_OUT: root + "modal_shade_clicked_event",
    CLICK_ASSUMPTIONS: root + "clicked_assumptions",
    REQUEST_FE_MODAL: root + "request_to_show_fe_modal",
    SHOW_FE_MODAL: root + "show_fe_modal",
    SHOW_FE_ERROR: root + "show_fe_error"
};
export default FEForecastEvents;
