import React, { forwardRef } from "react";

import PropTypes from "prop-types";

const PaycheckItemDetailsPopover = forwardRef(({ ...props }, ref) => {
    const { styles = null, title = "", content = null } = props;
    return (
        <div
            className="popoverContainer"
            style={styles}
            data-testid="paycheck-item-details-popover"
            ref={ref}
        >
            <div
                className="popoverMessage"
                role="alert"
                aria-describedby="popoverMessageTitle popoverMessageList"
            >
                <div id="popoverMessageTitle" data-testid="popover-title">
                    {title}
                </div>
                <ul id="popoverMessageList">
                    {content.map((plan) => {
                        return (
                            <li key={plan.id} data-testid="popover-plan-name">
                                {plan.name}
                            </li>
                        );
                    })}
                </ul>
            </div>

            <div className="popoverMessageArrow arrowDown">
                <div className="insideArrowDown" />
            </div>
        </div>
    );
});

PaycheckItemDetailsPopover.propTypes = {
    styles: PropTypes.object,
    title: PropTypes.string,
    content: PropTypes.any
};

PaycheckItemDetailsPopover.displayName = "PaycheckItemDetailsPopover";

export default PaycheckItemDetailsPopover;
