const SOCIAL_SECURITY_CONSTANTS = {
    SSA_GOV_QUICKCALC: "https://www.ssa.gov/OACT/quickcalc/",
    SSA_GOV_QUICKCALC_FAQ: "http://www.ssa.gov/OACT/quickcalc/faqs.html",
    ANNUAL_AMOUNT_PREFERENCES_OPTIONS: {
        NONE: "NONE",
        CALCULATED: "CALCULATED",
        USER_INPUT: "USER_INPUT"
    }
};

export default SOCIAL_SECURITY_CONSTANTS;
