import React, { useCallback } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { LoadingBar } from "gw-shared-components";

import { sentrySkeletonVisibleMetrics } from "../../../../utils/sentryMetricsLogging";

const DEFAULT_PHRASES = [
    "You're just moment away from your future...",
    "We're creating your complete financial picture...",
    "Get ready for your financial future..."
];

const LiatShell = () => {
    const translations = selectTranslations("paycheck")?.loadingBar;
    const liatShellRefCallback = useCallback((node: HTMLDivElement) => {
        if (node) {
            sentrySkeletonVisibleMetrics();
        }
    }, []);

    const phrases = translations ? (Object.values(translations) as string[]) : DEFAULT_PHRASES;

    return (
        <div ref={liatShellRefCallback} className="liat__load-shell">
            <LoadingBar position="center" phrases={phrases} />
        </div>
    );
};

export default LiatShell;
