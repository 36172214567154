import React from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";

import getContributionDetails from "../../helpers/getContributionDetails";

//  This button is related to the following user stories
//  https://jira.retirementpartner.com/browse/CDX-42796
//  https://jira.retirementpartner.com/browse/CDX-42299

const ContributionButton = () => {
    const showButton = window.location.href.indexOf("showContribution") !== -1;
    const detail = getContributionDetails(window.location.href);

    if (!showButton) {
        return null;
    }

    dispatchAmplitude({
        eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
        selection: "custom_show_contribution_button"
    });

    return (
        <button
            type="button"
            className="btn btn-pcap-primary btn-block"
            value="Add now"
            name="add-other-assets"
            onClick={() => {
                const evt = new CustomEvent("pc_editContributions", {
                    detail
                });
                window.dispatchEvent(evt);
            }}
        >
            Edit Contribution
        </button>
    );
};

export default ContributionButton;
