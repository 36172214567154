import React, { memo, useEffect, useMemo, useState } from "react";

import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { filter as _filter } from "lodash";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
    dispatchAmplitude,
    dispatchEventBus,
    setCurrentFault
} from "../../../actions/shared/sharedActionCreators";
import SOCIAL_SECURITY_CONSTANTS from "../../../constants/SocialSecurityConstants";
import { MANAGED_ACCOUNT_SOCIAL_SECURITY_EVENTS } from "../../../events/managedAccountSocialSecurity";
import { SSEvents } from "../../../events/SSEvents";
import LoggerFactory from "../../../factories/LoggerFactory";
import { useIsEmulator } from "../../../hooks/useEmulatorType";
import { saveSocialSecurityChange } from "../../../middleware/socialSecurityMiddleware";
import { getPAEFault } from "../../../utils/paeUtils";
import getDropDownOptions from "../helpers/getDropdownOptions/getDropdownOptions";
import { PersonDetails, SocialSecurityTableModal } from "../index";

const DEFAULT_SPOUSE_BENEFIT_VALUES = [];

const joinBenefitsWithCurrentOption = (currentValue, benefitsValues) => {
    const currentValueWithBenefits = benefitsValues.find((item) => item.age === currentValue?.age);
    return {
        ...currentValue,
        ...currentValueWithBenefits
    };
};

const getDropDownDetails = (startReceiveAge, benefitsValues = [], options) => {
    const currentValue = options.find((opt) => opt.age === startReceiveAge);
    const currentValueWithBenefits = joinBenefitsWithCurrentOption(currentValue, benefitsValues);

    const amount = currentValueWithBenefits.monthly || 0;

    return {
        currentValueWithBenefits,
        amount
    };
};

const SocialSecurityModal = ({ show, setShowModal, participantRecieveAge, spouseRecieveAge }) => {
    const dispatch = useDispatch();
    const logger = LoggerFactory.getInstance("SocialSecurity:");
    const maSocialSecurityTranslations = selectTranslations("maSocialSecurity")?.SocialSecurity;

    const participant = useSelector((state) => state.participant);
    const { socialSecuritySpouse, socialSecurityUser } = useSelector(
        (state) => state.socialSecurity
    );

    const {
        benefitsValues,
        socialSecurityIncome,
        atRetirement: userAtRetirement,
        monthlyAmountOverride: userMonthlyAmountOverride,
        estimatedAmountCheckboxStatus: userEstAmountCheckbox
    } = socialSecurityUser;

    const spouse = useSelector((state) => state.spouse);
    const baseline = useSelector((state) => state.baseline);
    const baselineRetireAge = baseline.retirementAge;
    const spouseAtRetirement =
        spouse.exists && socialSecuritySpouse ? socialSecuritySpouse.atRetirement : false;

    const [showSocialSecurityTable, setShowSocialSecurityTable] = useState(false);

    const [estimatedAmountCheckboxStatus, setEstimatedAmountCheckboxStatus] =
        useState(userEstAmountCheckbox);
    const [spouseOverrideChecked, setSpouseOverrideChecked] = useState(
        socialSecuritySpouse?.estimatedAmountCheckboxStatus || false
    );

    const [monthlyAmountOverride, setMonthlyAmountOverride] = useState(userMonthlyAmountOverride);
    const [spouseMonthlyAmountOverride, setSpouseMonthlyAmountOverride] = useState(
        socialSecuritySpouse?.monthlyAmountOverride || ""
    );

    const ssSpouseBenefitsMap =
        socialSecuritySpouse?.benefitsValues || DEFAULT_SPOUSE_BENEFIT_VALUES;
    const spouseSocialSecurityIncome = socialSecuritySpouse?.socialSecurityIncome;

    const [userMaxMonthlyLimit, setUserMaxMonthlyLimit] = useState(0);
    const [spouseMaxMonthlyLimit, setSpouseMaxMonthlyLimit] = useState(
        socialSecuritySpouse && spouse.exists
            ? ssSpouseBenefitsMap.length > 0
                ? ssSpouseBenefitsMap[70]
                : 0
            : 0
    );

    const [startReceivingAge, setStartReceivingAge] = useState(participantRecieveAge);
    const [startSpouseReceivingAge, setStartSpouseReceivingAge] = useState(spouseRecieveAge);
    const [showError, setShowError] = useState(false);
    const [openSpouseTable, setOpenSpouseTable] = useState(false);
    const [validationErrorMsg, setValidationErrorMsg] = useState("");
    const [showValidationError, setShowValidationError] = useState(false);
    const [showValidationErrorSp, setShowValidationErrorSp] = useState(false);
    const isPAEUser = useIsEmulator();
    const isRASliderChanged = baselineRetireAge !== participant.retirementAge;

    const validateAgeChange = (event) => {
        const optionValue = event.currentTarget.value.split("-");
        return Number(optionValue[0]);
    };

    const handleOpenTable = (event, setShowSocialSecurityTable, isSpouse) => {
        setOpenSpouseTable(isSpouse);
        dispatch(dispatchEventBus(SSEvents.OPEN_SOCIAL_SECURITY_ESTIMATE_MODAL, event));
        dispatch(
            dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection:
                        MANAGED_ACCOUNT_SOCIAL_SECURITY_EVENTS.OPEN_SOCIAL_SECURITY_ESTIMATE_MODAL,
                    isSpouse
                }
            })
        );
        document
            .getElementsByClassName("social-security-modal-container")[0]
            .classList.add("fade-out");
        setShowSocialSecurityTable(true);
    };

    const handleCloseTable = () => {
        document
            .getElementsByClassName("social-security-modal-container")[0]
            .classList.remove("fade-out");
        setShowSocialSecurityTable(false);
        dispatch(
            dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection:
                        MANAGED_ACCOUNT_SOCIAL_SECURITY_EVENTS.CLOSE_SOCIAL_SECURITY_ESTIMATE_MODAL
                }
            })
        );
    };

    const handleStartAgeChange = (age, action, isSpouse) => {
        if (!isNaN(age)) {
            action(age);
            dispatch(dispatchEventBus(SSEvents.UPDATED_START_AGE, { isSpouse }));
            dispatch(
                dispatchAmplitude({
                    event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                    event_properties: {
                        selection: MANAGED_ACCOUNT_SOCIAL_SECURITY_EVENTS.START_AGE_ALTERED,
                        isSpouse
                    }
                })
            );
        }
    };

    const handleErrorMsg = (value, limit, type) => {
        let valueLimit = limit;
        const firstName = type === "U" ? participant.firstName : spouse.firstName;
        let actualValue = value;
        actualValue = String(actualValue).replace(/,/g, "");

        if (Number(actualValue) > Number(valueLimit)) {
            valueLimit = Number(valueLimit).toLocaleString();
            setValidationErrorMsg(
                maSocialSecurityTranslations.landing.details.validationMax +
                    valueLimit +
                    " for " +
                    firstName
            );
            if (type === "U") {
                setShowValidationError(true);
            } else if (type === "S") {
                setShowValidationErrorSp(true);
            }
        }
    };
    /* - handle on click of checkbox for "Use our estimated monthly benefit for..."
     * - estimatedAmountCheckBoxStatus not updated yet; use before value
     */
    const handleChange = (action, override) => {
        action(!override);
        // If there is an existing error, let's not clear it
        if (!estimatedAmountCheckboxStatus) {
            setMonthlyAmountOverride("");
            setShowValidationError(false);
            if (!showValidationErrorSp) {
                setShowError(false);
            } else {
                handleErrorMsg(spouseMonthlyAmountOverride, spouseMaxMonthlyLimit, "S");
            }
        } else if (
            estimatedAmountCheckboxStatus &&
            !showValidationError &&
            String(monthlyAmountOverride).trim() > ""
        ) {
            handleErrorMsg(monthlyAmountOverride, userMaxMonthlyLimit, "U");
        }
    };

    /* - handle on click of checkbox for "Use our estimated monthly benefit for..."
     * - spouseOverrideChecked not updated yet; use before value
     */
    const handleSpouseChange = (action, override) => {
        action(!override);
        // If there is an existing error, let's not clear it
        if (!spouseOverrideChecked) {
            setSpouseMonthlyAmountOverride("");
            setShowValidationErrorSp(false);
            if (!showValidationError) {
                setShowError(false);
            } else {
                handleErrorMsg(monthlyAmountOverride, userMaxMonthlyLimit, "U");
            }
        } else if (
            spouseOverrideChecked &&
            !showValidationErrorSp &&
            String(spouseMonthlyAmountOverride).trim() > ""
        ) {
            handleErrorMsg(spouseMonthlyAmountOverride, spouseMaxMonthlyLimit, "S");
        }
    };

    /*
     *   Per business requirements, SS tab age should not go under 62 years
     *   Thus, sycning the 62 age with the correct monthly benefit.
     *   Monthly benefit should not exceed that of the age 70 amount
     *   If age falls within 62 - 70, return value already assigned as retirementMonthlyAmount
     *   If isMonthlyAmount is false, return monthlyAmountOverride
     *   If amount === 0 we don't want to convert number to USD string of $0.00
     */
    const handleCustomAmount = (value, action, valueLimit, firstName, type) => {
        logger.debug("handle custom amount: " + value + " type: " + type + " max: " + valueLimit);

        let isError = false;

        if (!estimatedAmountCheckboxStatus) {
            if (showError && String(monthlyAmountOverride).trim() > "") {
                setShowError(false);
            }

            let actualValue = type === "U" ? value : monthlyAmountOverride;
            const userValueLimit = type === "U" ? valueLimit : userMaxMonthlyLimit;
            actualValue = String(actualValue).replace(/,/g, "");
            if (type === "U") {
                action(actualValue);
            }

            if (Number(actualValue) > Number(userValueLimit)) {
                handleErrorMsg(actualValue, userValueLimit, "U");
                isError = true;
            } else {
                setValidationErrorMsg("");
                setShowValidationError(false);
            }
        }

        if (!spouseOverrideChecked && spouse.exists) {
            if (type === "S") {
                let spouseValue = value;
                const spouseValueLimit = valueLimit;
                spouseValue = String(spouseValue).replace(/,/g, "");
                action(spouseValue);
                if (Number(spouseValue) > Number(spouseValueLimit)) {
                    setShowValidationErrorSp(true);
                }
            }
        }

        if (isError) {
            return;
        }

        if (!spouseOverrideChecked && spouse.exists) {
            if (showError && String(spouseMonthlyAmountOverride).trim() > "") {
                setShowError(false);
            }

            let spouseValue = type === "S" ? value : spouseMonthlyAmountOverride;
            const spouseValueLimit = type === "S" ? valueLimit : spouseMaxMonthlyLimit;
            spouseValue = String(spouseValue).replace(/,/g, "");

            if (Number(spouseValue) > Number(spouseValueLimit)) {
                handleErrorMsg(spouseValue, spouseValueLimit, "S");
            } else {
                setValidationErrorMsg("");
                setShowValidationErrorSp(false);
            }
        }
    };

    const setCollectAge = (age, action) => action(age);

    const setDropDownDetails = (action, state, details) => action({ ...state, ...details });

    // Reset modal to original state for on cancel click
    const resetModalToStartValues = () => {
        setMonthlyAmountOverride(socialSecurityUser.monthlyAmountOverride);
        setEstimatedAmountCheckboxStatus(socialSecurityUser.estimatedAmountCheckboxStatus);

        setCollectAge(participantRecieveAge, setStartReceivingAge);

        if (benefitsValues) {
            const details = getDropDownDetails(
                participantRecieveAge,
                benefitsValues,
                participantDropDownOptions
            );
            setDropDownDetails(setParticipantDropDownValues, participantDropDownValues, details);
        }

        if (spouse.exists) {
            if (socialSecuritySpouse) {
                setSpouseMonthlyAmountOverride(socialSecuritySpouse.monthlyAmountOverride);
                setSpouseOverrideChecked(socialSecuritySpouse.estimatedAmountCheckboxStatus);

                setCollectAge(spouseRecieveAge, setStartSpouseReceivingAge);

                const spouseDetails = getDropDownDetails(
                    spouseRecieveAge,
                    ssSpouseBenefitsMap,
                    spouseDropDownOptions
                );
                setDropDownDetails(setSpouseDropDownValues, spouseDropDownValues, spouseDetails);
            }
        }
    };

    /**
     * Close modal and reset value to original values
     * @param {} e
     * @param {*} reset
     */
    const handleClose = (e, cancelButtonClicked) => {
        if (e && e.currentTarget) {
            resetModalToStartValues();
            setShowError(false);
            setShowModal(false);
            setShowValidationError(false);
            setShowValidationErrorSp(false);
            if (cancelButtonClicked) {
                dispatch(
                    dispatchAmplitude({
                        event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                        event_properties: {
                            selection: MANAGED_ACCOUNT_SOCIAL_SECURITY_EVENTS.CANCEL_BUTTON_CLICKED
                        }
                    })
                );
            }
        }
    };

    // Close modal and update UI display after save; reset dd or set monthly to blank depending on checkbox setting
    // at time of save
    const handleCloseAfterSave = () => {
        // participant
        if (!estimatedAmountCheckboxStatus) {
            // saves monthly override; reset dd to original values
            setCollectAge(participantRecieveAge, setStartReceivingAge);

            if (benefitsValues) {
                const details = getDropDownDetails(
                    participantRecieveAge,
                    benefitsValues,
                    participantDropDownOptions
                );
                setDropDownDetails(
                    setParticipantDropDownValues,
                    participantDropDownValues,
                    details
                );
            }
        } else {
            // saves dd, reset monthly value
            setMonthlyAmountOverride("");
        }

        if (spouse.exists) {
            if (!spouseOverrideChecked) {
                setCollectAge(spouseRecieveAge, setStartSpouseReceivingAge);

                const spouseDetails = getDropDownDetails(
                    spouseRecieveAge,
                    ssSpouseBenefitsMap,
                    spouseDropDownOptions
                );
                setDropDownDetails(setSpouseDropDownValues, spouseDropDownValues, spouseDetails);
            } else {
                if (socialSecuritySpouse) {
                    setSpouseMonthlyAmountOverride("");
                }
            }
        }

        setShowError(false);
        setShowModal(false);
        setShowValidationError(false);
        setShowValidationErrorSp(false);
    };

    const ssText = maSocialSecurityTranslations.landing;

    const participantDropDownOptions = useMemo(() => {
        return getDropDownOptions({
            startReceivingAge,
            ssText,
            exists: participant.dataIsLoaded,
            dateOfBirth: participant.dateOfBirth,
            retirementAge: participant.retirementAge
        });
    }, [ssText, participant, startReceivingAge]);

    const spouseDropDownOptions = useMemo(() => {
        return getDropDownOptions({
            startReceivingAge: spouseRecieveAge,
            ssText,
            exists: spouse.exists,
            dateOfBirth: spouse.dateOfBirth,
            retirementAge: spouse.retirementAge
        });
    }, [spouse, spouseRecieveAge, ssText]);

    /**
     * If estimatedAmountCheckboxStatus is checked and user monthly amount by age takes precedence
     * If the checkbox has been checked, clear out the monthly amount override field
     * @param {*} e
     */
    const handleSave = (e) => {
        e.stopPropagation();

        let userMonthlyAmt =
            monthlyAmountOverride > "" ? String(monthlyAmountOverride).replace(/,/g, "") : "";

        let userAgeOvrride =
            socialSecurityUser.retirementAge !== startReceivingAge ||
            (socialSecurityUser.startReceiveAge > "" &&
                socialSecurityUser.retirementAge === startReceivingAge);

        userAgeOvrride = !!estimatedAmountCheckboxStatus;
        userMonthlyAmt = estimatedAmountCheckboxStatus ? "" : userMonthlyAmt;

        const socialSecurityAmt = estimatedAmountCheckboxStatus
            ? null
            : userMonthlyAmt === ""
              ? null
              : userMonthlyAmt;
        const socialSecurityAgeOvr = startReceivingAge;
        const amtDisplayed = estimatedAmountCheckboxStatus
            ? participantDropDownValues.amount
            : userMonthlyAmt;
        const atRetirement = startReceivingAge === participant.retirementAge;
        const userPreference = estimatedAmountCheckboxStatus
            ? SOCIAL_SECURITY_CONSTANTS.ANNUAL_AMOUNT_PREFERENCES_OPTIONS.CALCULATED
            : SOCIAL_SECURITY_CONSTANTS.ANNUAL_AMOUNT_PREFERENCES_OPTIONS.USER_INPUT;

        if (!estimatedAmountCheckboxStatus) {
            if (String(monthlyAmountOverride).trim() === "") {
                setShowError(true);
                return;
            }

            if (Number(userMonthlyAmt) > Number(userMaxMonthlyLimit)) {
                const valueLimit = Number(userMaxMonthlyLimit).toLocaleString();
                setValidationErrorMsg(
                    maSocialSecurityTranslations.landing.details.validationMax +
                        valueLimit +
                        " for " +
                        participant.firstName
                );
                setShowValidationError(true);
                return;
            }
        }

        if (spouse.exists) {
            if (!spouseOverrideChecked) {
                const spouseMonthlyAmt = String(spouseMonthlyAmountOverride).replace(/,/g, "");
                if (String(spouseMonthlyAmountOverride).trim() === "") {
                    setShowError(true);
                    return;
                }

                if (Number(spouseMonthlyAmt) > Number(spouseMaxMonthlyLimit)) {
                    const valueLimit = Number(spouseMaxMonthlyLimit).toLocaleString();
                    setValidationErrorMsg(
                        maSocialSecurityTranslations.landing.details.validationMax +
                            valueLimit +
                            " for " +
                            spouse.firstName
                    );
                    setShowValidationErrorSp(true);
                    return;
                }
            }
        }

        const payload = {
            socialSecurityUser: {
                ...socialSecurityUser,
                estimatedAmountCheckboxStatus,
                monthlyAmountOverride: userMonthlyAmt,
                startReceiveAge: startReceivingAge,
                amount: participantDropDownValues.amount,
                amountDisplayed: amtDisplayed,
                ageOverride: userAgeOvrride,
                socialSecurityIncome: {
                    ...socialSecurityIncome,
                    socialSecurityAmount: socialSecurityAmt,
                    socialSecurityStartAge: socialSecurityAgeOvr,
                    annualAmountPreference: userPreference
                },
                atRetirement: atRetirement
            },
            hasSpouse: spouse.exists
        };

        if (spouse.exists) {
            let spouseMonthlyAmt =
                spouseMonthlyAmountOverride > ""
                    ? String(spouseMonthlyAmountOverride).replace(/,/g, "")
                    : "";
            let spouseAgeOvrride =
                socialSecuritySpouse.retirementAge !== startSpouseReceivingAge ||
                (socialSecuritySpouse.startSpouseReceiveAge > "" &&
                    socialSecuritySpouse.retirementAge === startSpouseReceivingAge);
            spouseAgeOvrride = !!spouseOverrideChecked;
            spouseMonthlyAmt = spouseOverrideChecked ? "" : spouseMonthlyAmt;

            const spouseSocSecurityAmt = spouseOverrideChecked
                ? null
                : spouseMonthlyAmt === ""
                  ? null
                  : spouseMonthlyAmt;
            const spouseSocSecAgeOvr = startSpouseReceivingAge;
            const spouseAmtDisplayed = spouseOverrideChecked
                ? spouseDropDownValues.amount
                : spouseMonthlyAmt;
            const spousePreference = spouseOverrideChecked
                ? SOCIAL_SECURITY_CONSTANTS.ANNUAL_AMOUNT_PREFERENCES_OPTIONS.CALCULATED
                : SOCIAL_SECURITY_CONSTANTS.ANNUAL_AMOUNT_PREFERENCES_OPTIONS.USER_INPUT;
            const spouseAtRetirement = startSpouseReceivingAge === spouse.retirementAge;

            payload.socialSecuritySpouse = {
                ...socialSecuritySpouse,
                estimatedAmountCheckboxStatus: spouseOverrideChecked,
                monthlyAmountOverride: spouseMonthlyAmt,
                startReceiveAge: startSpouseReceivingAge,
                amount: spouseDropDownValues.amount,
                amountDisplayed: spouseAmtDisplayed,
                ageOverride: spouseAgeOvrride,
                socialSecurityIncome: {
                    ...spouseSocialSecurityIncome,
                    socialSecurityAmount: spouseSocSecurityAmt,
                    socialSecurityStartAge: spouseSocSecAgeOvr,
                    annualAmountPreference: spousePreference
                },
                atRetirement: spouseAtRetirement
            };
        }

        dispatch(saveSocialSecurityChange(payload));

        if (isPAEUser) {
            setShowModal(false);
            dispatch(setCurrentFault(getPAEFault()));
        }

        dispatch(
            dispatchAmplitude({
                event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
                event_properties: {
                    selection: MANAGED_ACCOUNT_SOCIAL_SECURITY_EVENTS.SAVE_BUTTON_CLICKED
                }
            })
        );

        handleCloseAfterSave();
    };

    const [participantDropDownValues, setParticipantDropDownValues] = useState(
        getDropDownDetails(participantRecieveAge, benefitsValues, participantDropDownOptions)
    );

    const [spouseDropDownValues, setSpouseDropDownValues] = useState(
        getDropDownDetails(spouseRecieveAge, ssSpouseBenefitsMap, spouseDropDownOptions)
    );

    /**
     * Dynamically updates dropdown values for both the participant and their spouse and ensures that dropdowns always reflect the most current data
     */

    useEffect(() => {
        if (
            socialSecurityUser &&
            socialSecurityUser.benefitsValues &&
            socialSecurityUser.benefitsValues.length > 0
        ) {
            setParticipantDropDownValues(
                getDropDownDetails(
                    startReceivingAge,
                    socialSecurityUser.benefitsValues,
                    participantDropDownOptions
                )
            );
        }

        if (
            socialSecuritySpouse &&
            socialSecuritySpouse.benefitsValues &&
            socialSecuritySpouse.benefitsValues.length > 0
        ) {
            setSpouseDropDownValues(
                getDropDownDetails(
                    startSpouseReceivingAge,
                    socialSecuritySpouse.benefitsValues,
                    spouseDropDownOptions
                )
            );
        }
    }, [
        socialSecurityUser,
        socialSecuritySpouse,
        startReceivingAge,
        participantDropDownOptions,
        startSpouseReceivingAge,
        spouseDropDownOptions
    ]);

    useEffect(() => {
        setMonthlyAmountOverride(socialSecurityUser.monthlyAmountOverride);
        setEstimatedAmountCheckboxStatus(socialSecurityUser.estimatedAmountCheckboxStatus);

        if (socialSecurityUser.benefitsValues && socialSecurityUser.benefitsValues.length > 0) {
            const atAge70 = _filter(socialSecurityUser.benefitsValues, (item) => {
                return Number(item.age) === 70;
            });
            if (atAge70 && atAge70.length > 0) {
                setUserMaxMonthlyLimit(atAge70[0].monthly * 3);
            }
        }

        if (socialSecuritySpouse && spouse.exists) {
            setSpouseMonthlyAmountOverride(socialSecuritySpouse.monthlyAmountOverride);
            setSpouseOverrideChecked(socialSecuritySpouse.estimatedAmountCheckboxStatus);
            const spouseAtAge70 = _filter(ssSpouseBenefitsMap, (item) => {
                return Number(item.age) === 70;
            });
            if (spouseAtAge70 && spouseAtAge70.length > 0) {
                setSpouseMaxMonthlyLimit(spouseAtAge70[0].monthly * 3);
            }
        }
    }, [
        socialSecurityUser.monthlyAmountOverride,
        socialSecurityUser.benefitsValues,
        socialSecuritySpouse,
        socialSecurityUser.estimatedAmountCheckboxStatus,
        spouse.exists,
        setSpouseMonthlyAmountOverride,
        setSpouseOverrideChecked,
        ssSpouseBenefitsMap
    ]);

    useEffect(() => {
        setCollectAge(participantRecieveAge, setStartReceivingAge);

        if (spouse.exists) {
            setCollectAge(spouseRecieveAge, setStartSpouseReceivingAge);
        }
    }, [spouseRecieveAge, participantRecieveAge, spouse.exists]);

    return (
        <div data-testid="social-security-modal">
            <Modal className="social-security-modal-container" show={show} onHide={handleClose}>
                <Modal.Header>
                    <button
                        className="modal-close-button em-delete-icon"
                        onClick={handleClose}
                        aria-label={maSocialSecurityTranslations.landing.details.ModalCloseLabel}
                    ></button>
                    <Modal.Title>
                        {maSocialSecurityTranslations.landing.details.ModalTitle}
                    </Modal.Title>

                    <div className="modal-subheader">
                        {maSocialSecurityTranslations.liat.Heading}
                    </div>
                </Modal.Header>

                <Modal.Body>
                    <PersonDetails
                        user={participant}
                        ssBenefitByStartAge={benefitsValues}
                        handleOpenTable={(event) =>
                            handleOpenTable(event, setShowSocialSecurityTable, false)
                        }
                        startReceiveAge={startReceivingAge}
                        handleChange={() =>
                            handleChange(
                                setEstimatedAmountCheckboxStatus,
                                estimatedAmountCheckboxStatus
                            )
                        }
                        estimatedAmountCheckboxStatus={estimatedAmountCheckboxStatus}
                        handleCustomAmount={(value) =>
                            handleCustomAmount(
                                value,
                                setMonthlyAmountOverride,
                                userMaxMonthlyLimit,
                                participant.firstName,
                                "U"
                            )
                        }
                        monthlyAmountOverride={monthlyAmountOverride}
                        handleStartAgeChange={(event) => {
                            const age = validateAgeChange(event);
                            const details = getDropDownDetails(
                                age,
                                benefitsValues,
                                participantDropDownOptions
                            );
                            setDropDownDetails(
                                setParticipantDropDownValues,
                                participantDropDownValues,
                                details
                            );

                            handleStartAgeChange(age, setStartReceivingAge, false);
                        }}
                        dropDownValues={participantDropDownValues}
                        options={participantDropDownOptions}
                        atRetirement={userAtRetirement}
                    />
                    {spouse.exists && (
                        <PersonDetails
                            user={spouse}
                            ssBenefitByStartAge={ssSpouseBenefitsMap}
                            handleOpenTable={(event) =>
                                handleOpenTable(event, setShowSocialSecurityTable, true)
                            }
                            startReceiveAge={startSpouseReceivingAge}
                            handleChange={() =>
                                handleSpouseChange(setSpouseOverrideChecked, spouseOverrideChecked)
                            }
                            estimatedAmountCheckboxStatus={spouseOverrideChecked}
                            handleCustomAmount={(value) =>
                                handleCustomAmount(
                                    value,
                                    setSpouseMonthlyAmountOverride,
                                    spouseMaxMonthlyLimit,
                                    spouse.firstName,
                                    "S"
                                )
                            }
                            monthlyAmountOverride={spouseMonthlyAmountOverride}
                            handleStartAgeChange={(event) => {
                                const age = validateAgeChange(event);
                                const details = getDropDownDetails(
                                    age,
                                    ssSpouseBenefitsMap,
                                    spouseDropDownOptions
                                );
                                setDropDownDetails(
                                    setSpouseDropDownValues,
                                    spouseDropDownValues,
                                    details,
                                    spouseDropDownOptions
                                );
                                handleStartAgeChange(age, setStartSpouseReceivingAge, true);
                            }}
                            dropDownValues={spouseDropDownValues}
                            options={spouseDropDownOptions}
                            atRetirement={spouseAtRetirement}
                        />
                    )}
                </Modal.Body>

                <Modal.Footer className="modal-footer">
                    <div className="footer-content">
                        {showError && (
                            <div className="pull-left error-text">
                                {maSocialSecurityTranslations.landing.details.error_message1}
                            </div>
                        )}
                        {(showValidationError || showValidationErrorSp) && (
                            <div className="pull-left error-text">{validationErrorMsg}</div>
                        )}
                        <div className="done-btn-container">
                            <button
                                type="button"
                                className="btn btn-bare btn-modal-cancel"
                                onClick={(e) => handleClose(e, true)}
                            >
                                Cancel
                            </button>
                            <button
                                type="button"
                                className={`btn ${"btn-pcap-primary"}`}
                                onClick={handleSave}
                                disabled={
                                    (socialSecurityUser.atRetirement && isRASliderChanged) ||
                                    showValidationError ||
                                    showValidationErrorSp ||
                                    showError
                                }
                            >
                                Save
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
                <SocialSecurityTableModal
                    show={showSocialSecurityTable}
                    socialSecurityInfo={openSpouseTable ? socialSecuritySpouse : socialSecurityUser}
                    handleCloseTable={handleCloseTable}
                    firstName={openSpouseTable ? spouse.firstName : participant.firstName}
                />
            </Modal>
        </div>
    );
};
SocialSecurityModal.propTypes = {
    show: PropTypes.bool,
    setShowModal: PropTypes.func,
    participantRecieveAge: PropTypes.number,
    spouseRecieveAge: PropTypes.number
};

export default memo(SocialSecurityModal);
