import { isUndefinedOrNull } from "gw-shared-components";

import { formatCurrency } from "../../utils/currencyUtils";

export const formatVal = (val) => {
    if (isUndefinedOrNull(val)) {
        return;
    }

    let valStr = val.toString();
    valStr = formatCurrency(valStr);
    const valWhole = valStr.split(".")[0];
    const valDec = Number(valStr.split(".")[1]);
    return Number(valDec) === 0 ? valWhole : valStr;
};

export const getHsaExpenseSelectionType = function (hsaPlan) {
    if (isUndefinedOrNull(hsaPlan)) return "";
    let selection = "";
    if (hsaPlan.hsaDetails.expenseSelectionInd === "U") {
        const priorSpend = hsaPlan.hsaDetails.aggrPriorYearSpend;
        if (isUndefinedOrNull(priorSpend) && priorSpend !== 0) {
            selection = "P";
        } else {
            selection = "N";
        }
    } else {
        selection = hsaPlan.hsaDetails.expenseSelectionInd;
    }

    return selection;
};
