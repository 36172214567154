import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { batch } from "react-redux";

import ReferenceData from "../../constants/ReferenceData";
import { validateSocialSecurityAge } from "../../utils/socialSecurity";
import { setRetirementAge } from "../participantDetails/participantDetailsActions";
import { updateProjectedIncome } from "../projectedIncome/projectedIncomeActions";
import { setParticipantStartRecAge } from "../socialSecurity/socialSecurityActions";

import { SET_EQUITY_MIX, SET_INVESTMENTS_MIX_WARNINGS } from "./investmentActionTypes";

/**
 * ACTIONS FOUND IN THIS FILE
 * @setEquityMix
 * @handleInvestmentSliderChange
 * @setEquityMixWarnings
 */

export const setEquityMix = (equityMix) => {
    const payload = equityMix;
    return {
        type: SET_EQUITY_MIX,
        payload
    };
};

export const handleInvestmentSliderChange = ({
    value,
    individualId,
    primaryPlan,
    nonAllMoneyTypeGroupings
}) => {
    // re-calculate the projected income but don't reset the baseline
    const valueAsDecimal = Number(value);
    return (dispatch) => {
        batch(() => {
            dispatch(setEquityMix(valueAsDecimal));
            dispatch(setEquityMixWarnings(individualId, primaryPlan, nonAllMoneyTypeGroupings));
            dispatch(updateProjectedIncome());
        });
    };
};

export const handleRetirementAgeSliderChange = ({
    value,
    individualId,
    primaryPlan,
    nonAllMoneyTypeGroupings
}) => {
    // slider is an older value that is passed from the SliderWrapper change fn
    return (dispatch, getState) => {
        const state = getState();
        const socialSecurityUser = state.socialSecurity.socialSecurityUser;

        batch(() => {
            dispatch(setRetirementAge(value));
            dispatch(setEquityMixWarnings(individualId, primaryPlan, nonAllMoneyTypeGroupings));

            if (socialSecurityUser.atRetirement) {
                const ssAge = validateSocialSecurityAge(value);
                dispatch(setParticipantStartRecAge(ssAge));
            }
            dispatch(updateProjectedIncome());
        });
    };
};

export const setEquityMixWarnings = function (individualId, activePlan, nonAllMoneyTypeGroupings) {
    const translations = selectTranslations("reviewChanges");
    const payload = [];
    if (nonAllMoneyTypeGroupings) {
        const url = StringUtil.supplant(ReferenceData.URL_NON_ALL_MT_GROUPINGS, {
            individualId,
            groupId: activePlan.id
        });
        payload.push({
            message1: translations.warnings["not-all-mtg-1"],
            message2: translations.warnings["not-all-mtg-2"],
            message3: translations.warnings["not-all-mtg-3"],
            url: url,
            urlMessage: translations.warnings["not-all-mtg-link"]
        });
    }
    return {
        type: SET_INVESTMENTS_MIX_WARNINGS,
        payload
    };
};
