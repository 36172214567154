/**
 *
 * @class ObjectUtil
 * @memberOf common.util
 * @date 02/09/2015
 * @copyright 2015 Empower Retirement
 * @description
 *
 *      Provides very basic, non-framework dependent object, property, and value checks. Example methods include:
 *
 *        - isUndefined
 *        - isDefined
 *        - isNull
 *        - isUndefinedOrNull
 *        - isArrayEmpty
 *
 */

import {
    isArray as _isArray,
    isString as _isString,
    isObject as _isObject,
    isDate as _isDate
} from "lodash";

const ObjectUtil = {
    /**
     *
     * @function isUndefined
     * @memberOf common.util.ObjectUtil
     * @static
     * @param {*} value The value to check.
     * @returns {boolean} Returns `true` if the `value` is `undefined`, else `false`.
     * @description Checks if `value` is `undefined`.
     * @example
     *
     * ObjectUtil.isUndefined(void 0);
     * // => true
     *
     */
    isUndefined: function (value) {
        return value === undefined;
    },

    /**
     *
     * @function isDefined
     * @memberOf common.util.ObjectUtil
     * @static
     * @param {*} value The value to check.
     * @returns {boolean} Returns `true` if the `value` is defined or not `undefined`, else `false`.
     * @description Checks if `value` is defined or not `undefined`.
     * @example
     *
     * ObjectUtil.isDefined(void 0);
     * // => false
     *
     */
    isDefined: function (value) {
        return !ObjectUtil.isUndefined(value);
    },

    /**
     *
     * @function isNull
     * @memberOf common.util.ObjectUtil
     * @param {*} value The value to check.
     * @returns {boolean} Returns `true` if the `value` is `null`, else `false`.
     * @static
     * @description Checks if `value` is `null`.
     * @example
     *
     * ObjectUtil.isNull(null);
     * // => true
     *
     * ObjectUtil.isNull(undefined);
     * // => false
     *
     */
    isNull: function (value) {
        return value === null;
    },

    /**
     *
     * @function isUndefinedOrNull
     * @memberOf common.util.ObjectUtil
     * @param {*} value The value to check.
     * @returns {boolean} Returns `true` if the `value` is `undefined` or 'null', else `false`.
     * @static
     * @description Checks if `value` is `undefined` or is `null`.
     * @example
     *
     * ObjectUtil.isUndefinedOrNull(void 0);
     * // => true
     *
     * ObjectUtil.isUndefinedOrNull(null);
     * // => true
     *
     * ObjectUtil.isUndefinedOrNull(undefined);
     * // => true
     *
     * ObjectUtil.isUndefinedOrNull(5);
     * // => false
     *
     */
    isUndefinedOrNull: function (value) {
        return ObjectUtil.isUndefined(value) || ObjectUtil.isNull(value);
    },

    /**
     *
     * @function isArray
     * @memberOf common.util.ObjectUtil
     * @param {*} value
     * @description Checks to see if 'value' is an array
     *
     */
    isArray: function (value) {
        return _isArray(value);
    },

    /**
     *
     * @function isArrayEmpty
     * @memberOf common.util.ObjectUtil
     * @param {*} value The value to check.
     * @static
     * @returns {boolean} Returns `true` if the `value` has a length property less than or equal to zero, else `false`.
     * @description Checks if `value` is `null` or is `undefined` or is an array with no items.
     * @example
     *
     * ObjectUtil.isArrayEmpty(null);
     * // => true
     *
     * ObjectUtil.isArrayEmpty([]);
     * // => true
     *
     * ObjectUtil.isArrayEmpty([1, 2, 3]);
     * // => false
     *
     */
    isArrayEmpty: function (value) {
        if (!value) {
            return true;
        } else {
            return ObjectUtil.isDefined(value.length) && value.length <= 0;
        }
    },

    /**
     *
     * @function getPropertyByString
     * @memberOf common.util.ObjectUtil
     * @param {Object} object The source object containing the requested property.
     * @param {String|Array} property The property being requested.
     * @static
     * @description returns a property from a given object. If no property is given, the object is returned.
     * If no object is provided, it returns null.
     * @example
     * Allows for object property look up by a string with dot notation in it; for example, take object:
     *
     * var obj = { a: { b: "1", c: "2" } };
     *
     * If you want to access property "a.b" in obj you can use:
     *
     * var ab = getPropertyByString(obj, "a.b")
     *
     * or
     *
     * var ab = getPropertyByString(obj, "[a][b]");
     *
     * @returns {*} A reference to the property in the object.
     *
     */
    getPropertyByString: function (object, property) {
        if (
            ObjectUtil.isUndefinedOrNull(object) ||
            ObjectUtil.isUndefinedOrNull(property) ||
            property === ""
        ) {
            return null;
        }

        function usingArrayNotation(o, ref, i) {
            return !ref ? o : o[ref.slice(0, i ? -1 : ref.length)];
        }

        function usingDotNotation(o, ref) {
            return ref.split("[").reduce(usingArrayNotation, o);
        }

        return !property ? object : property.split(".").reduce(usingDotNotation, object);
    },

    /**
     *
     * @function isPropertyDefined
     * @memberOf common.util.ObjectUtil
     * @param {Object} obj the object to inspect
     * @param {String} prop the property to check for in dot.path notation
     * @static
     * @description returns a property from a given object. If no property is given, the object is returned.
     * @returns {*|boolean}
     *
     */
    isPropertyDefined: function (obj, prop) {
        return ObjectUtil.isDefined(ObjectUtil.getPropertyByString(obj, prop));
    },

    /**
     *
     * @function defaultIfUndefined
     * @memberOf common.util.ObjectUtil
     * @param {Object} obj the object to use
     * @param {String} propPath the property to check for in dot.path notation
     * @param {String} defValue the value to use in case obj.propPath is unavailable
     * @static
     * @description returns a default value, found at obj.propPath if available and from defValue if not.
     * @returns {*}
     *
     */
    defaultIfUndefined: function (obj, propPath, defValue) {
        if (ObjectUtil.isUndefined(obj)) {
            return defValue;
        }
        const p = ObjectUtil.getPropertyByString(obj, propPath);
        if (ObjectUtil.isDefined(p)) {
            return p;
        } else {
            return defValue;
        }
    },

    /**
     *
     * @function isBoolean
     * @memberOf common.util.ObjectUtil
     * @param {*} val the value to inspect
     * @static
     * @description determines if a given val is a boolean from if it is defined and either true or false.
     * @returns {boolean}
     *
     */
    isBoolean: function (val) {
        return ObjectUtil.isDefined(val) && (val === true || val === false);
    },

    /**
     *
     * @param val
     * @returns {*|boolean}
     */
    isString: function (val) {
        return ObjectUtil.isDefined(val) && _isString(val);
    },

    /**
     *
     * @function isEmptyString
     * @memberOf common.util.ObjectUtil
     * @param {*} val the value to inspect
     * @static
     * @description determines if a given val is an empty string.
     * @returns {boolean}
     *
     */
    isEmptyString: function (val) {
        return val === "";
    },

    /**
     *
     * @function isEmptyObject
     * @memberOf common.util.ObjectUtil
     * @param {*} val the value to inspect
     * @static
     * @description determines if a given val is an empty object.
     * @returns {boolean}
     *
     */
    isEmptyObject: function (val) {
        if (ObjectUtil.isDefined(val) && _isObject(val)) {
            const isEmpty = function (obj) {
                for (const prop in obj) {
                    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                        return false;
                    }
                }

                return true;
            };

            return isEmpty(val);
        } else {
            return true;
        }
    },

    /**
     *
     * @function hasValue
     * @memberOf common.util.ObjectUtil
     * @param {*} val the value to inspect
     * @static
     * @description determines if a given val has a value.
     * @returns {boolean}
     *
     */
    hasValue: function (val) {
        const defined = ObjectUtil.isDefined(val);
        const notNull = !ObjectUtil.isNull(val);
        const notBlank = !ObjectUtil.isEmptyString(val);
        const notEmpty = _isObject(val) ? !ObjectUtil.isEmptyObject(val) : true;

        return defined && notNull && notBlank && notEmpty;
    },

    /**
     *
     * @function isYes
     * @memberOf common.util.ObjectUtil
     * @param {*} value The value to check.
     * @description Checks if `value` is `yes`.
     * @static
     * @returns {boolean} Returns `true` if the `value` is `yes`, else `false`.
     * @example
     *
     * ObjectUtil.isYes("yes");
     * // => true
     *
     */
    isYes: function (value) {
        return value.toLowerCase() === "yes";
    },

    /**
     *
     * @function isNo
     * @memberOf common.util.ObjectUtil
     * @param {*} value The value to check.
     * @description Checks if `value` is `no`.
     * @static
     * @returns {boolean} Returns `true` if the `value` is `no`, else `false`.
     * @example
     *
     * ObjectUtil.isNo("no");
     * // => true
     *
     */
    isNo: function (value) {
        return value.toLowerCase() === "no";
    },

    /**
     * Returns boolean given "N", "n", "Y", or "y"
     * @param ind
     */
    getBooleanFromIndicator: function (ind, defaultVal) {
        if (ind) {
            switch (ind) {
                case "N":
                    return false;
                case "n":
                    return false;
                case "Y":
                    return true;
                case "y":
                    return true;
                default:
                    return Boolean(defaultVal);
            }
        }
        return false;
    },

    /**
     *
     * @function isDate
     * @memberOf common.util.ObjectUtil
     * @param {*} value The value to check.
     * @description Checks if `value` is a date object.
     * @static
     * @returns {boolean} Returns `true` if the `value` is an instanceof Date.
     *
     */
    isDate: function (value) {
        return _isDate(value);
    },

    /**
     *
     * @function formatDate
     * @memberOf common.util.ObjectUtil
     * @param {date} dateString The date to format.
     * @description Checks for and translates dates with 3-letter month names to mm/dd/yyyy.
     * @returns {string} returns a date formatted as mm/dd/yyyy.
     *
     */
    formatDate: function (dateString) {
        if (
            dateString &&
            ObjectUtil.isString(dateString) &&
            dateString.match(/^\d{1,2}-[A-z]{3}-\d{4}$/)
        ) {
            const splitDate = dateString.split("-");
            const day = splitDate[0];
            const month = splitDate[1];
            const year = splitDate[2];
            let monthNumber = 1;
            switch (String(month).toLowerCase()) {
                case "feb":
                    monthNumber = 2;
                    break;
                case "mar":
                    monthNumber = 3;
                    break;
                case "apr":
                    monthNumber = 4;
                    break;
                case "may":
                    monthNumber = 5;
                    break;
                case "jun":
                    monthNumber = 6;
                    break;
                case "jul":
                    monthNumber = 7;
                    break;
                case "aug":
                    monthNumber = 8;
                    break;
                case "sep":
                    monthNumber = 9;
                    break;
                case "oct":
                    monthNumber = 10;
                    break;
                case "nov":
                    monthNumber = 11;
                    break;
                case "dec":
                    monthNumber = 12;
                    break;
                default:
                    monthNumber = 1;
            }
            dateString = "" + monthNumber + "/" + day + "/" + year;
            return dateString;
        } else {
            return dateString;
        }
    },

    /**
     *
     * @function formatTimestamp
     * @memberOf common.util.ObjectUtil
     * @param {datetime} timestamp
     * @description formats date object as mm/dd/yyyy.
     * @returns {string} Returns 'mm/dd/yyyy'
     * @example
     *
     * ObjectUtil.isYes("yes");
     * // => true
     *
     */
    formatTimestamp: function (timestamp) {
        if (!ObjectUtil.isUndefinedOrNull(timestamp)) {
            const dateObject = new Date(timestamp);
            return (
                dateObject.getMonth() +
                1 +
                "/" +
                dateObject.getDate() +
                "/" +
                dateObject.getFullYear()
            );
        }
    },
    /**
     *
     * @function cleanDateString
     * @memberOf common.util.ObjectUtil
     * @param {string} dateString
     * @description removes leaning zeroes from months and days of dates in the format of mm/dd/year
     * @returns {string} Returns 'mm/dd/yyyy' with zeros being stripped
     * @example '05/07/2015' becomes '5/7/2015'
     *
     */
    cleanDateString: function (dateString) {
        if (!ObjectUtil.isUndefinedOrNull(dateString) && dateString.length > 0) {
            let firstChar = dateString.substr(0, 1);
            if (firstChar === "0") {
                dateString = dateString.substr(1);
            }
            const arr = dateString.split("/");
            if (arr.length < 3) {
                return null;
            }
            firstChar = arr[1].substr(0, 1);
            if (firstChar === "0") {
                arr[1] = arr[1].substr(1);
            }
            return arr[0] + "/" + arr[1] + "/" + arr[2];
        } else {
            return null;
        }
    },

    /**
     * @memberOf common.service.business.RetirementSavingsService
     * @description Rounds value down to deferral slider step
     * @param value
     * @returns {number|*}
     */
    roundDownValueToStep: function (value, step) {
        value = Math.floor(value / step) * step;
        return value;
    },

    /**
     * @memberOf common.service.business.RetirementSavingsService
     * @description Rounds value up to deferral slider step
     * @param value
     * @returns {number}
     */
    roundUpValueToStep: function (value, step) {
        return Math.ceil(value / step) * step;
    },

    /**
     *
     * @namespace errors
     * @memberOf common.util.ObjectUtil
     * @property {string} UNDEFINED_OR_NULL
     * @property {string} UNDEFINED
     * @property {string} IS_EMPTY
     *
     */
    errors: {
        UNDEFINED_OR_NULL: "{0} is undefined or null",
        UNDEFINED: "{0} is undefined",
        IS_EMPTY: "{0} is empty or blank"
    },

    /**
     * @description convert string as number to a percentage
     * @param value
     * @returns {number}
     */
    getStringDisplayPercent: function (value) {
        value = Math.round(value);
        return value === 0.0 ? "<1%" : Math.round(value * 100) / 100 + "%";
    },

    /**
     * @description convert number as string in USD
     * @param value
     * @returns {string}
     */
    numberToCurrency: function (val) {
        return val.toLocaleString("en-US", { style: "currency", currency: "USD" });
    }
};

export default ObjectUtil;
