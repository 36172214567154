import { useAtomValue } from "jotai";

import { eligibilityTypeAtom } from "../../atoms/atoms";
import ReferenceData from "../../constants/ReferenceData";

export const useIsEmulator = () => {
    const eligibilityType = useAtomValue(eligibilityTypeAtom);
    if (!eligibilityType) {
        return false;
    }
    return eligibilityType !== ReferenceData.USER_TYPE_PARTICIPANT;
};
