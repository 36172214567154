import axios from "axios";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import { ObjectUtil } from "gw-shared-components";

import RequestFactory from "../factories/RequestFactory.js";
import { pcapServiceRequest } from "../utils/apiUtils";
import {
    getResponseFromData,
    createUpdatePayload,
    createSpousePayload
} from "../utils/goalUtils.js";
import { getUrl } from "../utils/urlUtils.js";

const logger = ExternalLogger.getInstance("UserPreferencesService");

export const saveUserPreferencesService = (individualId, config) => {
    if (config.changeBooleans) {
        if (!config.changeBooleans.dob) {
            delete config.userInfo.dateOfBirth;
            delete config.dateOfBirth;
        }
        if (config.spouseInfo) {
            if (!config.changeBooleans.spouseDob && !config.changeBooleans.newSpouseAdded) {
                delete config.spouseInfo.dateOfBirth;
            }
        }
    }

    return saveMigratedPreferences(config, individualId);
};

const saveMigratedPersonPreferences = function (config, individualId) {
    const data = createUpdatePayload(config, individualId);
    const url = getUrl("updatePersonLite");
    const method = RequestFactory.HTTP_METHOD.POST;

    const contentType = RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED;

    const name = "person";
    const request = { method, url, name, data, contentType };

    if (request.headers) {
        delete request.headers.samlToken;
    }

    return pcapServiceRequest(request);
};

const saveSpouse = function (config) {
    const data = createSpousePayload(config);

    const url = getUrl("createPersonLite");

    const method = RequestFactory.HTTP_METHOD.POST;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED;

    const name = "person";
    const request = { method, url, name, data, contentType };

    if (request.headers) {
        delete request.headers.samlToken;
    }

    return pcapServiceRequest(request);
};

export const saveTermConfidence = (config) => {
    const payload = config;

    const url = getUrl("savePreferences");
    const method = RequestFactory.HTTP_METHOD.POST;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const request = RequestFactory.create(url, payload, method, contentType);
    delete request.headers.samlToken;

    return axios(request);
};

const deleteSpouse = function (personId) {
    const deleteSpouseStructure = String(personId);

    const data = deleteSpouseStructure;
    const url = getUrl("deletePerson");

    const method = RequestFactory.HTTP_METHOD.POST;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED;
    const name = "personId";
    const request = { method, url, name, data, contentType };

    if (request.headers) {
        delete request.headers.samlToken;
    }

    return pcapServiceRequest(request);
};

export const updateSalaryReminderService = function () {
    const url = getUrl("saveReminderUpdate");
    const params = {
        salUpdMsgDisplayed: true
    };
    const method = RequestFactory.HTTP_METHOD.POST;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const request = RequestFactory.create(url, params, method, contentType);
    delete request.headers.samlToken;

    return axios(request);
};

const saveMigratedPreferences = function (config, individualId) {
    const promisesList = [];

    const savePreferencesResult = function (data) {
        logger.info("savePreferencesResult()");
        return getResponseFromData(config, data);
    };

    const savePreferencesFault = function (fault) {
        if (fault.error) {
            fault.message = fault.error.message || fault.error.userMessage;
            logger.error(
                "Save Migrated Preferences Fault( fault error code = {0}, error message = {1} )",
                [fault.error.code, fault.message]
            );
        }
        throw fault;
    };

    if (Object.prototype.hasOwnProperty.call(config, "changeBooleans")) {
        // Has user info changed
        if (
            config.changeBooleans.salary ||
            config.changeBooleans.incomeGoal ||
            config.changeBooleans.dob ||
            config.changeBooleans.termOnly ||
            config.changeBooleans.additional ||
            config.changeBooleans.confidence
        ) {
            promisesList.push(saveMigratedPersonPreferences(config, individualId));
        }
        if (config.changeBooleans.spouseAdded) {
            // Has new spouse been added or changed
            promisesList.push(
                saveSpouse(config.spouseInfo).then((response) => {
                    const resp = !ObjectUtil.isUndefinedOrNull(response.data)
                        ? response.data
                        : response;

                    if (resp.status === "FAIL") {
                        resp.error.userMessage =
                            "Save Spouse service error: " + resp.error.userMessage;
                        return Promise.reject(resp);
                    }

                    return resp;
                })
            );
        } else if (config.changeBooleans.spouseInfoChanged) {
            const spouseConfig = {
                changeBooleans: config.changeBooleans,
                userInfo: {
                    personId: config.spouseInfo.personId,
                    base: config.spouseInfo.salary,
                    retirementAge: config.spouseInfo.retirementAge,
                    firstName: config.spouseInfo.firstName,
                    gender: config.spouseInfo.gender,
                    incomeGoalValue: config.spouseInfo.incomeGoalValue,
                    incomeGoalValueUnits: config.spouseInfo.incomeGoalValueUnits,
                    dateOfBirth: config.spouseInfo.dateOfBirth,
                    type: config.spouseInfo.type
                },
                user: config.spouse
            };
            promisesList.push(saveMigratedPersonPreferences(spouseConfig, individualId));
        } else if (config.spouseDeleted) {
            promisesList.push(deleteSpouse(config.spousePersonId));
        }
    } else if (config.retirementAge || config.confidence) {
        promisesList.push(saveMigratedPersonPreferences(config, individualId));
    }

    return Promise.all(promisesList).then(savePreferencesResult, savePreferencesFault);
};
