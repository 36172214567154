/**
 * @name app.common.model.domain.ExternalAssetContributionDetail
 * @date 1/11/2018
 * @copyright 2018 Empower Retirement
 */

"use strict";

class ExternalAssetContributionDetail {
    constructor(config) {
        this.id = null;
        this.plContributionTypeCode = null;
        this.contributionPercentageFlag = null;
        this.contributionValue = null;

        if (config) {
            this.id = config.id;
            this.plContributionTypeCode = config.plContributionTypeCode;
            this.contributionPercentageFlag = config.contributionPercentageFlag;
            this.contributionValue = config.contributionValue;
        }
    }
}

export default ExternalAssetContributionDetail;
