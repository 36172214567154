import React, { memo } from "react";

interface RadioButtonItems {
    groupId: string;
    individualId: string;
    planName: string;
}
interface DeminModalRadioButtonProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    radioValue: {
        groupId: string;
        individualId: string;
        planName: string;
    };
    secDeminModal: RadioButtonItems[];
}

const DeminModalRadioButton = ({
    handleChange,
    radioValue,
    secDeminModal
}: DeminModalRadioButtonProps) => {
    return (
        <div data-testid="demin-modal-radio-button">
            {secDeminModal.map((plan) => {
                return (
                    <div
                        className="row demin-radio-item"
                        data-testid="demin-radio-item"
                        key={plan.planName}
                    >
                        <div>
                            <input
                                id={`${plan.planName}`}
                                checked={plan.planName === radioValue.planName}
                                className="period-radio"
                                name="deminPlan"
                                onChange={handleChange}
                                type="radio"
                                value={`${plan.individualId},${plan.groupId},${plan.planName}`}
                            />
                        </div>
                        <div className="accu-logo">
                            <img
                                alt="empower-logo"
                                className="logo"
                                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                            ></img>
                        </div>
                        <label htmlFor={`${plan.planName}`}>{plan.planName}</label>
                    </div>
                );
            })}
        </div>
    );
};

export default memo(DeminModalRadioButton);
