/**
 * @name app.common.model.domain.ChartLegend
 * @description
 * Definition of the combined rule domain model object.
 */
import _each from "lodash/each";

import DeferralRuleMessage from "./DeferralRuleMessage";

const CombinedRule = function (config) {
    this.minPct = null;
    this.maxPct = null;
    this.minAmt = null;
    this.maxAmt = null;
    this.deferralTypeCodes = [];
    this.messages = [];
    this.deferralGroupCode = "";

    if (config) {
        this.minPct = config.minPct;
        this.maxPct = config.maxPct;
        this.minAmt = config.minAmt;
        this.maxAmt = config.maxAmt;
        this.deferralTypeCodes = config.deferralTypeCodes;
        this.deferralGroupCode = config.defrlGrpCode;
        this.messages = [];
        if (config.messages) {
            const messagesConfig = [];
            _each(config.messages, function (message) {
                messagesConfig.push(new DeferralRuleMessage(message));
            });
            this.messages = messagesConfig;
        }
    }
};

export default CombinedRule;
