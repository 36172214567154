/**
 * @name app.common.model.domain.DeferralRestrictions
 * @date 9/6/2017
 * @copyright 2017 Empower Retirement
 * @description
 * Creates a defined object structure for the DeferralRestrictions object type.
 */

/**
 * The DeferralRestrictions object constructor.
 * @param config This object of properties you would like to base the deferral restrictions off of. Note that only primitives are used.
 */

"use strict";

class DeferralRestrictions {
    constructor(config) {
        this.restricted = false;
        this.code = null;
        this.message = null;

        if (config) {
            this.restricted = config.restricted;
            this.code = config.code;
            this.message = config.message;
        }
    }
}

export default DeferralRestrictions;
