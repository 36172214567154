import axios, { spread } from "axios";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { ObjectUtil } from "gw-shared-components";
import { get as _get, each as _each, cloneDeep as _cloneDeep } from "lodash";

import { RetirementIncomeJsonUrls } from "../constants/RetirementIncomeJsonUrls";
import LoggerFactory from "../factories/LoggerFactory";
import { executeGetAll, executeGet } from "../utils/apiUtils";
import { getUrl, getStyleGuideUrl } from "../utils/urlUtils";

//-----------------------------------------------------------------------
// PROTECTED METHODS
//-----------------------------------------------------------------------

/**
 * Allows for a single entry point for getting both the assumptions for the FE Forecast modal and the FE
 * forecast data. If both are successful, a single object is passed back.
 *
 * @param isEnrolledInFullManagedAccounts - needed to get the proper methods and assumptions as they are slightly
 * different for enrolled and non-enrolled participants
 * @returns {*}
 */
export const getFinancialEngineForecast = (isEnrolledInFullManagedAccounts) => {
    const processResults = function (resolutions) {
        const returnData = {};
        returnData.assumptions = isEnrolledInFullManagedAccounts
            ? _get(resolutions, "assumptionsResult.data.member")
            : _get(resolutions, "assumptionsResult.data.nonMember");
        returnData.feData = _get(resolutions, "feDataResult.data.data.RetirementIncomeForecast");
        return returnData;
    };

    const dataUrl = getUrl("getFEData");
    const dataRequest = axios.get(dataUrl);

    const assumptionsUrl = getStyleGuideUrl(
        RetirementIncomeJsonUrls.URL_FE_FORECAST_ASSUMPTIONS_LOCAL,
        RetirementIncomeJsonUrls.URL_FE_FORECAST_ASSUMPTIONS_LIVE
    );
    const assumptionsRequest = axios.get(assumptionsUrl);

    return executeGetAll([dataRequest, assumptionsRequest], "getFinancialEngineForecastFault").then(
        spread((...responses) => {
            return processResults({
                feDataResult: responses[0],
                assumptionsResult: responses[1]
            });
        })
    );
};

export const getAdvisoryMessaging = (individualId, planId, advisoryTranslations) => {
    const logger = LoggerFactory.getInstance("financialEngineService");

    const getAdvisoryMessagingResult = function (response) {
        if (ObjectUtil.isUndefinedOrNull(response) || ObjectUtil.isUndefinedOrNull(response.data)) {
            logger.info("getAdvisoryMessagingResult( Response is null. )");
            return null;
        } else {
            logger.info("getAdvisoryMessagingResult() for " + individualId + ", " + planId);
            logger.debug("load advisory messaging successful");
            logger.debug(JSON.stringify(response.data));

            let resultKey = null;
            let updatedTranslations = null;

            _each(response.data, (objVal, objKey) => {
                resultKey = objKey;
            });

            if (!ObjectUtil.isUndefinedOrNull(resultKey)) {
                if (!ObjectUtil.isUndefinedOrNull(response.data[resultKey])) {
                    updatedTranslations = _cloneDeep(advisoryTranslations);
                    updatedTranslations[resultKey] = _cloneDeep(response.data[resultKey]);

                    // Default to "image-shoes" class if "icon-" class not found
                    // Check if "image" field is still present; at some point it will be changed to "imageCode"
                    if (!ObjectUtil.isUndefinedOrNull(updatedTranslations[resultKey].image)) {
                        if (String(updatedTranslations[resultKey].image).indexOf("icon-") === -1) {
                            updatedTranslations[resultKey].image = "image-shoes";
                        }
                    }
                    if (!ObjectUtil.isUndefinedOrNull(updatedTranslations[resultKey].imageCode)) {
                        if (
                            String(updatedTranslations[resultKey].imageCode).indexOf("icon-") === -1
                        ) {
                            updatedTranslations[resultKey].image = "image-shoes";
                        } else {
                            updatedTranslations[resultKey].image =
                                updatedTranslations[resultKey].imageCode;
                        }
                    }
                }
            }

            return updatedTranslations
                ? {
                      [resultKey]: updatedTranslations[resultKey]
                  }
                : null;
        }
    };

    const params = {
        individualId: individualId,
        groupId: planId, // the primary plan's ID is the group ID
        pageCode: "homepage",
        treatment: "tile"
    };

    const url = StringUtil.supplant(getUrl("getAdvisoryMessaging"), params);

    return executeGet(url, "getAdvisoryMessagingFault")
        .then(getAdvisoryMessagingResult)
        .catch((err) => {
            console.error(err);
        });
};
