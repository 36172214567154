/**
 * @name app.common.model.domain.ExternalAssetInvestment
 * @date 1/11/2018
 * @copyright 2018 Empower Retirement
 */

"use strict";

class ExternalAssetInvestment {
    constructor(config) {
        this.id = null;
        this.plInvestmentTypeCode = null;
        this.investmentRiskBasedPortfolio = null;
        this.investmentAssetAllocation = null;
        this.investmentFundHoldings = null;

        if (config) {
            this.id = config.id;
            this.plInvestmentTypeCode = config.plInvestmentTypeCode;
            this.investmentRiskBasedPortfolio = config.investmentRiskBasedPortfolio;
            this.investmentAssetAllocation = config.investmentAssetAllocation;
            this.investmentFundHoldings = config.investmentFundHoldings;
        }
    }
}

export default ExternalAssetInvestment;
