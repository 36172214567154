import { useEffect } from "react";

import { useSelector, useDispatch } from "../../hooks/use-redux-actions";
import { loadHowDoICompare } from "../../middleware/howDoICompareMiddleware";
import { canShowHDICView } from "../../selectors/featureFlagSelectors";

const useHdicData = () => {
    const dispatch = useDispatch();

    const primaryPlan = useSelector((state) => state.primaryPlan);
    const { howDoICompareLoaded } = useSelector((state) => state.howDoICompare);

    const showHowDoICompare = canShowHDICView(primaryPlan);

    useEffect(() => {
        // if user has can show how do i compare view refetch data if undefined
        if (showHowDoICompare && !howDoICompareLoaded) {
            dispatch(loadHowDoICompare());
        }
    }, [showHowDoICompare, howDoICompareLoaded, dispatch]);

    return {
        showHowDoICompare,
        howDoICompareLoaded
    };
};

export default useHdicData;
