import { updateProjectedIncome } from "../projectedIncome/projectedIncomeActions";

import { SAVE_HSA_PREFERENCES, SET_HSA_LOADER } from "./hsaActionTypes";

export const saveHsaPreferencesAction = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SAVE_HSA_PREFERENCES,
            payload
        });
        dispatch(updateProjectedIncome());
        dispatch(setHsaLoader(false));
    };
};

export const setHsaLoader = (payload) => {
    return {
        type: SET_HSA_LOADER,
        payload
    };
};
