export const SET_HOW_DO_I_COMPARE = "SET_HOW_DO_I_COMPARE";

export const SET_HOW_DO_I_COMPARE_SALARY_GROUP = "SET_HOW_DO_I_COMPARE_SALARY_GROUP";
export const SET_HOW_DO_I_COMPARE_GENDER_GROUP = "SET_HOW_DO_I_COMPARE_GENDER_GROUP";
export const SET_HOW_DO_I_COMPARE_KEY_AND_SCORE = "SET_HOW_DO_I_COMPARE_KEY_AND_SCORE";
export const SET_HDIC_ME_BALANCE = " SET_HDIC_ME_BALANCE";
export const SET_HDIC_ME_CONTRIBUTION_RATE = " SET_HDIC_ME_CONTRIBUTION_RATE";
export const CHANGE_HDIC_AGE_FILTER = "CHANGE_HDIC_AGE_FILTER";
export const CHANGE_HDIC_SALARY_FILTER = "CHANGE_HDIC_SALARY_FILTER";
export const CHANGE_HDIC_GENDER_FILTER = "CHANGE_HDIC_GENDER_FILTER";
export const SET_HDIC_ERROR = "SET_HDIC_ERROR";
export const HAS_TOP_PEERS_FOCUS = "HAS_TOP_PEERS_FOCUS";
export const SET_TOP_PEERS = "SET_TOP_PEERS";
