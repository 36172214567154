import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";

const DashboardPaeError = () => {
    const paeDashboardError = selectTranslations("error").paeDashboardError;

    return (
        <div className="dashboard-subpage-pae text-center" data-testid="dashboard-pae-error">
            {paeDashboardError}
        </div>
    );
};

export default DashboardPaeError;
