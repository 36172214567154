import { getEnvironmentDisplayName } from "core-ui/client/react/core/utils/getEnvironmentDisplayName";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { batch } from "react-redux";

import { name, version } from "../../../../../package.json";
import sentryLiatInitLoadMetrics from "../../utils/sentryMetricsLogging/sentryLiatInitLoadMetrics";
import { setDeferralSetupConfigData } from "../actions/deferrals/deferralActions";
import {
    setShowNqModal,
    setShowLockheedModal,
    setLockheedTerms,
    showLockheedEnroll
} from "../actions/enrollment/enrollmentAction";
import { setShowModal } from "../actions/goal/goalModalActionCreators";
import { SET_INIT_DATA } from "../actions/initData";
import { splitOtherAssetsByEmployer } from "../actions/otherAssets/otherAssetsActions";
import { SET_PLAN_RULES_OVERRIDE_FLAGS } from "../actions/participantDetails/participantDetailsTypes";
import { setPlansWithBalances, setNqInfo } from "../actions/paycheck/paycheckActions";
import {
    updateProjectedIncome,
    setHubCalculation
} from "../actions/projectedIncome/projectedIncomeActions";
import {
    setCachedAsOfDate,
    setCurrentFault,
    readyToShowApp,
    readyToShowAppLite,
    showLoader,
    dispatchEventBus,
    setShowLoaderMessages,
    setSecondaryLoader
} from "../actions/shared/sharedActionCreators";
import { HASH_ROUTES } from "../constants/HashRoutes";
import InitDataFactory from "../factories/InitDataFactory";
import { canShowHDICView, canShowHealthCareView } from "../selectors/featureFlagSelectors";
import getInitData from "../services/getInitData";
import getInitDataLite from "../services/initDataLiteService";
import { fetchIsLockheed } from "../services/lockheedModalService";
import { modalStack } from "../services/modalStackService";
import InitDataValidator from "../utils/InitDataValidator";
import { findPrimaryPlan } from "../utils/planUtils";

import { loadHealthViewData } from "./healthCareMiddleware";
import { loadHowDoICompare } from "./howDoICompareMiddleware";
import { getEligiblePlans } from "./nqEnrollment";
import { storeStandardDetails } from "./sessionStorageMiddleware";
import setManagedAccountsData from "./setManagedAccountsData";
import { setSamlToken } from "./setSamlTokenMiddleware";
import { getTxnAccessSettings } from "./sharedMiddleware";

let validationError = false;
let initDataLoadedSuccessfully = false;

const urlParams = new URLSearchParams(window.location.search);
const delay = urlParams.get("delay") ? parseInt(urlParams.get("delay"), 10) : 0; // Default to 0 if not provided
let startTime;
let cachedEndTime;

export const initialize = () => {
    return (dispatch) => {
        dispatch(showLoader(true));
        dispatch(setSecondaryLoader(true));
        dispatch(setShowLoaderMessages(true));

        startTime = performance.now();

        // getInitData has to happen first, then the others can be processed...
        // getInitDataLite is a subset of getInitData that only returns the data needed for the LIAT
        dispatch(loadInitDataLite())
            .then(() => {
                cachedEndTime = performance.now();
                dispatch(handleInitData());
            })
            .catch((error) => {
                console.log("initialize error", error);
            });

        // TODO REMOVE AFTER testing is complete
        // Set a timeout to dispatch loadInitData after 10 seconds
        setTimeout(() => {
            dispatch(loadInitData())
                .then(() => {
                    const endTime = performance.now();
                    sentryLiatInitLoadMetrics(startTime, endTime, "TIME_TO_LIAT_FULL");
                    dispatch(handleInitData());
                })
                .catch((error) => {
                    console.log("initialize error", error);
                });
        }, delay);
    };
};

export const noLiatInitialize = () => {
    // When showLIAT = false we only need to call this function
    return (dispatch) =>
        dispatch(dispatchDependentThunks(false)).catch((error) => handleError(error));
};

export const handleInitData = () => {
    return (dispatch) => {
        // Removing dispatchDependentThunks as a setSamlToken dependency
        // Allows for faster LIAT load times
        dispatch(dispatchDependentThunks(true)).catch((error) => {
            handleError(error);
        });
        // Need to set the saml token before calling any asdb services
        dispatch(
            setSamlToken()
                .then(() => {
                    dispatch(dispatchSamlTokenDependencies());
                })
                .catch((error) => {
                    // Set indicator for advisory display
                    handleError(error);
                })
        );
    };
};

const handleError = (error) => {
    return (dispatch) => {
        let code = "INIT";
        if (error.message.includes("Hub")) {
            code = "HUB";
        }
        dispatch(setCurrentFault({ code, message: error.toString() }));
    };
};

const handleInitDataResponse = (response, dispatch, getState) => {
    if (response.status === "SUCCESSFUL") {
        try {
            const state = getState();
            const isCachedApplication = state.shared.isCachedApplication;

            if (!(isCachedApplication && response.data === null)) {
                InitDataValidator.validate(response.data);
            }
        } catch (error) {
            validationError = true;
            const fault = {
                message: error.message,
                stack: error.stack,
                code: "INIT"
            };
            throw fault;
        }
        if (!validationError) {
            const domainModel = InitDataFactory.fromServiceResponse(response.data);

            // set the globalThis migrated settings here. referenced in urlUtils
            globalThis.migratedContext = domainModel.applicationSettings.migratedContext;

            batch(() => {
                dispatch({ type: SET_INIT_DATA, payload: domainModel });
                dispatch(initGAEvent(domainModel.user));
                dispatch(setSecondaryLoader(false));

                // Add secondary loader for components such as I want to

                if (response.data.asOfDate) {
                    const cachedAsOfDate = response.data.asOfDate;
                    dispatch(setCachedAsOfDate(cachedAsOfDate));
                }
            });
        }
    } else {
        batch(() => {
            dispatch(setCurrentFault(response.error));
        });
        throw response.error;
    }
};

const handleInitDataError = (fault, dispatch) => {
    console.log("init fault", fault.message, fault.stack);
    batch(() => {
        dispatch(showLoader(false));
        dispatch(setSecondaryLoader(false));
        dispatch(setCurrentFault(fault));
    });
    throw fault;
};

export const loadInitDataLite = () => {
    return (dispatch, getState) => {
        if (initDataLoadedSuccessfully) {
            return Promise.resolve();
        }
        return getInitDataLite()
            .then((response) => {
                if (response && response.status === "SUCCESSFUL") {
                    dispatch(readyToShowAppLite(true));
                    handleInitDataResponse(response, dispatch, getState);
                }
            })
            .catch((fault) => {
                console.error("Failed to load cached init data:", fault);
                return Promise.resolve();
            });
    };
};

export const loadInitData = () => {
    return (dispatch, getState) => {
        return getInitData()
            .then((response) => {
                if (response && response.status === "SUCCESSFUL") {
                    initDataLoadedSuccessfully = true;
                }
                dispatch(readyToShowAppLite(false));
                handleInitDataResponse(response, dispatch, getState);
            })
            .catch((fault) => handleInitDataError(fault, dispatch));
    };
};

export const dispatchDependentThunks = (showLIAT) => {
    return async (dispatch, getState) => {
        const state = getState();
        const primaryPlan = state.primaryPlan;
        const plans = state.participant.plans;
        if (!validationError) {
            // Add if showLIAT
            if (showLIAT) {
                batch(() => {
                    dispatch({ type: SET_PLAN_RULES_OVERRIDE_FLAGS });
                    dispatch(updateProjectedIncome());
                    dispatch(splitOtherAssetsByEmployer());
                    dispatch(setDeferralSetupConfigData());
                    dispatch(setPlansWithBalances());
                    dispatch(storeStandardDetails());
                    dispatch(getTxnAccessSettings());
                    dispatch(setHubCalculation(true));
                });
            }

            setManagedAccountsData(dispatch);

            const eligiblePlans = getEligiblePlans(plans);

            if (eligiblePlans.length > 0) {
                await fetchIsLockheed(eligiblePlans).then((response) => {
                    if (response.data.groupLockheedPlan) {
                        // save data for lockheedModal terms of service display
                        dispatch(setLockheedTerms(response.data));
                        dispatch(setNqInfo(eligiblePlans));
                        dispatch(showLockheedEnroll(true));
                        const lockHeedAction = () => dispatch(setShowLockheedModal(true));
                        modalStack.addModal("lockHeedModal", 1, lockHeedAction);
                    } else {
                        dispatch(setNqInfo(eligiblePlans));
                        const nqAction = () => dispatch(setShowNqModal(true));
                        modalStack.addModal("nqModal", 1, nqAction);
                    }
                });
            }

            // Add if showLiat
            if (showLIAT) {
                if (primaryPlan.planRules.promptForSalary) {
                    const goalAction = () => dispatch(setShowModal(true));
                    modalStack.addModal("goalModal", 1, goalAction);
                }
            }

            batch(() => {
                dispatch(readyToShowApp());
                dispatch(showLoader(false));
            });
        }
    };
};

export const dispatchSamlTokenDependencies = () => {
    return (dispatch, getState) => {
        const state = getState();
        const participant = state.participant;
        const primaryPlan = state.primaryPlan;

        if (canShowHealthCareView(participant, primaryPlan)) {
            dispatch(loadHealthViewData());
        }

        if (canShowHDICView(primaryPlan)) {
            dispatch(loadHowDoICompare());
        }
    };
};

const initGAEvent = (user) => {
    return (dispatch) => {
        sentryLiatInitLoadMetrics(startTime, cachedEndTime, "TIME_TO_LIAT");
        const env = getEnvironmentDisplayName(window.location.host);
        const primaryPlan = findPrimaryPlan(user.plans);
        const route = HASH_ROUTES[window.location.hash] || "";
        const payload = {
            route,
            indId: user.individualId,
            planId: primaryPlan.id,
            terminated: primaryPlan.terminated || false,
            age: user.age,
            gender: user.gender,
            appVersion: `${name}@${version}`,
            environment: env,
            channel: "pptweb-liat"
        };

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: "ApplicationEvent.init_complete_event.init",
            payload
        });

        const userProps = {
            age: user.age,
            gender: user.gender,
            individualId: user.individualId,
            personId: user.pcapPersonId,
            planId: primaryPlan.id,
            planName: primaryPlan.name,
            retirementAge: user.retirementAge,
            terminated: primaryPlan.terminated || false,
            appVersion: `${name}@${version} `,
            channel: "pptweb-liat"
        };

        payload.user_properties = userProps;

        dispatch(dispatchEventBus("ApplicationEvent.init_complete_event.init", {}, payload));

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.INITIALIZE_APPLICATION,
            selection: AMPLITUDE_EVENTS.INITIALIZE_APPLICATION,
            payload
        });
    };
};
