import { ktmgFlags } from "gw-shared-components";

const isWebSearchEnabled = (
    txnCodesResponseData: Record<keyof typeof ktmgFlags, string | boolean>
): boolean => {
    const learningCenterEnabled =
        txnCodesResponseData?.[ktmgFlags.FINANCIAL_WELLNESS_DOUBLE_NETPAY]?.toString() ===
            "false" && txnCodesResponseData?.[ktmgFlags.WELLNESS_PAGE]?.toString() === "false";
    const isCustomerWebSearchEnabled =
        txnCodesResponseData?.[ktmgFlags.CUSTOMER_WEB_SEARCH]?.toString() === "true";
    return !learningCenterEnabled && isCustomerWebSearchEnabled;
};

export default isWebSearchEnabled;
