import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { Button, SliderWrapper } from "gw-shared-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import ReferenceData from "../../constants/ReferenceData";

const AssumptionsTabView = ({
    handleSliderChange,
    handleReset,
    sliderAge,
    isPAE,
    handleSliderMoving
}) => {
    const preRetireeTranslations = selectTranslations("preRetiree");
    const retirementIncomeTranslations = selectTranslations("retirementIncome");
    const { age, firstName } = useSelector((state) => state.participant);
    const canReset = !isPAE;
    const minimumAge =
        age < ReferenceData.MIN_PLAN_TO_AGE ? ReferenceData.MIN_PLAN_TO_AGE : age + 2;

    const value = minimumAge > sliderAge ? minimumAge : sliderAge;

    return (
        <div className="row">
            <div className="user-icon--container col-xs-12 col-lg-2">
                <div className="user-icon margin-default"></div>
            </div>

            <div className="col-xs-12 col-lg-9 assumption-tab-details">
                <div className="participant-name">{firstName.toLowerCase()}</div>
                <Button
                    text={preRetireeTranslations.modal.tabs.assumptions.reset}
                    buttonClasses="btn-link btn-cancel"
                    clickEvent={handleReset}
                    isDisabled={!canReset}
                />
            </div>
            <div className="col-xs-12 col-lg-9 assumptions-plan-to-age--slider">
                <SliderWrapper
                    ariaLabelText="Editable when can I retire age"
                    sliderTranslations={retirementIncomeTranslations}
                    valueUnit="years"
                    sliderId="rivd-planToAgeSlider"
                    identifier="plan-to-age-slider"
                    value={value}
                    sliderMoving={(val) => handleSliderMoving(val)}
                    sliderChange={(id, val) => handleSliderChange(val)}
                    increment={1}
                    min={minimumAge}
                    max={ReferenceData.MAX_PLAN_TO_AGE}
                    maxEditableValue={ReferenceData.MAX_PLAN_TO_AGE}
                    minEditableValue={minimumAge}
                    maxEditableEntry={ReferenceData.MAX_PLAN_TO_AGE}
                />
            </div>
        </div>
    );
};
AssumptionsTabView.propTypes = {
    handleSliderChange: PropTypes.func,
    handleReset: PropTypes.func,
    sliderAge: PropTypes.number,
    isPAE: PropTypes.bool,
    handleSliderMoving: PropTypes.func
};
export default AssumptionsTabView;
