import React, { useEffect } from "react";

import { keyboardTabNavigation } from "core-ui/client/react/core/utils/accessibilityHelpers";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { isNil as _isNil } from "lodash";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
    SOCIAL_SECURITY_ROUTE,
    OTHER_ASSETS_ROUTE,
    INCOME_GAP_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    RETIREMENT_INCOME_ROUTE,
    HSA_ROUTE,
    HEALTHCARE_COSTS_ROUTE,
    PRE_RETIREMENT_ROUTE,
    MAXIMIZER_ROUTE
} from "../../../routes";
import { setShowModal, unSetGoalFocus } from "../../actions/goal/goalModalActionCreators";
import { setTopPeersFocus } from "../../actions/howDoICompare/howDoICompareActions";
import { setLeftArrowFocus } from "../../actions/paycheck/paycheckActions";
import { dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import Constants from "../../constants/Constants";
import { useIsEmulator } from "../../hooks/useEmulatorType";
import { saveGoalChanges, updateParticipantSalaryReminder } from "../../middleware/goalMiddleware";
import { reselectTermPeriod } from "../../selectors/reselectSelectors";
import { getAgeFromDateOfBirth, convertDobFormat } from "../../utils/dateUtils";
import {
    OTHER_ASSETS_CODE,
    RETIREMENT_INCOME_CODE,
    INCOME_GAP_CODE,
    GOVERNMENT_BENEFITS_CODE,
    HSA_CODE
} from "../../utils/goalUtils";

import Goal from "./Goal";

const getSelectedSegmentCode = (route) => {
    switch (route) {
        case RETIREMENT_INCOME_ROUTE:
        case HEALTHCARE_COSTS_ROUTE:
        case HOW_DO_I_COMPARE_ROUTE:
        case PRE_RETIREMENT_ROUTE:
            return RETIREMENT_INCOME_CODE;
        case SOCIAL_SECURITY_ROUTE:
            return GOVERNMENT_BENEFITS_CODE;
        case OTHER_ASSETS_ROUTE:
            return OTHER_ASSETS_CODE;
        case HSA_ROUTE:
            return HSA_CODE;
        case MAXIMIZER_ROUTE:
        case INCOME_GAP_ROUTE:
            return INCOME_GAP_CODE;
        default:
            return "";
    }
};

const GoalIndex = ({ isFocusable }) => {
    const maRetirementGoals = selectTranslations("maRetirementGoals");
    const maCommon = selectTranslations("maCommon");
    const retirementIncome = selectTranslations("retirementIncome");
    const paycheck = selectTranslations("paycheck");

    const {
        participant,
        spouse,
        projectedIncome,
        primaryPlan,
        applicationSettings,
        shared: { showLoader, readyToShowApplication }
    } = useSelector((state) => state);

    const dispatch = useDispatch();
    const period = useSelector(reselectTermPeriod);
    const isSaving = useSelector((state) => state.goalModal.isSaving);
    const goalModalVisible = useSelector((state) => state.goalModal.showModal);
    const { hasGoalFocus } = useSelector((state) => state.goalModal);

    const promptForSalary = !!primaryPlan.planRules.promptForSalary; // Needs to be boolean for PropTypes

    useEffect(() => {
        let modalVisibleCondition = !showLoader && readyToShowApplication && promptForSalary;

        modalVisibleCondition = false;

        dispatch(setShowModal(modalVisibleCondition));
    }, [showLoader, readyToShowApplication, promptForSalary, dispatch]);

    const isPAE = useIsEmulator();
    const percentageOfGoal = projectedIncome.percentageOfGoal;
    const name = participant.firstName;
    const salary = participant.salary.base;

    const confidenceLevel = applicationSettings.projectionSettings.confidence;
    const spouseSalary = spouse.salary ? spouse.salary.base : 0;
    const saveChanges = (args) => dispatch(saveGoalChanges({ ...args, isPAE }));
    const otherVariableCompensation = participant.salary.variable;
    const hasOtherVariableCompensation = !_isNil(otherVariableCompensation);
    const dateOfBirth = participant.dateOfBirth;
    const dateOfBirthDisabled = participant.dateOfBirthSource === "FILE";
    const toggleProgressModal = () => {
        dispatch(setShowModal(!goalModalVisible));
    };
    const spouseName = spouse.firstName;
    const spouseSaved = spouse.age > 0;
    const spouseDob = spouse.dateOfBirth;
    const spouseAge = spouse.age;
    const desiredRetAge = spouse.retirementAge;
    const spouseGender = spouse.gender || "U";
    const activeTab = useLocation().pathname === HOW_DO_I_COMPARE_ROUTE;
    const mustEnterSalary = primaryPlan.salary.base === 0 && primaryPlan.terminated;
    const hasReducedSalaryOff = function () {
        return participant.hasReducedSalary !== true;
    };
    const getHasReducedSalary = participant.hasReducedSalary;
    const isApple = () => {
        return primaryPlan.id === "150550-01";
    };
    const isJPM = () => {
        return primaryPlan.id.slice(0, primaryPlan.id.indexOf("-")) === "150012";
    };
    const pathname = useLocation().pathname;

    const spousePortion = spouse.incomeGoal.value
        ? spouse.incomeGoal.value
        : spouse.incomeGoal.default;

    const isPercent = participant.incomeGoal.valueUnits === Constants.PCT;
    const isPercentSpouse = spouse.incomeGoal.valueUnits === Constants.PCT;
    const portion = participant.incomeGoal.value
        ? participant.incomeGoal.value
        : participant.incomeGoal.default;
    const primaryTabIndexUpdated = false;

    const salaryReminder = participant.salary.salaryReminder;

    const updateSalaryReminder = () => {
        return dispatch(updateParticipantSalaryReminder());
    };

    const eventBus = {
        dispatch: (payload) => {
            dispatch(dispatchEventBus(payload));
        }
    };
    const onTabNav = (event) => {
        if (!isFocusable) {
            const nextAction = () => {
                if (!goalModalVisible) {
                    event.preventDefault();
                    dispatch(setLeftArrowFocus());
                }
                dispatch(unSetGoalFocus());
            };
            const prevAction = () => {
                event.preventDefault();
                dispatch(unSetGoalFocus());
                dispatch(setTopPeersFocus());
            };
            keyboardTabNavigation({ event, nextAction, prevAction });
        }
    };

    return (
        <Goal
            isEmulator={isPAE}
            primaryTabIndexUpdated={primaryTabIndexUpdated}
            userName={name}
            spouseName={spouseName}
            salary={salary}
            spouseSalary={spouseSalary}
            dob={convertDobFormat(dateOfBirth)}
            spouseDob={convertDobFormat(spouseDob)}
            portion={portion}
            toggleProgressModal={toggleProgressModal}
            saveChanges={saveChanges}
            percentageOfGoal={percentageOfGoal}
            activeTab={activeTab}
            getAge={getAgeFromDateOfBirth}
            hasOtherVariableComp={hasOtherVariableCompensation}
            additionalComp={otherVariableCompensation}
            eventBus={eventBus}
            goalModalVisible={goalModalVisible}
            mustEnterSalary={mustEnterSalary}
            promptForSalary={promptForSalary}
            dobDisabled={dateOfBirthDisabled}
            spousePortion={spousePortion}
            desiredRetAge={desiredRetAge}
            spouseAge={spouseAge}
            spouseGender={spouseGender}
            spouseSaved={spouseSaved}
            getHasReducedSalary={getHasReducedSalary}
            period={period}
            confidenceLevel={confidenceLevel}
            isPercent={isPercent}
            isPercentSpouse={isPercentSpouse}
            hasReducedSalaryOff={hasReducedSalaryOff}
            isApple={isApple}
            isJPM={isJPM}
            tooltipTranslations={{
                ...maRetirementGoals,
                ...maCommon,
                ...retirementIncome
            }}
            labelTranslations={paycheck}
            salaryReminder={salaryReminder}
            updateSalaryReminder={updateSalaryReminder}
            incomeParts={projectedIncome.incomeParts}
            defaultIncomeGoal={participant.incomeGoal.default}
            selectedSegmentCode={getSelectedSegmentCode(pathname)}
            isLoading={isSaving}
            tabIndex={isFocusable ? "0" : "-1"}
            hasFocus={hasGoalFocus}
            onTabNav={onTabNav}
        />
    );
};
GoalIndex.propTypes = {
    isFocusable: PropTypes.bool
};

export default GoalIndex;
