type VerifyCodeSignInTime = {
    hash: string;
    timestamp: number;
} | null;

const getVerifyCodeSignIn = (): VerifyCodeSignInTime => {
    const verifyCodeSignIn = sessionStorage.getItem("participant-verifyCodeSignIn");

    if (verifyCodeSignIn) {
        return JSON.parse(verifyCodeSignIn);
    }

    return null;
};

export default getVerifyCodeSignIn;
