import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { useSelector } from "react-redux";

import { reselectTradeOffMessages } from "../../../selectors/reselectSelectors";

const ShowLimitMessage = () => {
    const { reachedLimits } = useSelector(reselectTradeOffMessages);
    const tradeOffLimitMsg = selectTranslations("tradeoffs")?.reachLimit?.generic;

    return reachedLimits.showLimitMsg ? (
        <div className="red reach-limit" data-testid="show-limit-message">
            <span data-testid="trade-off-limit-msg">{tradeOffLimitMsg}</span>
        </div>
    ) : null;
};

export { ShowLimitMessage };
