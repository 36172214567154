import NextStep from "./NextStep";
import ProjectionSettings from "./ProjectionSettings";

/**
 * @name app.common.model.domain.ApplicationSettings
 * @date 1/26/2015
 * @copyright 2015 Empower Retirement
 * @type Class
 * @description
 *
 *      Definition of the ApplicationSettings domain model object.
 *
 *      NOTE: This object is not provided by the serer as is, but the data that populates it is.
 *      We'll need to map to Domain Model properties that are provided by the server to populate
 *      this object.
 */

class ApplicationSettings {
    constructor(config) {
        this.governmentBenefits = null; //UI value
        this.projectionSettings = new ProjectionSettings();
        this.projectedIncome = null; //UI value
        this.bestNextSteps = new NextStep();
        this.tradeOffs = null; //UI value
        this.migratedContext = "";

        if (config) {
            this.projectionFactors = config.projectionFactors;
            this.governmentBenefits = config.governmentBenefits;
            this.projectionSettings = config.projectionSettings;
            this.projectedIncome = config.projectedIncome;
            this.bestNextSteps = config.bestNextSteps;
            this.tradeOffs = config.tradeOffs;
            this.migratedContext = config.migratedContext;
        }
    }
}

export default ApplicationSettings;
