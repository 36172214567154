/**
 * @name app.common.model.domain.OutsideAsset
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement

 */

"use strict";

const OutsideAsset = function (config) {
    this.id = ""; // same as assetId in the API calls
    this.name = "";
    this.type = 0;
    this.typeString = "";
    this.description = "";
    this.value = 0;
    this.planId = "";
    this.source = "";
    this.isEditable = true;
    this.isDeletable = true;
    this.isViewable = true;
    this.employerContribution = false;
    this.assetType = "otherAsset";
    this.monthlyIncomeEST = 0;

    if (config) {
        this.id = config.id;
        this.name = config.name;
        this.type = config.type;
        this.typeString = config.typeString;
        this.description = config.description;
        this.value = config.value;
        this.planId = config.planId;
        this.source = config.source;
        this.isEditable = config.isEditable;
        this.isDeletable = config.isDeletable;
        this.isViewable = config.isViewable;
        this.employerContribution = config.employerContribution;
        this.assetType = config.assetType;
        this.monthlyIncomeEST = config.monthlyIncomeEST;
    }
};

export default OutsideAsset;
