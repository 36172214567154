/**
 * @class common.model.domain.PlanMatchRuleRate
 * @copyright 2015 Empower Retirement
 * @description
 * Creates a defined object structure for the PlanMatchRuleRate
 */

"use strict";

const PlanMatchRuleRate = function (config) {
    this.tierBasedOn = null;
    this.matchPct = 0;
    this.lowThreshold = null;
    this.highThreshold = null;
    this.matchPctStartDate = null;
    this.matchPctStopDate = null;

    if (config) {
        this.tierBasedOn = config.tierBasedOn;
        this.matchPct = config.matchPct;
        this.lowThreshold = config.lowThreshold;
        this.highThreshold = config.highThreshold;
        this.matchPctStartDate = config.matchPctStartDate;
        this.matchPctStopDate = config.matchPctStopDate;
    }
};

export default PlanMatchRuleRate;
