/**
 *
 * @memberOf common.model.constants
 * @date 2015
 * @copyright 2018 Empower Retirement
 * @description This module equity mixes for different risk levels
 *
 */
"use strict";

const EquityRiskLevels = {
    riskLevels: {
        CNSRVTV: 0.2,
        MDRT: 0.6,
        AGGRSV: 0.8
    },
    terms: {
        AGGRESSIVE: "aggressive",
        APPROPRIATE: "appropriate",
        CONSERVATIVE: "conservative"
    },
    assetCodes: {
        //stocks
        USLC: true,
        USSMC: true,
        INTL: true,
        //bonds
        BND: false,
        TIPS: false,
        CSH: false
    }
};

export default EquityRiskLevels;
