import React from "react";

import { ToolTip } from "gw-shared-components";
import PropTypes from "prop-types";

import { ACCESSIBILITY } from "../../../../constants/AccessibilityPaycheck";

const MyPeers = ({ translations }) => {
    return (
        <td className="integrated-hdic-peers hdic-tooltip">
            <div
                className="integrated-hdic-my-peers-title"
                id={ACCESSIBILITY.HOW_DO_I_COMPARE["my-peers-title"]}
            >
                {translations.peers.rivdTitle}&nbsp;
                <ToolTip
                    text={translations.peers.popoverMessage}
                    popoverProps={{ crossOffset: 60, placement: "top" }}
                >
                    <i className="em-question-circle integrated-hdic-color-primary-1000" />
                </ToolTip>
            </div>
        </td>
    );
};
MyPeers.propTypes = {
    translations: PropTypes.object
};

export default MyPeers;
