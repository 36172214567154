import { IAccountSummary, IAccount } from "../../../types/common";

type NullableAccountSummary = IAccountSummary | null | undefined;

const DEFAULT_MONTHLY_ACCOUNTS: IAccountSummary["monthlyAccounts"] = [];
const DEFAULT_NON_MONTHLY_ACCOUNTS: IAccountSummary["nonMonthlyAccounts"] = [];

export function filterTrsPlansOnly(account: IAccount) {
    return account?.groupDetails?.irsrlCode === "TRS";
}

export function filterTrsPlans(account: IAccount) {
    return account?.groupDetails?.irsrlCode !== "TRS";
}

const hasTrsPlan = (accountSummary: NullableAccountSummary) => {
    if (
        !accountSummary ||
        (!accountSummary.monthlyAccounts && !accountSummary.nonMonthlyAccounts)
    ) {
        return false;
    }

    const { nonMonthlyAccounts, monthlyAccounts } = accountSummary;
    const filteredMonthly =
        (monthlyAccounts && monthlyAccounts.filter(filterTrsPlansOnly)) || DEFAULT_MONTHLY_ACCOUNTS;
    const filteredNonMonthly =
        (nonMonthlyAccounts && nonMonthlyAccounts.filter(filterTrsPlansOnly)) ||
        DEFAULT_NON_MONTHLY_ACCOUNTS;

    return filteredMonthly.length > 0 || filteredNonMonthly.length > 0;
};

export default hasTrsPlan;
