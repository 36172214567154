import axios from "axios";
import { isUndefinedOrNull } from "gw-shared-components";

import LoggerFactory from "../factories/LoggerFactory.js";
import RequestFactory from "../factories/RequestFactory.js";
import { handleFault } from "../utils/errorUtils";
import { getUrl } from "../utils/urlUtils.js";

const logger = LoggerFactory.getInstance("SetSamlTokenService");

let assertionFlag = false;

export const getSamlAssertion = () => {
    logger.info("getSamlAssertion()");

    /**
     * Service success handler and promise resolver.
     * @param result
     */
    const getResult = function (result) {
        if (
            isUndefinedOrNull(result) ||
            isUndefinedOrNull(result.data) ||
            isUndefinedOrNull(result.data.data)
        ) {
            logger.error("getSamlAssertionResult( Bad response data. )");
            throw new Error("There is no response data.");
        } else {
            logger.info("getSamlAssertionResult()");
            return result.data.data.assertion;
        }
    };

    /**
     * Service fault handler and promise rejection.
     * @param fault
     */
    const getFault = function (fault) {
        logger.error("getSamlAssertionFault( fault = {0} )", [fault.message]);
        return handleFault(fault);
    };

    const service = "getSamlAssertionForMax";
    const url = getUrl(service);

    return axios.get(url).then(getResult, getFault);
};

const setCookie = function (samlAssertion) {
    logger.info("setCookie()");

    if (assertionFlag) {
        return;
    }

    /**
     * Service success handler and promise resolver.
     * @param result
     */
    const getResult = function (result) {
        logger.info("setCookieResult()");
        if (result) {
            if (result.data && result.data.message === "Unsupported Media Type") {
                const fault = {
                    code: "DSB100",
                    message: "Unsupported Media Type"
                };
                return handleFault(fault);
            }
            assertionFlag = result.assertionFlag;
            return result.assertionFlag;
        }
    };

    /**
     * Service fault handler and promise rejection.
     * @param fault
     */
    const getFault = function (fault) {
        logger.error("setCookieFault( fault = {0} )", [fault.message]);
        return handleFault(fault);
    };

    const service = "setASDBCookie";
    const url = getUrl(service);

    const method = RequestFactory.HTTP_METHOD.POST;
    const params = {};
    const token = samlAssertion;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const request = RequestFactory.create(url, params, method, contentType, token);

    return axios(request).then(getResult, getFault);
};

export const getThenSetToken = () => {
    return getSamlAssertion()
        .then(setCookie)
        .catch((error) => {
            return logger.error("getSamlAssertion() - ", error);
        });
};
