// Action creator definitions

import {
    SALARY_CHANGE,
    PORTION_CHANGE,
    CHANGE_VIEW,
    CHANGE_SPOUSE_NAME,
    SPOUSE_SALARY_CHANGE,
    GENDER_CHANGE,
    SPOUSE_DESIRED_AGE,
    SPOUSE_CHANGE_PORTION,
    ADD_COMP,
    ADD_SPOUSE,
    CANCEL,
    SET_SHOW_MODAL,
    DELETE_SPOUSE,
    NO_PROMPT,
    SAVE_DELETE,
    TAB_CHANGE,
    PARTICIPANT_DOB_CHANGE,
    SPOUSE_DOB_CHANGE,
    SET_GOAL_SAVING,
    HAS_GOAL_FOCUS
} from "./goalModalActionTypes";

export const participantDobChange = (dob) => {
    return {
        type: PARTICIPANT_DOB_CHANGE,
        dob
    };
};

export const spouseDobChange = (dob) => {
    return {
        type: SPOUSE_DOB_CHANGE,
        dob
    };
};

const salaryChange = (salary) => {
    return {
        type: SALARY_CHANGE,
        salary
    };
};

const portionChange = (portion, isPercent) => {
    return {
        type: PORTION_CHANGE,
        portion,
        isPercent
    };
};

const tabChange = (primaryTabIndexUpdated) => {
    return {
        type: TAB_CHANGE,
        primaryTabIndexUpdated
    };
};

const setGoalFocus = () => {
    return {
        type: HAS_GOAL_FOCUS,
        payload: true
    };
};

const unSetGoalFocus = () => {
    return {
        type: HAS_GOAL_FOCUS,
        payload: false
    };
};

const changeView = (period) => {
    return {
        type: CHANGE_VIEW,
        period
    };
};
const changeSpouseName = (name) => {
    return {
        type: CHANGE_SPOUSE_NAME,
        name
    };
};

const spouseSalaryChange = (salary) => {
    return {
        type: SPOUSE_SALARY_CHANGE,
        salary
    };
};

const genderChange = (gender) => {
    return {
        type: GENDER_CHANGE,
        gender
    };
};

const spouseDesiredAge = (age) => {
    return {
        type: SPOUSE_DESIRED_AGE,
        age
    };
};

const spouseChangePortion = (portion, isPercent) => {
    return {
        type: SPOUSE_CHANGE_PORTION,
        portion,
        isPercent
    };
};

const addComp = (comp) => {
    return {
        type: ADD_COMP,
        comp
    };
};

const addSpouse = () => {
    return {
        type: ADD_SPOUSE
    };
};

const cancel = (originalProps) => {
    return {
        type: CANCEL,
        props: originalProps
    };
};

const setShowModal = (payload) => {
    return {
        payload,
        type: SET_SHOW_MODAL
    };
};

const deleteSpouse = () => {
    return {
        type: DELETE_SPOUSE
    };
};

const noPrompt = () => {
    return {
        type: NO_PROMPT
    };
};

const saveDelete = () => {
    return {
        type: SAVE_DELETE
    };
};

const setGoalSaving = (value) => {
    return {
        type: SET_GOAL_SAVING,
        value
    };
};

export {
    salaryChange,
    portionChange,
    changeView,
    changeSpouseName,
    spouseSalaryChange,
    genderChange,
    spouseDesiredAge,
    spouseChangePortion,
    addComp,
    addSpouse,
    cancel,
    setShowModal,
    deleteSpouse,
    noPrompt,
    saveDelete,
    tabChange,
    setGoalSaving,
    setGoalFocus,
    unSetGoalFocus
};
