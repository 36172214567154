import { isUndefinedOrNull } from "gw-shared-components";
import { uniq as _uniq } from "lodash";
import { batch } from "react-redux";

import Deferrals from "../../business/deferralsLogic/deferrals";
import DeferralConstants from "../../constants/DeferralConstants";
import Deferral from "../../models/Deferral";
import DeferralConfig from "../../models/DeferralConfig";
import DeferralLimits from "../../models/DeferralLimits";
import { setBaselineDeferrals } from "../baseline/baselineActions";
import { SET_BASELINE_DEFERRAL_SUMMARY_TOTAL } from "../baseline/baselineTypes";
import { updateActiveDeferral } from "../deferrals/activeDeferralActions";
import { setHDICContributionRate } from "../howDoICompare/howDoICompareActions";
import {
    setParticipantDeferrals,
    setParticipantPlansNewDeferrals,
    updateParticipantSalary
} from "../participantDetails/participantDetailsActions";
import { setDeferralInfo, updatePrimaryPlanSalary } from "../plans/primaryPlanActions";
import { updateProjectedIncome } from "../projectedIncome/projectedIncomeActions";

import {
    SET_DEFERRAL_SETUP_CONFIG,
    SET_DEFERRAL_SUMMARY_TOTAL,
    DEFERRAL_SLIDER_CHANGE,
    CHANGED_AUTO_INCREASE_DEFERRALS,
    CHANGED_FUTURE_DATED_DEFERRALS,
    DEFERRAL_CHANGED_PRIMARY_PLAN_MAXIMZER_UNENROLLING,
    SET_CHANGED_DEFERRALS_ARRAY,
    SET_DEFERRAL_WARNING,
    SET_ADDITIONAL_DEFERRAL_LIST,
    SET_DEFERRAL_SUMMARY_TOTAL_BASE_AND_VAR,
    SET_HAS_VARIABLE_DEFERRAL,
    RESET_AUTO_INCREASE_DEFERRALS,
    RESET_FUTURE_DATED_DEFERRALS
} from "./deferralActionTypes";

/**
 * ACTIONS FOUND IN THIS FILE
 * @setDeferralSetupConfigData
 * @initializeDeferralSetupConfigParams
 * @setOriginalDeferralSummaryTotal
 * @deferralSliderChange
 * @deferralAutoIncreaseChanged
 * @deferralFutureDatedChanged
 * @deferralChangedPrimaryPlanMaximzerEnrolling
 */

export const setDeferralSetupConfigData = () => {
    return (dispatch, getState) => {
        const state = getState();
        const deferrals = state.participant.deferrals;
        dispatch(initializeDeferralSetupConfigParams(deferrals));
        dispatch(setOriginalDeferralSummaryTotal(deferrals));
    };
};

export const initializeDeferralSetupConfigParams = (deferrals) => {
    let hasMix = false;
    let hasVariableDeferral = false;
    let hasAllDollarDeferrals = true;
    let hasVariableDollarDeferral = false;
    let csorEstimatedCount = 0;
    let nonZeroVariableDeferralCount = 0;
    let deferralTotalVarPct = 0;
    let deferralTotalBasePct = 0;
    const valueUnits = deferrals.length > 0 ? deferrals[0].valueUnits : 0;

    return (dispatch, getState) => {
        const state = getState();
        const primaryPlan = state.primaryPlan;
        const deferralSetupConfig = state.deferralSetupConfig;
        const deferralLogic = new Deferrals(deferrals, primaryPlan, deferralSetupConfig);

        const canShowMultiple = deferralLogic.getCanShowMultiple();
        nonZeroVariableDeferralCount = deferralSetupConfig.nonZeroVariableDeferralCount;
        deferralTotalVarPct = deferralSetupConfig.deferralTotalVarPct;

        addIsMixedDeferralFlagToEachDeferral(deferrals);

        deferrals.forEach((deferral) => {
            const isVariableDeferral = deferralLogic.isVariableDeferral(deferral);
            const isDollarDeferral = deferralLogic.isDollarDeferral(deferral);
            hasMix = hasMix || deferral.valueUnits !== valueUnits;
            hasVariableDeferral = hasVariableDeferral || isVariableDeferral;
            hasAllDollarDeferrals = hasAllDollarDeferrals && isDollarDeferral;
            hasVariableDollarDeferral =
                hasVariableDollarDeferral || (isDollarDeferral && isVariableDeferral);

            if (isVariableDeferral && deferral.value > 0) {
                nonZeroVariableDeferralCount = deferralLogic.adjustNonZeroVariableDeferralCount(
                    1,
                    nonZeroVariableDeferralCount
                );
            }

            if (deferral.csor) {
                if (canShowMultiple) {
                    csorEstimatedCount++;
                } else {
                    if (deferral.active) {
                        csorEstimatedCount++;
                    }
                }
            }
            if (isVariableDeferral) {
                deferralTotalVarPct += deferralLogic.updateTotalsForSalaryTypes(deferral, null);
            } else {
                deferralTotalBasePct += deferralLogic.updateTotalsForSalaryTypes(deferral, null);
            }
        });

        csorEstimatedCount = deferralLogic.adjustCsorEstimatedCount(
            csorEstimatedCount,
            deferralSetupConfig,
            deferrals
        );

        const payload = {
            hasMixedDeferrals: hasMix,
            hasVariableDeferral,
            deferralTotalBasePct,
            deferralTotalVarPct,
            hasAllDollarDeferrals,
            hasVariableDollarDeferral,
            nonZeroVariableDeferralCount,
            csorEstimatedCount
        };

        dispatch({
            type: SET_DEFERRAL_SETUP_CONFIG,
            payload
        });

        dispatch(setDeferralSummaryTotalsForBase(payload));
    };
};

export const setDeferralSummaryTotals = (payload) => {
    return { type: SET_DEFERRAL_SUMMARY_TOTAL, payload };
};

/**
 * set total base pct and total var pct
 */
export const setDeferralSummaryTotalsForBase = (config) => {
    const payload = {
        deferralTotalBasePct: config.deferralTotalBasePct,
        deferralTotalVarPct: config.deferralTotalVarPct
    };
    return { type: SET_DEFERRAL_SUMMARY_TOTAL_BASE_AND_VAR, payload };
};

export const setOriginalDeferralSummaryTotal = (deferrals) => {
    return (dispatch, getState) => {
        const state = getState();
        const primaryPlan = state.primaryPlan;
        const deferralSetupConfig = state.deferralSetupConfig;
        const deferralLogic = new Deferrals(deferrals, primaryPlan, deferralSetupConfig);
        const payload = deferralLogic.setOriginalDeferralSummaryTotal(deferrals);
        dispatch(setDeferralSummaryTotals(payload));
        dispatch({ type: SET_BASELINE_DEFERRAL_SUMMARY_TOTAL, payload });
        const defConfigTotal = {
            deferralTotalBasePct: deferralSetupConfig.deferralTotalBasePct,
            deferralTotalVarPct: deferralSetupConfig.deferralTotalVarPct
        };
        dispatch(setDeferralSummaryTotalsForBase(defConfigTotal));
        dispatch({ type: SET_DEFERRAL_SUMMARY_TOTAL_BASE_AND_VAR, payload: defConfigTotal });
    };
};

export const deferralSliderChange = (deferralType, value, deferral) => {
    return (dispatch, getState) => {
        const state = getState();
        const primaryPlan = state.primaryPlan;
        const activeDeferral = state.activeDeferral;
        const deferralSetupConfig = state.deferralSetupConfig;
        const newDeferrals = state.participant.deferrals;
        const baselineDeferrals = state.baseline.deferrals;
        const deferralLogic = new Deferrals(
            newDeferrals,
            primaryPlan,
            deferralSetupConfig,
            deferral
        );

        const updatedSliderValues = deferralLogic.updateDeferralSlider(
            deferralType,
            value,
            deferral
        );
        if (isUndefinedOrNull(updatedSliderValues)) {
            return;
        }

        const deferralToUpdate = new Deferral(updatedSliderValues.newDeferral);

        const updatedDeferrals = newDeferrals.map((def) => {
            if (def.deferralTypeCode === deferralType) {
                return deferralToUpdate;
            }
            return def;
        });
        const originalDeferral = baselineDeferrals.find(
            (def) => def.deferralTypeCode === deferralType
        );

        batch(() => {
            // If it's a maximizer deferral - update the status hash
            if (
                primaryPlan.maximizer &&
                primaryPlan.maximizer.isOngoing &&
                activeDeferral.config.maximizeEligibleInd &&
                !primaryPlan.maximizer.isUnenrolling
            ) {
                // maximizer isUnenrolling is true when you change a deferral that is enrolled in maximizer
                // update active plan maximizer
                // AFTER deferral is not eligible in maximizer changes
                if (deferralType !== DeferralConstants.typeCodes.AFTER) {
                    dispatch(deferralChangedPrimaryPlanMaximzerUnEnrolling(true));
                }
            }

            dispatch({
                type: DEFERRAL_SLIDER_CHANGE,
                payload: updatedSliderValues.newDeferralConfig
            });
            dispatch(updateActiveDeferral(deferralToUpdate));
            dispatch(setParticipantDeferrals(updatedDeferrals));
            dispatch(setParticipantPlansNewDeferrals(updatedDeferrals));
            dispatch(setChangedDeferralsArray(updatedDeferrals));
            dispatch(
                setHDICContributionRate(
                    primaryPlan,
                    updatedSliderValues.newDeferralConfig,
                    activeDeferral
                )
            );

            dispatch(updateProjectedIncome());

            if (!isUndefinedOrNull(deferralToUpdate.autoIncrease)) {
                if (originalDeferral.value === value) {
                    dispatch(resetDeferralAutoIncrease(deferral));
                } else {
                    dispatch(deferralAutoIncreaseChanged(deferralToUpdate, updatedDeferrals));
                }
            }

            if (!isUndefinedOrNull(deferralToUpdate.futureDated)) {
                if (originalDeferral.value === value) {
                    dispatch(resetDeferralFutureDated(deferral));
                } else {
                    dispatch(deferralFutureDatedChanged(deferralToUpdate));
                }
            }
        });
    };
};

export const deferralAutoIncreaseChanged = (deferralToUpdate, updatedDeferrals) => {
    return {
        type: CHANGED_AUTO_INCREASE_DEFERRALS,
        payload: { deferralToUpdate, updatedDeferrals }
    };
};

export const resetDeferralAutoIncrease = (payload) => {
    return {
        type: RESET_AUTO_INCREASE_DEFERRALS,
        payload
    };
};

export const deferralFutureDatedChanged = (payload) => {
    return {
        type: CHANGED_FUTURE_DATED_DEFERRALS,
        payload
    };
};

export const resetDeferralFutureDated = (payload) => {
    return {
        type: RESET_FUTURE_DATED_DEFERRALS,
        payload
    };
};

export const deferralChangedPrimaryPlanMaximzerUnEnrolling = (payload) => {
    return {
        type: DEFERRAL_CHANGED_PRIMARY_PLAN_MAXIMZER_UNENROLLING,
        payload
    };
};

export const reconfigureSummarySlider = () => {
    return (dispatch, getState) => {
        const state = getState();
        const deferrals = state.participant.deferrals;
        dispatch(initializeDeferralSetupConfigParams(deferrals));
        dispatch(setOriginalDeferralSummaryTotal(deferrals));
        dispatch(updateAdditionalDeferralList());
        dispatch(updateNewDeferralEffectiveDates());
    };
};

export const setupSummarySlider = () => {
    return (dispatch, getState) => {
        const state = getState();
        const deferrals = state.baseline.deferrals;
        //dispatch(updateAllDeferralChangedHashes(deferralLogic.getAllDeferralChangedHashes(state.participant.plans))) @TODO work out how deferralChangeProperties work
        dispatch(initializeDeferralSetupConfigParams(deferrals));
        dispatch(setOriginalDeferralSummaryTotal(deferrals));
        dispatch(updateAdditionalDeferralList());
        dispatch(updateDeferralEffectiveDates());
        dispatch(updateNewDeferralEffectiveDates());
    };
};

export const setAdditionalDeferralList = (list) => {
    return {
        type: SET_ADDITIONAL_DEFERRAL_LIST,
        payload: list
    };
};

export const updateAdditionalDeferralList = () => {
    return (dispatch, getState) => {
        const state = getState();
        const deferrals = state.baseline.deferrals;
        const primaryPlan = state.primaryPlan;
        const deferralSetupConfig = state.deferralSetupConfig;
        const deferralLogic = new Deferrals(deferrals, primaryPlan, deferralSetupConfig);
        dispatch(setAdditionalDeferralList(deferralLogic.getAdditionalDeferralList()));
    };
};

export const updateNewDeferralEffectiveDates = () => {
    return (dispatch, getState) => {
        const state = getState();
        const deferrals = state.participant.deferrals;
        const primaryPlan = state.primaryPlan;
        const deferralSetupConfig = state.deferralSetupConfig;
        const deferralLogic = new Deferrals(deferrals, primaryPlan, deferralSetupConfig);
        dispatch(setParticipantDeferrals(deferralLogic.updateDeferralEffectiveDates(), true));
    };
};

export const updateDeferralEffectiveDates = () => {
    return (dispatch, getState) => {
        const state = getState();
        const deferrals = state.baseline.deferrals;
        const primaryPlan = state.primaryPlan;
        const deferralSetupConfig = state.deferralSetupConfig;
        const deferralLogic = new Deferrals(deferrals, primaryPlan, deferralSetupConfig);
        dispatch(setBaselineDeferrals(deferralLogic.updateDeferralEffectiveDates()));
    };
};

export const updateCsorPercentDeferralsAfterSalaryChange = (config) => {
    return (dispatch, getState) => {
        const state = getState();
        const deferrals = state.participant.deferrals;
        const primaryPlan = state.primaryPlan;
        const deferralSetupConfig = state.deferralSetupConfig;
        const deferralLogic = new Deferrals(deferrals, primaryPlan, deferralSetupConfig);
        const updates = deferralLogic.getCsorPercentDeferralsAfterSalaryChange(config);
        dispatch(setBaselineDeferrals(updates.deferrals));
        dispatch(setParticipantDeferrals(updates.newDeferrals));
        dispatch(setupSummarySlider());
    };
};

/**
 * @memberOf common.service.business.DeferralService
 * @description updates the arrays that keep track of the changed full and model only deferrals.  Note that
 * this only gets called when "Review Changes" is clicked and these arrays are used to simplify some of the
 * iterations in the reviewchanges and confirmchages directive(s)
 */
export const setChangedDeferralsArray = (deferralsArray) => {
    return (dispatch, getState) => {
        const state = getState();
        const deferrals = deferralsArray ? deferralsArray : state.participant.deferrals;
        const baselineDeferrals = state.baseline.deferrals;
        const primaryPlan = state.primaryPlan;
        const deferralSetupConfig = state.deferralSetupConfig;
        const deferralLogic = new Deferrals(deferrals, primaryPlan, deferralSetupConfig);

        const changedDeferralsFull = [];
        const changedDeferralsModel = [];
        const changedDeferralsGrandfathered = [];

        deferrals.forEach((deferral) => {
            const isGrandfatheredDeferral = deferralLogic.isGrandfatheredDeferral(deferral);
            const isFullDeferral = deferralLogic.isFullDeferral(deferral);
            const deferralTypeCode = deferral.deferralTypeCode;
            const correspondingDeferral = baselineDeferrals.find(
                (def) => def.deferralTypeCode === deferralTypeCode
            );

            if (deferral.value !== correspondingDeferral.value) {
                if (isGrandfatheredDeferral) {
                    deferral.priorValue = correspondingDeferral.value;
                    changedDeferralsGrandfathered.push(deferral);
                } else if (isFullDeferral) {
                    // add the prior value to the deferral so that we will have everything we need in the review changes modal
                    deferral.priorValue = correspondingDeferral.value;
                    changedDeferralsFull.push(deferral);
                } else if (!isFullDeferral) {
                    // add the prior value to the deferral so that we have everything
                    deferral.priorValue = correspondingDeferral.value;
                    changedDeferralsModel.push(deferral);
                }
            }
        });

        const payload = {
            changedDeferralsFull,
            changedDeferralsModel,
            changedDeferralsGrandfathered
        };

        dispatch({
            type: SET_CHANGED_DEFERRALS_ARRAY,
            payload
        });
    };
};

export const setDeferralRules = (
    deferrals,
    primaryPlan,
    deferralSetupConfig,
    activeDeferral,
    translations
) => {
    const rule100 = [
        "The contributions you have selected are over 100% of the salary you have recorded."
    ];

    const deferralLogic = new Deferrals(
        deferrals,
        primaryPlan,
        deferralSetupConfig,
        activeDeferral,
        translations
    );
    //check for deferralInfo
    if (isUndefinedOrNull(deferrals)) {
        return deferralLogic.checkIfTotalDeferralPercentIsOver(100) ? rule100 : [];
    }
    //check for rule breaks
    let brokenRules = deferralLogic
        .checkCombinedDeferralRules(deferrals, deferralSetupConfig)
        .concat(deferralLogic.checkTieredDeferralRules(deferrals))
        .concat(deferralLogic.checkMinDeferralRules(deferrals));

    //check for over 100%
    if (deferralLogic.checkIfTotalDeferralPercentIsOver(100)) {
        brokenRules = rule100.concat(brokenRules);
    }

    const changedFullDeferrals = deferralLogic.getChangedDeferrals("full");
    let hasDifferentMixedDeferralConfig = false;
    if (changedFullDeferrals.some((deferral) => deferral.isMixedDeferral === true)) {
        hasDifferentMixedDeferralConfig = true;
    }

    const mixPctAmtCode = ["mixPctAmtCode"];
    //Exlude HSA Plan even if it is a mixed match amount type.
    const isHSAPlan = deferralLogic.isHSAPlan(activeDeferral.plan);
    // check for mix types, if true, pass the keyword as broken rule.
    if (deferralSetupConfig.hasDeferralChanged && hasDifferentMixedDeferralConfig && !isHSAPlan) {
        brokenRules = mixPctAmtCode.concat(brokenRules);
    }

    return {
        type: SET_DEFERRAL_WARNING,
        payload: _uniq(brokenRules)
    };
};

/**
 * Reset changedDeferrals arrays
 * @param {*} config
 */
export const resetChangedDeferrals = (resetModel, resetFull, resetGrand) => {
    return (dispatch, getState) => {
        const state = getState();
        const changedDeferralsModel = state.deferralSetupConfig.changedDeferralsModel;
        const changedDeferralsGrandfathered =
            state.deferralSetupConfig.changedDeferralsGrandfathered;
        const changedDeferralsFull = state.deferralSetupConfig.changedDeferralsFull;

        const payload = {
            changedDeferralsModel,
            changedDeferralsGrandfathered,
            changedDeferralsFull
        };

        if (resetModel) {
            payload.changedDeferralsModel = [];
        }
        if (resetFull) {
            payload.changedDeferralsFull = [];
        }
        if (resetGrand) {
            payload.changedDeferralsGrandfathered = [];
        }

        dispatch({
            type: SET_CHANGED_DEFERRALS_ARRAY,
            payload
        });

        const nextState = getState();
        const primaryPlan = nextState.primaryPlan;
        const activeDeferral = nextState.activeDeferral;
        const deferralSetupConfig = nextState.deferralSetupConfig;

        dispatch(setHDICContributionRate(primaryPlan, deferralSetupConfig, activeDeferral));
    };
};

export const setHasVariableDeferral = (payload) => {
    return {
        type: SET_HAS_VARIABLE_DEFERRAL,
        payload
    };
};

export const addNewDeferral = (deferralOption, valueUnits, tempVariableSalary, deferrals) => {
    return (dispatch, getState) => {
        const state = getState();
        const primaryPlan = state.primaryPlan;
        const availableDeferrals = primaryPlan.deferralInfo.availableDeferrals;

        const groupCodeHash = primaryPlan.deferralInfo.enrollmentGroupCodeHash;
        const hasNoDeferrals = primaryPlan.deferrals.length === 0;
        const variableSalary = state.participant.salary.variable;
        const baselineDeferrals = state.baseline.deferrals;
        const newDeferrals = [...deferrals];
        const deferralLogic = new Deferrals();

        // first take out any matching deferrals of new available deferrals
        const newAvailableDef = availableDeferrals.filter(
            (def) => def.deferralTypeCode !== deferralOption.deferralTypeCode
        );

        // remove from dataModel.user.newAvailableDeferrals
        for (let i = 0; i < availableDeferrals.length; i++) {
            if (availableDeferrals[i].deferralTypeCode === deferralOption.deferralTypeCode) {
                dispatch(
                    setDeferralInfo({
                        availableDeferrals: newAvailableDef
                    })
                );
                break;
            }
        }

        if (
            deferralOption.enrollmentGroupCode &&
            groupCodeHash &&
            !groupCodeHash[deferralOption.enrollmentGroupCode]
        ) {
            const newHash = { ...groupCodeHash };
            newHash[deferralOption.enrollmentGroupCode] = valueUnits;

            dispatch(
                setDeferralInfo({
                    enrollmentGroupCodeHash: newHash
                })
            );
        }
        // dataModel.activePlan.deferralInfo.newAvailableDeferrals = newAvailableDef;
        //now add a 0 value deferral to both deferrals and newDeferrals
        const deferralConfigConfig = {
            incomeStream: deferralOption.incomeStream,
            taxStatus: deferralOption.taxStatus,
            ageCatchupApplicable: deferralOption.ageCatchupApplicable,
            allowAgeCombinedInd: deferralOption.allowAgeCombinedInd,
            displayName: deferralOption.displayName,
            enrollmentGroupCode: deferralOption.enrollmentGroupCode,
            defrlAvailCode: deferralOption.defrlAvailCode,
            csor: false,
            minRequiredInd: deferralOption.minRequiredInd === "Y"
        };

        const limitsConfig = {
            maxDeferral: 100,
            minDeferral: 0,
            maxSlider: deferralOption.maxSlider,
            granularity: 1
        };
        if (valueUnits === "percent") {
            limitsConfig.maxDeferral = deferralOption.maxPct;
            limitsConfig.minDeferral = deferralOption.minPct;
            limitsConfig.granularity = deferralOption.granularityPct;
        } else {
            limitsConfig.maxDeferral = deferralOption.maxAmt;
            limitsConfig.minDeferral = deferralOption.minAmt;
            limitsConfig.granularity = deferralOption.granularityAmt;
        }
        const config = {
            deferralTypeCode: deferralOption.deferralTypeCode,
            value: 0,
            valueUnits: valueUnits,
            nycGoalFlag: null,
            nextPayrollDate: deferralOption.nextPayrollDate,
            submissionDate: deferralOption.submissionDate,
            config: new DeferralConfig(deferralConfigConfig),
            deferralLimits: new DeferralLimits(limitsConfig),
            plan: deferralOption.plan,
            planId: deferralOption.plan.id,
            pctAmtCode: deferralOption.pctAmtCode
        };

        const deferral = new Deferral(config);

        // add before HSA
        const index = newDeferrals.findIndex(
            (obj) => obj.deferralTypeCode === DeferralConstants.typeCodes.HSA
        );

        // HSA deferral always last in the list ¯\_(ツ)_/¯
        if (index === -1) {
            newDeferrals.push(deferral);
            baselineDeferrals.push(deferral);
        } else {
            newDeferrals.splice(index, 0, deferral);
            baselineDeferrals.splice(index, 0, deferral);
        }

        dispatch(setParticipantDeferrals(newDeferrals));

        // keep baseline deferrals, and append new deferrals
        dispatch(setBaselineDeferrals(baselineDeferrals));

        if (
            isUndefinedOrNull(variableSalary) &&
            tempVariableSalary &&
            deferralLogic.isVariableDeferral(deferral)
        ) {
            dispatch(
                updateParticipantSalary({
                    tempVariable: tempVariableSalary
                })
            );
            dispatch(
                updatePrimaryPlanSalary({
                    tempVariable: tempVariableSalary
                })
            );
        }

        if (deferralLogic.isVariableDeferral(deferral)) {
            dispatch(setHasVariableDeferral(true));
        }

        /* If there were no deferrals to begin with - summary slider needs to get called */
        if (hasNoDeferrals) {
            dispatch(setupSummarySlider());
        }

        dispatch(setDeferralSetupConfigData());
        dispatch(setOriginalDeferralSummaryTotal(newDeferrals));
    };
};

/**
 * Remove autoincrease object afte a cancel on autoincrease has completed
 * @param {} updatedDeferral
 */
export const clearAutoIncreaseOrFutureDated = (deferral, isAutoIncrease) => {
    return (dispatch, getState) => {
        const state = getState();
        const newDeferrals = state.participant.deferrals;

        if (!isUndefinedOrNull(deferral)) {
            const updatedDeferrals = newDeferrals.map((def) => {
                if (def.deferralTypeCode === deferral.deferralType) {
                    return deferral;
                }
                return def;
            });

            if (deferral.isPrimaryAndActive) {
                dispatch(updateActiveDeferral(deferral));
            }

            dispatch(setParticipantDeferrals(updatedDeferrals));
            dispatch(setChangedDeferralsArray(updatedDeferrals));

            if (isAutoIncrease) {
                dispatch(resetDeferralAutoIncrease(deferral));
            } else {
                dispatch(resetDeferralFutureDated(deferral));
            }
        } else {
            dispatch(resetDeferralAutoIncrease(null));
            dispatch(resetDeferralFutureDated(null));
        }
    };
};

/**
 * If there are deferrals that have the same enrollmentCodeType along with a different pcmAmtCode
 * Then, we want to put a flag in order to show errors deferral specific.
 * HSA is excluded from this rule.
 * @param {} deferrals
 */
export const addIsMixedDeferralFlagToEachDeferral = (deferrals) => {
    function isSamepctAmtCode(element, index, array) {
        // Return true for the first element
        if (index === 0) {
            return true;
        } else {
            // Compare the value of the previous element
            return element.pctAmtCode !== array[index - 1].pctAmtCode;
        }
    }
    const groupByEnrollmentGroupCode = deferrals.reduce((groups, deferral) => {
        const group = groups[deferral.config.enrollmentGroupCode] || [];
        //HSA is excluded from the STANDARD enrollment group.
        //PPT should be able to make changes for HSA contribution in LIAT, even it has mismatched amount type.
        deferral.isMixedDeferral = deferral.deferralTypeCode === "HSA" ? false : undefined;
        //Even though, HSA is has enrollmentGroupCode of "STANDARD", we will not add it in the groupCode.
        if (deferral.deferralTypeCode !== "HSA") {
            group.push(deferral);
        }
        groups[deferral.config.enrollmentGroupCode] = group;
        return groups;
    }, {});

    //Then, within the group, determine which pctAmtCode doesn't match so that we can show error for that group.
    for (const key in groupByEnrollmentGroupCode) {
        let hasMismatchedCode = false;
        if (
            groupByEnrollmentGroupCode[key].length > 1 &&
            groupByEnrollmentGroupCode.hasOwnProperty.call(key)
        ) {
            hasMismatchedCode = groupByEnrollmentGroupCode[key].every(isSamepctAmtCode);
        }

        for (let i = 0; i < groupByEnrollmentGroupCode[key].length; i++) {
            //HSA is excluded, even though enrollmentGroupType is STANDARD.
            if (
                hasMismatchedCode &&
                groupByEnrollmentGroupCode[key][i].deferralTypeCode !== "HSA"
            ) {
                groupByEnrollmentGroupCode[key][i].isMixedDeferral = true;
            } else {
                groupByEnrollmentGroupCode[key][i].isMixedDeferral = false;
            }
        }
    }
};
