import {
    SET_APPLICATION_SETTINGS_PROJECTION_SETTINGS,
    UPDATE_PROJECTION_SETTINGS
} from "./applicationSettingsTypes";
/**
 * ACTIONS FOUND IN THIS FILE
 * @getApplicationSettings
 * @setApplicationSettingsProjectionSettings
 */

export const getApplicationSettings = (data) => {
    const headOfHouseHold = data.users.find((user) => user.headOfHousehold === true);
    const primaryPlan = headOfHouseHold.plans.find((plan) => plan.primary === true);
    return (dispatch) => {
        dispatch(setApplicationSettingsProjectionSettings(primaryPlan, headOfHouseHold));
    };
};

export const setApplicationSettingsProjectionSettings = (primaryPlan, headOfHouseHold) => {
    const payload = {
        confidence: primaryPlan.retirementOption.confidence,
        includeSocialSecurity: primaryPlan.retirementOption.inclSocialSec,
        rateOfInflation: Number(primaryPlan.irsLimits.rateOfInflation) / 100,
        term: headOfHouseHold.incomeGoalTerm
    };
    return {
        type: SET_APPLICATION_SETTINGS_PROJECTION_SETTINGS,
        payload
    };
};

export const updateProjectionSettings = (props) => {
    return {
        type: UPDATE_PROJECTION_SETTINGS,
        payload: props
    };
};
