import React, { useState, memo, useCallback } from "react";

import { navigateFE } from "core-ui/client/react/core/services/adviceService.js";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { isArrayEmpty } from "gw-shared-components";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

import { dispatchEventBus, setCurrentFault } from "../../actions/shared/sharedActionCreators";
import EventBusEvents from "../../events/eventBusEvents";
import { getPAENavigationFault } from "../../utils/paeUtils";

const DEFAULT_ARR = [];

const MAX_DROPDOWN_SHORTLIST = 5;
const MORE = "More...";
const LESS = "Less...";
const MY_TOTAL_RETIREMENT = "myTotalRetirement";

const ListItems = memo(({ menuItems = DEFAULT_ARR }) => {
    const dispatch = useDispatch();
    const paeFault = getPAENavigationFault();

    const handleClick = ({ key, paePreventNav, groupId, url }) => {
        if (paePreventNav) {
            dispatch(setCurrentFault(paeFault));
        } else if (groupId) {
            navigateFE(groupId);
        } else {
            window.dispatchEvent(
                new CustomEvent("open-sso-investment-modal", {
                    detail: url
                })
            );
            dispatch(dispatchEventBus(EventBusEvents.I_WANT_TO.LINK_CLICKED + "." + key));
            dispatchAmplitude({
                eventType: AMPLITUDE_EVENTS.SELECT_LINK,
                selection: EventBusEvents.I_WANT_TO.LINK_CLICKED + "." + key
            });
        }
    };

    const handleClickSecondary = useCallback(
        (event) => {
            const target = event.target;
            let eventBusName = EventBusEvents.I_WANT_TO.LINK_CLICKED + "." + target.dataset.testid;
            if (target.dataset.testid === MY_TOTAL_RETIREMENT) {
                eventBusName = EventBusEvents.I_WANT_TO.LINK_WANT_TO_ENROLL;
            }

            dispatch(dispatchEventBus(eventBusName));
            dispatchAmplitude({
                eventType: AMPLITUDE_EVENTS.SELECT_LINK,
                selection: eventBusName
            });
        },
        [dispatch]
    );

    return menuItems.map(
        ({
            key,
            url,
            label,
            openNewTab,
            className = "",
            enableSSOModal,
            groupId,
            paePreventNav = false
        }) => {
            if (enableSSOModal || groupId || paePreventNav) {
                return (
                    <li key={key} className={className}>
                        <button
                            type="button"
                            className="text-decoration-none text-left"
                            onClick={() =>
                                handleClick({
                                    key,
                                    paePreventNav,
                                    groupId,
                                    url
                                })
                            }
                            data-testid={key}
                        >
                            {label}
                        </button>
                    </li>
                );
            } else {
                return (
                    <li key={key} className={className}>
                        <a
                            onClick={handleClickSecondary}
                            className="text-decoration-none"
                            href={url}
                            target={openNewTab ? "_blank" : "_self"}
                            data-testid={key}
                            rel="noreferrer"
                        >
                            {label}
                        </a>
                    </li>
                );
            }
        }
    );
});

const ListItemsContainer = ({ menuItems = DEFAULT_ARR }) => {
    const dispatch = useDispatch();
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const {
        iWantTo: { expandCollapseButtonLabel }
    } = selectTranslations("rivdTodayView");

    const handleClick = useCallback(() => {
        const value = dropDownOpen ? "less" : "more";
        const dispatchSelection = `${EventBusEvents.I_WANT_TO.LINK_CLICKED}.${value}`;
        dispatch(dispatchEventBus(dispatchSelection));
        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_LINK,
            selection: dispatchSelection
        });
        setDropDownOpen(!dropDownOpen);
    }, [dispatch, setDropDownOpen, dropDownOpen]);

    if (isArrayEmpty(menuItems)) return null;

    if (menuItems.length <= MAX_DROPDOWN_SHORTLIST) {
        return (
            <ul className="list-unstyled dropdown">
                <ListItems menuItems={menuItems} />
            </ul>
        );
    }

    const getItems = () => {
        return menuItems.map((item, index) => {
            if (index >= MAX_DROPDOWN_SHORTLIST) {
                return dropDownOpen
                    ? { ...item, className: "show-element" }
                    : { ...item, className: "hide-element" };
            }
            return item;
        });
    };
    return (
        <ul
            className="list-unstyled dropdown"
            id="want-to-list-drop-down"
            data-testid="list-items-container"
        >
            <ListItems menuItems={getItems()} />
            <li className="text-decoration-none" key="more-key">
                <button
                    type="button"
                    aria-controls="want-to-list-drop-down"
                    aria-expanded={dropDownOpen}
                    onClick={handleClick}
                    data-testid="dropdownopen-id"
                    aria-label={expandCollapseButtonLabel}
                >
                    {dropDownOpen ? LESS : MORE}
                </button>
            </li>
        </ul>
    );
};
ListItemsContainer.propTypes = {
    menuItems: PropTypes.array
};
ListItems.propTypes = {
    menuItems: PropTypes.array
};
ListItems.displayName = "ListItemsContainer";

export default memo(ListItemsContainer, isEqual);
