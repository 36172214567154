import { atom } from "jotai";

const redwoodAtom = atom({
    pcapScriptLoaded: false,
    pcapClass: {},
    pcapInitComponents: false
});
const samlTokenErrorAtom = atom({ error: false, message: "", code: "" });

const csrfAtom = atom("");

const isRetailAtom = atom(false);

const homePageEligibilityAtom = atom(false);

const showWidgetsAtom = atom(false);

const hasRsgServicesAtom = atom(false);

const eligibilityTypeAtom = atom("");

export {
    csrfAtom,
    eligibilityTypeAtom,
    hasRsgServicesAtom,
    homePageEligibilityAtom,
    isRetailAtom,
    redwoodAtom,
    samlTokenErrorAtom,
    showWidgetsAtom
};
