import { each as _each } from "lodash";

import PlanMatchRuleCriteriaDetail from "../models/PlanMatchRuleCritDetail";
import PlanMatchRuleRate from "../models/PlanMatchRuleRate";

/**
 *
 * @class common.factory.MatchTierFactory
 * @memberOf common.factory
 * @date 01/01/2015
 * @copyright 2015 Empower Retirement
 * @type Class
 */

const MatchTierFactory = {
    /**
     * Creates a generic request object that's typically the same for app server API endpoints.
     */
    create: function (criteria) {
        const tiers = [];
        _each(criteria, function (matchRuleCriterion) {
            const rates = matchRuleCriterion.planMatchRuleRates; // array
            const criteriaDetails = matchRuleCriterion.planMatchRuleCritDetls; // array
            let tierBasedOn;
            if (rates.length) {
                tierBasedOn = rates[0].tierBasedOn;
            }
            const matchTierConfig = new MatchTierFactory.MatchTierConfig(
                matchRuleCriterion.seqnbr,
                matchRuleCriterion.matchCalculatedOn,
                matchRuleCriterion.tierCalcLevel,
                tierBasedOn
            );

            rates.sort(MatchTierFactory.pmrMatchRateTierComparitor);
            rates.forEach(function (rate, idx) {
                matchTierConfig.tiers[idx] = new PlanMatchRuleRate({
                    tierBasedOn: rate.tierBasedOn,
                    matchPct: rate.matchPct,
                    lowThreshold: rate.lowThreshold,
                    highThreshold: rate.highThreshold,
                    matchPctStartDate: rate.matchPctStartDate,
                    matchPctStopDate: rate.matchPctStopDate
                });
            });

            criteriaDetails.forEach(function (critDetail, idx) {
                critDetail.basisFreq = critDetail.frequency;
                matchTierConfig.detailSet[idx] = new PlanMatchRuleCriteriaDetail(critDetail);
            });

            tiers.push(matchTierConfig);
        });

        return tiers;
    },
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    //    helper functions
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    /**
     * Comparitor function for sorting match tiers...
     *
     * @param tierA
     * @param tierB
     * @returns {number}
     */
    pmrMatchRateTierComparitor: function (tierA, tierB) {
        if (tierA.lowThreshold !== null) {
            return tierA.lowThreshold - tierB.lowThreshold;
        } else if (!isNaN(Date.parse(tierA.matchPctStartDate))) {
            const dA = new Date(tierA.matchPctStartDate),
                dB = new Date(tierB.matchPctStartDate);
            return dA - dB;
        } else {
            return 0;
        }
    },

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    //    Data Object Constructors
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    /**
     * Constructor for MatchTierConfig object. Constructs an
     * object representation of a match rule match-tier configuration...
     *
     * @param {string} calculationBasis . Basis for calculation (e.g., comp, contribution ... )
     * @param {string} tierCalcLevel . CUMULATIVE, INDEPENDENT, or DEPENDENT
     * @param {string} tierCalcBasis . One of a set of values designating nature of the tier...
     * @constructor
     */
    MatchTierConfig: function (seqnbr, calculationBasis, tierCalcLevel, tierCalcBasis) {
        this.sequenceNo = seqnbr;
        this.matchCalculatedOn = calculationBasis;
        this.tierCalcLevel = tierCalcLevel;
        this.tiersBasedOn = tierCalcBasis;
        this.tiers = [];
        this.detailSet = [];
    }
};

export default MatchTierFactory;
