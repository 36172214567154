import axios from "axios";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import { ObjectUtil } from "gw-shared-components";

import RequestFactory from "../factories/RequestFactory";
import { getUrl } from "../utils/urlUtils";

const logger = ExternalLogger.getInstance("ProjectionFactorsService");

export const getProjectionFactors = (config) => {
    const getProjectionFactorsResult = function (result) {
        if (
            ObjectUtil.isUndefinedOrNull(result) ||
            ObjectUtil.isUndefinedOrNull(result.data) ||
            ObjectUtil.isUndefinedOrNull(result.data.data)
        ) {
            logger.error("getProjectionFactorsResult( Bad response data. )");
            throw new Error("There is no response data.");
        } else {
            logger.info("getProjectionFactorsResult()");
            return result.data.data;
        }
    };

    /**
     * Service fault handler and promise rejection.
     * @param fault
     */
    const getProjectionFactorsFault = function (fault) {
        logger.error("getProjectionFactorsFault( fault = {0} )", [fault.message]);
        throw fault;
    };

    const url = getUrl("getRetireeProjectionFactors");
    const method = RequestFactory.HTTP_METHOD.GET;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const request = RequestFactory.create(url, config, method, contentType);
    delete request.headers.samlToken;

    return axios(request).then(getProjectionFactorsResult, getProjectionFactorsFault);
};
