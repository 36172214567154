import { useEffect } from "react";

import { useAtomValue } from "jotai";

import { setCurrentFault } from "../../../../actions/shared/sharedActionCreators";
import { samlTokenErrorAtom } from "../../../../atoms/atoms";
import { useDispatch } from "../../../../hooks/use-redux-actions";

//TODO(EOC): Look to remove all this logic and just handle error response via the query

const useSamlServiceError = () => {
    const dispatch = useDispatch();
    const samlServiceResponse = useAtomValue(samlTokenErrorAtom);

    useEffect(() => {
        // if there is a samlToken error, display the messaging to the user
        if (samlServiceResponse.error) {
            const payload = { ...samlServiceResponse };
            dispatch(setCurrentFault(payload));
        }
    }, [dispatch, samlServiceResponse]);

    return samlServiceResponse;
};

export default useSamlServiceError;
