const ACCESSIBILITY = {
    HOW_DO_I_COMPARE: {
        "my-peers-title": "my-peers-title",
        "target-my-peers": "target-my-peers-percentage-value",
        "contribution-my-peers": "contribution-my-peers-percentage-value",
        "balance-my-peers": "balance-my-peers-value",
        "top-peers-title": "top-peers-title",
        "target-top-peers": "target-top-peers-percentage-value",
        "contribution-top-peers": "contribution-top-peers-percentage-value",
        "balance-top-peers": "balance-top-peers-value"
    },
    MY_GOAL: {
        "me-title": "me-title",
        "target-me-percentage-value": "target-me-percentage-value",
        "contribution-me": "contribution-me",
        "balance-me": "balance-me",
        "my-goal-percentage-value": "my-goal-percentage-value"
    },
    INCOME_PARTS: {
        "income-part-item-list-my-savings": "income-part-item-list-my-savings",
        "income-part-item-list-employer-contributions":
            "income-part-item-list-employer-contributions",
        "income-part-item-list-social-security": "income-part-item-list-social-security",
        "income-part-item-list-other-assets": "income-part-item-list-other-assets",
        "income-part-item-list-income-gap": "income-part-item-list-income-gap"
    },
    ESTIMATED_MONTHLY_INCOME: {
        "projected-income-label": "projected-income-label",
        "projected-income-monthly-value": "projected-income-monthly-value"
    },
    HEALTHCARECOSTS_TEXT_ID: "healthcare-costs-retirement-text-id",
    HEALTHCARECOSTS_CHART_ID: "healthcare-costs-retirement-chart-id",
    HOW_LONG_WILL_MY_MONEY_LAST_HIGHCHART_INFO_ID: "how-long-will-my-money-last-highchart-id",
    MESSAGE_UPDATE_ID: "message-update-id"
};

const ariaControlsPeersTopPeers = Object.values(ACCESSIBILITY.HOW_DO_I_COMPARE).join(" ");
const ariaControlsIncomeParts = Object.values(ACCESSIBILITY.INCOME_PARTS).join(" ");
const ariaControlsEstimatedMonthlyIncome = Object.values(
    ACCESSIBILITY.ESTIMATED_MONTHLY_INCOME
).join(" ");

const ariaControlsMe = Object.values(ACCESSIBILITY.MY_GOAL).join(" ");
const ariaControlsSlidersHomePage = `${ariaControlsEstimatedMonthlyIncome} ${ACCESSIBILITY.HEALTHCARECOSTS_CHART_ID} ${ACCESSIBILITY.HEALTHCARECOSTS_TEXT_ID} ${ACCESSIBILITY.HOW_LONG_WILL_MY_MONEY_LAST_HIGHCHART_INFO_ID} ${ariaControlsMe} ${ariaControlsIncomeParts} ${ACCESSIBILITY.MESSAGE_UPDATE_ID}`;

export { ACCESSIBILITY, ariaControlsPeersTopPeers, ariaControlsSlidersHomePage };
