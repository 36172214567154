import React from "react";

import PropTypes from "prop-types";

const WarningMessage = ({ message, classNames }) => {
    return (
        <div
            className={`alert alert-warning center-warning ${
                classNames ? classNames.join(" ") : ""
            }`}
            data-testid="warning-message"
        >
            <p data-testid="warn-msg">{message}</p>
        </div>
    );
};

WarningMessage.propTypes = {
    message: PropTypes.string.isRequired,
    classNames: PropTypes.array
};

export default WarningMessage;
