import { isUndefinedOrNull } from "gw-shared-components";

import Constants from "../constants/Constants";
import ReferenceData from "../constants/ReferenceData";

export const selectHasSpouse = (state) => {
    const { indvidualId, investorId, personId } = state.spouse;
    return !!indvidualId || !!investorId || !!personId;
};

/**
 * Returns boolean indicating if particiapnt type is user and certain features and details can be accessed
 * @returns {boolean}
 */
export const isTypeParticipant = (participant) => {
    if (isUndefinedOrNull(participant)) {
        return false;
    }
    return participant.type === ReferenceData.USER_TYPE_PARTICIPANT;
};

/**
 * Returns boolean indicating if particiapnt value units is Percent instead Amount (dollar)
 * @returns {boolean}
 */
export const isValueUnitsPercent = (participant) =>
    participant.incomeGoal.valueUnits === Constants.PCT;
