import { useMutation } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import { csrfAtom } from "../../atoms/atoms";
import getPerformanceHistories from "../../services/getPerformanceHistories";

interface PerformanceHistories {
    spData?: {
        accountSummaries: { dateRangeBalanceValueChange: number; userAccountId: number }[];
    };
}

interface PerformanceHistoriesError {
    code: string;
}

interface GetPerformanceHistoriesParams {
    endDate: string;
    interval?: string;
    requireBenchmark?: boolean;
    startDate: string;
    userAccountIds?: string[];
}

const usePerformanceHistories = (
    onSuccess?: (data: PerformanceHistories) => void,
    onError?: (error: PerformanceHistoriesError) => void
) => {
    const csrf = useAtomValue(csrfAtom);
    const mutationFn = async ({
        startDate,
        endDate,
        userAccountIds,
        interval,
        requireBenchmark
    }: GetPerformanceHistoriesParams) => {
        const { data } = await getPerformanceHistories(
            csrf,
            startDate,
            endDate,
            userAccountIds,
            interval,
            requireBenchmark
        );
        return data;
    };

    return useMutation<
        PerformanceHistories,
        PerformanceHistoriesError,
        GetPerformanceHistoriesParams
    >({
        mutationFn,
        onSuccess,
        onError
    });
};

export default usePerformanceHistories;
