import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { setShowNqModal } from "../../../actions/enrollment/enrollmentAction";
import { setNqInfo } from "../../../actions/paycheck/paycheckActions";
import ReferenceData from "../../../constants/ReferenceData";

export const NqModal = () => {
    const dispatch = useDispatch();
    const translations = selectTranslations("nqModal");
    const enrollment = useSelector((state) => state.enrollment);
    const plans = useSelector((state) => state.paycheck.nqInfo);

    const participant = useSelector((state) => state.participant);

    const getPlanLink = function (plan) {
        return StringUtil.supplant(ReferenceData.URL_NQ_ENROLLMENT_PLAN, { planId: plan.id });
    };

    const enroll = (plan) => {
        const evt = new CustomEvent("updateEnrollActiveGroupAccount", {
            bubbles: true,
            cancelable: true,
            detail: { groupId: plan.id, individualId: participant.individualId }
        });
        document.dispatchEvent(evt);
        window.location.href = getPlanLink(plan);
        dispatch(setShowNqModal(false));
        dispatch(setNqInfo([]));
    };

    /**
     * Enrollment modal was open before tour started; using tour.inProgress to trigger start of tour
     */
    const handleNqCancel = () => {
        dispatch(setShowNqModal(false));

        const closeModalEvent = new CustomEvent("modal-close");
        window.dispatchEvent(closeModalEvent);
    };

    const renderNqInfoRows = (plans) => {
        return plans.map((plan) => {
            return (
                <div className="row" key={`${plan.name}-${plan.id}`}>
                    <div className="col-xs-12 col-sm-8 col-md-8 text-left">
                        <h6
                            className="modal-sub-title"
                            id="modal-sub-title"
                            data-testid="nq-planName"
                        >
                            {plan.name}
                            <br />
                            <span className="nq-until" data-testid="nq-until">
                                {translations?.enrollUntil} {plan.nqEnrollmentInfo?.effDate}
                            </span>
                        </h6>
                    </div>

                    <div className="col-xs-12 col-sm-4 col-md-4 text-right">
                        <button
                            className="submit-btn btn btn-primary"
                            type="button"
                            onClick={() => enroll(plan)}
                        >
                            {translations["enroll-now"]}
                        </button>
                    </div>
                </div>
            );
        });
    };

    return (
        enrollment.showNqModal && (
            <Modal dialogClassName="rivd-nq-modal" show={enrollment.showNqModal}>
                <div className="nq-modal" data-testid="nq-modal">
                    <h3 className="modal-title" id="modal-title" data-testid="nq-title">
                        {translations?.title}
                    </h3>
                    <Modal.Header className="modal-header" data-testid="nq-content">
                        {translations?.content}
                    </Modal.Header>
                    <Modal.Body className="modal-body" id="modal-body">
                        {renderNqInfoRows(plans)}
                    </Modal.Body>
                    <Modal.Footer className="modal-footer">
                        <div className="footer-content">
                            <div className="btns">
                                <button
                                    className="cancel-btn btn btn-link"
                                    type="button"
                                    onClick={handleNqCancel}
                                >
                                    {translations["close-without-enrolling"]}
                                </button>
                            </div>
                        </div>
                    </Modal.Footer>
                </div>
            </Modal>
        )
    );
};
