import React from "react";

import ButtonLabel from "./ButtonLabel";

interface AdvisoryButton {
    buttonClass: string;
    handleClick: () => void;
    label: string;
    programName: string;
    testId: string;
}

const AdvisoryButton = ({
    buttonClass,
    handleClick,
    testId,
    label,
    programName
}: AdvisoryButton) => {
    return (
        <button className={buttonClass} onClick={handleClick} data-testid={testId}>
            <ButtonLabel label={label} professionalManagementProgramName={programName} />
        </button>
    );
};

export default AdvisoryButton;
