const PROJECTED_INCOME = "projectedIncome";

//  all the states from the angular application which is referenced in the init() GA event as *route* name
export const HASH_ROUTES = {
    "#/projected-income/retirement-income": PROJECTED_INCOME + ".retirementIncome",
    "#/projected-income/social-security": PROJECTED_INCOME + ".socialSecurity",
    "#/projected-income/other-assets": PROJECTED_INCOME + ".otherAssets",
    "#/projected-income/income-gap": PROJECTED_INCOME + ".incomeGap",
    "#/projected-income/healthcare-costs": PROJECTED_INCOME + ".healthCareCosts",
    "#/projected-income/how-do-i-compare": PROJECTED_INCOME + ".howDoICompare",
    "#/projected-income/plan-balances": PROJECTED_INCOME + ".planBalances",
    "#/projected-income/plan-savings": PROJECTED_INCOME + ".planSavings"
};
