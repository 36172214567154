import {
    SET_DEFERRAL_SETUP_CONFIG,
    SET_DEFERRAL_SUMMARY_TOTAL,
    DEFERRAL_SLIDER_CHANGE,
    SET_ADDITIONAL_DEFERRAL_LIST,
    CHANGED_AUTO_INCREASE_DEFERRALS,
    CHANGED_FUTURE_DATED_DEFERRALS,
    SET_CHANGED_DEFERRALS_ARRAY,
    SET_DEFERRAL_SUBMISSION_WARNING,
    SET_DEFERRAL_WARNING,
    RESET_HAS_DEFERRAL_CHANGED,
    SET_DEFERRAL_SUMMARY_TOTAL_BASE_AND_VAR,
    SET_HAS_VARIABLE_DEFERRAL,
    RESET_AUTO_INCREASE_DEFERRALS,
    RESET_FUTURE_DATED_DEFERRALS
} from "../../actions/deferrals/deferralActionTypes";

const initalState = {
    additionalDeferralList: [],
    changedAutoIncreaseDeferrals: [],
    changedDeferralsFull: [],
    changedDeferralsGrandfathered: [],
    changedDeferralsModel: [],
    changedFutureDatedDeferrals: [],
    csorEstimatedCount: 0,
    deferralSubmissionWarnings: [],
    deferralSummaryMax: 25,
    deferralSummaryTotal: 0,
    deferralTotalBasePct: 0,
    deferralTotalVarPct: 0,
    deferralWarnings: [],
    hasAllDollarDeferrals: false,
    hasAutoIncreaseChanges: false,
    hasFutureDatedChanges: false,
    hasMixedDeferrals: false,
    hasVariableDeferral: false,
    hasVariableDollarDeferral: false,
    nonZeroVariableDeferralCount: 0,
    hasDeferralChanged: false
};

const deferralDoesNotExistInArray = (array, deferral) => {
    if (array.length === 0) return true;
    return !array.some((def) => {
        return def.config.displayName === deferral.config.displayName;
    });
};

const removeDeferralIfReset = (array, deferral) => {
    if (array.length === 0) return array;
    const index = array.findIndex((def) => {
        return (
            def.config.displayName === deferral.config.displayName &&
            deferral.value === deferral.priorValue
        );
    });

    if (index > -1) {
        array.splice(index, 1);
    }

    return array;
};

const removeAutoIncreaseFutureDatedDeferral = (array, deferral) => {
    if (array.length === 0) return array;
    const index = array.findIndex((def) => {
        return (
            def.config.displayName === deferral.config.displayName &&
            def.deferralTypeCode === deferral.deferralTypeCode
        );
    });

    if (index > -1) {
        array.splice(index, 1);
    }

    return array;
};

const hasAnyAutoIncreaseChanges = (deferralList) => {
    return deferralList.some(
        (def) => def.autoIncrease && def.value >= def.autoIncrease.stopAtValue
    );
};

const filterAutoIncreaseChanged = (deferralList) => {
    return deferralList.filter(
        (def) => def.autoIncrease && def.value >= def.autoIncrease.stopAtValue
    );
};

export default (state = initalState, action) => {
    if (!action) return state;
    const { type, payload } = action;
    switch (type) {
        case SET_DEFERRAL_SETUP_CONFIG:
            return { ...state, ...payload };
        case SET_DEFERRAL_SUMMARY_TOTAL:
            return {
                ...state,
                deferralSummaryTotal: payload.deferralSummaryTotal,
                deferralSummaryMax: payload.deferralSummaryMax
            };
        case SET_DEFERRAL_SUMMARY_TOTAL_BASE_AND_VAR:
            return {
                ...state,
                deferralTotalBasePct: payload.deferralTotalBasePct,
                deferralTotalVarPct: payload.deferralTotalVarPct
            };
        case DEFERRAL_SLIDER_CHANGE:
            return { ...state, ...payload, hasDeferralChanged: true };

        case SET_ADDITIONAL_DEFERRAL_LIST:
            return { ...state, additionalDeferralList: payload };

        case CHANGED_AUTO_INCREASE_DEFERRALS: {
            let changedAutoIncreaseDeferrals = filterAutoIncreaseChanged(payload.updatedDeferrals);
            const hasChanges =
                (changedAutoIncreaseDeferrals.length > 0 &&
                    payload.deferralToUpdate.value >=
                        payload.deferralToUpdate.autoIncrease.stopAtValue) ||
                payload.deferralToUpdate.value === 0;

            const hasAnyChangesInList = hasAnyAutoIncreaseChanges(payload.updatedDeferrals);

            if (
                deferralDoesNotExistInArray(
                    changedAutoIncreaseDeferrals,
                    payload.deferralToUpdate
                ) &&
                hasChanges
            ) {
                changedAutoIncreaseDeferrals.push(payload.deferralToUpdate);
            } else {
                changedAutoIncreaseDeferrals = removeDeferralIfReset(
                    changedAutoIncreaseDeferrals,
                    payload.deferralToUpdate
                );
            }
            return {
                ...state,
                changedAutoIncreaseDeferrals,
                hasAutoIncreaseChanges: hasChanges || hasAnyChangesInList
            };
        }
        case CHANGED_FUTURE_DATED_DEFERRALS: {
            let changedFutureDatedDeferrals = [...state.changedFutureDatedDeferrals];
            if (deferralDoesNotExistInArray(changedFutureDatedDeferrals, payload)) {
                changedFutureDatedDeferrals.push(payload);
            } else {
                changedFutureDatedDeferrals = removeDeferralIfReset(
                    changedFutureDatedDeferrals,
                    payload
                );
            }
            return {
                ...state,
                changedFutureDatedDeferrals,
                hasFutureDatedChanges: changedFutureDatedDeferrals.length > 0
            };
        }
        case SET_CHANGED_DEFERRALS_ARRAY:
            return {
                ...state,
                changedDeferralsFull: payload.changedDeferralsFull,
                changedDeferralsModel: payload.changedDeferralsModel,
                changedDeferralsGrandfathered: payload.changedDeferralsGrandfathered
            };
        case SET_DEFERRAL_SUBMISSION_WARNING:
            return {
                ...state,
                deferralSubmissionWarnings: payload
            };
        case SET_DEFERRAL_WARNING:
            return {
                ...state,
                deferralWarnings: payload
            };
        case RESET_HAS_DEFERRAL_CHANGED:
            return { ...state, hasDeferralChanged: false };
        case SET_HAS_VARIABLE_DEFERRAL:
            return { ...state, hasVariableDeferral: payload };
        case RESET_AUTO_INCREASE_DEFERRALS: {
            let autoIncreaseDeferrals = [...state.changedAutoIncreaseDeferrals];

            autoIncreaseDeferrals = payload
                ? removeAutoIncreaseFutureDatedDeferral(autoIncreaseDeferrals, payload)
                : [];

            return {
                ...state,
                changedAutoIncreaseDeferrals: autoIncreaseDeferrals,
                hasAutoIncreaseChanges: false
            };
        }
        case RESET_FUTURE_DATED_DEFERRALS: {
            let futureDatedDeferrals = [...state.changedFutureDatedDeferrals];

            futureDatedDeferrals = payload
                ? removeAutoIncreaseFutureDatedDeferral(futureDatedDeferrals, payload)
                : [];

            return {
                ...state,
                changedFutureDatedDeferrals: futureDatedDeferrals,
                hasFutureDatedChanges: false
            };
        }
        default:
            return state;
    }
};
