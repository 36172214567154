import Logger from "../utils/Logger";

const LoggerFactory = {
    getInstance: function (context) {
        if (context === null || context === "undefined" || context === "") {
            context = "Unknown Context";
        }

        return new Logger(context);
    }
};

export default LoggerFactory;
