"use strict";
/**
 * @name app.common.model.domain.ExternalAsset
 * @date 1/11/2018
 * @copyright 2018 Empower Retirement
 * @type Class
 * @description
 *
 *  This creates the other asset object for migrated users
 */
class ExternalAsset {
    constructor(asset) {
        this.id = null;
        this.retirementAccountId = null;
        this.investorId = null;
        this.accountTypeCode = null;
        this.accountProviderName = null;
        this.accountTypeName = null;
        this.accountTypeSubtype = null;
        this.accountBalance = null;
        this.currentContribution = null;
        this.investment = null;
        this.legacyAssets = [];
        this.acctAggrId = null;
        this.acctAggrStatus = "INACTIVE";
        this.externalAccountType = null;
        this.externalSourceName = "";
        this.configInstruction = "";
        this.connectionId = "";
        this.connectionSyncStatusCode = "";
        this.isUserActionRequired = false;
        this.dataSource = null;
        this.investorAccountId = null;
        this.estimatedAnnualAmount = null;
        this.lastModifiedOn = null;
        this.name = "";
        this.assetType = "externalAsset";
        this.monthlyIncomeEST = 0;
        this.plAdviseCode = "";
        this.assetAllocationAdvisableFlag = false;
        this.savingsRateAdvisableFlag = false;
        this.owners = [];
        this.isJointOwner = false;
        this.isPrimary = false;
        this.equityMix = 0;

        if (asset) {
            this.id = asset.id;
            this.investorId = asset.investorId;
            this.accountTypeCode = asset.accountTypeCode;
            this.accountProviderName = asset.accountProviderName;
            this.accountTypeName = asset.accountTypeName;
            this.accountTypeSubtype = asset.accountTypeSubtype ? asset.accountTypeSubtype : "";
            this.accountBalance = asset.accountBalance;
            this.currentContribution = asset.currentContribution;
            this.investment = asset.investment;
            this.legacyAssets = asset.legacyAssets;
            this.retirementAccountId = asset.retirementAccountId;
            this.plAdviseCode = asset.plAdviseCode;
            this.assetAllocationAdvisableFlag = asset.assetAllocationAdvisableFlag;
            this.savingsRateAdvisableFlag = asset.savingsRateAdvisableFlag;
            this.lastUpdatedTime = asset.lastUpdatedTime;
            this.acctAggrId = asset.acctAggrId;
            this.acctAggrStatus = asset.acctAggrStatus;
            this.externalAccountType = asset.externalAccountType;
            this.externalSourceName = asset.externalSourceName;
            this.configInstruction = asset.configInstruction;
            this.connectionId = asset.connectionId;
            this.connectionSyncStatusCode = asset.connectionSyncStatusCode;
            this.isUserActionRequired = asset.isUserActionRequired;
            this.dataSource = asset.dataSource;
            this.investorAccountId = asset.investorId;
            this.lastModifiedOn = asset.lastUpdatedTime;
            this.estimatedAnnualAmount = asset.accountBalance;
            this.name = asset.accountProviderName;
            this.assetType =
                asset.assetType && asset.assetType > "" ? asset.assetType : "externalAsset";
            this.monthlyIncomeEST =
                asset.monthlyIncomeEST && asset.monthlyIncomeEST > 0 ? asset.monthlyIncomeEST : 0;
            this.owners = asset.owners;
            this.isJointOwner = asset.isJointOwner;
            this.isPrimary = asset.isPrimary;
            this.equityMix = asset.equityMix;
        }
    }
}

export default ExternalAsset;
