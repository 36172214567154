import {
    formatTimingPrefix,
    DiagnosticsConstants,
    determineLoginFlow,
    LoginFlowEvents
} from "./common";
import { getLiatAppStartTime } from "./getEvents";
import { sendMetricEventMFA, sendMetricEvent } from "./sendEvents";

const measurementName = "Widgets";

const timingPrefix = formatTimingPrefix();

const sentryWidgetsRenderMetrics = () => {
    const renderWidgets = new Date().getTime();

    const renderWidgetsStartTimeExists = sessionStorage.getItem(
        `${timingPrefix}${DiagnosticsConstants.RENDER_WIDGETS}`
    );

    /**
     * With this logic we will check to see if we have set the paycheck metrics in the current session.
     * If we have not then proceed with getting and setting the data.
     */
    if (!renderWidgetsStartTimeExists) {
        const liatAppStartTime = getLiatAppStartTime();

        const renderWidgetskDelta = renderWidgets - Number(liatAppStartTime);

        sessionStorage.setItem(
            timingPrefix + DiagnosticsConstants.RENDER_WIDGETS,
            String(renderWidgets)
        );
        sessionStorage.setItem(
            timingPrefix + DiagnosticsConstants.RENDER_WIDGETS + "Delta",
            String(renderWidgetskDelta)
        );

        const { loginLastHash, flowName, startHash, endTime } = determineLoginFlow();
        // If true, we are in the MFA Sign in login flow
        if (flowName === LoginFlowEvents.MFA_LOGIN) {
            // Measure from when the user clicked mfa sign in to when paycheck renders
            const timeToSkeletonFromVerify = renderWidgets - endTime;

            sendMetricEventMFA({
                measurementName,
                startHash,
                loginLastHash,
                endTime,
                startTime: renderWidgets,
                name: DiagnosticsConstants.MFA.TIME_TO_FULL_LOAD,
                timeToLoad: timeToSkeletonFromVerify
            });
            // If true, we are in the DF login flow
        } else if (flowName === LoginFlowEvents.DF_LOGIN) {
            // Measure from when the user clicked login to when they can see the LIS score
            const toToWidgetsFromClick = renderWidgets - endTime;

            sendMetricEvent({
                measurementName,
                startHash,
                loginLastHash,
                endTime,
                startTime: renderWidgets,
                name: DiagnosticsConstants.DF.TIME_TO_FULL_LOAD,
                timeToLoad: toToWidgetsFromClick
            });
            // If true, we are in the SSO login flow
        } else if (flowName === LoginFlowEvents.SSO_LOGIN) {
            const timeToWidgetsFromSSO = renderWidgets - endTime;

            sendMetricEvent({
                measurementName,
                startHash,
                loginLastHash,
                endTime,
                startTime: renderWidgets,
                name: DiagnosticsConstants.SSO.TIME_TO_FULL_LOAD,
                timeToLoad: timeToWidgetsFromSSO
            });
        } // Collect the registration flow data points
        else if (loginLastHash && DiagnosticsConstants[loginLastHash]) {
            sendMetricEvent({
                name: `${DiagnosticsConstants.TIME_TO_FULL_LOAD}_${DiagnosticsConstants[loginLastHash]}`,
                timeToLoad: renderWidgetskDelta,
                measurementName,
                startTime: renderWidgets,
                endTime: Number(liatAppStartTime),
                loginLastHash
            });
        }

        // We will keep this as a common metric independent of any login flow
        sendMetricEvent({
            name: DiagnosticsConstants.TIME_TO_FULL_LOAD,
            timeToLoad: renderWidgetskDelta,
            measurementName,
            startTime: renderWidgets,
            endTime: Number(liatAppStartTime),
            loginLastHash
        });
    }
};

export default sentryWidgetsRenderMetrics;
