/**
 *
 * @name app.module.projectedIncome.model.constants.IncomeStreams
 * @date 12/19/2014
 * @copyright 2015 Empower Retirement
 * @description
 *
 *  maps deferral config income streams to salary components
 */
"use strict";

const IncomeStreams = {
    Salary: "base",
    "Bonus or Commission": "variable",
    UNKNOWN: "base",
    Bonus: "variable",
    VARIABLE: "Bonus or Commission",
    BASE: "Salary"
};

export default IncomeStreams;
