import ApplicationSettings from "./ApplicationSettings";
import User from "./User";
/**
 *
 * @class models.InitData
 * @date 1/26/2015
 * @copyright 2015 Empower Retirement
 * @type Class
 *
 */
class InitData {
    constructor(config) {
        this.data = null;
        this.applicationSettings = new ApplicationSettings();
        this.users = [];
        this.user = new User();
        this.spouse = null;
        this.otherAssetsRefData = null;
        this.irsLimitsRefData = null;

        if (config) {
            this.data = config.data;
            this.applicationSettings = config.applicationSettings;
            this.users = config.users;
            this.user = config.user;
            this.spouse = config.spouse;
            this.otherAssetsRefData = config.otherAssetsRefData;
            this.irsLimitsRefData = config.irsLimitsRefData;
        }
    }
}

export default InitData;
