import { useQuery } from "@tanstack/react-query";
import { getGroupMenuService } from "core-ui/client/react/core/services/menuService";

import { QUERY_KEYS } from "../constants";

type Args = {
    groupId: string;
    isTaxableAcct: boolean;
};

const DEFAULT_MENU_NAVIGATION_DATA = [];

const useNavigationMenu = ({ groupId, isTaxableAcct }: Args) => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_NAVIGATION_MENUS, groupId],
        queryFn: async () => {
            const response = await getGroupMenuService(groupId);
            return response.data;
        },
        initialData: DEFAULT_MENU_NAVIGATION_DATA, // Needs to return an empty array even if it's disabled or "I want to" is hidden when it shouldn't
        staleTime: 0, // If staleTime > 0 the query keeps returning the initialData, even if it's enabled
        enabled: !!groupId && !isTaxableAcct
    });
};

export default useNavigationMenu;
