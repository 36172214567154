import { TopNavLinks } from "../../../types/common";

type Args = {
    topNavLinks: TopNavLinks;
};

const getIsInvestmentCheckupInTopNavLinks = ({ topNavLinks }: Args) => {
    const planningMenuItem = topNavLinks.find((item) => item.displayText === "cxPlanning");

    if (!planningMenuItem || !planningMenuItem.subMenu) {
        return false;
    }

    return planningMenuItem.subMenu.some((item) => item.displayText === "cxCheckup");
};

export default getIsInvestmentCheckupInTopNavLinks;
