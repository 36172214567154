/**
 *
 * @memberOf common.model.constants
 * @date 2015
 * @copyright 2015 Empower Retirement
 * @description This module contains enrollment group code display orders
 *
 */
"use strict";

const EnrollmentGroupCodesSort = function () {
    const codes = {
        STANDARD: 0,
        "CATCH-UP": 1,
        AFTERTAX: 2,
        BONUS: 3,
        OTHER: 4
    };

    return codes;
};

export default new EnrollmentGroupCodesSort();
