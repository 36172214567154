import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import { isUndefinedOrNull } from "gw-shared-components";

/**
 * Initializes a modal stack to manage the priority order for select modals
 * Exposes 2 functions: addModal, and init
 */

let stack = [];
let modalsPopped = 0;
let stackInitialized = false;
const modalsToShow = 2;
const listenerName = "modal-close";
const logger = ExternalLogger.getInstance("modalStackService");

/**
 * Triggers top modal in stack
 */
const init = () => {
    const topModal = getTopModal();

    if (topModal && modalsPopped !== modalsToShow) {
        const name = topModal.name;

        logger.log(`${name} modal triggered`);
        topModal.action();
    }

    if (!stackInitialized) {
        stackInitialized = true;
    }
};

/**
 * Creates a modal object from arguments and adds to stack
 * @param {string} name - Modal name
 * @param {number} priority - Priority of modal in stack
 * @param {function} action - Function that opens modal
 */
const addModal = (name, priority, action) => {
    if (isUndefinedOrNull(name) || !typeof name === "string") {
        logger.error(
            `addModal error: argument 'name' must be type 'string'. Got: '${typeof name}'`
        );
        return;
    }

    if (isUndefinedOrNull(priority) || !typeof priority === "number") {
        logger.error(
            `addModal error: argument 'priority' must be type 'number'. Got: '${typeof priority}'`
        );
        return;
    }

    if (isUndefinedOrNull(action) || !typeof action === "function") {
        logger.error(
            `addModal error: argument 'action' must be type 'function'. Got: '${typeof action}'`
        );
        return;
    }

    const modalObject = {
        name,
        priority,
        action
    };

    stack.push(modalObject);

    // If stack length is greater than 1, sort the stack
    if (stack.length > 1) {
        stack.sort((a, b) => {
            return b.priority - a.priority;
        });
    }
};

const popStack = () => {
    stack.pop();
    modalsPopped++;
};

const getTopModal = () => {
    return stack[stack.length - 1];
};

const setListener = () => {
    window.addEventListener(listenerName, () => {
        if (getTopModal()) {
            popStack();
            init();
        }
    });
};

const filterStack = () => {
    stack = stack.filter((modal) => {
        return modal.name.toLowerCase() !== "pla";
    });
};

const getLength = () => {
    return stack.length;
};

const getStackInitialized = () => {
    return stackInitialized;
};

export const modalStack = {
    addModal,
    setListener,
    init,
    filterStack,
    getLength,
    getStackInitialized
};
