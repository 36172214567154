/**
 * @name app.common.model.domain.Plan
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @description
 *
 *        Creates a defined object structure for the Plan object type.
 */

import IRSLimits from "./IRSLimits";
import ModelPortfolio from "./ModelPortfolio";
import PlanLimits from "./PlanLimits";
import PlanRules from "./PlanRules";
import SalaryInfo from "./SalaryInfo";

const Plan = function (config) {
    this.id = "";
    this.name = "";
    this.isPrimary = true;
    this.equityPercent = 0;
    this.payFrequency = 0;
    this.terminated = false;
    this.irsCode = "";
    this.balances = [];
    this.investmentHoldings = [];
    this.deferrals = [];
    this.newDeferrals = [];
    this.deferralInfo = {};
    this.IRSLimits = new IRSLimits();
    this.assetAllocationEligibility = null;
    this.planRules = new PlanRules();
    this.discretionaryContribution = null;
    this.limits = new PlanLimits();
    this.salary = new SalaryInfo();
    this.seniorityDate = null;
    this.companyMatch = null; //old tables for apple, etc
    this.employerMatch = null; //using new payroll system
    this.profitSharing = null;
    this.modelPortfolio = new ModelPortfolio();
    this.maximizer = null;
    this.retirementAge = 0;
    this.yearsOfService = null;
    this.trsFlexAccountInfo = null;
    this.nqEnrollmentInfo = null;
    this.deferralRestrictions = null;
    this.hsaDetails = null;
    this.higherCULimitInd = false;

    if (config) {
        this.id = config.id;
        this.name = config.name;
        this.isPrimary = config.isPrimary;
        this.equityPercent = config.equityPercent;
        this.payFrequency = config.payFrequency;
        this.terminated = config.terminated;
        this.irsCode = config.irsCode;
        this.balances = config.balances;
        this.investmentHoldings = config.investmentHoldings;
        this.deferrals = config.deferrals;
        this.newDeferrals = config.newDeferrals;
        this.deferralInfo = config.deferralInfo ? config.deferralInfo : {};
        this.IRSLimits = config.IRSLimits;
        this.assetAllocationEligibility = config.assetAllocationEligibility;
        this.planRules = config.planRules;
        this.discretionaryContribution = config.discretionaryContribution;
        this.limits = config.limits;
        this.salary = config.salary;
        this.seniorityDate = config.seniorityDate;
        this.companyMatch = config.companyMatch;
        this.employerMatch = config.employerMatch;
        this.profitSharing = config.profitSharing;
        this.modelPortfolio = config.modelPortfolio;
        this.maximizer = config.maximizer;
        this.retirementAge = config.retirementAge;
        this.yearsOfService = config.yearsOfService;
        this.trsFlexAccountInfo = config.trsFlexAccountInfo;
        this.nqEnrollmentInfo = config.nqEnrollmentInfo;
        this.deferralRestrictions = config.deferralRestrictions;
        this.hsaDetails = config.hsaDetails;
        this.higherCULimitInd = config.higherCULimitInd;
    }
};

export default Plan;
