import { executeGet } from "../utils/apiUtils";
import { getUrl } from "../utils/urlUtils.js";

const getInitData = async () => {
    const url = getUrl("getInitData");

    const response = await executeGet(url, "initializeDataFault");
    return response.data;
};

export default getInitData;
