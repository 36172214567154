import { isArrayEmpty, isNull, isUndefinedOrNull } from "gw-shared-components";
import { each as _each } from "lodash";

import DeferralConstants from "../../constants/DeferralConstants";
import TrsSdsvCodes from "../../constants/TrsSdsvCodes";

/**
 * @description determine if participant has an hsa plan and return that plan
 * @returns {Object|null}
 */
export const hasHsaPlan = (plans) => {
    if (isUndefinedOrNull(plans) || isArrayEmpty(plans)) return null;
    let hsaPlan = null;
    plans.forEach((plan) => {
        if (isHSAPlan(plan)) {
            hsaPlan = plan;
        }
    });

    return hsaPlan;
};

/**
 * @description determine if plan is an hsa
 * @returns {boolean}
 */
export const isHSAPlan = function (plan) {
    return (
        plan.trsFlexAccountInfo &&
        TrsSdsvCodes.HSA_OPTUM.indexOf(plan.trsFlexAccountInfo.sdsvCode) > -1
    );
};

export const hasHsaDeferralInPlan = (plans, deferrals) => {
    const hsaPlan = hasHsaPlan(plans);
    let hasHsaDeferral = false;

    if (!isNull(hsaPlan)) {
        _each(deferrals, (deferral) => {
            if (deferral.deferralTypeCode === DeferralConstants.typeCodes.HSA) {
                hasHsaDeferral = true;
            }
        });
    }

    return hasHsaDeferral;
};
