import {
    formatTimingPrefix,
    DiagnosticsConstants,
    determineLoginFlow,
    LoginFlowEvents
} from "./common";
import { getLiatAppStartTime } from "./getEvents";
import { sendMetricEventMFA, sendMetricEvent } from "./sendEvents";

const measurementName = "Skeleton";

const timingPrefix = formatTimingPrefix();

const sentrySkeletonVisibleMetrics = () => {
    const renderSkeleton = new Date().getTime();

    const renderSkeletonStartTimeExists = sessionStorage.getItem(
        `${timingPrefix}${DiagnosticsConstants.RENDER_SKELETON}`
    );

    /**
     * With this logic we will check to see if we have set the paycheck metrics in the current session.
     * If we have not then proceed with getting and setting the data.
     */
    if (!renderSkeletonStartTimeExists) {
        const liatAppStartTime = getLiatAppStartTime();

        const renderSkeletonDelta = renderSkeleton - Number(liatAppStartTime);

        sessionStorage.setItem(
            timingPrefix + DiagnosticsConstants.RENDER_SKELETON,
            String(renderSkeleton)
        );
        sessionStorage.setItem(
            timingPrefix + DiagnosticsConstants.RENDER_SKELETON + "Delta",
            String(renderSkeletonDelta)
        );

        const { loginLastHash, flowName, startHash, endTime } = determineLoginFlow();
        // If true, we are in the MFA Sign in login flow
        if (flowName === LoginFlowEvents.MFA_LOGIN) {
            // Measure from when the user clicked mfa sign in to when paycheck renders
            const timeToSkeletonFromVerify = renderSkeleton - endTime;

            sendMetricEventMFA({
                measurementName,
                startHash,
                loginLastHash,
                endTime,
                startTime: renderSkeleton,
                name: DiagnosticsConstants.MFA.TIME_TO_SKELETON_VISIBLE,
                timeToLoad: timeToSkeletonFromVerify
            });
            // If true, we are in the DF login flow
        } else if (flowName === LoginFlowEvents.DF_LOGIN) {
            // Measure from when the user clicked login to when they can see the LIS score
            const timeToSkeletonFromClick = renderSkeleton - endTime;

            sendMetricEvent({
                measurementName,
                startHash,
                loginLastHash,
                endTime,
                startTime: renderSkeleton,
                name: DiagnosticsConstants.DF.TIME_TO_SKELETON_VISIBLE,
                timeToLoad: timeToSkeletonFromClick
            });
            // If true, we are in the SSO login flow
        } else if (flowName === LoginFlowEvents.SSO_LOGIN) {
            const timeToSkeletonFromSSO = renderSkeleton - endTime;

            sendMetricEvent({
                measurementName,
                startHash,
                loginLastHash,
                endTime,
                startTime: renderSkeleton,
                name: DiagnosticsConstants.SSO.TIME_TO_SKELETON_VISIBLE,
                timeToLoad: timeToSkeletonFromSSO
            });
        } // Collect the registration flow data points
        else if (loginLastHash && DiagnosticsConstants[loginLastHash]) {
            sendMetricEvent({
                name: `${DiagnosticsConstants.TIME_TO_SKELETON_VISIBLE}_${DiagnosticsConstants[loginLastHash]}`,
                timeToLoad: renderSkeletonDelta,
                measurementName,
                startTime: renderSkeleton,
                endTime: Number(liatAppStartTime),
                loginLastHash
            });
        }

        // We will keep this as a common metric independent of any login flow
        sendMetricEvent({
            name: DiagnosticsConstants.TIME_TO_SKELETON_VISIBLE,
            timeToLoad: renderSkeletonDelta,
            measurementName,
            startTime: renderSkeleton,
            endTime: Number(liatAppStartTime),
            loginLastHash
        });
    }
};

export default sentrySkeletonVisibleMetrics;
