"use strict";
/**
 * @name app.common.model.domain.ProjectionSettings
 * @date 1/26/2015
 * @copyright 2015 Empower Retirement
 * @type Class
 * @description
 * Creates a defined object structure for the ProjectionSettings object type.
 */

class ProjectionSettings {
    constructor(config) {
        this.confidence = null;
        this.includeSocialSecurity = null;
        this.rateOfInflation = null;
        this.term = 12;

        if (config) {
            this.confidence = config.confidence;
            this.includeSocialSecurity = config.includeSocialSecurity;
            this.rateOfInflation = config.rateOfInflation;
            this.term = config.term;
        }
    }
}

export default ProjectionSettings;
