import { useQuery } from "@tanstack/react-query";

import { getFinancialEngineForecast } from "../../services/financialEngineService";
import { QUERY_KEYS } from "../constants";

const useFinancialEngineForecast = (isEnrolled: boolean, showForecast: boolean) => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_FE_DATA, isEnrolled],
        queryFn: () => getFinancialEngineForecast(isEnrolled),
        enabled: showForecast
    });
};

export default useFinancialEngineForecast;
