import { ObjectUtil } from "gw-shared-components";

// Check paycheck nqInfo
export const getEligiblePlans = (plans = []) => {
    // Eligible if eligible = true and participant hasn't already enrolled (enrolledDate = null in this case)
    return plans.filter(
        (plan) =>
            !ObjectUtil.isUndefinedOrNull(plan.nqEnrollmentInfo) &&
            plan.nqEnrollmentInfo?.eligible &&
            ObjectUtil.isUndefinedOrNull(plan.nqEnrollmentInfo.enrolledDate)
    );
};
