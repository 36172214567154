/**
 *  Footer for Best Next Steps; This needs to be a separate component placed in RetirementSavingsView
 *  When sizing minimizes to iphone8, this footer display below all features, such as the slider, and invesments
 */

import React from "react";

import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { useSelector, useDispatch } from "react-redux";

import { setDeferralRules } from "../../actions/deferrals/deferralActions";
import {
    getShowTradeoffs,
    showReviewButton,
    setShowReviewModal
} from "../../actions/reviewReset/reviewResetActions";
import { setCurrentFault, dispatchEventBus } from "../../actions/shared/sharedActionCreators";
import {
    shouldReplaceReviewChangesWithEquityMixPage,
    launchEquityMix
} from "../../business/retirementIncome";
import EventBusEvents from "../../events/eventBusEvents";
import { useIsEmulator } from "../../hooks/useEmulatorType";
import { resetSliderValues } from "../../middleware/reviewChangesMiddleware";
import { getPAEActionFault } from "../../utils/paeUtils";

const ReviewReset = () => {
    const bestNextStepTranslations = selectTranslations("bestNextStep");
    const retirementIncomeTranslations = selectTranslations("retirementIncome");

    const originalRetireAge = useSelector((state) => state.baseline.retirementAge);
    const originalEquity = useSelector((state) => state.baseline.investments.equityMix);
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const individualOptions = useSelector((state) => state.investments.individualOptions);
    const activeDeferral = useSelector((state) => state.activeDeferral);
    const indivId = useSelector((state) => state.participant.individualId);
    const currentRetireAge = useSelector((state) => state.participant.retirementAge);
    const deferralSetupConfig = useSelector((state) => state.deferralSetupConfig);
    const deferrals = useSelector((state) => state.participant.deferrals);
    const planId = primaryPlan.id;
    const currentEquityMix = useSelector((state) => state.investments.equityMix);
    const equityMixWarnings = useSelector((state) => state.investments.equityMixWarnings);
    const deferralWarnings = useSelector((state) => state.deferralSetupConfig.deferralWarnings);
    const deferralSubmissionWarnings = useSelector(
        (state) => state.deferralSetupConfig.deferralSubmissionWarnings
    );
    const isPAEUser = useIsEmulator();

    const dispatch = useDispatch();

    const resetClick = (event) => {
        event.preventDefault();
        dispatch(resetSliderValues());

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: "reset_changes_button"
        });
    };

    const reviewClick = (event) => {
        event.preventDefault();
        dispatch(dispatchEventBus(EventBusEvents.LIAT.REVIEW_CHANGED_CLICK));

        dispatchAmplitude({
            eventType: AMPLITUDE_EVENTS.SELECT_BUTTON,
            selection: EventBusEvents.LIAT.REVIEW_CHANGED_CLICK
        });

        if (
            shouldReplaceReviewChangesWithEquityMixPage(
                deferrals,
                originalRetireAge,
                currentRetireAge,
                individualOptions,
                originalEquity,
                currentEquityMix,
                equityMixWarnings,
                deferralWarnings,
                deferralSubmissionWarnings
            )
        ) {
            if (isPAEUser) {
                dispatch(resetSliderValues());
                dispatch(setCurrentFault(getPAEActionFault()));
                return;
            }
            launchEquityMix(indivId, planId, currentEquityMix, individualOptions);
        } else {
            dispatch(setDeferralRules(deferrals, primaryPlan, deferralSetupConfig, activeDeferral));

            dispatch(setShowReviewModal(true));
        }
    };

    const reset = retirementIncomeTranslations.reset;
    const reviewChanges = bestNextStepTranslations.reviewChangesBtnLbl;
    const prevStateShowTradeOff = useSelector((state) => state.reviewChanges.showTradeOff);
    const prevStateShowReviewBtn = useSelector((state) => state.reviewChanges.showReviewButton);
    getShowTradeoffs(
        prevStateShowTradeOff,
        deferrals,
        originalRetireAge,
        currentRetireAge,
        individualOptions,
        originalEquity,
        currentEquityMix,
        equityMixWarnings,
        dispatch
    );
    const showTradeoffs = useSelector((state) => state.reviewChanges.showTradeOff);
    showReviewButton(
        prevStateShowReviewBtn,
        deferrals,
        originalRetireAge,
        currentRetireAge,
        individualOptions,
        originalEquity,
        currentEquityMix,
        equityMixWarnings,
        dispatch
    );
    const showReviewButtonFlag = useSelector((state) => state.reviewChanges.showReviewButton);

    return (
        showTradeoffs && (
            <div id="reviewReset" className="pending-changes-container" data-testid="review-reset">
                <button
                    onClick={resetClick}
                    id="rivd-reset-btn"
                    className="btn btn-link rivd-changes-btn"
                    data-testid="reset-id"
                >
                    {reset}
                </button>
                {showReviewButtonFlag && (
                    <button
                        className="btn rivd-changes-btn col-sm-8"
                        id="rivd-review-btn"
                        data-bs-toggle="modal"
                        onClick={reviewClick}
                        data-testid="review-changes-id"
                    >
                        {reviewChanges}
                    </button>
                )}
            </div>
        )
    );
};

export default ReviewReset;
