"use strict";
/**
 * @name app.infrastructure.domainModel.createSsInfo
 * @date 6/7/201
 * @copyright 2021 Empower Retirement
 * @description
 * Definition of the ssInfo domain model object - only for integrated users.
 */

class IntegratedSocialSecurityInfo {
    constructor(config) {
        this.pcapPersonId = "";
        this.socialSecurityIncome = null;
        this.ssBenefitByStartAge = [];

        if (config) {
            this.pcapPersonId = config.pcapPersonId;
            this.socialSecurityIncome = config.socialSecurityIncome;
            this.ssBenefitByStartAge = config.ssBenefitByStartAge ? config.ssBenefitByStartAge : [];
        }
    }
}

export default IntegratedSocialSecurityInfo;
