import React from "react";

import PropTypes from "prop-types";

/**
 * Dynamically renders a select tag based on spouse DOB and desired retirement age
 * @param {number} defaultAge - Selected age based on DOB
 * @param {number} minAge - The minimum age possible based on current age
 * @param {number} maxAge - The maximum age possible based on current age
 * @param {number} age - The spouse/partner's current age
 * @param {number} currentYear - The current year
 * @param {function} changeAge - Handler function that passes the selected age
 */
const AgeSelect = ({ defaultAge, minAge, maxAge, age, currentYear, changeAge, id }) => {
    const ages = [];
    const renderOptions = () => {
        if (defaultAge) {
            for (let i = minAge; i <= maxAge; i++) {
                ages.push(i);
            }
            return ages.map((selectAge) => {
                return (
                    <option
                        key={selectAge}
                    >{`${selectAge} (${currentYear + selectAge - age})`}</option>
                );
            });
        } else {
            return <option />;
        }
    };

    return (
        <select
            value={`${defaultAge} (${currentYear + defaultAge - age})`}
            onChange={(e) => changeAge(e.target.value)}
            className="btn dropdown-toggle"
            id={id}
            data-testid="age-select"
            required
        >
            {renderOptions()}
        </select>
    );
};

AgeSelect.propTypes = {
    defaultAge: PropTypes.number,
    minAge: PropTypes.number,
    maxAge: PropTypes.number,
    age: PropTypes.number,
    currentYear: PropTypes.number,
    changeAge: PropTypes.func,
    id: PropTypes.string
};

export default AgeSelect;
