import { ObjectUtil } from "gw-shared-components";
import { each as _each } from "lodash";

import { getDeferralSummaryStringDisplay } from "../../business/howDoICompare";

import {
    SET_TOP_PEERS,
    SET_HOW_DO_I_COMPARE_SALARY_GROUP,
    SET_HOW_DO_I_COMPARE_GENDER_GROUP,
    SET_HOW_DO_I_COMPARE_KEY_AND_SCORE,
    SET_HDIC_ME_BALANCE,
    SET_HDIC_ME_CONTRIBUTION_RATE,
    CHANGE_HDIC_AGE_FILTER,
    CHANGE_HDIC_SALARY_FILTER,
    CHANGE_HDIC_GENDER_FILTER,
    SET_HDIC_ERROR,
    HAS_TOP_PEERS_FOCUS,
    SET_HOW_DO_I_COMPARE
} from "./howDoICompareTypes";

/**
 * ACTIONS FOUND IN THIS FILE
 * @setHowDoICompareData
 * @setHDICAgeGroup
 * @setHDICSalaryGroup
 * @setHDICGenderGroup
 * @setHDICCurrentScore
 * @setHDICKeyAndScore
 * @setTopPeers
 * @changeHDICAgeFilter
 * @changeHDICSalaryFilter
 * @changeHDICGenderFilter
 */

const GENDER_GROUP = {
    F: "G0",
    M: "G1",
    U: "G2"
};

const getParticipantGroupByGender = (gender) => {
    const group = gender && GENDER_GROUP[gender] ? GENDER_GROUP[gender] : GENDER_GROUP.U;
    return group;
};

const findGroup = (arr = [], value) => arr.find((group) => value >= group.from && value < group.to);

export const setHowDoICompareData = (payload) => {
    return (dispatch, getState) => {
        const state = getState();
        const particpant = state.participant;
        const primaryPlan = state.primaryPlan;
        const deferralSetupConfig = state.deferralSetupConfig;
        const activeDeferral = state.activeDeferral;
        const { age, salary, gender, plans } = particpant;
        const confidence = state.applicationSettings.projectionSettings.confidence;

        const ageGroup = findGroup(payload?.ageGroups, age);
        const salaryGroup = findGroup(payload?.salaryGroups, salary.base);
        const personGroup = getParticipantGroupByGender(gender);
        const genderGroup = payload?.genderList.find((group) => personGroup === group.id);

        const scoreMapKey = ageGroup.id + salaryGroup.id + genderGroup.id + confidence;
        const score = payload?.scores[scoreMapKey];

        dispatch({
            type: SET_HOW_DO_I_COMPARE,
            payload: {
                ...payload,
                ageGroup,
                salaryGroup,
                genderGroup,
                score,
                scoreMapKey
            }
        });

        dispatch(setHDICContributionRate(primaryPlan, deferralSetupConfig, activeDeferral));
        dispatch(setHDICBalance(plans));
    };
};

export const setHDICAgeGroup = (ageGroups, age) => {
    const payload = findGroup(ageGroups, age);
    return {
        payload
    };
};

export const setHDICGenderGroup = (genderList, gender) => {
    const personGroup = getParticipantGroupByGender(gender);
    const payload = genderList.find((group) => personGroup === group.id);
    return {
        type: SET_HOW_DO_I_COMPARE_GENDER_GROUP,
        payload
    };
};

export const setHDICSalaryGroup = (salaryGroups, salary) => {
    const payload = findGroup(salaryGroups, salary);
    return {
        type: SET_HOW_DO_I_COMPARE_SALARY_GROUP,
        payload
    };
};

export const setHDICKeyAndScore = (
    particpant,
    ageList,
    genderList,
    salaryList,
    confidence,
    scores
) => {
    const ageGroup = findGroup(ageList, particpant.age);
    const salaryGroup = findGroup(salaryList, particpant.salary.total);
    const personGroup = getParticipantGroupByGender(particpant.gender);
    const genderGroup = genderList.find((group) => personGroup === group.id);

    const key = ageGroup.id + salaryGroup.id + genderGroup.id + confidence;
    const score = scores[key];
    return {
        type: SET_HOW_DO_I_COMPARE_KEY_AND_SCORE,
        payload: { key, score }
    };
};

export const setHDICContributionRate = (primaryPlan, deferralSetupConfig, activeDeferral) => {
    const deferralSummaryStringDisplay = getDeferralSummaryStringDisplay(
        primaryPlan,
        deferralSetupConfig,
        activeDeferral
    );

    return {
        type: SET_HDIC_ME_CONTRIBUTION_RATE,
        payload: { contributionRate: deferralSummaryStringDisplay }
    };
};

export const setHDICBalance = (plans) => {
    let totalBalance = 0;
    _each(plans, function (thisPlan) {
        if (!ObjectUtil.isUndefinedOrNull(thisPlan.balances)) {
            _each(thisPlan.balances, (balance) => {
                totalBalance += balance.totalBalance;
            });
        }
    });

    return {
        type: SET_HDIC_ME_BALANCE,
        payload: { balance: totalBalance }
    };
};

export const changeHDICAgeFilter = (payload) => {
    return {
        type: CHANGE_HDIC_AGE_FILTER,
        payload
    };
};

export const changeHDICSalaryFilter = (payload) => {
    return {
        type: CHANGE_HDIC_SALARY_FILTER,
        payload
    };
};

export const changeHDICGenderFilter = (payload) => {
    return {
        type: CHANGE_HDIC_GENDER_FILTER,
        payload
    };
};

/**
 * Sets the "top peers" info on the view model
 */
export const setTopPeers = (payload) => {
    return {
        type: SET_TOP_PEERS,
        payload
    };
};

export const setHDICError = () => {
    return {
        type: SET_HDIC_ERROR,
        payload: {}
    };
};

export const setTopPeersFocus = () => {
    return {
        type: HAS_TOP_PEERS_FOCUS,
        payload: true
    };
};

export const unSetTopPeersFocus = () => {
    return {
        type: HAS_TOP_PEERS_FOCUS,
        payload: false
    };
};
