type LoginAppStartTime = {
    hash: string;
    timestamp: number;
} | null;

const getLoginAppStart = (): LoginAppStartTime => {
    const loginAppStart = sessionStorage.getItem("participant-loginAppStart");

    if (loginAppStart) {
        return JSON.parse(loginAppStart);
    }

    return null;
};

export default getLoginAppStart;
