import React, { memo } from "react";

import { Switch } from "react-router-dom";
import { CompatRoute as Route } from "react-router-dom-v5-compat";

import {
    RETIREMENT_INCOME_ROUTE,
    SOCIAL_SECURITY_ROUTE,
    INCOME_GAP_ROUTE,
    HSA_ROUTE,
    OTHER_ASSETS_ROUTE,
    MAXIMIZER_ROUTE,
    HEALTHCARE_COSTS_ROUTE,
    WHEN_CAN_I_RETIRE_ROUTE,
    HOW_DO_I_COMPARE_ROUTE,
    PRE_RETIREMENT_ROUTE
} from "../../routes";
import LifetimeIncomeScoreCircle from "../components/goal/LifetimeIncomeScoreCircle";
import HealthcareCosts from "../components/healthcareCosts/HealthcareCosts";
import HowDoICompare from "../components/howDoICompare/HowDoICompare";
import IncomeParts from "../components/incomeParts/IncomeParts";
import IntegratedPaycheck from "../components/paycheck/IntegratedPaycheck";
import PreRetireeView from "../components/preRetiree/PreRetireeView";
import WhenCanIRetire from "../components/whenCanIRetire/WhenCanIRetire";

import PaycheckPrimaryControls from "./PaycheckPrimaryControls";

const IntegratedPaycheckContainer = () => {
    return (
        <div className="liat-body">
            <div className="liat-body-section liat-body-section--top col-lg-12 d-flex">
                <div className="monthly-retirement-income-container">
                    <Switch>
                        <Route path={HEALTHCARE_COSTS_ROUTE} render={() => <HealthcareCosts />} />
                        <Route path={HOW_DO_I_COMPARE_ROUTE} render={() => <HowDoICompare />} />
                        <Route path={WHEN_CAN_I_RETIRE_ROUTE} render={() => <WhenCanIRetire />} />
                        <Route path={PRE_RETIREMENT_ROUTE} render={() => <PreRetireeView />} />
                        <Route
                            path={RETIREMENT_INCOME_ROUTE}
                            render={() => <IntegratedPaycheck />}
                        />
                        <Route path={OTHER_ASSETS_ROUTE} render={() => <IntegratedPaycheck />} />
                        <Route path={SOCIAL_SECURITY_ROUTE} render={() => <IntegratedPaycheck />} />
                        <Route path={INCOME_GAP_ROUTE} render={() => <IntegratedPaycheck />} />
                        <Route path={MAXIMIZER_ROUTE} render={() => <IntegratedPaycheck />} />
                        <Route path={HSA_ROUTE} render={() => <IntegratedPaycheck />} />
                    </Switch>
                </div>

                <div className="lifetime-income-score">
                    <LifetimeIncomeScoreCircle />
                </div>
            </div>
            <div className="liat-body-section liat-body-section--middle col-lg-12">
                <IncomeParts />
            </div>
            <div className="liat-body-section liat-body-section--bottom col-lg-12">
                <PaycheckPrimaryControls />
            </div>
        </div>
    );
};

export default memo(IntegratedPaycheckContainer);
