import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import CurrencyFormatter from "../../currencyFormatter/CurrencyFormatter";

import {
    getTradeOffCurrencyPrefix,
    getTradeOffMessage,
    getTradeOffMobileLabel
} from "./tradeoffHelpers";

const renderFutureTodayTradeOffs = (list, isFuture, tradeoffs, term) => {
    return list.map((tradeOff, id) => {
        const divClass = isFuture ? "future-trade-off" : "present-trade-off";
        const belowZeroClass = tradeOff.amount < 0 ? "red" : "medium-grey";
        const strongClass = tradeOff.amount > 0 ? "green" : belowZeroClass;
        return (
            <div key={tradeOff.message}>
                {!(tradeOff.amount > -0.01 && tradeOff.amount < 0.01) ? (
                    <div
                        className={`${divClass} review-changes-amount`}
                        data-testid="review-changes-amount"
                    >
                        <strong
                            id={`rivd-trade-off-amount-${tradeOff.message}-${id}`}
                            className={`rivd-trade-off-amount ${strongClass}`}
                        >
                            <span data-testid="trade-off-amt1">
                                {getTradeOffCurrencyPrefix(tradeOff.amount)}
                            </span>
                            <CurrencyFormatter
                                amount={Math.abs(tradeOff.amount)}
                                data-testid="trade-off-amt2"
                            />
                            &nbsp;
                        </strong>
                        <span className="hidden-xs" data-testid="trade-off-msg">
                            {getTradeOffMessage(tradeOff.message, tradeoffs, term)}
                        </span>
                        <span className="visible-xs-block text-uppercase text-center">
                            {getTradeOffMobileLabel(tradeOff.message, tradeoffs)}
                        </span>
                    </div>
                ) : null}
            </div>
        );
    });
};

const HasTradeOffsView = ({ label, hasTradeOffs, setting }) => {
    const tradeoffs = selectTranslations("tradeoffs");
    const term = useSelector((state) => state.applicationSettings.projectionSettings.term);
    return hasTradeOffs ? (
        <div
            className="list-unstyled gray-check-list rivd-current-trade-off-list"
            data-testid="has-trade-offs-view"
        >
            <strong className="hidden-xs">{label} : </strong>
            {renderFutureTodayTradeOffs(setting, false, tradeoffs, term)}
        </div>
    ) : null;
};

HasTradeOffsView.propTypes = {
    label: PropTypes.string.isRequired,
    hasTradeOffs: PropTypes.bool,
    setting: PropTypes.array
};

export { HasTradeOffsView };
