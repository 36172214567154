/* eslint-disable */
window.globalThis ||= window;

window.baseApiUrl = __baseApiUrl__;
window.authenticationUrl = __authenticationUrl__;
window.styleEnv = __styleEnv__;
window.iframeUrl = __iframeUrl__;
window.applicationVersion = __applicationVersion__;

window.pcapScriptLoaded = false;
window.pcapInitComponents = false;
window.PublicServices = {};
