import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import { csrfAtom } from "../../atoms/atoms";
import getAccountsList from "../../services/getAccountsList";
import { QUERY_KEYS } from "../constants";

const useAccountsList = () => {
    const csrf = useAtomValue(csrfAtom);

    return useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [QUERY_KEYS.GET_ACCOUNTS_2_LIST],
        queryFn: () => getAccountsList(csrf),
        enabled: !!csrf
    });
};

export default useAccountsList;
