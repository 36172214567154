/**
 *
 * @name DeferralChangeProperties
 * @memberOf common.model.constants
 * @date 02/09/2015
 * @copyright 2015 Empower Retirement
 * @description This module encapsulates deferral codes.
 * @property {string} AUTO_INCREASE "autoIncreaseChanges"
 * @property {string} MAXIMIZER "maximizerChanges"
 *
 * These are the properties in the viewModel.deferralSetupConfig object that are used
 * to keep track of deferral changes based on various properties - currently there are (2)
 * autoincrease, and maximizer - note that there are (2) properties one is the name of the property
 * and the other is a boolean indicating whether or not that property is a child property of the
 * deferral config property
 *
 */

"use strict";

export default {
    AUTO_INCREASE: {
        name: "autoIncreaseChanges",
        isConfigProp: false,
        deferralPropName: "autoIncrease"
    },
    FUTURE_DATED: {
        name: "futureDatedChanges",
        isConfigProp: false,
        deferralPropName: "futureDated"
    },
    MAXIMIZER: {
        name: "maximizerChanges",
        isConfigProp: true,
        deferralPropName: "maximizeEligibleInd"
    },
    VALUE: { name: "valueChanges", isConfigProp: false, deferralPropName: "value" }
};
