import { SET_OPEN_NEW_ACCOUNT_LINK } from "../../actions/openNewAccountLink/openNewAccountLinkTypes";

const initialState = {
    data: {},
    isLoaded: false
};

/**
 * The openNewAccountLink is used by the "I want to..." component
 */
export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_OPEN_NEW_ACCOUNT_LINK:
            return { ...state, ...{ data: payload, isLoaded: true } };

        default:
            return state;
    }
};
