import { useQuery } from "@tanstack/react-query";

import { getGroupTxnAccess } from "../../services/sharedService";
import { QUERY_KEYS } from "../constants";

type Args = {
    individualId?: string;
    planId?: string;
    txnCodes: string;
};

const useGroupTxnAccess = ({ individualId, planId, txnCodes }: Args) => {
    return useQuery({
        queryKey: [QUERY_KEYS.GET_GROUP_TXN_ACCESS, individualId, planId, txnCodes],
        queryFn: () => getGroupTxnAccess(individualId, planId, txnCodes),
        enabled: !!individualId && !!planId
    });
};

export default useGroupTxnAccess;
