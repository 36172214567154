import axios from "axios";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { isNil as _isNil } from "lodash";

import { executeGet } from "../utils/apiUtils";
import { getUrl, isLocalhost, hasMocks } from "../utils/urlUtils.js";

const logger = ExternalLogger.getInstance("AccountSummaryService");

export const getAccountSummary = async () => {
    const response = await executeGet(getUrl("getAccountSummary"), "accountSummaryFault");
    if (response.data && response.data.monthlyAccounts) {
        response.data.monthlyAccounts = response.data.monthlyAccounts.map((account) => {
            //fix typo in benefit field
            if (account.definedBenefit && !_isNil(account.definedBenefit.acccruedBenefitAmount)) {
                account.definedBenefit.accruedBenefitAmount =
                    account.definedBenefit.acccruedBenefitAmount;
            }

            return account;
        });
    }
    return response.data;
};

export const getAccountSummaryRefresh = () => {
    return executeGet(getUrl("getAccountSummaryRefresh"), "accountSummaryRefreshFault").then(
        (response) => {
            if (response.data && response.data.monthlyAccounts) {
                response.data.monthlyAccounts = response.data.monthlyAccounts.map((account) => {
                    //fix typo in benefit field
                    if (
                        account.definedBenefit &&
                        !_isNil(account.definedBenefit.acccruedBenefitAmount)
                    ) {
                        account.definedBenefit.accruedBenefitAmount =
                            account.definedBenefit.acccruedBenefitAmount;
                    }

                    return account;
                });
            }

            return response.data;
        }
    );
};

export const isParticipantEnrollEligible = (groupId) => {
    const url = StringUtil.supplant(getUrl("isParticipantEnrollEligible"), { groupId: groupId });

    return executeGet(url, "isParticipantEnrollEligibleFault").then((result) => {
        if (_isNil(result) || _isNil(result.data)) {
            logger.error("isParticipantEnrollEligible ( Bad response data. )");
            throw "There is no response data.";
        } else {
            return result.data;
        }
    });
};

export const getQuickViewAccountData = () => {
    const url = getUrl("getQuickViewAccountData");

    const mock =
        window.location.origin + window.location.pathname + "data/get-quick-view-account-data.json";
    const updatedUrl = isLocalhost && hasMocks() ? mock : url;

    //use axios.get directly because executeGet triggers an undesired popup if the service fails
    return axios.get(updatedUrl).then((result) => {
        if (_isNil(result) || _isNil(result.data)) {
            logger.error("getQuickViewAccountData ( Bad response data. )");
            throw "There is no response data.";
        } else {
            return result.data.data;
        }
    });
};
