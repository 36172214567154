import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { useSelector, shallowEqual } from "react-redux";

import { FilterController } from "./FilterController";

export const FiltersControls = () => {
    const translations = selectTranslations("hdic");
    const { ageGroups, salaryGroups, genderList } = useSelector(
        (state) => ({
            ageGroups: state.howDoICompare.ageGroups,
            salaryGroups: state.howDoICompare.salaryGroups,
            genderList: state.howDoICompare.genderList
        }),
        shallowEqual
    );
    const ageGroup = useSelector((state) => state.howDoICompare.ageGroup);
    const salaryGroup = useSelector((state) => state.howDoICompare.salaryGroup);
    const genderGroup = useSelector((state) => state.howDoICompare.genderGroup);

    return (
        <td className="comparison-controls">
            <form
                action="#replace-with-service"
                method="post"
                className="form-inline ng-pristine ng-valid hidden-xs"
            >
                <FilterController
                    list={ageGroups}
                    name={"ageRange"}
                    defaultValue={ageGroup}
                    title={translations.age}
                />
                <FilterController
                    list={salaryGroups}
                    name={"salaryRange"}
                    defaultValue={salaryGroup}
                    title={translations.salary}
                />
                <FilterController
                    list={genderList}
                    name={"genderRange"}
                    defaultValue={genderGroup}
                    title={translations.gender.title}
                />
            </form>
        </td>
    );
};
