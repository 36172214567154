import { isNil as _isNil } from "lodash";

import { UPDATE_COMPANY_MATCH } from "../actions/companyMatch";
import { setCurrentFault } from "../actions/shared/sharedActionCreators";
import InitDataFactory from "../factories/InitDataFactory";
import { getCompanyMatch } from "../services/companyMatchService";

export const loadCompanyMatch = (salaryOverride) => {
    return (dispatch, getState) => {
        const state = getState();
        const annualSalary = !_isNil(salaryOverride)
            ? salaryOverride
            : state.participant.salary.total;
        return getCompanyMatch(annualSalary)
            .then((response) => {
                const companyMatchResult = InitDataFactory.createCompanyMatch(
                    response.companyMatchRule
                );
                return dispatch({
                    type: UPDATE_COMPANY_MATCH,
                    payload: companyMatchResult
                });
            })
            .catch((fault) => {
                dispatch(setCurrentFault(fault));
                throw fault;
            });
    };
};
