import type { Investments } from "../../../types/common/index";

/**
 * Does the participant require the non-all MTG flow
 * @returns {boolean}
 */
const isNonAllMoneyTypeGroupingsFlow = (
    individualOptions: Investments["individualOptions"]
): boolean => {
    return individualOptions.notAllMoneyTypeGroupingsFlow;
};

export default isNonAllMoneyTypeGroupingsFlow;
