/**
 * Retrieves the value of the "useSentry" item from the session storage.
 * This is a flag for us to toggle between analytics platforms (sentry / datadog).
 * @returns {boolean} The value of the "useSentry" item if it exists, otherwise null.
 */
const getUseSentry = (): boolean => {
    const useSentry = sessionStorage.getItem("useSentry");
    return useSentry === "true";
};

export default getUseSentry;
