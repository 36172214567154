const IrsCodes = {
    PLAN_457: "457",
    PLAN_457_B: "457B",
    "401K": "401(k)",
    "401A": "401(a)",
    "457B": "457(b)",
    457: "457",
    "403B": "403(b)",
    "1165E": "1081.01"
};

export default IrsCodes;
