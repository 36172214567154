import React, { memo } from "react";

import { useQuery } from "@tanstack/react-query";

import IntegratedPaycheckContainer from "../../containers/IntegratedPaycheckContainer";
import { QUERY_KEYS } from "../../queries/constants";
import getShowLiat from "../../services/getShowLiat";
import { LiatShell } from "../loadShells/LoadShells";

interface LiatComponentProps {
    showLoader: boolean;
}

const LiatComponent = ({ showLoader }: LiatComponentProps) => {
    const componentClass = showLoader ? "" : "loaded";

    const { data: showLIAT } = useQuery({
        queryKey: [QUERY_KEYS.ELIGIBLE],
        queryFn: getShowLiat
    });

    if (!showLIAT) {
        return null;
    }

    return (
        <div
            className={`redwood-account-overview-container bg-white gray-border-000 rounded-border`}
        >
            {showLoader && <LiatShell />}
            <div className={`use-fade-in ${componentClass}`}>
                <IntegratedPaycheckContainer />
                <div className="paycheck-tour-target"></div>
            </div>
        </div>
    );
};

export default memo(LiatComponent);
