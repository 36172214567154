const Constants = {
    ERROR: "error",

    DELETED: "Spouse / Partner", // TODO: Pull from styleguide and delete

    PCT: "PCT",
    AMT: "AMT",

    // gender
    GENDER: {
        DEFAULT: "Select",
        ALL: "All",
        F: "F",
        FEMALE: "Female",
        M: "M",
        MALE: "Male",
        U: "U",
        N: "Non-binary"
    },

    INTEGRATED_GENDER_MAP: {
        M: "MALE",
        F: "FEMALE",
        N: "NEUTRAL",
        U: "UNKNOWN"
    },

    INTEGRATED_TYPE_MAP: {
        PCT: "PERCENT",
        AMT: "DOLLAR"
    },

    INTEGRATED_TYPE_CONVERSION: {
        PERCENT: "PCT",
        AMOUNT: "AMT"
    },

    // dates
    DATE: {
        FORMAT: "MM/DD/YYYY",
        MONTHLY: "monthly",
        YEARLY: "yearly"
    },
    DEFAULT_INCOME_GOAL: 75,

    //min screen width size
    SCREEN_WIDTH: {
        PHONE: 480,
        TABLET: 700,
        DESKTOP: 960,
        LARGE_DESKTOP: 1200
    },

    // max screen width size
    MAX_SCREEN_WIDTH: {
        PHONE: 699,
        TABLET: 959,
        DESKTOP: 1199
    },

    TERM: {
        monthly: 12,
        yearly: 1
    },

    OTHER_ASSETS_SELECT_OPTIONS: {
        incomeInRetirement: "iir",
        accountAggregation: "aa",
        linkedAccountBalances: "lab",
        externalAssets: "ea"
    },
    MODAL_WINDOW_SIZE: {
        none: "",
        medium: "medium",
        large: "large"
    },
    ASSET_TYPE: {
        OAB_PLAN: "OAB_PLAN",
        OAB_TRS: "OAB_TRS"
    },

    HAS_RETAIL_ACCOUNT: {
        SITE_NAME: ["empower-retail-pcap", "empower-retail-pcap-ps", "empower-retail-pcap-cash"]
    },

    // Load Priority consts
    I_WANT_TO: {
        NAME: "iWantTo",
        PRIORITY: 1
    },
    COMP_METER: {
        NAME: "completenessMeter",
        PRIORITY: 2
    },
    QUICK_VIEW: {
        NAME: "quickView",
        PRIORITY: 3
    },
    MA_MESSAGING: {
        NAME: "maMessaging",
        PRIORITY: 4
    },
    INSIGHTS: {
        NAME: "insights",
        PRIORITY: 5
    },
    WIDGETS: {
        NAME: "widgets",
        PRIORITY: 6
    },
    LIAT: {
        NAME: "liat",
        PRIORITY: 7
    },
    SIDEBAR: {
        NAME: "sidebar",
        PRIORITY: 8
    },

    QUICKVIEW: {
        ANNUAL_ROR: "Annualized",
        PERIOD_ROR: "Period"
    },

    DEFAULT_RETIREMENT_AGE: 65,
    STYLE_ENV: __styleEnv__
};

export default Constants;
