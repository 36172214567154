/**
 * @name app.common.model.domain.Maximizer
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @description
 *
 *  This creates the maximizer object off of the plan object
 *  this object will only be available if maximizer is enrolled
 */

"use strict";

class Maximizer {
    constructor(config) {
        this.isOngoing = false;
        this.totalMaxDefPercent = 0;
        this.deferralPercent = [];
        this.totalPayPeriods = 0;
        this.remainingPayPeriods = 0;
        this.salary = 0;
        this.enrollType = null;
        this.enrollmentSelection = null;
        this.wasEnrolled = false;
        this.isUnenrolling = false; //used for calculations, not for view

        if (config) {
            this.isOngoing = config.isOngoing;
            this.totalMaxDefPercent = config.totalMaxDefPercent;
            this.deferralPercent = config.deferralPercent;
            this.totalPayPeriods = config.totalPayPeriods;
            this.remainingPayPeriods = config.remainingPayPeriods;
            this.salary = config.salary;
            this.enrollType = config.enrollType;
            this.enrollmentSelection = config.enrollmentSelection;
            this.wasEnrolled = config.wasEnrolled;
            this.isUnenrolling = false;
        }
    }
}

export default Maximizer;
