import _isNumber from "lodash/isNumber";

const SAVING_RECOMMENDATION_MONEY_TYPES = [
    { id: "afterValue", moneyType: "AT" },
    { id: "beforeValue", moneyType: "BT" },
    { id: "rothValue", moneyType: "RT" }
];

type SavingsRecommendation = {
    annualValue: number;
    moneyType: string;
};

const parseValue = (val: string | null) => {
    if (!val) {
        return null;
    }

    const parsed = parseInt(val);

    return !isNaN(parsed) ? parsed : null;
};

const getContributionDetails = (input: string) => {
    const queryParams = new URLSearchParams(input);
    const savingsRecommendation: SavingsRecommendation[] = [];

    const pId = queryParams.get("pId");
    const aId = queryParams.get("aId");
    const salary = queryParams.get("salary");

    SAVING_RECOMMENDATION_MONEY_TYPES.forEach(({ id, moneyType }) => {
        const queryId = queryParams.get(id);
        if (queryId) {
            const annualValue = parseInt(queryId);
            if (annualValue) {
                savingsRecommendation.push({
                    annualValue,
                    moneyType
                });
            }
        }
    });

    return {
        personId: parseValue(pId),
        accountId: parseValue(aId),
        planName: "Apple 401(k) Plan",
        salaryOnPcap: parseValue(salary),
        savingsRecommendation
    };
};

export default getContributionDetails;
