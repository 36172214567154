import DateUtil from "core-ui/client/src/app/DateUtil";
import { isDate as _isDate, isNil as _isNil } from "lodash";

import Constants from "../constants/Constants";
// formats date object as mm/dd/yyyy.
export const formatTimestamp = (timestamp) => {
    if (!_isNil(timestamp)) {
        const dateObject = new Date(timestamp);
        return (
            dateObject.getMonth() + 1 + "/" + dateObject.getDate() + "/" + dateObject.getFullYear()
        );
    }
};

export const removeLeadingZeroFromDate = (date) => {
    if (!date.length) {
        return date;
    }

    const dateArr = date.split("/");
    const month = Number(dateArr[0]);
    const day = Number(dateArr[1]);

    if (month < 10) {
        dateArr[0] = month;
    }
    if (day < 10) {
        dateArr[1] = day;
    }

    return dateArr.join("/");
};

export const getAgeFromDateOfBirth = (dateOfBirth) => {
    const regex = /\d{4}-\d{2}-\d{2}/;
    if (regex.test(dateOfBirth)) {
        dateOfBirth = DateUtil.getValueOf(dateOfBirth);
    }
    if (_isDate(new Date(dateOfBirth))) {
        let newUserAge = new Date().getTime() - dateOfBirth;
        const msPerYear = 3.15569e10;
        newUserAge = newUserAge / msPerYear;
        return Math.floor(newUserAge);
    }
};

/**
 * @description returns a number into a date YYYY-MM-DD string such as 1978-10-18
 * @param {number} timestamp
 * @return {string}
 */
//
export const getDateString = (timestamp) => {
    if (typeof timestamp === "number") {
        const t = new Date(timestamp);
        const dateString =
            t.getFullYear() +
            "-" +
            ("0" + (t.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + t.getDate()).slice(-2);
        return dateString;
    }
    return timestamp;
};

/**
 * Format date to DD-MMM-YYYY, ie. 02-Nov-2020
 * @param {*} date
 */
export const convertToLongFormat = (date) => {
    return DateUtil.getUTCFormatted(date, "DD-MMM-YYYY");
};

/**
 * Convert UTC format to a date string for user and spouse dob's, ie 337500000000
 * @param {*} date
 * @return {string}
 */
export const convertDobFormat = (date, keepLeadZero = false) => {
    /** Convert UTC format to a date string for user and spouse dob's */
    let dob = "";

    if (date) {
        if (typeof date === "number") {
            dob = DateUtil.getUTCFormatted(date, Constants.DATE.FORMAT);
        } else {
            dob = DateUtil.getDateFormatted(Constants.DATE.FORMAT, date);
        }

        if (!keepLeadZero) {
            dob = removeLeadingZeroFromDate(dob);
        }
    }

    return dob;
};

/**
 * Format date to YYYY-MM-DD, ie. input 1/5/1980    output 1980-01-05
 * @param {string} dob
 * @return {string}
 */
export const changeDateFormat = (dob) => {
    const formatted = DateUtil.getDateFormatted(
        "YYYY-MM-DD",
        DateUtil.getDateFormatted("MM/DD/YYYY", dob)
    );
    return formatted;
};
