/**
 * @name app.common.model.domain.AllowableDeferral
 * @description
 * Definition of the allowableDeferral domain model object.
 */

import referenceData from "../constants/ReferenceData";

const AllowableDeferral = function (data) {
    this.pctAmtCode = "PCT";
    this.nextPayrollDate = null;
    this.displayOrder = 0;
    this.deferralTypeCode = null;
    this.displayName = "";
    this.defrlAvailCode = "";
    this.taxStatus = "BEFORE";
    this.incomeStream = "Salary";
    this.minRequiredInd = "N";
    this.allowScheduleInd = "N";
    this.maximizerEligibleInd = "N";
    this.allowAgeCombinedInd = "N";
    this.ageCatchupApplicable = "N";
    this.maxSlider = 25; //Set it to constant
    this.maxPct = 75;
    this.maxAmt = 18000;
    this.minAmt = 0;
    this.minPct = 0;
    this.granularityPct = 0.01;
    this.granularityAmt = 0.01;
    this.configId = "";
    this.maxNextYearAmt = 0;
    this.nextIncrDateOverrideInd = null;
    this.schedIncrMaxOverrideInd = null;
    this.enrollmentGroupCode = "";
    this.sdmtCode = "";
    this.gdmtSeqnbr = "";
    this.catchUp402gInd = "";
    this.ruleGroupCodes = [];
    this.submissionDate = new Date();
    this.plan = null; // watch out for circular reference
    this.mandatoryDeferralInd = "";

    if (data) {
        //These are no longer in the data structure
        //this.default                  = data.default;
        //this.deferralChangeEffDate    = data.deferralChangeEffDate;
        //this.deferralChange457EffDate = data.deferralChange457EffDate;         //this.specialCatchup           = data.specialCatchup;

        this.pctAmtCode = data.pctAmtCode;
        this.nextPayrollDate = data.nextPayRollDate;
        this.displayOrder = data.displayOrder;
        this.deferralTypeCode = data.typeCode;
        this.displayName = data.displayName;
        this.defrlAvailCode = data.defrlAvailCode;
        this.taxStatus = data.taxStatus;
        this.incomeStream = data.incomeStream;
        this.minRequiredInd = data.minRequiredInd;
        this.allowScheduleInd = data.allowScheduleInd;
        this.maximizerEligibleInd = data.maximizeEligibleInd;
        this.allowAgeCombinedInd = data.allowAgeCombinedInd;
        this.ageCatchupApplicable = data.ageCatchupApplicable;
        this.maxSlider = referenceData.MAX_SLIDER_CONTRIBUTION_RATE;
        this.maxPct = data.maxPct;
        this.maxAmt = data.maxAmt;
        this.minAmt = data.minAmt;
        this.minPct = data.minPct;
        this.granularityPct = data.granularityPct;
        this.granularityAmt = data.granularityAmt;
        this.configId = data.configId;
        this.maxNextYearAmt = data.maxNextYearAmt;
        this.nextIncrDateOverrideInd = data.nextIncrDateOverrideInd;
        this.schedIncrMaxOverrideInd = data.schedIncrMaxOverrideInd;
        this.enrollmentGroupCode = data.enrollmentGroupCode;
        this.sdmtCode = data.sdmtCode;
        this.gdmtSeqnbr = data.gdmtSeqnbr;
        this.catchUp402gInd = data.catchUp402gInd || "N";
        this.ruleGroupCodes = [];
        this.submissionDate = data.submissionDate;
        this.plan = data.plan;
        this.mandatoryDeferralInd = data.mandatoryDeferralInd;
    }
};

export default AllowableDeferral;
