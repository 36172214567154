import React, { useState, useRef } from "react";

import {
    handleKeyboardDownEvent,
    keyboardInteractions
} from "core-ui/client/react/core/utils/accessibilityHelpers";
import PropTypes from "prop-types";

import CurrencyFormatter from "../currencyFormatter/CurrencyFormatter";

import PaycheckItemDetailsPopoverOverlay from "./PaycheckItemDetailsPopoverOverlay";

const PaycheckItemDetails = ({
    id,
    label,
    value,
    cssClassName,
    hasTooltip,
    tooltipTitle,
    tooltipContent,
    showPaycheckViewDetails
}) => {
    const [showTooltip, setTooltipState] = useState(false);

    const popoverTarget = useRef(null);

    const tooltipShowHandler = () => {
        setTooltipState(true);
    };

    const tooltipCloseHandler = () => {
        setTooltipState(false);
    };

    const getSquarePosition = () => {
        if (!popoverTarget.current) {
            return;
        }

        const rect = popoverTarget.current.getBoundingClientRect();

        // numbers are calculated to correctly position(center) the tooltip
        return {
            /*
             * dev note!
             * window.scrollY isn't available in IE, use window.pageYOffset instead
             */
            bottom: document.body.offsetHeight - window.pageYOffset - rect.top - 8,
            left: rect.left - 36,
            zIndex: 1000
        };
    };

    const handleKeyboardTooltip = (event) => {
        handleKeyboardDownEvent({
            event,
            keys: keyboardInteractions.escapeKey,
            output: () => tooltipCloseHandler()
        });

        handleKeyboardDownEvent({
            event,
            keys: keyboardInteractions.link,
            output: () => tooltipShowHandler()
        });
    };

    const handleClickTooltip = (event) => {
        event.preventDefault();
        tooltipShowHandler();
        event.stopPropagation();
    };

    const clsFill = cssClassName === "section-income-gap-marker-liat" ? "fa" : "fa-solid";

    return (
        <li
            key={id}
            className="viewDetailsPaycheckItem"
            style={hasTooltip ? { cursor: "pointer" } : {}}
            data-testid="paycheck-item-details"
        >
            <span ref={popoverTarget} className={`${clsFill} fa-square " + ${cssClassName}`} />

            {showPaycheckViewDetails ? (
                <button
                    className="viewDetailsLabel"
                    onBlur={hasTooltip ? tooltipCloseHandler : null}
                    onKeyDown={(event) => (hasTooltip ? handleKeyboardTooltip(event) : null)}
                    onClick={(event) => (hasTooltip ? handleClickTooltip(event) : null)}
                    data-testid="view-details-label"
                >
                    &nbsp;{label}
                </button>
            ) : (
                <div className="viewDetailsLabel" data-testid="view-details-label-no">
                    &nbsp;{label}
                </div>
            )}

            <span className="paycheck-item-val" data-testid="view-details-val">
                {typeof value === "number" ? <CurrencyFormatter amount={value} /> : value}
            </span>

            {hasTooltip && (
                <PaycheckItemDetailsPopoverOverlay
                    show={showTooltip}
                    onHide={tooltipCloseHandler}
                    styles={getSquarePosition()}
                    title={tooltipTitle}
                    content={tooltipContent}
                />
            )}
        </li>
    );
};

PaycheckItemDetails.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cssClassName: PropTypes.string,
    hasTooltip: PropTypes.bool,
    tooltipTitle: PropTypes.string,
    tooltipContent: PropTypes.array,
    showPaycheckViewDetails: PropTypes.bool
};
export default PaycheckItemDetails;
