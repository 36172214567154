import { useQuery } from "@tanstack/react-query";

import { useSelector } from "../../hooks/use-redux-actions";
import { getAccountSummary } from "../../services/accountSummaryService";
import { IAccountSummary } from "../../types/common";
import { QUERY_KEYS } from "../constants";

const useAccountSummary = () => {
    const participant = useSelector((state) => state.participant);
    const primaryPlan = useSelector((state) => state.primaryPlan);

    const individualId = participant.individualId;
    const planId = primaryPlan.id;

    return useQuery<IAccountSummary>({
        queryKey: [QUERY_KEYS.SUMMARY, individualId, planId],
        queryFn: () => getAccountSummary(),
        enabled: individualId !== "" && planId !== ""
    });
};

export default useAccountSummary;
