import React from "react";

import PropTypes from "prop-types";

import GoalIndex from "../../../goal/GoalIndex";
import PeerRadialCharts from "../chartComparisons/PeerRadialCharts";

export const MobileCharts = ({ type, translations, peerPct }) => {
    const renderHeading = (type, translations) => {
        switch (type) {
            case "peers":
                return (
                    <div className="text-uppercase text-bold integrated-hdic-color-tax-types-education">
                        <span>{translations.peers.rivdTitle}</span>
                    </div>
                );
            case "topPeers":
                return (
                    <div className="text-uppercase text-bold integrated-hdic-color-tax-types-education">
                        <span>{translations.topPeers.title}</span>
                    </div>
                );
            case "me":
                return (
                    <div className="text-uppercase text-bold integrated-hdic-color-tax-types-education">
                        <span className="integrated-hdic-me-position-mobile">
                            {translations.me}
                        </span>
                    </div>
                );
            default:
                break;
        }
    };

    const renderChart = (type, peerPct) => {
        switch (type) {
            case "peers":
                return (
                    <PeerRadialCharts
                        percentageOfGoal={peerPct}
                        name={"target-my-peers"}
                        peerClass={"svg-my-peers"}
                    />
                );
            case "topPeers":
                return (
                    <PeerRadialCharts
                        percentageOfGoal={peerPct}
                        name={"target-top-peers"}
                        peerClass={"svg-top-peers"}
                    />
                );
            case "me":
                return (
                    <div className="target-my-goal hdic-goal">
                        <GoalIndex />
                    </div>
                );
            default:
                break;
        }
    };

    return (
        <div id="integrated-hdic" className="chartColumn">
            {renderHeading(type, translations)}
            {renderChart(type, peerPct)}
        </div>
    );
};
MobileCharts.propTypes = {
    type: PropTypes.string,
    translations: PropTypes.object,
    peerPct: PropTypes.any
};
