import React, { memo, useCallback } from "react";

import {
    handleKeyboardDownEvent,
    keyboardInteractions
} from "core-ui/client/react/core/utils/accessibilityHelpers";
import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { isArrayEmpty } from "gw-shared-components";

import { useSelector } from "../../../liat/hooks/use-redux-actions/index";
import { reselectAddDeferralButton } from "../../selectors/reselectSelectors";

const DEFAULT_DEFERRALS: { id: number; name: string }[] = [];

interface AddDeferralButtonProps {
    availableDeferrals?: { id: number; name: string }[];
    handleShowAddDeferralModal: (show: boolean) => void;
}

const AddDeferralButton: React.FC<AddDeferralButtonProps> = ({
    handleShowAddDeferralModal,
    availableDeferrals = DEFAULT_DEFERRALS
}) => {
    const primaryPlan = useSelector((state) => state.primaryPlan);
    const translations = selectTranslations("retirementIncome")?.addContribution;

    const isCachedApplication = useSelector((state) => state.shared.isCachedApplication);

    const { canShowAddNewContributions, displayMulti } = reselectAddDeferralButton(
        primaryPlan,
        isArrayEmpty(availableDeferrals)
    );

    const handleKeyboardDownLearnMore = (event: React.KeyboardEvent<HTMLDivElement>) => {
        handleKeyboardDownEvent({
            event,
            keys: keyboardInteractions.button,
            output: () => handleShowAddDeferralModal(true)
        });
    };

    const handleClick = useCallback(
        () => handleShowAddDeferralModal(true),
        [handleShowAddDeferralModal]
    );

    if (!canShowAddNewContributions || !displayMulti || isCachedApplication) {
        return null;
    }

    return (
        <div
            data-testid="add-deferral-button"
            onClick={handleClick}
            className="addContributionLink"
            onKeyDown={(e) => handleKeyboardDownLearnMore(e)}
            tabIndex={0}
            role="button"
        >
            [+] {translations?.addNew}
        </div>
    );
};

export default memo(AddDeferralButton);
