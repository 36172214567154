import _cloneDeep from "lodash/cloneDeep";
import _each from "lodash/each";
import _find from "lodash/find";
import _isNil from "lodash/isNil";
import _map from "lodash/map";

import ColorConstants from "../constants/ColorConstants";
const { HEALTHY_STYLE, YIELD_STYLE, WARN_STYLE } = ColorConstants.HEALTHCARE_COSTS;

export const getYearlyAmount = (amount, term) => {
    return amount * (12 / term);
};

export const selectHealthViewData = (state) => {
    return state.healthCare.healthViewData;
};

export const selectCurrentProjection = (attainedAge, projections) => {
    if (!_isNil(projections)) {
        return projections.find((projection) => projection.attainedAge === attainedAge);
    }

    return null;
};

export const selectTotalHealthCareCost = (projection) => {
    if (!_isNil(projection)) {
        const { totalHealthCareCost } = projection;

        return totalHealthCareCost;
    }

    return null;
};

export const selectRemainingIncome = (totalHealthCareExpenses, projectedIncome) => {
    const remainingIncome = projectedIncome - totalHealthCareExpenses;
    return remainingIncome >= 0 ? remainingIncome : 0;
};

export const selectMedicalExpensesPercentage = (projectedIncome, totalHealthCareExpenses) => {
    //because of a rounding issue, make sure total is rounded to 2 decimals before calculation
    const total = Math.round(projectedIncome * 100) / 100;
    return Math.round((totalHealthCareExpenses / total) * 100);
};

export const selectChartPercentColor = (medicalExpensesPercentage, healthViewData) => {
    const { yieldThreshold, warnThreshold } = healthViewData;
    // green = good to go
    if (medicalExpensesPercentage < yieldThreshold) {
        return HEALTHY_STYLE;
    } else if (
        medicalExpensesPercentage >= yieldThreshold &&
        medicalExpensesPercentage < warnThreshold
    ) {
        return YIELD_STYLE;
    } else {
        return WARN_STYLE;
    }
};

export const selectChartData = (
    projection,
    chartLegends,
    term,
    remainingIncome,
    chartPercentColor
) => {
    const placeHolder = "M 0,0 L 0,0 A 0,0,0,0,0,0,0 Z";
    const config = {
        first: placeHolder,
        second: placeHolder,
        third: placeHolder,
        fourth: placeHolder,
        fifth: placeHolder,
        sixth: placeHolder,
        seventh: placeHolder,
        eighth: placeHolder,
        remaining: placeHolder
    };

    if (_isNil(projection)) {
        return [config]; // return as array to eliminate runtime errors.
    }

    // Pull both sets of chartconfigs into one
    const combined = projection.premiumChartConfigs.concat(projection.oopChartConfigs);

    // convert data to array
    const chartData = _map(combined, function (item) {
        const legend = _find(chartLegends, { id: item.id });
        return {
            y: getYearlyAmount(item.amount, term),
            color: legend.color,
            toolTip: legend.toolTip,
            name: legend.label
        };
    });

    chartData.push({
        y: remainingIncome,
        color: chartPercentColor,
        toolTip: "Income available after healthcare"
    });

    return chartData;
};

export const selectHealthSurvey = (healthViewData) => {
    return healthViewData.healthSurvey;
};

export const selectHealthConditions = (healthSurvey) => {
    return healthSurvey.pptHealthConditions;
};

export const selectRetirementStates = (healthSurvey) => {
    return healthSurvey.states;
};

export const selectCurrentHealthConditions = (pptHealthConditions, pendingChanges) => {
    const healthConditions = _cloneDeep(pptHealthConditions);
    const pendingHealthConditions = pendingChanges.healthConditions;

    _each(pendingHealthConditions, (condition) => {
        const index = healthConditions.findIndex(
            ({ conditionCode }) => condition.conditionCode === conditionCode
        );
        healthConditions[index].isPreselected = condition.isPreselected;
    });

    return healthConditions;
};

export const selectCurrentRetirementState = (
    retirementStates,
    pendingRetirementStateCode,
    selectedRetirementState
) => {
    const retirementStateCode = pendingRetirementStateCode
        ? pendingRetirementStateCode
        : selectedRetirementState;
    return retirementStates.find(({ stateCode }) => stateCode === retirementStateCode);
};

export const selectCondition = (healthSurvey, conditionCode) => {
    const conditions = selectHealthConditions(healthSurvey);

    return conditions.find((condition) => condition.conditionCode === conditionCode);
};
export const selectPendingHealthConditionChanges = (healthCareState) => {
    return healthCareState.pendingChanges.healthConditions;
};

export const selectPendingHealthConditionChange = (healthCareState, conditionCode) => {
    const changes = selectPendingHealthConditionChanges(healthCareState);

    return changes.find((condition) => condition.conditionCode === conditionCode);
};

const chartConfigsToExpenseItems = (chartLegends, chartConfigs, term) => {
    const expenseItems = [];
    chartConfigs.forEach(function (item) {
        const legend = _find(chartLegends, { id: item.id });
        if (!_isNil(legend)) {
            expenseItems.push({
                color: legend.color,
                toolTip: legend.toolTip,
                label: legend.label,
                amount: getYearlyAmount(item.amount, term)
            });
        }
    });

    return expenseItems;
};

export const selectExpenseList = (healthCare, chartConfigsName, term) => {
    const projection = selectCurrentProjection(
        healthCare.attainedAge,
        healthCare.healthViewData.projections
    );
    if (!_isNil(projection)) {
        return chartConfigsToExpenseItems(
            healthCare.healthViewData.chartLegends,
            projection[chartConfigsName],
            term
        );
    }
    return [];
};
