import ReferenceData from "../../../../constants/ReferenceData";

const ageTickerValues = (retireAge: number) => {
    const ageRange: string[] = [];
    let wcirRetirementAge = retireAge - 3;

    for (let i = 0; i < 7; i++) {
        ageRange.push(wcirRetirementAge.toString());
        wcirRetirementAge++;
    }

    // Edge case, if wcir retirement age equals 50, index 0,1,2 will be blank
    // if wcir retirement age equals 85, index 4,5,6 will be blank
    if (retireAge === ReferenceData.MIN_RETIREMENT_AGE) {
        ageRange[0] = "";
        ageRange[1] = "";
        ageRange[2] = "";
    }

    if (retireAge === ReferenceData.MAX_RETIREMENT_AGE) {
        ageRange[4] = "";
        ageRange[5] = "";
        ageRange[6] = "";
    }

    return ageRange;
};

export default ageTickerValues;
