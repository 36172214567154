import {
    SET_SHOW_NQ_MODAL,
    SET_SHOW_LOCKHEED_MODAL,
    SET_SHOW_LOCKHEED_ENROLL,
    SET_SHOW_LOCKHEED_TERMS_OF_SVC,
    SET_SHOW_LOCKHEED_NO_ELECTIONS,
    SET_LOCKHEED_SETTINGS
} from "../../actions/enrollment/enrollmentTypes";

const initialState = {
    showNqModal: false,
    showLockheedModal: false,
    showLockheedEnroll: false,
    showLockheedTermsOfSvc: false,
    showLockheedNoElections: false,
    isGroupLockheedPlan: false,
    planName: "",
    currentChainingOption: "",
    enrollmentEndDate: "",
    dataLoaded: false
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_SHOW_NQ_MODAL:
            return { ...state, showNqModal: payload };
        case SET_SHOW_LOCKHEED_MODAL:
            return { ...state, showLockheedModal: payload };
        case SET_SHOW_LOCKHEED_ENROLL:
            return { ...state, showLockheedEnroll: payload };
        case SET_SHOW_LOCKHEED_TERMS_OF_SVC:
            return { ...state, showLockheedTermsOfSvc: payload };
        case SET_SHOW_LOCKHEED_NO_ELECTIONS:
            return { ...state, showLockheedNoElections: payload };
        case SET_LOCKHEED_SETTINGS:
            payload.dataLoaded = true;
            return { ...state, ...payload };
        default:
            return state;
    }
};
