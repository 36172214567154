import { useSelector } from "../../../hooks/use-redux-actions";

const CURRENT_DATE = new Date();

const usePaycheckRetirementYear = () => {
    const { dateOfBirth, retirementAge } = useSelector((state) => state.participant);

    if (dateOfBirth !== "") {
        return new Date(dateOfBirth).getFullYear() + retirementAge;
    }

    return CURRENT_DATE.getFullYear();
};

export default usePaycheckRetirementYear;
