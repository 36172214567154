import {
    SET_PROJECTED_INCOME,
    SET_INCOME_GOAL_VALUE,
    SET_ACTIVE_INCOME_TAB_ID,
    SET_FOCUS_ON_FIRST_ACTIONABLE,
    SET_HUB_CALCULATION_DONE
} from "../../actions/projectedIncome/projectedIncomeTypes";

const initialState = {
    defaultIncomeGoalValue: 75,
    incomeGap: 0,
    incomeGoalValue: 0,
    incomeParts: [],
    percentageOfGoal: 0,
    retireeDetails: null,
    total: null,
    hubCalculationDone: false,
    activeIncomeTabId: "",
    doFocusOnFirstActionable: false
};

export default (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SET_PROJECTED_INCOME:
            return { ...state, ...payload };
        case SET_INCOME_GOAL_VALUE:
            return { ...state, incomeGoalValue: payload };
        case SET_ACTIVE_INCOME_TAB_ID:
            return { ...state, activeIncomeTabId: payload };
        case SET_FOCUS_ON_FIRST_ACTIONABLE:
            return { ...state, doFocusOnFirstActionable: payload };
        case SET_HUB_CALCULATION_DONE:
            return { ...state, hubCalculationDone: payload };
        default:
            return state;
    }
};
