import React from "react";

import PropTypes from "prop-types";

import toKebabCase from "../../../utils/toKebabCase";

const HiddenA11yWrapper = ({ id, children }) => {
    return (
        <div data-testid={toKebabCase("HiddenA11yWrapper")} className="hidden" id={id}>
            {children}
        </div>
    );
};
HiddenA11yWrapper.propTypes = {
    children: PropTypes.any,
    id: PropTypes.string
};
export default HiddenA11yWrapper;
