import { ObjectUtil } from "gw-shared-components";

import { setCurrentFault, setTxnAccess } from "../actions/shared/sharedActionCreators";
import { getGroupTxnAccess, getTxnAccess } from "../services/sharedService";

/** If WNHASM is false or undefined, display advisory messaging */
export const loadGroupTxnAccess = (individualId, planId, txnCodes) => {
    return (dispatch) => {
        return getGroupTxnAccess(individualId, planId, txnCodes)
            .then((response) => {
                if (response) {
                    const txnAccess = {};
                    if (
                        !ObjectUtil.isUndefinedOrNull(response) &&
                        !ObjectUtil.isUndefinedOrNull(response.WNHASM)
                    ) {
                        txnAccess.hasAdvisoryAccess = response.WNHASM !== "true";
                    }
                    if (!ObjectUtil.isUndefinedOrNull(response.CXACR)) {
                        txnAccess.referralEnabled = response.CXACR === "true";
                    }
                    if (!ObjectUtil.isUndefinedOrNull(response.CXAOE)) {
                        txnAccess.accountOpenEnabled = response.CXAOE === "true";
                    }
                    if (!ObjectUtil.isUndefinedOrNull(response.WNOAIN && response.WNINSS)) {
                        const isOpenConsiderationsTab =
                            response.WNOAIN === "true" && response.WNINSS === "true";
                        txnAccess.showConsiderationsTab = isOpenConsiderationsTab;
                    }
                    if (!ObjectUtil.isUndefinedOrNull(response.WNOAIN)) {
                        txnAccess.otherAssetsEnabled = response.WNOAIN === "true";
                    }
                    if (!ObjectUtil.isUndefinedOrNull(response.CXPFD)) {
                        txnAccess.pcapWidgetsEnabled = response.CXPFD === "true";
                    }
                    if (!ObjectUtil.isUndefinedOrNull(response.CXNBW)) {
                        globalThis.integratedEligibility.showBudgetingWidget =
                            response.CXNBW === "true";
                    }
                    if (Object.keys(txnAccess).length > 0) {
                        dispatch(setTxnAccess(txnAccess));
                    }
                }
            })
            .catch((fault) => {
                dispatch(setCurrentFault({ ...fault, stayOnCurrentPage: true }));
            });
    };
};

export const getTxnAccessSettings = () => {
    return (dispatch) => {
        const params = {
            txnCodes:
                "CXAME,WNADDA,WNADDT,WNUPLD,WN1ID,CXACM,WNSTMT,WNBEN,WNCRM,WNDEMN,WNASML,WNDFER,CXNASW,CXMAW"
        };

        return getTxnAccess(params)
            .then(function ({
                CXAME,
                WNADDA,
                WNADDT,
                WNUPLD,
                WN1ID,
                WNSTMT,
                WNBEN,
                WNCRM,
                WNDEMN,
                WNASML,
                WNDFER,
                CXNASW,
                CXMAW
            }) {
                const txnAccess = {
                    aceMessagingEnabled: CXAME,
                    addressUpdateForActiveEmployeeEnabled: WNADDA,
                    addressUpdateForTermEmployeeEnabled: WNADDT,
                    uploadDocumentsEnabled: WNUPLD,
                    is1Id: WN1ID,
                    statementsAndDocuments: WNSTMT,
                    beneficiary: WNBEN,
                    ignoreHighContributions: WNCRM,
                    allowDeminModal: WNDEMN,
                    customMtrMessageEnabled: WNASML,
                    displayFeForecast: WNDFER,
                    showNewSidebar: CXNASW,
                    onboardingChecklistEnabled: CXMAW
                };

                dispatch(setTxnAccess(txnAccess));
            })
            .catch((error) => {
                console.error(
                    `ERROR - getTxnAccess - params: ${JSON.stringify(params)} \n ${error}`
                );
            });
    };
};
