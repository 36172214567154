import axios from "axios";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { isNil as _isNil } from "lodash";

import RequestFactory from "../factories/RequestFactory";
import { executeGet, executeRequest } from "../utils/apiUtils";
import { handleFault } from "../utils/errorUtils";
import { getUrl, isLocalhost, hasMocks } from "../utils/urlUtils.js";

const logger = ExternalLogger.getInstance("UserPreferencesService");

/*
* Example payload expected: 
payload: { 
    currentpassword: "NOT_NEEDED"
    flowName: "profile"
    newpassword: "NOT_NEEDED"
    username: "714638539ABC"
}
*/
export const updateCredentials = async (payload) => {
    if (_isNil(payload)) {
        Promise.reject("empty payload");
    }
    const url = getUrl("updateCredentials");
    const method = RequestFactory.HTTP_METHOD.POST;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const request = RequestFactory.create(url, payload, method, contentType);

    const response = await executeRequest(request, "updateCredentialsFault");
    return response.data;
};

export const getRetriveProfileInfo = async () => {
    const response = await executeGet(
        getUrl("getRetriveProfileInfo"),
        "getRetriveProfileInfoFault"
    );
    return response.data;
};

export const getSubjectToFeva = async () => {
    const response = await executeGet(getUrl("getSubjectToFeva"));
    if (_isNil(response) || _isNil(response.data)) {
        throw "There is no response data.";
    } else {
        return response.data;
    }
};

export const pcapHoldGetDataInSession = async (gaId, indId) => {
    const params = { gaId, indId };
    const url = StringUtil.supplant(getUrl("pcapHoldGetDataInSession"), params);
    const response = await executeGet(url, "pcapHoldGetDataInSessionFault");
    return response.data;
};

export const updateMarketingPreferences = async (payload) => {
    const url = getUrl("updateMarketingPreferences");
    const method = RequestFactory.HTTP_METHOD.POST;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const request = RequestFactory.create(url, payload, method, contentType);

    return await executeRequest(request, "updateMarketingPreferencesFault");
};

export const retrieveMarketingPreferences = async () => {
    const url = getUrl("retrieveMarketingPreferences");
    const response = await executeGet(url, "retrieveMarketingPreferencesFault");
    return response.data;
};

export const getPersons = function (csrf) {
    const service = "getPersons";
    const url = getUrl(service);
    const method = RequestFactory.HTTP_METHOD.POST;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.URL_ENCODED;

    const mock = window.location.origin + window.location.pathname + "data/get-persons.json";
    const updatedUrl = isLocalhost && hasMocks ? mock : url;

    const formData = new FormData();
    formData.append("csrf", csrf);
    formData.append("apiClient", "WEB");
    formData.append("lastServerChangeId", -1);

    return axios({
        method: method,
        url: updatedUrl,
        data: formData,
        headers: { "content-type": contentType },
        xhrFields: { withCredentials: true }
    })
        .then((response) => {
            if (response && response.data) {
                return response.data.spData;
            }
        })
        .catch((err) => {
            logger.error(method + "getPersons Service Error: {0}", [err.message]);
            return handleFault(err, "getPersonsFault");
        });
};
