import React, { useState, useEffect } from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import { noop as _noop } from "lodash";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { dispatchEventBus } from "../actions/shared/sharedActionCreators";
import EventBusEvents from "../events/eventBusEvents";
import { useIsPrivileged } from "../hooks/useEmulatorType";
import { updateUserMessages } from "../services/userMessagesService";

const DEFAULT_COPY_LINK = {};
const DEFAULT_EMAIL_LINK = {};

const CustomerReferralButtons = {
    Copy: "customer-referral-copy",
    Email: "customer-referral-email"
};

const CustomerReferral = ({ referral }) => {
    const privileged = useIsPrivileged();
    const referralTranslations = selectTranslations("customerReferral");
    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
        const input = document.querySelector("input[name=referral]");

        input.focus();
        input.select();
    }, []);

    const buttonClick = (key, link) => {
        const servicePayload = {
            userMessageIds: [referral.userMessageId],
            actions: ["markClicked"]
        };

        switch (key) {
            case "COPY":
                window.navigator.clipboard.writeText(link);
                setShowTooltip(true);

                setTimeout(() => {
                    setShowTooltip(false);
                }, 2000);
                break;

            case "EMAIL":
                window.open(link, "_blank");
                break;
        }

        dispatchEventBus(EventBusEvents.REFERRAL[`${key}_CLICK`]);
        updateUserMessages(servicePayload);
    };

    const termsClick = () => {
        dispatchEventBus(EventBusEvents.REFERRAL.TERMS_CLICK);
    };

    const handleEnterPress = (e) => {
        if (e.key === "Enter") {
            const { key, url } =
                CustomerReferralButtons.Copy === e.currentTarget.name ? copyLinkObj : emailLinkObj;
            buttonClick(key, url);
        }
    };

    const copyLinkObj = referral
        ? referral.action.find((obj) => obj.key === "COPY")
        : DEFAULT_COPY_LINK;
    const emailLinkObj = referral
        ? referral.action.find((obj) => obj.key === "EMAIL")
        : DEFAULT_EMAIL_LINK;

    return (
        <div data-testid="customer-referral" className="pc-module-container customer-referral-page">
            <div className="customer-referral-page__header">
                <h1>{referral.title}</h1>
                <h3>{referral.explanation}</h3>
                <div className="customer-referral-page__header-svg"></div>
            </div>
            <div className="customer-referral-page__link-section">
                <div className="link-section__input">
                    <label htmlFor="referral" id="referralInputId">
                        {referral.resources[0].jsonContent.referralUrlLabel}
                    </label>
                    <input
                        data-testid="customer-referral-input"
                        name="referral"
                        type="text"
                        readOnly={true}
                        aria-readonly={true}
                        value={copyLinkObj.url}
                        aria-describedby="referralInputId"
                    />
                </div>
                <div className="link-section__buttons">
                    <OverlayTrigger
                        placement="bottom"
                        show={showTooltip}
                        overlay={
                            <Tooltip
                                data-testid="customer-referral-tooltip"
                                className="link-section__button-tooltip"
                                id="copyTooltipId"
                            >
                                {referralTranslations.tooltip}
                            </Tooltip>
                        }
                    >
                        <button
                            data-testid={CustomerReferralButtons.Copy}
                            onClick={() => buttonClick(copyLinkObj.key, copyLinkObj.url)}
                            onKeyDown={handleEnterPress}
                            aria-describedby="copyTooltipId"
                            name={CustomerReferralButtons.Copy}
                        >
                            {copyLinkObj.label}
                        </button>
                    </OverlayTrigger>
                    <button
                        className={privileged ? "is-disabled" : ""}
                        data-testid={CustomerReferralButtons.Email}
                        aria-disabled={privileged}
                        onClick={() => buttonClick(emailLinkObj.key, emailLinkObj.url)}
                        onKeyDown={!privileged ? handleEnterPress : _noop}
                        name={CustomerReferralButtons.Email}
                    >
                        {emailLinkObj.label}
                    </button>
                </div>
                <div className="link-section__guide">
                    <p>{referral.summary}</p>
                </div>
            </div>
            <div className="customer-referral-page__terms">
                <a
                    onClick={termsClick}
                    href={referral.resources[0].jsonContent.disclosures.link}
                    target="_blank"
                    rel="noreferrer"
                >
                    {referral.resources[0].jsonContent.disclosures.title}
                </a>
            </div>
        </div>
    );
};

CustomerReferral.propTypes = {
    referral: PropTypes.shape({
        template: PropTypes.string.isRequired,
        summary: PropTypes.string.isRequired,
        updatedTime: PropTypes.number.isRequired,
        isValid: PropTypes.bool.isRequired,
        displayLocations: PropTypes.arrayOf(PropTypes.string).isRequired,
        resources: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
                url: PropTypes.string,
                size: PropTypes.string,
                alt: PropTypes.string,
                content: PropTypes.string,
                jsonContent: PropTypes.shape({
                    referrerGuid: PropTypes.string.isRequired,
                    referralUrlLabel: PropTypes.string.isRequired,
                    referralUrl: PropTypes.string.isRequired,
                    disclosures: PropTypes.shape({
                        link: PropTypes.string.isRequired,
                        title: PropTypes.string.isRequired
                    }).isRequired
                }).isRequired
            })
        ).isRequired,
        title: PropTypes.string.isRequired,
        explanation: PropTypes.string.isRequired,
        userMessageId: PropTypes.number.isRequired,
        priority: PropTypes.string.isRequired,
        component: PropTypes.string.isRequired,
        viewTemplate: PropTypes.string,
        action: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
                type: PropTypes.string,
                summary: PropTypes.string,
                trackingFlag: PropTypes.bool.isRequired,
                data: PropTypes.object,
                label: PropTypes.string.isRequired
            })
        ).isRequired,
        createdTime: PropTypes.number.isRequired,
        isStale: PropTypes.bool.isRequired
    }).isRequired
};

export default CustomerReferral;
