export const DEFAULT_STALE_TIME = 300_000; // 5 minutes

export const QUERY_KEYS = {
    GET_ACCOUNTS: "getAccounts",
    GET_ACCOUNT_2: "getAccounts2",
    GET_ACCOUNTS_2_LIST: "getAccounts2List",
    GET_ACCOUNTS_LITE: "getAccountsLite",
    GET_QUICK_VIEW_ACCOUNTS_DATA: "getQuickViewAccountsData",
    GET_USER_MESSAGES: "getUserMessages",
    ELIGIBLE: "eligible",
    SUMMARY: "summary",
    SHOW_LIAT: "showLIAT",
    GET_HOUSEHOLD_INIT_DATA: "getHouseholdInitData",
    GET_PERSONS: "getPersons",
    GET_PRIMARY_PLAN: "getPrimaryPlan",
    UPDATE_DEMIN_MODAL_TRACKING: "updateDeminModalTracking",
    GET_MTR_DETAILS: "getMADetails",
    GET_FUNNEL_DATA: "getFunnelData",
    GET_GROUP_TXN_ACCESS: "getGroupTxnAccess",
    GET_NAVIGATION_MENUS: "get-group-menu",
    GET_ADVISORY_MESSAGING: "getAdvisoryMessaging",
    GET_FE_DATA: "getFeData",
    GET_ACCOUNT_CUSTOMIZATION: "getAccountCustomization",
    GET_TOP_NAV_LINKS: "getTopNavLinks",
    QUERY_SESSION: "querySession"
};
