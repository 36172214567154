import axios from "axios";
import ExternalLogger from "core-ui/client/src/app/ExternalLogger";
import StringUtil from "core-ui/client/src/app/StringUtil";
import { isUndefinedOrNull, isArrayEmpty } from "gw-shared-components";
import { each as _each } from "lodash";

import RequestFactory from "../factories/RequestFactory";
import { executeGet } from "../utils/apiUtils.js";
import { handleFault } from "../utils/errorUtils";
import { getUrl } from "../utils/urlUtils.js";

const logger = ExternalLogger.getInstance("HealthCareService");

export const getHealthViewData = async (personId) => {
    const urlKey = "getHealthViewData";
    const url = StringUtil.supplant(getUrl(urlKey), { personId: personId });

    const response = await executeGet(url, urlKey);
    return response.data.data;
};

const pendingChangesToParams = (pendingChanges, selectedRetirementState) => {
    const data = {};
    // add conditions if applicable
    if (
        !isUndefinedOrNull(pendingChanges.healthConditions) &&
        !isArrayEmpty(pendingChanges.healthConditions)
    ) {
        const conditions = [];

        _each(pendingChanges.healthConditions, function (condition) {
            if (!isUndefinedOrNull(condition)) {
                conditions.push({
                    id: isUndefinedOrNull(condition.id) ? "" : condition.id,
                    conditionCode: condition.conditionCode,
                    conditionName: condition.conditionName,
                    preSelected: condition.isPreselected,
                    actionCode: condition.actionCode
                });
            }
        });

        data["conditions"] = conditions;
        data["selectedRetirementState"] = selectedRetirementState;
    }

    // add retirement state if applicable
    if (!isUndefinedOrNull(pendingChanges.selectedRetirementStateCode)) {
        data.selectedRetirementState = pendingChanges.selectedRetirementStateCode;
    }

    return data;
};

/**
 * Makes an HTTP request to the API endpoint to save health view data. Internally handles the success and fault
 * of that HTTP request.
 *
 * Unlike other API calls the `data` property for a successful response is null -- there's no data coming back
 * and a successful response simply indicates that the save was successful and the changes were persisted to
 * the back-end.
 *
 * @param {object} pendingChanges Contains the list of changed health conditions and selected state.
 * @returns {promise} A promise to the future resolution of the HTTP request.
 * @note saveHealthViewData was a bit overused and this function came from the old Delegate
 */
const saveHealthViewDataDelegate = function (pendingChanges, selectedRetirementState) {
    logger.info("saveHealthViewData()");

    /**
     * Service success handler and promise resolver.
     * @param result
     */
    const saveHealthViewDataResult = function (result) {
        // NOTE: In most service responses we look for the data property, but it's always null here when
        // successful so we allow null data.
        if (isUndefinedOrNull(result)) {
            logger.error("saveHealthViewDataResult( Bad response data. )");
            throw new Error("There is no response data.");
        } else {
            logger.info("saveHealthViewDataResult()");
            return result;
        }
    };

    /**
     * Service fault handler and promise rejection.
     * @param fault
     */
    const saveHealthViewDataFault = function (fault) {
        logger.error("saveHealthViewDataFault( fault = {0} )", [fault.message]);
        return Promise.reject(fault);
    };

    const url = getUrl("saveHealthViewDataIntegrated");
    const params = pendingChangesToParams(pendingChanges, selectedRetirementState);
    const method = RequestFactory.HTTP_METHOD.POST;
    const contentType = RequestFactory.HTTP_CONTENT_TYPE.JSON;
    const request = RequestFactory.create(url, params, method, contentType);
    delete request.headers.samlToken;

    return axios(request).then(saveHealthViewDataResult, saveHealthViewDataFault);
};

/**
 * The health care data is split among multiple API calls, so this method aggregates and chains together those
 * calls to produce an expected data response in one object. The entry point for the health care data and performs
 * the following chained actions:
 *
 * 1) Calls save health view data API endpoint.
 * 2) Calls get health view data API endpoint to acquire the updated health view data based on the save data.
 *
 */
const save = async function (pendingChanges, selectedRetirementState) {
    logger.info("save()");

    const saveResult = function (result) {
        logger.info("saveResult()");
        return result;
    };

    const saveFault = function (fault) {
        logger.error("saveFault( fault = {0} )", [fault.message]);
        return Promise.reject(fault);
    };

    try {
        const result = await saveHealthViewDataDelegate(pendingChanges, selectedRetirementState);
        return saveResult(result);
    } catch (fault) {
        return saveFault(fault);
    }
};

/**
 * Saves the changes to health conditions or selected state for the health view. The service call will return
 * the updated HealthViewData so we'll need to push the updated data into the service.
 */
export const saveHealthViewData = function (pendingChanges, personId, selectedRetirementState) {
    logger.info("saveHealthViewData()");

    const saveHealthViewDataResult = function (healthViewData) {
        logger.info("saveHealthViewDataResult()");
        // return the health view data
        return healthViewData;
    };

    const saveHealthViewDataFault = function (fault) {
        // show the API fault in the error modal
        logger.error("saveHealthViewDataFault( fault = {0} )", [fault.message]);
        return handleFault(fault);
    };

    pendingChanges.personId = personId;

    return save(pendingChanges, selectedRetirementState).then(
        saveHealthViewDataResult,
        saveHealthViewDataFault
    );
};

export const openHealthViewReport = (retirementAge, salary) => {
    const params = [retirementAge, salary];
    const url = getUrl("getHealthViewPDF");
    const parsed = StringUtil.supplant(url, params);
    const win = window.open(parsed, "_blank");
    if (win) {
        win.focus();
    } else {
        logger.warn("Cannot set focus to medicare web site due to browser security");
    }
};
