import HealthSurvey from "./HealthSurvey";

/**
 * @name app.common.model.domain.HealthViewData
 * @date 1/20/2015
 * @copyright 2015 Empower Retirement
 * @type Class
 * @description
 * Creates a defined object structure for the HealthViewData object type.
 */
class HealthViewData {
    constructor(config) {
        this.chartLegends = [];
        this.notifications = [];
        this.projections = [];
        this.salaryBrackets = [];
        this.yieldThreshold = 0;
        this.warnThreshold = 0;
        this.healthSurvey = new HealthSurvey();

        if (config) {
            this.chartLegends = config.chartLegends;
            this.projections = config.projections;
            this.salaryBrackets = config.salaryBrackets;
            this.yieldThreshold = config.yieldThreshold;
            this.warnThreshold = config.warnThreshold;
            this.healthSurvey = config.healthSurvey;
        }
    }
}

export default HealthViewData;
