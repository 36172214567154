import initialKTMGFlags from "../initialKTMGFlags";

const getParsedKTMGFLags = ({ txnCodesResponseData }) => {
    const parsedFlags = initialKTMGFlags;
    for (const key in txnCodesResponseData) {
        parsedFlags[key] = txnCodesResponseData[key] === "true";
    }
    return parsedFlags;
};

export default getParsedKTMGFLags;
