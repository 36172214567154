import React from "react";

import { selectTranslations } from "core-ui/client/src/app/core/translateServiceModule/TranslationsSelector";
import PropTypes from "prop-types";

import RadialChart from "../../../radialChart/RadialChart";

/**
 * Mobile version for hdic radial charts
 * @param {*} pog
 */

const PeerRadialCharts = ({ percentageOfGoal, name }) => {
    const translations = selectTranslations("hdic");

    const getGoalColor = (pog) => {
        if (pog < 40) {
            return "hdic-pog-low";
        } else if (pog < 50) {
            return "hdic-pog-medium";
        }
        return "hdic-pog-high";
    };

    const peerPctColor = getGoalColor(percentageOfGoal);
    const goalSegments = [];
    let item = {};

    switch (name) {
        case "target-my-peers":
            item = {
                code: "myPeers",
                class: peerPctColor,
                percent: Number(percentageOfGoal) > 100 ? 100 : Number(percentageOfGoal)
            };

            break;
        case "target-top-peers":
            item = {
                code: "topPeers",
                class: getGoalColor(percentageOfGoal),
                percent: Number(percentageOfGoal) > 100 ? 100 : Number(percentageOfGoal)
            };
            break;
        default:
            break;
    }

    goalSegments.push(item);
    const remainingPct = percentageOfGoal >= 100 ? 0 : 100 - percentageOfGoal;
    const remainingItem = {
        code: "remaining",
        class: "integrated-hdic-pog-remaining-color",
        percent: remainingPct
    };
    goalSegments.push(remainingItem);

    return (
        <div>
            <RadialChart
                wrapperClass="mobile-hdic-radial-chart-wrapper"
                segments={goalSegments}
                selectedSegmentCode=""
                donutHoleClass="integrated-hdic-radial-chart-inner"
                topPadding={true}
                topPaddingClass="mobile-hdic-radial-chart-top-padding"
                handleClick={(e) => e.preventDefault}
            >
                <text fill="#092c3d" x="52%" y="50%">
                    <tspan className="score integrated-hdic-score">
                        {Math.round(percentageOfGoal)}%
                    </tspan>
                </text>
                <text
                    className="goal-subtext-of-goal progress-to-goal-chart-action"
                    x="50%"
                    y="65%"
                    fill="#7d7d7d"
                >
                    {translations.ofGoal}
                </text>
            </RadialChart>
        </div>
    );
};
PeerRadialCharts.propTypes = {
    percentageOfGoal: PropTypes.number,
    name: PropTypes.string
};
export default PeerRadialCharts;
