/**
 *
 * @class InitDataValidator
 * @memberOf common.validation
 * @date 2/26/2015
 * @copyright 2015 Empower Retirement
 * @description Validates the raw API data response that represents the model.
 *
 */
import { each as _each, find as _find, findIndex as _findIndex, isEmpty as _isEmpty } from "lodash";

import DeferralConstants from "../constants/DeferralConstants";

import ObjectUtil from "./ObjectUtil";

const InitDataValidator = {
    //-----------------------------------------------------------------------
    // CONSTANTS
    //-----------------------------------------------------------------------

    /**
     * @constant {string} ERROR_NO_DATA
     * @memberOf common.validation.InitDataValidator
     * @description The error message for no data.
     */
    ERROR_NO_DATA: "The data being validated is undefined or null.",

    /**
     * @constant {string} ERROR_NO_USER
     * @memberOf common.validation.InitDataValidator
     * @description The error message for no user in the data.
     */
    ERROR_NO_USER: "There is no user in the data model.",

    /**
     * @constant {string} ERROR_NO_PLANS
     * @memberOf common.validation.InitDataValidator
     * @description The error message for no plans in the data.
     */
    ERROR_NO_PLANS: "There are no plans or it's an empty list in the data model.",

    /**
     * @constant {string} ERROR_NO_ASSET_INFO
     * @memberOf common.validation.InitDataValidator
     * @description The error message for no asset info in the data.
     */
    ERROR_NO_ASSET_INFO: "There asset info in the data model.",

    /**
     * @constant {string} ERROR_IRS_LIMITS
     * @memberOf common.validation.InitDataValidator
     * @description The error message for no IRS Limits in the data.
     */
    ERROR_IRS_LIMITS: "The IRSLimits must be a valid non-empty array",

    /**
     * @constant {string} ERROR_FACTOR_SETS
     * @memberOf common.validation.InitDataValidator
     * @description The error message for no invalid factor sets in the data.
     */
    ERROR_FACTOR_SETS: "The factor sets provided are invalid",

    /**
     * @constant {string} ERROR_NO_ACTIVE_PLAN
     * @memberOf common.validation.InitDataValidator
     * @description The error message for no active plan info in the data.
     */
    ERROR_NO_ACTIVE_PLAN: "There is no active plan",

    /**
     * @constant {string} ERROR_NO_PLAN_LIMITS
     * @memberOf common.validation.InitDataValidator
     * @description The error message for no plan limits in the data.
     */
    ERROR_NO_PLAN_LIMITS: "There are no plan limits in the active plan",

    /**
     * @constant {string} ERROR_INVALID_SS_BENEFITS
     * @memberOf common.validation.InitDataValidator
     * @description The error message for invalid ss benefits layout for non-migrated user.
     */
    ERROR_SS_BENEFITS: "The social security benefits map is invalid",

    //-----------------------------------------------------------------------
    // MODEL METHODS
    //-----------------------------------------------------------------------

    /**
     * @function validate
     * @memberOf common.validation.InitDataValidator
     * @param {object} initData The model.
     * @description Reviews the data and determines if it's valid. If essential data properties
     * are missing the validator throws an error.
     * @throws {ERROR_NO_USER} Will throw an error if there's no user object in the data model.
     * @throws {ERROR_NO_PLANS} Will throw an error if there's no plans list in the data model.
     */
    validate: function (initData) {
        if (ObjectUtil.isUndefinedOrNull(initData)) {
            throw new Error(InitDataValidator.ERROR_NO_DATA);
        }
        if (ObjectUtil.isUndefinedOrNull(initData.users)) {
            throw new Error(InitDataValidator.ERROR_NO_USER);
        }
        const primaryHHIndx = _findIndex(initData.users, { headOfHousehold: true });
        if (
            ObjectUtil.isUndefinedOrNull(initData.users[primaryHHIndx].plans) ||
            ObjectUtil.isArrayEmpty(initData.users[primaryHHIndx].plans)
        ) {
            throw new Error(InitDataValidator.ERROR_NO_PLANS);
        }

        InitDataValidator.validateIRSLimits(initData);
        InitDataValidator.validateFactorSets(initData);
        InitDataValidator.validateActivePlan(initData, primaryHHIndx);
        InitDataValidator.validatePlanLimits(initData, primaryHHIndx);
    },

    /**
     * @function validateIRSLimits
     * @memberOf common.validation.InitDataValidator
     * @param {object} initData The model.
     * @description Inspects the IRSLimits property for each lan and determines if it's valid.
     * If essential data properties are missing the validator throws an error.
     * @throws {ERROR_IRS_LIMITS} Will throw an error if there are no IRSLimits in the data model.
     */
    validateIRSLimits: function (initData) {
        /*
         "IRSLimits" : {
         "limit402g": "18000",
         "limit415": "53000",
         "discountFactor": "4",
         "limitCatchup": "6000",
         "limit402gCatchup": "24000",
         "rateOfInflation": "3",
         "matchableSalaryCap": "265000",
         "catchupEligibleAge": "50"
         },
         */
        _each(initData.users, function (user) {
            _each(user.plans, (plan) => {
                const IRSLimits = plan.irsLimits;

                if (plan.irsCode === DeferralConstants.typeCodes.HSA) {
                    if (
                        !ObjectUtil.isUndefinedOrNull(IRSLimits) &&
                        !_isEmpty(IRSLimits) &&
                        (ObjectUtil.isUndefinedOrNull(IRSLimits.hsaFamily) ||
                            ObjectUtil.isUndefinedOrNull(IRSLimits.hsaSingleCatchUp) ||
                            ObjectUtil.isUndefinedOrNull(IRSLimits.hsaSingle) ||
                            ObjectUtil.isUndefinedOrNull(IRSLimits.hsaFamilyCatchUp))
                    ) {
                        throw new Error(InitDataValidator.ERROR_IRS_LIMITS);
                    }
                } else {
                    if (
                        !ObjectUtil.isUndefinedOrNull(IRSLimits) &&
                        !_isEmpty(IRSLimits) &&
                        (ObjectUtil.isUndefinedOrNull(IRSLimits.limit415) ||
                            (ObjectUtil.isUndefinedOrNull(IRSLimits.limit402g) &&
                                ObjectUtil.isUndefinedOrNull(IRSLimits.limit457)) ||
                            ObjectUtil.isUndefinedOrNull(IRSLimits.discountFactor) ||
                            ObjectUtil.isUndefinedOrNull(IRSLimits.limitCatchup) ||
                            ObjectUtil.isUndefinedOrNull(IRSLimits.rateOfInflation) ||
                            ObjectUtil.isUndefinedOrNull(IRSLimits.matchableSalaryCap) ||
                            ObjectUtil.isUndefinedOrNull(IRSLimits.catchupEligibleAge))
                    ) {
                        throw new Error(InitDataValidator.ERROR_IRS_LIMITS);
                    }
                }
            });
        });
    },

    /**
     * @function validateFactorSets
     * @memberOf common.validation.InitDataValidator
     * @param {object} initData The model.
     * @description Inspects the factorSets property and throws an error if certain properties
     * are undefined.
     * new structure - ssBenefitsMap and ssMonthlyBenefit will not be defined for migrated user
     * for integrated user - verify ssBenefitStartByAge map
     * @throws {ERROR_FACTOR_SETS} Will throw an error if initData.factorSets is invalid.
     */
    validateFactorSets: function (initData) {
        _each(initData.users, (user) => {
            if (ObjectUtil.isUndefinedOrNull(user.projectionsMap)) {
                throw new Error(InitDataValidator.ERROR_FACTOR_SETS);
            }

            const ssBenefitsUndefined = !ObjectUtil.isPropertyDefined(
                user,
                "socialSecuritySummary.ssBenefitByStartAge"
            );
            if (ssBenefitsUndefined) {
                throw new Error(InitDataValidator.ERROR_SS_BENEFITS);
            }
        });
    },

    /**
     * @function validateActivePlan
     * @memberOf common.validation.InitDataValidator
     * @param {object} initData The model.
     * @param {number} primaryHHIndx  Index of primary participant
     * @description Inspects the and throws an error if it doesn't have a valid active plan.
     * @throws {ERROR_NO_ACTIVE_PLAN} Will throw an error if proper flag is falsy.
     */
    validateActivePlan: function (initData, primaryHHIndx) {
        const activePlan = _find(initData.users[primaryHHIndx].plans, { primary: true });
        if (ObjectUtil.isUndefinedOrNull(activePlan)) {
            throw new Error(InitDataValidator.ERROR_NO_ACTIVE_PLAN);
        }
    },

    /**
     * @function validateActivePlan
     * @memberOf common.validation.InitDataValidator
     * @param {object} initData The model.
     * @param {number} primaryHHIndx  Index of primary participant
     * @description Inspects the and throws an error if it doesn't have a valid active plan.
     * @throws {ERROR_NO_ACTIVE_PLAN} Will throw an error if proper flag is falsy.
     */
    validatePlanLimits: function (initData, primaryHHIndx) {
        const activePlan = _find(initData.users[primaryHHIndx].plans, { primary: true });

        if (ObjectUtil.isUndefinedOrNull(activePlan.limits)) {
            throw new Error(InitDataValidator.ERROR_NO_PLAN_LIMITS);
        }
    }
};

export default InitDataValidator;
