import * as Sentry from "@sentry/react";
import { AMPLITUDE_EVENTS, dispatchAmplitude } from "core-ui/client/src/app/core/amplitude";

import { isLocalhost } from "../../../liat/utils/urlUtils";
import { getUseSentry } from "../getEvents";

const LAGGING_OUTLIER_EVENT = "LAGGING_OUTLIER_EVENT";

const TEN_MINUTES_IN_MILLISECONDS = 10 * 60 * 1000;

interface SendMetricEventArgs {
    endTime?: number; // setting as optional because we do not have this value easily accessible (in pcap-webui)
    loginLastHash?: string;
    measurementName: string;
    name: string;
    startHash?: string;
    startTime: number;
    timeToLoad: number;
}

const sendMetricEvent = ({
    name,
    timeToLoad,
    measurementName,
    startHash = "",
    loginLastHash = "",
    startTime,
    endTime
}: SendMetricEventArgs) => {
    // No need to collect data in local development
    if (isLocalhost) {
        return null;
    }

    const useSentry = getUseSentry();

    // Remove edge cases where the time is going to be greater than 10 minutes
    const isGreaterThan10Minutes = timeToLoad > TEN_MINUTES_IN_MILLISECONDS;
    if (isGreaterThan10Minutes) {
        if (useSentry) {
            Sentry.metrics.distribution(`${LAGGING_OUTLIER_EVENT}-${name}`, timeToLoad, {
                tags: {
                    type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                    startHash,
                    loginLastHash,
                    startTime,
                    endTime
                },
                unit: "millisecond"
            });
        }
        // TODO: Do we want to add an amplittude event here?

        return null;
    }
    if (useSentry) {
        // https://docs.sentry.io/platforms/javascript/guides/react/metrics/#emit-a-distribution
        // Add '15.0' to a distribution used for tracking the loading times for component.
        Sentry.metrics.distribution(name, timeToLoad, {
            tags: {
                type: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
                startHash,
                loginLastHash,
                startTime,
                endTime
            },
            unit: "millisecond"
        });
    }

    window.dispatchEvent(
        new CustomEvent(name, {
            detail: { timeToLoad, startTime, endTime }
        })
    );

    const amplitudePayload: Record<string, string | number> = {
        name,
        timeToLoad,
        measurementName,
        startHash,
        loginLastHash,
        startTime
    };

    if (endTime) {
        amplitudePayload.endTime = endTime;
    }

    dispatchAmplitude({
        eventType: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
        selection: AMPLITUDE_EVENTS.REPORT_DIAGNOSTICS,
        payload: amplitudePayload
    });
};

export default sendMetricEvent;
