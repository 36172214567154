"use strict";
/**
 * @name app.common.model.domain.ExternalAssetEmployerDetail
 * @date 1/11/2018
 * @copyright 2018 Empower Retirement
 * @type Class
 */
class ExternalAssetEmployerDetail {
    constructor(config) {
        this.id = null;
        this.sequenceNumber = null;
        this.matchValuePercentageFlag = null;
        this.matchValue = null;
        this.limitValuePercentageFlag = null;
        this.limitValue = null;

        if (config) {
            this.id = config.id;
            this.sequenceNumber = config.sequenceNumber;
            this.matchValuePercentageFlag = config.matchValuePercentageFlag;
            this.matchValue = config.matchValue;
            this.limitValuePercentageFlag = config.limitValuePercentageFlag;
            this.limitValue = config.limitValue;
        }
    }
}

export default ExternalAssetEmployerDetail;
