"use strict";
/**
 * @name app.infrastructure.domainModel.SpecialLimit
 * @date 2/9/2018
 * @copyright 2018 Empower Retirement
 * @type Class
 * @description
 * Definition of the Special Limit domain model object - needed for LA County
 */

class SpecialLimit {
    constructor(config) {
        this.planEnabled = false;
        this.pourOverEnabled = false;
        this.type = null;
        this.limit = 0;

        if (config) {
            this.planEnabled = config.planEnabled;
            this.pourOverEnabled = config.pourOverEnabled;
            this.limit = config.limit || 0;
            this.type = config.type || null;
        }
    }
}

export default SpecialLimit;
